import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import Ruhr from '../Images/Ruhr.mp4';

export default function RuhrMove ({ setLanguage, Language, isAuth, setIsAuth, TimeVI, setTimeVI, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    // 再生チャプター変色
    const [ChapterColor1, setChapterColor1] = useState('MoveChapBtn');
    const [ChapterColor2, setChapterColor2] = useState('MoveChapBtn');
    const [ChapterColor3, setChapterColor3] = useState('MoveChapBtn');
    const [ChapterColor4, setChapterColor4] = useState('MoveChapBtn');
    const [ChapterColor5, setChapterColor5] = useState('MoveChapBtn');

    const [TimePlus, setTimePlus] = useState(0)
    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        if(TimePlus == 0){
            document.getElementById('video').currentTime = TimeVI;
            setTimePlus(1);
        }

        //  日本語で話そう
        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }

            setChapterColor1('MoveChapBtn MChBChange');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
        }
        if(counter >= 1){
            if(Language == 0){
                setKashi('日本語で話そう');
            }
            if(Language == 1){
                setKashi("Let's speak in Japanese");
            }
            if(Language == 2){
                setKashi('我們來說說日語吧');
            }
            if(Language == 3){
                setKashi('जापानी भाषामा बोल्ने बानी बसालौँ।');
            }
            if(Language == 4){
                setKashi('අපි ජපන් භාෂාව කතා කරමු');
            }
            if(Language == 5){
                setKashi('ဂျပန်စကားပြောရအောင်');
            }
            if(Language == 6){
                setKashi('আসুন জাপানি কথা বলি');
            }
        }
        if(counter >= 3.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 13.7){
            if(Language == 0){
                setKashi('授業のときは、日本語だけで話してください。');
            }
            if(Language == 1){
                setKashi('Please speak only in Japanese during class.');
            }
            if(Language == 2){
                setKashi('上課時請只說日語。');
            }
            if(Language == 3){
                setKashi('कक्षाको समयमा, कृपया जापानी मात्र बोल्नुहोस्।');
            }
            if(Language == 4){
                setKashi('පන්තිය අතරතුර, කරුණාකර ජපන් භාෂාව පමණක් කතා කරන්න.');
            }
            if(Language == 5){
                setKashi('စာသင်ချိန်အတွင်း ဂျပန်စကားကိုသာ ပြောပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে ক্লাস চলাকালীন শুধুমাত্র জাপানি ভাষায় কথা বলুন।');
            }
        }
        if(counter >= 18.8){
            if(Language == 0){
                setKashi('国のことばで友達と話さないでください。');
            }
            if(Language == 1){
                setKashi("Don't talk to your friends in your country's language.");
            }
            if(Language == 2){
                setKashi('不要用您所在國家的語言與您的朋友交談。');
            }
            if(Language == 3){
                setKashi('आफ्ना साथीहरूसँग आफ्नो देशको भाषामा कुरा नगर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබේ රටේ භාෂාවෙන් ඔබේ මිතුරන් සමඟ කතා නොකරන්න.');
            }
            if(Language == 5){
                setKashi('သင့်တိုင်းပြည်၏ဘာသာစကားဖြင့် သင့်သူငယ်ချင်းများနှင့် စကားမပြောပါနှင့်။');
            }
            if(Language == 6){
                setKashi('আপনার বন্ধুদের সাথে আপনার দেশের ভাষায় কথা বলবেন না।');
            }
        }
        if(counter >= 25){
            if(Language == 0){
                setKashi('みなさん勉強していますから、うるさくしないでください。');
            }
            if(Language == 1){
                setKashi("Everyone is studying, so please don't make noise.");
            }
            if(Language == 2){
                setKashi('大家都在學習，請不要大驚小怪。');
            }
            if(Language == 3){
                setKashi('सबैजना पढिरहेका छन्, त्यसैले कृपया हल्ला नगर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('හැමෝම පාඩම් කරන නිසා කරුණාකරලා කලබල වෙන්න එපා.');
            }
            if(Language == 5){
                setKashi('အားလုံးလေ့လာနေတာမို့ စိတ်ရှုပ်မခံပါနဲ့။');
            }
            if(Language == 6){
                setKashi('সবাই পড়াশোনা করছে, তাই আওয়াজ করবেন না।');
            }
        }
        if(counter >= 31.6){
            if(Language == 0){
                setKashi('しつもんがあるときは、先生にしつもんしてください。');
            }
            if(Language == 1){
                setKashi('If you have any questions, please ask your teacher.');
            }
            if(Language == 2){
                setKashi('如果您有任何疑問，請詢問您的老師。');
            }
            if(Language == 3){
                setKashi('यदि तपाइँसँग कुनै प्रश्नहरू छन् भने, कृपया तपाइँको शिक्षकलाई सोध्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබට කිසියම් ප්‍රශ්නයක් ඇත්නම්, කරුණාකර ඔබේ ගුරුවරයාගෙන් විමසන්න.');
            }
            if(Language == 5){
                setKashi('မေးစရာများရှိရင် ဆရာ့ကို မေးပါ။');
            }
            if(Language == 6){
                setKashi('যদি আপনার কোন প্রশ্ন থাকে, অনুগ্রহ করে আপনার শিক্ষককে জিজ্ঞাসা করুন।');
            }
        }
        if(counter >= 37){
            if(Language == 0){
                setKashi('きゅうけいのときも日本語で話して、');
            }
            if(Language == 1){
                setKashi('Even during breaks, speak in Japanese,');
            }
            if(Language == 2){
                setKashi('即使休息的時候請日語交談，');
            }
            if(Language == 3){
                setKashi('विश्रामको समयमा पनि जापानी　भाषामा बोल्नुहोस्।');
            }
            if(Language == 4){
                setKashi('විවේක කාලයේදී පවා කතා කරන්න භාෂාවෙන්,');
            }
            if(Language == 5){
                setKashi('အားလပ်ချိန်မှာတောင် ဂျပန်လိုပြော၊');
            }
            if(Language == 6){
                setKashi('বিরতির সময়ও, আমরা জাপানি ভাষায় কথা বলে');
            }
        }
        if(counter >= 40.2){
            if(Language == 0){
                setKashi('ほかの国の友達をたくさん作ってください。');
            }
            if(Language == 1){
                setKashi('Make a lot of friends from other countries.');
            }
            if(Language == 2){
                setKashi('請結交很多來自其他國家的朋友。');
            }
            if(Language == 3){
                setKashi('कृपया अन्य  देशका साथीहरू धेरैभन्दा धेरै बनाउनुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර වෙනත් රටවලින් බොහෝ මිතුරන් ඇති කර ගන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးပြု၍ အခြားနိုင်ငံမှ သူငယ်ချင်းများ များများလုပ်ပါ။');
            }
            if(Language == 6){
                setKashi('অন্যান্য দেশ থেকে প্রচুর বন্ধু তৈরি করুন।');
            }
        }
        if(counter >= 44.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 47.3){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn MChBChange');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
        }
        // けいたい電話
        if(counter >= 48){
            if(Language == 0){
                setKashi('けいたい電話');
            }
            if(Language == 1){
                setKashi('mobile phone');
            }
            if(Language == 2){
                setKashi('智慧型手機');
            }
            if(Language == 3){
                setKashi('मोबाइल फोन');
            }
            if(Language == 4){
                setKashi('ජංගම දුරකථන');
            }
            if(Language == 5){
                setKashi('မိုဘိုင်းဖုန်း');
            }
            if(Language == 6){
                setKashi('মোবাইল ফোন');
            }
        }
        if(counter >= 50.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 62.5){
            if(Language == 0){
                setKashi('授業のとき、けいたい電話は');
            }
            if(Language == 1){
                setKashi('Do not use your mobile phone during class.');
            }
            if(Language == 2){
                setKashi('上課時請勿使用手機');
            }
            if(Language == 3){
                setKashi('कक्षाको समयमा आफ्नो सेल फोन प्रयोग नगर्नुहोस्');
            }
            if(Language == 4){
                setKashi('පන්ති අතරතුර ඔබගේ ජංගම දුරකථනය භාවිතා නොකරන්න');
            }
            if(Language == 5){
                setKashi('စာသင်ချိန်အတွင်း လက်ကိုင်ဖုန်းကို မသုံးပါနှင့်');
            }
            if(Language == 6){
                setKashi('ক্লাস চলাকালীন, ফোন চালু থাকে');
            }
        }
        if(counter >= 65){
            if(Language == 0){
                setKashi('マナーモードにして、');
            }
            if(Language == 1){
                setKashi('Set to silent mode,');
            }
            if(Language == 2){
                setKashi('設定為靜音模式，');
            }
            if(Language == 3){
                setKashi('मोबाइल साइलेन्ट मोडमा सेट गर्नुहोस्,');
            }
            if(Language == 4){
                setKashi('නිහඬ ප්‍රකාරයට සකසන්න,');
            }
            if(Language == 5){
                setKashi('အသံတိတ်မုဒ်သို့ သတ်မှတ်ရန်၊');
            }
            if(Language == 6){
                setKashi('নীরব মোডে সেট করুন,');
            }
        }
        if(counter >= 67){
            if(Language == 0){
                setKashi('かばんに入れて');
            }
            if(Language == 1){
                setKashi('Put it in your bag');
            }
            if(Language == 2){
                setKashi('把它放進您的包裡');
            }
            if(Language == 3){
                setKashi('आफ्नो झोला मा राख्नुहोस्');
            }
            if(Language == 4){
                setKashi('එය ඔබේ බෑගයට දමන්න');
            }
            if(Language == 5){
                setKashi('သင့်အိတ်ထဲတွင်ထည့်ပါ။');
            }
            if(Language == 6){
                setKashi('এটি আপনার ব্যাগে রাখুন');
            }
        }
        if(counter >= 69.3){
            if(Language == 0){
                setKashi('かばんをしめてください。');
            }
            if(Language == 1){
                setKashi('Please close your bag.');
            }
            if(Language == 2){
                setKashi('請關上您的包。');
            }
            if(Language == 3){
                setKashi('कृपया आफ्नो झोला बन्द गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබේ බෑගය වසා දමන්න.');
            }
            if(Language == 5){
                setKashi('သင့်အိတ်ကိုပိတ်ပါ။');
            }
            if(Language == 6){
                setKashi('আপনার ব্যাগ বন্ধ করুন.');
            }
        }
        if(counter >= 74){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 76.5){
            if(Language == 0){
                setKashi('けいたい電話はポケットに入れてはいけません。');
            }
            if(Language == 1){
                setKashi("Don't put your mobile phone in your pocket.");
            }
            if(Language == 2){
                setKashi('不要將手機放在口袋裡。');
            }
            if(Language == 3){
                setKashi('आफ्नो सेल फोन आफ्नो खल्ती मा नराख्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබගේ ජංගම දුරකථනය ඔබගේ සාක්කුවේ තබා නොගන්න.');
            }
            if(Language == 5){
                setKashi('လက်ကိုင်ဖုန်းကို အိတ်ကပ်ထဲ ထည့်မထားပါနဲ့။');
            }
            if(Language == 6){
                setKashi('আপনার পকেটে সেল ফোন বহন করবেন না।');
            }
        }
        if(counter >= 82.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 84.7){
            if(Language == 0){
                setKashi('授業中にけいたい電話を見ないでください。');
            }
            if(Language == 1){
                setKashi("Please don't look at your mobile phone during class.");
            }
            if(Language == 2){
                setKashi('上課時請不要看手機。');
            }
            if(Language == 3){
                setKashi('कृपया कक्षाको समयमा आफ्नो सेल फोन नहेर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර පන්ති අතරතුර ඔබගේ ජංගම දුරකථනය දෙස නොබලන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးပြုပြီး အတန်းချိန်အတွင်း သင့်လက်ကိုင်ဖုန်းကို မကြည့်ပါနဲ့။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে ক্লাস চলাকালীন টেলিফোনের দিকে তাকাবেন না।');
            }
        }
        if(counter >= 89){
            if(Language == 0){
                setKashi('先生がけいたい電話を使ってもいいと言ったときだけ、使ってもいいです。');
            }
            if(Language == 1){
                setKashi("You can only use your mobile phone when your teacher allows it.");
            }
            if(Language == 2){
                setKashi('只有在老師允許的情況下，您才可以使用手機。');
            }
            if(Language == 3){
                setKashi('तपाईले आफ्नो सेल फोन  प्रयोग गर्न सक्नुहुन्छ यदि तपाईको शिक्षकले तपाईलाई त्यसो गर्न अनुमति दिनुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබට ඔබේ ජංගම දුරකථනය භාවිත කළ හැක්කේ ඔබේ ගුරුවරයා ඔබට එසේ කිරීමට ඉඩ දෙන්නේ නම් පමණි.');
            }
            if(Language == 5){
                setKashi('မင်းရဲ့ဆရာက မင်းကိုခွင့်ပြုမှသာလျှင် မင်းရဲ့ဆဲလ်ဖုန်းကို သုံးနိုင်တယ်။');
            }
            if(Language == 6){
                setKashi('আপনি সেল ফোন ব্যবহার করতে পারেন শুধুমাত্র যদি আপনার শিক্ষক বলেন এটা ঠিক আছে।');
            }
        }
        if(counter >= 95.5){
            if(Language == 0){
                setKashi('もし、けいたい電話を見たら');
            }
            if(Language == 1){
                setKashi('If you are seen using mobile phone,');
            }
            if(Language == 2){
                setKashi('如果您看看您的手機，');
            }
            if(Language == 3){
                setKashi('यदि सेलफोन हेर्नुभयो भने,');
            }
            if(Language == 4){
                setKashi('ඔබ ඔබේ ජංගම දුරකථනය දෙස බැලුවහොත්,');
            }
            if(Language == 5){
                setKashi('စာသင်မချိန်တွင်း လက်ကိုင်ဖုန်းကို ကြည့်လိုက်ရင်');
            }
            if(Language == 6){
                setKashi('আপনি যদি একটি সেল ফোন দেখতে পান,');
            }
        }
        if(counter >= 98.5){
            if(Language == 0){
                setKashi('授業がおわるまで');
            }
            if(Language == 1){
                setKashi('Until class ends');
            }
            if(Language == 2){
                setKashi('直到下課');
            }
            if(Language == 3){
                setKashi('कक्षा समाप्त नभएसम्म');
            }
            if(Language == 4){
                setKashi('පන්තිය අවසන් වන තුරු');
            }
            if(Language == 5){
                setKashi('အတန်းပြီးသည်အထိ');
            }
            if(Language == 6){
                setKashi('ক্লাস শেষ না হওয়া পর্যন্ত');
            }
        }
        if(counter >= 100){
            if(Language == 0){
                setKashi('先生がけいたい電話をあずかります。');
            }
            if(Language == 1){
                setKashi('The teacher will take away the mobile phone.');
            }
            if(Language == 2){
                setKashi('老師會保留你的手機。');
            }
            if(Language == 3){
                setKashi('शिक्षकले तपाईको सेल फोन राख्नेछन्।');
            }
            if(Language == 4){
                setKashi('ගුරුවරයා ඔබේ ජංගම දුරකථනය තබා ගනීවි.');
            }
            if(Language == 5){
                setKashi('ဆရာက မင်းရဲ့လက်ကိုင်ဖုန်းကို သိမ်းထားလိမ့်မယ်။');
            }
            if(Language == 6){
                setKashi('আপনার শিক্ষক আপনার সেল ফোন অফিসে নিয়ে যাবেন।');
            }
        }
        if(counter >= 104.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 108){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn MChBChange');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
        }
        // 飲食
        if(counter >= 108.7){
            if(Language == 0){
                setKashi('飲食');
            }
            if(Language == 1){
                setKashi('Eating and drinking');
            }
            if(Language == 2){
                setKashi('飲食');
            }
            if(Language == 3){
                setKashi('खाने र पिउने');
            }
            if(Language == 4){
                setKashi('කනවා බොනවා');
            }
            if(Language == 5){
                setKashi('စားသောက်ခြင်း။');
            }
            if(Language == 6){
                setKashi('খাওয়া-দাওয়া');
            }
        }
        if(counter >= 112){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 125){
            if(Language == 0){
                setKashi('教室で飲み物は飲んでもいいです。');
            }
            if(Language == 1){
                setKashi('You may drink in the classroom.');
            }
            if(Language == 2){
                setKashi('你可以在教室裡喝飲料。');
            }
            if(Language == 3){
                setKashi('तपाईंले कक्षाकोठामा पेय पदार्थ पिउन सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබට පන්ති කාමරයේ බීම පානය කළ හැකිය.');
            }
            if(Language == 5){
                setKashi('စာသင်ခန်းထဲမှာ အချိုရည်တွေ သောက်နိုင်တယ်။');
            }
            if(Language == 6){
                setKashi('আপনি ক্লাসরুমে পানীয় পান করতে পারেন।');
            }
        }
        if(counter >= 130){
            if(Language == 0){
                setKashi('でも、食べ物はだめです。');
            }
            if(Language == 1){
                setKashi('But no food.');
            }
            if(Language == 2){
                setKashi('但食物不好。');
            }
            if(Language == 3){
                setKashi('तर खान पाइँदैन।');
            }
            if(Language == 4){
                setKashi('නමුත් කෑම ඡාති කන්න බැහැ.');
            }
            if(Language == 5){
                setKashi('ဒါပေမယ့် အစားအသောက်က မကောင်းဘူး။');
            }
            if(Language == 6){
                setKashi('তবে খাবার নিষিদ্ধ।');
            }
        }
        if(counter >= 135){
            if(Language == 0){
                setKashi('あめ、ガム、フリスクはだめです。');
            }
            if(Language == 1){
                setKashi('No candy, gum, or frisk.');
            }
            if(Language == 2){
                setKashi('不要吃口香糖或糖果。');
            }
            if(Language == 3){
                setKashi('चकलेट，चुइगम र फ्रिस्क पनि खान पाइँदैन।');
            }
            if(Language == 4){
                setKashi('කැන්ඩි, චුයින්ගම් සහ ෆ්‍රිස්ක් වලට අවසර නැත.');
            }
            if(Language == 5){
                setKashi('သကြားလုံး၊ ပီကေနှင့် frisk တို့ကို ခွင့်မပြုပါ။');
            }
            if(Language == 6){
                setKashi('ক্যান্ডি, গাম এবং ফ্রিস্কিও নিষিদ্ধ।');
            }
        }
        if(counter >= 140.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 143.7){
            if(Language == 0){
                setKashi('3Fロビーがあります。');
            }
            if(Language == 1){
                setKashi('There is a lobby on the 3rd floor.');
            }
            if(Language == 2){
                setKashi('3樓設有大堂。');
            }
            if(Language == 3){
                setKashi('तेस्रो तल्लामा लबी छ।');
            }
            if(Language == 4){
                setKashi('3 වන මහලේ විවේක කාමරය ඇත.');
            }
            if(Language == 5){
                setKashi('တတိယထပ်တွင် ဧည့်ခန်းတစ်ခုရှိသည်။');
            }
            if(Language == 6){
                setKashi('3য় তলায় একটি লবি আছে।');
            }
        }
        if(counter >= 147.5){
            if(Language == 0){
                setKashi('3Fロビーで食べてもいいです。');
            }
            if(Language == 1){
                setKashi('You can eat in the 3rd floor lobby.');
            }
            if(Language == 2){
                setKashi('您也可以在三樓大廳用餐。');
            }
            if(Language == 3){
                setKashi('तपाईं तेस्रो तल्लाको लबीमा  खान सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi(' ඔබට 3 වන මහලේ කාමරයේ  ආහාර ගත හැකිය.');
            }
            if(Language == 5){
                setKashi('3rd floor lobby မှာလည်း စားလို့ရပါတယ်။');
            }
            if(Language == 6){
                setKashi('আপনি 3য় তলার লবিতে খেতে পারেন।');
            }
        }
        if(counter >= 152){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 153.3){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn MChBChange');
            setChapterColor5('MoveChapBtn');
        }
        // ぼうし・うわぎ
        if(counter >= 154){
            if(Language == 0){
                setKashi('ぼうし・うわぎ');
            }
            if(Language == 1){
                setKashi('hat/coat');
            }
            if(Language == 2){
                setKashi('帽子/外套');
            }
            if(Language == 3){
                setKashi('टोपी/कोट/ज्याकेट');
            }
            if(Language == 4){
                setKashi('තොප්පිය / කබාය');
            }
            if(Language == 5){
                setKashi('ဦးထုပ်/အင်္ကျီ');
            }
            if(Language == 6){
                setKashi('টুপি/কোট');
            }
        }
        if(counter >= 157){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 165){
            if(Language == 0){
                setKashi('教室で、ぼうしをかぶらないでください。');
            }
            if(Language == 1){
                setKashi('Please do not wear a hat in the classroom.');
            }
            if(Language == 2){
                setKashi('請不要在教室裡戴帽子。');
            }
            if(Language == 3){
                setKashi('कृपया कक्षाकोठामा टोपी नलगाउनुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර පන්ති කාමරය තුළ හිස් වැසුම් පැළඳීමෙන් වළකින්න.');
            }
            if(Language == 5){
                setKashi('စာသင်ခန်းထဲမှာ ဦးထုပ်မဆောင်းပါနဲ့။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে শ্রেণীকক্ষে টুপি পরবেন না।');
            }
        }
        if(counter >= 171.5){
            if(Language == 0){
                setKashi('マフラーもとってください。');
            }
            if(Language == 1){
                setKashi('Please take off your scarf as well.');
            }
            if(Language == 2){
                setKashi('請同時帶上消音器。');
            }
            if(Language == 3){
                setKashi('कृपया मफलर पनि नलगाउनुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර මෆුරා පැලදීමෙන් වලකින්න.');
            }
            if(Language == 5){
                setKashi('မာဖလာကိုလည်း ချွတ်ထားပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে মাফলারটিও নিন।');
            }
        }
        if(counter >= 178.5){
            if(Language == 0){
                setKashi('コートもぬいでください。');
            }
            if(Language == 1){
                setKashi('Please take off your coat too.');
            }
            if(Language == 2){
                setKashi('還請脫掉外套。');
            }
            if(Language == 3){
                setKashi('कृपया आफ्नो कोट पनि फुकाल्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබේ කබායත් ගලවන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးပြုပြီး အနွေးထည်ကိုချွတ်ထားပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে আপনার কোটও খুলে ফেলুন।');
            }
        }
        if(counter >= 188.3){
            if(Language == 0){
                setKashi('日本では、学校や家の中で');
            }
            if(Language == 1){
                setKashi('In Japan, in school or at home');
            }
            if(Language == 2){
                setKashi('在日本，在學校或家裡');
            }
            if(Language == 3){
                setKashi('जापानमा स्कूलमा वा घरमा');
            }
            if(Language == 4){
                setKashi('ජපානයේ, පාසැලේදී හෝ නිවසේදී ඇතුලදී');
            }
            if(Language == 5){
                setKashi('ဂျပန်မှာ၊ ကျောင်းမှာပဲဖြစ်ဖြစ်၊ အိမ်မှာ');
            }
            if(Language == 6){
                setKashi('জাপানে, স্কুলে বা বাড়িতে');
            }
        }
        if(counter >= 192){
            if(Language == 0){
                setKashi('ぼうしをかぶったり、コートを着たりするのはしつれいです。');
            }
            if(Language == 1){
                setKashi('It is rude to wear a hat or a coat.');
            }
            if(Language == 2){
                setKashi('戴帽子或穿外套是不禮貌的。');
            }
            if(Language == 3){
                setKashi('टोपी वा कोट लगाउनु अशिष्ट हो।');
            }
            if(Language == 4){
                setKashi('තොප්පියක් හෝ කබායක් පැළඳීම අශිෂ්ටයි.');
            }
            if(Language == 5){
                setKashi('ဦးထုပ် သို့မဟုတ် ကုတ်အင်္ကျီကို ဝတ်ဆင်ခြင်းသည် ယဉ်ကျေးမှုနှင့်မကိုက်ညီပါ။');
            }
            if(Language == 6){
                setKashi('টুপি বা কোট পরা কঠিন।');
            }
        }
        if(counter >= 197){
            if(Language == 0){
                setKashi('寒いときは、中にたくさん服を着てください。');
            }
            if(Language == 1){
                setKashi("When it's cold, wear lots of clothes inside.");
            }
            if(Language == 2){
                setKashi('天冷的時候，在室內多穿點衣服。');
            }
            if(Language == 3){
                setKashi('चिसो मौसममा भित्र धेरै लुगाहरू लगाउनुहोस्।');
            }
            if(Language == 4){
                setKashi('එය සීතල වන විට, ඇතුළත ඇඳුම් ගොඩක් අඳින්න.');
            }
            if(Language == 5){
                setKashi('အအေးမိတဲ့အခါ အထဲမှာ အဝတ်များများဝတ်ပါ။');
            }
            if(Language == 6){
                setKashi('যখন ঠাণ্ডা হয়, তখন ভিতরে প্রচুর কাপড় পরুন।');
            }
        }
        if(counter >= 201.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 204.3){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn MChBChange');
        }
        // しせい
        if(counter >= 205){
            if(Language == 0){
                setKashi('しせい');
            }
            if(Language == 1){
                setKashi('posture');
            }
            if(Language == 2){
                setKashi('姿勢');
            }
            if(Language == 3){
                setKashi('आसन');
            }
            if(Language == 4){
                setKashi('ඉරියව්ව');
            }
            if(Language == 5){
                setKashi('ကိုယ်ဟန်အနေအထား');
            }
            if(Language == 6){
                setKashi('ভঙ্গি');
            }
        }
        if(counter >= 208){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 215){
            if(Language == 0){
                setKashi('うでをくんではいけません。');
            }
            if(Language == 1){
                setKashi("Don't cross your arms.");
            }
            if(Language == 2){
                setKashi('不要交叉雙臂。');
            }
            if(Language == 3){
                setKashi('आफ्नो हात हात बाँधेर नबसौँ।');
            }
            if(Language == 4){
                setKashi('ඔබේ දෑත් හරස් නොකරන්න.');
            }
            if(Language == 5){
                setKashi('လက်ပိုက်ထားခြင်းမပြုလုပ်ရန်။');
            }
            if(Language == 6){
                setKashi('আপনার বাহু ভাঁজ করবেন না');
            }
        }
        if(counter >= 220){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 221){
            if(Language == 0){
                setKashi('足をくんではいけません。');
            }
            if(Language == 1){
                setKashi('Do not cross your legs.');
            }
            if(Language == 2){
                setKashi('不要交叉雙腿。');
            }
            if(Language == 3){
                setKashi('आफ्नो  खुट्टा उपरखुट्टी लाएर　नबसौँ।');
            }
            if(Language == 4){
                setKashi('කකුලක් උඩින් කකුලක් තබා වාඩි නොවන්න.');
            }
            if(Language == 5){
                setKashi('သင်၏ခြေထောက်များကို တစ်ဖက်နှင့်တစ်ဖက်တင်ထားခြင်းမပြုလုပ်ရန်။');
            }
            if(Language == 6){
                setKashi('পায়ের উপর পা তুলে বসা যাবে না');
            }
        }
        if(counter >= 224.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 225.3){
            if(Language == 0){
                setKashi('かべによりかかってはいけません。');
            }
            if(Language == 1){
                setKashi('Do not lean on walls.');
            }
            if(Language == 2){
                setKashi('不要靠在牆上。');
            }
            if(Language == 3){
                setKashi('भित्तामा अडेस लाएर　नबसौँ।');
            }
            if(Language == 4){
                setKashi('බිත්තියට හේත්තු නොවන්න.');
            }
            if(Language == 5){
                setKashi('နံရံကို မမှီပါနှင့်။');
            }
            if(Language == 6){
                setKashi('দেয়ালে হেলান দেবেন না।');
            }
        }
        if(counter >= 231){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 232.4){
            if(Language == 0){
                setKashi('きれいにすわってください。');
            }
            if(Language == 1){
                setKashi('Please sit properly.');
            }
            if(Language == 2){
                setKashi('請坐好。');
            }
            if(Language == 3){
                setKashi('कृपया सही तरिकाले बस्ने बानी बसालौँ।');
            }
            if(Language == 4){
                setKashi('කරුණාකර හොඳින් වාඩි වන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးပြုပြီး တည့်တည့်မတ်မတ်ထိုင်ရန်။');
            }
            if(Language == 6){
                setKashi('দয়া করে সুন্দরভাবে বসুন।');
            }
        }
        if(counter >= 237.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 239.5){
            if(Language == 0){
                setKashi('ほおづえをついてはいけません。');
            }
            if(Language == 1){
                setKashi("Don't rest your chin.");
            }
            if(Language == 2){
                setKashi('不要靠在下巴上。');
            }
            if(Language == 3){
                setKashi('कुइना टेकेर चिउडोमा हात राखेर नबसौँ।');
            }
            if(Language == 4){
                setKashi('ඔබේ නිකට මතට  අත තබා නොසිටින්න.');
            }
            if(Language == 5){
                setKashi('မေးစေ့ထောက်၍မနေရန်။');
            }
            if(Language == 6){
                setKashi('গালে হাত রেখে বসা যাবে না');
            }
        }
        if(counter >= 244.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 246.3){
            if(Language == 0){
                setKashi('寝てはいけません。');
            }
            if(Language == 1){
                setKashi("Don't sleep.");
            }
            if(Language == 2){
                setKashi('別睡覺。');
            }
            if(Language == 3){
                setKashi('कक्षा कोठामा सुत्न पाइँदैन।');
            }
            if(Language == 4){
                setKashi('නිදාගන්න එපා.');
            }
            if(Language == 5){
                setKashi('မအိပ်ပါနဲ့။');
            }
            if(Language == 6){
                setKashi('ঘুমাবেন না।');
            }
        }
        if(counter >= 250.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 253){
            if(Language == 0){
                setKashi('ポケットに手を入れてはいけません。');
            }
            if(Language == 1){
                setKashi("Don't put your hands in your pockets.");
            }
            if(Language == 2){
                setKashi('不要將手放入口袋中。');
            }
            if(Language == 3){
                setKashi('आफ्नो खल्ती मा  हात नराख्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබේ අත් සාක්කුවලට දමන්න එපා.');
            }
            if(Language == 5){
                setKashi('လက်ကို အိတ်ကပ်ထဲ မထည့်ပါနဲ့။');
            }
            if(Language == 6){
                setKashi('আপনার পকেটে হাত রাখবেন না।');
            }
        }
        if(counter >= 256.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
    }

    const timeSetMsg1 = () => {
        document.getElementById('video').currentTime = 0;
    }
    const timeSetMsg2 = () => {
        document.getElementById('video').currentTime = 48;
    }
    const timeSetMsg3 = () => {
        document.getElementById('video').currentTime = 108;
    }
    const timeSetMsg4 = () => {
        document.getElementById('video').currentTime = 154;
    }
    const timeSetMsg5 = () => {
        document.getElementById('video').currentTime = 205;
    }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            setLogInNo(LogInNo);
            navigate('/');
        }
    })

    const HomeReset = () => {
        setTimeVI(0);
        setLogInNo(LogInNo);
        navigate('/');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <img src={Titleimg} className='MenuLogo' onClick={() => {HomeReset()}}/>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                        <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                        <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                        <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>Chp4</div>
                        <div className={ChapterColor5} onClick={() => {timeSetMsg5()}}>Chp5</div>
                    </div>
                    <video src={Ruhr} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                    <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                    <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                    <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>Chp4</div>
                    <div className={ChapterColor5} onClick={() => {timeSetMsg5()}}>Chp5</div>
                </div>
            </div>
        </div>
    )
}