import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import Tour from '../Images/Tour.mp4';

export default function InsideSchoolMove ({ setLanguage, Language, isAuth, setIsAuth, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        // 学内ツアー
        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
        }
        if(counter >= 7){
            if(Language == 0){
                setKashi('NSA日本語学校学内ツアー動画');
            }
            if(Language == 1){
                setKashi('NSA Japanese school campus tour video');
            }
            if(Language == 2){
                setKashi('NSA日本語學校內部參觀視頻');
            }
            if(Language == 3){
                setKashi('NSA जापानीज स्कूल आन्तरिक भ्रमण भिडियो');
            }
            if(Language == 4){
                setKashi('NSA ජපන් පාසල් අභ්‍යන්තර චාරිකා වීඩියෝව');
            }
            if(Language == 5){
                setKashi('NSA ဂျပန်ကျောင်းတွင်း ခရီးသွားဗီဒီယို');
            }
            if(Language == 6){
                setKashi('NSA জাপানি স্কুল ক্যাম্পাস ট্যুর ভিডিও');
            }
        }
        if(counter >= 11){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 26.5){
            if(Language == 0){
                setKashi('駐輪場(ちゅうりんじょう)、ここに自転車をきれいにならべておいてください。');
            }
            if(Language == 1){
                setKashi('Bicycle parking lot Please line up your bicycles neatly.');
            }
            if(Language == 2){
                setKashi('自行車停車場、請在此整齊地排列自行車。');
            }
            if(Language == 3){
                setKashi('साइकल पार्किङ स्थल कृपया यहाँ आफ्नो साइकलहरू क्रमबद्ध रूपमा मिलाएर राख्नुहोस्।');
            }
            if(Language == 4){
                setKashi('බයිසිකල් නැවැත්වීමේ ස්ථානය කරුණාකර ඔබේ බයිසිකල් මෙහි පිළිවෙලට පෙළගස්වන්න.');
            }
            if(Language == 5){
                setKashi('စက်ဘီးပါကင် သင့်စက်ဘီးများကို ဤနေရာတွင် သေသေသပ်သပ် တန်းစီပါ။');
            }
            if(Language == 6){
                setKashi('সাইকেল পার্কিং লট এখানে সুন্দরভাবে আপনার সাইকেল লাইন আপ করুন.');
            }
        }
        if(counter >= 28.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 33){
            if(Language == 0){
                setKashi('自動販売機(じどうはんばいき)、飲み物を買うことができます。');
            }
            if(Language == 1){
                setKashi('Vending machine You can buy drinks.');
            }
            if(Language == 2){
                setKashi('自動販賣機、您可以買飲料。');
            }
            if(Language == 3){
                setKashi('भेन्डिङ मेसिन यहाँ तपाईँहरू पिय पदार्थ किन्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('විකුණුම් යන්ත්රය ඔබට බීම මිලදී ගත හැකිය.');
            }
            if(Language == 5){
                setKashi('အလိုအလျောက် စျေးရောင်းစက်မှာသောက်စရာတွေ ဝယ်လို့ရတယ်။');
            }
            if(Language == 6){
                setKashi('ভেন্ডিং মেশিন (জিডো হানবাইকি) আপনি পানীয় কিনতে পারেন.');
            }
        }
        if(counter >= 34.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 56.5){
            if(Language == 0){
                setKashi('2Fでくつをぬいで、スリッパをはいてください。');
            }
            if(Language == 1){
                setKashi('Please take off your shoes and put on slippers on the 2nd floor.');
            }
            if(Language == 2){
                setKashi('上二樓請脫鞋換上拖鞋。');
            }
            if(Language == 3){
                setKashi('दोस्रो तल्लामा पुगेसी जुत्ता खोल्नुहोस् र चप्पल लगाएर भित्र छिर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබේ සපත්තු ගලවා 2 වන මහලේ සෙරෙප්පු දමාගන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးပြုပြီး ဖိနပ်ချွတ်ပြီး ဒုတိယထပ်မှာ ဖိနပ်တစ်ရံ ဝတ်ပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে আপনার জুতা খুলে ২য় তলায় চপ্পল পরুন।');
            }
        }
        if(counter >= 59.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 78.5){
            if(Language == 0){
                setKashi('保健室(ほけんしつ)、体の調子が悪いときに休みます。');
            }
            if(Language == 1){
                setKashi("Health room You can take a break when you don't feel well.");
            }
            if(Language == 2){
                setKashi('保健室、當您感覺不舒服時我會休息一下。');
            }
            if(Language == 3){
                setKashi('स्वास्थ्य कक्ष स्वास्थ्य स्थिति नराम्रो महशुस भएको बेला यहाँ आराम गर्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('සෞඛ්ය කාමරය මට සනීප නැති වෙලාවට මම විවේක ගන්නවා.');
            }
            if(Language == 5){
                setKashi('ကျန်းမာရေးအခန်း အဆင်မပြေတဲ့အခါ အနားယူပါ။');
            }
            if(Language == 6){
                setKashi('স্বাস্থ্য কক্ষ আমি যখন ভাল বোধ করি না তখন আমি বিরতি নিই।');
            }
        }
        if(counter >= 80){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 82.5){
            if(Language == 0){
                setKashi('第一(だいいち)教室');
            }
            if(Language == 1){
                setKashi('lassroom 1');
            }
            if(Language == 2){
                setKashi('第一教室');
            }
            if(Language == 3){
                setKashi('कक्षाकोठा नम्बर १');
            }
            if(Language == 4){
                setKashi('පන්ති කාමර අංක 1');
            }
            if(Language == 5){
                setKashi('စာသင်ခန်းအမှတ် ၁');
            }
            if(Language == 6){
                setKashi('প্রথম শ্রেণীকক্ষ');
            }
        }
        if(counter >= 85.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 96.8){
            if(Language == 0){
                setKashi('第二(だいに)教室');
            }
            if(Language == 1){
                setKashi('Classroom 2');
            }
            if(Language == 2){
                setKashi('第二間教室');
            }
            if(Language == 3){
                setKashi('कक्षाकोठा नम्बर २');
            }
            if(Language == 4){
                setKashi('දෙවන පන්ති කාමරය');
            }
            if(Language == 5){
                setKashi('ဒုတိယစာသင်ခန်း');
            }
            if(Language == 6){
                setKashi('দ্বিতীয় শ্রেণীকক্ষ');
            }
        }
        if(counter >= 99.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 104.7){
            if(Language == 0){
                setKashi('女子トイレ');
            }
            if(Language == 1){
                setKashi("Women's restroom");
            }
            if(Language == 2){
                setKashi('女廁');
            }
            if(Language == 3){
                setKashi('महिला शौचालय');
            }
            if(Language == 4){
                setKashi('කාන්තා වැසිකිළිය');
            }
            if(Language == 5){
                setKashi('အမျိုးသမီးအိမ်သာ');
            }
            if(Language == 6){
                setKashi('মহিলাদের টয়লেট');
            }
        }
        if(counter >= 106.1){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 110){
            if(Language == 0){
                setKashi('男子トイレ');
            }
            if(Language == 1){
                setKashi("Men's restroom");
            }
            if(Language == 2){
                setKashi('男洗手間');
            }
            if(Language == 3){
                setKashi('पुरुषको शौचालय');
            }
            if(Language == 4){
                setKashi('පිරිමි වැසිකිළිය');
            }
            if(Language == 5){
                setKashi('အမျိုးသားသန့်စင်ခန်း');
            }
            if(Language == 6){
                setKashi('পুরুষদের বিশ্রামাগার');
            }
        }
        if(counter >= 111.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 121.8){
            if(Language == 0){
                setKashi('事務所(じむしょ)、事務所の先生がいます。');
            }
            if(Language == 1){
                setKashi('School office There are staffs at the office.');
            }
            if(Language == 2){
                setKashi('辦公室、辦公室裡有一位老師。');
            }
            if(Language == 3){
                setKashi('कार्यालय अफिसमा शिक्षकहरू हुनुहुन्छ।');
            }
            if(Language == 4){
                setKashi('කාර්යාල ඔෆිස් එකේ ගුරැවරැ ඉන්නවා.');
            }
            if(Language == 5){
                setKashi('ရုံး ရုံးမှာ ဆရာတစ်ယောက်ရှိတယ်။');
            }
            if(Language == 6){
                setKashi('অফিস অফিসে একজন শিক্ষক আছেন।');
            }
        }
        if(counter >= 124.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 148){
            if(Language == 0){
                setKashi('3階 受付');
            }
            if(Language == 1){
                setKashi('3rd floor reception');
            }
            if(Language == 2){
                setKashi('三樓接待處');
            }
            if(Language == 3){
                setKashi('तेस्रो तल्लाको हाजिरी गर्ने ठाउँ');
            }
            if(Language == 4){
                setKashi('3 වන මහල පිළිගැනීම');
            }
            if(Language == 5){
                setKashi('တတိယထပ်ဧည့်ကြိုကောင်တာ');
            }
            if(Language == 6){
                setKashi('তলায় অভ্যর্থনা');
            }
        }
        if(counter >= 149.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 152){
            if(Language == 0){
                setKashi('3階 ロビー、きゅうけいのとき、ここで食べることができます。');
            }
            if(Language == 1){
                setKashi('3rd floor lobby You can eat here during your break time.');
            }
            if(Language == 2){
                setKashi('三樓大堂、休息的時候可以在這裡吃飯。');
            }
            if(Language == 3){
                setKashi('तेस्रो तल्लाको लबी तपाईं आफ्नो विश्राम समयमा यहाँ खाना खान सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('3 වන මහලේ ලොබිය විවේක කාලයේදී ඔබට මෙහි ආහාර ගත හැකිය.');
            }
            if(Language == 5){
                setKashi('တတိယထပ် ဧည့်ခန်း အားလပ်ချိန်မှာ ဒီမှာအစားစာ စားလို့ရပါတယ်။');
            }
            if(Language == 6){
                setKashi('3য় তলার লবি আপনি Kyukei সময় এখানে খেতে পারেন.');
            }
        }
        if(counter >= 154){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 160){
            if(Language == 0){
                setKashi('電子レンジを使ってもいいです。きれいに使ってください。');
            }
            if(Language == 1){
                setKashi('You can use the microwave. Please use it neatly.');
            }
            if(Language == 2){
                setKashi('您也可以使用微波爐。請謹慎使用。');
            }
            if(Language == 3){
                setKashi('तपाईं माइक्रोवेव पनि प्रयोग गर्न सक्नुहुन्छ। कृपया यसलाई सावधानीपूर्वक प्रयोग गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබට මයික්‍රෝවේව් උදුනද භාවිතා කළ හැකිය. කරුණාකර එය ප්රවේශමෙන් භාවිතා කරන්න.');
            }
            if(Language == 5){
                setKashi('မိုက်ခရိုဝေ့ကိုလည်း သုံးနိုင်ပါတယ်။ ဂရုတစိုက်အသုံးပြုပါ။');
            }
            if(Language == 6){
                setKashi('আপনি মাইক্রোওয়েভ ব্যবহার করতে পারেন। সাবধানে এটি ব্যবহার করুন.');
            }
        }
        if(counter >= 161.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 164.2){
            if(Language == 0){
                setKashi('3F 男子トイレ・女子トイレ');
            }
            if(Language == 1){
                setKashi("3F Men's and Women's Restrooms");
            }
            if(Language == 2){
                setKashi('3F 男女洗手間');
            }
            if(Language == 3){
                setKashi('तेस्रो तल्लाको पुरुष र महिला शौचालय');
            }
            if(Language == 4){
                setKashi('3F පිරිමි සහ කාන්තා වැසිකිලිය');
            }
            if(Language == 5){
                setKashi('3F အမျိုးသားနှင့် အမျိုးသမီး သန့်စင်ခန်းများ');
            }
            if(Language == 6){
                setKashi('পুরুষ ও মহিলাদের বিশ্রামাগার');
            }
        }
        if(counter >= 166.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 171.5){
            if(Language == 0){
                setKashi('3階 B教室');
            }
            if(Language == 1){
                setKashi('3rd floor Classroom B');
            }
            if(Language == 2){
                setKashi('三樓B教室');
            }
            if(Language == 3){
                setKashi('तेस्रो तल्लाको कक्षाकोठा B');
            }
            if(Language == 4){
                setKashi('3 වන මහලේ පන්ති කාමරය B');
            }
            if(Language == 5){
                setKashi('တတိယထပ် စာသင်ခန်း B');
            }
            if(Language == 6){
                setKashi('3য় তলায় শ্রেণীকক্ষ B');
            }
        }
        if(counter >= 173.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 189.5){
            if(Language == 0){
                setKashi('3階 C教室');
            }
            if(Language == 1){
                setKashi('3rd floor Classroom C');
            }
            if(Language == 2){
                setKashi('三樓C教室');
            }
            if(Language == 3){
                setKashi('तेस्रो तल्लाको कक्षाकोठा C');
            }
            if(Language == 4){
                setKashi('3 වන මහලේ පන්තිකාමරය C');
            }
            if(Language == 5){
                setKashi('တတိယထပ် စာသင်ခန်း C');
            }
            if(Language == 6){
                setKashi('3য় তলায় ক্লাসরুম সি');
            }
        }
        if(counter >= 191.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 206){
            if(Language == 0){
                setKashi('3階 D教室');
            }
            if(Language == 1){
                setKashi('3rd floor Classroom D');
            }
            if(Language == 2){
                setKashi('三樓D教室');
            }
            if(Language == 3){
                setKashi('तेस्रो तल्लाको कक्षा कोठा D');
            }
            if(Language == 4){
                setKashi('3 වන මහලේ පන්ති කාමරය D');
            }
            if(Language == 5){
                setKashi('တတိယထပ် စာသင်ခန်း D');
            }
            if(Language == 6){
                setKashi('3য় তলায় ক্লাসরুম ডি');
            }
        }
        if(counter >= 207.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 225){
            if(Language == 0){
                setKashi('ここで食べてもいいです。');
            }
            if(Language == 1){
                setKashi('You can eat here.');
            }
            if(Language == 2){
                setKashi('您可以在這裡吃飯。');
            }
            if(Language == 3){
                setKashi('तपाईं यहाँ खान सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('මෙතන සිට  කෑම කන්න පුළුවන්.');
            }
            if(Language == 5){
                setKashi('ဒီမှာစားလို့ရပါတယ်။');
            }
            if(Language == 6){
                setKashi('আপনি এখানে খেতে পারেন।');
            }
        }
        if(counter >= 226.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 230){
            if(Language == 0){
                setKashi('ここにもトイレがあります。男の人と女の人がいっしょに使うトイレです。');
            }
            if(Language == 1){
                setKashi('There is also a toilet here. This is a restroom for men and women.');
            }
            if(Language == 2){
                setKashi('這裡還有一個廁所。這是男女共同使用的廁所。');
            }
            if(Language == 3){
                setKashi('यहाँ शौचालय पनि छ। यो महिला र पुरुष मिलेर प्रयोग गर्ने शौचालय हो।');
            }
            if(Language == 4){
                setKashi('මෙහි වැසිකිළියක් ද ඇත. මෙය ගැහැණු පිරිමි එකට භාවිතා කරන වැසිකිළියකි.');
            }
            if(Language == 5){
                setKashi('ဒီမှာ အိမ်သာလည်းရှိတယ်။ ဒီအိမ်သာက အမျိုးသားရော အမျိုးသမီးပါ တွဲသုံးတဲ့ အိမ်သာပါ။');
            }
            if(Language == 6){
                setKashi('এখানে একটি টয়লেটও আছে। এটি একটি টয়লেট যা পুরুষ এবং মহিলারা একসাথে ব্যবহার করেন।');
            }
        }
        if(counter >= 232.1){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 236.2){
            if(Language == 0){
                setKashi('日本語の本やマンガがたくさんあります。きゅうけいのとき、読んでもいいです。');
            }
            if(Language == 1){
                setKashi('There are many Japanese books and manga. You can read them during your break time.');
            }
            if(Language == 2){
                setKashi('有很多日本書籍和漫畫。您可以在休息時間閱讀。');
            }
            if(Language == 3){
                setKashi('यँहा धेरै जापानी पुस्तकहरू र मंगा छन्। तपाइँ यसलाई  ब्रेक को समयमा पढ्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ජපන් පොත් සහ මංගා ගොඩක් තියෙනවා. ඔබේ විවේක කාලය තුළ ඔබට එය කියවිය හැකිය.');
            }
            if(Language == 5){
                setKashi('ဂျပန်စာအုပ်တွေနဲ့ မန်ဂါတွေ အများကြီးရှိတယ်။ အားလပ်ချိန်မှာ ဖတ်နိုင်ပါတယ်။');
            }
            if(Language == 6){
                setKashi('অনেক জাপানি বই এবং মাঙ্গা আছে। আপনি যখন মজা করছেন তখন আপনি এটি পড়তে পারেন।');
            }
        }
        if(counter >= 238.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 249){
            if(Language == 0){
                setKashi('学内ツアー動画END...');
            }
            if(Language == 1){
                setKashi('Campus tour video END...');
            }
            if(Language == 2){
                setKashi('校園參觀影片完...');
            }
            if(Language == 3){
                setKashi('स्कूल भ्रमण भिडियो END...');
            }
            if(Language == 4){
                setKashi('කැම්පස් චාරිකා වීඩියෝව අවසන්...');
            }
            if(Language == 5){
                setKashi('ကျောင်းတွင်း ခရီးသွားဗီဒီယို အဆုံး...');
            }
            if(Language == 6){
                setKashi('ক্যাম্পাস ট্যুর ভিডিও শেষ...');
            }
        }
    }

    const timeSetMsg1 = () => {
        document.getElementById('video').currentTime = 0;
    }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            setLogInNo(LogInNo);
            navigate('/');
        }
    })

    const HomeReset = () => {
        setLogInNo(LogInNo);
        navigate('/');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <img src={Titleimg} className='MenuLogo' onClick={() => {HomeReset()}}/>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className='MoveChapBtn MChBChange' onClick={() => {timeSetMsg1()}}>Chp1</div>
                    </div>
                    <video src={Tour} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className='MoveChapBtn MChBChange' onClick={() => {timeSetMsg1()}}>Chp1</div>
                </div>
            </div>
        </div>
    )
}