import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import SchoolLife from '../Images/SchoolLife.mp4';

export default function SchoolLifeMove ({ setLanguage, Language, isAuth, setIsAuth, TimeVI, setTimeVI, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    // 再生チャプター変色
    const [ChapterColor1, setChapterColor1] = useState('MoveChapBtn');
    const [ChapterColor2, setChapterColor2] = useState('MoveChapBtn');
    const [ChapterColor3, setChapterColor3] = useState('MoveChapBtn');
    const [ChapterColor4, setChapterColor4] = useState('MoveChapBtn');
    const [ChapterColor5, setChapterColor5] = useState('MoveChapBtn');
    const [ChapterColor6, setChapterColor6] = useState('MoveChapBtn');
    const [ChapterColor7, setChapterColor7] = useState('MoveChapBtn');
    const [ChapterColor8, setChapterColor8] = useState('MoveChapBtn');
    const [ChapterColor9, setChapterColor9] = useState('MoveChapBtn');
    const [ChapterColor10, setChapterColor10] = useState('MoveChapBtn');
    const [ChapterColor11, setChapterColor11] = useState('MoveChapBtn');
    const [ChapterColor12, setChapterColor12] = useState('MoveChapBtn');
    const [ChapterColor13, setChapterColor13] = useState('MoveChapBtn');

    const [TimePlus, setTimePlus] = useState(0)
    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        if(TimePlus == 0){
            document.getElementById('video').currentTime = TimeVI;
            setTimePlus(1);
        }

        // 住所などの変更
        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }

            setChapterColor1('MoveChapBtn MChBChange');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
        }
        if(counter >= 0.5){
            if(Language == 0){
                setKashi('住所などの変更');
            }
            if(Language == 1){
                setKashi('Change of address etc.');
            }
            if(Language == 2){
                setKashi('地址變更等');
            }
            if(Language == 3){
                setKashi('ठेगाना परिवर्तन आदि।');
            }
            if(Language == 4){
                setKashi('ලිපිනය වෙනස් කිරීම ආදිය.');
            }
            if(Language == 5){
                setKashi('လိပ်စာပြောင်းခြင်း စသည်တို့');
            }
            if(Language == 6){
                setKashi('ঠিকানা পরিবর্তন ইত্যাদি');
            }
        }
        if(counter >= 3.7){
            if(Language == 0){
                setKashi('学生は、電話番号やメールアドレス');
            }
            if(Language == 1){
                setKashi('When your phone number, e-mail address');
            }
            if(Language == 2){
                setKashi('學生必須有電話號碼和電子郵件地址。');
            }
            if(Language == 3){
                setKashi('विद्यार्थीहरूले फोन नंबर ईमेल  ठेगाना, ');
            }
            if(Language == 4){
                setKashi('සිසුන්ට දුරකථන අංකයක් සහ ඊමේල් ලිපිනයක් තිබිය යුතුය.');
            }
            if(Language == 5){
                setKashi('ကျောင်းသားများသည် ဖုန်းနံပါတ်နှင့် အီးမေးလ်လိပ်စာရှိရမည်။');
            }
            if(Language == 6){
                setKashi('শিক্ষার্থীদের অবশ্যই তাদের ফোন নম্বর এবং ইমেল ঠিকানা প্রদান করতে হবে।');
            }
        }
        if(counter >= 8.4){
            if(Language == 0){
                setKashi('住所が変わるとき');
            }
            if(Language == 1){
                setKashi('and residency are changed,');
            }
            if(Language == 2){
                setKashi('地址變更時');
            }
            if(Language == 3){
                setKashi('ठेगाना  परिवर्तन गर्दा,');
            }
            if(Language == 4){
                setKashi('දුරකථන අංකය සහා ලිපිනය වෙනස් වූ විට');
            }
            if(Language == 5){
                setKashi('လိပ်စာပြောင်းတဲ့အခါ');
            }
            if(Language == 6){
                setKashi('যখন ঠিকানা পরিবর্তন হয়');
            }
        }
        if(counter >= 12.1){
            if(Language == 0){
                setKashi('すぐに学校のじむしょの先生に教えてください。');
            }
            if(Language == 1){
                setKashi('Please inform the school office immediately');
            }
            if(Language == 2){
                setKashi('請立即通知學校辦公室。');
            }
            if(Language == 3){
                setKashi('तुरुन्त विद्यालयमा कार्यालयको शिक्षकलाई खबर  गर्नुस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර වහාම පාසල් කාර්යාලයට දැනුම් දෙන්න.');
            }
            if(Language == 5){
                setKashi('ကျောင်းရုံးကို ချက်ချင်းအကြောင်းကြားပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে অবিলম্বে স্কুলকে বলুন');
            }
        }
        if(counter >= 18.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 19.5){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn MChBChange');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
        }
        // 暴力
        if(counter >= 20.3){
            if(Language == 0){
                setKashi('暴力');
            }
            if(Language == 1){
                setKashi('Violence');
            }
            if(Language == 2){
                setKashi('暴力');
            }
            if(Language == 3){
                setKashi('हिंसात्मक क्रियाकलाप');
            }
            if(Language == 4){
                setKashi('ප්රචණ්ඩත්වය');
            }
            if(Language == 5){
                setKashi('အကြမ်းဖက်မှု');
            }
            if(Language == 6){
                setKashi('সহিংসতা');
            }
        }
        if(counter >= 23.4){
            if(Language == 0){
                setKashi('学校の中でも外でも');
            }
            if(Language == 1){
                setKashi('Inside and outside of school,');
            }
            if(Language == 2){
                setKashi('校內校外');
            }
            if(Language == 3){
                setKashi('विद्यालय भित्र र बाहिर दुवै');
            }
            if(Language == 4){
                setKashi('පාසැලේ ඇතුළත සහ පිටත යන දෙකම');
            }
            if(Language == 5){
                setKashi('ကျောင်းတွင်းရော အပြင်ရော');
            }
            if(Language == 6){
                setKashi('স্কুলের ভিতরে এবং বাইরে');
            }
        }
        if(counter >= 27.1){
            if(Language == 0){
                setKashi('暴力はダメです。');
            }
            if(Language == 1){
                setKashi('Violence is not allowed.');
            }
            if(Language == 2){
                setKashi('禁止暴力。');
            }
            if(Language == 3){
                setKashi('हिंसात्मक क्रियाकलाप गर्न पाइने छैन।');
            }
            if(Language == 4){
                setKashi('ප්‍රචණ්ඩත්වය තහනම්.');
            }
            if(Language == 5){
                setKashi('အကြမ်းဖက်မှုက မကောင်းဘူး။');
            }
            if(Language == 6){
                setKashi('সহিংসতা অনুমোদিত নয়।');
            }
        }
        if(counter >= 29.7){
            if(Language == 0){
                setKashi('学校で暴力を使った場合');
            }
            if(Language == 1){
                setKashi('In case of violence at school,');
            }
            if(Language == 2){
                setKashi('如果在學校使用暴力');
            }
            if(Language == 3){
                setKashi('यदि स्कूलमा हिंसात्मक क्रियाकलाप गर्यो भने,');
            }
            if(Language == 4){
                setKashi('පාසැලේදී ප්‍රචණ්ඩත්වය භාවිතා කරන්නේ නම්');
            }
            if(Language == 5){
                setKashi('ကျောင်းမှာ အကြမ်းဖက်လျှင်');
            }
            if(Language == 6){
                setKashi('যদি স্কুলে সহিংসতা ব্যবহার করা হয়');
            }
        }
        if(counter >= 33){
            if(Language == 0){
                setKashi('その学生は１週間停学になります。');
            }
            if(Language == 1){
                setKashi('The student will be suspended from school for one week.');
            }
            if(Language == 2){
                setKashi('該學生將被停學一周。');
            }
            if(Language == 3){
                setKashi('त्यस विद्यार्थीलाई एक साताको लागि विद्यालयबाट निलम्बन गरिनेछ।');
            }
            if(Language == 4){
                setKashi('ශිෂ්‍යයාට සතියක පාසල් තහනමක් පැනවේ.');
            }
            if(Language == 5){
                setKashi('အဆိုပါ ကျောင်းသားအား ကျောင်းမှ တစ်ပတ်တာ ရပ်နားမည်ဖြစ်သည်။');
            }
            if(Language == 6){
                setKashi('শিক্ষার্থীকে এক সপ্তাহের জন্য স্কুল থেকে সাসপেন্ড করা হবে।');
            }
        }
        if(counter >= 38.3){
            if(Language == 0){
                setKashi('暴力がひどい場合は');
            }
            if(Language == 1){
                setKashi('If the violence is severe,');
            }
            if(Language == 2){
                setKashi('如果暴力行為嚴重');
            }
            if(Language == 3){
                setKashi('यदि हिंसा गम्भीर किसिमको छ भने,');
            }
            if(Language == 4){
                setKashi('ප්‍රචණ්ඩත්වය දරුණු නම්');
            }
            if(Language == 5){
                setKashi('အကြမ်းဖက်မှုတွေ ပြင်းထန်လာရင်');
            }
            if(Language == 6){
                setKashi('সহিংসতা গুরুতর হলে');
            }
        }
        if(counter >= 41.2){
            if(Language == 0){
                setKashi('退学になることもあります。');
            }
            if(Language == 1){
                setKashi('The student might be expelled from school.');
            }
            if(Language == 2){
                setKashi('你甚至可能被學校開除。');
            }
            if(Language == 3){
                setKashi('तपाईलाई विद्यालयबाट निष्कासन पनि गर्न सकिन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබ පාසලෙන් පවා නෙරපා හැරිය හැක.');
            }
            if(Language == 5){
                setKashi('ကျောင်းထုတ်ခံရတာတောင် ဖြစ်နိုင်တယ်။');
            }
            if(Language == 6){
                setKashi('আপনাকে স্কুল থেকে বহিষ্কার করা হতে পারে।');
            }
        }
        if(counter >= 44.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 45.5){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn MChBChange');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
        }
        // 反省文
        if(counter >= 46.5){
            if(Language == 0){
                setKashi('反省文');
            }
            if(Language == 1){
                setKashi('Letter of apology');
            }
            if(Language == 2){
                setKashi('反思陳述');
            }
            if(Language == 3){
                setKashi('प्रतिबिम्ब बयान');
            }
            if(Language == 4){
                setKashi('පරාවර්තන ප්රකාශය');
            }
            if(Language == 5){
                setKashi('တောင်းပန်စာ');
            }
            if(Language == 6){
                setKashi('প্রতিফলন বিবৃতি');
            }
        }
        if(counter >= 49.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 50.9){
            if(Language == 0){
                setKashi('暴力を使った人は');
            }
            if(Language == 1){
                setKashi('And those who committed the violence,');
            }
            if(Language == 2){
                setKashi('還有那些使用暴力的人');
            }
            if(Language == 3){
                setKashi('हिंसात्मक क्रियाकलाप गर्ने व्यक्तिलाई');
            }
            if(Language == 4){
                setKashi('සහ ප්‍රචණ්ඩත්වය භාවිතා කළ අය');
            }
            if(Language == 5){
                setKashi('အကြမ်း ဖက်သုံးတဲ့သူတွေ၊');
            }
            if(Language == 6){
                setKashi('এবং যারা সহিংসতা ব্যবহার করেছিল');
            }
        }
        if(counter >= 55.5){
            if(Language == 0){
                setKashi('反省文と課題をしなければなりません。');
            }
            if(Language == 1){
                setKashi('They have to do a reflection statement and an assignment.');
            }
            if(Language == 2){
                setKashi('必須做一篇反思論文和作業。');
            }
            if(Language == 3){
                setKashi('तपाईंले प्रतिबिम्ब बयान र असाइनमेन्ट गर्नु पर्छ।');
            }
            if(Language == 4){
                setKashi('ඔබ සමාව ඉල්ලන ලිපිය සහ පැවරැම් කල යුතුය.');
            }
            if(Language == 5){
                setKashi('တောင်းပန်စာနှင့် တာဝန်တစ်ခု ကိုလုပ်ဆောင်ရမည်။');
            }
            if(Language == 6){
                setKashi('আমাকে একটি প্রতিফলন রচনা এবং একটি অ্যাসাইনমেন্ট করতে হবে।');
            }
        }
        if(counter >= 62.5){
            if(Language == 0){
                setKashi('学校は暴力を使ったことを');
            }
            if(Language == 1){
                setKashi('The school will notify');
            }
            if(Language == 2){
                setKashi('學校將通知家人');
            }
            if(Language == 3){
                setKashi('हिंसात्मक क्रियाकलाप गरेको कुरा');
            }
            if(Language == 4){
                setKashi('පාසලේ ප්‍රචණ්ඩත්වය පාවිච්චි කළා කියලා');
            }
            if(Language == 5){
                setKashi('ကျောင်းက အကြမ်းဖက်မှုကို သုံးသည့်အခါ');
            }
            if(Language == 6){
                setKashi('স্কুল স্বীকার করেছে যে এটি সহিংসতা ব্যবহার করেছে।');
            }
        }
        if(counter >= 67.3){
            if(Language == 0){
                setKashi('家族に連絡します。');
            }
            if(Language == 1){
                setKashi("the student's family about the violence.");
            }
            if(Language == 2){
                setKashi('該名學生使用了暴力。');
            }
            if(Language == 3){
                setKashi('तपाईँहरूको परिवारमा जानकारी गराउनेछौं।');
            }
            if(Language == 4){
                setKashi('මම එය  ඔබේ පවුලේ අයට දැනුම් දෙමි.');
            }
            if(Language == 5){
                setKashi('ငါ့မိသားစုကို ငါဆက်သွယ်မယ်။');
            }
            if(Language == 6){
                setKashi('আমি আমার পরিবারের সাথে যোগাযোগ করব।');
            }
        }
        if(counter >= 72.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 76){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn MChBChange');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
        }
        // けいさつ
        if(counter >= 77.2){
            if(Language == 0){
                setKashi('けいさつ');
            }
            if(Language == 1){
                setKashi('Police');
            }
            if(Language == 2){
                setKashi('警察');
            }
            if(Language == 3){
                setKashi('प्रहरी');
            }
            if(Language == 4){
                setKashi('පොලිසිය');
            }
            if(Language == 5){
                setKashi('ရဲ');
            }
            if(Language == 6){
                setKashi('পুলিশ');
            }
        }
        if(counter >= 80.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 82){
            if(Language == 0){
                setKashi('けいさつにつかまったときは、');
            }
            if(Language == 1){
                setKashi('When You were arrested by the police outside the school,');
            }
            if(Language == 2){
                setKashi('當您在學校外面被警察逮捕時，');
            }
            if(Language == 3){
                setKashi('यदि तपाईँहरूलाई प्रहरीले हिरासतमा लिएको खण्डमा,');
            }
            if(Language == 4){
                setKashi('පාසලෙන් පිටතදී මාව පොලිසිය විසින් අත්අඩංගුවට ගන්නා විට,');
            }
            if(Language == 5){
                setKashi('ကျောင်းအပြင်မှာ ရဲတွေ ဖမ်းတုန်းက');
            }
            if(Language == 6){
                setKashi('যখন আমাকে স্কুলের বাইরে পুলিশ গ্রেপ্তার করেছিল,');
            }
        }
        if(counter >= 85.6){
            if(Language == 0){
                setKashi('かならず学校に教えてください。');
            }
            if(Language == 1){
                setKashi('please make sure to inform the school.');
            }
            if(Language == 2){
                setKashi('請務必告知學校。');
            }
            if(Language == 3){
                setKashi('कृपया विद्यालयलाई जानकारी गराउनु होला।');
            }
            if(Language == 4){
                setKashi('කරුණාකර පාසල දැනුවත් කිරීමට වග බලා ගන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးပြု၍ ကျောင်းကို အသိပေးပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে স্কুলকে জানাতে ভুলবেন না।');
            }
        }
        if(counter >= 90){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 90.5){
            if(Language == 0){
                setKashi('ビザを更新するときに');
            }
            if(Language == 1){
                setKashi('At the time of visa renewal');
            }
            if(Language == 2){
                setKashi('更新簽證時');
            }
            if(Language == 3){
                setKashi('तपाईंको भिसा नवीकरण गर्दा');
            }
            if(Language == 4){
                setKashi('ඔබගේ වීසා බලපත්‍රය අලුත් කිරීමේදී එම සීදුවීම ගැන වාර්තා කරනු ලැබේ');
            }
            if(Language == 5){
                setKashi('ဗီဇာသက်တမ်းတိုးတဲ့အခါ');
            }
            if(Language == 6){
                setKashi('ভিসা নবায়নের সময়');
            }
        }
        if(counter >= 93.5){
            if(Language == 0){
                setKashi('レポートを書きます。先生が手伝います。');
            }
            if(Language == 1){
                setKashi('the student writes a report. School will help.');
            }
            if(Language == 2){
                setKashi('寫報告。學校會幫忙的。');
            }
            if(Language == 3){
                setKashi('प्रहरीले समातेको कुराको रिपोर्ट लेख्नुपर्ने हुन्छ। हामी मद्दत गर्छौं।');
            }
            if(Language == 4){
                setKashi('වාර්තාවක් ලියන්න.  පාසලෙන් උදව්  කරයි.');
            }
            if(Language == 5){
                setKashi('အစီရင်ခံစာရေးပါ။ ကျောင်းကကူညီပါမည်။');
            }
            if(Language == 6){
                setKashi('একটি প্রতিবেদন লিখুন। সাহায্য করবে.');
            }
        }
        if(counter >= 96.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 97.4){
            if(Language == 0){
                setKashi('でも、問題が大きいときは');
            }
            if(Language == 1){
                setKashi('But when the problem is terrible,');
            }
            if(Language == 2){
                setKashi('但當問題很大的時候');
            }
            if(Language == 3){
                setKashi('यदि हिंसात्मक क्रियाकलाप र समस्या ठूलो छ भने,');
            }
            if(Language == 4){
                setKashi('නමුත් ගැටලුව විශාල වූ විට');
            }
            if(Language == 5){
                setKashi('ဒါပေမယ့် ပြဿနာက ကြီးလာတဲ့အခါ');
            }
            if(Language == 6){
                setKashi('কিন্তু যখন সমস্যা বড় হয়');
            }
        }
        if(counter >= 100.6){
            if(Language == 0){
                setKashi('退学になります。');
            }
            if(Language == 1){
                setKashi('You will be expelled from school.');
            }
            if(Language == 2){
                setKashi('您將被學校開除。');
            }
            if(Language == 3){
                setKashi('तपाईँलाई विद्यालयबाट निष्कासन गरिनेछ।');
            }
            if(Language == 4){
                setKashi('ඔබව ඉස්කෝලෙන් අයින් කරනවා.');
            }
            if(Language == 5){
                setKashi('ကျောင်းထုတ်ခံရတော့မယ်။');
            }
            if(Language == 6){
                setKashi('আপনাকে স্কুল থেকে বহিষ্কার করা হবে।');
            }
        }
        if(counter >= 103.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 106){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn MChBChange');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
        }
        //　退学（たいがく）
        if(counter >= 107.2){
            if(Language == 0){
                setKashi('退学（たいがく）');
            }
            if(Language == 1){
                setKashi('Withdrawal from school');
            }
            if(Language == 2){
                setKashi('被學校開除');
            }
            if(Language == 3){
                setKashi('विद्यालयबाट निष्कासन');
            }
            if(Language == 4){
                setKashi('පාසලෙන් ඉවත් කිරීම');
            }
            if(Language == 5){
                setKashi('ကျောင်းထွက်ခြင်း');
            }
            if(Language == 6){
                setKashi('স্কুল ত্যাগ');
            }
        }
        if(counter >= 110.4){
            if(Language == 0){
                setKashi('病気など問題があって学校をやめるときは、');
            }
            if(Language == 1){
                setKashi('If you have to quit school due to illness or other problems,');
            }
            if(Language == 2){
                setKashi('如果您因生病或其他問題必須退學，');
            }
            if(Language == 3){
                setKashi('यदि तपाईले बिरामी वा अन्य समस्याहरूको कारण विद्यालय छोड्नु पर्यो भने,');
            }
            if(Language == 4){
                setKashi('අසනීප හෝ වෙනත් ගැටළු හේතුවෙන් ඔබට පාසලෙන් ඉවත් වීමට සිදුවුවහොත්,');
            }
            if(Language == 5){
                setKashi('ဖျားနာခြင်း သို့မဟုတ် အခြားပြဿနာများကြောင့် ကျောင်းထွက်ရခြင်း၊');
            }
            if(Language == 6){
                setKashi('যদি আপনাকে অসুস্থতা বা অন্যান্য সমস্যার কারণে স্কুল ছাড়তে হয়,');
            }
        }
        if(counter >= 113.5){
            if(Language == 0){
                setKashi('退学届(たいがくとどけ)を書きます。');
            }
            if(Language == 1){
                setKashi('You will write a notice of withdrawal.');
            }
            if(Language == 2){
                setKashi('寫一份撤回通知。');
            }
            if(Language == 3){
                setKashi('फिर्ता हुनको लागि पत्र लेख्नुपर्ने हुन्छ।');
            }
            if(Language == 4){
                setKashi('මම ඉල්ලා අස්කර ගැනීමේ *ලිපියක් ලිවීම.');
            }
            if(Language == 5){
                setKashi('နုတ်ထွက်ကြောင်း အကြောင်းကြားစာရေးပါမည်။');
            }
            if(Language == 6){
                setKashi('আমি প্রত্যাহারের নোটিশ লিখব।');
            }
        }
        if(counter >= 116.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 117.2){
            if(Language == 0){
                setKashi('退学(たいがく)しても、');
            }
            if(Language == 1){
                setKashi('Even if you drop out of school,');
            }
            if(Language == 2){
                setKashi('就算你輟學了，');
            }
            if(Language == 3){
                setKashi('विद्यालय छाडे पनि,');
            }
            if(Language == 4){
                setKashi('ඔබ පාසල් හැර ගියත්,');
            }
            if(Language == 5){
                setKashi('ကျောင်းထွက်ရင်တောင်');
            }
            if(Language == 6){
                setKashi('আপনি স্কুল ছেড়ে দিলেও,');
            }
        }
        if(counter >= 119.4){
            if(Language == 0){
                setKashi('学校のお金や寮のお金は返しません。');
            }
            if(Language == 1){
                setKashi('School money and dormitory money will not be returned.');
            }
            if(Language == 2){
                setKashi('學費和宿舍費將不予退還。');
            }
            if(Language == 3){
                setKashi('विद्यालयको पैसा र छात्रावासको पैसा फिर्ता गरिने छैन।');
            }
            if(Language == 4){
                setKashi('පාසල් මුදල් සහ නේවාසිකාගාර මුදල් ආපසු දෙන්නේ නැහැ.');
            }
            if(Language == 5){
                setKashi('ကျောင်းသုံးငွေနှင့် အိပ်ဆောင်ခကို ပြန်ပေးမည်မဟုတ်ပါ။');
            }
            if(Language == 6){
                setKashi('স্কুলের টাকা এবং ছাত্রাবাসের টাকা ফেরত দেওয়া হবে না।');
            }
        }
        if(counter >= 123.6){
            if(Language == 0){
                setKashi('学校からかりているものがあったら、返さなければなりません。');
            }
            if(Language == 1){
                setKashi('If you borrowed anything from school, you must return it.');
            }
            if(Language == 2){
                setKashi('如果你從學校借了任何東西，你必須歸還。');
            }
            if(Language == 3){
                setKashi('यदि तपाईँले विद्यालयबाट केही सामान उधारो लिनुभएको छ भने त्यसलाई फिर्ता गर्न जरुरी छ।');
            }
            if(Language == 4){
                setKashi('ඔබ පාසලෙන් යමක් ණයට ගන්නේ නම්, ඔබ එය ආපසු ලබා දිය යුතුය.');
            }
            if(Language == 5){
                setKashi('ကျောင်းက တစ်ခုခုချေးရင် ပြန်ပေးရမယ်။');
            }
            if(Language == 6){
                setKashi('আপনি স্কুল থেকে ধার করা জিনিস ফেরত দিন.');
            }
        }
        if(counter >= 129.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 131){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn MChBChange');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
        }
        // はんこ
        if(counter >= 132){
            if(Language == 0){
                setKashi('はんこ');
            }
            if(Language == 1){
                setKashi('Stamp');
            }
            if(Language == 2){
                setKashi('印章');
            }
            if(Language == 3){
                setKashi('हस्ताक्षर छाप');
            }
            if(Language == 4){
                setKashi('මුද්දර');
            }
            if(Language == 5){
                setKashi('တံဆိပ်တုံး');
            }
            if(Language == 6){
                setKashi('ছাপ');
            }
        }
        if(counter >= 136){
            if(Language == 0){
                setKashi('はんこと保険は、');
            }
            if(Language == 1){
                setKashi('Stamps and insurance will be');
            }
            if(Language == 2){
                setKashi('印章和保險是');
            }
            if(Language == 3){
                setKashi('टिकट र बीमा छन्');
            }
            if(Language == 4){
                setKashi('මුද්දර සහ රක්ෂණ වේ');
            }
            if(Language == 5){
                setKashi('တံဆိပ်ခေါင်းနဲ့ အာမခံတွေဖြစ်ပါတယ်။');
            }
            if(Language == 6){
                setKashi('স্ট্যাম্প এবং বীমা হয়');
            }
        }
        if(counter >= 138.3){
            if(Language == 0){
                setKashi('お金がかかります。');
            }
            if(Language == 1){
                setKashi('charged at cost.');
            }
            if(Language == 2){
                setKashi('需要單獨的錢。');
            }
            if(Language == 3){
                setKashi('पैसा लाग्छ।');
            }
            if(Language == 4){
                setKashi('ඒකට සල්ලි ඕන.');
            }
            if(Language == 5){
                setKashi('ပိုက်ဆံယူတယ်။');
            }
            if(Language == 6){
                setKashi('টাকা লাগে।');
            }
        }
        if(counter >= 141.4){
            if(Language == 0){
                setKashi('ひつようなお金は');
            }
            if(Language == 1){
                setKashi('Regarding the amount ');
            }
            if(Language == 2){
                setKashi('關於所需金額');
            }
            if(Language == 3){
                setKashi('आवश्यक रकमको सम्बन्धमा');
            }
            if(Language == 4){
                setKashi('එයට වැය වෙන මුදල');
            }
            if(Language == 5){
                setKashi('လိုအပ်သောပမာဏနှင့်ပတ်သက်၍');
            }
            if(Language == 6){
                setKashi('সাধারণ পরিমাণ সম্পর্কে');
            }
        }
        if(counter >= 144){
            if(Language == 0){
                setKashi('じむしょの先生に聞いてください。');
            }
            if(Language == 1){
                setKashi('please ask the teachers in the office.');
            }
            if(Language == 2){
                setKashi('請詢問辦公室或老師。');
            }
            if(Language == 3){
                setKashi('कृपया आफ्नो कार्यालयको  शिक्षकलाई सोध्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබේ කාර්යාලයෙන් හෝ ගුරුවරයාගෙන් විමසන්න.');
            }
            if(Language == 5){
                setKashi('သင့်ရုံး သို့မဟုတ် ဆရာကို မေးပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে জিমুশোয়াকে জিজ্ঞাসা করুন।');
            }
        }
        if(counter >= 146.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 147.4){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn MChBChange');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
        }
        // 学校のお金①
        if(counter >= 148){
            if(Language == 0){
                setKashi('学校のお金①');
            }
            if(Language == 1){
                setKashi('Tuition fee①');
            }
            if(Language == 2){
                setKashi('學費①');
            }
            if(Language == 3){
                setKashi('विद्यालयको पैसा①');
            }
            if(Language == 4){
                setKashi('පාසල් මුදල්①');
            }
            if(Language == 5){
                setKashi('ကျောင်းပိုက်ဆံ①');
            }
            if(Language == 6){
                setKashi('স্কুলের টাকা①');
            }
        }
        if(counter >= 151.5){
            if(Language == 0){
                setKashi('学校のお金は、半年に1回はらいます。');
            }
            if(Language == 1){
                setKashi('You pay the tuition fee every six months.');
            }
            if(Language == 2){
                setKashi('學費每六個月支付一次。');
            }
            if(Language == 3){
                setKashi('विद्यालयको शुल्क हरेक ६ महिनामा  एकचोटी तिर्नुपर्ने हुन्छ।');
            }
            if(Language == 4){
                setKashi('පාසල් ගාස්තු මාස ​​හයකට වරක් ගෙවනු ලැබේ.');
            }
            if(Language == 5){
                setKashi('ကျောင်းလခကို ခြောက်လတစ်ကြိမ် ပေးဆောင်ရသည်။');
            }
            if(Language == 6){
                setKashi('আমি প্রতি ছয় মাসে আমার স্কুলের টাকা পরিশোধ করি।');
            }
        }
        if(counter >= 156.2){
            if(Language == 0){
                setKashi('はらえないときは、');
            }
            if(Language == 1){
                setKashi("When you are unable to pay,");
            }
            if(Language == 2){
                setKashi('如果你付不起錢，');
            }
            if(Language == 3){
                setKashi('तिर्न नसक्ने अवस्थामा,');
            }
            if(Language == 4){
                setKashi('ඔබට ගෙවිය නොහැකි නම්,');
            }
            if(Language == 5){
                setKashi('မဆပ်နိုင်ရင်၊');
            }
            if(Language == 6){
                setKashi('যখন আপনি এটি পেতে পারেন না,');
            }
        }
        if(counter >= 157.7){
            if(Language == 0){
                setKashi('早めにじむしょの先生にそうだんしてください。');
            }
            if(Language == 1){
                setKashi('Please consult with the teacher at the office as soon as possible.');
            }
            if(Language == 2){
                setKashi('請盡快向辦公室老師諮詢。');
            }
            if(Language == 3){
                setKashi('कृपया सकेसम्म चाँडो आफ्नो कार्यालयको शिक्षक संग परामर्श गर्नुहोला।');
            }
            if(Language == 4){
                setKashi('කරුණාකර හැකි ඉක්මනින් ඔබේ කාර්යාල ගුරුවරයා හමුවන්න.');
            }
            if(Language == 5){
                setKashi('သင်၏ရုံးဆရာနှင့် အမြန်ဆုံးတိုင်ပင်ပါ။');
            }
            if(Language == 6){
                setKashi('যত তাড়াতাড়ি সম্ভব আপনার সন্তানের শিক্ষককে বলুন।');
            }
        }
        if(counter >= 161.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 163){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn MChBChange');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
        }
        // 学校のお金②
        if(counter >= 163.7){
            if(Language == 0){
                setKashi('学校のお金②');
            }
            if(Language == 1){
                setKashi('Tuition fee②');
            }
            if(Language == 2){
                setKashi('學費②');
            }
            if(Language == 3){
                setKashi('विद्यालयको पैसा②');
            }
            if(Language == 4){
                setKashi('පාසල් මුදල්②');
            }
            if(Language == 5){
                setKashi('ကျောင်းပိုက်ဆံ②');
            }
            if(Language == 6){
                setKashi('স্কুলের টাকা②');
            }
        }
        if(counter >= 167.2){
            if(Language == 0){
                setKashi('そうだんしないで２か月以上');
            }
            if(Language == 1){
                setKashi("Students who don't pay");
            }
            if(Language == 2){
                setKashi('超過2個月未諮詢');
            }
            if(Language == 3){
                setKashi('शिक्षक संगको परामर्श बिना दुईमहिना भन्दा बढी ');
            }
            if(Language == 4){
                setKashi('දැනුම් දීමක්  නොමැතිව මාස 2 කට වඩා වැඩි කාලයක්');
            }
            if(Language == 5){
                setKashi('တိုင်ပင်ခြင်းမရှိဘဲ ၂ လကျော်');
            }
            if(Language == 6){
                setKashi('কিছু না করে 2 মাসের বেশি হয়ে গেছে।');
            }
        }
        if(counter >= 170.6){
            if(Language == 0){
                setKashi('お金をはらわない学生は');
            }
            if(Language == 1){
                setKashi("without consultation more than 2months,");
            }
            if(Language == 2){
                setKashi('不繳費的學生');
            }
            if(Language == 3){
                setKashi('शुल्क नतिर्ने विद्यार्थीलाई');
            }
            if(Language == 4){
                setKashi('මුදල් නොගෙවන සිසුන්');
            }
            if(Language == 5){
                setKashi('ကျောင်းသားတွေက ကျောင်းစရိတ်မပေးဆောင်လျှင်။');
            }
            if(Language == 6){
                setKashi('যে ছাত্ররা টাকা খরচ করে না');
            }
        }
        if(counter >= 173.4){
            if(Language == 0){
                setKashi('学校を退学になることがあります。');
            }
            if(Language == 1){
                setKashi('might be expelled from school.');
            }
            if(Language == 2){
                setKashi('您可能會被學校開除。');
            }
            if(Language == 3){
                setKashi('विद्यालयबाट निष्कासन गर्न सकिन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබව පාසලෙන් නෙරපා හැරිය හැක.');
            }
            if(Language == 5){
                setKashi('ကျောင်းမှ ထုတ်ပယ်ခံရနိုင်သည်။');
            }
            if(Language == 6){
                setKashi('আপনাকে স্কুল থেকে বহিষ্কার করা হতে পারে।');
            }
        }
        if(counter >= 177.8){
            if(Language == 0){
                setKashi('学校のお金をはらわない学生は');
            }
            if(Language == 1){
                setKashi("Students who don't pay school fees");
            }
            if(Language == 2){
                setKashi('不繳學費的學生');
            }
            if(Language == 3){
                setKashi('विद्यालयको शुल्क नतिर्ने विद्यार्थीहरूको');
            }
            if(Language == 4){
                setKashi('පාසලට මුදල් නොගෙවන සිසුන්');
            }
            if(Language == 5){
                setKashi('ကျောင်းစရိတ်မရှိသော ကျောင်းသား၊');
            }
            if(Language == 6){
                setKashi('যে শিক্ষার্থীরা স্কুলের জন্য অর্থ প্রদান করে না');
            }
        }
        if(counter >= 181.5){
            if(Language == 0){
                setKashi('学校の証明書を作ることができません。');
            }
            if(Language == 1){
                setKashi("can't receive school certificates.");
            }
            if(Language == 2){
                setKashi('無法頒發學校證書。');
            }
            if(Language == 3){
                setKashi('विद्यालयको प्रमाणपत्र बनाउन  असमर्थ छौं।');
            }
            if(Language == 4){
                setKashi('ඔබට පාසල් සහතිකයක් හදන්න බැහැ.');
            }
            if(Language == 5){
                setKashi('ကျောင်းဆင်းလက်မှတ် မဖန်တီးနိုင်ဘူး။');
            }
            if(Language == 6){
                setKashi('আমি স্কুল সার্টিফিকেট তৈরি করতে পারি না।');
            }
        }
        if(counter >= 185.1){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 186.5){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn MChBChange');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
        }
        //　校外学習
        if(counter >= 187.5){
            if(Language == 0){
                setKashi('校外学習');
            }
            if(Language == 1){
                setKashi('Field trip');
            }
            if(Language == 2){
                setKashi('校園旅行');
            }
            if(Language == 3){
                setKashi('फिल्ड भ्रमण');
            }
            if(Language == 4){
                setKashi('අධ්‍යාපන චාරිකා');
            }
            if(Language == 5){
                setKashi('Excursions');
            }
            if(Language == 6){
                setKashi('স্কুল ট্রিপ');
            }
        }
        if(counter >= 191){
            if(Language == 0){
                setKashi('学校の外で勉強します。');
            }
            if(Language == 1){
                setKashi('We study outside of school.');
            }
            if(Language == 2){
                setKashi('在校外學習。');
            }
            if(Language == 3){
                setKashi('विद्यालय बाहिर अध्ययन गरिनेछ,');
            }
            if(Language == 4){
                setKashi('පාසලෙන් පිටත ඉගෙන ගන්න.');
            }
            if(Language == 5){
                setKashi('ကျောင်းပြင်ပလေ့လာခြင်း');
            }
            if(Language == 6){
                setKashi('আমি স্কুলের বাইরে পড়ি।');
            }
        }
        if(counter >= 196.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 201.5){
            if(Language == 0){
                setKashi('必ずさんかしてください。');
            }
            if(Language == 1){
                setKashi('Please be sure to participate.');
            }
            if(Language == 2){
                setKashi('請務必參加。');
            }
            if(Language == 3){
                setKashi('अवश्य सहभागी हुनुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර සහභාගී වීමට වග බලා ගන්න.');
            }
            if(Language == 5){
                setKashi('ပါဝင်ကြပါရန်။');
            }
            if(Language == 6){
                setKashi('শিক্ষার্থীদের অংশগ্রহণ করতে হবে।');
            }
        }
        if(counter >= 206.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 209.5){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn MChBChange');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
        }
        // せいせき
        if(counter >= 210.5){
            if(Language == 0){
                setKashi('せいせき');
            }
            if(Language == 1){
                setKashi('Grades evaluation');
            }
            if(Language == 2){
                setKashi('成績');
            }
            if(Language == 3){
                setKashi('ग्रेडहरू');
            }
            if(Language == 4){
                setKashi('ශ්රේණි');
            }
            if(Language == 5){
                setKashi('အဆင့်များ');
            }
            if(Language == 6){
                setKashi('শ্রেণীসমূহ');
            }
        }
        if(counter >= 213.6){
            if(Language == 0){
                setKashi('みなさんのせいせきは');
            }
            if(Language == 1){
                setKashi('Grades are determined by');
            }
            if(Language == 2){
                setKashi('成績是');
            }
            if(Language == 3){
                setKashi('तपाईँको ग्रेडहरू');
            }
            if(Language == 4){
                setKashi('ශ්රේණි වේ');
            }
            if(Language == 5){
                setKashi('အဆင့်တွေကတော့');
            }
            if(Language == 6){
                setKashi('সেসেকিহা');
            }
        }
        if(counter >= 215.7){
            if(Language == 0){
                setKashi('テストの点数');
            }
            if(Language == 1){
                setKashi('Examination results,');
            }
            if(Language == 2){
                setKashi('檢測結果');
            }
            if(Language == 3){
                setKashi('परीक्षाको रिजल्टबाट,');
            }
            if(Language == 4){
                setKashi('පරීක්ෂණ ප්රතිඵල');
            }
            if(Language == 5){
                setKashi('စမ်းသပ်မှုရလဒ်များ');
            }
            if(Language == 6){
                setKashi('পরীক্ষার ফলাফল');
            }
        }
        if(counter >= 217.7){
            if(Language == 0){
                setKashi('宿題をしているかどうか、');
            }
            if(Language == 1){
                setKashi('Submitting homework,');
            }
            if(Language == 2){
                setKashi('提交作業');
            }
            if(Language == 3){
                setKashi('कक्षाको गृहकार्यबाट,');
            }
            if(Language == 4){
                setKashi('ගෙදර වැඩ ඉදිරිපත් කිරීම');
            }
            if(Language == 5){
                setKashi('အိမ်စာတွေတင်တယ်။');
            }
            if(Language == 6){
                setKashi('হোমওয়ার্ক জমা দিন');
            }
        }
        if(counter >= 219.6){
            if(Language == 0){
                setKashi('授業態度などで決まります。');
            }
            if(Language == 1){
                setKashi('Attitude in class, and ect.');
            }
            if(Language == 2){
                setKashi('這將取決於你在課堂上的態度。');
            }
            if(Language == 3){
                setKashi('कक्षाकोठामा तपाईँहरूले गर्ने क्रियाकलापहरूबाट  निर्धारण गरिनेछ।');
            }
            if(Language == 4){
                setKashi('මෙය පන්තියේ ඔබේ ආකල්පය අනුව තීරණය වේ.');
            }
            if(Language == 5){
                setKashi('ဒါကို အတန်းထဲက မင်းရဲ့သဘောထားက ဆုံးဖြတ်လိမ့်မယ်။');
            }
            if(Language == 6){
                setKashi('এটা ক্লাসে আপনার মনোভাবের উপর নির্ভর করে।');
            }
        }
        if(counter >= 224){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 225){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn MChBChange');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
        }
        // 卒業するために必要なこと
        if(counter >= 225.7){
            if(Language == 0){
                setKashi('卒業するために必要なこと');
            }
            if(Language == 1){
                setKashi('Requirements for graduation');
            }
            if(Language == 2){
                setKashi('畢業條件');
            }
            if(Language == 3){
                setKashi('स्नातक हुनको लागि पूरा गर्नुपर्ने शर्तहरू');
            }
            if(Language == 4){
                setKashi('උපාධි කොන්දේසි');
            }
            if(Language == 5){
                setKashi('ဘွဲ့အခြေအနေများ');
            }
            if(Language == 6){
                setKashi('স্নাতক শর্তাবলী');
            }
        }
        if(counter >= 229.3){
            if(Language == 0){
                setKashi('卒業するために必要なことは4つあります。');
            }
            if(Language == 1){
                setKashi('There are four qualification for graduation.');
            }
            if(Language == 2){
                setKashi('畢業有四個條件。');
            }
            if(Language == 3){
                setKashi('स्नातकको लागि चार शर्तहरू छन्।');
            }
            if(Language == 4){
                setKashi('උපාධිය සඳහා කොන්දේසි හතරක් ඇත.');
            }
            if(Language == 5){
                setKashi('ဘွဲ့ရဖို့ အခြေအနေလေးမျိုးရှိတယ်။');
            }
            if(Language == 6){
                setKashi('স্নাতকের জন্য চারটি শর্ত আছে।');
            }
        }
        if(counter >= 234){
            if(Language == 0){
                setKashi('出席率が70%以上あること');
            }
            if(Language == 1){
                setKashi('Attendance rate is 70% or higher');
            }
            if(Language == 2){
                setKashi('①出席率70%以上');
            }
            if(Language == 3){
                setKashi('उपस्थिति दर 70% वा बढी छ');
            }
            if(Language == 4){
                setKashi('පැමිණීමේ අනුපාතය 70% හෝ ඊට වැඩි ය');
            }
            if(Language == 5){
                setKashi('တက်ရောက်မှုနှုန်း 70% သို့မဟုတ် ထို့ထက်ပိုပါသည်။');
            }
            if(Language == 6){
                setKashi('উপস্থিতির হার 70% বা তার বেশি');
            }
        }
        if(counter >= 242.8){
            if(Language == 0){
                setKashi('学校のテストをぜんぶ受けること');
            }
            if(Language == 1){
                setKashi('Taking all school examinations');
            }
            if(Language == 2){
                setKashi('②參加所有學校考試');
            }
            if(Language == 3){
                setKashi('स्कुलको सबै परीक्षाहरू दिएको हुनुपर्छ।');
            }
            if(Language == 4){
                setKashi('සියලුම පාසල් විභාග කරන්න');
            }
            if(Language == 5){
                setKashi('ကျောင်းစာမေးပွဲအားလုံးဖြေပါ။');
            }
            if(Language == 6){
                setKashi('সমস্ত স্কুল পরীক্ষা দিন');
            }
        }
        if(counter >= 248.7){
            if(Language == 0){
                setKashi('毎年JLPTを受けること');
            }
            if(Language == 1){
                setKashi('Taking the JLPT every year');
            }
            if(Language == 2){
                setKashi('③每年參加日本語能力考試');
            }
            if(Language == 3){
                setKashi('हरेक वर्ष JLPTजापानीज भाषा परीक्षामा सहभागी लिएको  हुनुपर्छ।');
            }
            if(Language == 4){
                setKashi('සෑම වසරකම JLPT ගැනීම');
            }
            if(Language == 5){
                setKashi('နှစ်စဉ် JLPT ဖြေဆိုခြင်း။');
            }
            if(Language == 6){
                setKashi('প্রতি বছর JLPT নেওয়া');
            }
        }
        if(counter >= 255.1){
            if(Language == 0){
                setKashi('学校のお金をぜんぶはらうことです。');
            }
            if(Language == 1){
                setKashi('Paying all school fees');
            }
            if(Language == 2){
                setKashi('④支付所有的學費');
            }
            if(Language == 3){
                setKashi('विद्यालय शुल्क सबै तिरेको हुनुपर्छ।');
            }
            if(Language == 4){
                setKashi('පාසල සඳහා සියලු මුදල් ගෙවන්න');
            }
            if(Language == 5){
                setKashi('ကျောင်းစရိတ်ကိုအားလုံးပေးဆောင်ခြင်း။');
            }
            if(Language == 6){
                setKashi('স্কুলের জন্য সমস্ত টাকা পরিশোধ করা');
            }
        }
        if(counter >= 261.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 263){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn MChBChange');
            setChapterColor13('MoveChapBtn');
        }
        // たばこ
        if(counter >= 263.8){
            if(Language == 0){
                setKashi('たばこ');
            }
            if(Language == 1){
                setKashi('Tobacco');
            }
            if(Language == 2){
                setKashi('菸草');
            }
            if(Language == 3){
                setKashi('धुम्रपान');
            }
            if(Language == 4){
                setKashi('දුම්වැටි');
            }
            if(Language == 5){
                setKashi('ဆေးလိပ်');
            }
            if(Language == 6){
                setKashi('তামাক');
            }
        }
        if(counter >= 267.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 270.6){
            if(Language == 0){
                setKashi('日本でお酒やたばこは何歳からいいか、知っていますか。');
            }
            if(Language == 1){
                setKashi('Do you know from what age is okay to drink alcohol or smoke in Japan?');
            }
            if(Language == 2){
                setKashi('在日本幾歲可以喝酒和抽煙？');
            }
            if(Language == 3){
                setKashi('तिमीहरूलाई जापानमा धुम्रपान र रक्सी सेवन गर्नको लागि कति उमेर पुगेको हुनुपर्छ थाहा छ?');
            }
            if(Language == 4){
                setKashi('ජපානයේ මත්පැන් පානය කිරීම සහ දුම් පානය කිරීම සුදුසු වන්නේ කුමන වයසේදීද?');
            }
            if(Language == 5){
                setKashi('ဂျပန်မှာ အရက်နဲ့ ဆေးလိပ်သောက်ရင် ဘယ်အရွယ်မှာ အဆင်ပြေလဲ။');
            }
            if(Language == 6){
                setKashi('কোন বয়সে জাপানে অ্যালকোহল পান করা বা ধূমপান করা ঠিক?');
            }
        }
        if(counter >= 274.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 275.8){
            if(Language == 0){
                setKashi('20歳からです。');
            }
            if(Language == 1){
                setKashi('It is from 20 years old.');
            }
            if(Language == 2){
                setKashi('從20歲開始。');
            }
            if(Language == 3){
                setKashi('२० वर्ष पुगेको हुनुपर्छ।');
            }
            if(Language == 4){
                setKashi('මම වයස අවුරුදු 20 සිට.');
            }
            if(Language == 5){
                setKashi('ငါအသက် 20 မှ။');
            }
            if(Language == 6){
                setKashi('আমার বয়স 20 বছর।');
            }
        }
        if(counter >= 280){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 281.4){
            if(Language == 0){
                setKashi('たばこをすいたい人は、きつえんじょですってください。');
            }
            if(Language == 1){
                setKashi("If you want to smoke, please do in school's smoking area.");
            }
            if(Language == 2){
                setKashi('如果您想吸煙，請在吸煙區吸煙。');
            }
            if(Language == 3){
                setKashi('यदि तपाईं धुम्रपान गर्न चाहनुहुन्छ भने, कृपया धुम्रपान गर्ने ठाउँमा गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබට දුම් පානය කිරීමට අවශ්‍ය නම්, කරුණාකර දුම්පානය කරන කාමරයට යන්න.');
            }
            if(Language == 5){
                setKashi('ဆေးလိပ်သောက်ချင်ရင်ဆေးလိပ်သောက်လို့ရမည့်နေရာတွင်သောက်ရန်။');
            }
            if(Language == 6){
                setKashi('আপনি যদি ধূমপান করতে চান তবে দয়া করে একজন ধূমপায়ীর সাথে তা করুন।');
            }
        }
        if(counter >= 289.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 290.9){
            if(Language == 0){
                setKashi('きつえんじょは１Fにあります。');
            }
            if(Language == 1){
                setKashi('Smoking area is on the ground floor.');
            }
            if(Language == 2){
                setKashi('吸煙區位於一樓。');
            }
            if(Language == 3){
                setKashi('धुम्रपान क्षेत्र पहिलो तलामा छ।');
            }
            if(Language == 4){
                setKashi('දුම් පානය කරන ස්ථානය 1 වන මහලේ ඇත.');
            }
            if(Language == 5){
                setKashi('ဆေးလိပ်သောက်သည့်နေရာသည် ပထမထပ်တွင်ရှိသည်။');
            }
            if(Language == 6){
                setKashi('Kitsuenjo 1ম তলায় আছে।');
            }
        }
        if(counter >= 296.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 322.8){
            if(Language == 0){
                setKashi('きつえんじょを使ったあとは、ドアのかぎをかけてください。');
            }
            if(Language == 1){
                setKashi('After you smoke, please lock the door.');
            }
            if(Language == 2){
                setKashi('離開時請鎖好門。');
            }
            if(Language == 3){
                setKashi('कृपया धुम्रपान गर्ने ठाउँ प्रयोग गर्दा प्रयोग गरेर फर्किँदा ढोका बन्द गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබ යන විට දොර අගුළු දමන්න.');
            }
            if(Language == 5){
                setKashi('ပြန်လာသည့်အခါ တံခါးကို ပြန်ပိတ်ပေးပါ။');
            }
            if(Language == 6){
                setKashi('আপনি চলে গেলে দরজা লক করুন.');
            }
        }
        if(counter >= 330.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 346.2){
            if(Language == 0){
                setKashi('日本では、道にたばこをすててはいけません。');
            }
            if(Language == 1){
                setKashi("In Japan you are not allowed to throw Tobacco on the street.");
            }
            if(Language == 2){
                setKashi('在日本不要把香菸丟在街上。');
            }
            if(Language == 3){
                setKashi('जापानमा सडकमा चुरोटको ठुटा फाल्न पाइँदैन।');
            }
            if(Language == 4){
                setKashi('ජපානයේ සිගරට් පාරේ විසි නොකරන්න.');
            }
            if(Language == 5){
                setKashi('ဂျပန်မှာ စီးကရက်တွေကို လမ်းပေါ်မှာ မစွန့်ပစ်ပါနဲ့။');
            }
            if(Language == 6){
                setKashi('আপনি জাপানে রাস্তায় সিগারেট ফেলতে পারবেন না।');
            }
        }
        if(counter >= 350.8){
            if(Language == 0){
                setKashi('けいさつが見たら、2000円はらわなければなりません。');
            }
            if(Language == 1){
                setKashi('If the police see it, you must have to pay 2000 yen.');
            }
            if(Language == 2){
                setKashi('如果警察看到的話，就要繳2000日圓。');
            }
            if(Language == 3){
                setKashi('प्रहरीले देखेमा २००० येन  तिर्नुपर्ने हुन्छ।');
            }
            if(Language == 4){
                setKashi('පොලිසිය දැක්කොත් යෙන් 2000ක් ගෙවන්න වෙයි.');
            }
            if(Language == 5){
                setKashi('ရဲတွေတွေ့ရင် ယန်း ၂၀၀၀ ပေးရမယ်။');
            }
            if(Language == 6){
                setKashi('যদি Keisatsu এটা দেখে, আপনাকে 2000 ইয়েন দিতে হবে।');
            }
        }
        if(counter >= 355.2){
            if(Language == 0){
                setKashi('道でたばこをすうとき、けいたいはいざらを使ってください。');
            }
            if(Language == 1){
                setKashi('Please use a portable ashtray when smoking on the street.');
            }
            if(Language == 2){
                setKashi('在街上吸煙時請使用便攜式煙灰缸。');
            }
            if(Language == 3){
                setKashi('सडकमा धुम्रपान गर्दा कृपया पोर्टेबल एशट्रे प्रयोग गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('වීථියේ දුම් පානය කරන විට කරුණාකර අතේ ගෙන යා හැකි අළු බඳුනක් භාවිතා කරන්න.');
            }
            if(Language == 5){
                setKashi('လမ်းပေါ်မှာ ဆေးလိပ်သောက်တဲ့အခါ အိတ်ဆောင်ပြာခွက်ကို သုံးပါ။');
            }
            if(Language == 6){
                setKashi('রাস্তায় ধূমপান করার সময়, অনুগ্রহ করে একটি সিগারেট ব্যবহার করুন।');
            }
        }
        if(counter >= 359.6){
            if(Language == 0){
                setKashi('100円ショップで買うことができます。');
            }
            if(Language == 1){
                setKashi('You can buy it at the 100 yen shop.');
            }
            if(Language == 2){
                setKashi('可以在百元商店購買。');
            }
            if(Language == 3){
                setKashi('तपाईं यसलाई 100 येन पसलहरूमा किन्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබට එය යෙන් සාප්පු 100 කින් මිලදී ගත හැකිය.');
            }
            if(Language == 5){
                setKashi('ယန်း 100 ဆိုင်တွေမှာ ဝယ်လို့ရပါတယ်။');
            }
            if(Language == 6){
                setKashi('আপনি এটি 100 ইয়েনের দোকানে কিনতে পারেন।');
            }
        }
        if(counter >= 363.1){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 364.3){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn MChBChange');
        }
        // 自転車
        if(counter >= 365.3){
            if(Language == 0){
                setKashi('自転車');
            }
            if(Language == 1){
                setKashi('Bicycle');
            }
            if(Language == 2){
                setKashi('自行車');
            }
            if(Language == 3){
                setKashi('साइकल');
            }
            if(Language == 4){
                setKashi('බයිසිකලය');
            }
            if(Language == 5){
                setKashi('စက်ဘီး');
            }
            if(Language == 6){
                setKashi('সাইকেল');
            }
        }
        if(counter >= 366.2){
            if(Language == 0){
                setKashi('日本はみなさんの国と自転車のルールがちがいます。');
            }
            if(Language == 1){
                setKashi('Japan has different rules for cycling than your country.');
            }
            if(Language == 2){
                setKashi('日本的自行車規則與您的國家不同。');
            }
            if(Language == 3){
                setKashi('जापानमा साइकल चलाउनको लागि आफ्नो देश भन्दा फरक नियमहरू छन्।');
            }
            if(Language == 4){
                setKashi('ජපානයේ බයිසිකල් පැදීම සඳහා ඔබේ රටට වඩා වෙනස් නීති තිබේ.');
            }
            if(Language == 5){
                setKashi('ဂျပန်နိုင်ငံသည် စက်ဘီးစီးရန် မတူညီသော စည်းမျဉ်းများရှိသည်။');
            }
            if(Language == 6){
                setKashi('জাপানে সাইকেল চালানোর জন্য আপনার দেশের চেয়ে আলাদা নিয়ম রয়েছে৷');
            }
        }
        if(counter >= 370.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 372.9){
            if(Language == 0){
                setKashi('学校の前で自転車をおりてください。');
            }
            if(Language == 1){
                setKashi('Please get off your bicycle in front of the school.');
            }
            if(Language == 2){
                setKashi('請在學校前面下車。');
            }
            if(Language == 3){
                setKashi('कृपया स्कूलको अगाडि आफ्नो साइकलबाट ओर्लनुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබේ බයිසිකලයෙන් පාසල ඉදිරිපිටින් බැස යන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးပြုပြီး ကျောင်းရှေ့မှာ စက်ဘီးပေါ်ကဆင်းပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে স্কুলের সামনে আপনার সাইকেল থেকে নামুন।');
            }
        }
        if(counter >= 376.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 377.7){
            if(Language == 0){
                setKashi('自転車をおりてから、ちゅうりんじょうに入ってください。');
            }
            if(Language == 1){
                setKashi('Please get off your bicycle and enter the parking lot.');
            }
            if(Language == 2){
                setKashi('請下車並進入停車場。');
            }
            if(Language == 3){
                setKashi('कृपया आफ्नो साइकलबाट ओर्लनुहोस् र पार्किङ स्थलमा प्रवेश गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබේ බයිසිකලයෙන් බැස බයිසිකල්  නැවැත්වීමේ ස්ථානයට ඇතුළු වන්න.');
            }
            if(Language == 5){
                setKashi('စက်ဘီးပေါ်ကဆင်းပြီး ကားပါကင်ထဲကို ဝင်ပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে আপনার সাইকেল থেকে নামুন এবং পার্কিং লটে প্রবেশ করুন।');
            }
        }
        if(counter >= 381.6){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 389){
            if(Language == 0){
                setKashi('バイクや車で学校に来てはいけません。');
            }
            if(Language == 1){
                setKashi('Do not come to school by motorcycle or car.');
            }
            if(Language == 2){
                setKashi('不要騎自行車或汽車來學校。');
            }
            if(Language == 3){
                setKashi('मोटरसाइकल वा कारबाट विद्यालय नआउनुहोस्।');
            }
            if(Language == 4){
                setKashi('මෝටර් බයික්  හෝ කාර් එකෙන් පාසලට එන්න එපා.');
            }
            if(Language == 5){
                setKashi('মোটর সাইকেল বা গাড়িতে স্কুলে আসবেন না।');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 394.1){
            if(Language == 0){
                setKashi('自転車、電車、バスで来るのはいいです。');
            }
            if(Language == 1){
                setKashi('You can come to school by bicycle, train, or bus.');
            }
            if(Language == 2){
                setKashi('騎自行車、火車或公車前來都很方便。');
            }
            if(Language == 3){
                setKashi('साइकल, रेल वा बसबाट आउनुहोस्।');
            }
            if(Language == 4){
                setKashi('බයිසිකලයෙන්, දුම්රියෙන්, බස් එකෙන් එන එක හොඳයි.');
            }
            if(Language == 5){
                setKashi('က်ဘီး၊ ရထား သို့မဟုတ် ဘတ်စ်ကားဖြင့် လာရန် ကောင်းပါတယ်။');
            }
            if(Language == 6){
                setKashi('আপনি সাইকেল, ট্রেন বা বাসে আসতে পারেন।');
            }
        }
        if(counter >= 398.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 399){
            if(Language == 0){
                setKashi('自転車はちゅうりんじょうに置いてください。');
            }
            if(Language == 1){
                setKashi('Please leave your bicycle at the parking lot.');
            }
            if(Language == 2){
                setKashi('請將自行車停放於自行車停車處。');
            }
            if(Language == 3){
                setKashi('कृपया आफ्नो साइकल साइकल पार्किङ स्थलमा राख्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබේ බයිසිකලය බයිසිකල් ගාල් කරන ස්ථානයේ තබන්න.');
            }
            if(Language == 5){
                setKashi('သင့်စက်ဘီးကို စက်ဘီးရပ်နားရာနေရာတွင် ထားခဲ့ပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে আপনার সাইকেল পার্কিং লটে রেখে দিন।');
            }
        }
        if(counter >= 403.1){
            if(Language == 0){
                setKashi('自転車がなくなったせんぱいもたくさんいます。');
            }
            if(Language == 1){
                setKashi("Don't spread out on the street when you are riding your bike with your friends");
            }
            if(Language == 2){
                setKashi('有很多老年人丟了自行車。');
            }
            if(Language == 3){
                setKashi('साइकल गुमाएका धेरै सिनियर विद्यार्थीहरू छन् ।');
            }
            if(Language == 4){
                setKashi('බයිසිකල් නැති වෙච්ච ජ්‍යෙෂ්ඨයෝ ඕන තරම් ඉන්නවා.');
            }
            if(Language == 5){
                setKashi('စက်ဘီးဆုံးရှုံးသွားတဲ့ သက်ကြီးရွယ်အိုတွေ အများကြီးရှိတယ်။');
            }
            if(Language == 6){
                setKashi('অনেক ছাত্র আছে যারা তাদের সাইকেল হারিয়েছে।');
            }
        }
        if(counter >= 406.9){
            if(Language == 0){
                setKashi('日本にもどろぼうがいますから、駅でもスーパーでもいつもかぎをかけてください。');
            }
            if(Language == 1){
                setKashi('There are thieves in Japan, so please always lock your bicycle at the station and supermarket.');
            }
            if(Language == 2){
                setKashi('日本有小偷，所以請在車站和超市鎖好門。');
            }
            if(Language == 3){
                setKashi('जापानमा पनि चोरी हुने भएको हुँदा, कृपया स्टेशनहरू र सुपरमार्केटहरूमा सधैँ आफ्नो साइकलको चाबी लगाउने गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ජපානයේ සොරුන් සිටින බැවින් කරුණාකර සෑම විටම දුම්රිය ස්ථානවල සහ සුපිරි වෙළඳසැල්වලට යන විට * ඔබේ බයිසිකලය අගුලු  දමන්න.');
            }
            if(Language == 5){
                setKashi('ဂျပန်မှာ သူခိုးတွေရှိတာကြောင့် ဘူတာရုံနဲ့ စူပါမားကတ်တွေမှာ သင့်တံခါးတွေကို အမြဲသော့ခတ်ထားပါ။');
            }
            if(Language == 6){
                setKashi('জাপানে চোর আছে, তাই অনুগ্রহ করে সবসময় স্টেশন এবং সুপারমার্কেটে আপনার দরজা বন্ধ করে রাখুন।');
            }
        }
        if(counter >= 414){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 415.1){
            if(Language == 0){
                setKashi('2人で乗ってはいけません。');
            }
            if(Language == 1){
                setKashi('Do not ride with two people.');
            }
            if(Language == 2){
                setKashi('請勿與兩人一起騎乘。');
            }
            if(Language == 3){
                setKashi('साइकलमा दुईजना  चलाउन　पाइँदैन।');
            }
            if(Language == 4){
                setKashi('දෙදෙනෙකු සමඟ පදින්න එපා.');
            }
            if(Language == 5){
                setKashi('လူနှစ်ယောက်နဲ့ မစီးပါနဲ့။');
            }
            if(Language == 6){
                setKashi("দু'জনের সাথে চড়বেন না।");
            }
        }
        if(counter >= 422.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi("");
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 423.2){
            if(Language == 0){
                setKashi('道にひろがって自転車に乗ってはいけません。');
            }
            if(Language == 1){
                setKashi("Don't ride your bike on the road.");
            }
            if(Language == 2){
                setKashi('不要在路上分散騎自行車。');
            }
            if(Language == 3){
                setKashi('सडकमा छरिएर साइकल चलाउन　पाइँदैन।　');
            }
            if(Language == 4){
                setKashi('ඔබේ බයිසිකලය පාර අවහිර වන සේ පදින්න එපා.');
            }
            if(Language == 5){
                setKashi('စက်ဘီးကို လမ်းမှာဖြန့်မစီးပါနဲ့။');
            }
            if(Language == 6){
                setKashi('রাস্তায় আপনার বাইক চালাবেন না।');
            }
        }
        if(counter >= 430.6){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi("");
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 431.2){
            if(Language == 0){
                setKashi('道じゃないところをとおってはいけません。');
            }
            if(Language == 1){
                setKashi("");
            }
            if(Language == 2){
                setKashi('請勿在非道路的道路上通行。');
            }
            if(Language == 3){
                setKashi('सडक नभएको ठाउँमा साइकल चलाउन　पाइँदैन।');
            }
            if(Language == 4){
                setKashi('මාර්ග නොවන මාර්ගවල ගමන් නොකරන්න.');
            }
            if(Language == 5){
                setKashi('လမ်းမဟုတ်သောလမ်းများကို မဖြတ်သန်းရ။');
            }
            if(Language == 6){
                setKashi('রাস্তা নয় এমন কিছু দিয়ে যাবেন না।');
            }
        }
        if(counter >= 438.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 439.4){
            if(Language == 0){
                setKashi('かさをさして乗ってもいけません。');
            }
            if(Language == 1){
                setKashi('Do not ride with your umbrella on.');
            }
            if(Language == 2){
                setKashi('請勿帶傘騎行。');
            }
            if(Language == 3){
                setKashi('छाता लिएर  साइकल चलाउन　पाइँदैन।');
            }
            if(Language == 4){
                setKashi('කුඩයක් අතැතිව ගමන් නොකරන්න.');
            }
            if(Language == 5){
                setKashi('ထီးဆောင်း၍ မစီးပါနှင့်။');
            }
            if(Language == 6){
                setKashi('আপনার ছাতা নিয়ে চড়বেন না।');
            }
        }
        if(counter >= 452.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 453.3){
            if(Language == 0){
                setKashi('イヤホンで音楽をききながら自転車に乗ってはいけません。');
            }
            if(Language == 1){
                setKashi('Do not ride a bicycle while listening to music with earphones.');
            }
            if(Language == 2){
                setKashi('不要邊騎腳踏車邊用耳機聽音樂。');
            }
            if(Language == 3){
                setKashi('इयरफोन लगाएर संगीत सुन्दै साइकल चलाउन पाइँदैन।');
            }
            if(Language == 4){
                setKashi('ඉයර්ෆෝන් දමා සංගීතයට සවන් දෙමින් බයිසිකලයක් පදින්න එපා.');
            }
            if(Language == 5){
                setKashi('နားကြပ်တပ်ပြီး သီချင်းနားထောင်ရင်း စက်ဘီးမစီးပါနဲ့။');
            }
            if(Language == 6){
                setKashi('ইয়ারফোন দিয়ে গান শোনার সময় সাইকেল চালাবেন না।');
            }
        }
        if(counter >= 466.6){
            if(Language == 0){
                setKashi('電話をしながら乗ってもいけません。');
            }
            if(Language == 1){
                setKashi('Do not ride while talking on the phone.');
            }
            if(Language == 2){
                setKashi('請勿邊騎車邊打電話。');
            }
            if(Language == 3){
                setKashi(' फोनमा कुरा गर्दै साइकल चलाउन पाइँदैन।');
            }
            if(Language == 4){
                setKashi('දුරකථනයෙන් කතා කරමින් පදින්න එපා.');
            }
            if(Language == 5){
                setKashi('ဖုန်းပြောနေရင်း မစီးပါနဲ့။');
            }
            if(Language == 6){
                setKashi('ফোনে কথা বলার সময় আপনি বাইক চালাতে পারবেন না।');
            }
        }
        if(counter >= 472.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 476.6){
            if(Language == 0){
                setKashi('自転車に乗りながら、たばこをすってはいけません。');
            }
            if(Language == 1){
                setKashi('Do not smoke while riding a bicycle.');
            }
            if(Language == 2){
                setKashi('騎自行車時請勿吸煙。');
            }
            if(Language == 3){
                setKashi('धुम्रपान गर्दै　साइकल चलाउन पाइँदैन।');
            }
            if(Language == 4){
                setKashi('බයිසිකල් පැදීමේදී දුම් පානය නොකරන්න.');
            }
            if(Language == 5){
                setKashi('စက်ဘီးစီးရင်း ဆေးလိပ်မသောက်ပါနဲ့။');
            }
            if(Language == 6){
                setKashi('সাইকেল চালানোর সময় ধূমপান করবেন না।');
            }
        }
        if(counter >= 481.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 484.8){
            if(Language == 0){
                setKashi('学校の前で長い時間立って話さないでください。');
            }
            if(Language == 1){
                setKashi('Please do not stand and talk for a long time in front of the school.');
            }
            if(Language == 2){
                setKashi('請不要在學校前長時間站立說話。');
            }
            if(Language == 3){
                setKashi('कृपया विद्यालयको अगाडि अथवा वरिपरि लामो समयसम्म उभिएर कुरा नगर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර පාසල ඉදිරිපිට දිගු වේලාවක් සිටගෙන කතා නොකරන්න.');
            }
            if(Language == 5){
                setKashi('ကျောင်းရှေ့မှာ အကြာကြီးရပ်ပြီး စကားမပြောပါနဲ့။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে স্কুলের সামনে দীর্ঘক্ষণ দাঁড়িয়ে কথা বলবেন না।');
            }
        }
        if(counter >= 489.6){
            if(Language == 0){
                setKashi('車がきますからあぶないです。');
            }
            if(Language == 1){
                setKashi("There are cars passing by so it's dangerous.");
            }
            if(Language == 2){
                setKashi('很危險，因為有車來了。');
            }
            if(Language == 3){
                setKashi('सडकमा गाडीहरू आउने　भएकोले खतरनाक छ।');
            }
            if(Language == 4){
                setKashi('වාහනයක් එන නිසා භයානකයි.');
            }
            if(Language == 5){
                setKashi('ကားတစ်စီးလာနေတာကြောင့် အန္တရာယ်ရှိတယ်။');
            }
            if(Language == 6){
                setKashi('একটি গাড়ি আসছে, তাই এটি বিপজ্জনক।');
            }
        }
        if(counter >= 493.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 498.2){
            if(Language == 0){
                setKashi('バイクや車に乗るときは、かならず保険に入ってください。');
            }
            if(Language == 1){
                setKashi('When riding a motorcycle or car, be sure to get insurance.');
            }
            if(Language == 2){
                setKashi('騎摩托車或汽車時，一定要買保險。');
            }
            if(Language == 3){
                setKashi('मोटरसाइकल वा कार चलाउँदा, बीमा गर्न जरुरी छ।');
            }
            if(Language == 4){
                setKashi('මෝටර් බයිසිකලයක් හෝ මෝටර් රථයක් ධාවනය කරන විට, රක්ෂණයක් ඇති බවට වග බලා ගන්න.');
            }
            if(Language == 5){
                setKashi('မော်တော်ဆိုင်ကယ် သို့မဟုတ် ကားစီးသည့်အခါ အာမခံရှိထားရမည်။');
            }
            if(Language == 6){
                setKashi('একটি মোটরসাইকেল বা গাড়ী চালানোর সময়, বীমা পেতে ভুলবেন না.');
            }
        }
        if(counter >= 506){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
    }

    const timeSetMsg1 = () => {
        document.getElementById('video').currentTime = 0;
    }
    const timeSetMsg2 = () => {
        document.getElementById('video').currentTime = 19.5;
    }
    const timeSetMsg3 = () => {
        document.getElementById('video').currentTime = 45.5;
    }
    const timeSetMsg4 = () => {
        document.getElementById('video').currentTime = 76;
    }
    const timeSetMsg5 = () => {
        document.getElementById('video').currentTime = 106;
    }
    const timeSetMsg6 = () => {
        document.getElementById('video').currentTime = 131;
    }
    const timeSetMsg7 = () => {
        document.getElementById('video').currentTime = 147.5;
    }
    const timeSetMsg8 = () => {
        document.getElementById('video').currentTime = 163;
    }
    const timeSetMsg9 = () => {
        document.getElementById('video').currentTime = 186.5;
    }
    const timeSetMsg10 = () => {
        document.getElementById('video').currentTime = 209.5;
    }
    const timeSetMsg11 = () => {
        document.getElementById('video').currentTime = 225;
    }
    const timeSetMsg12 = () => {
        document.getElementById('video').currentTime = 263;
    }
    const timeSetMsg13 = () => {
        document.getElementById('video').currentTime = 364.3;
    }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            setLogInNo(LogInNo);
            navigate('/');
        }
    })

    const HomeReset = () => {
        setTimeVI(0);
        setLogInNo(LogInNo);
        navigate('/');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <img src={Titleimg} className='MenuLogo' onClick={() => {HomeReset()}}/>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                        <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                        <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                        <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>Chp4</div>
                        <div className={ChapterColor5} onClick={() => {timeSetMsg5()}}>Chp5</div>
                    </div>
                    <div className='MoveChapArea'>
                        <div className={ChapterColor6} onClick={() => {timeSetMsg6()}}>Chp6</div>
                        <div className={ChapterColor7} onClick={() => {timeSetMsg7()}}>Chp7</div>
                        <div className={ChapterColor8} onClick={() => {timeSetMsg8()}}>Chp8</div>
                        <div className={ChapterColor9} onClick={() => {timeSetMsg9()}}>Chp9</div>
                        <div className={ChapterColor10} onClick={() => {timeSetMsg10()}}>Chp10</div>
                    </div>
                    <div className='MoveChapArea'>
                        <div className={ChapterColor11} onClick={() => {timeSetMsg11()}}>Chp11</div>
                        <div className={ChapterColor12} onClick={() => {timeSetMsg12()}}>Chp12</div>
                        <div className={ChapterColor13} onClick={() => {timeSetMsg13()}}>Chp13</div>
                    </div>
                    <video src={SchoolLife} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                    <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                    <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                    <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>Chp4</div>
                    <div className={ChapterColor5} onClick={() => {timeSetMsg5()}}>Chp5</div>
                </div>
                <div className='MoveChapArea3'>
                    <div className={ChapterColor6} onClick={() => {timeSetMsg6()}}>Chp6</div>
                    <div className={ChapterColor7} onClick={() => {timeSetMsg7()}}>Chp7</div>
                    <div className={ChapterColor8} onClick={() => {timeSetMsg8()}}>Chp8</div>
                    <div className={ChapterColor9} onClick={() => {timeSetMsg9()}}>Chp9</div>
                    <div className={ChapterColor10} onClick={() => {timeSetMsg10()}}>Chp10</div>
                </div>
                <div className='MoveChapArea3'>
                    <div className={ChapterColor11} onClick={() => {timeSetMsg11()}}>Chp11</div>
                    <div className={ChapterColor12} onClick={() => {timeSetMsg12()}}>Chp12</div>
                    <div className={ChapterColor13} onClick={() => {timeSetMsg13()}}>Chp13</div>
                </div>
            </div>
        </div>
    )
}