import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import DorRuhr from '../Images/DorRuhr.mp4';

export default function DorRuhrMove ({ setLanguage, Language, isAuth, setIsAuth, TimeVI, setTimeVI, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    // 再生チャプター変色
    const [ChapterColor1, setChapterColor1] = useState('MoveChapBtn');
    const [ChapterColor2, setChapterColor2] = useState('MoveChapBtn');
    const [ChapterColor3, setChapterColor3] = useState('MoveChapBtn');
    const [ChapterColor4, setChapterColor4] = useState('MoveChapBtn');
    const [ChapterColor5, setChapterColor5] = useState('MoveChapBtn');
    const [ChapterColor6, setChapterColor6] = useState('MoveChapBtn');
    const [ChapterColor7, setChapterColor7] = useState('MoveChapBtn');
    const [ChapterColor8, setChapterColor8] = useState('MoveChapBtn');
    const [ChapterColor9, setChapterColor9] = useState('MoveChapBtn');
    const [ChapterColor10, setChapterColor10] = useState('MoveChapBtn');
    const [ChapterColor11, setChapterColor11] = useState('MoveChapBtn');

    const [TimePlus, setTimePlus] = useState(0)
    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        if(TimePlus == 0){
            document.getElementById('video').currentTime = TimeVI;
            setTimePlus(1);
        }

        // ベランダでの禁止事項
        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }

            setChapterColor1('MoveChapBtn MChBChange');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
        }
        if(counter >= 0.5){
            if(Language == 0){
                setKashi('今から、寮の説明をします。');
            }
            if(Language == 1){
                setKashi('I will now explain the rules of dormitory.');
            }
            if(Language == 2){
                setKashi('現在我就來介紹一下宿舍吧！');
            }
            if(Language == 3){
                setKashi('म अब छात्रावासको व्याख्या गर्नेछु!');
            }
            if(Language == 4){
                setKashi('මම දැන් නේවාසිකාගාරය පැහැදිලි කරන්නම්!');
            }
            if(Language == 5){
                setKashi('အခု အိပ်ဆောင်ကို ရှင်းပြမယ်။');
            }
            if(Language == 6){
                setKashi('আমি এখন ডরমিটরি ব্যাখ্যা করব।');
            }
        }
        if(counter >= 3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 4.2){
            if(Language == 0){
                setKashi('ベランダでの禁止事項');
            }
            if(Language == 1){
                setKashi('Prohibited things on the balcony');
            }
            if(Language == 2){
                setKashi('陽台上禁止攜帶的物品');
            }
            if(Language == 3){
                setKashi('बालकनीमा गर्न नहुने कामहरू');
            }
            if(Language == 4){
                setKashi('බැල්කනියේ තහනම් භාණ්ඩ');
            }
            if(Language == 5){
                setKashi('လသာဆောင်တွင် တားမြစ်ထားသောပစ္စည်းများ');
            }
            if(Language == 6){
                setKashi('ব্যালকনিতে নিষিদ্ধ জিনিসপত্র');
            }
        }
        if(counter >= 7.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 9.6){
            if(Language == 0){
                setKashi('ベランダで電話してはいけません。');
            }
            if(Language == 1){
                setKashi("Don't make phone calls on the balcony.");
            }
            if(Language == 2){
                setKashi('不要在陽台上打電話。');
            }
            if(Language == 3){
                setKashi('बालकनीमा बसेर　फोन कल नगर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('බැල්කනියේ දුරකථන ඇමතුම් ගන්න එපා.');
            }
            if(Language == 5){
                setKashi('လသာဆောင်တွင် ဖုန်းမခေါ်ဆိုပါနှင့်။');
            }
            if(Language == 6){
                setKashi('ব্যালকনিতে ফোন কল করবেন না।');
            }
        }
        if(counter >= 12){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 15){
            if(Language == 0){
                setKashi('ベランダで話してはいけません。');
            }
            if(Language == 1){
                setKashi("Don't talk on the balcony.");
            }
            if(Language == 2){
                setKashi('不要在陽台上說話。');
            }
            if(Language == 3){
                setKashi('बालकनीमा　बसेर कुरा नगर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('බැල්කනියේ කතා කරන්න එපා.');
            }
            if(Language == 5){
                setKashi('လသာဆောင်မှာ စကားမပြောပါနဲ့။');
            }
            if(Language == 6){
                setKashi('ব্যালকনিতে কথা বলবেন না।');
            }
        }
        if(counter >= 17.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 19.6){
            if(Language == 0){
                setKashi('ベランダでたばこをすってはいけません。');
            }
            if(Language == 1){
                setKashi('Do not smoke on the balcony.');
            }
            if(Language == 2){
                setKashi('請勿在陽台上吸煙。');
            }
            if(Language == 3){
                setKashi('बालकनीमा धुम्रपान नगर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('බැල්කනියේ දුම් පානය නොකරන්න.');
            }
            if(Language == 5){
                setKashi('လသာဆောင်တွင် ဆေးလိပ်မသောက်ပါနှင့်။');
            }
            if(Language == 6){
                setKashi('ব্যালকনিতে ধূমপান করবেন না।');
            }
        }
        if(counter >= 21.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 22.3){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn MChBChange');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
        }
        // 寮に入ってもいい人
        if(counter >= 23){
            if(Language == 0){
                setKashi('寮に入ってもいい人');
            }
            if(Language == 1){
                setKashi('People who can stay in the dormitory');
            }
            if(Language == 2){
                setKashi('可以進入宿舍的人');
            }
            if(Language == 3){
                setKashi('छात्रावासमा बस्न सक्ने मानिसहरू');
            }
            if(Language == 4){
                setKashi('නේවාසිකාගාරයේ ඉන්න පුළුවන් අය');
            }
            if(Language == 5){
                setKashi('အဆောင်မှာနေနိုင်သူတွေ');
            }
            if(Language == 6){
                setKashi('যারা ডরমেটরিতে থাকতে পারে');
            }
        }
        if(counter >= 26.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 27.2){
            if(Language == 0){
                setKashi('寮にはルームメイトだけ入ることができます');
            }
            if(Language == 1){
                setKashi('Only roommates can enter the dormitory.');
            }
            if(Language == 2){
                setKashi('宿舍內只允許室友入住。');
            }
            if(Language == 3){
                setKashi('छात्रावासमा रुममेटहरूलाई मात्र अनुमति छ।');
            }
            if(Language == 4){
                setKashi('නේවාසිකාගාරයේ නේවාසික මිතුරන්ට පමණක් අවසර ඇත.');
            }
            if(Language == 5){
                setKashi('အခန်းဖော်များကိုသာ အိပ်ဆောင်တွင် ခွင့်ပြုထားသည်။');
            }
            if(Language == 6){
                setKashi('যারা ডরমেটরিতে থাকতে পারে');
            }
        }
        if(counter >= 32){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 40){
            if(Language == 0){
                setKashi('友達は寮に入ってはいけません。');
            }
            if(Language == 1){
                setKashi('Friends are not allowed in the dormitory.');
            }
            if(Language == 2){
                setKashi('朋友不得進入宿舍。');
            }
            if(Language == 3){
                setKashi('छात्रावासमा साथीहरूलाई अनुमति छैन।');
            }
            if(Language == 4){
                setKashi('මිතුරන්ට නේවාසිකාගාරයට ඇතුළු වීමට අවසර නැත.');
            }
            if(Language == 5){
                setKashi('သူငယ်ချင်းများကို အိပ်ဆောင်တွင် ခွင့်မပြုပါ။');
            }
            if(Language == 6){
                setKashi('বন্ধুদের ডরমেটরিতে অনুমতি দেওয়া হয় না।');
            }
        }
        if(counter >= 42.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 45.5){
            if(Language == 0){
                setKashi('寮でパーティーをしてはいけません。');
            }
            if(Language == 1){
                setKashi("Parties are not allowed in your dorm.");
            }
            if(Language == 2){
                setKashi('不要在宿舍裡舉辦聚會。');
            }
            if(Language == 3){
                setKashi('तपाईंको छात्रावासमा पार्टीहरू नगर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබේ නේවාසිකාගාරයේ සාද පවත්වන්න එපා.');
            }
            if(Language == 5){
                setKashi('သင့်အိပ်ဆောင်တွင် ပါတီများ ကျင်းပခွင့်မပြု  အိပ်ဆောင်စာချုပ်');
            }
            if(Language == 6){
                setKashi('আপনার ডর্মে পার্টি করবেন না।');
            }
        }
        if(counter >= 48.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 49.8){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn MChBChange');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
        }
        // 寮の契約
        if(counter >= 50.5){
            if(Language == 0){
                setKashi('寮の契約');
            }
            if(Language == 1){
                setKashi('dormitory contract');
            }
            if(Language == 2){
                setKashi('宿舍合約');
            }
            if(Language == 3){
                setKashi('छात्रावास सम्झौता');
            }
            if(Language == 4){
                setKashi('නේවාසිකාගාර කොන්ත්රාත්තුව');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင်စာချုပ်');
            }
            if(Language == 6){
                setKashi('ছাত্রাবাস চুক্তি');
            }
        }
        if(counter >= 53.8){
            if(Language == 0){
                setKashi('日本に来る前に寮を予約した人は、学校の寮に住んでください。');
            }
            if(Language == 1){
                setKashi('If you reserved a dormitory before coming to Japan, please live in the school dormitory');
            }
            if(Language == 2){
                setKashi('如果您在來日本之前預訂了宿舍，請住在學校宿舍。');
            }
            if(Language == 3){
                setKashi('यदि तपाईंले जापान आउनु अघि छात्रावास आरक्षित गर्नुभयो भने, कृपया विद्यालयको छात्रावासमा　बस्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබ ජපානයට පැමිණීමට පෙර නේවාසිකාගාරයක් වෙන් කරවා ගත්තේ නම් කරුණාකර පාසල් නේවාසිකාගාරයේ ජීවත් වන්න.');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင်ထဲမှာ တစ်ခုခု ပျက်ဆီးသွားသည့်အခါ၊');
            }
            if(Language == 6){
                setKashi('আপনি যদি জাপানে আসার আগে একটি ছাত্রাবাস সংরক্ষণ করেন, অনুগ্রহ করে স্কুলের ছাত্রাবাসে থাকুন।');
            }
        }
        if(counter >= 59.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 60.6){
            if(Language == 0){
                setKashi('6か月か1年です。');
            }
            if(Language == 1){
                setKashi('for 6 months or 1 year.');
            }
            if(Language == 2){
                setKashi('6個月或1年。');
            }
            if(Language == 3){
                setKashi('६ महिना वा १ वर्ष ।');
            }
            if(Language == 4){
                setKashi('මාස 6ක් හෝ අවුරුද්දක්.');
            }
            if(Language == 5){
                setKashi('၆ လ သို့မဟုတ် ၁ နှစ်။');
            }
            if(Language == 6){
                setKashi('6 মাস বা 1 বছর।');
            }
        }
        if(counter >= 64.3){
            if(Language == 0){
                setKashi('とちゅうでキャンセルできません。');
            }
            if(Language == 1){
                setKashi('You cannot cancel midway.');
            }
            if(Language == 2){
                setKashi('中途不能取消。');
            }
            if(Language == 3){
                setKashi('तपाईँले बीचमा रद्द गर्न सक्नुहुन्न।');
            }
            if(Language == 4){
                setKashi('ඔබට අතරමග අවලංගු කළ නොහැක.');
            }
            if(Language == 5){
                setKashi('လမ်းခုလတ်မှာ ပယ်ဖျက်လို့မရပါဘူး။');
            }
            if(Language == 6){
                setKashi('আপনি মাঝপথে বাতিল করতে পারবেন না।');
            }
        }
        if(counter >= 67.5){
            if(Language == 0){
                setKashi('ルールをまもって、住んでください！');
            }
            if(Language == 1){
                setKashi('Please follow the rules and live here!');
            }
            if(Language == 2){
                setKashi('請遵守這裡的規則！');
            }
            if(Language == 3){
                setKashi('कृपया नियमहरू पालना पालना गरेर बस्नुहोस्!');
            }
            if(Language == 4){
                setKashi('කරුණාකර නීති අනුගමනය කර මෙහි ජීවත් වන්න!');
            }
            if(Language == 5){
                setKashi('စည်းကမ်းများကို လိုက်နာပြီး ဤနေရာတွင် နေထိုင်ပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে নিয়ম অনুসরণ করুন এবং এখানে বাস করুন!');
            }
        }
        if(counter >= 70.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 70.8){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn MChBChange');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
        }
        // 寮のお金
        if(counter >= 71.5){
            if(Language == 0){
                setKashi('寮のお金');
            }
            if(Language == 1){
                setKashi('dormitory money');
            }
            if(Language == 2){
                setKashi('宿舍錢');
            }
            if(Language == 3){
                setKashi('छात्रावास पैसा');
            }
            if(Language == 4){
                setKashi('නේවාසිකාගාර මුදල්');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင်ပိုက်ဆံ');
            }
            if(Language == 6){
                setKashi('ছাত্রাবাস টাকা');
            }
        }
        if(counter >= 75){
            if(Language == 0){
                setKashi('電気、ガス、水のお金は1人1人が、毎月かならずはらってください。');
            }
            if(Language == 1){
                setKashi('Each person must pay for electricity, gas, and water every month.');
            }
            if(Language == 2){
                setKashi('每個人每月必須支付電費、瓦斯費和水費。');
            }
            if(Language == 3){
                setKashi('प्रत्येक व्यक्तिले प्रत्येक महिना बिजुली, ग्यास र पानीको शुल्क बुझाउनुपर्छ।');
            }
            if(Language == 4){
                setKashi('සෑම පුද්ගලයෙකුම සෑම මසකම විදුලිය, ගෑස් සහ ජලය සඳහා ගෙවිය යුතුය.');
            }
            if(Language == 5){
                setKashi('လူတစ်ဦးစီသည် လစဉ် လျှပ်စစ်မီး၊ သဘာဝဓာတ်ငွေ့နှင့် ရေအတွက် ပေးဆောင်ရမည်ဖြစ်သည်။');
            }
            if(Language == 6){
                setKashi('প্রত্যেক ব্যক্তিকে প্রতি মাসে বিদ্যুৎ, গ্যাস এবং জলের জন্য অর্থ প্রদান করতে হবে৷');
            }
        }
        if(counter >= 85.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 86.3){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn MChBChange');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
        }
        // エネルギーの節約
        if(counter >= 87){
            if(Language == 0){
                setKashi('エネルギーの節約');
            }
            if(Language == 1){
                setKashi('energy savings');
            }
            if(Language == 2){
                setKashi('節能');
            }
            if(Language == 3){
                setKashi('जब तपाईं छात्रावासमा केहि तोड्नुहुन्छ');
            }
            if(Language == 4){
                setKashi('බලශක්ති ඉතිරිකිරීම්');
            }
            if(Language == 5){
                setKashi('စွမ်းအင်ချွေတာခြင်း။');
            }
            if(Language == 6){
                setKashi('শক্তি সঞ্চয়');
            }
        }
        if(counter >= 90.2){
            if(Language == 0){
                setKashi('日本の電気は高いです。');
            }
            if(Language == 1){
                setKashi('Electricity in Japan is expensive.');
            }
            if(Language == 2){
                setKashi('日本的電很貴。');
            }
            if(Language == 3){
                setKashi('जापानमा बिजुली महँगो छ।');
            }
            if(Language == 4){
                setKashi('ජපානයේ විදුලිය මිල අධිකයි.');
            }
            if(Language == 5){
                setKashi('ဂျပန်မှာ လျှပ်စစ်က ဈေးကြီးတယ်။');
            }
            if(Language == 6){
                setKashi('জাপানে বিদ্যুৎ ব্যয়বহুল।');
            }
        }
        if(counter >= 93.3){
            if(Language == 0){
                setKashi('エアコンの温度に気を付けてください。');
            }
            if(Language == 1){
                setKashi('Please be careful about the temperature of your air conditioner.');
            }
            if(Language == 2){
                setKashi('請注意空調的溫度。');
            }
            if(Language == 3){
                setKashi('कृपया आफ्नो एयर कन्डिसनरको तापक्रममा ध्यान दिनुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබේ වායු සමීකරණ යන්ත්රයේ උෂ්ණත්වය ගැන සැලකිලිමත් වන්න.');
            }
            if(Language == 5){
                setKashi('သင့်လေအေးပေးစက်၏ အပူချိန်ကို သတိထားပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে আপনার এয়ার কন্ডিশনার তাপমাত্রা সম্পর্কে সতর্ক থাকুন।');
            }
        }
        if(counter >= 96.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 99.5){
            if(Language == 0){
                setKashi('出かけるとき、必ず電気を消してください！');
            }
            if(Language == 1){
                setKashi('Be sure to turn off the lights when you go out!');
            }
            if(Language == 2){
                setKashi('出門一定要關燈！');
            }
            if(Language == 3){
                setKashi('जब तपाईं बाहिर जानुहुन्छ बत्तीहरू बन्द गर्न निश्चित हुनुहोस्!');
            }
            if(Language == 4){
                setKashi('ඔබ පිටතට යන විට විදුලි පහන් නිවා දැමීමට වග බලා ගන්න!');
            }
            if(Language == 5){
                setKashi('အပြင်သွားတဲ့အခါ မီးကို သေချာပိတ်ထားပါ။');
            }
            if(Language == 6){
                setKashi('আপনি যখন বাইরে যান লাইট বন্ধ করতে ভুলবেন না!');
            }
        }
        if(counter >= 103.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 108.3){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn MChBChange');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
        }
        // 寮のものをこわした時
        if(counter >= 109){
            if(Language == 0){
                setKashi('寮のものをこわした時');
            }
            if(Language == 1){
                setKashi('When you broke something in the dormitory');
            }
            if(Language == 2){
                setKashi('當我在宿舍打破東西時');
            }
            if(Language == 3){
                setKashi('जब मैले छात्रावासमा केहि तोडें');
            }
            if(Language == 4){
                setKashi('මම නේවාසිකාගාරයේ යමක් කැඩු විට');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင်ထဲမှာ တစ်ခုခု ကွဲသွားတဲ့အခါ');
            }
            if(Language == 6){
                setKashi('যখন আমি ডরমেটরিতে কিছু ভাঙলাম');
            }
        }
        if(counter >= 112.3){
            if(Language == 0){
                setKashi('寮のものをこわしたときは、');
            }
            if(Language == 1){
                setKashi('When you break something in the dormitory,');
            }
            if(Language == 2){
                setKashi('當你在宿舍打破東西時');
            }
            if(Language == 3){
                setKashi('जब तपाइँ छात्रावासमा केहि भाँच्नुहुन्छ,');
            }
            if(Language == 4){
                setKashi('මම නේවාසිකාගාරයේ යමක් කැඩු විට');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင်ထဲမှာ တစ်ခုခု ဖောက်ဖျက်တဲ့အခါ၊');
            }
            if(Language == 6){
                setKashi('যখন আপনি ছাত্রাবাসে কিছু ভাঙেন,');
            }
        }
        if(counter >= 117.7){
            if(Language == 0){
                setKashi('こわした人が、しゅうりのお金をはらわなければなりません。');
            }
            if(Language == 1){
                setKashi('The person who broke it must pay for the repair cost.');
            }
            if(Language == 2){
                setKashi('造成損壞的人必須支付修理費。');
            }
            if(Language == 3){
                setKashi('क्षति पुर्याएको व्यक्तिले मर्मतको लागि तिर्नुपर्छ।');
            }
            if(Language == 4){
                setKashi('හානියට පත් පුද්ගලයා අලුත්වැඩියා සඳහා ගෙවිය යුතුය.');
            }
            if(Language == 5){
                setKashi('ပျက်ဆီးသွားသူသည် ပြုပြင်မှုအတွက် ပေးဆောင်ရမည်ဖြစ်ပါသည်။');
            }
            if(Language == 6){
                setKashi('যে ব্যক্তি এটি ভেঙেছে তাকে অবশ্যই টাকা দিতে হবে।');
            }
        }

        if(counter >= 122.8){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn MChBChange');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
        }
        // かぎをなくしたとき
        if(counter >= 123.3){
            if(Language == 0){
                setKashi('かぎをなくしたとき');
            }
            if(Language == 1){
                setKashi('When you lose your keys');
            }
            if(Language == 2){
                setKashi('當你丟失鑰匙時');
            }
            if(Language == 3){
                setKashi('जब तपाईं आफ्नो चाबी हराउनुहुन्छ');
            }
            if(Language == 4){
                setKashi('ඔබේ යතුරු නැති වූ විට');
            }
            if(Language == 5){
                setKashi('မင်းရဲ့သော့ပျောက်သွားတဲ့အခါ');
            }
            if(Language == 6){
                setKashi('যখন আপনি আপনার চাবি হারাবেন');
            }
        }
        if(counter >= 126){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 132.5){
            if(Language == 0){
                setKashi('かぎをなくしたときは、学校にれんらくしてください。');
            }
            if(Language == 1){
                setKashi('If you lose your key, please inform the school.');
            }
            if(Language == 2){
                setKashi('如果您遺失了鑰匙，請聯絡學校。');
            }
            if(Language == 3){
                setKashi('यदि तपाईंले आफ्नो साँचो हराउनु भयो भने, कृपया विद्यालयमा सम्पर्क गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබේ යතුර නැති වුවහොත් කරුණාකර පාසල අමතන්න.');
            }
            if(Language == 5){
                setKashi('သင့်သော့ပျောက်ဆုံးပါက ကျောင်းကို ဆက်သွယ်ပါ။');
            }
            if(Language == 6){
                setKashi('আপনি যদি আপনার চাবি হারিয়ে ফেলেন, অনুগ্রহ করে স্কুলে আসুন।');
            }
        }
        if(counter >= 138.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 139.3){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn MChBChange');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
        }
        // 寮をきれいに使う
        if(counter >= 140){
            if(Language == 0){
                setKashi('寮をきれいに使う');
            }
            if(Language == 1){
                setKashi('Use the dormitory neatly');
            }
            if(Language == 2){
                setKashi('宿舍使用整齊');
            }
            if(Language == 3){
                setKashi('सफा सँग छात्रावास प्रयोग गर्नुहोस्');
            }
            if(Language == 4){
                setKashi('නේවාසිකාගාරය පිළිවෙලට භාවිතා කරන්න');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင်ကို သပ်သပ်ရပ်ရပ်သုံးပါ။');
            }
            if(Language == 6){
                setKashi('সুন্দরভাবে ডরমিটরি ব্যবহার করুন');
            }
        }
        if(counter >= 143.6){
            if(Language == 0){
                setKashi('部屋がきたない人は、寮を出るときお金をはらわなければなりません。');
            }
            if(Language == 1){
                setKashi('If your room is dirty, you will have to pay when you leave the dormitory.');
            }
            if(Language == 2){
                setKashi('房間髒亂的學生離開宿舍時必須付費。');
            }
            if(Language == 3){
                setKashi('जसका कोठाहरू फोहोर छन् उनीहरूले छात्रावासबाट बाहिर निस्कँदा पैसा तिर्नु पर्छ।');
            }
            if(Language == 4){
                setKashi('කාමර අපිරිසිදු වී ඇති අය නේවාසිකාගාරයෙන් පිටවන විට මුදල් ගෙවිය යුතුය.');
            }
            if(Language == 5){
                setKashi('အခန်းတွေ ညစ်ပတ်နေတဲ့သူတွေ အိပ်ဆောင်က ထွက်သွားရင် ပိုက်ဆံပေးဆောင်ရမည်။');
            }
            if(Language == 6){
                setKashi('যদি আপনার রুম নোংরা হয়, আপনি ডরমিটরি ছেড়ে যাওয়ার সময় আপনাকে অর্থ প্রদান করতে হবে।');
            }
        }
        if(counter >= 150){
            if(Language == 0){
                setKashi('きれいに使ってください。');
            }
            if(Language == 1){
                setKashi('Please use it cleanly.');
            }
            if(Language == 2){
                setKashi('請謹慎使用。');
            }
            if(Language == 3){
                setKashi('कृपया यसलाई सफा सँग प्रयोग गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර එය ප්රවේශමෙන් භාවිතා කරන්න.');
            }
            if(Language == 5){
                setKashi('ဂရုတစိုက်အသုံးပြုပါ။');
            }
            if(Language == 6){
                setKashi('দয়া করে এটি পরিষ্কারভাবে ব্যবহার করুন।');
            }
        }
        if(counter >= 154){
            if(Language == 0){
                setKashi('電気、ガス、水道のお金を払わない人、寮のルールを守らない人は、');
            }
            if(Language == 1){
                setKashi("People who don't pay for electricity, gas, and water, or who don't follow the dormitory rules,");
            }
            if(Language == 2){
                setKashi('不繳水電費、瓦斯費、不遵守宿舍規定的，');
            }
            if(Language == 3){
                setKashi('बिजुली, ग्यास र पानीको भुक्तानी नगर्ने र छात्रावासको नियम पालना नगर्नेहरू,');
            }
            if(Language == 4){
                setKashi('විදුලිය, ගෑස් සහ ජලය සඳහා මුදල් නොගෙවන අය සහ නේවාසිකාගාර නීති අනුගමනය නොකරන අය,');
            }
            if(Language == 5){
                setKashi('လျှပ်စစ်မီး၊ ဂက်စ်၊ ရေ ပေးဆောင်ခြင်း မပြုသူများနှင့် အိပ်ဆောင်စည်းကမ်း မလိုက်နာသူများ၊');
            }
            if(Language == 6){
                setKashi('যারা বিদ্যুৎ, গ্যাস এবং পানির জন্য অর্থ প্রদান করেন না বা যারা ডরমেটরি নিয়ম অনুসরণ করেন না,');
            }
        }
        if(counter >= 161){
            if(Language == 0){
                setKashi('他の部屋にひっこしすることがあります。');
            }
            if(Language == 1){
                setKashi('might be moved to another room.');
            }
            if(Language == 2){
                setKashi('您可能會被轉移到另一個房間。');
            }
            if(Language == 3){
                setKashi('तपाईंलाई अर्को कोठामा सार्न सकिन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබව වෙනත් කාමරයකට ගෙන යා හැකිය.');
            }
            if(Language == 5){
                setKashi('သင်သည် အခြားနေရာသို့ ပြောင်းရွှေ့နိုင်သည်။');
            }
            if(Language == 6){
                setKashi('আমি অন্য ঘরে ফিরে যেতে পারি।');
            }
        }
        if(counter >= 165){
            if(Language == 0){
                setKashi('学生は自分で部屋をきめることができません。');
            }
            if(Language == 1){
                setKashi('Students cannot choose your own room.');
            }
            if(Language == 2){
                setKashi('學生不能選擇自己的房間。');
            }
            if(Language == 3){
                setKashi('विद्यार्थीले आफ्नो कोठा छनोट गर्न पाउँदैनन् ।');
            }
            if(Language == 4){
                setKashi('සිසුන්ට තමන්ගේම කාමරයක් තෝරා ගත නොහැක.');
            }
            if(Language == 5){
                setKashi('ကျောင်းသားများသည် မိမိအခန်းကို ရွေးချယ်၍မရပါ။');
            }
            if(Language == 6){
                setKashi('শিক্ষার্থীরা তাদের নিজস্ব রুম বেছে নিতে পারে না।');
            }
        }
        if(counter >= 169.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 170.3){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn MChBChange');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
        }
        // 郵便受けの確認
        if(counter >= 171){
            if(Language == 0){
                setKashi('郵便受けの確認');
            }
            if(Language == 1){
                setKashi('Check your mailbox');
            }
            if(Language == 2){
                setKashi('檢查您的信箱');
            }
            if(Language == 3){
                setKashi('आफ्नो मेलबक्सको जाँच गर्नुहोस्');
            }
            if(Language == 4){
                setKashi('ඔබගේ තැපැල් පෙට්ටිය පරීක්ෂා කරන්න');
            }
            if(Language == 5){
                setKashi('သင့်စာတိုက်ပုံးကို စစ်ဆေးပါ။');
            }
            if(Language == 6){
                setKashi('আপনার মেইলবক্স দেখুন');
            }
        }
        if(counter >= 174){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 178){
            if(Language == 0){
                setKashi('毎日、ゆうびん受けをチェックしてください。');
            }
            if(Language == 1){
                setKashi('Check your mailbox every day.');
            }
            if(Language == 2){
                setKashi('每天檢查您的郵箱。');
            }
            if(Language == 3){
                setKashi('आफ्नो मेलबक्स दैनिक जाँच गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබේ තැපැල් පෙට්ටිය දිනපතා පරීක්ෂා කරන්න.');
            }
            if(Language == 5){
                setKashi('သင့်စာတိုက်ပုံးကို နေ့စဉ်စစ်ဆေးပါ။');
            }
            if(Language == 6){
                setKashi('প্রতিদিন আপনার মেইলবক্স চেক করুন।');
            }
        }
        if(counter >= 184.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 189.5){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn MChBChange');
            setChapterColor11('MoveChapBtn');
        }
        // ポストの確認
        if(counter >= 190.5){
            if(Language == 0){
                setKashi('ポストの確認');
            }
            if(Language == 1){
                setKashi('Confirmation of post');
            }
            if(Language == 2){
                setKashi('確認發帖');
            }
            if(Language == 3){
                setKashi('पोस्टको जाँच गर्नुहोस्');
            }
            if(Language == 4){
                setKashi('තනතුර තහවුරු කිරීම');
            }
            if(Language == 5){
                setKashi('ပို့စ်အတည်ပြုချက်');
            }
            if(Language == 6){
                setKashi('পোস্টের নিশ্চিতকরণ');
            }
        }
        if(counter >= 193.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 195.3){
            if(Language == 0){
                setKashi('寮にかえってきたら、ポストをチェックしてください。');
            }
            if(Language == 1){
                setKashi('Please check the mailbox when you return to the dormitory.');
            }
            if(Language == 2){
                setKashi('返回宿舍後請查收信箱。');
            }
            if(Language == 3){
                setKashi('जब तपाईं छात्रावासमा फर्कनुहुन्छ कृपया आफ्नो मेलबक्स जाँच गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබ නැවත නේවාසිකාගාරයට පැමිණෙන විට කරුණාකර ඔබගේ තැපැල් පෙට්ටිය පරීක්ෂා කරන්න.');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင်သို့ ပြန်ရောက်သောအခါ သင့်စာတိုက်ပုံးကို စစ်ဆေးပါ။');
            }
            if(Language == 6){
                setKashi('আপনি ডরমিটরিতে ফিরে আসার সময় দয়া করে মেইলবক্স চেক করুন।');
            }
        }
        if(counter >= 206.9){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 211){
            if(Language == 0){
                setKashi('出かけるときもチェックしてください。');
            }
            if(Language == 1){
                setKashi('Please check it when you go out.');
            }
            if(Language == 2){
                setKashi('外出時請檢查一下。');
            }
            if(Language == 3){
                setKashi('तपाईं बाहिर जाँदा पनि कृपया यसलाई जाँच गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබ පිටතට යන විට කරුණාකර එය පරීක්ෂා කරන්න.');
            }
            if(Language == 5){
                setKashi('အပြင်သွားတဲ့အခါ ကျေးဇူးပြု၍ စစ်ဆေးပါ။');
            }
            if(Language == 6){
                setKashi('আপনি যখন বাইরে যান অনুগ্রহ করে এটি পরীক্ষা করুন।');
            }
        }
        if(counter >= 221.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 223.3){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn MChBChange');
        }
        // 寮の注意事項
        if(counter >= 224){
            if(Language == 0){
                setKashi('寮の注意事項');
            }
            if(Language == 1){
                setKashi('Dormitory notes');
            }
            if(Language == 2){
                setKashi('宿舍筆記');
            }
            if(Language == 3){
                setKashi('छात्रवासमा सावधानी अपनाउनुपर्ने कुराहरू:-');
            }
            if(Language == 4){
                setKashi('නේවාසිකාගාර ගැටලු');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင်မှတ်စုများ');
            }
            if(Language == 6){
                setKashi('ছাত্রাবাস নোট');
            }
        }
        if(counter >= 227){
            if(Language == 0){
                setKashi('寮の大きい問題は２つです。');
            }
            if(Language == 1){
                setKashi('There are two major problems with dormitories.');
            }
            if(Language == 2){
                setKashi('宿舍有兩個主要問題。');
            }
            if(Language == 3){
                setKashi('छात्रावासमा दुईवटा प्रमुख समस्या छन् ।');
            }
            if(Language == 4){
                setKashi('නේවාසිකාගාරවල ප්‍රධාන ගැටලු දෙකක් තිබේ.');
            }
            if(Language == 5){
                setKashi('အဆောင်များတွင် ပြဿနာကြီးနှစ်ခုရှိသည်။');
            }
            if(Language == 6){
                setKashi('ডরমিটরিতে দুটি প্রধান সমস্যা রয়েছে।');
            }
        }
        if(counter >= 230.5){
            if(Language == 0){
                setKashi('１つは、ゴミの捨て方が悪いことです。');
            }
            if(Language == 1){
                setKashi('One, is the way of garbage disposal is wrong.');
            }
            if(Language == 2){
                setKashi('第一件是垃圾沒有妥善處理。');
            }
            if(Language == 3){
                setKashi('एउटा फोहोरको उचित व्यवस्थापन नहुनु ।');
            }
            if(Language == 4){
                setKashi('එකක් තමයි කසළ නිසි ලෙස බැහැර නොකිරීම.');
            }
            if(Language == 5){
                setKashi('တစ်ခုမှာ အမှိုက်ကို စနစ်တကျ မစွန့်ပစ်ခြင်းပင်ဖြစ်သည်။');
            }
            if(Language == 6){
                setKashi('একটি হল আমরা যেভাবে আবর্জনা ফেলি তা খারাপ।');
            }
        }
        if(counter >= 235){
            if(Language == 0){
                setKashi('ごみの捨て方のルールが寮にはってあります。ごみのスケジュールの通りに捨ててください。');
            }
            if(Language == 1){
                setKashi('Rules for how to dispose of trash are posted in the dormitory. Please dispose off garbage according to the garbage schedule.');
            }
            if(Language == 2){
                setKashi('宿舍內垃圾的處理有規定。 請依照垃圾分類表處理垃圾。');
            }
            if(Language == 3){
                setKashi('छात्रावासमा फोहोर फाल्ने तरिकाका लागि नियमहरू छन्। कृपया फोहोर फाल्ने तालिका अनुसार फोहोर फाल्ने गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('නේවාසිකාගාරයේ කසළ බැහැර කරන්නේ කෙසේද යන්න පිළිබඳ නීති තිබේ. කරුණාකර කසළ කාලසටහනට අනුව කසළ බැහැර කරන්න.');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင်မှာ အမှိုက်ဘယ်လိုစွန့်ပစ်ရမယ်ဆိုတဲ့ စည်းကမ်းတွေရှိတယ်။ အမှိုက်ကို အချိန်ဇယားအတိုင်း စွန့်ပစ်ပါ။');
            }
            if(Language == 6){
                setKashi('ডরমিটরিতে ময়লা ফেলার নিয়ম আছে। আবর্জনা সময়সূচী অনুযায়ী আবর্জনা নিষ্পত্তি করুন.');
            }
        }
        if(counter >= 242){
            if(Language == 0){
                setKashi('夜に捨てては、いけません。');
            }
            if(Language == 1){
                setKashi("Don't throw it away at night.");
            }
            if(Language == 2){
                setKashi('晚上不要把它丟掉。');
            }
            if(Language == 3){
                setKashi('यसलाई राती नफाल्नुहोस्।');
            }
            if(Language == 4){
                setKashi('රාත්රියේදී එය විසි නොකරන්න.');
            }
            if(Language == 5){
                setKashi('ညဘက် မလွှင့်ပစ်ပါနဲ့။');
            }
            if(Language == 6){
                setKashi('রাতে এটি ফেলে দেবেন না।');
            }
        }
        if(counter >= 246){
            if(Language == 0){
                setKashi('２つ目は、うるさいことです。');
            }
            if(Language == 1){
                setKashi("The second thing is that it's noisy.");
            }
            if(Language == 2){
                setKashi('第二件事是噪音。');
            }
            if(Language == 3){
                setKashi('दोस्रो कुरा यो हो कि यो कोलाहल हो।');
            }
            if(Language == 4){
                setKashi('දෙවන කරුණ නම් එය ඝෝෂාකාරී වීමයි.');
            }
            if(Language == 5){
                setKashi('ဒုတိယအချက်ကတော့ ဆူညံနေတာပါပဲ။');
            }
            if(Language == 6){
                setKashi('দ্বিতীয় জিনিসটি হল এটি কোলাহলপূর্ণ।');
            }
        }
        if(counter >= 249.8){
            if(Language == 0){
                setKashi('夜9時から、朝6時まで、まどをあけて大きいこえで話さないでください。');
            }
            if(Language == 1){
                setKashi('Please do not open the windows and speak loudly from 9pm to 6am.');
            }
            if(Language == 2){
                setKashi('请不要开着窗户大声说话。晚上 9 點至凌晨 6 點期間請勿大聲喧嘩。');
            }
            if(Language == 3){
                setKashi('कृपया आफ्ना झ्यालहरू खुल्ला राखेर  राति ९ बजेदेखि बिहान ६ बजेसम्म चर्को स्वरमा नबोल्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබේ ජනේල විවෘතව තබා ගන්න, රාත්‍රී 9 සිට උදේ 6 දක්වා හයියෙන් කතා නොකරන්න.');
            }
            if(Language == 5){
                setKashi('သင့်ပြတင်းပေါက်များကို ဖွင့်ထားပြီး ည ၉ နာရီမှ နံနက် ၆ နာရီအထိ ကျယ်လောင်စွာ မပြောပါနှင့်။');
            }
            if(Language == 6){
                setKashi('দয়া করে জানালা খুলবেন না এবং রাত 9 টা থেকে সকাল 6 টা পর্যন্ত জোরে কথা বলবেন না।');
            }
        }
        if(counter >= 258.5){
            if(Language == 0){
                setKashi('ギターや音楽もだめです。');
            }
            if(Language == 1){
                setKashi('No guitar or music allowed.');
            }
            if(Language == 2){
                setKashi('吉他和音樂也不好。');
            }
            if(Language == 3){
                setKashi('गितार र संगीत पनि बजाउन पाइँदैन।');
            }
            if(Language == 4){
                setKashi('ගිටාර් සහ සංගීතය ද හොඳ නැත.');
            }
            if(Language == 5){
                setKashi('ဂစ်တာ နဲ့ သီချင်းလည်း တီးဆိုခြင်းမပြုရန်။');
            }
            if(Language == 6){
                setKashi('কোন গিটার বা সঙ্গীত অনুমোদিত.');
            }
        }
        if(counter >= 263.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
    }

    const timeSetMsg1 = () => {
        document.getElementById('video').currentTime = 0;
    }
    const timeSetMsg2 = () => {
        document.getElementById('video').currentTime = 23;
    }
    const timeSetMsg3 = () => {
        document.getElementById('video').currentTime = 50;
    }
    const timeSetMsg4 = () => {
        document.getElementById('video').currentTime = 71;
    }
    const timeSetMsg5 = () => {
        document.getElementById('video').currentTime = 86.5;
    }
    const timeSetMsg6 = () => {
        document.getElementById('video').currentTime = 108.5;
    }
    const timeSetMsg7 = () => {
        document.getElementById('video').currentTime = 123;
    }
    const timeSetMsg8 = () => {
        document.getElementById('video').currentTime = 140;
    }
    const timeSetMsg9 = () => {
        document.getElementById('video').currentTime = 170.5;
    }
    const timeSetMsg10 = () => {
        document.getElementById('video').currentTime = 189.5;
    }
    const timeSetMsg11 = () => {
        document.getElementById('video').currentTime = 223.5;
    }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            setLogInNo(LogInNo);
            navigate('/');
        }
    })

    const HomeReset = () => {
        setTimeVI(0);
        setLogInNo(LogInNo);
        navigate('/');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <img src={Titleimg} className='MenuLogo' onClick={() => {HomeReset()}}/>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                        <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                        <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                        <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>Chp4</div>
                        <div className={ChapterColor5} onClick={() => {timeSetMsg5()}}>Chp5</div>
                    </div>
                    <div className='MoveChapArea'>
                        <div className={ChapterColor6} onClick={() => {timeSetMsg6()}}>Chp6</div>
                        <div className={ChapterColor7} onClick={() => {timeSetMsg7()}}>Chp7</div>
                        <div className={ChapterColor8} onClick={() => {timeSetMsg8()}}>Chp8</div>
                        <div className={ChapterColor9} onClick={() => {timeSetMsg9()}}>Chp9</div>
                        <div className={ChapterColor10} onClick={() => {timeSetMsg10()}}>Chp10</div>
                    </div>
                    <div className='MoveChapArea'>
                        <div className={ChapterColor11} onClick={() => {timeSetMsg11()}}>Chp11</div>
                    </div>
                    <video src={DorRuhr} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                    <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                    <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                    <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>Chp4</div>
                    <div className={ChapterColor5} onClick={() => {timeSetMsg5()}}>Chp5</div>
                </div>
                <div className='MoveChapArea3'>
                    <div className={ChapterColor6} onClick={() => {timeSetMsg6()}}>Chp6</div>
                    <div className={ChapterColor7} onClick={() => {timeSetMsg7()}}>Chp7</div>
                    <div className={ChapterColor8} onClick={() => {timeSetMsg8()}}>Chp8</div>
                    <div className={ChapterColor9} onClick={() => {timeSetMsg9()}}>Chp9</div>
                    <div className={ChapterColor10} onClick={() => {timeSetMsg10()}}>Chp10</div>
                </div>
                <div className='MoveChapArea3'>
                    <div className={ChapterColor11} onClick={() => {timeSetMsg11()}}>Chp11</div>
                </div>
            </div>
        </div>
    )
}