import '../CSS/Style.css';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { signInWithPopup, signOut } from 'firebase/auth';
import { auth, provider, db } from '../firebase';
import { addDoc, collection } from 'firebase/firestore';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import GREETSImg from '../Images/GREETS.svg';
import TOURImg from '../Images/TOUR.svg';
import VISAImg from '../Images/VISA.svg';
import CARDImg from '../Images/CARD.svg';
import APPImg from '../Images/APP.svg';
import DAILYImg from '../Images/DAILY.svg';
import CERTImg from '../Images/CERT.svg';
import CLASSImg from '../Images/CLASS.svg';
import ROOMImg from '../Images/ROOM.svg';
import SCHOOLImg from '../Images/SCHOOL.svg';
import DORMImg from '../Images/DORM.svg';


export default function Home ({ Language, setLanguage, isAuth, setIsAuth, TimeVI, setTimeVI, LogInNo, setLogInNo }) {

    const navigate = useNavigate();

    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    const [VISA, setVISA] = useState('SelectQChapter ');
    const [VISAChap, setVISAChap] = useState('noneArea');
    const [WSDB, setWSDB] = useState('SelectQChapter');
    const [WSDBChap, setWSDBChap] = useState('noneArea');
    const [SchoolLife, setSchoolLife] = useState('SelectQChapter');
    const [SchoolLifeChap, setSchoolLifeChap] = useState('noneArea');
    const [Proof, setProof] = useState('SelectQChapter');
    const [ProofChap, setProofChap] = useState('noneArea');
    const [Lesson, setLesson] = useState('SelectQChapter');
    const [LessonChap, setLessonChap] = useState('noneArea');
    const [Learning, setLearning] = useState('SelectQChapter');
    const [LearningChap, setLearningChap] = useState('noneArea');
    const [Ruhr, setRuhr] = useState('SelectQChapter');
    const [RuhrChap, setRuhrChap] = useState('noneArea');
    const [DorRuhr, setDorRuhr] = useState('SelectQChapter');
    const [DorRuhrChap, setDorRuhrChap] = useState('noneArea');

    //ビザチャプター
    const VISAClick = () => {
        setVISA('noneArea');
        setWSDB('SelectQChapter');
        setSchoolLife('SelectQChapter');
        setProof('SelectQChapter');
        setLesson('SelectQChapter');
        setLearning('SelectQChapter');
        setRuhr('SelectQChapter');
        setDorRuhr('SelectQChapter');

        setVISAChap('ChapText');
        setWSDBChap('noneArea');
        setSchoolLifeChap('noneArea');
        setProofChap('noneArea');
        setLessonChap('noneArea');
        setLearningChap('noneArea');
        setRuhrChap('noneArea');
        setDorRuhrChap('noneArea');
    }
    const VISACh1 = () => {
        setTimeVI(0);
        navigate('/VISAMove');
    }
    const VISACh2 = () => {
        setTimeVI(21.5);
        navigate('/VISAMove');
    }
    const VISACh3 = () => {
        setTimeVI(53.7);
        navigate('/VISAMove');
    }
    const VISACh4 = () => {
        setTimeVI(75);
        navigate('/VISAMove');
    }
    const VISACh5 = () => {
        setTimeVI(97);
        navigate('/VISAMove');
    }
    const VISACh6 = () => {
        setTimeVI(115.5);
        navigate('/VISAMove');
    }
    const VISACh7 = () => {
        setTimeVI(146.5);
        navigate('/VISAMove');
    }

    //WSDBチャプター
    const WSDBClick = () => {
        setVISA('SelectQChapter');
        setWSDB('noneArea');
        setSchoolLife('SelectQChapter');
        setProof('SelectQChapter');
        setLesson('SelectQChapter');
        setLearning('SelectQChapter');
        setRuhr('SelectQChapter');
        setDorRuhr('SelectQChapter');

        setVISAChap('noneArea');
        setWSDBChap('ChapText');
        setSchoolLifeChap('noneArea');
        setProofChap('noneArea');
        setLessonChap('noneArea');
        setLearningChap('noneArea');
        setRuhrChap('noneArea');
        setDorRuhrChap('noneArea');
    }

    const WSDBCh1 = () => {
        setTimeVI(16);
        navigate('/CardMove');
    }
    const WSDBCh2 = () => {
        setTimeVI(29);
        navigate('/CardMove');
    }
    const WSDBCh3 = () => {
        setTimeVI(78.5);
        navigate('/CardMove');
    }

    //学生生活チャプター
    const SchoolLifeClick = () => {
        setVISA('SelectQChapter');
        setWSDB('SelectQChapter');
        setSchoolLife('noneArea');
        setProof('SelectQChapter');
        setLesson('SelectQChapter');
        setLearning('SelectQChapter');
        setRuhr('SelectQChapter');
        setDorRuhr('SelectQChapter');

        setVISAChap('noneArea');
        setWSDBChap('noneArea');
        setSchoolLifeChap('ChapText');
        setProofChap('noneArea');
        setLessonChap('noneArea');
        setLearningChap('noneArea');
        setRuhrChap('noneArea');
        setDorRuhrChap('noneArea');
    }

    const ScLiCh1 = () => {
        setTimeVI(0);
        navigate('/SchoolLifeMove');
    }
    const ScLiCh2 = () => {
        setTimeVI(19.5);
        navigate('/SchoolLifeMove');
    }
    const ScLiCh3 = () => {
        setTimeVI(45.5);
        navigate('/SchoolLifeMove');
    }
    const ScLiCh4 = () => {
        setTimeVI(76);
        navigate('/SchoolLifeMove');
    }
    const ScLiCh5 = () => {
        setTimeVI(106);
        navigate('/SchoolLifeMove');
    }
    const ScLiCh6 = () => {
        setTimeVI(131);
        navigate('/SchoolLifeMove');
    }
    const ScLiCh7 = () => {
        setTimeVI(147.5);
        navigate('/SchoolLifeMove');
    }
    const ScLiCh8 = () => {
        setTimeVI(163);
        navigate('/SchoolLifeMove');
    }
    const ScLiCh9 = () => {
        setTimeVI(186.5);
        navigate('/SchoolLifeMove');
    }
    const ScLiCh10 = () => {
        setTimeVI(209.5);
        navigate('/SchoolLifeMove');
    }
    const ScLiCh11 = () => {
        setTimeVI(225);
        navigate('/SchoolLifeMove');
    }
    const ScLiCh12 = () => {
        setTimeVI(263);
        navigate('/SchoolLifeMove');
    }
    const ScLiCh13 = () => {
        setTimeVI(364.3);
        navigate('/SchoolLifeMove');
    }

    //証明書チャプター
    const ProofClick = () => {
        setVISA('SelectQChapter');
        setWSDB('SelectQChapter');
        setSchoolLife('SelectQChapter');
        setProof('noneArea');
        setLesson('SelectQChapter');
        setLearning('SelectQChapter');
        setRuhr('SelectQChapter');
        setDorRuhr('SelectQChapter');

        setVISAChap('noneArea');
        setWSDBChap('noneArea');
        setSchoolLifeChap('noneArea');
        setProofChap('ChapText');
        setLessonChap('noneArea');
        setLearningChap('noneArea');
        setRuhrChap('noneArea');
        setDorRuhrChap('noneArea');
    }

    const ProCh1 = () => {
        setTimeVI(0);
        navigate('/ProofMove');
    }
    const ProCh2 = () => {
        setTimeVI(29);
        navigate('/ProofMove');
    }
    const ProCh3 = () => {
        setTimeVI(58);
        navigate('/ProofMove');
    }

    //教室チャプター
    const LearningClick = () => {
        setVISA('SelectQChapter');
        setWSDB('SelectQChapter');
        setSchoolLife('SelectQChapter');
        setProof('SelectQChapter');
        setLesson('SelectQChapter');
        setLearning('noneArea');
        setRuhr('SelectQChapter');
        setDorRuhr('SelectQChapter');

        setVISAChap('noneArea');
        setWSDBChap('noneArea');
        setSchoolLifeChap('noneArea');
        setProofChap('noneArea');
        setLessonChap('noneArea');
        setLearningChap('ChapText');
        setRuhrChap('noneArea');
        setDorRuhrChap('noneArea');
    }

    const LesCh1 = () => {
        setTimeVI(0);
        navigate('/LessonMove');
    }
    const LesCh2 = () => {
        setTimeVI(68);
        navigate('/LessonMove');
    }
    const LesCh3 = () => {
        setTimeVI(130);
        navigate('/LessonMove');
    }
    const LesCh4 = () => {
        setTimeVI(158.5);
        navigate('/LessonMove');
    }
    const LesCh5 = () => {
        setTimeVI(188);
        navigate('/LessonMove');
    }
    const LesCh6 = () => {
        setTimeVI(233);
        navigate('/LessonMove');
    }

    //授業チャプター
    const LessonClick = () => {
        setVISA('SelectQChapter');
        setWSDB('SelectQChapter');
        setSchoolLife('SelectQChapter');
        setProof('SelectQChapter');
        setLesson('noneArea');
        setLearning('SelectQChapter');
        setRuhr('SelectQChapter');
        setDorRuhr('SelectQChapter');

        setVISAChap('noneArea');
        setWSDBChap('noneArea');
        setSchoolLifeChap('noneArea');
        setProofChap('noneArea');
        setLessonChap('ChapText');
        setLearningChap('noneArea');
        setRuhrChap('noneArea');
        setDorRuhrChap('noneArea');
    }

    const RuhrCh1 = () => {
        setTimeVI(0);
        navigate('/RuhrMove');
    }
    const RuhrCh2 = () => {
        setTimeVI(48);
        navigate('/RuhrMove');
    }
    const RuhrCh3 = () => {
        setTimeVI(108);
        navigate('/RuhrMove');
    }
    const RuhrCh4 = () => {
        setTimeVI(154);
        navigate('/RuhrMove');
    }
    const RuhrCh5 = () => {
        setTimeVI(205);
        navigate('/RuhrMove');
    }

    //学校チャプター
    const RuhrClick = () => {
        setVISA('SelectQChapter');
        setWSDB('SelectQChapter');
        setSchoolLife('SelectQChapter');
        setProof('SelectQChapter');
        setLesson('SelectQChapter');
        setLearning('SelectQChapter');
        setRuhr('noneArea');
        setDorRuhr('SelectQChapter');

        setVISAChap('noneArea');
        setWSDBChap('noneArea');
        setSchoolLifeChap('noneArea');
        setProofChap('noneArea');
        setLessonChap('noneArea');
        setLearningChap('noneArea');
        setRuhrChap('ChapText');
        setDorRuhrChap('noneArea');
    }

    const ScRuhrCh1 = () => {
        setTimeVI(0);
        navigate('/SchoolRuhr');
    }
    const ScRuhrCh2 = () => {
        setTimeVI(31.5);
        navigate('/SchoolRuhr');
    }
    const ScRuhrCh3 = () => {
        setTimeVI(58.5);
        navigate('/SchoolRuhr');
    }

     //寮チャプター
     const DorRuhrClick = () => {
        setVISA('SelectQChapter');
        setWSDB('SelectQChapter');
        setSchoolLife('SelectQChapter');
        setProof('SelectQChapter');
        setLesson('SelectQChapter');
        setLearning('SelectQChapter');
        setRuhr('SelectQChapter');
        setDorRuhr('noneArea');

        setVISAChap('noneArea');
        setWSDBChap('noneArea');
        setSchoolLifeChap('noneArea');
        setProofChap('noneArea');
        setLessonChap('noneArea');
        setLearningChap('noneArea');
        setRuhrChap('noneArea');
        setDorRuhrChap('ChapText');
    }
    
    const DRuhrCh1 = () => {
        setTimeVI(0);
        navigate('/DorRuhrMove');
    }
    const DRuhrCh2 = () => {
        setTimeVI(23);
        navigate('/DorRuhrMove');
    }
    const DRuhrCh3 = () => {
        setTimeVI(50);
        navigate('/DorRuhrMove');
    }
    const DRuhrCh4 = () => {
        setTimeVI(71);
        navigate('/DorRuhrMove');
    }
    const DRuhrCh5 = () => {
        setTimeVI(86.5);
        navigate('/DorRuhrMove');
    }
    const DRuhrCh6 = () => {
        setTimeVI(108.5);
        navigate('/DorRuhrMove');
    }
    const DRuhrCh7 = () => {
        setTimeVI(123);
        navigate('/DorRuhrMove');
    }
    const DRuhrCh8 = () => {
        setTimeVI(140);
        navigate('/DorRuhrMove');
    }
    const DRuhrCh9 = () => {
        setTimeVI(170.5);
        navigate('/DorRuhrMove');
    }
    const DRuhrCh10 = () => {
        setTimeVI(189.5);
        navigate('/DorRuhrMove');
    }
    const DRuhrCh11 = () => {
        setTimeVI(223.5);
        navigate('/DorRuhrMove');
    }

    const JouACh = () => {
        setTimeVI(0);
        navigate('/JourneyAMove');
    }

    const JouBCh = () => {
        setTimeVI(0);
        navigate('/JourneyBMove');
    }

    const DorToCh = () => {
        setTimeVI(0);
        navigate('/DormitoryTourMove');
    }

    const ElectCh1 = () => {
        setTimeVI(0);
        navigate('/ElectronicsMove');
    }
    const ElectCh2 = () => {
        setTimeVI(53.3);
        navigate('/ElectronicsMove');
    }
    const ElectCh3 = () => {
        setTimeVI(85.5);
        navigate('/ElectronicsMove');
    }
    const ElectCh4 = () => {
        setTimeVI(105);
        navigate('/ElectronicsMove');
    }

    // 問題集内の文字
    const [QuestionText1, setQuestionText1] = useState('校長のあいさつ');
    const [QuestionText2, setQuestionText2] = useState('学内ツアー');
    const [QuestionText3, setQuestionText3] = useState('ビザ');
    const [QuestionText4, setQuestionText4] = useState('学生証');
    const [QuestionText5, setQuestionText5] = useState('WSDB');
    const [QuestionText6, setQuestionText6] = useState('学生生活');
    const [QuestionText7, setQuestionText7] = useState('証明書');
    const [QuestionText8, setQuestionText8] = useState('授業について');
    const [QuestionText9, setQuestionText9] = useState('教室のルール');
    const [QuestionText10, setQuestionText10] = useState('学校のルール');
    const [QuestionText11, setQuestionText11] = useState('寮のルール');
    const [QuestionText12, setQuestionText12] = useState('寮までの道のり');
    const [QuestionText13, setQuestionText13] = useState('寮ツアー');
    const [QuestionText14, setQuestionText14] = useState('家電');
    const [QuestionTextChapter, setQuestionTextChapter] = useState('チャプター');
    const [QuestionText3_1, setQuestionText3_1] = useState('在留カード');
    const [QuestionText3_2, setQuestionText3_2] = useState('ビザとアルバイト');
    const [QuestionText3_3, setQuestionText3_3] = useState('国民健康保険');
    const [QuestionText3_4, setQuestionText3_4] = useState('年金');
    const [QuestionText3_5, setQuestionText3_5] = useState('ビザと給与明細');
    const [QuestionText3_6, setQuestionText3_6] = useState('出席とビザ');
    const [QuestionText3_7, setQuestionText3_7] = useState('アルバイト');
    const [QuestionText5_1, setQuestionText5_1] = useState('アプリ紹介');
    const [QuestionText5_2, setQuestionText5_2] = useState('ダウンロード');
    const [QuestionText5_3, setQuestionText5_3] = useState('アプリの使い方');
    const [QuestionText6_1, setQuestionText6_1] = useState('住所変更');
    const [QuestionText6_2, setQuestionText6_2] = useState('暴力');
    const [QuestionText6_3, setQuestionText6_3] = useState('反省文');
    const [QuestionText6_4, setQuestionText6_4] = useState('警察');
    const [QuestionText6_5, setQuestionText6_5] = useState('退学');
    const [QuestionText6_6, setQuestionText6_6] = useState('はんこ');
    const [QuestionText6_7, setQuestionText6_7] = useState('学校のお金①');
    const [QuestionText6_8, setQuestionText6_8] = useState('学校のお金②');
    const [QuestionText6_9, setQuestionText6_9] = useState('校外学習');
    const [QuestionText6_10, setQuestionText6_10] = useState('成績');
    const [QuestionText6_11, setQuestionText6_11] = useState('卒業条件');
    const [QuestionText6_12, setQuestionText6_12] = useState('たばこ');
    const [QuestionText6_13, setQuestionText6_13] = useState('自転車');
    const [QuestionText7_1, setQuestionText7_1] = useState('証明書の値段');
    const [QuestionText7_2, setQuestionText7_2] = useState('卒業見込み証明書');
    const [QuestionText7_3, setQuestionText7_3] = useState('推薦書');
    const [QuestionText8_1, setQuestionText8_1] = useState('授業時間と受付');
    const [QuestionText8_2, setQuestionText8_2] = useState('テスト');
    const [QuestionText8_3, setQuestionText8_3] = useState('教科書');
    const [QuestionText8_4, setQuestionText8_4] = useState('名札');
    const [QuestionText8_5, setQuestionText8_5] = useState('あいさつ');
    const [QuestionText8_6, setQuestionText8_6] = useState('ミニテスト');
    const [QuestionText9_1, setQuestionText9_1] = useState('日本語で話そう');
    const [QuestionText9_2, setQuestionText9_2] = useState('携帯電話');
    const [QuestionText9_3, setQuestionText9_3] = useState('飲食');
    const [QuestionText9_4, setQuestionText9_4] = useState('帽子・上着');
    const [QuestionText9_5, setQuestionText9_5] = useState('姿勢');
    const [QuestionText10_1, setQuestionText10_1] = useState('教科書を忘れたとき');
    const [QuestionText10_2, setQuestionText10_2] = useState('遅刻（ちこく）');
    const [QuestionText10_3, setQuestionText10_3] = useState('休むときの連絡');
    const [QuestionText11_1, setQuestionText11_1] = useState('ルール説明');
    const [QuestionText11_2, setQuestionText11_2] = useState('入ってもいい人');
    const [QuestionText11_3, setQuestionText11_3] = useState('契約');
    const [QuestionText11_4, setQuestionText11_4] = useState('寮のお金');
    const [QuestionText11_5, setQuestionText11_5] = useState('エネルギーの節約');
    const [QuestionText11_6, setQuestionText11_6] = useState('寮の物がこわれたとき');
    const [QuestionText11_7, setQuestionText11_7] = useState('かぎをなくしたとき');
    const [QuestionText11_8, setQuestionText11_8] = useState('寮をきれいに使う');
    const [QuestionText11_9, setQuestionText11_9] = useState('ゆうびん受けの確認');
    const [QuestionText11_10, setQuestionText11_10] = useState('ポストの確認');
    const [QuestionText11_11, setQuestionText11_11] = useState('寮でよくあるトラブル');
    const [QuestionText12_1, setQuestionText12_1] = useState('尾上');
    const [QuestionText12_2, setQuestionText12_2] = useState('大幸東');
    const [QuestionText14_1, setQuestionText14_1] = useState('コンロ');
    const [QuestionText14_2, setQuestionText14_2] = useState('エアコン');
    const [QuestionText14_3, setQuestionText14_3] = useState('洗濯機');
    const [QuestionText14_4, setQuestionText14_4] = useState('おふろ');

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setQuestionText1('校長のあいさつ');
            setQuestionText2('学内ツアー');
            setQuestionText3('ビザ');
            setQuestionText4('学生証');
            setQuestionText5('WSDB');
            setQuestionText6('学生生活');
            setQuestionText7('証明書');
            setQuestionText8('授業について');
            setQuestionText9('教室のルール');
            setQuestionText10('学校のルール');
            setQuestionText11('寮のルール');
            setQuestionText12('寮までの道のり');
            setQuestionText13('寮ツアー');
            setQuestionText14('家電');
            setQuestionTextChapter('チャプター');
            setQuestionText3_1('在留カード');
            setQuestionText3_2('ビザとアルバイト');
            setQuestionText3_3('国民健康保険');
            setQuestionText3_4('年金');
            setQuestionText3_5('ビザと給与明細');
            setQuestionText3_6('出席とビザ');
            setQuestionText3_7('アルバイト');
            setQuestionText5_1('アプリ紹介');
            setQuestionText5_2('ダウンロード');
            setQuestionText5_3('アプリの使い方');
            setQuestionText6_1('住所変更');
            setQuestionText6_2('暴力');
            setQuestionText6_3('反省文');
            setQuestionText6_4('警察');
            setQuestionText6_5('退学');
            setQuestionText6_6('はんこ');
            setQuestionText6_7('学校のお金①');
            setQuestionText6_8('学校のお金②');
            setQuestionText6_9('校外学習');
            setQuestionText6_10('成績');
            setQuestionText6_11('卒業条件');
            setQuestionText6_12('たばこ');
            setQuestionText6_13('自転車');
            setQuestionText7_1('証明書の値段');
            setQuestionText7_2('卒業見込み証明書');
            setQuestionText7_3('推薦書');
            setQuestionText8_1('授業時間と受付');
            setQuestionText8_2('テスト');
            setQuestionText8_3('教科書');
            setQuestionText8_4('名札');
            setQuestionText8_5('あいさつ');
            setQuestionText8_6('ミニテスト');
            setQuestionText9_1('日本語で話そう');
            setQuestionText9_2('携帯電話');
            setQuestionText9_3('飲食');
            setQuestionText9_4('帽子・上着');
            setQuestionText9_5('姿勢');
            setQuestionText10_1('教科書を忘れたとき');
            setQuestionText10_2('遅刻（ちこく）');
            setQuestionText10_3('休むときの連絡');
            setQuestionText11_1('ルール説明');
            setQuestionText11_2('入ってもいい人');
            setQuestionText11_3('契約');
            setQuestionText11_4('寮のお金');
            setQuestionText11_5('エネルギーの節約');
            setQuestionText11_6('寮の物がこわれたとき');
            setQuestionText11_7('かぎをなくしたとき');
            setQuestionText11_8('寮をきれいに使う');
            setQuestionText11_9('ゆうびん受けの確認');
            setQuestionText11_10('ポストの確認');
            setQuestionText11_11('寮でよくあるトラブル');
            setQuestionText12_1('尾上');
            setQuestionText12_2('大幸東');
            setQuestionText14_1('コンロ');
            setQuestionText14_2('エアコン');
            setQuestionText14_3('洗濯機');
            setQuestionText14_4('おふろ');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setQuestionText1('Greetings from the principal');
            setQuestionText2('Campus tour');
            setQuestionText3('visa');
            setQuestionText4('Student ID card');
            setQuestionText5('WSDB');
            setQuestionText6('student life');
            setQuestionText7('credentials');
            setQuestionText8('About the Class');
            setQuestionText9('Classroom Rules');
            setQuestionText10('School Rules');
            setQuestionText11('Dormitory Rules');
            setQuestionText12('Getting to the dormitory');
            setQuestionText13('Dorm Tour');
            setQuestionText14('consumer electronics');
            setQuestionTextChapter('chapter');
            setQuestionText3_1('Residence card');
            setQuestionText3_2('Visa and part-time job');
            setQuestionText3_3('National Health Insurance');
            setQuestionText3_4('Pension');
            setQuestionText3_5('Visa and pay slip');
            setQuestionText3_6('Attendance and visa');
            setQuestionText3_7('Attendance and visa');
            setQuestionText5_1('Application introduction');
            setQuestionText5_2('download');
            setQuestionText5_3('How to use the app');
            setQuestionText6_1('Change of residency etc.');
            setQuestionText6_2('Violence');
            setQuestionText6_3('Letter of apology');
            setQuestionText6_4('Police');
            setQuestionText6_5('Withdrawal from school');
            setQuestionText6_6('Stamp');
            setQuestionText6_7('Tuition fee①');
            setQuestionText6_8('Tuition fee②');
            setQuestionText6_9('Field trip');
            setQuestionText6_10('Grades evaluation');
            setQuestionText6_11('Requirements for graduation');
            setQuestionText6_12('Tobacco');
            setQuestionText6_13('Bicycle');
            setQuestionText7_1('certificate');
            setQuestionText7_2('Certificate of expected graduation');
            setQuestionText7_3('letter of recommendation');
            setQuestionText8_1('attendance');
            setQuestionText8_2('test');
            setQuestionText8_3('textbook');
            setQuestionText8_4('name tag');
            setQuestionText8_5('Greetings at the start and end of class');
            setQuestionText8_6('mini test');
            setQuestionText9_1("Let's speak in Japanese");
            setQuestionText9_2('mobile phone');
            setQuestionText9_3('Eating and drinking');
            setQuestionText9_4('hat/coat');
            setQuestionText9_5('posture');
            setQuestionText10_1('When you forget your textbook');
            setQuestionText10_2('Being late');
            setQuestionText10_3('Informing of absences');
            setQuestionText11_1('rule explanation');
            setQuestionText11_2('People who can stay in the dormitory');
            setQuestionText11_3('dormitory contract');
            setQuestionText11_4('dormitory money');
            setQuestionText11_5('energy savings');
            setQuestionText11_6('When you broke something in the dormitory');
            setQuestionText11_7('When you lose your keys');
            setQuestionText11_8('Use the dormitory neatly');
            setQuestionText11_9('Check your mailbox');
            setQuestionText11_10('Confirmation of post');
            setQuestionText11_11('Dormitory notes');
            setQuestionText12_1('Onoe Danchi');
            setQuestionText12_2('Taiko Higashi Danchi');
            setQuestionText14_1('How to use the stove');
            setQuestionText14_2('How to use the air conditioner');
            setQuestionText14_3('How to use a washing machine');
            setQuestionText14_4('How to use the bath');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setQuestionText1('校長的問候');
            setQuestionText2('校園參觀');
            setQuestionText3('簽證');
            setQuestionText4('學生卡');
            setQuestionText5('WSDB');
            setQuestionText6('學生生活');
            setQuestionText7('证明书');
            setQuestionText8('关于课程');
            setQuestionText9('课堂规则');
            setQuestionText10('校规');
            setQuestionText11('宿舍规则');
            setQuestionText12('去宿舍的路');
            setQuestionText13('宿舍参观');
            setQuestionText14('家用电器');
            setQuestionTextChapter('章节');
            setQuestionText3_1('居留證');
            setQuestionText3_2('簽證和兼職工作');
            setQuestionText3_3('國民健康保險');
            setQuestionText3_4('退休金（養老金）');
            setQuestionText3_5('簽證和薪資單');
            setQuestionText3_6('出席和簽證');
            setQuestionText3_7('兼職工作');
            setQuestionText5_1('应用介绍');
            setQuestionText5_2('下載');
            setQuestionText5_3('如何使用該App');
            setQuestionText6_1('地址變更等');
            setQuestionText6_2('暴力');
            setQuestionText6_3('反思陳述');
            setQuestionText6_4('警察');
            setQuestionText6_5('被學校開除');
            setQuestionText6_6('印章');
            setQuestionText6_7('學費①');
            setQuestionText6_8('學費②');
            setQuestionText6_9('校園旅行');
            setQuestionText6_10('成績');
            setQuestionText6_11('畢業條件');
            setQuestionText6_12('菸草');
            setQuestionText6_13('自行車');
            setQuestionText7_1('证书价格');
            setQuestionText7_2('预计毕业证明');
            setQuestionText7_3('推荐信');
            setQuestionText8_1('出席率');
            setQuestionText8_2('測試');
            setQuestionText8_3('教科書');
            setQuestionText8_4('名稱標籤');
            setQuestionText8_5('上課開始和結束時的問候');
            setQuestionText8_6('迷你測試');
            setQuestionText9_1('我們來說說日語吧');
            setQuestionText9_2('智慧型手機');
            setQuestionText9_3('飲食');
            setQuestionText9_4('帽子/外套');
            setQuestionText9_5('姿勢');
            setQuestionText10_1('當你忘記帶課本時');
            setQuestionText10_2('落後於時間');
            setQuestionText10_3('休息時聯繫');
            setQuestionText11_1('规则解释');
            setQuestionText11_2('可以進入宿舍的人');
            setQuestionText11_3('宿舍合約');
            setQuestionText11_4('宿舍錢');
            setQuestionText11_5('節能');
            setQuestionText11_6('當我在宿舍打破東西時');
            setQuestionText11_7('當你丟失鑰匙時');
            setQuestionText11_8('宿舍使用整齊');
            setQuestionText11_9('檢查您的信箱');
            setQuestionText11_10('確認發帖');
            setQuestionText11_11('宿舍筆記');
            setQuestionText12_1('尾上團地');
            setQuestionText12_2('大幸東團地');
            setQuestionText14_1('瓦斯爐的使用方法');
            setQuestionText14_2('空調的使用方法');
            setQuestionText14_3('洗衣機的使用方法');
            setQuestionText14_4('浴缸的使用方法');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setQuestionText1('प्रिन्सिपल बाट शुभकामना');
            setQuestionText2('क्याम्पस भ्रमण');
            setQuestionText3('भिसा');
            setQuestionText4('विद्यार्थी कार्ड');
            setQuestionText5('WSDB');
            setQuestionText6('विद्यार्थी जीवन');
            setQuestionText7('प्रमाणपत्र');
            setQuestionText8('कक्षाको बारेमा');
            setQuestionText9('कक्षा नियमहरू');
            setQuestionText10('विद्यालय नियम');
            setQuestionText11('छात्रावास नियम');
            setQuestionText12('छात्रावास जाने बाटो');
            setQuestionText13('छात्रावास भ्रमण');
            setQuestionText14('घरेलु उपकरणहरू');
            setQuestionTextChapter('अध्याय');
            setQuestionText3_1('निवास कार्ड');
            setQuestionText3_2('भिसा र पार्ट टाइम काम');
            setQuestionText3_3('राष्ट्रिय स्वास्थ्य बीमा');
            setQuestionText3_4('पेन्सन（वृदा भत्ता）');
            setQuestionText3_5('भिसा र पे स्लिप');
            setQuestionText3_6('उपस्थिति र भिसा');
            setQuestionText3_7('पार्ट टाइम काम');
            setQuestionText5_1('आवेदन परिचय');
            setQuestionText5_2('डाउनलोड गर्नुहोस्');
            setQuestionText5_3('एप कसरी प्रयोग गर्ने');
            setQuestionText6_1('ठेगाना परिवर्तन आदि।');
            setQuestionText6_2('हिंसात्मक क्रियाकलाप');
            setQuestionText6_3('प्रतिबिम्ब बयान');
            setQuestionText6_4('प्रहरी');
            setQuestionText6_5('विद्यालयबाट निष्कासन');
            setQuestionText6_6('हस्ताक्षर छाप');
            setQuestionText6_7('विद्यालयको पैसा①');
            setQuestionText6_8('विद्यालयको पैसा②');
            setQuestionText6_9('फिल्ड भ्रमण');
            setQuestionText6_10('ग्रेडहरू');
            setQuestionText6_11('स्नातक हुनको लागि पूरा गर्नुपर्ने शर्तहरू');
            setQuestionText6_12('धुम्रपान');
            setQuestionText6_13('साइकल');
            setQuestionText7_1('प्रमाणपत्र मूल्य');
            setQuestionText7_2('अपेक्षित स्नातकको प्रमाणपत्र');
            setQuestionText7_3('सिफारिस पत्र');
            setQuestionText8_1('कक्षा सञ्चालन हुने समय र हाजिर गर्ने ठाउँ');
            setQuestionText8_2('परीक्षा');
            setQuestionText8_3('पाठ्यपुस्तक');
            setQuestionText8_4('नाम लेखिएको कार्ड');
            setQuestionText8_5('कक्षाको सुरु र अन्त्यमा अभिवादन');
            setQuestionText8_6('मिनी परीक्षण');
            setQuestionText9_1('जापानी भाषामा बोल्ने बानी बसालौँ।');
            setQuestionText9_2('मोबाइल फोन');
            setQuestionText9_3('खाने र पिउने');
            setQuestionText9_4('टोपी/कोट/ज्याकेट');
            setQuestionText9_5('आसन');
            setQuestionText10_1('जब तपाईं आफ्नो पाठ्यपुस्तक बिर्सनुहुन्छ');
            setQuestionText10_2('ढिलो उपस्थित');
            setQuestionText10_3('बिदा लिँदा सम्पर्क गर्नुहोस्।');
            setQuestionText11_1('नियम व्याख्या');
            setQuestionText11_2('छात्रावासमा बस्न सक्ने मानिसहरू');
            setQuestionText11_3('छात्रावास सम्झौता');
            setQuestionText11_4('छात्रावास पैसा');
            setQuestionText11_5('जब तपाईं छात्रावासमा केहि तोड्नुहुन्छ');
            setQuestionText11_6('जब मैले छात्रावासमा केहि तोडें');
            setQuestionText11_7('जब तपाईं आफ्नो चाबी हराउनुहुन्छ');
            setQuestionText11_8('सफा सँग छात्रावास प्रयोग गर्नुहोस्');
            setQuestionText11_9('आफ्नो मेलबक्सको जाँच गर्नुहोस्');
            setQuestionText11_10('पोस्टको जाँच गर्नुहोस्');
            setQuestionText11_11('छात्रवासमा सावधानी अपनाउनुपर्ने कुराहरू:-');
            setQuestionText12_1('ओनोउए दान्ची');
            setQuestionText12_2('दाइको हिगाशि दान्ची');
            setQuestionText14_1('ग्याँस स्टोभ प्रयोग गर्ने तरीका');
            setQuestionText14_2('एयर कंडीशनर प्रयोग गर्ने तरीका');
            setQuestionText14_3('लुगा धुने मेसिन प्रयोग गर्ने तरीका');
            setQuestionText14_4('नुहाउने ठाउँ प्रयोग गर्ने तरिका');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setQuestionText1('විදුහල්පතිතුමාගෙන් සුභ පැතුම්');
            setQuestionText2('කැම්පස් සංචාරය');
            setQuestionText3('වීසා');
            setQuestionText4('ශිෂ්ය කාඩ්පත');
            setQuestionText5('WSDB');
            setQuestionText6('ශිෂ්ය ජීවිතය');
            setQuestionText7('සහතිකය');
            setQuestionText8('පන්ති ගැන');
            setQuestionText9('පන්ති කාමර නීති');
            setQuestionText10('පාසල් නීති');
            setQuestionText11('නේවාසිකාගාර නීති');
            setQuestionText12('නේවාසිකාගාරයට යන මාර්ගය');
            setQuestionText13('නේවාසිකාගාර චාරිකාව');
            setQuestionText14('ගෘහ උපකරණ');
            setQuestionTextChapter('පරිච්ඡේදය');
            setQuestionText3_1('පදිංචි කාඩ්පත');
            setQuestionText3_2('වීසා සහ අර්ධකාලීන රැකියාව');
            setQuestionText3_3('ජාතික සෞඛ්ය රක්ෂණය');
            setQuestionText3_4('විශ්රාම වැටුප්');
            setQuestionText3_5('වීසා සහ ගෙවීම් පත්‍රිකාව');
            setQuestionText3_6('පැමිණීම සහ වීසා');
            setQuestionText3_7('අර්ධ කාලීන රැකියාව');
            setQuestionText5_1('යෙදුම හැඳින්වීම');
            setQuestionText5_2('බාගත');
            setQuestionText5_3('යෙදුම භාවිතා කරන්නේ කෙසේද');
            setQuestionText6_1('ලිපිනය වෙනස් කිරීම ආදිය.');
            setQuestionText6_2('ප්රචණ්ඩත්වය');
            setQuestionText6_3('සමාව ඉල්ලන ලිපිය');
            setQuestionText6_4('පොලිසිය');
            setQuestionText6_5('පාසලෙන් ඉවත් කිරීම ');
            setQuestionText6_6('මුද්දර');
            setQuestionText6_7('පාසල් මුදල්①');
            setQuestionText6_8('පාසල් මුදල්②');
            setQuestionText6_9('අධ්‍යාපන චාරිකා');
            setQuestionText6_10('☆ශ්රේණි');
            setQuestionText6_11('උපාධි කොන්දේසි');
            setQuestionText6_12('දුම්වැටි');
            setQuestionText6_13('බයිසිකලය');
            setQuestionText7_1('සහතික මිල');
            setQuestionText7_2('අපේක්ෂිත උපාධිය පිළිබඳ සහතිකය');
            setQuestionText7_3('නිර්දේශ ලිපිය');
            setQuestionText8_1('පැමිණීම');
            setQuestionText8_2('පරීක්ෂණය');
            setQuestionText8_3('පෙළ පොත');
            setQuestionText8_4('Name Tag');
            setQuestionText8_5('පන්තියේ ආරම්භයේ සහ අවසානයේ අචාර කිරීම්');
            setQuestionText8_6('කුඩා පරීක්ෂණය');
            setQuestionText9_1('අපි ජපන් භාෂාව කතා කරමු');
            setQuestionText9_2('ජංගම දුරකථන');
            setQuestionText9_3('කනවා බොනවා');
            setQuestionText9_4('තොප්පිය / කබාය');
            setQuestionText9_5('ඉරියව්ව');
            setQuestionText10_1('ඔබට ඔබේ පෙළපොත අමතක වූ විට');
            setQuestionText10_2('ප්‍රමාදවී පැමීණීම');
            setQuestionText10_3('විවේකයක් ගන්නා විට දැනුම් දීම');
            setQuestionText11_1('රීති පැහැදිලි කිරීම');
            setQuestionText11_2('නේවාසිකාගාරයේ ඉන්න පුළුවන් අය');
            setQuestionText11_3('නේවාසිකාගාර කොන්ත්රාත්තුව');
            setQuestionText11_4('නේවාසිකාගාර මුදල්');
            setQuestionText11_5('බලශක්ති ඉතිරිකිරීම්');
            setQuestionText11_6('මම නේවාසිකාගාරයේ යමක් කැඩු විට');
            setQuestionText11_7('ඔබේ යතුරු නැති වූ විට');
            setQuestionText11_8('නේවාසිකාගාරය පිළිවෙලට භාවිතා කරන්න');
            setQuestionText11_9('ඔබගේ තැපැල් පෙට්ටිය පරීක්ෂා කරන්න');
            setQuestionText11_10('තනතුර තහවුරු කිරීම');
            setQuestionText11_11('නේවාසිකාගාර ගැටලු');
            setQuestionText12_1('Onoe Danchi');
            setQuestionText12_2('Daiko Higashi Danchi');
            setQuestionText14_1('උදුන භාවිතා කරන්නේ කෙසේද');
            setQuestionText14_2('වායු සමීකරණ යන්ත්රය භාවිතා කරන්නේ කෙසේද');
            setQuestionText14_3('රෙදි සෝදන යන්ත්රයක් භාවිතා කරන්නේ කෙසේද');
            setQuestionText14_4('ස්නානය භාවිතා කරන්නේ කෙසේද');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setQuestionText1('ကျောင်းအုပ်ကြီးမှ နှုတ်ခွန်းဆက်သပါသည်။');
            setQuestionText2('ကျောင်းဝင်းခရီးစဉ်');
            setQuestionText3('ဗီဇာ');
            setQuestionText4('ကျောင်းသားကတ်');
            setQuestionText5('WSDB');
            setQuestionText6('ကျောင်းသားဘဝ');
            setQuestionText7('လက်မှတ်');
            setQuestionText8('အတန်းတွေအကြောင်း');
            setQuestionText9('စာသင်ခန်းစည်းကမ်း');
            setQuestionText10('ကျောင်းစည်းကမ်းများ');
            setQuestionText11('အိပ်ဆောင်စည်းကမ်း');
            setQuestionText12('အိပ်ဆောင်သို့သွားရာလမ်း');
            setQuestionText13('အိပ်ဆောင်ခရီးစဉ်');
            setQuestionText14('အိမ်သုံးပစ္စည်းများ');
            setQuestionTextChapter('အခန်း');
            setQuestionText3_1('နေထိုင်ခွင့်ကတ်');
            setQuestionText3_2('ဗီဇာနှင့် အချိန်ပိုင်းအလုပ်');
            setQuestionText3_3('အမျိုးသားကျန်းမာရေးအာမခံ');
            setQuestionText3_4('ပင်စင်');
            setQuestionText3_5('ဗီဇာနှင့် လစာစလစ်');
            setQuestionText3_6('တက်ရောက်ခွင့်နှင့် ဗီဇာ');
            setQuestionText3_7('အချိန်ပိုင်းအလုပ်');
            setQuestionText5_1('လျှောက်လွှာမိတ်ဆက်');
            setQuestionText5_2('ဒေါင်းလုဒ်လုပ်ပါ။');
            setQuestionText5_3('အက်ပ်ကိုအသုံးပြုနည်း');
            setQuestionText6_1('လိပ်စာပြောင်းခြင်း စသည်တို့');
            setQuestionText6_2('အကြမ်းဖက်မှု');
            setQuestionText6_3('တောင်းပန်စာ');
            setQuestionText6_4('ရဲ');
            setQuestionText6_5('ကျောင်းထွက်ခြင်း');
            setQuestionText6_6('တံဆိပ်တုံး');
            setQuestionText6_7('ကျောင်းပိုက်ဆံ①');
            setQuestionText6_8('ကျောင်းပိုက်ဆံ②');
            setQuestionText6_9('Excursions ');
            setQuestionText6_10('အဆင့်များ');
            setQuestionText6_11('ဘွဲ့အခြေအနေများ');
            setQuestionText6_12('ဆေးလိပ်');
            setQuestionText6_13('စက်ဘီး');
            setQuestionText7_1('လက်မှတ်စျေးနှုန်း');
            setQuestionText7_2('မျှော်မှန်းထားသည့် ဘွဲ့လက်မှတ်');
            setQuestionText7_3('ထောက်ခံစာ');
            setQuestionText8_1('တက်ရောက်ခြင်း။');
            setQuestionText8_2('စမ်းသပ်');
            setQuestionText8_3('ကျောင်းစာအုပ်');
            setQuestionText8_4('အမည်တံဆိပ်');
            setQuestionText8_5('အတန်းအစနဲ့ အဆုံးမှာ နှုတ်ဆက်ပါတယ်။');
            setQuestionText8_6('အသေးစားစမ်းသပ်မှု');
            setQuestionText9_1('ဂျပန်စကားပြောရအောင်');
            setQuestionText9_2('မိုဘိုင်းဖုန်း');
            setQuestionText9_3('စားသောက်ခြင်း။');
            setQuestionText9_4('ဦးထုပ်/အင်္ကျီ');
            setQuestionText9_5('ကိုယ်ဟန်အနေအထား');
            setQuestionText10_1('သင်ဖတ်စာအုပ်မေ့သွားသောအခါ');
            setQuestionText10_2('အချိန်နောက်ကျ');
            setQuestionText10_3('အနားယူသည့်အခါ ဆက်သွယ်ပါ။');
            setQuestionText11_1('စည်းကမ်းရှင်းလင်းချက်');
            setQuestionText11_2('အဆောင်မှာနေနိုင်သူတွေ');
            setQuestionText11_3('အိပ်ဆောင်စာချုပ်');
            setQuestionText11_4('အိပ်ဆောင်ပိုက်ဆံ');
            setQuestionText11_5('စွမ်းအင်ချွေတာခြင်း။');
            setQuestionText11_6('အိပ်ဆောင်ထဲမှာ တစ်ခုခု ကွဲသွားတဲ့အခါ');
            setQuestionText11_7('မင်းရဲ့သော့ပျောက်သွားတဲ့အခါ');
            setQuestionText11_8('အိပ်ဆောင်ကို သပ်သပ်ရပ်ရပ်သုံးပါ။');
            setQuestionText11_9('သင့်စာတိုက်ပုံးကို စစ်ဆေးပါ။');
            setQuestionText11_10('ပို့စ်အတည်ပြုချက်');
            setQuestionText11_11('အိပ်ဆောင်မှတ်စုများ');
            setQuestionText12_1('Onoe Danchi');
            setQuestionText12_2('Daiko Higashi Danchi');
            setQuestionText14_1('မီးဖိုအသုံးပြုနည်း');
            setQuestionText14_2('လေအေးပေးစက် အသုံးပြုနည်း');
            setQuestionText14_3('အဝတ်လျှော်စက်အသုံးပြုနည်း');
            setQuestionText14_4('ရေချိုးခန်းအသုံးပြုနည်း');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setQuestionText1('অধ্যক্ষের পক্ষ থেকে শুভেচ্ছা');
            setQuestionText2('ক্যাম্পাস ট্যুর');
            setQuestionText3('ভিসা');
            setQuestionText4('ছাত্র পত্র');
            setQuestionText5('WSDB');
            setQuestionText6('ছাত্রজীবন');
            setQuestionText7('সনদপত্র');
            setQuestionText8('ক্লাস সম্পর্কে');
            setQuestionText9('শ্রেণীকক্ষ নিয়ম');
            setQuestionText10('স্কুলের নিয়ম');
            setQuestionText11('ছাত্রাবাসের নিয়ম');
            setQuestionText12('ছাত্রাবাসের পথ');
            setQuestionText13('ছাত্রাবাস ভ্রমণ');
            setQuestionText14('বাড়ির যন্ত্রপাতি');
            setQuestionTextChapter('অধ্যায়');
            setQuestionText3_1('আবাসিক কার্ড');
            setQuestionText3_2('ভিসা এবং খণ্ডকালীন চাকরি');
            setQuestionText3_3('জাতীয় স্বাস্থ্য বীমা');
            setQuestionText3_4('পেনশন');
            setQuestionText3_5('ভিসা এবং পে স্লিপ');
            setQuestionText3_6('উপস্থিতি এবং ভিসা');
            setQuestionText3_7('খন্ডকালীন চাকরী');
            setQuestionText5_1('অ্যাপ্লিকেশন ভূমিকা');
            setQuestionText5_2('ডাউনলোড');
            setQuestionText5_3('অ্যাপটি কিভাবে ব্যবহার করবেন');
            setQuestionText6_1('ঠিকানা পরিবর্তন ইত্যাদি');
            setQuestionText6_2('সহিংসতা');
            setQuestionText6_3('প্রতিফলন বিবৃতি');
            setQuestionText6_4('পুলিশ');
            setQuestionText6_5('স্কুল ত্যাগ');
            setQuestionText6_6('ছাপ');
            setQuestionText6_7('স্কুলের টাকা①');
            setQuestionText6_8('স্কুলের টাকা②');
            setQuestionText6_9('স্কুল ট্রিপ');
            setQuestionText6_10('শ্রেণীসমূহ');
            setQuestionText6_11('স্নাতক শর্তাবলী');
            setQuestionText6_12('তামাক');
            setQuestionText6_13('সাইকেল');
            setQuestionText7_1('শংসাপত্র মূল্য');
            setQuestionText7_2('প্রত্যাশিত স্নাতকের শংসাপত্র');
            setQuestionText7_3('সুপারিশপত্র');
            setQuestionText8_1('উপস্থিতি');
            setQuestionText8_2('পরীক্ষা');
            setQuestionText8_3('পাঠ্যপুস্তক');
            setQuestionText8_4('নাম নির্দেশক');
            setQuestionText8_5('ক্লাসের শুরুতে এবং শেষে শুভেচ্ছা');
            setQuestionText8_6('মিনি পরীক্ষা');
            setQuestionText9_1('আসুন জাপানি কথা বলি');
            setQuestionText9_2('মোবাইল ফোন');
            setQuestionText9_3('খাওয়া-দাওয়া');
            setQuestionText9_4('টুপি/কোট');
            setQuestionText9_5('ভঙ্গি');
            setQuestionText10_1('আপনি যখন আপনার পাঠ্যবই ভুলে যান');
            setQuestionText10_2('সময়ের পিছনে');
            setQuestionText10_3('বিরতি নেওয়ার সময় যোগাযোগ করুন');
            setQuestionText11_1('নিয়মের ব্যাখ্যা');
            setQuestionText11_2('যারা ডরমেটরিতে থাকতে পারে');
            setQuestionText11_3('ছাত্রাবাস চুক্তি');
            setQuestionText11_4('ছাত্রাবাস টাকা');
            setQuestionText11_5('শক্তি সঞ্চয়');
            setQuestionText11_6('যখন আমি ডরমেটরিতে কিছু ভাঙলাম');
            setQuestionText11_7('যখন আপনি আপনার চাবি হারাবেন');
            setQuestionText11_8('সুন্দরভাবে ডরমিটরি ব্যবহার করুন');
            setQuestionText11_9('আপনার মেইলবক্স দেখুন');
            setQuestionText11_10('পোস্টের নিশ্চিতকরণ');
            setQuestionText11_11('ছাত্রাবাস নোট');
            setQuestionText12_1('Onoe Danchi');
            setQuestionText12_2('DAIKO HIGASHI');
            setQuestionText14_1('চুলা কিভাবে ব্যবহার করবেন');
            setQuestionText14_2('এয়ার কন্ডিশনার কিভাবে ব্যবহার করবেন');
            setQuestionText14_3('কিভাবে একটি ওয়াশিং মেশিন ব্যবহার করতে হয়');
            setQuestionText14_4('স্নান কিভাবে ব্যবহার করবেন');
        }

        console.log(LogInNo);
        console.log(isAuth);
    })

    const AllCh = () => {
        navigate('/AllMove');
    }

    const AllMoveShortCh = () => {
        navigate('/AllMoveShort');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <Link to='/'>
                    <img src={Titleimg} className='MenuLogo'/>
                </Link>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                {isAuth ? 
                    (
                        LogInNo == 1 ? (
                            <div>
                                <div className='SelectQ SQChAllArea'>
                                    <div className='SelectQArea SQBAll'>
                                        <div className='SelectQChapter SQChAll' onClick={() => {AllCh()}}>PLAY ALL</div>
                                    </div>
                                    <div className='SelectQArea SQBottom SQBClea'>
                                        <img src={TOURImg} className='SelectQIcon'/>
                                        <Link to='/StayHome'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                        <div className='SelectQTitle'>{QuestionText2}</div>
                                        <div className='SelectQChapter SQBottom'>{QuestionTextChapter}</div>
                                    </div>
                                </div>
                                <div className='SelectQ'>
                                    <div className='SelectQArea'>
                                        {/* 校長のあいさつ */}
                                        <img src={GREETSImg} className='SelectQIcon'/>
                                        <Link to='/PrincipalMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                        <div className='SelectQTitle'>{QuestionText1}</div>
                                        <div className='SelectQChapter SQBottom'>{QuestionTextChapter}</div>
                                    </div>
                                    <div className='SelectQArea'>
                                        {/* 学内ツアー */}
                                        <img src={TOURImg} className='SelectQIcon'/>
                                        <Link to='/InsideSchoolMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                        <div className='SelectQTitle'>{QuestionText2}</div>
                                        <div className='SelectQChapter SQBottom'>{QuestionTextChapter}</div>
                                    </div>
                                </div>
                                <div className='SelectQ'>
                                    <div className='SelectQArea'>
                                        {/* ビザ */}
                                        <img src={VISAImg} className='SelectQIcon'/>
                                        <Link to='/VISAMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                        <div className='SelectQTitle'>{QuestionText3}</div>
                                        <div className={VISA} onClick={() => {VISAClick()}}>{QuestionTextChapter}</div>
                                        <div className={VISAChap}>
                                            <p className='' onClick={() => {VISACh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText3_1}</p>
                                            <p className='' onClick={() => {VISACh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText3_2}</p>
                                            <p className='' onClick={() => {VISACh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText3_3}</p>
                                            <p className='' onClick={() => {VISACh4()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText3_4}</p>
                                            <p className='' onClick={() => {VISACh5()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText3_5}</p>
                                            <p className='' onClick={() => {VISACh6()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText3_6}</p>
                                            <p className='' onClick={() => {VISACh7()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText3_7}</p>
                                        </div>
                                    </div>
                                    <div className='SelectQArea'>
                                        {/* 学生証 */}
                                        <img src={CARDImg} className='SelectQIcon'/>
                                        <Link to='/CardMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                        <div className='SelectQTitle'>{QuestionText4}</div>
                                        <div className='SelectQChapter SQBottom'>{QuestionTextChapter}</div>
                                    </div>
                                </div>
                                <div className='SelectQ'>
                                    <div className='SelectQArea'>
                                        {/* WSDB */}
                                        <img src={APPImg} className='SelectQIcon'/>
                                        <Link to='/CardMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                        <div className='SelectQTitle'>{QuestionText5}</div>
                                        <div className={WSDB} onClick={() => {WSDBClick()}}>{QuestionTextChapter}</div>
                                        <div className={WSDBChap}>
                                            <p className='' onClick={() => {WSDBCh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText5_1}</p>
                                            <p className='' onClick={() => {WSDBCh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText5_2}</p>
                                            <p className='' onClick={() => {WSDBCh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText5_3}</p>
                                        </div>
                                    </div>
                                    <div className='SelectQArea'>
                                        {/* 学生生活 */}
                                        <img src={DAILYImg} className='SelectQIcon'/>
                                        <Link to='/SchoolLifeMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                        <div className='SelectQTitle'>{QuestionText6}</div>
                                        <div className={SchoolLife} onClick={() => {SchoolLifeClick()}}>{QuestionTextChapter}</div>
                                        <div className={SchoolLifeChap}>
                                            <p className='' onClick={() => {ScLiCh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_1}</p>
                                            <p className='' onClick={() => {ScLiCh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_2}</p>
                                            <p className='' onClick={() => {ScLiCh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_3}</p>
                                            <p className='' onClick={() => {ScLiCh4()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_4}</p>
                                            <p className='' onClick={() => {ScLiCh5()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_5}</p>
                                            <p className='' onClick={() => {ScLiCh6()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_6}</p>
                                            <p className='' onClick={() => {ScLiCh7()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_7}</p>
                                            <p className='' onClick={() => {ScLiCh8()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_8}</p>
                                            <p className='' onClick={() => {ScLiCh9()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_9}</p>
                                            <p className='' onClick={() => {ScLiCh10()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_10}</p>
                                            <p className='' onClick={() => {ScLiCh11()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_11}</p>
                                            <p className='' onClick={() => {ScLiCh12()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_12}</p>
                                            <p className='' onClick={() => {ScLiCh13()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_13}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='SelectQ'>
                                    <div className='SelectQArea'>
                                        {/* 証明書 */}
                                        <img src={CERTImg} className='SelectQIcon'/>
                                        <Link to='/ProofMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                        <div className='SelectQTitle'>{QuestionText7}</div>
                                        <div className={Proof} onClick={() => {ProofClick()}}>{QuestionTextChapter}</div>
                                        <div className={ProofChap}>
                                            <p className='SelectQChText' onClick={() => {ProCh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText7_1}</p>
                                            <p className='SelectQChText' onClick={() => {ProCh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText7_2}</p>
                                            <p className='SelectQChText' onClick={() => {ProCh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText7_3}</p>
                                        </div>
                                    </div>
                                    <div className='SelectQArea'>
                                        {/* 授業について */}
                                        <img src={CLASSImg} className='SelectQIcon'/>
                                        <Link to='/LessonMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                        <div className='SelectQTitle'>{QuestionText8}</div>
                                        <div className={Lesson} onClick={() => {LessonClick()}}>{QuestionTextChapter}</div>
                                        <div className={LessonChap}>
                                            <p className='SelectQChText' onClick={() => {LesCh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText8_1}</p>
                                            <p className='SelectQChText' onClick={() => {LesCh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText8_2}</p>
                                            <p className='SelectQChText' onClick={() => {LesCh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText8_3}</p>
                                            <p className='SelectQChText' onClick={() => {LesCh4()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText8_4}</p>
                                            <p className='SelectQChText' onClick={() => {LesCh5()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText8_5}</p>
                                            <p className='SelectQChText' onClick={() => {LesCh6()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText8_6}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='SelectQ'>
                                    <div className='SelectQArea'>
                                        {/* 教室のルール */}
                                        <img src={ROOMImg} className='SelectQIcon'/>
                                        <Link to='/RuhrMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                        <div className='SelectQTitle'>{QuestionText9}</div>
                                        <div className={Learning} onClick={() => {LearningClick()}}>{QuestionTextChapter}</div>
                                        <div className={LearningChap}>
                                            <p className='SelectQChText' onClick={() => {RuhrCh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText9_1}</p>
                                            <p className='SelectQChText' onClick={() => {RuhrCh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText9_2}</p>
                                            <p className='SelectQChText' onClick={() => {RuhrCh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText9_3}</p>
                                            <p className='SelectQChText' onClick={() => {RuhrCh4()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText9_4}</p>
                                            <p className='SelectQChText' onClick={() => {RuhrCh5()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText9_5}</p>
                                        </div>
                                    </div>
                                    <div className='SelectQArea'>
                                        {/* 学校のルール */}
                                        <img src={SCHOOLImg} className='SelectQIcon'/>
                                        <Link to='/SchoolRuhr'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                        <div className='SelectQTitle'>{QuestionText10}</div>
                                        <div className={Ruhr} onClick={() => {RuhrClick()}}>{QuestionTextChapter}</div>
                                        <div className={RuhrChap}>
                                            <p className='SelectQChText' onClick={() => {ScRuhrCh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText10_1}</p>
                                            <p className='SelectQChText' onClick={() => {ScRuhrCh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText10_2}</p>
                                            <p className='SelectQChText' onClick={() => {ScRuhrCh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText10_3}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='SelectQ'>
                                    <div className='SelectQArea'>
                                        {/* 寮のルール */}
                                        <img src={DORMImg} className='SelectQIcon'/>
                                        <Link to='/DorRuhrMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                        <div className='SelectQTitle'>{QuestionText11}</div>
                                        <div className={DorRuhr} onClick={() => {DorRuhrClick()}}>{QuestionTextChapter}</div>
                                        <div className={DorRuhrChap}>
                                            <p className='SelectQChText' onClick={() => {DRuhrCh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText11_1}</p>
                                            <p className='SelectQChText' onClick={() => {DRuhrCh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText11_2}</p>
                                            <p className='SelectQChText' onClick={() => {DRuhrCh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText11_3}</p>
                                            <p className='SelectQChText' onClick={() => {DRuhrCh4()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText11_4}</p>
                                            <p className='SelectQChText' onClick={() => {DRuhrCh5()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText11_5}</p>
                                            <p className='SelectQChText' onClick={() => {DRuhrCh6()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText11_6}</p>
                                            <p className='SelectQChText' onClick={() => {DRuhrCh7()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText11_7}</p>
                                            <p className='SelectQChText' onClick={() => {DRuhrCh8()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText11_8}</p>
                                            <p className='SelectQChText' onClick={() => {DRuhrCh9()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText11_9}</p>
                                            <p className='SelectQChText' onClick={() => {DRuhrCh10()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText11_10}</p>
                                            <p className='SelectQChText' onClick={() => {DRuhrCh11()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText11_11}</p>
                                            
                                            <p className='SelectQText'>{QuestionText12}</p>
                                            <p className='SelectQChText' onClick={() => {JouACh()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText12_1}</p>
                                            <p className='SelectQChText' onClick={() => {JouBCh()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText12_2}</p>

                                            <p className='SelectQText' onClick={() => {DorToCh()}}><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn2' style={{ color: '#ff8647' }} />{QuestionText13}</p>

                                            <p className='SelectQText' onClick={() => {ElectCh1()}}><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn2' style={{ color: '#ff8647' }} />{QuestionText14}</p>
                                            <p className='SelectQChText' onClick={() => {ElectCh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText14_1}</p>
                                            <p className='SelectQChText' onClick={() => {ElectCh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText14_2}</p>
                                            <p className='SelectQChText' onClick={() => {ElectCh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText14_3}</p>
                                            <p className='SelectQChText' onClick={() => {ElectCh4()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText14_4}</p>
                                        </div>
                                    </div>
                                    <div className='SelectQArea SQBottom'>
                                        <img src={GREETSImg} className='SelectQIcon'/>
                                        <Link to='/StayHome'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                        <div className='SelectQTitle'>ここにはないよ</div>
                                        <div className='SelectQChapter'>チャプター</div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            LogInNo == 2 ? (
                                <div>
                                    <div className='SelectQ'>
                                        <div className='SelectQArea'>
                                            {/* 学内ツアー */}
                                            <img src={TOURImg} className='SelectQIcon'/>
                                            <Link to='/InsideSchoolMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                            <div className='SelectQTitle'>{QuestionText2}</div>
                                            <div className='SelectQChapter SQBottom'>{QuestionTextChapter}</div>
                                        </div>
                                        <div className='SelectQArea'>
                                            {/* 寮のルール */}
                                            <img src={DORMImg} className='SelectQIcon'/>
                                            <Link to='/DormitoryTourMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                            <div className='SelectQTitle'>{QuestionText13}</div>
                                            <div className='SelectQChapter SQBottom'>{QuestionTextChapter}</div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                LogInNo == 3 ? (
                                    <div>
                                        <div className='SelectQ SQChAllArea'>
                                            <div className='SelectQArea SQBAll'>
                                                <div className='SelectQChapter SQChAll' onClick={() => {AllMoveShortCh()}}>PLAY ALL</div>
                                            </div>
                                            <div className='SelectQArea SQBottom SQBClea'>
                                                <img src={TOURImg} className='SelectQIcon'/>
                                                <Link to='/StayHome'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                                <div className='SelectQTitle'>{QuestionText2}</div>
                                                <div className='SelectQChapter SQBottom'>{QuestionTextChapter}</div>
                                            </div>
                                        </div>
                                        <div className='SelectQ'>
                                            <div className='SelectQArea'>
                                                {/* 校長のあいさつ */}
                                                <img src={GREETSImg} className='SelectQIcon'/>
                                                <Link to='/PrincipalMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                                <div className='SelectQTitle'>{QuestionText1}</div>
                                                <div className='SelectQChapter SQBottom'>{QuestionTextChapter}</div>
                                            </div>
                                            <div className='SelectQArea'>
                                                {/* 学内ツアー */}
                                                <img src={TOURImg} className='SelectQIcon'/>
                                                <Link to='/InsideSchoolMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                                <div className='SelectQTitle'>{QuestionText2}</div>
                                                <div className='SelectQChapter SQBottom'>{QuestionTextChapter}</div>
                                            </div>
                                        </div>
                                        <div className='SelectQ'>
                                            <div className='SelectQArea'>
                                                {/* ビザ */}
                                                <img src={VISAImg} className='SelectQIcon'/>
                                                <Link to='/VISAMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                                <div className='SelectQTitle'>{QuestionText3}</div>
                                                <div className={VISA} onClick={() => {VISAClick()}}>{QuestionTextChapter}</div>
                                                <div className={VISAChap}>
                                                    <p className='' onClick={() => {VISACh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText3_1}</p>
                                                    <p className='' onClick={() => {VISACh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText3_2}</p>
                                                    <p className='' onClick={() => {VISACh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText3_3}</p>
                                                    <p className='' onClick={() => {VISACh4()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText3_4}</p>
                                                    <p className='' onClick={() => {VISACh5()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText3_5}</p>
                                                    <p className='' onClick={() => {VISACh6()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText3_6}</p>
                                                    <p className='' onClick={() => {VISACh7()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText3_7}</p>
                                                </div>
                                            </div>
                                            <div className='SelectQArea'>
                                                {/* 学生証 */}
                                                <img src={CARDImg} className='SelectQIcon'/>
                                                <Link to='/CardMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                                <div className='SelectQTitle'>{QuestionText4}</div>
                                                <div className='SelectQChapter SQBottom'>{QuestionTextChapter}</div>
                                            </div>
                                        </div>
                                        <div className='SelectQ'>
                                            <div className='SelectQArea'>
                                                {/* WSDB */}
                                                <img src={APPImg} className='SelectQIcon'/>
                                                <Link to='/CardMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                                <div className='SelectQTitle'>{QuestionText5}</div>
                                                <div className={WSDB} onClick={() => {WSDBClick()}}>{QuestionTextChapter}</div>
                                                <div className={WSDBChap}>
                                                    <p className='' onClick={() => {WSDBCh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText5_1}</p>
                                                    <p className='' onClick={() => {WSDBCh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText5_2}</p>
                                                    <p className='' onClick={() => {WSDBCh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText5_3}</p>
                                                </div>
                                            </div>
                                            <div className='SelectQArea'>
                                                {/* 学生生活 */}
                                                <img src={DAILYImg} className='SelectQIcon'/>
                                                <Link to='/SchoolLifeMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                                <div className='SelectQTitle'>{QuestionText6}</div>
                                                <div className={SchoolLife} onClick={() => {SchoolLifeClick()}}>{QuestionTextChapter}</div>
                                                <div className={SchoolLifeChap}>
                                                    <p className='' onClick={() => {ScLiCh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_1}</p>
                                                    <p className='' onClick={() => {ScLiCh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_2}</p>
                                                    <p className='' onClick={() => {ScLiCh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_3}</p>
                                                    <p className='' onClick={() => {ScLiCh4()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_4}</p>
                                                    <p className='' onClick={() => {ScLiCh5()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_5}</p>
                                                    <p className='' onClick={() => {ScLiCh6()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_6}</p>
                                                    <p className='' onClick={() => {ScLiCh7()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_7}</p>
                                                    <p className='' onClick={() => {ScLiCh8()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_8}</p>
                                                    <p className='' onClick={() => {ScLiCh9()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_9}</p>
                                                    <p className='' onClick={() => {ScLiCh10()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_10}</p>
                                                    <p className='' onClick={() => {ScLiCh11()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_11}</p>
                                                    <p className='' onClick={() => {ScLiCh12()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_12}</p>
                                                    <p className='' onClick={() => {ScLiCh13()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText6_13}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='SelectQ'>
                                            <div className='SelectQArea'>
                                                {/* 証明書 */}
                                                <img src={CERTImg} className='SelectQIcon'/>
                                                <Link to='/ProofMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                                <div className='SelectQTitle'>{QuestionText7}</div>
                                                <div className={Proof} onClick={() => {ProofClick()}}>{QuestionTextChapter}</div>
                                                <div className={ProofChap}>
                                                    <p className='SelectQChText' onClick={() => {ProCh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText7_1}</p>
                                                    <p className='SelectQChText' onClick={() => {ProCh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText7_2}</p>
                                                    <p className='SelectQChText' onClick={() => {ProCh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText7_3}</p>
                                                </div>
                                            </div>
                                            <div className='SelectQArea'>
                                                {/* 授業について */}
                                                <img src={CLASSImg} className='SelectQIcon'/>
                                                <Link to='/LessonMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                                <div className='SelectQTitle'>{QuestionText8}</div>
                                                <div className={Lesson} onClick={() => {LessonClick()}}>{QuestionTextChapter}</div>
                                                <div className={LessonChap}>
                                                    <p className='SelectQChText' onClick={() => {LesCh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText8_1}</p>
                                                    <p className='SelectQChText' onClick={() => {LesCh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText8_2}</p>
                                                    <p className='SelectQChText' onClick={() => {LesCh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText8_3}</p>
                                                    <p className='SelectQChText' onClick={() => {LesCh4()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText8_4}</p>
                                                    <p className='SelectQChText' onClick={() => {LesCh5()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText8_5}</p>
                                                    <p className='SelectQChText' onClick={() => {LesCh6()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText8_6}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='SelectQ'>
                                            <div className='SelectQArea'>
                                                {/* 教室のルール */}
                                                <img src={ROOMImg} className='SelectQIcon'/>
                                                <Link to='/RuhrMove'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                                <div className='SelectQTitle'>{QuestionText9}</div>
                                                <div className={Learning} onClick={() => {LearningClick()}}>{QuestionTextChapter}</div>
                                                <div className={LearningChap}>
                                                    <p className='SelectQChText' onClick={() => {RuhrCh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText9_1}</p>
                                                    <p className='SelectQChText' onClick={() => {RuhrCh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText9_2}</p>
                                                    <p className='SelectQChText' onClick={() => {RuhrCh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText9_3}</p>
                                                    <p className='SelectQChText' onClick={() => {RuhrCh4()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText9_4}</p>
                                                    <p className='SelectQChText' onClick={() => {RuhrCh5()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText9_5}</p>
                                                </div>
                                            </div>
                                            <div className='SelectQArea'>
                                                {/* 学校のルール */}
                                                <img src={SCHOOLImg} className='SelectQIcon'/>
                                                <Link to='/SchoolRuhr'><FontAwesomeIcon icon={faPlayCircle} className='PlayBtn' style={{ color: '#ff8647' }} /></Link>
                                                <div className='SelectQTitle'>{QuestionText10}</div>
                                                <div className={Ruhr} onClick={() => {RuhrClick()}}>{QuestionTextChapter}</div>
                                                <div className={RuhrChap}>
                                                    <p className='SelectQChText' onClick={() => {ScRuhrCh1()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText10_1}</p>
                                                    <p className='SelectQChText' onClick={() => {ScRuhrCh2()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText10_2}</p>
                                                    <p className='SelectQChText' onClick={() => {ScRuhrCh3()}}><FontAwesomeIcon icon={faPlayCircle} style={{ color: '#96d35f' }} />{QuestionText10_3}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <p className='SelectQTextLog'>どのページでもないよ</p>
                                )
                            )
                        )
                        ) : (
                        <div>
                            <div className='SelectPos'>
                               <Link to='/StayHome'><p className='SelectQTextLog'>ログイン</p></Link>
                            </div>
                            <div className='SelectPos'>
                                <Link target="_blank" to='https://support.google.com/accounts/answer/7675428?hl=ja&co=GENIE.Platform%3DAndroid&oco=0'>
                                    <p className='SelectQTextJS SelectQTextJSLine' >設定からJavaScriptを有効にしてください。</p>
                                </Link>
                            </div>
                            <div className='SelectPos'>
                                <p className='SelectQTextJS' >ブラウザはGoogle Chrome、Safari、Mozilla Firefox、Microsoft Edgeをご利用ください</p>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}