import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import Proof from '../Images/Proof.mp4';

export default function ProofMove ({ setLanguage, Language, isAuth, setIsAuth, TimeVI, setTimeVI, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');
            
            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    // 再生チャプター変色
    const [ChapterColor1, setChapterColor1] = useState('MoveChapBtn');
    const [ChapterColor2, setChapterColor2] = useState('MoveChapBtn');
    const [ChapterColor3, setChapterColor3] = useState('MoveChapBtn');

    const [TimePlus, setTimePlus] = useState(0)
    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        if(TimePlus == 0){
            document.getElementById('video').currentTime = TimeVI;
            setTimePlus(1);
        }

        // 証明書（しょうめいしょ）
        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            setChapterColor1('MoveChapBtn MChBChange');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
        }
        if(counter >= 0.5){
            if(Language == 0){
                setKashi('証明書（しょうめいしょ）');
            }
            if(Language == 1){
                setKashi('certificate');
            }
            if(Language == 2){
                setKashi('證書');
            }
            if(Language == 3){
                setKashi('प्रमाणपत्र');
            }
            if(Language == 4){
                setKashi('සහතිකය');
            }
            if(Language == 5){
                setKashi('လက်မှတ်');
            }
            if(Language == 6){
                setKashi('সনদপত্র');
            }
        }
        if(counter >= 3.5){
            if(Language == 0){
                setKashi('NSA日本語学校が作る証明書は、');
            }
            if(Language == 1){
                setKashi('The certificate prepared by NSA Japanese School');
            }
            if(Language == 2){
                setKashi('NSA日本語學校製作的證書是');
            }
            if(Language == 3){
                setKashi('NSA जापानी स्कूल द्वारा बनाईएको प्रमाणपत्रहरू निम्न प्रकारका छन्:-');
            }
            if(Language == 4){
                setKashi('NSA ජපන් පාසල විසින් නිර්මාණය කරන ලද සහතිකය වේ');
            }
            if(Language == 5){
                setKashi('NSA Japanese School မှ ဖန်တီးထားသော လက်မှတ်ဖြစ်ပါသည်။');
            }
            if(Language == 6){
                setKashi('NSA জাপানিজ স্কুল দ্বারা তৈরি সার্টিফিকেট হল');
            }
        }
        if(counter >= 7.7){
            if(Language == 0){
                setKashi('1まい300円です。');
            }
            if(Language == 1){
                setKashi('costs 300 yen.');
            }
            if(Language == 2){
                setKashi('一份300日圓。');
            }
            if(Language == 3){
                setKashi('एउटा पेपरको ३००येन लाग्छ।');
            }
            if(Language == 4){
                setKashi('එක් සහතිකයක් යෙන්300 කි.');
            }
            if(Language == 5){
                setKashi('လက်မှတ်တစ်စောင် 300 ယန်း။');
            }
            if(Language == 6){
                setKashi('1 মাই হল 300 ইয়েন।');
            }
        }
        if(counter >= 11){
            if(Language == 0){
                setKashi('証明書がほしいときは');
            }
            if(Language == 1){
                setKashi('When you need a certificate');
            }
            if(Language == 2){
                setKashi('當您想要證書時');
            }
            if(Language == 3){
                setKashi('जब तपाइँ प्रमाणपत्र चाहानुहुन्छ');
            }
            if(Language == 4){
                setKashi('ඔබට සහතිකයක් අවශ්ය විට');
            }
            if(Language == 5){
                setKashi('လက်မှတ်လိုချင်တဲ့အခါ');
            }
            if(Language == 6){
                setKashi('যখন আপনি একটি শংসাপত্র চান');
            }
        }
        if(counter >= 13.7){
            if(Language == 0){
                setKashi('1日前にじむしょの先生に話してください。');
            }
            if(Language == 1){
                setKashi('Please inform a teacher at the office one day in advance.');
            }
            if(Language == 2){
                setKashi('請提前一天向辦公室老師諮詢。');
            }
            if(Language == 3){
                setKashi('कृपया एक दिन पहिले कार्यालयको शिक्षकसँग अनुरोध गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර දිනකට පෙර කාර්යාලයේ සිටින ගුරුවරයා සමඟ සාකච්ඡා කරන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးပြု၍ ရုံးတွင် တစ်ရက်ကြိုတင်၍ ဆရာနှင့် တိုင်ပင်ပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে একদিন আগে জিমুশোর শিক্ষকের সাথে কথা বলুন।');
            }
        }
        if(counter >= 18.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 19.4){
            if(Language == 0){
                setKashi('学校のお金をはらっていない学生は、');
            }
            if(Language == 1){
                setKashi('Students who do not pay the school fees are');
            }
            if(Language == 2){
                setKashi('不繳學費的學生是');
            }
            if(Language == 3){
                setKashi('विद्यालय शुल्क नतिर्ने विद्यार्थीहरूको');
            }
            if(Language == 4){
                setKashi('පාසල් ගාස්තු නොගෙවන සිසුන් වේ');
            }
            if(Language == 5){
                setKashi('ကျောင်းစရိတ်မပေးရသော ကျောင်းသား၊');
            }
            if(Language == 6){
                setKashi('যারা স্কুলের জন্য অর্থ প্রদান করে না তারা হল');
            }
        }
        if(counter >= 23.5){
            if(Language == 0){
                setKashi('証明書を作ることができません。');
            }
            if(Language == 1){
                setKashi('Unable to receive certificate.');
            }
            if(Language == 2){
                setKashi('無法建立證書。');
            }
            if(Language == 3){
                setKashi('प्रमाणपत्र दिन असमर्थ हुनेछौं।');
            }
            if(Language == 4){
                setKashi('සහතිකය සෑදිය නොහැක.');
            }
            if(Language == 5){
                setKashi('လက်မှတ်ကို ဖန်တီး၍မရပါ။');
            }
            if(Language == 6){
                setKashi('সার্টিফিকেট তৈরি করতে অক্ষম।');
            }
        }
        if(counter >= 27){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 28.7){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn MChBChange');
            setChapterColor3('MoveChapBtn');
        }
        // 卒業見込証明書（そつぎょうみこみしょうめいしょ）
        if(counter >= 29){
            if(Language == 0){
                setKashi('卒業見込証明書（そつぎょうみこみしょうめいしょ）');
            }
            if(Language == 1){
                setKashi('Certificate of expected graduation');
            }
            if(Language == 2){
                setKashi('預計畢業證明');
            }
            if(Language == 3){
                setKashi('अपेक्षित स्नातकको प्रमाणपत्र');
            }
            if(Language == 4){
                setKashi('උපාධි පිළිබඳ සහතිකය');
            }
            if(Language == 5){
                setKashi('မျှော်မှန်းဘွဲ့လက်မှတ်');
            }
            if(Language == 6){
                setKashi('প্রত্যাশিত স্নাতকের শংসাপত্র');
            }
        }
        if(counter >= 32.5){
            if(Language == 0){
                setKashi('卒業見込証明書は、');
            }
            if(Language == 1){
                setKashi('The certificate of expected graduation');
            }
            if(Language == 2){
                setKashi('預計畢業證書是');
            }
            if(Language == 3){
                setKashi('अपेक्षित स्नातकको प्रमाणपत्र को लागि，');
            }
            if(Language == 4){
                setKashi('අපේක්ෂිත උපාධිය පිළිබඳ සහතිකය වේ');
            }
            if(Language == 5){
                setKashi('မျှော်မှန်းဘွဲ့လက်မှတ်');
            }
            if(Language == 6){
                setKashi('প্রত্যাশিত স্নাতকের শংসাপত্র');
            }
        }
        if(counter >= 36){
            if(Language == 0){
                setKashi('2年生の10月1日から作ることができます。');
            }
            if(Language == 1){
                setKashi('can be made starting from October 1st of your second year.');
            }
            if(Language == 2){
                setKashi('您可以從第二年的 10 月 1 日開始申請。');
            }
            if(Language == 3){
                setKashi('तपाइँ यसलाई तपाइँको दोस्रो वर्षको अक्टोबर 1 देखि बनाउन सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබේ දෙවන වසරේ ඔක්තෝබර් 1 වනදා සිට ඔබට එය කළ හැකිය.');
            }
            if(Language == 5){
                setKashi('သင်၏ဒုတိယနှစ်၏အောက်တိုဘာလ 1 ရက်မှသင်ပြုလုပ်နိုင်သည်။');
            }
            if(Language == 6){
                setKashi('আপনি এটি আপনার দ্বিতীয় বছরের ১লা অক্টোবর থেকে করতে পারেন।');
            }
        }
        if(counter >= 41.2){
            if(Language == 0){
                setKashi('しゅっせきが75%以上なければなりません。');
            }
            if(Language == 1){
                setKashi('Attendance must be at least 75%.');
            }
            if(Language == 2){
                setKashi('出勤率必須至少達到 75%。');
            }
            if(Language == 3){
                setKashi('विद्यालयमा　उपस्थिति कम्तिमा 75% हुनुपर्छ।');
            }
            if(Language == 4){
                setKashi('පැමිණීම අවම වශයෙන් 75% විය යුතුය.');
            }
            if(Language == 5){
                setKashi('တက်ရောက်သူ အနည်းဆုံး 75% ရှိရပါမည်။');
            }
            if(Language == 6){
                setKashi('Shusseki কমপক্ষে 75% হতে হবে।');
            }
        }
        if(counter >= 48.3){
            if(Language == 0){
                setKashi('10月1日より前にほしい学生は');
            }
            if(Language == 1){
                setKashi('Students who want it before October 1st');
            }
            if(Language == 2){
                setKashi('10月1日前想要的同學');
            }
            if(Language == 3){
                setKashi('अक्टोबर 1 अघि यो चाहने विद्यार्थीहरू');
            }
            if(Language == 4){
                setKashi('ඔක්තෝබර් 1 ට පෙර එය අවශ්ය සිසුන්');
            }
            if(Language == 5){
                setKashi('အောက်တိုဘာ ၁ ရက်နေ့ မတိုင်ခင် လိုချင်တဲ့ ကျောင်းသား၊');
            }
            if(Language == 6){
                setKashi('যে ছাত্ররা 1লা অক্টোবরের আগে এটা চায়');
            }
        }
        if(counter >= 52.3){
            if(Language == 0){
                setKashi('クラスの先生にそうだんしてください。');
            }
            if(Language == 1){
                setKashi('Please consult about it with your class teacher.');
            }
            if(Language == 2){
                setKashi('請諮詢您的班主任。');
            }
            if(Language == 3){
                setKashi('कृपया आफ्नो कक्षा शिक्षक　सँग सल्लाह गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබේ පන්ති ගුරුවරයාගෙන් විමසන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးပြု၍ သင်၏အတန်းဆရာနှင့် တိုင်ပင်ပါ။');
            }
            if(Language == 6){
                setKashi('আপনার ক্লাস টিচারকে বলুন।');
            }
        }
        if(counter >= 56){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 58){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn MChBChange');
        }
        // 推薦書（すいせんしょ）
        if(counter >= 58.5){
            if(Language == 0){
                setKashi('推薦書（すいせんしょ）');
            }
            if(Language == 1){
                setKashi('letter of recommendation');
            }
            if(Language == 2){
                setKashi('推薦信');
            }
            if(Language == 3){
                setKashi('सिफारिस पत्र');
            }
            if(Language == 4){
                setKashi('නිර්දේශ ලිපිය');
            }
            if(Language == 5){
                setKashi('ထောက်ခံစာ');
            }
            if(Language == 6){
                setKashi('সুপারিশপত্র');
            }

            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn MChBChange');
        }
        if(counter >= 62.5){
            if(Language == 0){
                setKashi('専門学校や大学に出す推薦書は、');
            }
            if(Language == 1){
                setKashi('Recommendation letters for vocational schools and universities');
            }
            if(Language == 2){
                setKashi('職業學校和大學的推薦信是');
            }
            if(Language == 3){
                setKashi('व्यावसायिक विद्यालय र विश्वविद्यालयहरूको लागि सिफारिस पत्रहरू छन्');
            }
            if(Language == 4){
                setKashi('වෘත්තීය පාසල් සහ විශ්වවිද්‍යාල සඳහා නිර්දේශ ලිපි වේ');
            }
            if(Language == 5){
                setKashi('သက်မွေးဝမ်းကျောင်းနှင့် တက္ကသိုလ်များအတွက် ထောက်ခံစာများ');
            }
            if(Language == 6){
                setKashi('বৃত্তিমূলক স্কুল এবং বিশ্ববিদ্যালয়ের জন্য সুপারিশ চিঠি হয়');
            }
        }
        if(counter >= 66.7){
            if(Language == 0){
                setKashi('2年生の10月1日から作ることができます。');
            }
            if(Language == 1){
                setKashi('can be made starting from October 1st of your second year.');
            }
            if(Language == 2){
                setKashi('您可以從第二年的 10 月 1 日開始申請。');
            }
            if(Language == 3){
                setKashi('तपाइँ यसलाई तपाइँको दोस्रो वर्षको अक्टोबर 1 देखि बनाउन सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබේ දෙවන වසරේ ඔක්තෝබර් 1 වනදා සිට ඔබට එය කළ හැකිය.');
            }
            if(Language == 5){
                setKashi('သင်၏ဒုတိယနှစ်၏အောက်တိုဘာလ 1 ရက်မှသင်ပြုလုပ်နိုင်သည်။');
            }
            if(Language == 6){
                setKashi('আপনি এটি আপনার দ্বিতীয় বছরের 1লা অক্টোবর থেকে করতে পারেন।');
            }
        }
        if(counter >= 72){
            if(Language == 0){
                setKashi('出席率が90%以上で、');
            }
            if(Language == 1){
                setKashi('Attendance rate must be 90% or higher.');
            }
            if(Language == 2){
                setKashi('出席率達90%以上，');
            }
            if(Language == 3){
                setKashi('उपस्थिति दर 90% भन्दा बढी छ,');
            }
            if(Language == 4){
                setKashi('පැමිණීමේ අනුපාතය 90% ඉක්මවයි,');
            }
            if(Language == 5){
                setKashi('တက်ရောက်နှုန်း 90% ကျော်၊');
            }
            if(Language == 6){
                setKashi('উপস্থিতির হার 90% বা তার বেশি।');
            }
        }
        if(counter >= 75.3){
            if(Language == 0){
                setKashi('せいせきがB以上なければなりません。');
            }
            if(Language == 1){
                setKashi('Grade must be B or higher.');
            }
            if(Language == 2){
                setKashi('成績必須為 B 或更高。');
            }
            if(Language == 3){
                setKashi('ग्रेड B वा उच्च हुनुपर्छ।');
            }
            if(Language == 4){
                setKashi('ශ්‍රේණි B හෝ ඊට වැඩි විය යුතුය.');
            }
            if(Language == 5){
                setKashi('အဆင့် B နှင့်အထက်ဖြစ်ရမည်။');
            }
            if(Language == 6){
                setKashi('গ্রেড B বা উচ্চতর হতে হবে।');
            }
        }
        if(counter >= 81){
            if(Language == 0){
                setKashi('推薦書が欲しい学生は');
            }
            if(Language == 1){
                setKashi('Students who want a letter of recommendation');
            }
            if(Language == 2){
                setKashi('想要推薦信的學生');
            }
            if(Language == 3){
                setKashi('विद्यार्थीहरू जो सिफारिसको पत्र चाहन्छन्');
            }
            if(Language == 4){
                setKashi('නිර්දේශ ලිපියක් අවශ්‍ය සිසුන්');
            }
            if(Language == 5){
                setKashi('ထောက်ခံစာလိုချင်တဲ့ ကျောင်းသား၊');
            }
            if(Language == 6){
                setKashi('ছাত্র যারা সুপারিশ একটি চিঠি চান');
            }
        }
        if(counter >= 83.3){
            if(Language == 0){
                setKashi('クラスの先生にそうだんしてください。');
            }
            if(Language == 1){
                setKashi('Please consult with your class teacher.');
            }
            if(Language == 2){
                setKashi('請諮詢您的班主任老師。');
            }
            if(Language == 3){
                setKashi('कृपया आफ्नो कक्षा शिक्षक　सँग सल्लाह गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබේ පන්ති ගුරුවරයාගෙන් විමසන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးပြု၍ သင်၏အတန်းဆရာနှင့် တိုင်ပင်ပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে আপনার ক্লাস শিক্ষকের সাথে পরামর্শ করুন।');
            }
        }
        if(counter >= 88.5){
            if(Language == 0){
                setKashi('卒業した後は、');
            }
            if(Language == 1){
                setKashi('After graduating,');
            }
            if(Language == 2){
                setKashi('畢業以後，');
            }
            if(Language == 3){
                setKashi('स्नातक गरेपछि,');
            }
            if(Language == 4){
                setKashi('උපාධිය ලැබීමෙන් පසු,');
            }
            if(Language == 5){
                setKashi('ဘွဲ့ရပြီး၊');
            }
            if(Language == 6){
                setKashi('স্নাতক হওয়ার পর,');
            }
        }
        if(counter >= 90.3){
            if(Language == 0){
                setKashi('推薦書を作ることができません。');
            }
            if(Language == 1){
                setKashi('You wont be able to get a recommendation letter.');
            }
            if(Language == 2){
                setKashi('您無法建立推薦信。');
            }
            if(Language == 3){
                setKashi('प्रमाणपत्र दिन असमर्थ हुनेछौं।');
            }
            if(Language == 4){
                setKashi('මට නිර්දේශ ලිපියක් සෑදිය නොහැක.');
            }
            if(Language == 5){
                setKashi('အကြံပြုစာတစ်စောင် ဖန်တီး၍မရပါ။');
            }
            if(Language == 6){
                setKashi('আমি একটি সুপারিশ চিঠি তৈরি করতে অক্ষম।');
            }
        }
        if(counter >= 94.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
    }

    const timeSetMsg1 = () => {
        document.getElementById('video').currentTime = 0;
    }
    const timeSetMsg2 = () => {
        document.getElementById('video').currentTime = 28.7;
    }
    const timeSetMsg3 = () => {
        document.getElementById('video').currentTime = 58;
    }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            setLogInNo(LogInNo);
            navigate('/');
        }
    })

    const HomeReset = () => {
        setTimeVI(0);
        setLogInNo(LogInNo);
        navigate('/');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <img src={Titleimg} className='MenuLogo' onClick={() => {HomeReset()}}/>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                        <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                        <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                    </div>
                    <video src={Proof} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                    <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                    <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                </div>
            </div>
        </div>
    )
}