import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import Electronics from '../Images/Electronics.mp4';

export default function ElectronicsMove ({ setLanguage, Language, isAuth, setIsAuth, TimeVI, setTimeVI, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    // 再生チャプター変色
    const [ChapterColor1, setChapterColor1] = useState('MoveChapBtn');
    const [ChapterColor2, setChapterColor2] = useState('MoveChapBtn');
    const [ChapterColor3, setChapterColor3] = useState('MoveChapBtn');
    const [ChapterColor4, setChapterColor4] = useState('MoveChapBtn');

    const [TimePlus, setTimePlus] = useState(0)
    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        if(TimePlus == 0){
            document.getElementById('video').currentTime = TimeVI;
            setTimePlus(1);
        }

        // コンロの使い方
        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }

            setChapterColor1('MoveChapBtn MChBChange');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
        }
        if(counter >= 0.5){
            if(Language == 0){
                setKashi('コンロの使い方');
            }
            if(Language == 1){
                setKashi('How to use the stove');
            }
            if(Language == 2){
                setKashi('瓦斯爐的使用方法');
            }
            if(Language == 3){
                setKashi('ग्याँस स्टोभ प्रयोग गर्ने तरीका');
            }
            if(Language == 4){
                setKashi('උදුන භාවිතා කරන්නේ කෙසේද');
            }
            if(Language == 5){
                setKashi('မီးဖိုအသုံးပြုနည်း');
            }
            if(Language == 6){
                setKashi('চুলা কিভাবে ব্যবহার করবেন');
            }
        }
        if(counter >= 4.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 4.8){
            if(Language == 0){
                setKashi('コンロで火をつけるときは、');
            }
            if(Language == 1){
                setKashi('When starting a fire on the stove,');
            }
            if(Language == 2){
                setKashi('當在瓦斯爐上生火時，');
            }
            if(Language == 3){
                setKashi('चुलोमा आगो बाल्दा,');
            }
            if(Language == 4){
                setKashi('ලිප මත ගින්දර දල්වන විට,');
            }
            if(Language == 5){
                setKashi('မီးဖိုပေါ်မီးထွန်းသောအခါ၊');
            }
            if(Language == 6){
                setKashi('চুলায় আগুন জ্বালানোর সময়,');
            }
        }
        if(counter >= 6.8){
            if(Language == 0){
                setKashi('火がつくまでボタンを長く押してください。');
            }
            if(Language == 1){
                setKashi('Press and hold the button until it lights up.');
            }
            if(Language == 2){
                setKashi('按住按鈕直至其亮起。');
            }
            if(Language == 3){
                setKashi('आगो नबल्दासम्म बटन थिच्नुहोस् र होल्ड गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('එය දැල්වෙන තුරු බොත්තම ඔබාගෙන සිටින්න.');
            }
            if(Language == 5){
                setKashi('မီးလင်းသည်အထိ ခလုတ်ကို နှိပ်ပြီး ဖိထားပါ။');
            }
            if(Language == 6){
                setKashi('আলো না হওয়া পর্যন্ত বোতাম টিপুন এবং ধরে রাখুন।');
            }
        }
        if(counter >= 10.5){
            if(Language == 0){
                setKashi('レバーを左右に動かして火の大きさをちょうせつします。');
            }
            if(Language == 1){
                setKashi('Move the lever to the left or right to adjust the level of the fire.');
            }
            if(Language == 2){
                setKashi('左右移動控制桿可調整火力大小。');
            }
            if(Language == 3){
                setKashi('आगोको आकार समायोजन गर्न लीभर बायाँ र दायाँ सार्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ගින්නෙහි ප්රමාණය සකස් කිරීම සඳහා ලීවරය වමට සහ දකුණට ගෙන යන්න.');
            }
            if(Language == 5){
                setKashi('မီးအရွယ်အစားကိုချိန်ညှိရန် လီဗာကို ဘယ်ညာရွှေ့ပါ။');
            }
            if(Language == 6){
                setKashi('আগুনের আকার সামঞ্জস্য করতে লিভারটি বাম এবং ডানদিকে সরান।');
            }
        }
        if(counter >= 15.5){
            if(Language == 0){
                setKashi('ボタンをおすと、火がきえます。');
            }
            if(Language == 1){
                setKashi('Press the button to extinguish the fire.');
            }
            if(Language == 2){
                setKashi('按下按鈕即可滅火。');
            }
            if(Language == 3){
                setKashi('आगो निभाउन बटन थिच्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ගින්න නිවා දැමීමට බොත්තම ඔබන්න.');
            }
            if(Language == 5){
                setKashi('မီးငြှိမ်းသတ်ရန် ခလုတ်ကို နှိပ်ပါ။');
            }
            if(Language == 6){
                setKashi('আগুন নিভানোর জন্য বোতাম টিপুন।');
            }
        }
        if(counter >= 19.6){
            if(Language == 0){
                setKashi('魚などを焼きたいときは、グリルを使って焼くこともできます。');
            }
            if(Language == 1){
                setKashi('If you want to grill fish, etc., you can also use the grill.');
            }
            if(Language == 2){
                setKashi('如果你想烤魚等，也可以使用烤架。');
            }
            if(Language == 3){
                setKashi('यदि तपाइँ माछा आदि ग्रिल गर्न चाहनुहुन्छ भने, तपाइँ ग्रिल पनि प्रयोग गर्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබට මාළු ආදිය ග්‍රිල් කිරීමට අවශ්‍ය නම්, ඔබට ග්‍රිල් ද භාවිතා කළ හැකිය.');
            }
            if(Language == 5){
                setKashi('ငါးကင် စတာတွေကို ကင်ချင်ရင်လည်း အကင်ကို သုံးနိုင်ပါတယ်။');
            }
            if(Language == 6){
                setKashi('আপনি যদি মাছ, ইত্যাদি গ্রিল করতে চান, আপনি গ্রিলও ব্যবহার করতে পারেন।');
            }
        }
        if(counter >= 27){
            if(Language == 0){
                setKashi('グリルをつかうときは、かならず水を入れてつかいましょう。');
            }
            if(Language == 1){
                setKashi('When using the grill, be sure to fill it with water.');
            }
            if(Language == 2){
                setKashi('使用烤架時，請務必加滿水。');
            }
            if(Language == 3){
                setKashi('ग्रिल प्रयोग गर्दा, ग्रिलमा　पानी भर्न निश्चित हुनुहोस्।');
            }
            if(Language == 4){
                setKashi('ග්රිල් භාවිතා කරන විට, එය ජලය සමග පිරවීමට වග බලා ගන්න.');
            }
            if(Language == 5){
                setKashi('အကင်ကိုအသုံးပြုတဲ့အခါ၊ ရေနဲ့သေချာဖြည့်ပါ။');
            }
            if(Language == 6){
                setKashi('গ্রিল ব্যবহার করার সময়, এটি জল দিয়ে পূরণ করতে ভুলবেন না।');
            }
        }
        if(counter >= 33.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 38){
            if(Language == 0){
                setKashi('火がつかないとき、赤いランプがつきます。');
            }
            if(Language == 1){
                setKashi('When the fire stops, the red lamp will turn on.');
            }
            if(Language == 2){
                setKashi('當火停止時，紅燈會亮起。');
            }
            if(Language == 3){
                setKashi('जब आगो बल्दैन, रातो बत्ती बल्छ।');
            }
            if(Language == 4){
                setKashi('ගින්න නැවැත්වූ විට රතු එළිය දැල්වෙයි.');
            }
            if(Language == 5){
                setKashi('မီးရပ်သွားတဲ့အခါ မီးနီပွင့်လာမယ်။');
            }
            if(Language == 6){
                setKashi('আগুন বন্ধ হয়ে গেলে, লাল বাতি জ্বলবে।');
            }
        }
        if(counter >= 42.7){
            if(Language == 0){
                setKashi('電池をかえてください。');
            }
            if(Language == 1){
                setKashi('Please change the battery.');
            }
            if(Language == 2){
                setKashi('請更換電池。');
            }
            if(Language == 3){
                setKashi('कृपया ब्याट्री परिवर्तन गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර බැටරිය වෙනස් කරන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးပြု၍ ဘက်ထရီကိုပြောင်းပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে ব্যাটারি পরিবর্তন করুন।');
            }
        }
        if(counter >= 46.6){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 53.3){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn MChBChange');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
        }
        // エアコンの使い方
        if(counter >= 54){
            if(Language == 0){
                setKashi('エアコンの使い方');
            }
            if(Language == 1){
                setKashi('How to use the air conditioner');
            }
            if(Language == 2){
                setKashi('空調的使用方法');
            }
            if(Language == 3){
                setKashi('एयर कंडीशनर प्रयोग गर्ने तरीका');
            }
            if(Language == 4){
                setKashi('වායු සමීකරණ යන්ත්රය භාවිතා කරන්නේ කෙසේද');
            }
            if(Language == 5){
                setKashi('လေအေးပေးစက် အသုံးပြုနည်း');
            }
            if(Language == 6){
                setKashi('এয়ার কন্ডিশনার কিভাবে ব্যবহার করবেন');
            }
        }
        if(counter >= 57){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 58){
            if(Language == 0){
                setKashi('部屋では暖房や冷房をつかうことができます。');
            }
            if(Language == 1){
                setKashi('You can use cooling and heating  in your room.');
            }
            if(Language == 2){
                setKashi('您可以在房間內使用暖氣或冷氣。');
            }
            if(Language == 3){
                setKashi('तपाईं आफ्नो कोठा तातो  वा चिसो बनाउन सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබේ කාමරයේ උණුසුම හෝ සිසිලනය භාවිතා කළ හැකිය.');
            }
            if(Language == 5){
                setKashi('သင့်အခန်းတွင် အပူပေးခြင်း သို့မဟုတ် အအေးပေးခြင်းတို့ကို အသုံးပြုနိုင်သည်။');
            }
            if(Language == 6){
                setKashi('আপনি আপনার ঘরে গরম এবং ঠান্ডা ব্যবহার করতে পারেন।');
            }
        }
        if(counter >= 62.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 66.2){
            if(Language == 0){
                setKashi('冷房の温度はだいたい23℃～26℃、');
            }
            if(Language == 1){
                setKashi('The cooling temperature is approximately 23℃~26℃,');
            }
            if(Language == 2){
                setKashi('冷卻溫度設定約23℃~28℃，');
            }
            if(Language == 3){
                setKashi('चिसो बनाउनकोलागी तापमान लगभग 23 ℃ देखि 26 ℃ हुनुपर्छ। ');
            }
            if(Language == 4){
                setKashi('වායුසමීකරණ යන්ත්රයේ  සිසිලඋෂ්ණත්වය ආසන්න වශයෙන් 23℃~26℃,');
            }
            if(Language == 5){
                setKashi('လေအေးပေးစက်၏ အပူချိန်မှာ ခန့်မှန်းခြေအားဖြင့် 23 ℃ ~ 26 ℃ ၊');
            }
            if(Language == 6){
                setKashi('এয়ার কন্ডিশনার তাপমাত্রা প্রায় 23℃~26℃,');
            }
        }
        if(counter >= 71.1){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 75.1){
            if(Language == 0){
                setKashi('暖房の温度はだいたい25℃～28℃にしましょう。');
            }
            if(Language == 1){
                setKashi('The heating temperature should be approximately 25℃ to 28℃.');
            }
            if(Language == 2){
                setKashi('加熱溫度應約25℃至28℃。');
            }
            if(Language == 3){
                setKashi('तातो बनाउनको लागी तापमान लगभग 25 ℃ देखि 28 ℃ हुनुपर्छ।');
            }
            if(Language == 4){
                setKashi('උනුසුම් උෂ්ණත්වය ආසන්න වශයෙන් 25 ° සිට 28 ° දක්වා විය යුතුය.');
            }
            if(Language == 5){
                setKashi('အပူအပူချိန်သည် ခန့်မှန်းခြေအားဖြင့် 25°C မှ 28°C ရှိသင့်သည်။');
            }
            if(Language == 6){
                setKashi('গরম করার তাপমাত্রা প্রায় 25 ℃ থেকে 28 ℃ হওয়া উচিত।');
            }
        }
        if(counter >= 80.6){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 85.3){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn MChBChange');
            setChapterColor4('MoveChapBtn');
        }
        // 洗濯機の使い方
        if(counter >= 86){
            if(Language == 0){
                setKashi('洗濯機の使い方');
            }
            if(Language == 1){
                setKashi('How to use a washing machine');
            }
            if(Language == 2){
                setKashi('洗衣機的使用方法');
            }
            if(Language == 3){
                setKashi('लुगा धुने मेसिन प्रयोग गर्ने तरीका');
            }
            if(Language == 4){
                setKashi('රෙදි සෝදන යන්ත්රයක් භාවිතා කරන්නේ කෙසේද');
            }
            if(Language == 5){
                setKashi('အဝတ်လျှော်စက်အသုံးပြုနည်း');
            }
            if(Language == 6){
                setKashi('কিভাবে একটি ওয়াশিং মেশিন ব্যবহার করতে হয়');
            }
        }
        if(counter >= 89.5){
            if(Language == 0){
                setKashi('洗濯機（せんたくき）をつかうときは、でんげんをつけてスタートをおしてください。');
            }
            if(Language == 1){
                setKashi('When using the washing machine, please turn on the laundry and press start.');
            }
            if(Language == 2){
                setKashi('使用洗衣機時，請開啟並按啟動鍵。');
            }
            if(Language == 3){
                setKashi('धुने मेसिन प्रयोग गर्दा,पावर अन बटन थिच्नुहोस् र सुरु गर्ने बटन थिच्नुहोस्।');
            }
            if(Language == 4){
                setKashi('රෙදි සෝදන යන්ත්රය භාවිතා කරන විට, එය සක්රිය කර ආරම්භය ඔබන්න.');
            }
            if(Language == 5){
                setKashi('အဝတ်လျှော်စက်ကို အသုံးပြုသောအခါတွင် ၎င်းကိုဖွင့်ပြီး စတင်မည်ကို နှိပ်ပါ။');
            }
            if(Language == 6){
                setKashi('ওয়াশিং মেশিন ব্যবহার করার সময়, অনুগ্রহ করে লন্ড্রি চালু করুন এবং স্টার্ট টিপুন৷');
            }
        }
        if(counter >= 96){
            if(Language == 0){
                setKashi('たくさん服を入れると、洗濯（せんたく）できません。');
            }
            if(Language == 1){
                setKashi("If you put too many clothes in it, you won't be able to wash it.");
            }
            if(Language == 2){
                setKashi('衣服放太多的話就洗不了了。');
            }
            if(Language == 3){
                setKashi('यदि तपाईंले यसमा धेरै लुगाहरू राख्नुभयो भने, तपाईंले यसलाई धुन सक्नुहुन्न।');
            }
            if(Language == 4){
                setKashi('රෙදි ගොඩක් දැම්මොත් හෝදන්න බැරි වෙනවා.');
            }
            if(Language == 5){
                setKashi('အဝတ်တွေ အများကြီးထည့်ထားရင် လျှော်လို့ မရတော့ဘူး။');
            }
            if(Language == 6){
                setKashi('আপনি যদি এটিতে অনেকগুলি কাপড় রাখেন তবে আপনি এটি ধুতে পারবেন না।');
            }
        }
        if(counter >= 100){
            if(Language == 0){
                setKashi('気をつけてください。');
            }
            if(Language == 1){
                setKashi('Please be careful.');
            }
            if(Language == 2){
                setKashi('偶爾洗一下衣服。');
            }
            if(Language == 3){
                setKashi('कृपया सावधान रहनुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර පරිස්සම් වන්න.');
            }
            if(Language == 5){
                setKashi('သတိထားပါ။');
            }
            if(Language == 6){
                setKashi('দয়া করে সতর্ক থাকুন।');
            }
        }
        if(counter >= 103.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 105){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn MChBChange');
        }
        // お風呂の使い方
        if(counter >= 105.7){
            if(Language == 0){
                setKashi('お風呂の使い方');
            }
            if(Language == 1){
                setKashi('How to use the bath');
            }
            if(Language == 2){
                setKashi('浴缸的使用方法');
            }
            if(Language == 3){
                setKashi('नुहाउने ठाउँ प्रयोग गर्ने तरिका');
            }
            if(Language == 4){
                setKashi('ස්නානය භාවිතා කරන්නේ කෙසේද');
            }
            if(Language == 5){
                setKashi('ရေချိုးခန်းအသုံးပြုနည်း');
            }
            if(Language == 6){
                setKashi('স্নান কিভাবে ব্যবহার করবেন');
            }
        }
        if(counter >= 109){
            if(Language == 0){
                setKashi('お湯がでないときは、ガスがオフになっているかもしれません。');
            }
            if(Language == 1){
                setKashi('If there is no hot water, the gas may be turned off.');
            }
            if(Language == 2){
                setKashi('如果沒有熱水，瓦斯可能會關閉。');
            }
            if(Language == 3){
                setKashi('यदि नुहाउने ठाउँमा तातोपानी आउँदैन भने ग्याँस बन्द भएको हुनसक्छ।');
            }
            if(Language == 4){
                setKashi('ඔබට උණු වතුර අවශ්‍ය නොමැති නම්, ගෑස් නිවා දැමිය හැකිය.');
            }
            if(Language == 5){
                setKashi('သင့်တွင် ရေနွေးမရှိပါက ဓာတ်ငွေ့ပိတ်သွားနိုင်သည်။');
            }
            if(Language == 6){
                setKashi('গরম পানি না থাকলে গ্যাস বন্ধ করা হতে পারে।');
            }
        }
        if(counter >= 113.3){
            if(Language == 0){
                setKashi('オフになっていたら、レバーを下におしながら回すとオンになります。');
            }
            if(Language == 1){
                setKashi("If it's off, hold the lever down and turn it to turn it on.");
            }
            if(Language == 2){
                setKashi('如果它處於關閉狀態，請向下按操縱桿並轉動它以將其打開。');
            }
            if(Language == 3){
                setKashi('यदि यो बन्द छ भने, लीभर तल थिच्नुहोस् र यसलाई खोल्न यसलाई घुमाउनुहोस्।');
            }
            if(Language == 4){
                setKashi('එය ක්‍රියා විරහිත නම්, ලීවරය පහළට ඔබා එය ක්‍රියාත්මක කිරීමට එය හරවන්න.');
            }
            if(Language == 5){
                setKashi('ပိတ်ထားပါက လီဗာကို အောက်သို့ နှိပ်ပြီး ၎င်းကိုဖွင့်ရန် ခလုတ်ကိုနှိပ်ပါ။');
            }
            if(Language == 6){
                setKashi('যদি এটি বন্ধ থাকে, লিভারটি ধরে রাখুন এবং এটি চালু করতে এটি চালু করুন।');
            }
        }
        if(counter >= 118.1){
            if(Language == 0){
                setKashi('ハンドルを赤いほうにまわすとお湯が出ます。');
            }
            if(Language == 1){
                setKashi('Turn the handle to the red side to get hot water.');
            }
            if(Language == 2){
                setKashi('將手把轉向紅色一側即可獲得熱水。');
            }
            if(Language == 3){
                setKashi('ह्यान्डललाई रातोतर्फ घुमाउनुभयो भने तातोपानी आउनेछ।');
            }
            if(Language == 4){
                setKashi('උණු වතුර ලබා ගැනීම සඳහා හසුරුව රතු පැත්තට හරවන්න.');
            }
            if(Language == 5){
                setKashi('ရေနွေးရဖို့ လက်ကိုင်ကို အနီရောင်ဘက်ကို လှည့်ပါ။');
            }
            if(Language == 6){
                setKashi('গরম জল পেতে হাতলটিকে লাল দিকে ঘুরিয়ে দিন।');
            }
        }
        if(counter >= 121.7){
            if(Language == 0){
                setKashi('青いほうにまわすと水が出ます。');
            }
            if(Language == 1){
                setKashi('Turn it to the blue side for cold water.');
            }
            if(Language == 2){
                setKashi('將其轉向藍色一側，水就會流出來。');
            }
            if(Language == 3){
                setKashi('यसलाई नीलोतर्फ घुमाउनुभयो भने  चिसोपानी आउनेछ।');
            }
            if(Language == 4){
                setKashi('එය නිල් පැත්තට හරවන්න, එවිට  සිසිල් ඡලය පිටතට පැමිණේ.');
            }
            if(Language == 5){
                setKashi('အပြာဘက်ကို လှည့်လျှင်ရေအေးထွက်လာမည်။');
            }
            if(Language == 6){
                setKashi('এটিকে নীল দিকে ঘুরিয়ে দিন এবং জল বেরিয়ে আসবে।');
            }
        }
        if(counter >= 124.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 126.3){
            if(Language == 0){
                setKashi('ハンドルを上げても水が出ないときは、');
            }
            if(Language == 1){
                setKashi('If water does not come out even after raising the handle,');
            }
            if(Language == 2){
                setKashi('如果即使抬起手柄後也沒有出水，');
            }
            if(Language == 3){
                setKashi('ह्यान्डल उठाउँदा पनि पानी आएन भने');
            }
            if(Language == 4){
                setKashi('හසුරුව එසවූ පසුවත් ජලය පිට නොවන්නේ නම්,');
            }
            if(Language == 5){
                setKashi('လက်ကိုင်ကို မြှင့်ပြီးတောင် ရေမထွက်ရင်၊');
            }
            if(Language == 6){
                setKashi('হাতল তোলার পরও যদি পানি বের না হয়,');
            }
        }
        if(counter >= 130){
            if(Language == 0){
                setKashi('シャワーについているボタンをおしてください。');
            }
            if(Language == 1){
                setKashi('Please press the button on the shower.');
            }
            if(Language == 2){
                setKashi('按下淋浴上的按鈕。');
            }
            if(Language == 3){
                setKashi('पानी आउने ठाउँ नजिकै एउटा बटम छ त्यसलाई थिच्नुहोस्।');
            }
            if(Language == 4){
                setKashi('නානය මත බොත්තම ඔබන්න.');
            }
            if(Language == 5){
                setKashi('ရေချိုးခန်းထဲက ခလုတ်ကို နှိပ်ပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে ঝরনার বোতাম টিপুন।');
            }
        }
        if(counter >= 135){
            if(Language == 0){
                setKashi('ボタンで水を出したり止めたりできます。');
            }
            if(Language == 1){
                setKashi('You can turn the water on and off with the button.');
            }
            if(Language == 2){
                setKashi('您只需按一下按鈕即可打開和關閉水。');
            }
            if(Language == 3){
                setKashi('यो बटमलाई थिचेर पानी खोल्न र बन्द गर्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('බොත්තමක් එබීමෙන් ඔබට ජලය සක්රිය සහ අක්රිය කළ හැකිය.');
            }
            if(Language == 5){
                setKashi('ခလုတ်တစ်ချက်နှိပ်ရုံဖြင့် ရေကို အဖွင့်အပိတ်လုပ်နိုင်ပါသည်။');
            }
            if(Language == 6){
                setKashi('আপনি বোতাম দিয়ে জল চালু এবং বন্ধ করতে পারেন।');
            }
        }
        if(counter >= 139){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
    }

    const timeSetMsg1 = () => {
        document.getElementById('video').currentTime = 0;
    }
    const timeSetMsg2 = () => {
        document.getElementById('video').currentTime = 53.5;
    }
    const timeSetMsg3 = () => {
        document.getElementById('video').currentTime = 85.5;
    }
    const timeSetMsg4 = () => {
        document.getElementById('video').currentTime = 105;
    }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            setLogInNo(LogInNo);
            navigate('/');
        }
    })

    const HomeReset = () => {
        setTimeVI(0);
        setLogInNo(LogInNo);
        navigate('/');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <img src={Titleimg} className='MenuLogo' onClick={() => {HomeReset()}}/>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                        <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                        <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                        <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>Chp4</div>
                    </div>
                    <video src={Electronics} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                    <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                    <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                    <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>Chp4</div>
                </div>
            </div>
        </div>
    )
}