import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import WSDB from '../Images/WSDB.mp4';

export default function CardMove ({ setLanguage, Language, isAuth, setIsAuth, TimeVI, setTimeVI, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    // 再生チャプター変色
    const [ChapterColor1, setChapterColor1] = useState('MoveChapBtn');
    const [ChapterColor2, setChapterColor2] = useState('MoveChapBtn');
    const [ChapterColor3, setChapterColor3] = useState('MoveChapBtn');
    const [ChapterColor4, setChapterColor4] = useState('MoveChapBtn');

    const [TimePlus, setTimePlus] = useState(0)
    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        if(TimePlus == 0){
            document.getElementById('video').currentTime = TimeVI;
            setTimePlus(1);
        }

        // ビザ
        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }

            setChapterColor1('MoveChapBtn MChBChange');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
        }
        if(counter >= 0.5){
            if(Language == 0){
                setKashi('学生証');
            }
            if(Language == 1){
                setKashi('Student ID card');
            }
            if(Language == 2){
                setKashi('學生卡');
            }
            if(Language == 3){
                setKashi('विद्यार्थी कार्ड');
            }
            if(Language == 4){
                setKashi('ශිෂ්ය කාඩ්පත');
            }
            if(Language == 5){
                setKashi('ကျောင်းသားကတ်');
            }
            if(Language == 6){
                setKashi('ছাত্র পত্র');
            }
        }
        if(counter >= 3.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 4.3){
            if(Language == 0){
                setKashi('学生証はカードとWSDBアプリにあります。');
            }
            if(Language == 1){
                setKashi('There are two types of student ID card: an ID card and WSDB app.');
            }
            if(Language == 2){
                setKashi('您可以使用學生卡和 WSDB App。');
            }
            if(Language == 3){
                setKashi('तपाईँहरूको विद्यार्थी कार्ड एउटा कार्डमा छ अर्को WSDB एप्पमा छ ।');
            }
            if(Language == 4){
                setKashi('ශිෂ්‍ය හැඳුනුම්පත් සහ WSDB යෙදුම් තිබේ.');
            }
            if(Language == 5){
                setKashi('ကျောင်းသား ID ကတ်များနှင့် WSDB အက်ပ်များ ရှိပါသည်။');
            }
            if(Language == 6){
                setKashi('স্টুডেন্ট আইডি কার্ড এবং WSDB অ্যাপস আছে।');
            }
        }
        if(counter >= 9.3){
            if(Language == 0){
                setKashi('日本でじゅんびしたSIMがある人は、');
            }
            if(Language == 1){
                setKashi('If you have a valid SIM in Japan,');
            }
            if(Language == 2){
                setKashi('若您有在日本準備的 SIM 卡，');
            }
            if(Language == 3){
                setKashi('जापानको  सिम लिनु भए');
            }
            if(Language == 4){
                setKashi('ඔබට ජපානයේ භාවිතා කරන  සිම්පතක් තිබේ නම්,');
            }
            if(Language == 5){
                setKashi('ဂျပန်မှာ ဆင်းမ်ကတ် ရှိပီးလျှင်၊');
            }
            if(Language == 6){
                setKashi('আপনার যদি জাপানে একটি বৈধ সিম থাকে,');
            }
        }
        if(counter >= 12.3){
            if(Language == 0){
                setKashi('あとからアプリを入れましょう。');
            }
            if(Language == 1){
                setKashi("please install the WSDB app.");
            }
            if(Language == 2){
                setKashi('請稍後安裝該App。');
            }
            if(Language == 3){
                setKashi('पछि एप डाउनलोड गर्नुहोला।');
            }
            if(Language == 4){
                setKashi('අපි පසුව යෙදුම ස්ථාපනය කරමු.');
            }
            if(Language == 5){
                setKashi('အက်ပ်ကို downloadထည့်ထားရန်။');
            }
            if(Language == 6){
                setKashi('পরে অ্যাপটি ইনস্টল করা যাক।');
            }
        }
        if(counter >= 14.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 16.5){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn MChBChange');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
        }
        //　WSDBアプリ
        if(counter >= 17.2){
            if(Language == 0){
                setKashi('WSDBアプリ');
            }
            if(Language == 1){
                setKashi('WSDB app');
            }
            if(Language == 2){
                setKashi('WSDB App');
            }
            if(Language == 3){
                setKashi('WSDB एप');
            }
            if(Language == 4){
                setKashi('WSDB යෙදුම');
            }
            if(Language == 5){
                setKashi('WSDB အက်ပ်');
            }
            if(Language == 6){
                setKashi('WSDB অ্যাপ');
            }
        }
        if(counter >= 20.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 21){
            if(Language == 0){
                setKashi('NSAでは、WSDBというアプリで学生証や出席を見ることができます。');
            }
            if(Language == 1){
                setKashi('At NSA, you can check your student ID and attendance using an app called WSDB.');
            }
            if(Language == 2){
                setKashi('在 NSA，您可以使用名為 WSDB 的App檢查您的學生證和出勤情況。');
            }
            if(Language == 3){
                setKashi('NSA मा तपाईंले WSDB नामक एप प्रयोग गरेर आफ्नो विद्यार्थी परिचयपत्र र उपस्थिति जाँच गर्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('NSA හි, ඔබට WSDB නම් යෙදුමක් භාවිතයෙන් ඔබේ ශිෂ්‍ය හැඳුනුම්පත සහ පැමිණීම පරීක්ෂා කළ හැක.');
            }
            if(Language == 5){
                setKashi('NSA တွင်၊ သင်သည် WSDB ဟုခေါ်သော အက်ပ်ကို အသုံးပြု၍ သင်၏ကျောင်းသား ID ကဒ်နှင့်အတန်းတက်ရောက်မှုကို စစ်ဆေးနိုင်သည်။');
            }
            if(Language == 6){
                setKashi('NSA-তে, আপনি WSDB নামক একটি অ্যাপ ব্যবহার করে আপনার স্টুডেন্ট আইডি এবং উপস্থিতি পরীক্ষা করতে পারেন।');
            }
        }
        if(counter >= 27.6){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 28.8){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn MChBChange');
            setChapterColor4('MoveChapBtn');
        }
        // アプリのダウンロード
        if(counter >= 29.5){
            if(Language == 0){
                setKashi('アプリのダウンロード');
            }
            if(Language == 1){
                setKashi('Download the app');
            }
            if(Language == 2){
                setKashi('下載應用程式');
            }
            if(Language == 3){
                setKashi('एप डाउनलोड गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('යෙදුම බාගන්න');
            }
            if(Language == 5){
                setKashi('အက်ပ်ကို ဒေါင်းလုဒ်လုပ်ပါ။');
            }
            if(Language == 6){
                setKashi('অ্যাপটি ডাউনলোড করুন');
            }
        }
        if(counter >= 32.6){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 33.7){
            if(Language == 0){
                setKashi('日本でSIMカードを買ったら、WSDBアプリをダウンロードしてください。');
            }
            if(Language == 1){
                setKashi('After purchasing a SIM card in Japan, download the WSDB app.');
            }
            if(Language == 2){
                setKashi('在日本購買 SIM 卡後，請下載 WSDB App。');
            }
            if(Language == 3){
                setKashi('एकपटक तपाईंले जापानमा सिम कार्ड किन्नुभएपछि, WSDB एप डाउनलोड गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබ ජපානයේ SIM කාඩ්පතක් මිලදී ගත් පසු, WSDB යෙදුම download කරගන්න');
            }
            if(Language == 5){
                setKashi('ဂျပန်တွင် SIM ကတ်ဝယ်ပြီးသည်နှင့် WSDB အက်ပ်ကို ဒေါင်းလုဒ်လုပ်ပါ။');
            }
            if(Language == 6){
                setKashi('আপনি যদি জাপানে একটি সিম কার্ড কিনে থাকেন, তাহলে WSDB অ্যাপ ডাউনলোড করুন।');
            }
        }
        if(counter >= 39.5){
            if(Language == 0){
                setKashi('まず、IPhoneの人はApp Store、Androidの人はGoogle Playを開いてください。');
            }
            if(Language == 1){
                setKashi('First, for iPhone users open the App Store, for Android users open Google Play.');
            }
            if(Language == 2){
                setKashi('首先，iPhone用戶開啟App Store，Android用戶開啟Google Play。');
            }
            if(Language == 3){
                setKashi('पहिले, आईफोन प्रयोगकर्ताहरूको लागि एप स्टोर खोल्नुहोस् र एन्ड्रोइड प्रयोगकर्ताहरूको लागि गुगल प्ले।');
            }
            if(Language == 4){
                setKashi('පළමුව, iPhone භාවිතා කරන්නන් සඳහා App Store සහ Android භාවිතා කරන්නන් සඳහා Google Play විවෘත කරන්න.');
            }
            if(Language == 5){
                setKashi('ပထမဦးစွာ iPhone အသုံးပြုသူများအတွက် App Store နှင့် Android အသုံးပြုသူများအတွက် Google Play ကိုဖွင့်ပါ။');
            }
            if(Language == 6){
                setKashi('প্রথমে, আইফোন ব্যবহারকারীদের অ্যাপ স্টোর খুলতে হবে, অ্যান্ড্রয়েড ব্যবহারকারীদের গুগল প্লে খুলতে হবে।');
            }
        }
        if(counter >= 46.5){
            if(Language == 0){
                setKashi('「WSDB」と検索してください。');
            }
            if(Language == 1){
                setKashi('Search for "WSDB".');
            }
            if(Language == 2){
                setKashi('請搜尋“WSDB”。');
            }
            if(Language == 3){
                setKashi('कृपया "WSDB" खोज्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර "WSDB" සඳහා සොයන්න.');
            }
            if(Language == 5){
                setKashi('"WSDB" ကိုရှာပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে "WSDB" অনুসন্ধান করুন।');
            }
        }
        if(counter >= 52){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 54.3){
            if(Language == 0){
                setKashi('「WSDB学生管理システム」というアプリをダウンロードしてください。');
            }
            if(Language == 1){
                setKashi('Download the app "WSDB Student Management System".');
            }
            if(Language == 2){
                setKashi('請下載名為「WSDB學生管理系統」的App。');
            }
            if(Language == 3){
                setKashi('कृपया "WSDB विद्यार्थी व्यवस्थापन प्रणाली" नामक एप डाउनलोड गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර "WSDB ශිෂ්‍ය කළමනාකරණ පද්ධතිය" නම් යෙදුම  download කරගන්න.');
            }
            if(Language == 5){
                setKashi('"WSDB ကျောင်းသားစီမံခန့်ခွဲမှုစနစ်" ဟုခေါ်သောအက်ပ်ကိုဒေါင်းလုဒ်လုပ်ပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে "WSDB Student Management System" অ্যাপটি ডাউনলোড করুন।');
            }
        }
        if(counter >= 61){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 67){
            if(Language == 0){
                setKashi('アプリをクリックすると、ログイン画面が表示されますので、');
            }
            if(Language == 1){
                setKashi('Click on WSDB app and the login screen will appear.');
            }
            if(Language == 2){
                setKashi('當您點擊該App時，將顯示登入畫面。');
            }
            if(Language == 3){
                setKashi('जब तपाइँ एपमा क्लिक गर्नुहुन्छ, लगइन स्क्रिन देखाउनेछ।');
            }
            if(Language == 4){
                setKashi('ඔබ යෙදුම මත ක්ලික් කළ විට, පිවිසුම් තිරය දර්ශනය වේ.');
            }
            if(Language == 5){
                setKashi('အက်ပ်ကို နှိပ်လိုက်သောအခါ၊ အကောင့်ဝင်သည့် မျက်နှာပြင်ကို ပြသမည်ဖြစ်သည်။');
            }
            if(Language == 6){
                setKashi('অ্যাপটিতে ক্লিক করুন এবং লগইন স্ক্রীন প্রদর্শিত হবে।');
            }
        }
        if(counter >= 73){
            if(Language == 0){
                setKashi('IDとパスワードをにゅうりょくしてログインしましょう。');
            }
            if(Language == 1){
                setKashi('Enter your username and password to log in.');
            }
            if(Language == 2){
                setKashi('輸入您的使用者名稱和密碼登入。');
            }
            if(Language == 3){
                setKashi('आफ्नो प्रयोगकर्ता नाम र पासवर्ड राखेर लग इन गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබගේ පරිශීලක නාමය සහ මුරපදය ඇතුළත් කිරීමෙන් ලොග් වන්න.');
            }
            if(Language == 5){
                setKashi('သင်၏အသုံးပြုသူအမည်နှင့် စကားဝှက်ကို ထည့်သွင်းခြင်းဖြင့် ဝင်ရောက်ပါ။');
            }
            if(Language == 6){
                setKashi('লগ ইন করতে আপনার ব্যবহারকারীর নাম এবং পাসওয়ার্ড লিখুন।');
            }
        }
        if(counter >= 77.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 78.3){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn MChBChange');
        }
        // アプリの使い方
        if(counter >= 79){
            if(Language == 0){
                setKashi('アプリの使い方');
            }
            if(Language == 1){
                setKashi('How to use the app');
            }
            if(Language == 2){
                setKashi('如何使用該App');
            }
            if(Language == 3){
                setKashi('एप कसरी प्रयोग गर्ने');
            }
            if(Language == 4){
                setKashi('යෙදුම භාවිතා කරන්නේ කෙසේද');
            }
            if(Language == 5){
                setKashi('အက်ပ်ကိုအသုံးပြုနည်း');
            }
            if(Language == 6){
                setKashi('অ্যাপটি কিভাবে ব্যবহার করবেন');
            }
        }
        if(counter >= 83){
            if(Language == 0){
                setKashi('アプリでできることをしょうかいします。');
            }
            if(Language == 1){
                setKashi('Here are some things you can do with the app.');
            }
            if(Language == 2){
                setKashi('我們將向您展示該App可以做什麼。');
            }
            if(Language == 3){
                setKashi('हामी तपाईंलाई एपबाट के गर्न सक्नुहुन्छ भनेर देखाउने छौँ।');
            }
            if(Language == 4){
                setKashi('ඔබට යෙදුම සමඟ කළ හැකි දේ අපි ඔබට පෙන්වන්නෙමු.');
            }
            if(Language == 5){
                setKashi('အက်ပ်ဖြင့် သင်ဘာလုပ်နိုင်သည်ကို ကျွန်ုပ်တို့ပြသပါမည်။');
            }
            if(Language == 6){
                setKashi('আপনি অ্যাপ দিয়ে কি করতে পারেন তা দেখান।');
            }
        }
        if(counter >= 87.4){
            if(Language == 0){
                setKashi('まずは学生証の確認です。');
            }
            if(Language == 1){
                setKashi('First, check your student ID card.');
            }
            if(Language == 2){
                setKashi('首先，檢查您的學生證。');
            }
            if(Language == 3){
                setKashi('पहिले, आफ्नो विद्यार्थी आईडी जाँच गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('පළමුව, ඔබේ ශිෂ්‍ය හැඳුනුම්පත පරීක්ෂා කරන්න.');
            }
            if(Language == 5){
                setKashi('ပထမဦးစွာ သင်၏ကျောင်းသား ID ကိုစစ်ဆေးပါ။');
            }
            if(Language == 6){
                setKashi('প্রথমে আপনার স্টুডেন্ট আইডি চেক করুন।');
            }
        }
        if(counter >= 90){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 93.3){
            if(Language == 0){
                setKashi('ボタンを押すと自分の学生証が見られます。');
            }
            if(Language == 1){
                setKashi('Click the button to see your student ID card.');
            }
            if(Language == 2){
                setKashi('按按鈕查看您的學生證。');
            }
            if(Language == 3){
                setKashi('आफ्नो विद्यार्थी आईडी हेर्न बटन थिच्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබේ ශිෂ්‍ය හැඳුනුම්පත බැලීමට බොත්තම ඔබන්න.');
            }
            if(Language == 5){
                setKashi('သင်၏ကျောင်းသား ID ကိုကြည့်ရန် ခလုတ်ကိုနှိပ်ပါ။');
            }
            if(Language == 6){
                setKashi('আপনার ছাত্র আইডি দেখতে বোতাম টিপুন।');
            }
        }
        if(counter >= 97){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 98.6){
            if(Language == 0){
                setKashi('じぶんの出席を見ます。');
            }
            if(Language == 1){
                setKashi("Let's look at your own attendance.");
            }
            if(Language == 2){
                setKashi('檢查您的出席情況。');
            }
            if(Language == 3){
                setKashi('आफ्नो उपस्थिति जाँच गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබේ පැමීණිම පරීක්ෂා කරන්න.');
            }
            if(Language == 5){
                setKashi('ကျွန်ုပ်၏တက်ရောက်မှုကိုစစ်ဆေးပါ။');
            }
            if(Language == 6){
                setKashi('আমার উপস্থিতি পরীক্ষা করুন।');
            }
        }
        if(counter >= 100.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 105.3){
            if(Language == 0){
                setKashi('今までの出席をチェックできます。');
            }
            if(Language == 1){
                setKashi('You can check your whole attendance.');
            }
            if(Language == 2){
                setKashi('您可以查看到目前為止您的出席情況。');
            }
            if(Language == 3){
                setKashi('तपाईं अहिले सम्मकोआफ्नो उपस्थिति जाँच गर्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබට මෙතෙක් ඔබගේ පැමිණීම පරීක්ෂා කළ හැකිය.');
            }
            if(Language == 5){
                setKashi('သင့်တက်ရောက်မှုကို ယခုအချိန်အထိ စစ်ဆေးနိုင်ပါသည်။');
            }
            if(Language == 6){
                setKashi('আপনি এখন পর্যন্ত আপনার উপস্থিতি পরীক্ষা করতে পারেন।');
            }
        }
        if(counter >= 108.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 111.2){
            if(Language == 0){
                setKashi('じぶんのテストの点数を見ます。');
            }
            if(Language == 1){
                setKashi("Let's look at your own examination score.");
            }
            if(Language == 2){
                setKashi('看看你的考試成績。');
            }
            if(Language == 3){
                setKashi('आफ्नो परीक्षाको स्कोर जाँच गर्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබේ පරීක්ෂණ ලකුණු බලන්න.');
            }
            if(Language == 5){
                setKashi('သင်၏စာမေးပွဲရမှတ်များကိုကြည့်ပါ။ ');
            }
            if(Language == 6){
                setKashi('আমার পরীক্ষার স্কোর দেখুন।');
            }
        }
        if(counter >= 113.1){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 117){
            if(Language == 0){
                setKashi('今までのテストの点数をチェックできます。');
            }
            if(Language == 1){
                setKashi('You can check all your examination scores.');
            }
            if(Language == 2){
                setKashi('您可以查看到目前為止的考試成績。');
            }
            if(Language == 3){
                setKashi('तपाईं अहिलेसम्मको आफ्नो परीक्षाको स्कोर जाँच गर्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබට මෙතෙක් ඔබගේ පරීක්ෂණ ලකුණු පරීක්ෂා කළ හැකිය.');
            }
            if(Language == 5){
                setKashi('သင်၏ စာမေးပွဲရမှတ်များကို ယခုအချိန်အထိ စစ်ဆေးနိုင်ပါသည်။');
            }
            if(Language == 6){
                setKashi('আপনি এখন পর্যন্ত আপনার পরীক্ষার স্কোর পরীক্ষা করতে পারেন।');
            }
        }
        if(counter >= 121.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 128.8){
            if(Language == 0){
                setKashi('じぶんの成績を見ます。');
            }
            if(Language == 1){
                setKashi("Let's look at your own examination result.");
            }
            if(Language == 2){
                setKashi('看看您的成績。');
            }
            if(Language == 3){
                setKashi('आफ्नो ग्रेड हेर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබේ ශේණිය බලා ගැනීමට හැකිය.');
            }
            if(Language == 5){
                setKashi('သင်၏အဆင့်များကိုကြည့်ပါ။');
            }
            if(Language == 6){
                setKashi('আমার গ্রেড দেখুন।');
            }
        }
        if(counter >= 131.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('按按鈕查看您的成績。');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 135){
            if(Language == 0){
                setKashi('今までの成績をチェックできます。');
            }
            if(Language == 1){
                setKashi('You can check all your grades.');
            }
            if(Language == 2){
                setKashi('您可以檢查到目前為止的結果。');
            }
            if(Language == 3){
                setKashi('तपाईं आफ्नो  अहिलेसम्मको ग्रेड　जाँच गर्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබට මෙතෙක් ඔබේ ප්‍රතිඵල පරීක්ෂා කළ හැක.');
            }
            if(Language == 5){
                setKashi('သင့်ရလဒ်များကို ယခုအချိန်အထိ စစ်ဆေးနိုင်ပါသည်။');
            }
            if(Language == 6){
                setKashi('আপনি এখন পর্যন্ত আপনার ফলাফল পরীক্ষা করতে পারেন।');
            }
        }
        if(counter >= 139.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 147){
            if(Language == 0){
                setKashi('学校の情報を見ます。');
            }
            if(Language == 1){
                setKashi("Let's look at the school information.");
            }
            if(Language == 2){
                setKashi('查看學校資訊。');
            }
            if(Language == 3){
                setKashi('स्कूल जानकारी हेर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('පාසල් තොරතුරු බලන්න.');
            }
            if(Language == 5){
                setKashi('ကျောင်းအချက်အလက်ကိုကြည့်ပါ။');
            }
            if(Language == 6){
                setKashi('স্কুলের তথ্য দেখুন।');
            }
        }
        if(counter >= 149.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('按按鈕查看學校資訊。');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 153.3){
            if(Language == 0){
                setKashi('NSAの住所、電話番号などをチェックできます。');
            }
            if(Language == 1){
                setKashi("You can check NSA's address, phone number, etc.");
            }
            if(Language == 2){
                setKashi('您可以查看 NSA 的地址、電話號碼等。');
            }
            if(Language == 3){
                setKashi('तपाईं NSA को ठेगाना, फोन नम्बर, आदि जाँच गर्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබට NSA හි ලිපිනය, දුරකථන අංකය, ආදිය පරීක්ෂා කළ හැක.');
            }
            if(Language == 5){
                setKashi('NSA ၏လိပ်စာ၊ ဖုန်းနံပါတ်စသည်တို့ကို သင်စစ်ဆေးနိုင်သည်။');
            }
            if(Language == 6){
                setKashi('আপনি NSA এর ঠিকানা, ফোন নম্বর ইত্যাদি পরীক্ষা করতে পারেন।');
            }
        }
        if(counter >= 157.6){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
    }

    const timeSetMsg1 = () => {
        document.getElementById('video').currentTime = 0;
    }
    const timeSetMsg2 = () => {
        document.getElementById('video').currentTime = 16.5;
    }
    const timeSetMsg3 = () => {
        document.getElementById('video').currentTime = 29;
    }
    const timeSetMsg4 = () => {
        document.getElementById('video').currentTime = 79;
    }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            setLogInNo(LogInNo);
            navigate('/');
        }
    })

    const HomeReset = () => {
        setTimeVI(0);
        setLogInNo(LogInNo);
        navigate('/');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <img src={Titleimg} className='MenuLogo' onClick={() => {HomeReset()}}/>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                        <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                        <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                        <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>Chp4</div>
                    </div>
                    <video src={WSDB} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                    <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                    <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                    <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>Chp4</div>
                </div>
            </div>
        </div>
    )
}