import './App.css';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Home from './JS/Home';
import StayHome from './JS/StayHome';

import PrincipalMove from './JS/PrincipalMove';
import InsideSchoolMove from './JS/InsideSchoolMove';
import VISAMove from './JS/VISAMove';
import CardMove from './JS/CardMove';
import SchoolLifeMove from './JS/SchoolLifeMove';
import ProofMove from './JS/ProofMove';
import LessonMove from './JS/LessonMove';
import RuhrMove from './JS/RuhrMove';
import SchoolRuhr from './JS/SchoolRuhr';
import DorRuhrMove from './JS/DorRuhrMove';
import JourneyAMove from './JS/JourneyAMove';
import JourneyBMove from './JS/JourneyBMove';
import DormitoryTourMove from './JS/DormitoryTourMove';
import ElectronicsMove from './JS/ElectronicsMove';

import AllMove from './JS/AllMove';
import AllMoveShort from './JS/AllMoveShort';

function App() {
  const [Language, setLanguage] = useState(0);
  const [isAuth, setIsAuth] = useState(false);

  const [TimeVI, setTimeVI] = useState(0);

  const [LogInNo, setLogInNo] = useState(0);

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} TimeVI={TimeVI} setTimeVI={setTimeVI} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/StayHome' element={<StayHome Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} TimeVI={TimeVI} setTimeVI={setTimeVI} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/PrincipalMove' element={<PrincipalMove Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/InsideSchoolMove' element={<InsideSchoolMove Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/VISAMove' element={<VISAMove Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} TimeVI={TimeVI} setTimeVI={setTimeVI} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/CardMove' element={<CardMove Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} TimeVI={TimeVI} setTimeVI={setTimeVI} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>

        <Route path='/SchoolLifeMove' element={<SchoolLifeMove Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} TimeVI={TimeVI} setTimeVI={setTimeVI} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/ProofMove' element={<ProofMove Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} TimeVI={TimeVI} setTimeVI={setTimeVI} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/LessonMove' element={<LessonMove Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} TimeVI={TimeVI} setTimeVI={setTimeVI} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/RuhrMove' element={<RuhrMove Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} TimeVI={TimeVI} setTimeVI={setTimeVI} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/SchoolRuhr' element={<SchoolRuhr Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} TimeVI={TimeVI} setTimeVI={setTimeVI} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/DorRuhrMove' element={<DorRuhrMove Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} TimeVI={TimeVI} setTimeVI={setTimeVI} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/JourneyAMove' element={<JourneyAMove Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} TimeVI={TimeVI} setTimeVI={setTimeVI} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/JourneyBMove' element={<JourneyBMove Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} TimeVI={TimeVI} setTimeVI={setTimeVI} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/DormitoryTourMove' element={<DormitoryTourMove Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} TimeVI={TimeVI} setTimeVI={setTimeVI} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/ElectronicsMove' element={<ElectronicsMove Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} TimeVI={TimeVI} setTimeVI={setTimeVI} LogInNo={LogInNo} setLogInNo={setLogInNo} />}></Route>
        <Route path='/AllMove' element={<AllMove Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} />}></Route>
        <Route path='/AllMoveShort' element={<AllMoveShort Language={Language} setLanguage={setLanguage} isAuth={isAuth} setIsAuth={setIsAuth} />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
