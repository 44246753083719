import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import VISA from '../Images/VISA.mp4';

export default function VISAMove ({ setLanguage, Language, isAuth, setIsAuth, TimeVI, setTimeVI, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    // 再生チャプター変色
    const [ChapterColor1, setChapterColor1] = useState('MoveChapBtn');
    const [ChapterColor2, setChapterColor2] = useState('MoveChapBtn');
    const [ChapterColor3, setChapterColor3] = useState('MoveChapBtn');
    const [ChapterColor4, setChapterColor4] = useState('MoveChapBtn');
    const [ChapterColor5, setChapterColor5] = useState('MoveChapBtn');
    const [ChapterColor6, setChapterColor6] = useState('MoveChapBtn');
    const [ChapterColor7, setChapterColor7] = useState('MoveChapBtn');
    const [ChapterColor8, setChapterColor8] = useState('MoveChapBtn');

    const [TimePlus, setTimePlus] = useState(0)
    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        if(TimePlus == 0){
            document.getElementById('video').currentTime = TimeVI;
            setTimePlus(1);
        }

        // ビザ
        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }

            setChapterColor1('MoveChapBtn MChBChange');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
        }
        if(counter >= 0.5){
            if(Language == 0){
                setKashi('ビザの有効期限');
            }
            if(Language == 1){
                setKashi('Visa validity period');
            }
            if(Language == 2){
                setKashi('簽證效期');
            }
            if(Language == 3){
                setKashi('भिसा म्याद सकिने मिति');
            }
            if(Language == 4){
                setKashi('වීසා වලංගු කාලය');
            }
            if(Language == 5){
                setKashi('ဗီဇာသက်တမ်း');
            }
            if(Language == 6){
                setKashi('ভিসার মেয়াদকাল');
            }
        }
        if(counter >= 3.7){
            if(Language == 0){
                setKashi('ビザについて ビザの有効期限');
            }
            if(Language == 1){
                setKashi('About VISA ／ VISA expiry date');
            }
            if(Language == 2){
                setKashi('關於簽證 簽證效期');
            }
            if(Language == 3){
                setKashi('भिसा बारे भिसा म्याद सकिने मिति।');
            }
            if(Language == 4){
                setKashi('වීසා ගැන වීසා වලංගු කාලය');
            }
            if(Language == 5){
                setKashi('ဗီဇာအကြောင်း　 ဗီဇာသက်တမ်း');
            }
            if(Language == 6){
                setKashi('ভিসা সম্পর্কে ভিসার মেয়াদ শেষ হওয়ার তারিখ');
            }
        }
        if(counter >= 6){
            if(Language == 0){
                setKashi('ビザの話をします。');
            }
            if(Language == 1){
                setKashi("I'm going to explain about visa.");
            }
            if(Language == 2){
                setKashi('我現在就來解釋一下簽證的事。');
            }
            if(Language == 3){
                setKashi('भिसाको बारेमा कुराहरु ।');
            }
            if(Language == 4){
                setKashi('අපි වීසා ගැන කතා කරමු.');
            }
            if(Language == 5){
                setKashi('ဗီဇာအကြောင်း ပြောကြရအောင်။');
            }
            if(Language == 6){
                setKashi('ভিসা সম্পর্কে কথা বলা যাক।');
            }
        }
        if(counter >= 8.5){
            if(Language == 0){
                setKashi('みなさん、在留カードを見てください。');
            }
            if(Language == 1){
                setKashi('Please look at your residence card.');
            }
            if(Language == 2){
                setKashi('請大家看一下自己的在留卡。');
            }
            if(Language == 3){
                setKashi('सबैजना, कृपया आफ्नो निवास कार्ड हेर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('හැමෝම, කරුණාකර ඔබේ පදිංචි කාඩ්පත බලන්න.');
            }
            if(Language == 5){
                setKashi('လူတိုင်း၊ သင့်နေထိုင်ခွင့်ကတ်ကို ကြည့်ပါ။');
            }
            if(Language == 6){
                setKashi('সবাই, আপনার আবাসিক কার্ড দেখুন।');
            }
        }
        if(counter >= 12){
            if(Language == 0){
                setKashi('あなたのビザはいつまでですか。');
            }
            if(Language == 1){
                setKashi('When is the expiry date of your visa?');
            }
            if(Language == 2){
                setKashi('您的簽證有效期限是多久？');
            }
            if(Language == 3){
                setKashi('तपाईको भिसा कति लामो छ?');
            }
            if(Language == 4){
                setKashi('ඔබගේ වීසා බලපත්‍රය කොපමණ කාලයක්ද?');
            }
            if(Language == 5){
                setKashi('မင်းရဲ့ ဗီဇာက ဘယ်ချိန်ထိလဲ။');
            }
            if(Language == 6){
                setKashi('আপনার ভিসা কতদিনের?');
            }
        }
        if(counter >= 15){
            if(Language == 0){
                setKashi('ビザがおわる3か月前に、更新しなければなりません。');
            }
            if(Language == 1){
                setKashi('You must renew your visa three months before it expires.');
            }
            if(Language == 2){
                setKashi('您必須在簽證到期前三個月更新簽證。');
            }
            if(Language == 3){
                setKashi('तपाईंले आफ्नो भिसाको म्याद समाप्त हुनुभन्दा तीन महिना अघि नवीकरण गर्नुपर्छ।');
            }
            if(Language == 4){
                setKashi('ඔබ ඔබේ වීසා බලපත්‍රය කල් ඉකුත් වීමට මාස තුනකට පෙර අලුත් කළ යුතුය.');
            }
            if(Language == 5){
                setKashi('ဗီဇာသက်တမ်းမကုန်မီ သုံးလနှင့်အထက်သက်တန်းတိုးရန်။');
            }
            if(Language == 6){
                setKashi('আপনার ভিসার মেয়াদ শেষ হওয়ার তিন মাস আগে আপনাকে অবশ্যই নবায়ন করতে হবে।');
            }
        }
        if(counter >= 20.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 21.4){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn MChBChange');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
        }
        // ビザとアルバイト
        if(counter >= 22){
            if(Language == 0){
                setKashi('ビザとアルバイト');
            }
            if(Language == 1){
                setKashi('Visa and part-time job');
            }
            if(Language == 2){
                setKashi('簽證和兼職工作');
            }
            if(Language == 3){
                setKashi('भिसा र पार्ट टाइम काम');
            }
            if(Language == 4){
                setKashi('වීසා සහ අර්ධකාලීන රැකියාව');
            }
            if(Language == 5){
                setKashi('ဗီဇာနှင့် အချိန်ပိုင်းအလုပ်');
            }
            if(Language == 6){
                setKashi('ভিসা এবং খণ্ডকালীন চাকরি');
            }
        }
        if(counter >= 25){
            if(Language == 0){
                setKashi('出席と成績がよくて、アルバイトの時間をまもっている人は、');
            }
            if(Language == 1){
                setKashi('Students who have good attendance, good grades, and follow the rules at their part-time jobs');
            }
            if(Language == 2){
                setKashi('出席率高、成績好、準時兼職的人');
            }
            if(Language == 3){
                setKashi('राम्रो हाजिरी, राम्रो ग्रेड, र　पार्ट टाइमको नियमपालना　गर्नेहरूले');
            }
            if(Language == 4){
                setKashi('හොඳ පැමිණීමක්, හොඳ ලකුණු ඇති සහ ඔවුන්ගේ අර්ධකාලීන රැකියා සඳහා නියමිත වේලාවට සිටින අය වේ');
            }
            if(Language == 5){
                setKashi('ကျောင်းတက်ချိန်မှန်၊ အမှတ်ကောင်းကောင်းရပြီး အချိန်ပိုင်းအလုပ်အတွက် အချိန်မှန်နေသူများဖြစ်သည်။');
            }
            if(Language == 6){
                setKashi('যাদের উপস্থিতি ভালো, ভালো গ্রেড এবং পার্ট টাইম কাজের সময় নিয়ম মেনে তারা ।');
            }
        }
        if(counter >= 32){
            if(Language == 0){
                setKashi('長いビザをもらうことができます。');
            }
            if(Language == 1){
                setKashi('can receive a longer term of VISA.');
            }
            if(Language == 2){
                setKashi('您可以獲得長期簽證。');
            }
            if(Language == 3){
                setKashi('लामो भिसा प्राप्त गर्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබට දිගු වීසා බලපත්රයක් ලබා ගත හැකිය.');
            }
            if(Language == 5){
                setKashi('ဗီဇာ အရှည်ကြီး ရနိုင်ပါတယ်။');
            }
            if(Language == 6){
                setKashi('দীর্ঘ ভিসা পাবেন');
            }
        }
        if(counter >= 34.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 35.8){
            if(Language == 0){
                setKashi('出席と成績が悪い人、');
            }
            if(Language == 1){
                setKashi('Students with lack of attendance and poor grades');
            }
            if(Language == 2){
                setKashi('出席率和成績較差的人');
            }
            if(Language == 3){
                setKashi('नराम्रो　हाजिरी र ग्रेड भएका मानिसहरू');
            }
            if(Language == 4){
                setKashi('අඩු පැමිණීම් සහ  අඩු ලකුණු ඇති පුද්ගලයින්');
            }
            if(Language == 5){
                setKashi('တက်ရောက်သူနှင့် အတန်းညံ့သူများ');
            }
            if(Language == 6){
                setKashi('দুর্বল উপস্থিতি এবং গ্রেড সহ মানুষ');
            }
        }
        if(counter >= 40){
            if(Language == 0){
                setKashi('アルバイトの時間が長すぎる人は');
            }
            if(Language == 1){
                setKashi('Students who work part-time jobs too much');
            }
            if(Language == 2){
                setKashi('兼職工作時間超過規定的人');
            }
            if(Language == 3){
                setKashi('धेरै लामो समय पार्टटाइम काम गर्ने मानिसहरू');
            }
            if(Language == 4){
                setKashi('වැඩි කාලයක් අර්ධකාලීනව වැඩ කරන අය');
            }
            if(Language == 5){
                setKashi('အချိန်ပိုင်း အကြာကြီး အလုပ်လုပ်နေသူများ');
            }
            if(Language == 6){
                setKashi('যারা খুব বেশি সময় ধরে পার্টটাইম কাজ করে');
            }
        }
        if(counter >= 44){
            if(Language == 0){
                setKashi('ビザがもらえないかもしれません。');
            }
            if(Language == 1){
                setKashi('Might not receive a visa');
            }
            if(Language == 2){
                setKashi('您可能無法獲得簽證');
            }
            if(Language == 3){
                setKashi('तपाईंले भिसा नपाउन सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබට වීසා බලපත්‍රයක් නොලැබෙනු ඇත');
            }
            if(Language == 5){
                setKashi('ဗီဇာမရပါ။');
            }
            if(Language == 6){
                setKashi('আপনি ভিসা নাও পেতে পারেন');
            }
        }
        if(counter >= 48){
            if(Language == 0){
                setKashi('ルールをまもってはたらいてください。');
            }
            if(Language == 1){
                setKashi('Please follow the rules and work.');
            }
            if(Language == 2){
                setKashi('請遵守規則並工作。');
            }
            if(Language == 3){
                setKashi('कृपया नियमहरू पालना गर्नुहोस् र काम गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර නීති රීති අනුගමනය කර වැඩ කරන්න.');
            }
            if(Language == 5){
                setKashi('စည်းကမ်းချက်တွေကို လိုက်နာပြီး အလုပ်လုပ်ပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে নিয়ম মেনে কাজ করুন।');
            }
        }
        if(counter >= 51.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 53.7){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn MChBChange');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
        }
        // 国民健康保険
        if(counter >= 54.3){
            if(Language == 0){
                setKashi('国民健康保険');
            }
            if(Language == 1){
                setKashi('National Health Insurance');
            }
            if(Language == 2){
                setKashi('國民健康保險');
            }
            if(Language == 3){
                setKashi('राष्ट्रिय स्वास्थ्य बीमा');
            }
            if(Language == 4){
                setKashi('ජාතික සෞඛ්ය රක්ෂණය');
            }
            if(Language == 5){
                setKashi('အမျိုးသားကျန်းမာရေးအာမခံ');
            }
            if(Language == 6){
                setKashi('জাতীয় স্বাস্থ্য বীমা');
            }
        }
        if(counter >= 57.4){
            if(Language == 0){
                setKashi('日本に来たら、みなさん国民健康保険にもうしこまなければなりません。');
            }
            if(Language == 1){
                setKashi('When you come to Japan, you have to apply for National Health Insurance.');
            }
            if(Language == 2){
                setKashi('來日本時也必須加入國民健康保險。');
            }
            if(Language == 3){
                setKashi('तपाईं जापान आउँदा, तपाईंले राष्ट्रिय स्वास्थ्य बीमाको लागि पनि आवेदन गर्नुपर्नेछ।');
            }
            if(Language == 4){
                setKashi('ඔබ ජපානයට පැමිණෙන විට, ඔබට ජාතික සෞඛ්‍ය රක්‍ෂණය සඳහාද අයදුම් කිරීමට සිදුවේ.');
            }
            if(Language == 5){
                setKashi('ဂျပန်ကို ရောက်တဲ့အခါ အမျိုးသားကျန်းမာရေး အာမခံကို လျှောက်ထားရမှာ ဖြစ်ပါတယ်။');
            }
            if(Language == 6){
                setKashi('আপনি যখন জাপানে আসবেন, আপনাকে জাতীয় স্বাস্থ্য বীমার জন্য আবেদন করতে হবে।');
            }
        }
        if(counter >= 66){
            if(Language == 0){
                setKashi('国民健康保険証があると、病院のお金が70％オフになります。');
            }
            if(Language == 1){
                setKashi('If you have a National Health Insurance card, you can get 70% off on hospital fees.');
            }
            if(Language == 2){
                setKashi('如果持有國民健康保險證，醫療費可享70％的折扣。');
            }
            if(Language == 3){
                setKashi('यदि तपाईंसँग राष्ट्रिय स्वास्थ्य बीमा कार्ड छ भने, तपाईंले अस्पताल शुल्कमा 70% छुट पाउन सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('ඔබට ජාතික සෞඛ්‍ය රක්‍ෂණ කාඩ්පතක් තිබේ නම්, ඔබට රෝහල් ගාස්තු මත 70% ක වට්ටමක් ලබා ගත හැකිය.');
            }
            if(Language == 5){
                setKashi('သင့်တွင် အမျိုးသားကျန်းမာရေးအာမခံကတ်တစ်ခုရှိလျှင် ဆေးရုံစရိတ် 70% လျှော့စျေးကို ရရှိနိုင်ပါသည်။');
            }
            if(Language == 6){
                setKashi('আপনার যদি ন্যাশনাল হেলথ ইন্স্যুরেন্স কার্ড থাকে, তাহলে আপনি হাসপাতালের ফিতে 70% ছাড় পেতে পারেন।');
            }
        }
        if(counter >= 73.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 75){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn MChBChange');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
        }
        // 年金
        if(counter >= 75.7){
            if(Language == 0){
                setKashi('年金');
            }
            if(Language == 1){
                setKashi('Pension');
            }
            if(Language == 2){
                setKashi('退休金');
            }
            if(Language == 3){
                setKashi('पेन्सन（वृदा भत्ता）');
            }
            if(Language == 4){
                setKashi('විශ්රාම වැටුප්');
            }
            if(Language == 5){
                setKashi('ပင်စင်');
            }
            if(Language == 6){
                setKashi('পেনশন');
            }
        }
        if(counter >= 79){
            if(Language == 0){
                setKashi('日本では、２０歳になったら年金をはらわなければなりません。');
            }
            if(Language == 1){
                setKashi('In Japan, you have to pay your pension when you turn 20 years old.');
            }
            if(Language == 2){
                setKashi('在日本，年滿20歲就必須繳交退休金。');
            }
            if(Language == 3){
                setKashi('जापानमा २० वर्ष पुगेपछि पेन्सन तिर्नुपर्छ।');
            }
            if(Language == 4){
                setKashi('ජපානයේ, ඔබට වයස අවුරුදු 20 සම්පූර්ණ වන විට විශ්රාම වැටුපක් ගෙවිය යුතුය.');
            }
            if(Language == 5){
                setKashi('ဂျပန်မှာ အသက် 20 ပြည့်ရင် ပင်စင်ပေးရမယ်။');
            }
            if(Language == 6){
                setKashi('জাপানে, আপনার বয়স 20 বছর হলে আপনাকে পেনশন দিতে হবে।');
            }
        }
        if(counter >= 86){
            if(Language == 0){
                setKashi('でも、学生のときは年金をはらわなくてもいいです。');
            }
            if(Language == 1){
                setKashi("But when you're a student, you don't have to pay for your pension.");
            }
            if(Language == 2){
                setKashi('但是，當您是學生時，您無需支付退休金。');
            }
            if(Language == 3){
                setKashi('यद्यपि, जब तपाईं विद्यार्थी हुनुहुन्छ, तपाईंले आफ्नो पेन्सनको लागि तिर्नुपर्ने छैन।');
            }
            if(Language == 4){
                setKashi('කෙසේ වෙතත්, ඔබ ශිෂ්‍යයෙකු වූ විට, ඔබේ විශ්‍රාම වැටුප සඳහා ගෙවිය යුතු නැත.');
            }
            if(Language == 5){
                setKashi('သို့သော် သင်သည် ကျောင်းသားဖြစ်သောအခါတွင် သင်၏ပင်စင်အတွက် ပေးဆောင်ရန် မလိုအပ်ပါ။');
            }
            if(Language == 6){
                setKashi('কিন্তু আপনি যখন একজন ছাত্র, আপনাকে আপনার পেনশনের জন্য অর্থ প্রদান করতে হবে না।');
            }
        }
        if(counter >= 90.5){
            if(Language == 0){
                setKashi('区役所で年金免除の申し込みをしてください。');
            }
            if(Language == 1){
                setKashi('You need to apply for pension exemption at the ward office.');
            }
            if(Language == 2){
                setKashi('請向居住地的區役所申請年金（退休金）減免。');
            }
            if(Language == 3){
                setKashi('कृपया आफ्नो वार्ड कार्यालयमा पेन्सन छुटको लागि आवेदन दिनुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබේ  ප්‍රාදේශීය සභාවේ දීවිශ්‍රාම වැටුප් නිදහස් කිරීම සඳහා අයදුම් කරන්න.');
            }
            if(Language == 5){
                setKashi('သင့်ရပ်ကွက်ရုံးတွင် ပင်စင်ကင်းလွတ်ခွင့်အတွက် လျှောက်ထားပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে ওয়ার্ড অফিসে পেনশন  জন্য আবেদন করুন।');
            }
        }
        if(counter >= 95.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 97){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn MChBChange');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
        }
        // ビザと給与明細
        if(counter >= 97.5){
            if(Language == 0){
                setKashi('ビザと給与明細');
            }
            if(Language == 1){
                setKashi('Visa and pay slip');
            }
            if(Language == 2){
                setKashi('簽證和薪資單');
            }
            if(Language == 3){
                setKashi('भिसा र पे स्लिप');
            }
            if(Language == 4){
                setKashi('වීසා සහ ගෙවීම් පත්‍රිකාව');
            }
            if(Language == 5){
                setKashi('ဗီဇာနှင့် လစာစလစ်');
            }
            if(Language == 6){
                setKashi('ভিসা এবং পে স্লিপ');
            }
        }
        if(counter >= 100.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 102.2){
            if(Language == 0){
                setKashi('毎月、アルバイトの給与明細を持って来てください。');
            }
            if(Language == 1){
                setKashi('Please submit your part-time job pay slip every month at school office.');
            }
            if(Language == 2){
                setKashi('請攜帶每月的兼職薪資單。');
            }
            if(Language == 3){
                setKashi('कृपया हरेक महिना आफ्नो पार्ट टाइम कामको पे स्लिप ल्याउनुहोस्।');
            }
            if(Language == 4){
                setKashi('සෑම මසකම ඔබගේ අර්ධකාලීන රැකියා වැටුප් පත්‍රිකාව රැගෙන එන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးပြု၍ လစဉ် အချိန်ပိုင်းအလုပ် လစာစာရွက်ကို ယူဆောင်လာပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে প্রতি মাসে আপনার খণ্ডকালীন চাকরির বেতন স্লিপ আনুন।');
            }
        }
        if(counter >= 108.3){
            if(Language == 0){
                setKashi('給与明細がないとき、つぎのビザを作ることができません。');
            }
            if(Language == 1){
                setKashi('If you do not submit a pay slip, you cannot apply for the next visa.');
            }
            if(Language == 2){
                setKashi('如果您沒有薪資單，您將無法續簽簽證。');
            }
            if(Language == 3){
                setKashi('यदि तपाईंसँग पे स्लिप छैन भने, तपाईंले अर्को भिसाको लागि आवेदन दिन सक्नुहुने छैन।');
            }
            if(Language == 4){
                setKashi('ඔබට වැටුප් පත්‍රිකාවක් නොමැති නම්, ඔබට වෙනත් වීසා බලපත්‍රයක් සඳහා අයදුම් කිරීමට නොහැකි වනු ඇත.');
            }
            if(Language == 5){
                setKashi('အကယ်၍ သင့်တွင် လစာစလစ်မရှိပါက အခြားဗီဇာကို လျှောက်ထားနိုင်မည်မဟုတ်ပေ။');
            }
            if(Language == 6){
                setKashi('যদি আপনার পে স্লিপ না থাকে, তাহলে আপনি পরবর্তী ভিসার জন্য আবেদন করতে পারবেন না।');
            }
        }
        if(counter >= 113.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 115.5){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn MChBChange');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
        }
        // 出席とビザ
        if(counter >= 116){
            if(Language == 0){
                setKashi('出席とビザ');
            }
            if(Language == 1){
                setKashi('Attendance and visa');
            }
            if(Language == 2){
                setKashi('出席和簽證');
            }
            if(Language == 3){
                setKashi('उपस्थिति र भिसा');
            }
            if(Language == 4){
                setKashi('පැමිණීම සහ වීසා');
            }
            if(Language == 5){
                setKashi('တက်ရောက်ခွင့်နှင့် ဗီဇာ');
            }
            if(Language == 6){
                setKashi('উপস্থিতি এবং ভিসা');
            }
        }
        if(counter >= 119){
            if(Language == 0){
                setKashi('出席が悪い人は、つぎのビザができません。');
            }
            if(Language == 1){
                setKashi('Students who have lack of attendance will not be able to get the next visa.');
            }
            if(Language == 2){
                setKashi('出席率低的人將無法續簽簽證。');
            }
            if(Language == 3){
                setKashi('कम उपस्थिति भएकाहरूले अर्को भिसा पाउन सक्ने छैनन्।');
            }
            if(Language == 4){
                setKashi('අඩු පැමිණීමක් ඇති අයට වෙනත් වීසා බලපත්‍රයක් ලබා ගැනීමට නොහැකි වනු ඇත.');
            }
            if(Language == 5){
                setKashi('တက်ရောက်သူ ချို့တဲ့သူများသည် အခြားဗီဇာကို ရရှိနိုင်မည်မဟုတ်ပေ။');
            }
            if(Language == 6){
                setKashi('যাদের উপস্থিতি কম তারা পরবর্তী ভিসা পেতে সক্ষম হবে না।');
            }
        }
        if(counter >= 123.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 125.3){
            if(Language == 0){
                setKashi('就職や進学もできません。');
            }
            if(Language == 1){
                setKashi("you can't work or move to higher education.");
            }
            if(Language == 2){
                setKashi('無法找到工作或接受高等教育');
            }
            if(Language == 3){
                setKashi('वर्किङ गर्न र　उच्च शिक्षामा　जान सक्नुहुने छैन।');
            }
            if(Language == 4){
                setKashi('රැකියාවක් කිරීමට හෝ උසස් අධ්‍යාපනයට යාමට නොහැකි වීම');
            }
            if(Language == 5){
                setKashi('အလုပ်ရနိုင်သလို အထက်တန်းပညာကိုလည်း မတက်နိုင်ပါ။');
            }
            if(Language == 6){
                setKashi('সেই ব্যক্তি চাকরি পাবে না বা উচ্চ শিক্ষায় যেতে পারবে না।');
            }
        }
        if(counter >= 128.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 130.2){
            if(Language == 0){
                setKashi('出席が80%より悪くなると、つぎのビザがみじかくなるかもしれません。');
            }
            if(Language == 1){
                setKashi('If your attendance is less than 80%, your next visa may be for a short period of time.');
            }
            if(Language == 2){
                setKashi('如果您的出席率低於 80%，下次續簽簽證時，在留期限可能會縮短。');
            }
            if(Language == 3){
                setKashi('यदि तपाईंको उपस्थिति 80% भन्दा खराब छ भने, तपाईंको अर्को भिसा खतरामा हुन सक्छ।');
            }
            if(Language == 4){
                setKashi('ඔබගේ පැමිණීම 80% ට වඩා නරක නම්, ඔබගේ මීළඟ වීසා බලපත්‍රය අනතුරේ විය හැක.');
            }
            if(Language == 5){
                setKashi('သင်တက်ရောက်သူ 80% ထက် ပိုဆိုးပါက သင်၏နောက်ထပ်ဗီဇာသည် အန္တရာယ်ရှိနိုင်ပါသည်။');
            }
            if(Language == 6){
                setKashi('যদি আপনার উপস্থিতি 80% এর চেয়ে খারাপ হয়, তাহলে আপনার পরবর্তী ভিসা বিপদে পড়তে পারে।');
            }
        }
        if(counter >= 138.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 140.3){
            if(Language == 0){
                setKashi('ビザのルールはどんどんきびしくなっています。');
            }
            if(Language == 1){
                setKashi('Visa rules are becoming rigid.');
            }
            if(Language == 2){
                setKashi('簽證規則變得越來越嚴格。');
            }
            if(Language == 3){
                setKashi('भिसा नियम झन कडा हुँदै गएको छ ।');
            }
            if(Language == 4){
                setKashi('වීසා නීති එන්න එන්නම දැඩි වෙමින් පවතී.');
            }
            if(Language == 5){
                setKashi('ဗီဇာစည်းကမ်းတွေက ပိုတင်းကျပ်လာတယ်။ အချိန်ပိုင်းအလုပ်');
            }
            if(Language == 6){
                setKashi('ভিসার নিয়ম আরও কঠোর থেকে কঠোর হচ্ছে।');
            }
        }
        if(counter >= 144.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 146.2){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn MChBChange');
            setChapterColor8('MoveChapBtn');
        }
        // アルバイト１
        if(counter >= 146.7){
            if(Language == 0){
                setKashi('アルバイト');
            }
            if(Language == 1){
                setKashi('Part-time job');
            }
            if(Language == 2){
                setKashi('兼職工作');
            }
            if(Language == 3){
                setKashi('पार्ट टाइम काम');
            }
            if(Language == 4){
                setKashi('අර්ධ කාලීන රැකියාව');
            }
            if(Language == 5){
                setKashi('အချိန်ပိုင်းအလုပ်');
            }
            if(Language == 6){
                setKashi('খন্ডকালীন চাকরী');
            }
        }
        if(counter >= 150){
            if(Language == 0){
                setKashi('日本でアルバイトをするとき、');
            }
            if(Language == 1){
                setKashi('When working part-time job in Japan,');
            }
            if(Language == 2){
                setKashi('在日本打工時，');
            }
            if(Language == 3){
                setKashi('जापानमा पार्टटाइम काम गर्दा,');
            }
            if(Language == 4){
                setKashi('ජපානයේ අර්ධකාලීන වැඩ කරන විට,');
            }
            if(Language == 5){
                setKashi('ဂျပန်မှာ အချိန်ပိုင်းအလုပ်လုပ်တဲ့အခါ၊');
            }
            if(Language == 6){
                setKashi('জাপানে খণ্ডকালীন কাজ করার সময়,');
            }
        }
        if(counter >= 153.2){
            if(Language == 0){
                setKashi('在留カードとマイナンバーカードを見せます。');
            }
            if(Language == 1){
                setKashi('Present your residence card and My Number card.');
            }
            if(Language == 2){
                setKashi('請出示在留卡和個人編號卡。');
            }
            if(Language == 3){
                setKashi('आफ्नो निवास कार्ड र MY NUMBER कार्ड देखाउनुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබේ පදිංචි කාඩ්පත සහ මගේ අංක කාඩ්පත පෙන්වන්න.');
            }
            if(Language == 5){
                setKashi('သင်၏နေထိုင်ခွင့်ကတ်နှင့် ကျွန်ုပ်၏နံပါတ်ကတ်ကို ပြပါ။');
            }
            if(Language == 6){
                setKashi('আপনার আবাসিক কার্ড এবং আমার নম্বর কার্ড দেখান।');
            }
        }
        if(counter >= 157){
            if(Language == 0){
                setKashi('入管はマイナンバーを調べて、その人のアルバイトの会社や給料を調べることができます。');
            }
            if(Language == 1){
                setKashi("The Immigration Bureau can check a person's My Number and find out the company and salary of that person's part-time job.");
            }
            if(Language == 2){
                setKashi('入國管理局可以檢查一個人的編號，並找出該人打工的公司和工資。');
            }
            if(Language == 3){
                setKashi('अध्यागमन अधिकारीहरूले एक व्यक्तिको MY NUMBER जाँच गरी त्यो व्यक्तिको पार्ट टाइम काम， कम्पनी र तलब पत्ता लगाउन सक्छन्।');
            }
            if(Language == 4){
                setKashi('ආගමන බලධාරීන්ට පුද්ගලයෙකුගේ මගේ අංකය පරීක්ෂා කර එම පුද්ගලයාගේ අර්ධකාලීන රැකියාවේ සමාගම සහ වැටුප සොයා ගත හැකිය.');
            }
            if(Language == 5){
                setKashi('လူဝင်မှုကြီးကြပ်ရေး အာဏာပိုင်များသည် လူတစ်ဦး၏ My Number ကို စစ်ဆေးနိုင်ပြီး ထိုလူ၏ အချိန်ပိုင်းအလုပ်၏ ကုမ္ပဏီနှင့် လစာကို ရှာဖွေနိုင်သည်။');
            }
            if(Language == 6){
                setKashi('অভিবাসন কর্তৃপক্ষ তাদের খণ্ডকালীন চাকরির কোম্পানি এবং বেতন জানতে ছাত্রের মাই নম্বর ব্যবহার করতে পারে।');
            }
        }
        if(counter >= 165.5){
            if(Language == 0){
                setKashi('学校にひみつでアルバイトをしたとき、つぎのビザがもらえないかもしれません。');
            }
            if(Language == 1){
                setKashi('If you work part-time without informing the school, you may not be able to get another visa.');
            }
            if(Language == 2){
                setKashi('如果您在學校秘密兼職，您可能無法續簽簽證。');
            }
            if(Language == 3){
                setKashi('यदि तपाईंले　विद्यालयमा जानकारी नदिइ गोप्य रूपमा  काम गर्नुहुन्छ भने, तपाईंले अर्को भिसा प्राप्त गर्न सक्नुहुन्न।');
            }
            if(Language == 4){
                setKashi('ඔබ රහසේ පාසැලේ අර්ධකාලීන රැකියාවක් කරන්නේ නම්, ඔබට වෙනත් වීසා බලපත්‍රයක් ලබා ගැනීමට නොහැකි වනු ඇත.');
            }
            if(Language == 5){
                setKashi('ကျောင်းတွင် အချိန်ပိုင်း တိတ်တဆိတ် အလုပ်လုပ်ပါက အခြားဗီဇာ မရနိုင်ပါ။');
            }
            if(Language == 6){
                setKashi('আপনি যদি স্কুলে একটি গোপন খণ্ডকালীন চাকরি করেন, তাহলে আপনি অন্য ভিসা পেতে সক্ষম হবেন না।');
            }
        }
        if(counter >= 173.5){
            if(Language == 0){
                setKashi('アルバイトをはじめるとき、必ずじむしょの先生に話してください。');
            }
            if(Language == 1){
                setKashi('When you start a part-time job, make sure to talk to your teacher at the office.');
            }
            if(Language == 2){
                setKashi('當您開始兼職工作時，請務必告訴辦公室的老師。');
            }
            if(Language == 3){
                setKashi('पार्ट टाइम काम सुरु गर्दा　तपाइँकोअफिसको शिक्षकलाई बताउनुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබ අර්ධකාලීන රැකියාවක් ආරම්භ කරන විට, කාර්යාලයේදී ඔබේ ගුරුවරයාට පැවසීමට වග බලා ගන්න.');
            }
            if(Language == 5){
                setKashi('အချိန်ပိုင်းအလုပ်စလုပ်တဲ့အခါ ရုံးမှာ သင့်ဆရာကို သေချာပြောပြပါ။');
            }
            if(Language == 6){
                setKashi('আপনি যখন একটি খণ্ডকালীন চাকরি শুরু করেন, অফিসে আপনার শিক্ষকের সাথে কথা বলতে ভুলবেন না।');
            }
        }
        if(counter >= 182.5){
            if(Language == 0){
                setKashi('問題があるときは、すぐにそうだんしてください。');
            }
            if(Language == 1){
                setKashi('If you have a problem, please consult your teacher immediately.');
            }
            if(Language == 2){
                setKashi('如果您有任何問題，請立即與我們聯繫。');
            }
            if(Language == 3){
                setKashi('यदि तपाईलाई समस्या छ भने, कृपया  तुरुन्तै  शिक्षकलाई बताउनुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබට ගැටලුවක් ඇත්නම් වහාම එය නිවැරදි කරන්න.');
            }
            if(Language == 5){
                setKashi('ပြဿနာရှိပါက ဆရာများနှင့်ချက်ချင်းတိုင်ပင်ပါ');
            }
            if(Language == 6){
                setKashi('যদি আপনার কোন সমস্যা থাকে, অনুগ্রহ করে তা অবিলম্বে সমাধান করুন।');
            }
        }
        if(counter >= 188){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 190.5){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn MChBChange');
        }
        // アルバイト２
        if(counter >= 191){
            if(Language == 0){
                setKashi('アルバイト');
            }
            if(Language == 1){
                setKashi('part-time job');
            }
            if(Language == 2){
                setKashi('兼職工作');
            }
            if(Language == 3){
                setKashi('आंसिक काम');
            }
            if(Language == 4){
                setKashi('අර්ධ කාලීන රැකියාව');
            }
            if(Language == 5){
                setKashi('အချိန်ပိုင်းအလုပ်');
            }
            if(Language == 6){
                setKashi('খন্ডকালীন চাকরী');
            }
        }
        if(counter >= 194.2){
            if(Language == 0){
                setKashi('これからアルバイトの話をします。');
            }
            if(Language == 1){
                setKashi("I'm going to explain about part-time jobs.");
            }
            if(Language == 2){
                setKashi('現在我將解釋一下兼職工作。');
            }
            if(Language == 3){
                setKashi('अब म  पार्ट टाइम कामबारेमा कुरा गर्नेछु।');
            }
            if(Language == 4){
                setKashi('මම දැන් අර්ධකාලීන රැකියා ගැන කතා කරන්නම්.');
            }
            if(Language == 5){
                setKashi('အခုပြောပြမယ့် အချိန်ပိုင်းအလုပ်တွေအကြောင်း။');
            }
            if(Language == 6){
                setKashi('আমি খণ্ডকালীন চাকরি সম্পর্কে কথা বলতে যাচ্ছি।');
            }
        }
        if(counter >= 197.5){
            if(Language == 0){
                setKashi('アルバイトを学校にないしょですることは、絶対にだめです。');
            }
            if(Language == 1){
                setKashi('It is absolutely not allowed to work part-time without informing the school.');
            }
            if(Language == 2){
                setKashi('絕對禁止不報到學校打工。');
            }
            if(Language == 3){
                setKashi('विद्यालयमा जानकारी नदिइ पार्ट टाइम काम गर्न पूर्ण रूपमा निषेध गरिएको छ।');
            }
            if(Language == 4){
                setKashi('පාසැලෙන් පිටත අර්ධකාලීනව වැඩ කිරීම සපුරා තහනම්ය.');
            }
            if(Language == 5){
                setKashi('ကျောင်းအပြင်မှာ အချိန်ပိုင်းအလုပ်လုပ်ဖို့ လုံးဝတားမြစ်ထားပါတယ်။');
            }
            if(Language == 6){
                setKashi('স্কুলের বাইরে খণ্ডকালীন কাজ করা একেবারেই নিষিদ্ধ।');
            }
        }
        if(counter >= 203.5){
            if(Language == 0){
                setKashi('始めるときとやめるときは、じむしょの先生にかならずそうだんしてください。');
            }
            if(Language == 1){
                setKashi('Make sure to inform your teacher at the office when starting or quitting your part-time job.');
            }
            if(Language == 2){
                setKashi('開始或停止時請務必諮詢辦公室的老師。');
            }
            if(Language == 3){
                setKashi('पार्ट टाइम काम सुरुगर्दा　  अथवा  छोद्दा कार्यालयमा आफ्नो शिक्षकसँग परामर्श गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ආරම්භ කරන විට හෝ නතර කරන විට කාර්යාලයේදී ඔබේ ගුරුවරයා සමඟ සාකච්ඡා කිරීමට වග බලා ගන්න.');
            }
            if(Language == 5){
                setKashi('စတင်ချိန် သို့မဟုတ် ရပ်သည့်အခါ ရုံးတွင် သင့်ဆရာနှင့် တိုင်ပင်ရန် သေချာပါစေ။');
            }
            if(Language == 6){
                setKashi('শুরু বা বন্ধ করার সময় অফিসে শিক্ষকের সাথে পরামর্শ করতে ভুলবেন না।');
            }
        }
        if(counter >= 210.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 213.5){
            if(Language == 0){
                setKashi('学校があるときは、1週間28時間以内、');
            }
            if(Language == 1){
                setKashi('When there is school, students can work for only 28 hours per week,');
            }
            if(Language == 2){
                setKashi('學校上課時，每週 28 小時內、');
            }
            if(Language == 3){
                setKashi('जब विद्यालय सत्रमा छ, प्रति हप्ता 28 घण्टा सम्म।');
            }
            if(Language == 4){
                setKashi('පාසැල පැවැත්වෙන විට, සතියකට පැය 28ක් ඇතුළත.');
            }
            if(Language == 5){
                setKashi('ကျောင်းတက်ချိန်၊ တစ်ပတ်လျှင် ၂၈ နာရီအတွင်း။');
            }
            if(Language == 6){
                setKashi('যখন স্কুল সেশনে থাকে, প্রতি সপ্তাহে 28 ঘন্টার মধ্যে,');
            }
        }
        if(counter >= 220){
            if(Language == 0){
                setKashi('長い休みのときは、1週間40時間以内です。');
            }
            if(Language == 1){
                setKashi('During long holidays, students can work less than 40 hours in a week.');
            }
            if(Language == 2){
                setKashi('長假期間，您每週最多可工作 40 小時。');
            }
            if(Language == 3){
                setKashi('लामो बिदाहरूमा, तपाइँ हप्तामा 40 घण्टा सम्म काम गर्न सक्नुहुन्छ।');
            }
            if(Language == 4){
                setKashi('දිගු නිවාඩු කාලය තුළ, ඔබට සතියකට පැය 40 ක් දක්වා වැඩ කළ හැකිය.');
            }
            if(Language == 5){
                setKashi('အားလပ်ရက်ရှည်များအတွင်း တစ်ပတ်လျှင် နာရီ ၄၀ အထိ အလုပ်လုပ်နိုင်သည်။');
            }
            if(Language == 6){
                setKashi('দীর্ঘ ছুটির সময়, এটি সপ্তাহে 40 ঘন্টারও কম।');
            }
        }
        if(counter >= 225.7){
            if(Language == 0){
                setKashi('かならずまもってください。');
            }
            if(Language == 1){
                setKashi('Make sure to follow the rules!');
            }
            if(Language == 2){
                setKashi('請務必遵守此規則！');
            }
            if(Language == 3){
                setKashi('पार्ट टाइमको नियमपालना गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('එම නීතීය අනුගමනය කීරීමට වග බලා ගන්න!');
            }
            if(Language == 5){
                setKashi('သေချာကာကွယ်ပါ။');
            }
            if(Language == 6){
                setKashi('নিয়ম অনুসরণ করুন.');
            }
        }
        if(counter >= 228.2){
            if(Language == 0){
                setKashi('きけんなアルバイト、');
            }
            if(Language == 1){
                setKashi('These are the part-time jobs not allowed for students,');
            }
            if(Language == 2){
                setKashi('不從事危險兼職、');
            }
            if(Language == 3){
                setKashi('जापानमा बिर्द्याथीले गर्न नमिल्ने पार्ट टाइम कामहरूः-');
            }
            if(Language == 4){
                setKashi('භයානක අර්ධකාලීන රැකියාවක්');
            }
            if(Language == 5){
                setKashi('အန္တရာယ်ရှိသော အချိန်ပိုင်းအလုပ်');
            }
            if(Language == 6){
                setKashi('বিপজ্জনক খণ্ডকালীন চাকরি,');
            }
        }
        if(counter >= 230.5){
            if(Language == 0){
                setKashi('健康に悪いアルバイト、');
            }
            if(Language == 1){
                setKashi('Part-time job that is bad for your health.');
            }
            if(Language == 2){
                setKashi('不健康兼職、');
            }
            if(Language == 3){
                setKashi('पार्ट टाइम कामहरू जुन तपाईंको स्वास्थ्यको लागि हानिकारक छ।');
            }
            if(Language == 4){
                setKashi('ඔබේ සෞඛ්‍යයට අහිතකර අර්ධකාලීන රැකියා');
            }
            if(Language == 5){
                setKashi('ကျန်းမာရေးအတွက် မကောင်းသော အချိန်ပိုင်းအလုပ်များ');
            }
            if(Language == 6){
                setKashi('খণ্ডকালীন চাকরি যা আপনার স্বাস্থ্যের জন্য খারাপ।');
            }
        }
        if(counter >= 233.5){
            if(Language == 0){
                setKashi('スナック、ギャンブルなどのアルバイトをしてはいけません。');
            }
            if(Language == 1){
                setKashi('Part-time job at hostess bars, gambling, etc.');
            }
            if(Language == 2){
                setKashi('酒吧工作、賭博工作等。');
            }
            if(Language == 3){
                setKashi('बार, क्लब, पब वा जुवा खेल्ने जस्ता  ठाउँहरूमा काम　नगर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ආහාර බෙදාහැරීම හෝ සූදුව වැනි අර්ධකාලීන රැකියාවල නිරත නොවන්න.');
            }
            if(Language == 5){
                setKashi('အဆာပြေစားခြင်း သို့မဟုတ် လောင်းကစားခြင်းကဲ့သို့သော အချိန်ပိုင်းအလုပ်များတွင် မပါဝင်ပါနှင့်။');
            }
            if(Language == 6){
                setKashi('খন্ডকালীন কাজ করবেন না যেমন স্ন্যাকিং বা জুয়া খেলা।');
            }
        }
        if(counter >= 238.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 239.8){
            if(Language == 0){
                setKashi('Uber Eatsなどはアルバイトの時間がわかりにくいのでだめです。');
            }
            if(Language == 1){
                setKashi('Do not work part-time jobs such as Uber Eats, as the working hours are difficult to count.');
            }
            if(Language == 2){
                setKashi('由於難以了解工作時間，因此禁止在 Uber Eats 等公司打工。');
            }
            if(Language == 3){
                setKashi('Uber Eats आदिमा　काम गरेको समय र घण्टा निश्चित नहुने भएकोले काम　नगर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('Uber Eats යනාදිය භාවිතා නොකරන්නේ මගේ අර්ධකාලීන රැකියාවේ නිරත  නියමිත වේලාවක් නියමිත දවසක් තීරණය කීරීමට  අපහසු නිසාය.');
            }
            if(Language == 5){
                setKashi('ကျွန်ုပ်၏အချိန်ပိုင်းအလုပ်သည် မည်သည့်အချိန်ဖြစ်သည်ကို ပြောပြရန်ခက်ခဲသောကြောင့် Uber Eats စသည်တို့ကို ကျွန်ုပ်အသုံးမပြုပါ။');
            }
            if(Language == 6){
                setKashi('আপনার UberEats ব্যবহার করা উচিত নয় কারণ আপনার খণ্ডকালীন চাকরি কখন হবে তা বলা কঠিন।');
            }
        }
        if(counter >= 245.5){
            if(Language == 0){
                setKashi('アルバイトのルールをまもらないとき、ビザをもらうことができません。');
            }
            if(Language == 1){
                setKashi('When students work for the prohibited jobs, they cannot renew their visa.');
            }
            if(Language == 2){
                setKashi('如果學生從事禁止的兼職工作，他們將不會獲得簽證。');
            }
            if(Language == 3){
                setKashi('पार्ट टाइमको नियमपालना　नगर्ने विद्यार्थीहरूले भिसा पाउन सक्ने छैनन्।');
            }
            if(Language == 4){
                setKashi('ශිෂ්‍යයෙකු නරක අර්ධ-කාලීන රැකියාවක් කරන්නේ නම්, ඔවුන්ට වීසා බලපත්‍රයක් ලබා ගැනීමට නොහැකි වනු ඇත.');
            }
            if(Language == 5){
                setKashi('ကျောင်းသားသည် အချိန်ပိုင်းအလုပ်မကောင်းပါက ဗီဇာရနိုင်မည်မဟုတ်ပေ။');
            }
            if(Language == 6){
                setKashi('যদি একজন ছাত্র নিষিদ্ধ একটি খণ্ডকালীন চাকরি নেয়, তাহলে তাদের ভিসা নবায়ন করা যাবে না।');
            }
        }
        if(counter >= 252.7){
            if(Language == 0){
                setKashi('新しいアルバイトをするときは、このアルバイトは');
            }
            if(Language == 1){
                setKashi('When starting a new part-time job, this part-time job should be');
            }
            if(Language == 2){
                setKashi('當開始新的兼職工作時，這份兼職工作應該是、');
            }
            if(Language == 3){
                setKashi('नयाँ पार्ट टाइम काम सुरु गर्दा, पार्ट टाइम कामहरूः ');
            }
            if(Language == 4){
                setKashi('නව අර්ධකාලීන රැකියාවක් ආරම්භ කරන විට, මෙම අර්ධකාලීන රැකියාව විය යුතුය');
            }
            if(Language == 5){
                setKashi('အချိန်ပိုင်းအလုပ်အသစ်တစ်ခုစလုပ်သောအခါ ဤအချိန်ပိုင်းအလုပ်ဖြစ်သင့်သည်။');
            }
            if(Language == 6){
                setKashi('একটি নতুন খণ্ডকালীন চাকরি শুরু করার সময়, এই খণ্ডকালীন চাকরি হওয়া উচিত');
            }
        }
        if(counter >= 257.5){
            if(Language == 0){
                setKashi('いいですか？だめですか？');
            }
            if(Language == 1){
                setKashi('okay? or not?');
            }
            if(Language == 2){
                setKashi('我可以？ 是不是不好啊？');
            }
            if(Language == 3){
                setKashi('गर्न मिल्छ या मिल्दैन');
            }
            if(Language == 4){
                setKashi('මම කරන්නද? ඒක හොඳ නැද්ද?');
            }
            if(Language == 5){
                setKashi('ရမလား? မကောင်းဘူးလား?');
            }
            if(Language == 6){
                setKashi('ঠিক আছে? এটা কি ভাল না?');
            }
        }
        if(counter >= 261){
            if(Language == 0){
                setKashi('じむしょの先生にそうだんしてください。');
            }
            if(Language == 1){
                setKashi('Please consult the teachers at the office.');
            }
            if(Language == 2){
                setKashi('請諮詢辦公室老師。');
            }
            if(Language == 3){
                setKashi('कृपया कार्यालयमा शिक्षकसँग परामर्श गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර කාර්යාලයේ ගුරුවරයා සමඟ සාකච්ඡා කරන්න.');
            }
            if(Language == 5){
                setKashi('ရုံးမှာ ဆရာနဲ့ တိုင်ပင်ပါ။');
            }
            if(Language == 6){
                setKashi('অফিসে শিক্ষকের সাথে পরামর্শ করুন।');
            }
        }
        if(counter >= 264.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
    }

    const timeSetMsg1 = () => {
        document.getElementById('video').currentTime = 0;
    }
    const timeSetMsg2 = () => {
        document.getElementById('video').currentTime = 21.5;
    }
    const timeSetMsg3 = () => {
        document.getElementById('video').currentTime = 53.7;
    }
    const timeSetMsg4 = () => {
        document.getElementById('video').currentTime = 75;
    }
    const timeSetMsg5 = () => {
        document.getElementById('video').currentTime = 97;
    }
    const timeSetMsg6 = () => {
        document.getElementById('video').currentTime = 115.5;
    }
    const timeSetMsg7 = () => {
        document.getElementById('video').currentTime = 146.5;
    }
    const timeSetMsg8 = () => {
        document.getElementById('video').currentTime = 190.5;
    }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            setLogInNo(LogInNo);
            navigate('/');
        }
    })

    const HomeReset = () => {
        setTimeVI(0);
        setLogInNo(LogInNo);
        navigate('/');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <img src={Titleimg} className='MenuLogo' onClick={() => {HomeReset()}}/>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                        <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                        <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                        <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>Chp4</div>
                        <div className={ChapterColor5} onClick={() => {timeSetMsg5()}}>Chp5</div>
                    </div>
                    <div className='MoveChapArea'>
                        <div className={ChapterColor6} onClick={() => {timeSetMsg6()}}>Chp6</div>
                        <div className={ChapterColor7} onClick={() => {timeSetMsg7()}}>Chp7</div>
                        <div className={ChapterColor8} onClick={() => {timeSetMsg8()}}>Chp8</div>
                    </div>
                    <video src={VISA} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                    <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                    <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                    <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>Chp4</div>
                    <div className={ChapterColor5} onClick={() => {timeSetMsg5()}}>Chp5</div>
                </div>
                <div className='MoveChapArea3'>
                    <div className={ChapterColor6} onClick={() => {timeSetMsg6()}}>Chp6</div>
                    <div className={ChapterColor7} onClick={() => {timeSetMsg7()}}>Chp7</div>
                    <div className={ChapterColor8} onClick={() => {timeSetMsg8()}}>Chp8</div>
                </div>
            </div>
        </div>
    )
}