import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import JourneyA from '../Images/JourneyA.mp4';

export default function JourneyAMove ({ setLanguage, Language, isAuth, setIsAuth, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        //　寮(尾上団地)スタート！
        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
        }
        if(counter >= 0.5){
            if(Language == 0){
                setKashi('寮(尾上団地)スタート！');
            }
            if(Language == 1){
                setKashi('Start of the road to the dormitory (Onoe Danchi)!');
            }
            if(Language == 2){
                setKashi('宿舍（尾上團地）開始了！');
            }
            if(Language == 3){
                setKashi('छात्रावास (ओनोउए दान्ची) सुरु भएको छ!');
            }
            if(Language == 4){
                setKashi('නේවාසිකාගාරය (Onoe Danchi) ආරම්භ වී ඇත!');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင် (Onoe Danchi) စတင်ပါပြီ။');
            }
            if(Language == 6){
                setKashi('ডরমিটরি (Onoe Danchi) শুরু!');
            }
        }
        if(counter >= 2.9){
            if(Language == 0){
                setKashi('学校を出て、ひだりにまがってください。');
            }
            if(Language == 1){
                setKashi('Exit the school and turn to the left.');
            }
            if(Language == 2){
                setKashi('退出學校並左轉。');
            }
            if(Language == 3){
                setKashi('स्कूलबाट बाहिर निस्कनुहोस् र बायाँ घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('පාසලෙන් පිටවී වමට හැරෙන්න.');
            }
            if(Language == 5){
                setKashi('ကျောင်းမှထွက်ပြီး ဘယ်ဘက်သို့ လှည့်ပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে স্কুল ত্যাগ করুন এবং আশ্রয়ে যান।');
            }
        }
        if(counter >= 7.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 10.6){
            if(Language == 0){
                setKashi('すぐつぎのかどをみぎにまがります。');
            }
            if(Language == 1){
                setKashi('Turn right at the next corner.');
            }
            if(Language == 2){
                setKashi('在下一個轉角處右轉。');
            }
            if(Language == 3){
                setKashi('अलि अगाडि नजिकैबाट दाँया घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඊළඟ කෙළවරේ දකුණට හැරෙන්න.');
            }
            if(Language == 5){
                setKashi('နောက်ထောင့်မှာ ညာဘက်ကို လှည့်ပါ။');
            }
            if(Language == 6){
                setKashi('পরবর্তী কোণে ঘুরুন।');
            }
        }
        if(counter >= 14.9){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 18){
            if(Language == 0){
                setKashi('「止まれ」はみぎ、ひだりをチェックしてからとおってください。');
            }
            if(Language == 1){
                setKashi('Please check to the right and left before passing.');
            }
            if(Language == 2){
                setKashi('當出現“停止”標誌時，請在通過前檢查左右。');
            }
            if(Language == 3){
                setKashi("''रोक्नुहोस्।'' कृपया पास गर्नु अघि दायाँ र बायाँ जाँच गर्नुहोस्।");
            }
            if(Language == 4){
                setKashi(`"නවත්වන්න.''"කරුණාකර පසුකර යාමට පෙර වම සහ දකුණ පරීක්ෂා කරන්න.`);
            }
            if(Language == 5){
                setKashi('"ရပ်ပါ" ဟုပြောသောအခါ မဖြတ်သန်းမီ ညာဘက်နှင့် ဘယ်ဘက်တို့ကို စစ်ဆေးပါ။');
            }
            if(Language == 6){
                setKashi("''স্টপ।'' পাস করার আগে মিগি এবং হিদারি চেক করুন।");
            }
        }
        if(counter >= 22.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 30.9){
            if(Language == 0){
                setKashi('ひだりにまがります。');
            }
            if(Language == 1){
                setKashi('Turn left.');
            }
            if(Language == 2){
                setKashi('左轉。');
            }
            if(Language == 3){
                setKashi('बायाँ घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('වමට හරවන්න.');
            }
            if(Language == 5){
                setKashi('လက်ဝဲဘက်ကွေ့ပါ။');
            }
            if(Language == 6){
                setKashi('আমি হ্যাং আউট করতে যাচ্ছি।');
            }
        }
        if(counter >= 35.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 41.7){
            if(Language == 0){
                setKashi('大きいみちに来たら、みぎにまがってください。');
            }
            if(Language == 1){
                setKashi('When you reach the large street, turn to the right.');
            }
            if(Language == 2){
                setKashi('當你來到一條大路時，向右轉。');
            }
            if(Language == 3){
                setKashi('जब तपाईं ठूलो सडकमा आउनुहुन्छ, कृपया दायाँ घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබ විශාල මාර්ගයට පැමිණෙන විට කරුණාකර දකුණටහැරෙන්න.');
            }
            if(Language == 5){
                setKashi('လမ်းမကြီးကိုရောက်ရင် လှည့်ကြည့်လိုက်ပါ။');
            }
            if(Language == 6){
                setKashi('আপনি যখন একটি বড় রাস্তায় আসেন, অনুগ্রহ করে ঘুরে আসুন।');
            }
        }
        if(counter >= 46.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 54.4){
            if(Language == 0){
                setKashi('しんごうをわたったら、ひだりにまがります。');
            }
            if(Language == 1){
                setKashi('After you cross the traffic light, turn left.');
            }
            if(Language == 2){
                setKashi('穿過紅綠燈後，左轉。');
            }
            if(Language == 3){
                setKashi('ट्राफिक लाइट पार गरेपछि, बायाँ घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබ රථවාහන ආලෝකය තරණය කළ පසු, වමට හැරෙන්න.');
            }
            if(Language == 5){
                setKashi('မီးပွိုင့်ကိုဖြတ်ပြီးရင် ဘယ်ဘက်ကွေ့ပါ။');
            }
            if(Language == 6){
                setKashi('শিঙ্গো পার হওয়ার পর, রিজের নিচে যান।');
            }
        }
        if(counter >= 58.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 60){
            if(Language == 0){
                setKashi('しんごうがあおになったら、みちをわたってください。');
            }
            if(Language == 1){
                setKashi('When the traffic light turns green, cross the street.');
            }
            if(Language == 2){
                setKashi('當燈變綠時，過馬路。');
            }
            if(Language == 3){
                setKashi('जब बत्ती हरियो हुन्छ, सडक पार गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ආලෝකය කොළ පැහැයට හැරෙන විට, පාර මාරැ වෙන්න.');
            }
            if(Language == 5){
                setKashi('မီးစိမ်းသွားလျှင် လမ်းဖြတ်ကူးပါ။');
            }
            if(Language == 6){
                setKashi('ট্রাফিক লাইট সবুজ হয়ে গেলে রাস্তা পার হন।');
            }
        }
        if(counter >= 64){
            if(Language == 0){
                setKashi('ここに、自転車屋「JOY」があります。');
            }
            if(Language == 1){
                setKashi('There is a bicycle shop called "JOY" here.');
            }
            if(Language == 2){
                setKashi('這裡有一家名為「JOY」的自行車店。');
            }
            if(Language == 3){
                setKashi("यहाँ ``JOY'' नामक साइकल पसल छ।");
            }
            if(Language == 4){
                setKashi('මෙතන තියෙනවා "JOY" කියලා බයිසිකල් කඩයක්.');
            }
            if(Language == 5){
                setKashi('ဒီမှာ ``JOY´ လို့ ခေါ်တဲ့ စက်ဘီးဆိုင်ရှိတယ်။');
            }
            if(Language == 6){
                setKashi('এখানে "JOY" নামে একটি সাইকেলের দোকান আছে।');
            }
        }
        if(counter >= 66.5){
            if(Language == 0){
                setKashi('自転車屋「JOY」のまえを、みぎにまがってください。');
            }
            if(Language == 1){
                setKashi('Please turn right in front of the bicycle shop "JOY".');
            }
            if(Language == 2){
                setKashi('在自行車店“JOY”前右轉。');
            }
            if(Language == 3){
                setKashi('कृपया साइकल पसल "JOY" को अगाडिबाट दायाँ घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර "JOY" බයිසිකල් සාප්පුව දකුණට හැරෙන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးပြုပြီး "JOY" စက်ဘီးဆိုင်ရဲ့ညာဘက်ကိုကွေ့ပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে সাইকেলের দোকান "JOY" এর সামনে ঘুরে আসুন।');
            }
        }
        if(counter >= 70.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 78.3){
            if(Language == 0){
                setKashi('しばらくまっすぐすすんでください。');
            }
            if(Language == 1){
                setKashi('Please go straight for a bit.');
            }
            if(Language == 2){
                setKashi('請繼續向前行駛片刻。');
            }
            if(Language == 3){
                setKashi('केही समय सिधा अगाडि बढि रहनुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර  කෙළින්ම යන්න.');
            }
            if(Language == 5){
                setKashi('ခဏလောက် ရှေ့တည့်တည့်ကို ဆက်သွားပါ။');
            }
            if(Language == 6){
                setKashi('দয়া করে এক মুহুর্তের জন্য সোজা যান।');
            }
        }
        if(counter >= 82.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 87.7){
            if(Language == 0){
                setKashi('あるいている人に気をつけてください。');
            }
            if(Language == 1){
                setKashi('Be careful of people who are out there.');
            }
            if(Language == 2){
                setKashi('請注意行人。');
            }
            if(Language == 3){
                setKashi('हिँड्ने मान्छे भएको हुनाले ख्याल गरेर चलाउनुहोस्।');
            }
            if(Language == 4){
                setKashi('ඇවිදන් යන අය සිටින බැහින්  පරිස්සම් යන්න.');
            }
            if(Language == 5){
                setKashi('လမ်းလျှောက်နေသောသူများရှိတဲ့အတွက် ဂရုစိုက်ပါ။');
            }
            if(Language == 6){
                setKashi('দয়া করে পথচারীদের জন্য সতর্ক থাকুন।');
            }
        }
        if(counter >= 92){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 106.5){
            if(Language == 0){
                setKashi('ひだりにまがってください。');
            }
            if(Language == 1){
                setKashi('Please turn to the left.');
            }
            if(Language == 2){
                setKashi('請左轉。');
            }
            if(Language == 3){
                setKashi('बायाँ घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර වමට හැරෙන්න.');
            }
            if(Language == 5){
                setKashi('ဘယ်ဘက်ကိုကွေ့ပါ။');
            }
            if(Language == 6){
                setKashi('দয়া করে বাম দিকে ঘুরুন।');
            }
        }
        if(counter >= 110.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 120.9){
            if(Language == 0){
                setKashi('まっすぐすすんでください。');
            }
            if(Language == 1){
                setKashi('Please go straight ahead.');
            }
            if(Language == 2){
                setKashi('請一直往前走。');
            }
            if(Language == 3){
                setKashi('केही समय सिधा अगाडि बढि रहनुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර කෙළින්ම ඉදිරියට යන්න.');
            }
            if(Language == 5){
                setKashi('ရှေ့တည့်တည့်ကို သွားပါ။');
            }
            if(Language == 6){
                setKashi('দয়া করে সরাসরি এগিয়ে যান।');
            }
        }
        if(counter >= 125.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 144.2){
            if(Language == 0){
                setKashi('おおぞね駅のこうさてんで、みぎにまがります。');
            }
            if(Language == 1){
                setKashi('At the intersection of Ozone Station, turn right.');
            }
            if(Language == 2){
                setKashi('在大曾根站交叉路口右轉。');
            }
            if(Language == 3){
                setKashi('ओजोन स्टेशन चोक (intersection)बाट  दाँया घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඕසෝන් දුම්රිය ස්ථානයේ   ඉදිරියෙන් දකුණට හැරෙන්න.');
            }
            if(Language == 5){
                setKashi('အိုဇုန်းဘူတာရုံလမ်းဆုံတွင် ညာဘက်သို့ကွေ့ပါ။');
            }
            if(Language == 6){
                setKashi('ওজোন স্টেশনের কোসেটেনে, মিগিতে পরিণত করুন।');
            }
        }
        if(counter >= 148.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 149.5){
            if(Language == 0){
                setKashi('まえに、BMWの店があります。');
            }
            if(Language == 1){
                setKashi('There is a BMW store in front of you.');
            }
            if(Language == 2){
                setKashi('前面有一家BMW專賣店。');
            }
            if(Language == 3){
                setKashi('अगाडि एउटा BMW कार स्टोर छ।');
            }
            if(Language == 4){
                setKashi('ඉදිරිපස BMW වෙළඳසැලක් ඇත.');
            }
            if(Language == 5){
                setKashi('ရှေ့တွင် BMW စတိုးတစ်ခုရှိသည်။');
            }
            if(Language == 6){
                setKashi('আপনার সামনে একটি BMW স্টোর আছে।');
            }
        }
        if(counter >= 154){
            if(Language == 0){
                setKashi('BMWの店を、ひだりにまがります。');
            }
            if(Language == 1){
                setKashi('Turn left at the BMW store.');
            }
            if(Language == 2){
                setKashi('在BMW專賣店左轉。');
            }
            if(Language == 3){
                setKashi('BMW स्टोरमा, बाँया घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('BMW වෙළඳසැලේදී, වමට හැරෙන්න.');
            }
            if(Language == 5){
                setKashi('BMW စတိုးတွင် ဘယ်ဘက်သို့ လှည့်ပါ။');
            }
            if(Language == 6){
                setKashi('BMW স্টোরের কোণে বাম দিকে ঘুরুন।');
            }
        }
        if(counter >= 158.1){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 162.6){
            if(Language == 0){
                setKashi('ひだりのしんごうをわたってください。まえに「mini mini」があります。');
            }
            if(Language == 1){
                setKashi('Please cross the traffic light on the left. There is a "mini mini" in front.');
            }
            if(Language == 2){
                setKashi('穿過左側的紅綠燈。  面前有一個「迷你迷你」。');
            }
            if(Language == 3){
                setKashi('कृपया बायाँ तर्फबाट हुँदै सडक पार गर्नुहोस्। तपाईको अगाडि एउटा "मिनी मिनी" छ।');
            }
            if(Language == 4){
                setKashi('වම් පසින් පාර  මාර්ග සාංඥාව මාරැ වෙන්න ඔබ ඉදිරියෙහි " mini mini" ඇත.');
            }
            if(Language == 5){
                setKashi('ဘယ်ဘက်မီးပွိုင့်ကိုဖြတ်ပါ။ သင့်ရှေ့မှာ "မီနီမီနီ" ရှိတယ်။');
            }
            if(Language == 6){
                setKashi('ট্রাফিক লাইট ক্রস করুন. আপনার সামনে একটি "minimini"আছে।');
            }
        }
        if(counter >= 167){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 174.8){
            if(Language == 0){
                setKashi('まっすぐすすみます。');
            }
            if(Language == 1){
                setKashi('Go straight ahead.');
            }
            if(Language == 2){
                setKashi('直行。');
            }
            if(Language == 3){
                setKashi('सिधा अगाडि बढि रहनुहोस्।');
            }
            if(Language == 4){
                setKashi('කෙලින්ම යන්න.');
            }
            if(Language == 5){
                setKashi('တည့်တည့်သွားပါ။');
            }
            if(Language == 6){
                setKashi('সোজা এগিয়ে যান।');
            }
        }
        if(counter >= 179){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 207.7){
            if(Language == 0){
                setKashi('へいあんどおり駅');
            }
            if(Language == 1){
                setKashi('Heian Dori Station');
            }
            if(Language == 2){
                setKashi('平安通站');
            }
            if(Language == 3){
                setKashi('हे एन दोओरी(Heiandori) स्टेशन');
            }
            if(Language == 4){
                setKashi('EIANDORI දුම්රිය  ස්ථානය');
            }
            if(Language == 5){
                setKashi('Heiandori ဘူတာရုံ');
            }
            if(Language == 6){
                setKashi('হেইয়ান ডোরি স্টেশন');
            }
        }
        if(counter >= 210){
            if(Language == 0){
                setKashi('へいあんどおり駅のしんごうをわたって、みぎにまがります。');
            }
            if(Language == 1){
                setKashi('Cross the signal at Heiandori Station and turn right.');
            }
            if(Language == 2){
                setKashi('在平通站穿過信號燈並右轉。');
            }
            if(Language == 3){
                setKashi('हे एन दोओरी(Heiandori) स्टेशनमा सिग्नल पार गर्नुहोस् र दायाँ घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('HEIANDORI ස්ථානයේ ඇති මාර්ග සංඥාව හරස් කර දකුණට හැරෙන්න.');
            }
            if(Language == 5){
                setKashi('Heiandori ဘူတာရှိ မီးပွိုင့်ကို ဖြတ်ပြီး ညာဘက်သို့ ကွေ့ပါ။ ');
            }
            if(Language == 6){
                setKashi('Heiandori স্টেশনের signal পেরিয়ে মিগিতে পরিণত করুন।');
            }
        }
        if(counter >= 215.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 242.5){
            if(Language == 0){
                setKashi('ひだりに、スーパー「AEON そよら」があります。');
            }
            if(Language == 1){
                setKashi('There is a supermarket "AEON Soyora" on the left.');
            }
            if(Language == 2){
                setKashi('左邊有一家超市「AEON Soyora」。');
            }
            if(Language == 3){
                setKashi('त्यहाँ बायाँ मा एक सुपरमार्केट "AEON Soyora" छ।');
            }
            if(Language == 4){
                setKashi('වම් පසින් "AEON Soyora" සුපිරි වෙළඳසැලක් ඇත.');
            }
            if(Language == 5){
                setKashi('ဘယ်ဘက်တွင် "AEON Soyora" စူပါမားကတ်တစ်ခုရှိသည်။');
            }
            if(Language == 6){
                setKashi("হিদারিতে ``AEON Soyora'' নামে একটি সুপার মার্কেট আছে।");
            }
        }
        if(counter >= 245){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 252.7){
            if(Language == 0){
                setKashi('交番をひだりにまがります。');
            }
            if(Language == 1){
                setKashi('Turn left at the police box.');
            }
            if(Language == 2){
                setKashi('在警察崗亭左轉。');
            }
            if(Language == 3){
                setKashi('पुलिस स्टेशनबाट बाँया घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('පොලිස්  මුරපොලෙන් වමට හැරෙන්න.');
            }
            if(Language == 5){
                setKashi('ရဲဘောက်စ်မှာ ဘယ်ဘက်ကွေ့။');
            }
            if(Language == 6){
                setKashi('পুলিশ বক্সের কোণে বাম দিকে ঘুরুন।');
            }
        }
        if(counter >= 257){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 263.1){
            if(Language == 0){
                setKashi('ここから「AEON そよら」に入ることができます。');
            }
            if(Language == 1){
                setKashi('You can enter "AEON Soyora" from here.');
            }
            if(Language == 2){
                setKashi('從這裡輸入“AEON Soyora”。');
            }
            if(Language == 3){
                setKashi('यहाँबाट "AEON Soyora" प्रवेश गर्न मिल्छ।');
            }
            if(Language == 4){
                setKashi('මෙතනින් "AEON Soyora" ඇතුලත් කරන්න පුළුවන්');
            }
            if(Language == 5){
                setKashi('ဤနေရာမှ "AEON Soyora" သို့ဝင်နိုင်ပါတယ်။');
            }
            if(Language == 6){
                setKashi('আপনি এখান থেকে "AEON SOYORA" যেতে পারেন।');
            }
        }
        if(counter >= 266.3){
            if(Language == 0){
                setKashi('寮は、ここです。');
            }
            if(Language == 1){
                setKashi('The dormitory is here.');
            }
            if(Language == 2){
                setKashi('宿舍就在這裡。');
            }
            if(Language == 3){
                setKashi('छात्रावास यहाँ छ।');
            }
            if(Language == 4){
                setKashi('නේවාසිකාගාරය මෙහි ඇත.');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင်က ဒီမှာပါ။');
            }
            if(Language == 6){
                setKashi('ডরমিটরি এখানে।');
            }
        }
        if(counter >= 270){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 275.6){
            if(Language == 0){
                setKashi('ひだりにまがります。');
            }
            if(Language == 1){
                setKashi('Turn left.');
            }
            if(Language == 2){
                setKashi('左轉。');
            }
            if(Language == 3){
                setKashi('बायाँ घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('වමට හරවන්න.');
            }
            if(Language == 5){
                setKashi('ဘယ်ဘက်ကွေ့ပါ။');
            }
            if(Language == 6){
                setKashi('বাম দিকে ঘুরুন।');
            }
        }
        if(counter >= 280){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 285){
            if(Language == 0){
                setKashi('みぎにまがります。');
            }
            if(Language == 1){
                setKashi('Turn right.');
            }
            if(Language == 2){
                setKashi('右轉。');
            }
            if(Language == 3){
                setKashi('दाँया मोड।');
            }
            if(Language == 4){
                setKashi('දකුණට හරවන්න.');
            }
            if(Language == 5){
                setKashi('ညာဘက်ကွေ့ပါ။');
            }
            if(Language == 6){
                setKashi('আমি মিগিতে যাচ্ছি।');
            }
        }
        if(counter >= 289.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 297.5){
            if(Language == 0){
                setKashi('寮(尾上団地)ゴール！');
            }
            if(Language == 1){
                setKashi('The road to the dormitory (Onoe Danchi) goal!');
            }
            if(Language == 2){
                setKashi('宿舍（尾上團地）到達！');
            }
            if(Language == 3){
                setKashi('छात्रावास (Onoe Danchi) लक्ष्य!');
            }
            if(Language == 4){
                setKashi('නේවාසිකාගාරය (Onoe Danchi) ඉලක්කය!');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင် (Onoe Danchi) ပန်းတိုင်။');
            }
            if(Language == 6){
                setKashi('ডরমিটরি (Onoe Danchi) গোল!');
            }
        }
        if(counter >= 300.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
    }

    const timeSetMsg1 = () => {
        document.getElementById('video').currentTime = 0;
    }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            setLogInNo(LogInNo);
            navigate('/');
        }
    })

    const HomeReset = () => {
        setLogInNo(LogInNo);
        navigate('/');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <img src={Titleimg} className='MenuLogo' onClick={() => {HomeReset()}}/>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className='MoveChapBtn MChBChange' onClick={() => {timeSetMsg1()}}>Chp1</div>
                    </div>
                    <video src={JourneyA} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className='MoveChapBtn MChBChange' onClick={() => {timeSetMsg1()}}>Chp1</div>
                </div>
            </div>
        </div>
    )
}