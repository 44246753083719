import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import Regulations from '../Images/Regulations.mp4';

export default function SchoolRuhr ({ setLanguage, Language, isAuth, setIsAuth, TimeVI, setTimeVI, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    // 再生チャプター変色
    const [ChapterColor1, setChapterColor1] = useState('MoveChapBtn');
    const [ChapterColor2, setChapterColor2] = useState('MoveChapBtn');
    const [ChapterColor3, setChapterColor3] = useState('MoveChapBtn');

    const [TimePlus, setTimePlus] = useState(0)
    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        if(TimePlus == 0){
            document.getElementById('video').currentTime = TimeVI;
            setTimePlus(1);
        }

        // 教科書をわすれたとき
        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }

            setChapterColor1('MoveChapBtn MChBChange');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
        }
        if(counter >= 1){
            if(Language == 0){
                setKashi('教科書をわすれたとき');
            }
            if(Language == 1){
                setKashi('When you forget your textbook');
            }
            if(Language == 2){
                setKashi('當你忘記帶課本時');
            }
            if(Language == 3){
                setKashi('जब तपाईं आफ्नो पाठ्यपुस्तक बिर्सनुहुन्छ');
            }
            if(Language == 4){
                setKashi('ඔබට ඔබේ පෙළපොත අමතක වූ විට');
            }
            if(Language == 5){
                setKashi('သင်ဖတ်စာအုပ်မေ့သွားသောအခါ');
            }
            if(Language == 6){
                setKashi('আপনি যখন আপনার পাঠ্যবই ভুলে যান');
            }
        }
        if(counter >= 3.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 6.2){
            if(Language == 0){
                setKashi('教科書をわすれないでください。');
            }
            if(Language == 1){
                setKashi("Don't forget your textbook.");
            }
            if(Language == 2){
                setKashi('別忘带您的課本。');
            }
            if(Language == 3){
                setKashi('आफ्नो पाठ्यपुस्तक नबिर्सनुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබේ පෙළපොත අමතක කරන්න එපා.');
            }
            if(Language == 5){
                setKashi('သင်ဖတ်စာအုပ်ကိုမမေ့ပါနှင့်။');
            }
            if(Language == 6){
                setKashi('আপনার পাঠ্যপুস্তক ভুলবেন না.');
            }
        }
        if(counter >= 11.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 12.7){
            if(Language == 0){
                setKashi('わすれたときは、1まい10円でコピーをします。');
            }
            if(Language == 1){
                setKashi('If you forget it, we will make a copy for 10 yen per piece.');
            }
            if(Language == 2){
                setKashi('如果您忘記了，我們將影印一份，每份 10 日圓。');
            }
            if(Language == 3){
                setKashi('यदि तपाइँ यसलाई बिर्सनुभयो भने, हामी प्रत्येक प्रतिलिपिको  10 येन  लिनेछौं।');
            }
            if(Language == 4){
                setKashi('ඔබට එය අමතක වුවහොත්, අපි *එක පිටුවකට යෙන් 10 බැගින් පිටපත් සාදන්නෙමු.');
            }
            if(Language == 5){
                setKashi('မေ့သွားရင်လျှင်စာရွက်၁ရွက်ကို ၁၀ ယန်းနဲ့ ကူးယူရမည်။');
            }
            if(Language == 6){
                setKashi('যদি আপনি এটি ভুলে যান, আমরা প্রতিদিন 10 ইয়েনের জন্য একটি অনুলিপি তৈরি করব।');
            }
        }
        if(counter >= 18.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 31.4){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn MChBChange');
            setChapterColor3('MoveChapBtn');
        }
        //遅刻（ちこく）
        if(counter >= 32){
            if(Language == 0){
                setKashi('遅刻（ちこく）');
            }
            if(Language == 1){
                setKashi('Being late');
            }
            if(Language == 2){
                setKashi('落後於時間');
            }
            if(Language == 3){
                setKashi('ढिलो उपस्थित');
            }
            if(Language == 4){
                setKashi('ප්‍රමාදවී පැමීණීම ');
            }
            if(Language == 5){
                setKashi('အချိန်နောက်ကျ');
            }
            if(Language == 6){
                setKashi('সময়ের পিছনে');
            }
        }
        if(counter >= 35.1){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 36.9){
            if(Language == 0){
                setKashi('１分でもちこくすると、');
            }
            if(Language == 1){
                setKashi('Even if you are late for 1 minute,');
            }
            if(Language == 2){
                setKashi('如果您忘遲到哪怕一分鐘');
            }
            if(Language == 3){
                setKashi('यदि तपाईं एक मिनेट ढिलो हुनुहुन्छ भने,');
            }
            if(Language == 4){
                setKashi('ඔබ විනාඩියක්වත් ප්‍රමාද නම්,');
            }
            if(Language == 5){
                setKashi('တစ်မိနစ်တောင်နောက်ကျရင်');
            }
            if(Language == 6){
                setKashi('আপনি যদি এটি 1 মিনিট ধরে রাখেন,');
            }
        }
        if(counter >= 40.9){
            if(Language == 0){
                setKashi('その授業はけっせきになります。');
            }
            if(Language == 1){
                setKashi('you will be considered as absent for that subject.');
            }
            if(Language == 2){
                setKashi('您將缺席那堂課。');
            }
            if(Language == 3){
                setKashi('तपाईं  त्यो विषयको कक्षामा अनुपस्थित हुनुहुनेछ।');
            }
            if(Language == 4){
                setKashi('ඔබ එම පන්තියට නොපැමිණෙනු ඇත.');
            }
            if(Language == 5){
                setKashi('အဲဒီအတန်းကနေ ပျက်ကွက်စာရင်းဖြစ်ပါမည်။');
            }
            if(Language == 6){
                setKashi('সেই ক্লাস কঠিন হবে।');
            }
        }
        if(counter >= 45.2){
            if(Language == 0){
                setKashi('ちこくした時、');
            }
            if(Language == 1){
                setKashi('When you are late,');
            }
            if(Language == 2){
                setKashi('當我遲到的時候，');
            }
            if(Language == 3){
                setKashi('जब म ढिलो भएँ,');
            }
            if(Language == 4){
                setKashi('මම පරක්කු වූ විට,');
            }
            if(Language == 5){
                setKashi('နောက်ကျတဲ့အခါ၊');
            }
            if(Language == 6){
                setKashi('যখন আমি বিরক্ত হয়ে যাই,');
            }
        }
        if(counter >= 47.8){
            if(Language == 0){
                setKashi('じむしょに行ってから教室に行かないと');
            }
            if(Language == 1){
                setKashi('you have to go to the office before going to the classroom');
            }
            if(Language == 2){
                setKashi('您必須去辦公室，然後去教室。');
            }
            if(Language == 3){
                setKashi('2Fअफिस जानु पर्छ र त्यसपछि कक्षा कोठामा जानु पर्छ।');
            }
            if(Language == 4){
                setKashi('ඔෆිස් එකට ගිහින් පන්ති කාමරයට යන්න ඕන.*නැතිනම්,');
            }
            if(Language == 5){
                setKashi('ရုံးခန်းသွားပြီးမှ စာသင်ခန်းကို သွားရမယ်။ ');
            }
            if(Language == 6){
                setKashi('আমাকে জিমে যেতে হবে এবং তারপর ক্লাসরুমে যেতে হবে।');
            }
        }
        if(counter >= 52){
            if(Language == 0){
                setKashi('しゅっせきにならないので気をつけてください。');
            }
            if(Language == 1){
                setKashi('so that you will not be considered as absent.');
            }
            if(Language == 2){
                setKashi('請注意，因為您將不算出席。');
            }
            if(Language == 3){
                setKashi('कृपया सावधान रहनुहोस् किनकि तपाई अफिस  जानु भएन भने उपस्थित भएनि हजिरी हुदैन ।');
            }
            if(Language == 4){
                setKashi('ඔබට පැමිණීමට ඉඩ නොලැබෙන බැවින් ප්‍රවේශම් වන්න.');
            }
            if(Language == 5){
                setKashi('တက်ရောက်ခွင့်မပြုတဲ့အတွက် သတိထားပါ။');
            }
            if(Language == 6){
                setKashi('রাগ না করার জন্য সতর্ক থাকুন।');
            }
        }
        if(counter >= 56.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 58.4){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn MChBChange');
        }
        //休むときの連絡
        if(counter >= 59){
            if(Language == 0){
                setKashi('休むときの連絡');
            }
            if(Language == 1){
                setKashi('Informing of absences ');
            }
            if(Language == 2){
                setKashi('休息時聯繫');
            }
            if(Language == 3){
                setKashi('बिदा लिँदा सम्पर्क गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('විවේකයක් ගන්නා විට  දැනුම් දීම');
            }
            if(Language == 5){
                setKashi('အနားယူသည့်အခါ ဆက်သွယ်ပါ။');
            }
            if(Language == 6){
                setKashi('বিরতি নেওয়ার সময় যোগাযোগ করুন');
            }
        }
        if(counter >= 61.9){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 62.5){
            if(Language == 0){
                setKashi('学校を休むときや、ちこくするときは、');
            }
            if(Language == 1){
                setKashi('When you are absent or being late');
            }
            if(Language == 2){
                setKashi('當你缺課或遲到時、當出現問題時');
            }
            if(Language == 3){
                setKashi('स्कुल बिदा लिँदा र ढिलो आउँदा，');
            }
            if(Language == 4){
                setKashi('ඔබ පාසලෙන් විවේකයක් ගන්නා විට හෝ පාසලට පැමීණිම ප්‍රමාද වන විට,');
            }
            if(Language == 5){
                setKashi('ကျောင်းနားချိန် ဒါမှမဟုတ် အိပ်ယာဝင်တဲ့အခါ၊');
            }
            if(Language == 6){
                setKashi('আপনি যখন স্কুলে অনুপস্থিত থাকেন বা ছুটি পান,');
            }
        }
        if(counter >= 66){
            if(Language == 0){
                setKashi('かならずじゅぎょうがはじまる前に学校にれんらくしてください。');
            }
            if(Language == 1){
                setKashi('Please be sure to contact the shcool before classes start.');
            }
            if(Language == 2){
                setKashi('請務必在開課前聯絡學校。');
            }
            if(Language == 3){
                setKashi('कृपया कक्षा सुरु हुनु अघि जसरी नि  स्कुलमा जानकारी गराउनुहोला।');
            }
            if(Language == 4){
                setKashi('පන්ති ආරම්භ කිරීමට පෙර කරුණාකර පාසල සම්බන්ධ කර ගැනීමට වග බලා ගන්න.');
            }
            if(Language == 5){
                setKashi('အတန်းမစခင် ကျောင်းကို သေချာဆက်သွယ်ပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে স্কুল দিন শুরু হওয়ার আগে স্কুলে পৌঁছাতে ভুলবেন না।');
            }
        }
        if(counter >= 71.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 75){
            if(Language == 0){
                setKashi('休むとき、ちこくするとき、なにかもんだいがあるときは、');
            }
            if(Language == 1){
                setKashi('If you are absent or will be late, or have any problems,');
            }
            if(Language == 2){
                setKashi('當您忘休息、放鬆或有事情要做時，');
            }
            if(Language == 3){
                setKashi('स्कुल बिदा लिँदा， ढिलो उपस्थिति भएको बेला र समस्या परेको बेला，');
            }
            if(Language == 4){
                setKashi('ඔබ විවේකයක් ගන්නා විට, විවේකීව සිටින විට හෝ ගැටලුවක් ඇති විට,');
            }
            if(Language == 5){
                setKashi('အနားယူတဲ့အခါ၊ အပန်းဖြေဖို့ ဒါမှမဟုတ် လုပ်စရာတစ်ခုခုရှိတဲ့အခါ၊');
            }
            if(Language == 6){
                setKashi('যখন আপনার বিশ্রাম, বিশ্রাম বা কিছু করার প্রয়োজন হয়,');
            }
        }
        if(counter >= 79.4){
            if(Language == 0){
                setKashi('でんわかLINEでれんらくしてください。');
            }
            if(Language == 1){
                setKashi('please contact the office by telephone number or LINE.');
            }
            if(Language == 2){
                setKashi('請透過電話或LINE與我們聯繫。');
            }
            if(Language == 3){
                setKashi('कृपया हामीलाई फोन वा लाइन मार्फत सम्पर्क गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර දුරකථනයෙන් හෝ LINE මගින් අප හා සම්බන්ධ වන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးပြု၍ ဖုန်း သို့မဟုတ် LINE ဖြင့် ဆက်သွယ်ပါ။');
            }
            if(Language == 6){
                setKashi('দয়া করে নির্দ্বিধায় Denwaka LINE ব্যবহার করুন।');
            }
        }
        if(counter >= 82.9){
            if(Language == 0){
                setKashi('クラスのLINEでいろいろなれんらくをしますから');
            }
            if(Language == 1){
                setKashi('We will communicate various things on class LINE.');
            }
            if(Language == 2){
                setKashi('學校將透過 LINE 向您傳達各種訊息。');
            }
            if(Language == 3){
                setKashi('कक्षाको लाइनमा  हामी धेरै कुराहरूको जानकारी आदानप्रदान गर्छौं।');
            }
            if(Language == 4){
                setKashi('LINE පන්තියේ අපි ගොඩක් විනෝද වෙනවා.');
            }
            if(Language == 5){
                setKashi('အတန်း LINE မှာ ပုံစံအမျိူးမျိူးဖြင့်ဆက်သွယ်သည့်အတွက်ကြောင့်');
            }
            if(Language == 6){
                setKashi('আমরা ক্লাসে লাইনে অনেক মজা করব।');
            }
        }
        if(counter >= 86.4){
            if(Language == 0){
                setKashi('SIMをかった人はLINEを入れてください。');
            }
            if(Language == 1){
                setKashi('Once you bought a SIM, please download LINE and add NSA account.');
            }
            if(Language == 2){
                setKashi('如果您購買了 SIM 卡，請立即下載 LINE。');
            }
            if(Language == 3){
                setKashi('यदि तपाईंले सिम कार्ड किन्नु भएको छ भने, कृपया LINE APPडाउनलोड गरेर चलाउनुहोला। ');
            }
            if(Language == 4){
                setKashi('ඔබ SIM එකක් මිල දී ගත්තේ නම්, කරුණාකර LINE ඇතුලත් කරන්න.');
            }
            if(Language == 5){
                setKashi('သင့်တွင် SIM ရှိလျှင် LINE ကိုdownload လုပ်ပြီးထည့်ထားရန်။');
            }
            if(Language == 6){
                setKashi('আপনার যদি একটি সিম থাকে, অনুগ্রহ করে লাইন যোগ করুন।');
            }
        }
        if(counter >= 89.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
    }

    const timeSetMsg1 = () => {
        document.getElementById('video').currentTime = 0;
    }
    const timeSetMsg2 = () => {
        document.getElementById('video').currentTime = 31.5;
    }
    const timeSetMsg3 = () => {
        document.getElementById('video').currentTime = 58.5;
    }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            setLogInNo(LogInNo);
            navigate('/');
        }
    })

    const HomeReset = () => {
        setTimeVI(0);
        setLogInNo(LogInNo);
        navigate('/');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <img src={Titleimg} className='MenuLogo' onClick={() => {HomeReset()}}/>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                        <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                        <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                    </div>
                    <video src={Regulations} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                    <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                    <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                </div>
            </div>
        </div>
    )
}