import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import Lesson from '../Images/Lesson.mp4';

export default function LessonMove ({ setLanguage, Language, isAuth, setIsAuth, TimeVI, setTimeVI, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    // 再生チャプター変色
    const [ChapterColor1, setChapterColor1] = useState('MoveChapBtn');
    const [ChapterColor2, setChapterColor2] = useState('MoveChapBtn');
    const [ChapterColor3, setChapterColor3] = useState('MoveChapBtn');
    const [ChapterColor4, setChapterColor4] = useState('MoveChapBtn');
    const [ChapterColor5, setChapterColor5] = useState('MoveChapBtn');
    const [ChapterColor6, setChapterColor6] = useState('MoveChapBtn');

    const [TimePlus, setTimePlus] = useState(0)
    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        if(TimePlus == 0){
            document.getElementById('video').currentTime = TimeVI;
            setTimePlus(1);
        }

        // 授業時間と受付
        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }

            setChapterColor1('MoveChapBtn MChBChange');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
        }
        if(counter >= 10){
            if(Language == 0){
                setKashi('授業時間と受付');
            }
            if(Language == 1){
                setKashi('attendance');
            }
            if(Language == 2){
                setKashi('上課時間及出席率');
            }
            if(Language == 3){
                setKashi('कक्षा सञ्चालन हुने समय र हाजिर गर्ने ठाउँ');
            }
            if(Language == 4){
                setKashi('පැමිණීම');
            }
            if(Language == 5){
                setKashi('တက်ရောက်ခြင်း။');
            }
            if(Language == 6){
                setKashi('উপস্থিতি');
            }
        }
        if(counter >= 13){
            if(Language == 0){
                setKashi('午前クラスの学生は、8:50までに教室に来てください。');
            }
            if(Language == 1){
                setKashi('For students in morning classes, please come to the classroom by 8:50.');
            }
            if(Language == 2){
                setKashi('上午班的學生應在8點50分前到達教室。');
            }
            if(Language == 3){
                setKashi('बिहानको कक्षामा विद्यार्थीहरू 8:50 सम्म कक्षाकोठामा आइपुग्नु पर्छ।');
            }
            if(Language == 4){
                setKashi('උදෑසන පන්තිවල සිසුන් 8:50 වන විට පන්ති කාමරයට පැමිණිය යුතුය.');
            }
            if(Language == 5){
                setKashi('မနက်ပိုင်း အတန်းတက်တဲ့ ကျောင်းသားတွေအနေနဲ့ 8:50 မှာ စာသင်ခန်းကို လာသင့်ပါတယ်။');
            }
            if(Language == 6){
                setKashi('সকালের ক্লাসে শিক্ষার্থীদের জন্য, অনুগ্রহ করে 8:50 এর মধ্যে শ্রেণীকক্ষে আসুন।');
            }
        }
        if(counter >= 19.5){
            if(Language == 0){
                setKashi('9:00から12:20まで勉強します。');
            }
            if(Language == 1){
                setKashi('You will study from 9:00 to 12:20.');
            }
            if(Language == 2){
                setKashi('上午班的學生會從9:00到12:20學習。');
            }
            if(Language == 3){
                setKashi('9:00 देखि 12:20 सम्म अध्ययन हुन्छ।');
            }
            if(Language == 4){
                setKashi('මම 9:00 සිට 12:20 දක්වා පාඩම් කරන්නෙමි.');
            }
            if(Language == 5){
                setKashi('9:00 မှ 12:20 ထိ စာသင်ပါမည်။');
            }
            if(Language == 6){
                setKashi('আমরা9:00 থেকে 12:20 পর্যন্ত পড়াশোনা করব।');
            }
        }
        if(counter >= 24.5){
            if(Language == 0){
                setKashi('午後クラスの学生は、13:20までに教室に来てください。');
            }
            if(Language == 1){
                setKashi('Students in afternoon classes, please come to the classroom by 1:20 p.m.');
            }
            if(Language == 2){
                setKashi('下午課程的學生應在下午 1 點 20 分之前到達教室。');
            }
            if(Language == 3){
                setKashi('दिउँसो कक्षाका विद्यार्थीहरू दिउँसो 13:20 बजेभित्र कक्षाकोठामा आइपुग्नु पर्नेछ।');
            }
            if(Language == 4){
                setKashi('දහවල් පන්තිවල සිසුන් දහවල් 1:20 වන විට පන්ති කාමරයට පැමිණිය යුතුය.');
            }
            if(Language == 5){
                setKashi('နေ့လယ်စာ သင်ကြားနေသော ကျောင်းသားများသည် နေ့လည် 1:20 တွင် စာသင်ခန်းသို့ လာရောက်သင့်ပါသည်။');
            }
            if(Language == 6){
                setKashi('বিকালের ক্লাসে শিক্ষার্থীরা, অনুগ্রহ করে 1:20 টার মধ্যে ক্লাসরুমে আসুন।');
            }
        }
        if(counter >= 30.2){
            if(Language == 0){
                setKashi('13:30から16:50まで勉強します。');
            }
            if(Language == 1){
                setKashi('You will study from 13:30 to 16:50.');
            }
            if(Language == 2){
                setKashi('下午課程的學生會從13:30到16:50學習。');
            }
            if(Language == 3){
                setKashi('13:30 देखि 16:50 सम्म अध्ययन हुन्छ।');
            }
            if(Language == 4){
                setKashi('මම 13:30 සිට 16:50 දක්වා පාඩම් කරන්නෙමි.');
            }
            if(Language == 5){
                setKashi('13:30 မှ 16:50 ထိ စာသင်ပါမည်။');
            }
            if(Language == 6){
                setKashi('আমরা 13:30 থেকে 16:50 পর্যন্ত পড়াশোনা করব।');
            }
        }
        if(counter >= 35){
            if(Language == 0){
                setKashi('受付であいさつをしてから入らないとけっせきになりますから気をつけてください。');
            }
            if(Language == 1){
                setKashi('Please be careful as you will be marked absent if you go to the classroom without greeting at the reception desk.');
            }
            if(Language == 2){
                setKashi('請注意，如果在接待處問候後不進入，您將缺席。');
            }
            if(Language == 3){
                setKashi('रिसेप्शन डेस्क（हाजिर गर्ने ठाउँ）मा　तपाईँ हाजिर गरेर जानुभएन भने तपाईंको हाजिर मानिने छैन सावधान रहनुहोस्।');
            }
            if(Language == 4){
                setKashi('පිළිගැනීමේ මේසයට ආචාර කිරීමට නොපැමිනේනම් ,පාසලට නොපැමිණිලෙස සටහන් වන  බැවින් ප්‍රවේශම් වන්න.');
            }
            if(Language == 5){
                setKashi('ဧည့်ခံကောင်တာတွင် နှုတ်ဆက်ပြီးနောက် မဝင်ပါက ပျက်ကွက်သွားမည်ကို သတိပြုပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে মনে রাখবেন যে আপনি যদি প্রবেশের আগে অভ্যর্থনাকারীকে অভিবাদন না করেন তবে আপনি ক্লাসে অনুপস্থিত থাকবেন।');
            }
        }
        if(counter >= 41){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 42){
            if(Language == 0){
                setKashi('ぼうし・イヤホン・サングラスをつけてあいさつをしてはいけません。');
            }
            if(Language == 1){
                setKashi('Do not do the greeting while wearing hats, earphones, or sunglasses.');
            }
            if(Language == 2){
                setKashi('請勿戴著帽子、耳機或墨鏡與他人打招呼。');
            }
            if(Language == 3){
                setKashi('टोपी, इयरफोन वा सनग्लास लगाएर  अभिवादन नगर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('තොප්පියක්, ඉයර්ෆෝන් හෝ අව් කණ්ණාඩි පැළඳ සිටින විට අන් අයට ආචාර නොකරන්න.');
            }
            if(Language == 5){
                setKashi('ဦးထုပ်၊ နားကြပ် သို့မဟုတ် နေကာမျက်မှန် တပ်ထားစဉ် အခြားသူများကို မနှုတ်ဆက်ပါနှင့်။');
            }
            if(Language == 6){
                setKashi('টুপি, ইয়ারফোন বা সানগ্লাস পরে লোকেদের শুভেচ্ছা জানাবেন না।');
            }
        }
        if(counter >= 53.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 57.2){
            if(Language == 0){
                setKashi('名前をはっきり言ってください。');
            }
            if(Language == 1){
                setKashi('Please say your name clearly.');
            }
            if(Language == 2){
                setKashi('請清楚說出你的名字。');
            }
            if(Language == 3){
                setKashi('कृपया आफ्नो नाम स्पष्ट रूपमा भन्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබේ නම පැහැදිලිව කියන්න.');
            }
            if(Language == 5){
                setKashi('မင်းနာမည်ကို ရှင်းရှင်းလင်းလင်းပြောပါ။');
            }
            if(Language == 6){
                setKashi('আপনার নাম স্পষ্টভাবে বলুন।');
            }
        }
        if(counter >= 65.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 67.8){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn MChBChange');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
        }
        // テスト
        if(counter >= 68.5){
            if(Language == 0){
                setKashi('テスト');
            }
            if(Language == 1){
                setKashi('test');
            }
            if(Language == 2){
                setKashi('測試');
            }
            if(Language == 3){
                setKashi('परीक्षा');
            }
            if(Language == 4){
                setKashi('පරීක්ෂණය');
            }
            if(Language == 5){
                setKashi('စမ်းသပ်');
            }
            if(Language == 6){
                setKashi('পরীক্ষা');
            }
        }
        if(counter >= 72.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 101.8){
            if(Language == 0){
                setKashi('半年に2回大きいテストがあります。');
            }
            if(Language == 1){
                setKashi('There are two big tests every six months.');
            }
            if(Language == 2){
                setKashi('六個月內有兩次測試。');
            }
            if(Language == 3){
                setKashi('छ महिनामा दुईवटा　ठूला परीक्षा हुन्छन् ।');
            }
            if(Language == 4){
                setKashi('මාස හයකට පරීක්ෂණ දෙකක් තියෙනවා.');
            }
            if(Language == 5){
                setKashi('ခြောက်လအတွင်း စမ်းသပ်မှု နှစ်ခုရှိသည်။');
            }
            if(Language == 6){
                setKashi('প্রতি ছয় মাসে দুটি বড় পরীক্ষা আছে।');
            }
        }
        if(counter >= 106.5){
            if(Language == 0){
                setKashi('半年に1回クラスが変わります。');
            }
            if(Language == 1){
                setKashi('Classes change once every six months.');
            }
            if(Language == 2){
                setKashi('課程每六個月更換一次。');
            }
            if(Language == 3){
                setKashi('कक्षाहरू प्रत्येक छ महिनामा एक पटक परिवर्तन हुन्छ।');
            }
            if(Language == 4){
                setKashi('සෑම මාස හයකට වරක් පන්ති වෙනස් වේ.');
            }
            if(Language == 5){
                setKashi('ခြောက်လတစ်ကြိမ် အတန်းပြောင်းသည်။');
            }
            if(Language == 6){
                setKashi('প্রতি ছয় মাসে একবার ক্লাস পরিবর্তন হয়।');
            }
        }
        if(counter >= 111.6){
            if(Language == 0){
                setKashi('せいせきが悪い学生は、らくだいします。');
            }
            if(Language == 1){
                setKashi('Students with poor grades will be failed.');
            }
            if(Language == 2){
                setKashi('成績不好的學生會失敗。');
            }
            if(Language == 3){
                setKashi('कमजोर ग्रेड भएका विद्यार्थीहरू　तल्लो कक्षामा झर्नेछन्।');
            }
            if(Language == 4){
                setKashi('දුර්වල ලකුණු ඇති සිසුන් අසමත් වනු ඇත.');
            }
            if(Language == 5){
                setKashi('အမှတ်မကောင်းတဲ့ ကျောင်းသားတွေ ကျရှုံးမယ်။');
            }
            if(Language == 6){
                setKashi('খারাপ গ্রেডের ছাত্ররা ফেল করবে।');
            }
        }
        if(counter >= 117.3){
            if(Language == 0){
                setKashi('テストをうけない学生は、そつぎょうできません。');
            }
            if(Language == 1){
                setKashi('Students who do not take the test cannot graduate.');
            }
            if(Language == 2){
                setKashi('不參加考試的學生將無法畢業。');
            }
            if(Language == 3){
                setKashi('परीक्षा नदिने विद्यार्थीले स्नातक गर्न पाउने छैनन् ।');
            }
            if(Language == 4){
                setKashi('විභාගයට පෙනී නොසිටින සිසුන්ට උපාධිය ලබා ගැනීමට නොහැකි වනු ඇත.');
            }
            if(Language == 5){
                setKashi('စာမေးပွဲမဖြေသော ကျောင်းသားများသည် ဘွဲ့ရရှိမည်မဟုတ်ပါ။');
            }
            if(Language == 6){
                setKashi('পরীক্ষা না দিয়ে আপনি স্নাতক হতে পারবেন না।');
            }
        }
        if(counter >= 127){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 129.8){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn MChBChange');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
        }
        // 教科書
        if(counter >= 130.5){
            if(Language == 0){
                setKashi('教科書');
            }
            if(Language == 1){
                setKashi('textbook');
            }
            if(Language == 2){
                setKashi('教科書');
            }
            if(Language == 3){
                setKashi('पाठ्यपुस्तक');
            }
            if(Language == 4){
                setKashi('පෙළ පොත');
            }
            if(Language == 5){
                setKashi('ကျောင်းစာအုပ်');
            }
            if(Language == 6){
                setKashi('পাঠ্যপুস্তক');
            }
        }
        if(counter >= 134){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 143.3){
            if(Language == 0){
                setKashi('教科書です。');
            }
            if(Language == 1){
                setKashi('There are Textbook.');
            }
            if(Language == 2){
                setKashi('這是一本教科書。');
            }
            if(Language == 3){
                setKashi('यो पाठ्यपुस्तक हो।');
            }
            if(Language == 4){
                setKashi('ඒක පාඩම් පොතක්.');
            }
            if(Language == 5){
                setKashi('အဲဒါက ကျောင်းသုံးစာအုပ်ပါ။');
            }
            if(Language == 6){
                setKashi('পাঠ্যপুস্তক।');
            }
        }
        if(counter >= 147){
            if(Language == 0){
                setKashi('文法の本');
            }
            if(Language == 1){
                setKashi('Grammar book');
            }
            if(Language == 2){
                setKashi('文法書');
            }
            if(Language == 3){
                setKashi('व्याकरण पुस्तक');
            }
            if(Language == 4){
                setKashi('ව්යාකරණ පොත');
            }
            if(Language == 5){
                setKashi('သဒ္ဒါစာအုပ်');
            }
            if(Language == 6){
                setKashi('ব্যাকরণ বই');
            }
        }
        if(counter >= 149){
            if(Language == 0){
                setKashi('文法ノート');
            }
            if(Language == 1){
                setKashi('Grammar Notes');
            }
            if(Language == 2){
                setKashi('文法筆記');
            }
            if(Language == 3){
                setKashi('व्याकरण कपी');
            }
            if(Language == 4){
                setKashi('ව්යාකරණ සටහන්');
            }
            if(Language == 5){
                setKashi('သဒ္ဒါမှတ်စုများ');
            }
            if(Language == 6){
                setKashi('ব্যাকরণ নোট');
            }
        }
        if(counter >= 151){
            if(Language == 0){
                setKashi('漢字の本');
            }
            if(Language == 1){
                setKashi('Kanji book');
            }
            if(Language == 2){
                setKashi('漢字書');
            }
            if(Language == 3){
                setKashi('खान्जी किताब');
            }
            if(Language == 4){
                setKashi('කන්ජි පොත');
            }
            if(Language == 5){
                setKashi('ကန်ဂျီစာအုပ်');
            }
            if(Language == 6){
                setKashi('কাঞ্জি বই');
            }
        }
        if(counter >= 152.5){
            if(Language == 0){
                setKashi('漢字ノート');
            }
            if(Language == 1){
                setKashi('Kanji notes');
            }
            if(Language == 2){
                setKashi('漢字筆記');
            }
            if(Language == 3){
                setKashi('खान्जी नोटहरू');
            }
            if(Language == 4){
                setKashi('කන්ජි සටහන්');
            }
            if(Language == 5){
                setKashi('ကန်ဂျီမှတ်စုများ');
            }
            if(Language == 6){
                setKashi('কাঞ্জি নোট');
            }
        }
        if(counter >= 154){
            if(Language == 0){
                setKashi('作文ファイルがあります。');
            }
            if(Language == 1){
                setKashi('and an essay file.');
            }
            if(Language == 2){
                setKashi('論文文件。');
            }
            if(Language == 3){
                setKashi('यहाँ निबन्ध लेख्ने फाइल छ।');
            }
            if(Language == 4){
                setKashi('මා සතුව රචනා ගොනුවක් තිබේ.');
            }
            if(Language == 5){
                setKashi('ငါ့မှာ စာစီစာကုံးဖိုင်ရှိတယ်။');
            }
            if(Language == 6){
                setKashi('একটি রচনা ফাইল আছে।');
            }
        }
        if(counter >= 157){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 158.5){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn MChBChange');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
        }
        // なふだ
        if(counter >= 159.5){
            if(Language == 0){
                setKashi('なふだ');
            }
            if(Language == 1){
                setKashi('name tag');
            }
            if(Language == 2){
                setKashi('名稱標籤');
            }
            if(Language == 3){
                setKashi('नाम लेखिएको कार्ड');
            }
            if(Language == 4){
                setKashi('Name Tag');
            }
            if(Language == 5){
                setKashi('အမည်တံဆိပ်');
            }
            if(Language == 6){
                setKashi('নাম নির্দেশক');
            }
        }
        if(counter >= 162.5){
            if(Language == 0){
                setKashi('受付であいさつをしてから教室に入ってください。');
            }
            if(Language == 1){
                setKashi('Please greet at the reception and then enter the classroom.');
            }
            if(Language == 2){
                setKashi('進入教室前請先在接待處打招呼。');
            }
            if(Language == 3){
                setKashi('कृपया कक्षाकोठामा प्रवेश गर्नु अघि रिसेप्शन डेस्क（हाजिर गर्ने ठाउँ）मा अभिवादन गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර පන්ති කාමරයට ඇතුළු වීමට පෙර පිළිගැනීමේ මේසයට ආචාර කරන්න.');
            }
            if(Language == 5){
                setKashi('စာသင်ခန်းထဲမ၀င်ခင် ဧည့်ခံကောင်တာမှာ နှုတ်ဆက်ပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে অভ্যর্থনায় অভ্যর্থনা জানান এবং তারপর শ্রেণীকক্ষে প্রবেশ করুন।');
            }
        }
        if(counter >= 168){
            if(Language == 0){
                setKashi('教室に入るとき、あいさつをしてください。');
            }
            if(Language == 1){
                setKashi('Please greet when you enter the classroom.');
            }
            if(Language == 2){
                setKashi('進入教室時請打招呼。');
            }
            if(Language == 3){
                setKashi('कृपया कक्षाकोठामा प्रवेश गर्दा　पनि सबैलाई अभिवादन गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබ පන්ති කාමරයට ඇතුළු වන විට කරුණාකර ආයුබෝවන් කියන්න.');
            }
            if(Language == 5){
                setKashi('စာသင်ခန်းထဲဝင်တဲ့အခါ နှုတ်ဆက်ပါ။');
            }
            if(Language == 6){
                setKashi('আপনি ক্লাসরুমে প্রবেশ করার সময় অনুগ্রহ করে হ্যালো বলুন।');
            }
        }
        if(counter >= 174.3){
            if(Language == 0){
                setKashi('教室に入ったら、なふだをとってすわってください。');
            }
            if(Language == 1){
                setKashi('When you enter the classroom, please take your name tag and sit down.');
            }
            if(Language == 2){
                setKashi('進入教室後，請攜帶姓名牌入座。');
            }
            if(Language == 3){
                setKashi('कक्षामा प्रवेश गर्दा, कृपया आफ्नो नाम लेखिएको कार्ड लिनुहोस् र सिटमा बस्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබ පන්ති කාමරයට ඇතුළු වූ විට, කරුණාකර ඔබේ Name tag එක රැගෙන අසුන් ගන්න.');
            }
            if(Language == 5){
                setKashi('စာသင်ခန်းထဲသို့ဝင်သောအခါ ကျေးဇူးပြု၍ သင်၏အမည်ကဒ်ပြားကိုယူ၍ ထိုင်ပါ။');
            }
            if(Language == 6){
                setKashi('আপনি যখন শ্রেণীকক্ষে প্রবেশ করবেন, অনুগ্রহ করে আপনার নাম ট্যাগ নিন এবং একটি আসনে বসুন');
            }
        }
        if(counter >= 184.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 188){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn MChBChange');
            setChapterColor6('MoveChapBtn');
        }
        // 授業のあいさつ
        if(counter >= 188.7){
            if(Language == 0){
                setKashi('授業のあいさつ');
            }
            if(Language == 1){
                setKashi('Greetings at the start and end of class');
            }
            if(Language == 2){
                setKashi('上課開始和結束時的問候');
            }
            if(Language == 3){
                setKashi('कक्षाको सुरु र अन्त्यमा अभिवादन');
            }
            if(Language == 4){
                setKashi('පන්තියේ ආරම්භයේ සහ අවසානයේ අචාර කිරීම්');
            }
            if(Language == 5){
                setKashi('အတန်းအစနဲ့ အဆုံးမှာ နှုတ်ဆက်ပါတယ်။');
            }
            if(Language == 6){
                setKashi('ক্লাসের শুরুতে এবং শেষে শুভেচ্ছা');
            }
        }
        if(counter >= 191.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 192.5){
            if(Language == 0){
                setKashi('授業が始まるときと終わるとき、あいさつをします。');
            }
            if(Language == 1){
                setKashi('We greet each other at the beginning and end of class.');
            }
            if(Language == 2){
                setKashi('上課開始和結束時的問候。');
            }
            if(Language == 3){
                setKashi('कक्षा सञ्चालन हुनुअघि र कक्षा सञ्चालन सकिएपछि अभिवादन गरिनेछ।');
            }
            if(Language == 4){
                setKashi('පන්තියේ ආරම්භයේ සහ අවසානයේ ආචාර කිරීම.');
            }
            if(Language == 5){
                setKashi('အတန်းအစနဲ့ အဆုံး နှုတ်ဆက်ပါတယ်။');
            }
            if(Language == 6){
                setKashi('আমরা ক্লাসের শুরুতে এবং শেষে একে অপরকে অভিবাদন জানাই।');
            }
        }
        if(counter >= 198){
            if(Language == 0){
                setKashi('みなさん、始めましょう。');
            }
            if(Language == 1){
                setKashi("Everyone, let's get started.");
            }
            if(Language == 2){
                setKashi('大家，讓我們開始吧。');
            }
            if(Language == 3){
                setKashi('सबैजना, सुरु गरौं।');
            }
            if(Language == 4){
                setKashi('හැමෝම, අපි පටන් ගනිමු.');
            }
            if(Language == 5){
                setKashi('အားလုံးပဲ စလိုက်ကြရအောင်။');
            }
            if(Language == 6){
                setKashi('সবাই, শুরু করা যাক।');
            }
        }
        if(counter >= 199.7){
            if(Language == 0){
                setKashi('きりつ。');
            }
            if(Language == 1){
                setKashi('Stand up.');
            }
            if(Language == 2){
                setKashi('起來。');
            }
            if(Language == 3){
                setKashi('कृपया　खडा हुनुहोस्।');
            }
            if(Language == 4){
                setKashi('නැගිටින්න.');
            }
            if(Language == 5){
                setKashi('မတ်တတ်ထပါ။');
            }
            if(Language == 6){
                setKashi('কিরিটসু।');
            }
        }
        if(counter >= 203.3){
            if(Language == 0){
                setKashi('れい。');
            }
            if(Language == 1){
                setKashi('Attention!');
            }
            if(Language == 2){
                setKashi('謝謝。');
            }
            if(Language == 3){
                setKashi('धन्यवाद।');
            }
            if(Language == 4){
                setKashi('ලැස්ති වෙන්න.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးတင်ပါသည်။');
            }
            if(Language == 6){
                setKashi('রেই');
            }
        }
        if(counter >= 204.2){
            if(Language == 0){
                setKashi('おはようございます。');
            }
            if(Language == 1){
                setKashi('Good morning');
            }
            if(Language == 2){
                setKashi('早安.');
            }
            if(Language == 3){
                setKashi('शुभ - प्रभात।');
            }
            if(Language == 4){
                setKashi('සුභ උදෑසනක්.');
            }
            if(Language == 5){
                setKashi('မင်္ဂလာနံနက်ခင်းပါ');
            }
            if(Language == 6){
                setKashi('শুভ সকাল');
            }
        }
        if(counter >= 205.5){
            if(Language == 0){
                setKashi('よろしくお願いします。');
            }
            if(Language == 1){
                setKashi('Thank you.');
            }
            if(Language == 2){
                setKashi('謝謝。');
            }
            if(Language == 3){
                setKashi('धन्यवाद।');
            }
            if(Language == 4){
                setKashi('ඔයාට ස්තූතියි.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးတင်ပါသည်။');
            }
            if(Language == 6){
                setKashi('ধন্যবাদ।');
            }
        }
        if(counter >= 208.5){
            if(Language == 0){
                setKashi('ちゃくせき。');
            }
            if(Language == 1){
                setKashi('Seated');
            }
            if(Language == 2){
                setKashi('坐下。');
            }
            if(Language == 3){
                setKashi('कृपया　बस्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඉඳ ගන්න.');
            }
            if(Language == 5){
                setKashi('ထိုင်ပါ။');
            }
            if(Language == 6){
                setKashi('চাকুসেকি');
            }
        }
        if(counter >= 209.8){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 215.7){
            if(Language == 0){
                setKashi('おわりましょう。');
            }
            if(Language == 1){
                setKashi("Let's finish.");
            }
            if(Language == 2){
                setKashi('讓我們結束吧。');
            }
            if(Language == 3){
                setKashi('अन्त्य गरौं।');
            }
            if(Language == 4){
                setKashi('අපි අවසන් කරමු.');
            }
            if(Language == 5){
                setKashi('အဆုံးသတ်ကြပါစို့။');
            }
            if(Language == 6){
                setKashi('শেষ করা যাক।');
            }
        }
        if(counter >= 218){
            if(Language == 0){
                setKashi('きりつ。');
            }
            if(Language == 1){
                setKashi('Stand up.');
            }
            if(Language == 2){
                setKashi('起來。');
            }
            if(Language == 3){
                setKashi('कृपया　खडा हुनुहोस्। अभिवादन।');
            }
            if(Language == 4){
                setKashi('කෙළින් සිටින.');
            }
            if(Language == 5){
                setKashi('မတ်တတ်ထပါ။');
            }
            if(Language == 6){
                setKashi('কিরিটসু।');
            }
        }
        if(counter >= 220.5){
            if(Language == 0){
                setKashi('れい。');
            }
            if(Language == 1){
                setKashi('Thanks');
            }
            if(Language == 2){
                setKashi('謝謝。');
            }
            if(Language == 3){
                setKashi('धन्यवाद।');
            }
            if(Language == 4){
                setKashi('ඔයාට ස්තූතියි.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးတင်ပါသည်။');
            }
            if(Language == 6){
                setKashi('রেই');
            }
        }
        if(counter >= 221.5){
            if(Language == 0){
                setKashi('ありがとうございました。');
            }
            if(Language == 1){
                setKashi('Thank you very much');
            }
            if(Language == 2){
                setKashi('非常感謝。');
            }
            if(Language == 3){
                setKashi('मुरी मुरी धन्यवाद।');
            }
            if(Language == 4){
                setKashi('ඔයාට බොහෝම ස්තූතියි.');
            }
            if(Language == 5){
                setKashi('ကျေးဇူးအများကြီးတင်ပါတယ်။');
            }
            if(Language == 6){
                setKashi('আপনাকে অনেক ধন্যবাদ');
            }
        }
        if(counter >= 223.2){
            if(Language == 0){
                setKashi('さようなら。');
            }
            if(Language == 1){
                setKashi('Goodbye');
            }
            if(Language == 2){
                setKashi('再見。');
            }
            if(Language == 3){
                setKashi('अलविदा।');
            }
            if(Language == 4){
                setKashi('ආයුබෝවන්.');
            }
            if(Language == 5){
                setKashi('သွားတော့မယ်။');
            }
            if(Language == 6){
                setKashi('বিদায়');
            }
        }
        if(counter >= 226){
            if(Language == 0){
                setKashi('ちゃくせき。');
            }
            if(Language == 1){
                setKashi('Dismissed!');
            }
            if(Language == 2){
                setKashi('坐下。');
            }
            if(Language == 3){
                setKashi('कृपया　बस्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඉඳ ගන්න.');
            }
            if(Language == 5){
                setKashi('ထိုင်ပါ။');
            }
            if(Language == 6){
                setKashi('চাকুসেকি');
            }
        }
        if(counter >= 227.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }

        if(counter >= 232.7){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn MChBChange');
        }
        // ミニテスト
        if(counter >= 233.3){
            if(Language == 0){
                setKashi('ミニテスト');
            }
            if(Language == 1){
                setKashi('mini test');
            }
            if(Language == 2){
                setKashi('迷你測試');
            }
            if(Language == 3){
                setKashi('मिनी परीक्षण');
            }
            if(Language == 4){
                setKashi('කුඩා පරීක්ෂණය');
            }
            if(Language == 5){
                setKashi('အသေးစားစမ်းသပ်မှု');
            }
            if(Language == 6){
                setKashi('মিনি পরীক্ষা');
            }
        }
        if(counter >= 237){
            if(Language == 0){
                setKashi('毎日、授業のはじめにミニテストをします。');
            }
            if(Language == 1){
                setKashi('We will take a mini-test at the beginning of each class.');
            }
            if(Language == 2){
                setKashi('每節課開始時都會有一個小測驗。');
            }
            if(Language == 3){
                setKashi('प्रत्येक दिन　कक्षाको सुरुमा एउटा सानो-परीक्षा हुनेछ।');
            }
            if(Language == 4){
                setKashi('සෑම පන්තියකම ආරම්භයේදීම කුඩා පරීක්ෂණයක් ඇත.');
            }
            if(Language == 5){
                setKashi('အတန်းတစ်ခုစီ၏အစတွင် mini-test တစ်ခုရှိလိမ့်မည်။');
            }
            if(Language == 6){
                setKashi('আমরা প্রতিটি ক্লাসের শুরুতে একটি ছোট-টেস্ট নেব।');
            }
        }
        if(counter >= 244){
            if(Language == 0){
                setKashi('ことばと漢字のテストです。');
            }
            if(Language == 1){
                setKashi("It's Vocabulary and Kanji test.");
            }
            if(Language == 2){
                setKashi('這是單字和漢字的測驗。');
            }
            if(Language == 3){
                setKashi('यो शब्द र कांजी को परीक्षण हो।');
            }
            if(Language == 4){
                setKashi('මෙය වචන සහ කන්ජි පරීක්ෂණයකි.');
            }
            if(Language == 5){
                setKashi('ဒါက kotobaနဲ့ ခန်ဂျီ စမ်းသပ်မှုပါ။');
            }
            if(Language == 6){
                setKashi('এটি একটি শব্দভান্ডার এবং কাঞ্জি পরীক্ষা।');
            }
        }
        if(counter >= 249){
            if(Language == 0){
                setKashi('前の日に勉強したことば、漢字をしっかりふくしゅうしてください。');
            }
            if(Language == 1){
                setKashi('Make sure to review the vocabularies  and kanji you studied the previous day.');
            }
            if(Language == 2){
                setKashi('請務必複習前一天學習的單字和漢字。');
            }
            if(Language == 3){
                setKashi('तपाईंले अघिल्लो दिन अध्ययन गर्नुभएको शब्द र खान्जी समीक्षा गर्न निश्चित गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබ පෙර දින ඉගෙන ගත් වචන සහ කන්ජි පාඩම්කි කිරීමට වග බලා ගන්න.');
            }
            if(Language == 5){
                setKashi('ယခင်နေ့က သင်လေ့လာခဲ့သော စကားလုံးများနှင့် ကန်ဂျီများကို သေချာသုံးသပ်ပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে আপনি আগের দিন অধ্যয়ন করা শব্দ এবং কাঞ্জি মনে রাখবেন।');
            }
        }
        if(counter >= 258){
            if(Language == 0){
                setKashi('名前はカタカナのフルネームで書いてください。');
            }
            if(Language == 1){
                setKashi('Please write your full name in katakana.');
            }
            if(Language == 2){
                setKashi('請用片假名寫下您的全名。');
            }
            if(Language == 3){
                setKashi('कृपया आफ्नो पूरा नाम काताकानामा लेख्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර ඔබේ සම්පූර්ණ නම katakana හි ලියන්න.');
            }
            if(Language == 5){
                setKashi('မင်းနာမည်အပြည့်အစုံကို katakana မှာရေးပေးပါ။');
            }
            if(Language == 6){
                setKashi('কাতাকানায় আপনার পুরো নাম লিখুন।');
            }
        }
        if(counter >= 263){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
    }

    const timeSetMsg1 = () => {
        document.getElementById('video').currentTime = 0;
    }
    const timeSetMsg2 = () => {
        document.getElementById('video').currentTime = 68;
    }
    const timeSetMsg3 = () => {
        document.getElementById('video').currentTime = 130;
    }
    const timeSetMsg4 = () => {
        document.getElementById('video').currentTime = 158.5;
    }
    const timeSetMsg5 = () => {
        document.getElementById('video').currentTime = 188;
    }
    const timeSetMsg6 = () => {
        document.getElementById('video').currentTime = 233;
    }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            setLogInNo(LogInNo);
            navigate('/');
        }
    })

    const HomeReset = () => {
        setTimeVI(0);
        setLogInNo(LogInNo);
        navigate('/');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <img src={Titleimg} className='MenuLogo' onClick={() => {HomeReset()}}/>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                        <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                        <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                        <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>Chp4</div>
                        <div className={ChapterColor5} onClick={() => {timeSetMsg5()}}>Chp5</div>
                    </div>
                    <div className='MoveChapArea'>
                        <div className={ChapterColor6} onClick={() => {timeSetMsg6()}}>Chp6</div>
                    </div>
                    <video src={Lesson} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>Chp1</div>
                    <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>Chp2</div>
                    <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>Chp3</div>
                    <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>Chp4</div>
                    <div className={ChapterColor5} onClick={() => {timeSetMsg5()}}>Chp5</div>
                </div>
                <div className='MoveChapArea3'>
                    <div className={ChapterColor6} onClick={() => {timeSetMsg6()}}>Chp6</div>
                </div>
            </div>
        </div>
    )
}