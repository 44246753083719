import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import Dormitory from '../Images/Dormitory.mp4';

export default function DormitoryTourMove ({ setLanguage, Language, isAuth, setIsAuth, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        //　寮ツアー
        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
        }
        if(counter >= 0.8){
            if(Language == 0){
                setKashi('NSA日本語学校寮ツアー動画');
            }
            if(Language == 1){
                setKashi('NSA Japanese School Dormitory Tour Video');
            }
            if(Language == 2){
                setKashi('NSA日本學校宿舍參觀視頻');
            }
            if(Language == 3){
                setKashi('NSA जापानी स्कूल छात्रावास भ्रमण भिडियो');
            }
            if(Language == 4){
                setKashi('NSA ජපන් පාසල් නේවාසිකාගාර චාරිකා වීඩියෝව');
            }
            if(Language == 5){
                setKashi('NSA Japanese School Dormitory Tour Video');
            }
            if(Language == 6){
                setKashi('NSA জাপানি স্কুল ডরমিটরি ট্যুর ভিডিও');
            }
        }
        if(counter >= 4.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 18){
            if(Language == 0){
                setKashi('ゴミ箱 ごみはすぐにすてて、部屋をきれいに使ってください。');
            }
            if(Language == 1){
                setKashi('Trash can Please throw away trash immediately and keep your room clean.');
            }
            if(Language == 2){
                setKashi('垃圾桶 請立即扔掉垃圾並保持房間清潔。');
            }
            if(Language == 3){
                setKashi('फोहोर बक्स  कृपया तुरुन्तै फोहोर फाल्नुहोस् र आफ्नो कोठा सफा राख्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කුණු කූඩය    කරුණාකර වහාම කුණු ඉවත දමා ඔබේ කාමරය පිරිසිදුව තබා ගන්න.');
            }
            if(Language == 5){
                setKashi('အမှိုက်ပုံး  အမှိုက်ကို ချက်ချင်းစွန့်ပစ်ပြီး အခန်းကို သန့်ရှင်းအောင်ထားပါ။');
            }
            if(Language == 6){
                setKashi('আবর্জনা অনুগ্রহ করে অবিলম্বে আবর্জনা ফেলে দিন এবং আপনার ঘর পরিষ্কার রাখুন।');
            }
        }
        if(counter >= 20.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 34.5){
            if(Language == 0){
                setKashi('部屋①');
            }
            if(Language == 1){
                setKashi('Room ①');
            }
            if(Language == 2){
                setKashi('房間①');
            }
            if(Language == 3){
                setKashi('कोठा ①');
            }
            if(Language == 4){
                setKashi('කාමරය ①');
            }
            if(Language == 5){
                setKashi('အခန်း ①');
            }
            if(Language == 6){
                setKashi('রুম ①');
            }
        }
        if(counter >= 36.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 43.6){
            if(Language == 0){
                setKashi('洗濯機（せんたくき）  じゅんばんに使いましょう。');
            }
            if(Language == 1){
                setKashi('Washing machine Use them in order.');
            }
            if(Language == 2){
                setKashi('洗衣機  請與您的室友輪流使用它。');
            }
            if(Language == 3){
                setKashi('कपडा धुनी मिसिन    मिलेर पालैपालो प्रयोग गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('රෙදි සෝදන යන්ත්රය    ඒවා පිළිවෙලට භාවිතා කරන්න.');
            }
            if(Language == 5){
                setKashi('အဝတ်လျှော်စက်　  အဲဒါတွေကို စနစ်တကျသုံးပါ။');
            }
            if(Language == 6){
                setKashi('ওয়াশিং মেশিন এর পুঙ্খানুপুঙ্খভাবে ব্যবহার করা যাক।');
            }
        }
        if(counter >= 46.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 49.7){
            if(Language == 0){
                setKashi('洗面台（せんめんだい）  ルームメイトといっしょに使うので、きれいにしてください。');
            }
            if(Language == 1){
                setKashi('Wash basin You will be using it with your roommate, so please keep it clean.');
            }
            if(Language == 2){
                setKashi('洗手盆  您將與室友一起使用它，因此請保持清潔。');
            }
            if(Language == 3){
                setKashi('मुख धुने बेसिन तपाइँ यसलाई तपाइँको रूममेट संग प्रयोग गर्नुहुनेछ, त्यसैले कृपया यसलाई सफा राख्नुहोस्।');
            }
            if(Language == 4){
                setKashi('නාන බේසම ඔබ එය ඔබේ නේවාසික මිතුරා සමඟ භාවිතා කරනු ඇත, එබැවින් කරුණාකර එය පිරිසිදුව තබා ගන්න.');
            }
            if(Language == 5){
                setKashi('မျက်နှာသစ်စင် သင့်အခန်းဖော်နှင့်အတူ အသုံးပြုမည်ဖြစ်သောကြောင့် သန့်ရှင်းအောင်ထားပါ။');
            }
            if(Language == 6){
                setKashi('ওয়াশবাসিন আপনি এটি আপনার রুমমেটের সাথে ব্যবহার করবেন, তাই দয়া করে এটি পরিষ্কার রাখুন।');
            }
        }
        if(counter >= 52.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 56.7){
            if(Language == 0){
                setKashi('おふろ  使うとき、かんきせんをまわしてください。');
            }
            if(Language == 1){
                setKashi('Bath Please turn on the ventilation fan when using it.');
            }
            if(Language == 2){
                setKashi('浴室  使用時請打開換氣扇。');
            }
            if(Language == 3){
                setKashi('स्नान कक्ष，कृपया यसलाई प्रयोग गर्दा भेन्टिलेसन फ्यान खोल्नुहोस्।');
            }
            if(Language == 4){
                setKashi('නාන    කරුණාකර එය භාවිතා කරන විට වාතාශ්රය විදුලි පංකාව සක්රිය කරන්න.');
            }
            if(Language == 5){
                setKashi('ရေချိုး  အသုံးပြုနေစဉ် လေဝင်လေထွက်ပန်ကာကို ဖွင့်ပါ။');
            }
            if(Language == 6){
                setKashi('স্নান ব্যবহার করার সময় অনুগ্রহ করে ফ্যান চালু করুন।');
            }
        }
        if(counter >= 59.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 75.5){
            if(Language == 0){
                setKashi('トイレ  こまめにそうじしてください。');
            }
            if(Language == 1){
                setKashi('Toilet Please clean it frequently.');
            }
            if(Language == 2){
                setKashi('廁所  請經常清潔。');
            }
            if(Language == 3){
                setKashi('शौचालय कृपया बारम्बार सफा गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('වැසිකිළිය    කරුණාකර නිතර පිරිසිදු කරන්න.');
            }
            if(Language == 5){
                setKashi('အိမ်သာ  မကြာခဏ သန့်ရှင်းပေးပါ။');
            }
            if(Language == 6){
                setKashi('অনুগ্রহ করে ঘন ঘন টয়লেট পরিষ্কার করুন।');
            }
        }
        if(counter >= 78.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 85.5){
            if(Language == 0){
                setKashi('部屋② ');
            }
            if(Language == 1){
                setKashi('Room ②');
            }
            if(Language == 2){
                setKashi('房間② ');
            }
            if(Language == 3){
                setKashi('कोठा ② ');
            }
            if(Language == 4){
                setKashi('කාමරය ②  ');
            }
            if(Language == 5){
                setKashi('အခန်း ②  ');
            }
            if(Language == 6){
                setKashi('রুম ②');
            }
        }
        if(counter >= 88.5){
            if(Language == 0){
                setKashi(' ');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 92){
            if(Language == 0){
                setKashi('部屋③ ');
            }
            if(Language == 1){
                setKashi('Room ③');
            }
            if(Language == 2){
                setKashi('房間③');
            }
            if(Language == 3){
                setKashi('कोठा ③');
            }
            if(Language == 4){
                setKashi('කාමරය ③');
            }
            if(Language == 5){
                setKashi('အခန်း ③');
            }
            if(Language == 6){
                setKashi('রুম ③');
            }
        }
        if(counter >= 95.2){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 105.4){
            if(Language == 0){
                setKashi('キッチン  きれいにそうじして、使ってください。');
            }
            if(Language == 1){
                setKashi('Kitchen Please clean it thoroughly and use it.');
            }
            if(Language == 2){
                setKashi('廚房  請徹底清潔並使用。');
            }
            if(Language == 3){
                setKashi('भान्सा कृपया यसलाई राम्ररी सफा गर्नुहोस् र प्रयोग गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කුස්සිය    කරුණාකර එය හොඳින් පිරිසිදු කර භාවිතා කරන්න.');
            }
            if(Language == 5){
                setKashi('မီးဖိုချောင်  ကျေးဇူးပြု၍ သေချာဆေးကြောပြီး အသုံးပြုပါ။');
            }
            if(Language == 6){
                setKashi('রান্নাঘর পরিষ্কার করুন এবং ব্যবহার করুন।');
            }
        }
        if(counter >= 108){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 111.8){
            if(Language == 0){
                setKashi('炊飯器(すいはんき)  きれいに使ってください。');
            }
            if(Language == 1){
                setKashi('Rice cooker Please use it carefully.');
            }
            if(Language == 2){
                setKashi('電鍋  請乾淨地使用。');
            }
            if(Language == 3){
                setKashi('राइस कुकर कृपया यसलाई सावधानीपूर्वक प्रयोग गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('රයිස් කුකර්    කරුණාකර එය ප්රවේශමෙන් භාවිතා කරන්න.');
            }
            if(Language == 5){
                setKashi('ထမင်းပေါင်းအိုး  ဂရုတစိုက်အသုံးပြုပါ။');
            }
            if(Language == 6){
                setKashi('দয়া করে রাইস কুকার পরিষ্কারভাবে ব্যবহার করুন।');
            }
        }
        if(counter >= 114.4){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 119){
            if(Language == 0){
                setKashi('コンロ  使ったあとは、きれいにふいてください。');
            }
            if(Language == 1){
                setKashi('Stove Wipe it clean after using it.');
            }
            if(Language == 2){
                setKashi('厨房炉灶  使用後擦拭乾淨。');
            }
            if(Language == 3){
                setKashi('चुलो यसलाई प्रयोग गरेपछि सफा गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('උදුන    එය භාවිතා කිරීමෙන් පසු එය පිරිසිදු කරන්න.');
            }
            if(Language == 5){
                setKashi('မီးဖို  အသုံးပြုပြီးပါက သန့်ရှင်းပေးပါ။');
            }
            if(Language == 6){
                setKashi('চুলা ব্যবহার করার পরে এটি পরিষ্কার করুন।');
            }
        }
        if(counter >= 121.6){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 124){
            if(Language == 0){
                setKashi('冷蔵庫(れいぞうこ)  ときどき中をふいて、きれいに使ってください。');
            }
            if(Language == 1){
                setKashi('Refrigerator Wipe the inside from time to time to keep it clean.');
            }
            if(Language == 2){
                setKashi('冰箱  時常擦拭內部，保持清潔。');
            }
            if(Language == 3){
                setKashi('फ्रिज  यसलाई सफा राख्न समय समयमा भित्री भाग पुछ्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ශීතකරණය    ඇතුළත පිරිසිදුව තබා ගැනීමට වරින් වර පිස දමන්න.');
            }
            if(Language == 5){
                setKashi('ရေခဲသေတ္တာ  အတွင်းပိုင်းကို အခါအားလျော်စွာ သန့်ရှင်းအောင် သုတ်ပေးပါ။');
            }
            if(Language == 6){
                setKashi('রেফ্রিজারেটর পরিষ্কার রাখতে অনুগ্রহ করে সময় সময় ভিতরে মুছুন।');
            }
        }
        if(counter >= 126.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 135.4){
            if(Language == 0){
                setKashi('禁煙＆ごみ分別表  部屋とベランダでたばこをすってはいけません。');
            }
            if(Language == 1){
                setKashi('No smoking & garbage sorting table Do not smoke in your room or on the balcony.');
            }
            if(Language == 2){
                setKashi('禁止吸煙及垃圾分類台  請勿在房間內或陽台上吸煙。');
            }
            if(Language == 3){
                setKashi('धुम्रपान सम्बन्धी र फोहोर फाल्ने सम्बन्धी  तालिका ,आफ्नो कोठामा वा बालकनीमा धुम्रपान नगर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('දුම්පානය සහ කසළ වර්ග කිරීමේ වගුවක් ඇත. ඔබේ කාමරයේ හෝ බැල්කනියේ දුම් පානය නොකරන්න.');
            }
            if(Language == 5){
                setKashi('ဆေးလိပ်မသောက်ရနှင့် အမှိုက်ခွဲခြင်း ဇယား  သင့်အခန်း သို့မဟုတ် လသာဆောင်တွင် ဆေးလိပ်မသောက်ပါနှင့်။');
            }
            if(Language == 6){
                setKashi('নো ধূমপান এবং আবর্জনা পৃথকীকরণ চার্ট রুম এবং বারান্দায় ধূমপান নিষিদ্ধ।');
            }
        }
        if(counter >= 138.5){
            if(Language == 0){
                setKashi('禁煙＆ごみ分別表  ごみは分別表を見て、きちんとわけてください。');
            }
            if(Language == 1){
                setKashi('No smoking & garbage sorting table Please refer to the garbage separation table and separate your garbage properly.');
            }
            if(Language == 2){
                setKashi('止吸煙及垃圾分類台  請參閱垃圾分類表，正確分類垃圾。');
            }
            if(Language == 3){
                setKashi('धुम्रपान सम्बन्धी र फोहोर फाल्ने सम्बन्धी  तालिका, कृपया फोहोर अलग गर्ने तालिकालाई सन्दर्भ गर्नुहोस् र आफ्नो फोहोरलाई राम्ररी अलग गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('දුම්පානය සහ කසළ වර්ග කිරීමේ වගුවක් නොමැත    කරුණාකර කසළ වෙන් කිරීමේ වගුව වෙත ගොස් ඔබේ කසළ නිසි ලෙස වෙන් කරන්න.');
            }
            if(Language == 5){
                setKashi('ဆေးလိပ်မသောက်ရနှင့် အမှိုက်ခွဲခြင်း ဇယား ကျေးဇူးပြု၍ အမှိုက်ခွဲခြားခြင်းဇယားကို ကြည့်ပြီး သင့်အမှိုက်များကို စနစ်တကျ ခွဲခြားပါ။');
            }
            if(Language == 6){
                setKashi('নো ধূমপান এবং আবর্জনা আলাদা করার টেবিল অনুগ্রহ করে আবর্জনা আলাদা করার টেবিল দেখুন এবং আপনার আবর্জনা সঠিকভাবে আলাদা করুন।');
            }
        }
        if(counter >= 141.5){
            if(Language == 0){
                setKashi('ごみすて場  わけたごみは、決められた曜日にすててください。');
            }
            if(Language == 1){
                setKashi('Garbage disposal site Please throw away the separated garbage on the designated day of the week.');
            }
            if(Language == 2){
                setKashi('垃圾場  請在一週中的指定日期丟掉分類垃圾。');
            }
            if(Language == 3){
                setKashi('फोहोर फाल्ने ठाउँ ,  कृपया छुट्याइएको फोहोर हप्ताको तोकिएको दिनमा फाल्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කුණු ගොඩ    කරුණාකර වෙන් කරන ලද කසළ සතියේ නියමිත දිනට ඉවත දමන්න.');
            }
            if(Language == 5){
                setKashi('အမှိုက်သရိုက်အမှိုက်ပုံ  ကျေးဇူးပြု၍ ရက်သတ္တပတ်၏ သတ်မှတ်ရက်တွင် ခွဲထားသော အမှိုက်များကို စွန့်ပစ်ပါ။');
            }
            if(Language == 6){
                setKashi('আবর্জনা নিষ্পত্তি এলাকা অনুগ্রহ করে সপ্তাহের নির্ধারিত দিনে পৃথক আবর্জনা নিষ্পত্তি করুন।');
            }
        }
        if(counter >= 147){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 150.3){
            if(Language == 0){
                setKashi('寮ツアー動画END...');
            }
            if(Language == 1){
                setKashi('Dormitory tour video END...');
            }
            if(Language == 2){
                setKashi('宿舍參觀影片完...');
            }
            if(Language == 3){
                setKashi('छात्रावास भ्रमण भिडियो END...');
            }
            if(Language == 4){
                setKashi('නේවාසිකාගාර චාරිකාවේ වීඩියෝව අවසන්...');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင် ခရီးစဉ် ဗီဒီယို အဆုံး...');
            }
            if(Language == 6){
                setKashi('ডরমেটরি ট্যুর ভিডিও শেষ...');
            }
        }
    }

    const timeSetMsg1 = () => {
        document.getElementById('video').currentTime = 0;
    }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            setLogInNo(LogInNo);
            navigate('/');
        }
    })

    const HomeReset = () => {
        setLogInNo(LogInNo);
        navigate('/');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <img src={Titleimg} className='MenuLogo' onClick={() => {HomeReset()}}/>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className='MoveChapBtn MChBChange' onClick={() => {timeSetMsg1()}}>Chp1</div>
                    </div>
                    <video src={Dormitory} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className='MoveChapBtn MChBChange' onClick={() => {timeSetMsg1()}}>Chp1</div>
                </div>
            </div>
        </div>
    )
}