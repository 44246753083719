import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import Life from '../Images/Life.mp4';

export default function PrincipalMove ({ setLanguage, Language, isAuth, setIsAuth, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        // 校長あいさつ
        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
        }
        if(counter >= 1.1){
            if(Language == 0){
                setKashi('こんにちは！');
            }
            if(Language == 1){
                setKashi('Hello!');
            }
            if(Language == 2){
                setKashi('你好！');
            }
            if(Language == 3){
                setKashi('नमस्ते!');
            }
            if(Language == 4){
                setKashi('ආයුබෝවන්!');
            }
            if(Language == 5){
                setKashi('မင်္ဂလာပါ!');
            }
            if(Language == 6){
                setKashi('হ্যালো!');
            }
        }
        if(counter >= 2.9){
            if(Language == 0){
                setKashi('NSA日本語学校 校長の松本です。');
            }
            if(Language == 1){
                setKashi('My name is Matsumoto, the principal of NSA Japanese School.');
            }
            if(Language == 2){
                setKashi('我是NSA日本語學校的校長松本。');
            }
            if(Language == 3){
                setKashi('म यस NSA जापानी भाषा स्कूलको प्रिन्सिपल माचुमोतो हो।');
            }
            if(Language == 4){
                setKashi('මම Matsumoto, NSA ජපන් භාෂා පාසලේ විදුහල්පති.');
            }
            if(Language == 5){
                setKashi('ကျွန်ုပ်သည် NSA ဂျပန်ဘာသာစကားသင်တန်းကျောင်း၏ ကျောင်းအုပ် Matsumoto ဖြစ်သည်။');
            }
            if(Language == 6){
                setKashi('ইনি মাতসুমোটো, NSA জাপানিজ ল্যাংগুয়েজ স্কুলের অধ্যক্ষ।');
            }
        }
        if(counter >= 8.2){
            if(Language == 0){
                setKashi('みなさん、入学おめでとうございます！');
            }
            if(Language == 1){
                setKashi('Congratulations to everyone on your admission!');
            }
            if(Language == 2){
                setKashi('恭喜大家被錄取！');
            }
            if(Language == 3){
                setKashi('तपाइँहरूको प्रवेशको लागी सबैलाई बधाई छ।');
            }
            if(Language == 4){
                setKashi('ඔබගේ ඇතුලත් වීම  පිළිබඳ සියලු දෙනාටම සුබ පැතුම්!');
            }
            if(Language == 5){
                setKashi('သင်၏ဝင်ခွင့်အတွက် လူတိုင်းကို ဂုဏ်ပြုပါသည်။');
            }
            if(Language == 6){
                setKashi('আপনার ভর্তির জন্য সবাইকে অভিনন্দন!');
            }
        }
        if(counter >= 13){
            if(Language == 0){
                setKashi('ビザの準備は大変でしたね。');
            }
            if(Language == 1){
                setKashi('Preparing for the visa was difficult.');
            }
            if(Language == 2){
                setKashi('準備簽證很困難。');
            }
            if(Language == 3){
                setKashi('भिसाको तयारी गर्न गाह्रो थियो।');
            }
            if(Language == 4){
                setKashi('වීසා සඳහා සූදානම් වීම දුෂ්කර විය.');
            }
            if(Language == 5){
                setKashi('ဗီဇာအတွက် ပြင်ဆင်ရတာ ခက်တယ်။');
            }
            if(Language == 6){
                setKashi('ভিসার জন্য প্রস্তুতি কঠিন ছিল।');
            }
        }
        if(counter >= 16.9){
            if(Language == 0){
                setKashi('いよいよ、日本の学校生活が始まります。');
            }
            if(Language == 1){
                setKashi('Finally, school life in Japan begins.');
            }
            if(Language == 2){
                setKashi('日本的學校生活終於開始了。');
            }
            if(Language == 3){
                setKashi('जापानी विद्यालय जीवन अन्ततः सुरु हुँदैछ।');
            }
            if(Language == 4){
                setKashi('ජපන් පාසල් ජීවිතය මෙතැන් සිට ආරම්භ  වේ.');
            }
            if(Language == 5){
                setKashi('ဂျပန်ကျောင်းဘဝဟာ နောက်ဆုံးတော့ စတင်နေပါပြီ။');
            }
            if(Language == 6){
                setKashi('অবশেষে, জাপানে স্কুল জীবন শুরু হয়।');
            }
        }
        if(counter >= 23){
            if(Language == 0){
                setKashi('日本と、学校のルールを勉強して');
            }
            if(Language == 1){
                setKashi('Please study Japan and school rules and');
            }
            if(Language == 2){
                setKashi('日本留學與學校規則');
            }
            if(Language == 3){
                setKashi('जापान र स्कूल नियमहरू अध्ययन गर्दै');
            }
            if(Language == 4){
                setKashi('ජපානය සහ පාසල් නීති අධ්‍යයනය කිරීම');
            }
            if(Language == 5){
                setKashi('ဂျပန်စာလေ့လာပြီး ကျောင်းစည်းကမ်း');
            }
            if(Language == 6){
                setKashi('জাপান এবং স্কুলের নিয়ম অধ্যয়ন');
            }
        }
        if(counter >= 27){
            if(Language == 0){
                setKashi('楽しい学生生活をおくってください。');
            }
            if(Language == 1){
                setKashi('have an enjoyable student life!');
            }
            if(Language == 2){
                setKashi('祝學生生活愉快！');
            }
            if(Language == 3){
                setKashi('म आशा गर्दछु कि तपाई रमाइलो विद्यार्थी जीवन बिताउनु हुन्छ।');
            }
            if(Language == 4){
                setKashi('විනෝදජනක ශිෂ්‍ය ජීවිතයක් ගත කරන්න!');
            }
            if(Language == 5){
                setKashi('ပျော်ရွှင်စရာ ကျောင်းသားဘဝလေးဖြစ်ပါစေ။');
            }
            if(Language == 6){
                setKashi('একটি মজার ছাত্র জীবন কাটাও!');
            }
        }
        if(counter >= 32){
            if(Language == 0){
                setKashi('NSA日本語学校　オリエンテーション');
            }
            if(Language == 1){
                setKashi('NSA Japanese School Orientation');
            }
            if(Language == 2){
                setKashi('NSA 日本學校迎新活動');
            }
            if(Language == 3){
                setKashi('NSA जापानीज भाषा स्कूलको जान्नु पर्ने कुराहरु，');
            }
            if(Language == 4){
                setKashi('NSA ජපන් පාසල් ඡිවිතය');
            }
            if(Language == 5){
                setKashi('NSA ဂျပန်ကျောင်း လမ်းညွှန်');
            }
            if(Language == 6){
                setKashi('NSA জাপানিজ স্কুল ওরিয়েন্টেশন');
            }
        }
        if(counter >= 35.5){
            if(Language == 0){
                setKashi('スタート！');
            }
            if(Language == 1){
                setKashi('Start!');
            }
            if(Language == 2){
                setKashi('開始！');
            }
            if(Language == 3){
                setKashi('सुरु।');
            }
            if(Language == 4){
                setKashi('ආරම්භ කරමු!');
            }
            if(Language == 5){
                setKashi('လမ်းညွှန်');
            }
            if(Language == 6){
                setKashi('শুরু করুন!');
            }
        }
        if(counter >= 38.6){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
    }

    const timeSetMsg1 = () => {
        document.getElementById('video').currentTime = 0;
    }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            setLogInNo(LogInNo);
            navigate('/');
        }
    })

    const HomeReset = () => {
        setLogInNo(LogInNo);
        navigate('/');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <img src={Titleimg} className='MenuLogo' onClick={() => {HomeReset()}}/>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className='MoveChapBtn MChBChange' onClick={() => {timeSetMsg1()}}>Chp1</div>
                    </div>
                    <video src={Life} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className='MoveChapBtn MChBChange' onClick={() => {timeSetMsg1()}}>Chp1</div>
                </div>
            </div>
        </div>
    )
}