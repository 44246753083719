import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/BackImg.jpg';

import Life from '../Images/Life.mp4';
import Tour from '../Images/Tour.mp4';
import VISA from '../Images/VISA.mp4';
import Arbeit from '../Images/Arbeit.mp4';
import WSDB from '../Images/WSDB.mp4';
import SchoolLife from '../Images/SchoolLife.mp4';

import JourneyA from '../Images/JourneyA.mp4';
import JourneyB from '../Images/JourneyB.mp4';
import Dormitory from '../Images/Dormitory.mp4';
import DorRuhr from '../Images/DorRuhr.mp4';
import Electronics from '../Images/Electronics.mp4';
import Lesson from '../Images/Lesson.mp4';
import Regulations from '../Images/Regulations.mp4';


import Proof from '../Images/Proof.mp4';
import Ruhr from '../Images/Ruhr.mp4';

export default function AllMove ({ setLanguage, Language, isAuth, setIsAuth, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        // 他ページに選択中の言語を指定する処理
        setLanguage(0);
    }

    //英語時
    const selectEnglish = () => {
        // 他ページに選択中の言語を指定する処理
        setLanguage(1);
    }

    //繁体中国語時
    const selectChina = () => {
        // 他ページに選択中の言語を指定する処理
        setLanguage(2);
    }

    //ネパール語時
    const selectNepal = () => {
        // 他ページに選択中の言語を指定する処理
        setLanguage(3);
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        // 他ページに選択中の言語を指定する処理
        setLanguage(4);
    }

    //ビルマ語時
    const selectBurma = () => {
        // 他ページに選択中の言語を指定する処理
        setLanguage(5);
    }

    //ベンガル語時
    const selectBengal = () => {
        // 他ページに選択中の言語を指定する処理
        setLanguage(6);
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [RightAnswer, setRightAnswer] = useState();
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    // 再生チャプター変色
    const [ChapterColor1, setChapterColor1] = useState('MoveChapBtn');
    const [ChapterColor2, setChapterColor2] = useState('MoveChapBtn');
    const [ChapterColor3, setChapterColor3] = useState('MoveChapBtn');
    const [ChapterColor4, setChapterColor4] = useState('MoveChapBtn');
    const [ChapterColor5, setChapterColor5] = useState('MoveChapBtn');
    const [ChapterColor6, setChapterColor6] = useState('MoveChapBtn');
    const [ChapterColor7, setChapterColor7] = useState('MoveChapBtn');
    const [ChapterColor8, setChapterColor8] = useState('MoveChapBtn');
    const [ChapterColor9, setChapterColor9] = useState('MoveChapBtn');
    const [ChapterColor10, setChapterColor10] = useState('MoveChapBtn');
    const [ChapterColor11, setChapterColor11] = useState('MoveChapBtn');
    const [ChapterColor12, setChapterColor12] = useState('MoveChapBtn');
    const [ChapterColor13, setChapterColor13] = useState('MoveChapBtn');
    const [ChapterColor14, setChapterColor14] = useState('MoveChapBtn');

    // 再生中のビデオ
    const [MoveVideo, setMoveVideo] = useState();
    const [MoveVideoTime, setMoveVideoTime] = useState(0);

    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }

            setChapterColor1('MoveChapBtn MChBChange');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
            setChapterColor14('MoveChapBtn');
        }

        //　1本目　校長の挨拶
        if(MoveVideoTime == 0){
            setChapterColor1('MoveChapBtn MChBChange');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
            setChapterColor14('MoveChapBtn');

            if(counter >= 0){
                setMoveVideo(Life);
            }
            if(counter >= 1.1){
                if(Language == 0){
                    setKashi('こんにちは！');
                }
                if(Language == 1){
                    setKashi('Hello!');
                }
                if(Language == 2){
                    setKashi('你好！');
                }
                if(Language == 3){
                    setKashi('नमस्ते।');
                }
                if(Language == 4){
                    setKashi('ආයුබෝවන්!');
                }
                if(Language == 5){
                    setKashi('မင်္ဂလာပါ!');
                }
                if(Language == 6){
                    setKashi('হ্যালো!');
                }
            }
            if(counter >= 2.9){
                if(Language == 0){
                    setKashi('NSA日本語学校 校長の松本です。');
                }
                if(Language == 1){
                    setKashi('My name is Matsumoto, the principal of NSA Japanese School.');
                }
                if(Language == 2){
                    setKashi('我是NSA日本語學校的校長松本。');
                }
                if(Language == 3){
                    setKashi('म यस NSA जापानी भाषा स्कूलको प्रिन्सिपल माचुमोतो हो।');
                }
                if(Language == 4){
                    setKashi('මම Matsumoto, NSA ජපන් භාෂා පාසලේ විදුහල්පති.');
                }
                if(Language == 5){
                    setKashi('ကျွန်ုပ်သည် NSA ဂျပန်ဘာသာစကားသင်တန်းကျောင်း၏ ကျောင်းအုပ် Matsumoto ဖြစ်သည်။');
                }
                if(Language == 6){
                    setKashi('ইনি মাতসুমোটো, NSA জাপানিজ ল্যাংগুয়েজ স্কুলের অধ্যক্ষ।');
                }
            }
            if(counter >= 8.2){
                if(Language == 0){
                    setKashi('みなさん、入学おめでとうございます！');
                }
                if(Language == 1){
                    setKashi('Congratulations to everyone on your admission!');
                }
                if(Language == 2){
                    setKashi('恭喜大家被錄取！');
                }
                if(Language == 3){
                    setKashi('तपाइँहरूको प्रवेशको लागी सबैलाई बधाई छ।');
                }
                if(Language == 4){
                    setKashi('ඔබගේ ඇතුලත් වීම  පිළිබඳ සියලු දෙනාටම සුබ පැතුම්!');
                }
                if(Language == 5){
                    setKashi('သင်၏ဝင်ခွင့်အတွက် လူတိုင်းကို ဂုဏ်ပြုပါသည်။');
                }
                if(Language == 6){
                    setKashi('আপনার ভর্তির জন্য সবাইকে অভিনন্দন!');
                }
            }
            if(counter >= 13){
                if(Language == 0){
                    setKashi('ビザの準備は大変でしたね。');
                }
                if(Language == 1){
                    setKashi('Preparing for the visa was difficult.');
                }
                if(Language == 2){
                    setKashi('準備簽證很困難。');
                }
                if(Language == 3){
                    setKashi('भिसाको तयारी गर्न गाह्रो थियो।');
                }
                if(Language == 4){
                    setKashi('වීසා සඳහා සූදානම් වීම දුෂ්කර විය.');
                }
                if(Language == 5){
                    setKashi('ဗီဇာအတွက် ပြင်ဆင်ရတာ ခက်တယ်။');
                }
                if(Language == 6){
                    setKashi('ভিসার জন্য প্রস্তুতি কঠিন ছিল।');
                }
            }
            if(counter >= 16.9){
                if(Language == 0){
                    setKashi('いよいよ、日本の学校生活が始まります。');
                }
                if(Language == 1){
                    setKashi('Finally, school life in Japan begins.');
                }
                if(Language == 2){
                    setKashi('日本的學校生活終於開始了。');
                }
                if(Language == 3){
                    setKashi('जापानी विद्यालय जीवन अन्ततः सुरु हुँदैछ।');
                }
                if(Language == 4){
                    setKashi('ජපන් පාසල් ජීවිතය මෙතැන් සිට ආරම්භ  වේ.');
                }
                if(Language == 5){
                    setKashi('ဂျပန်ကျောင်းဘဝဟာ နောက်ဆုံးတော့ စတင်နေပါပြီ။');
                }
                if(Language == 6){
                    setKashi('অবশেষে, জাপানে স্কুল জীবন শুরু হয়।');
                }
            }
            if(counter >= 23){
                if(Language == 0){
                    setKashi('日本と、学校のルールを勉強して');
                }
                if(Language == 1){
                    setKashi('Please study Japan and school rules and');
                }
                if(Language == 2){
                    setKashi('日本留學與學校規則');
                }
                if(Language == 3){
                    setKashi('जापान र स्कूल नियमहरू अध्ययन गर्दै');
                }
                if(Language == 4){
                    setKashi('ජපානය සහ පාසල් නීති අධ්‍යයනය කිරීම');
                }
                if(Language == 5){
                    setKashi('ဂျပန်စာလေ့လာပြီး ကျောင်းစည်းကမ်း');
                }
                if(Language == 6){
                    setKashi('জাপান এবং স্কুলের নিয়ম অধ্যয়ন');
                }
            }
            if(counter >= 27){
                if(Language == 0){
                    setKashi('楽しい学生生活をおくってください。');
                }
                if(Language == 1){
                    setKashi('have an enjoyable student life!');
                }
                if(Language == 2){
                    setKashi('祝學生生活愉快！');
                }
                if(Language == 3){
                    setKashi('म आशा गर्दछु कि तपाई रमाइलो विद्यार्थी जीवन बिताउनु हुन्छ।');
                }
                if(Language == 4){
                    setKashi('විනෝදජනක ශිෂ්‍ය ජීවිතයක් ගත කරන්න!');
                }
                if(Language == 5){
                    setKashi('ပျော်ရွှင်စရာ ကျောင်းသားဘဝလေးဖြစ်ပါစေ။');
                }
                if(Language == 6){
                    setKashi('একটি মজার ছাত্র জীবন কাটাও!');
                }
            }
            if(counter >= 32){
                if(Language == 0){
                    setKashi('NSA日本語学校　オリエンテーション');
                }
                if(Language == 1){
                    setKashi('NSA Japanese School Orientation');
                }
                if(Language == 2){
                    setKashi('NSA 日本學校迎新活動');
                }
                if(Language == 3){
                    setKashi('NSA जापानीज भाषा स्कूलको जान्नु पर्ने कुराहरु，');
                }
                if(Language == 4){
                    setKashi('NSA ජපන් පාසල් ඡිවිතය');
                }
                if(Language == 5){
                    setKashi('NSA ဂျပန်ကျောင်း လမ်းညွှန်');
                }
                if(Language == 6){
                    setKashi('NSA জাপানিজ স্কুল ওরিয়েন্টেশন');
                }
            }
            if(counter >= 35.5){
                if(Language == 0){
                    setKashi('スタート！');
                }
                if(Language == 1){
                    setKashi('Start!');
                }
                if(Language == 2){
                    setKashi('開始！');
                }
                if(Language == 3){
                    setKashi('सुरु।');
                }
                if(Language == 4){
                    setKashi('ආරම්භ කරමු!');
                }
                if(Language == 5){
                    setKashi('လမ်းညွှန်');
                }
                if(Language == 6){
                    setKashi('শুরু করুন!');
                }
            }
            if(counter >= 38.6){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            if(counter >= 42){
                setMoveVideoTime(1);
                document.getElementById('video').currentTime = 0;
            }
        }

        //　2本目　学内ツアー
        if(MoveVideoTime == 1){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn MChBChange');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
            setChapterColor14('MoveChapBtn');

            if(counter >= 0){
                setMoveVideo(Tour);
            }

            if(counter >= 7){
                if(Language == 0){
                    setKashi('NSA日本語学校学内ツアー動画');
                }
                if(Language == 1){
                    setKashi('NSA Japanese school campus tour video');
                }
                if(Language == 2){
                    setKashi('NSA日本語學校內部參觀視頻');
                }
                if(Language == 3){
                    setKashi('NSA जापानीज स्कूल आन्तरिक भ्रमण भिडियो');
                }
                if(Language == 4){
                    setKashi('NSA ජපන් පාසල් අභ්‍යන්තර චාරිකා වීඩියෝව');
                }
                if(Language == 5){
                    setKashi('NSA ဂျပန်ကျောင်းတွင်း ခရီးသွားဗီဒီယို');
                }
                if(Language == 6){
                    setKashi('NSA জাপানি স্কুল ক্যাম্পাস ট্যুর ভিডিও');
                }
            }
            if(counter >= 11){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 26.5){
                if(Language == 0){
                    setKashi('駐輪場(ちゅうりんじょう)、ここに自転車をきれいにならべておいてください。');
                }
                if(Language == 1){
                    setKashi('Bicycle parking lot Please line up your bicycles neatly.');
                }
                if(Language == 2){
                    setKashi('自行車停車場、請在此整齊地排列自行車。');
                }
                if(Language == 3){
                    setKashi('साइकल पार्किङ स्थल कृपया यहाँ आफ्नो साइकलहरू क्रमबद्ध रूपमा मिलाएर राख्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('බයිසිකල් නැවැත්වීමේ ස්ථානය කරුණාකර ඔබේ බයිසිකල් මෙහි පිළිවෙලට පෙළගස්වන්න.');
                }
                if(Language == 5){
                    setKashi('စက်ဘီးပါကင် သင့်စက်ဘီးများကို ဤနေရာတွင် သေသေသပ်သပ် တန်းစီပါ။');
                }
                if(Language == 6){
                    setKashi('সাইকেল পার্কিং লট এখানে সুন্দরভাবে আপনার সাইকেল লাইন আপ করুন.');
                }
            }
            if(counter >= 28.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 33){
                if(Language == 0){
                    setKashi('自動販売機(じどうはんばいき)、飲み物を買うことができます。');
                }
                if(Language == 1){
                    setKashi('Vending machine You can buy drinks.');
                }
                if(Language == 2){
                    setKashi('自動販賣機、您可以買飲料。');
                }
                if(Language == 3){
                    setKashi('भेन्डिङ मेसिन यहाँ तपाईँहरू पिय पदार्थ किन्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('විකුණුම් යන්ත්රය ඔබට බීම මිලදී ගත හැකිය.');
                }
                if(Language == 5){
                    setKashi('အလိုအလျောက် စျေးရောင်းစက်မှာသောက်စရာတွေ ဝယ်လို့ရတယ်။');
                }
                if(Language == 6){
                    setKashi('ভেন্ডিং মেশিন (জিডো হানবাইকি) আপনি পানীয় কিনতে পারেন.');
                }
            }
            if(counter >= 34.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 56.5){
                if(Language == 0){
                    setKashi('2Fでくつをぬいで、スリッパをはいてください。');
                }
                if(Language == 1){
                    setKashi('Please take off your shoes and put on slippers on the 2nd floor.');
                }
                if(Language == 2){
                    setKashi('上二樓請脫鞋換上拖鞋。');
                }
                if(Language == 3){
                    setKashi('दोस्रो तल्लामा पुगेसी जुत्ता खोल्नुहोस् र चप्पल लगाएर भित्र छिर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබේ සපත්තු ගලවා 2 වන මහලේ සෙරෙප්පු දමාගන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးပြုပြီး ဖိနပ်ချွတ်ပြီး ဒုတိယထပ်မှာ ဖိနပ်တစ်ရံ ဝတ်ပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে আপনার জুতা খুলে ২য় তলায় চপ্পল পরুন।');
                }
            }
            if(counter >= 59.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 78.5){
                if(Language == 0){
                    setKashi('保健室(ほけんしつ)、体の調子が悪いときに休みます。');
                }
                if(Language == 1){
                    setKashi("Health room You can take a break when you don't feel well.");
                }
                if(Language == 2){
                    setKashi('保健室、當您感覺不舒服時我會休息一下。');
                }
                if(Language == 3){
                    setKashi('स्वास्थ्य कक्ष स्वास्थ्य स्थिति नराम्रो महशुस भएको बेला यहाँ आराम गर्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('සෞඛ්ය කාමරය මට සනීප නැති වෙලාවට මම විවේක ගන්නවා.');
                }
                if(Language == 5){
                    setKashi('ကျန်းမာရေးအခန်း အဆင်မပြေတဲ့အခါ အနားယူပါ။');
                }
                if(Language == 6){
                    setKashi('স্বাস্থ্য কক্ষ আমি যখন ভাল বোধ করি না তখন আমি বিরতি নিই।');
                }
            }
            if(counter >= 80){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 82.5){
                if(Language == 0){
                    setKashi('第一(だいいち)教室');
                }
                if(Language == 1){
                    setKashi('lassroom 1');
                }
                if(Language == 2){
                    setKashi('第一教室');
                }
                if(Language == 3){
                    setKashi('कक्षाकोठा नम्बर १');
                }
                if(Language == 4){
                    setKashi('පන්ති කාමර අංක 1');
                }
                if(Language == 5){
                    setKashi('စာသင်ခန်းအမှတ် ၁');
                }
                if(Language == 6){
                    setKashi('প্রথম শ্রেণীকক্ষ');
                }
            }
            if(counter >= 85.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 96.8){
                if(Language == 0){
                    setKashi('第二(だいに)教室');
                }
                if(Language == 1){
                    setKashi('Classroom 2');
                }
                if(Language == 2){
                    setKashi('第二間教室');
                }
                if(Language == 3){
                    setKashi('कक्षाकोठा नम्बर २');
                }
                if(Language == 4){
                    setKashi('දෙවන පන්ති කාමරය');
                }
                if(Language == 5){
                    setKashi('ဒုတိယစာသင်ခန်း');
                }
                if(Language == 6){
                    setKashi('দ্বিতীয় শ্রেণীকক্ষ');
                }
            }
            if(counter >= 99.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 104.7){
                if(Language == 0){
                    setKashi('女子トイレ');
                }
                if(Language == 1){
                    setKashi("Women's restroom");
                }
                if(Language == 2){
                    setKashi('女廁');
                }
                if(Language == 3){
                    setKashi('महिला शौचालय');
                }
                if(Language == 4){
                    setKashi('කාන්තා වැසිකිළිය');
                }
                if(Language == 5){
                    setKashi('အမျိုးသမီးအိမ်သာ');
                }
                if(Language == 6){
                    setKashi('মহিলাদের টয়লেট');
                }
            }
            if(counter >= 106.1){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 110){
                if(Language == 0){
                    setKashi('男子トイレ');
                }
                if(Language == 1){
                    setKashi("Men's restroom");
                }
                if(Language == 2){
                    setKashi('男洗手間');
                }
                if(Language == 3){
                    setKashi('पुरुषको शौचालय');
                }
                if(Language == 4){
                    setKashi('පිරිමි වැසිකිළිය');
                }
                if(Language == 5){
                    setKashi('အမျိုးသားသန့်စင်ခန်း');
                }
                if(Language == 6){
                    setKashi('পুরুষদের বিশ্রামাগার');
                }
            }
            if(counter >= 111.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 121.8){
                if(Language == 0){
                    setKashi('事務所(じむしょ)、事務所の先生がいます。');
                }
                if(Language == 1){
                    setKashi('School office There are staffs at the office.');
                }
                if(Language == 2){
                    setKashi('辦公室、辦公室裡有一位老師。');
                }
                if(Language == 3){
                    setKashi('कार्यालय अफिसमा शिक्षकहरू हुनुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('කාර්යාල ඔෆිස් එකේ ගුරැවරැ ඉන්නවා.');
                }
                if(Language == 5){
                    setKashi('ရုံး ရုံးမှာ ဆရာတစ်ယောက်ရှိတယ်။');
                }
                if(Language == 6){
                    setKashi('অফিস অফিসে একজন শিক্ষক আছেন।');
                }
            }
            if(counter >= 124.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 148){
                if(Language == 0){
                    setKashi('3階 受付');
                }
                if(Language == 1){
                    setKashi('3rd floor reception');
                }
                if(Language == 2){
                    setKashi('三樓接待處');
                }
                if(Language == 3){
                    setKashi('तेस्रो तल्लाको हाजिरी गर्ने ठाउँ');
                }
                if(Language == 4){
                    setKashi('3 වන මහල පිළිගැනීම');
                }
                if(Language == 5){
                    setKashi('တတိယထပ်ဧည့်ကြိုကောင်တာ');
                }
                if(Language == 6){
                    setKashi('তলায় অভ্যর্থনা');
                }
            }
            if(counter >= 149.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 152){
                if(Language == 0){
                    setKashi('3階 ロビー、きゅうけいのとき、ここで食べることができます。');
                }
                if(Language == 1){
                    setKashi('3rd floor lobby You can eat here during your break time.');
                }
                if(Language == 2){
                    setKashi('三樓大堂、休息的時候可以在這裡吃飯。');
                }
                if(Language == 3){
                    setKashi('तेस्रो तल्लाको लबी तपाईं आफ्नो विश्राम समयमा यहाँ खाना खान सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('3 වන මහලේ ලොබිය විවේක කාලයේදී ඔබට මෙහි ආහාර ගත හැකිය.');
                }
                if(Language == 5){
                    setKashi('တတိယထပ် ဧည့်ခန်း အားလပ်ချိန်မှာ ဒီမှာအစားစာ စားလို့ရပါတယ်။');
                }
                if(Language == 6){
                    setKashi('3য় তলার লবি আপনি Kyukei সময় এখানে খেতে পারেন.');
                }
            }
            if(counter >= 154){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 160){
                if(Language == 0){
                    setKashi('電子レンジを使ってもいいです。きれいに使ってください。');
                }
                if(Language == 1){
                    setKashi('You can use the microwave. Please use it neatly.');
                }
                if(Language == 2){
                    setKashi('您也可以使用微波爐。請謹慎使用。');
                }
                if(Language == 3){
                    setKashi('तपाईं माइक्रोवेव पनि प्रयोग गर्न सक्नुहुन्छ। कृपया यसलाई सावधानीपूर्वक प्रयोग गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබට මයික්‍රෝවේව් උදුනද භාවිතා කළ හැකිය. කරුණාකර එය ප්රවේශමෙන් භාවිතා කරන්න.');
                }
                if(Language == 5){
                    setKashi('မိုက်ခရိုဝေ့ကိုလည်း သုံးနိုင်ပါတယ်။ ဂရုတစိုက်အသုံးပြုပါ။');
                }
                if(Language == 6){
                    setKashi('আপনি মাইক্রোওয়েভ ব্যবহার করতে পারেন। সাবধানে এটি ব্যবহার করুন.');
                }
            }
            if(counter >= 161.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 164.2){
                if(Language == 0){
                    setKashi('3F 男子トイレ・女子トイレ');
                }
                if(Language == 1){
                    setKashi("3F Men's and Women's Restrooms");
                }
                if(Language == 2){
                    setKashi('3F 男女洗手間');
                }
                if(Language == 3){
                    setKashi('तेस्रो तल्लाको पुरुष र महिला शौचालय');
                }
                if(Language == 4){
                    setKashi('3F පිරිමි සහ කාන්තා වැසිකිලිය');
                }
                if(Language == 5){
                    setKashi('3F အမျိုးသားနှင့် အမျိုးသမီး သန့်စင်ခန်းများ');
                }
                if(Language == 6){
                    setKashi('পুরুষ ও মহিলাদের বিশ্রামাগার');
                }
            }
            if(counter >= 166.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 171.5){
                if(Language == 0){
                    setKashi('3階 B教室');
                }
                if(Language == 1){
                    setKashi('3rd floor Classroom B');
                }
                if(Language == 2){
                    setKashi('三樓B教室');
                }
                if(Language == 3){
                    setKashi('तेस्रो तल्लाको कक्षाकोठा B');
                }
                if(Language == 4){
                    setKashi('3 වන මහලේ පන්ති කාමරය B');
                }
                if(Language == 5){
                    setKashi('တတိယထပ် စာသင်ခန်း B');
                }
                if(Language == 6){
                    setKashi('3য় তলায় শ্রেণীকক্ষ B');
                }
            }
            if(counter >= 173.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 189.5){
                if(Language == 0){
                    setKashi('3階 C教室');
                }
                if(Language == 1){
                    setKashi('3rd floor Classroom C');
                }
                if(Language == 2){
                    setKashi('三樓C教室');
                }
                if(Language == 3){
                    setKashi('तेस्रो तल्लाको कक्षाकोठा C');
                }
                if(Language == 4){
                    setKashi('3 වන මහලේ පන්තිකාමරය C');
                }
                if(Language == 5){
                    setKashi('တတိယထပ် စာသင်ခန်း C');
                }
                if(Language == 6){
                    setKashi('3য় তলায় ক্লাসরুম সি');
                }
            }
            if(counter >= 191.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 206){
                if(Language == 0){
                    setKashi('3階 D教室');
                }
                if(Language == 1){
                    setKashi('3rd floor Classroom D');
                }
                if(Language == 2){
                    setKashi('三樓D教室');
                }
                if(Language == 3){
                    setKashi('तेस्रो तल्लाको कक्षा कोठा D');
                }
                if(Language == 4){
                    setKashi('3 වන මහලේ පන්ති කාමරය D');
                }
                if(Language == 5){
                    setKashi('တတိယထပ် စာသင်ခန်း D');
                }
                if(Language == 6){
                    setKashi('3য় তলায় ক্লাসরুম ডি');
                }
            }
            if(counter >= 207.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 225){
                if(Language == 0){
                    setKashi('ここで食べてもいいです。');
                }
                if(Language == 1){
                    setKashi('You can eat here.');
                }
                if(Language == 2){
                    setKashi('您可以在這裡吃飯。');
                }
                if(Language == 3){
                    setKashi('तपाईं यहाँ खान सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('මෙතන සිට  කෑම කන්න පුළුවන්.');
                }
                if(Language == 5){
                    setKashi('ဒီမှာစားလို့ရပါတယ်။');
                }
                if(Language == 6){
                    setKashi('আপনি এখানে খেতে পারেন।');
                }
            }
            if(counter >= 226.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 230){
                if(Language == 0){
                    setKashi('ここにもトイレがあります。男の人と女の人がいっしょに使うトイレです。');
                }
                if(Language == 1){
                    setKashi('There is also a toilet here. This is a restroom for men and women.');
                }
                if(Language == 2){
                    setKashi('這裡還有一個廁所。這是男女共同使用的廁所。');
                }
                if(Language == 3){
                    setKashi('यहाँ शौचालय पनि छ। यो महिला र पुरुष मिलेर प्रयोग गर्ने शौचालय हो।');
                }
                if(Language == 4){
                    setKashi('මෙහි වැසිකිළියක් ද ඇත. මෙය ගැහැණු පිරිමි එකට භාවිතා කරන වැසිකිළියකි.');
                }
                if(Language == 5){
                    setKashi('ဒီမှာ အိမ်သာလည်းရှိတယ်။ ဒီအိမ်သာက အမျိုးသားရော အမျိုးသမီးပါ တွဲသုံးတဲ့ အိမ်သာပါ။');
                }
                if(Language == 6){
                    setKashi('এখানে একটি টয়লেটও আছে। এটি একটি টয়লেট যা পুরুষ এবং মহিলারা একসাথে ব্যবহার করেন।');
                }
            }
            if(counter >= 232.1){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 236.2){
                if(Language == 0){
                    setKashi('日本語の本やマンガがたくさんあります。きゅうけいのとき、読んでもいいです。');
                }
                if(Language == 1){
                    setKashi('There are many Japanese books and manga. You can read them during your break time.');
                }
                if(Language == 2){
                    setKashi('有很多日本書籍和漫畫。您可以在休息時間閱讀。');
                }
                if(Language == 3){
                    setKashi('यँहा धेरै जापानी पुस्तकहरू र मंगा छन्। तपाइँ यसलाई  ब्रेक को समयमा पढ्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ජපන් පොත් සහ මංගා ගොඩක් තියෙනවා. ඔබේ විවේක කාලය තුළ ඔබට එය කියවිය හැකිය.');
                }
                if(Language == 5){
                    setKashi('ဂျပန်စာအုပ်တွေနဲ့ မန်ဂါတွေ အများကြီးရှိတယ်။ အားလပ်ချိန်မှာ ဖတ်နိုင်ပါတယ်။');
                }
                if(Language == 6){
                    setKashi('অনেক জাপানি বই এবং মাঙ্গা আছে। আপনি যখন মজা করছেন তখন আপনি এটি পড়তে পারেন।');
                }
            }
            if(counter >= 238.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 249){
                if(Language == 0){
                    setKashi('学内ツアー動画END...');
                }
                if(Language == 1){
                    setKashi('Campus tour video END...');
                }
                if(Language == 2){
                    setKashi('校園參觀影片完...');
                }
                if(Language == 3){
                    setKashi('स्कूल भ्रमण भिडियो END...');
                }
                if(Language == 4){
                    setKashi('කැම්පස් චාරිකා වීඩියෝව අවසන්...');
                }
                if(Language == 5){
                    setKashi('ကျောင်းတွင်း ခရီးသွားဗီဒီယို အဆုံး...');
                }
                if(Language == 6){
                    setKashi('ক্যাম্পাস ট্যুর ভিডিও শেষ...');
                }
            }

            if(counter >= 250){
                setMoveVideoTime(2);
                document.getElementById('video').currentTime = 0;
            }
        }
        
        //　3本目　ビザ
        if(MoveVideoTime == 2){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn MChBChange');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
            setChapterColor14('MoveChapBtn');

            if(counter >= 0){
                setMoveVideo(VISA);
            }

            if(counter >= 0.5){
                if(Language == 0){
                    setKashi('ビザの有効期限');
                }
                if(Language == 1){
                    setKashi('Visa validity period');
                }
                if(Language == 2){
                    setKashi('簽證效期');
                }
                if(Language == 3){
                    setKashi('भिसा म्याद सकिने मिति');
                }
                if(Language == 4){
                    setKashi('වීසා වලංගු කාලය');
                }
                if(Language == 5){
                    setKashi('ဗီဇာသက်တမ်း');
                }
                if(Language == 6){
                    setKashi('ভিসার মেয়াদকাল');
                }
            }
            if(counter >= 3.7){
                if(Language == 0){
                    setKashi('ビザについて ビザの有効期限');
                }
                if(Language == 1){
                    setKashi('About VISA ／ VISA expiry date');
                }
                if(Language == 2){
                    setKashi('關於簽證 簽證效期');
                }
                if(Language == 3){
                    setKashi('भिसा बारे भिसा म्याद सकिने मिति।');
                }
                if(Language == 4){
                    setKashi('වීසා ගැන වීසා වලංගු කාලය');
                }
                if(Language == 5){
                    setKashi('ဗီဇာအကြောင်း　 ဗီဇာသက်တမ်း');
                }
                if(Language == 6){
                    setKashi('ভিসা সম্পর্কে ভিসার মেয়াদ শেষ হওয়ার তারিখ');
                }
            }
            if(counter >= 6){
                if(Language == 0){
                    setKashi('ビザの話をします。');
                }
                if(Language == 1){
                    setKashi("I'm going to explain about visa.");
                }
                if(Language == 2){
                    setKashi('我現在就來解釋一下簽證的事。');
                }
                if(Language == 3){
                    setKashi('भिसाको बारेमा कुराहरु ।');
                }
                if(Language == 4){
                    setKashi('අපි වීසා ගැන කතා කරමු.');
                }
                if(Language == 5){
                    setKashi('ဗီဇာအကြောင်း ပြောကြရအောင်။');
                }
                if(Language == 6){
                    setKashi('ভিসা সম্পর্কে কথা বলা যাক।');
                }
            }
            if(counter >= 8.5){
                if(Language == 0){
                    setKashi('みなさん、在留カードを見てください。');
                }
                if(Language == 1){
                    setKashi('Please look at your residence card.');
                }
                if(Language == 2){
                    setKashi('請大家看一下自己的在留卡。');
                }
                if(Language == 3){
                    setKashi('सबैजना, कृपया आफ्नो निवास कार्ड हेर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('හැමෝම, කරුණාකර ඔබේ පදිංචි කාඩ්පත බලන්න.');
                }
                if(Language == 5){
                    setKashi('လူတိုင်း၊ သင့်နေထိုင်ခွင့်ကတ်ကို ကြည့်ပါ။');
                }
                if(Language == 6){
                    setKashi('সবাই, আপনার আবাসিক কার্ড দেখুন।');
                }
            }
            if(counter >= 12){
                if(Language == 0){
                    setKashi('あなたのビザはいつまでですか。');
                }
                if(Language == 1){
                    setKashi('When is the expiry date of your visa?');
                }
                if(Language == 2){
                    setKashi('您的簽證有效期限是多久？');
                }
                if(Language == 3){
                    setKashi('तपाईको भिसा कति लामो छ?');
                }
                if(Language == 4){
                    setKashi('ඔබගේ වීසා බලපත්‍රය කොපමණ කාලයක්ද?');
                }
                if(Language == 5){
                    setKashi('မင်းရဲ့ ဗီဇာက ဘယ်ချိန်ထိလဲ။');
                }
                if(Language == 6){
                    setKashi('আপনার ভিসা কতদিনের?');
                }
            }
            if(counter >= 15){
                if(Language == 0){
                    setKashi('ビザがおわる3か月前に、更新しなければなりません。');
                }
                if(Language == 1){
                    setKashi('You must renew your visa three months before it expires.');
                }
                if(Language == 2){
                    setKashi('您必須在簽證到期前三個月更新簽證。');
                }
                if(Language == 3){
                    setKashi('तपाईंले आफ्नो भिसाको म्याद समाप्त हुनुभन्दा तीन महिना अघि नवीकरण गर्नुपर्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබ ඔබේ වීසා බලපත්‍රය කල් ඉකුත් වීමට මාස තුනකට පෙර අලුත් කළ යුතුය.');
                }
                if(Language == 5){
                    setKashi('ဗီဇာသက်တမ်းမကုန်မီ သုံးလနှင့်အထက်သက်တန်းတိုးရန်။');
                }
                if(Language == 6){
                    setKashi('আপনার ভিসার মেয়াদ শেষ হওয়ার তিন মাস আগে আপনাকে অবশ্যই নবায়ন করতে হবে।');
                }
            }
            if(counter >= 20.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // ビザとアルバイト
            if(counter >= 22){
                if(Language == 0){
                    setKashi('ビザとアルバイト');
                }
                if(Language == 1){
                    setKashi('Visa and part-time job');
                }
                if(Language == 2){
                    setKashi('簽證和兼職工作');
                }
                if(Language == 3){
                    setKashi('भिसा र पार्ट टाइम काम');
                }
                if(Language == 4){
                    setKashi('වීසා සහ අර්ධකාලීන රැකියාව');
                }
                if(Language == 5){
                    setKashi('ဗီဇာနှင့် အချိန်ပိုင်းအလုပ်');
                }
                if(Language == 6){
                    setKashi('ভিসা এবং খণ্ডকালীন চাকরি');
                }
            }
            if(counter >= 25){
                if(Language == 0){
                    setKashi('出席と成績がよくて、アルバイトの時間をまもっている人は、');
                }
                if(Language == 1){
                    setKashi('Students who have good attendance, good grades, and follow the rules at their part-time jobs');
                }
                if(Language == 2){
                    setKashi('出席率高、成績好、準時兼職的人');
                }
                if(Language == 3){
                    setKashi('राम्रो हाजिरी, राम्रो ग्रेड, र　पार्ट टाइमको नियमपालना　गर्नेहरूले');
                }
                if(Language == 4){
                    setKashi('හොඳ පැමිණීමක්, හොඳ ලකුණු ඇති සහ ඔවුන්ගේ අර්ධකාලීන රැකියා සඳහා නියමිත වේලාවට සිටින අය වේ');
                }
                if(Language == 5){
                    setKashi('ကျောင်းတက်ချိန်မှန်၊ အမှတ်ကောင်းကောင်းရပြီး အချိန်ပိုင်းအလုပ်အတွက် အချိန်မှန်နေသူများဖြစ်သည်။');
                }
                if(Language == 6){
                    setKashi('যাদের উপস্থিতি ভালো, ভালো গ্রেড এবং পার্ট টাইম কাজের সময় নিয়ম মেনে তারা ।');
                }
            }
            if(counter >= 32){
                if(Language == 0){
                    setKashi('長いビザをもらうことができます。');
                }
                if(Language == 1){
                    setKashi('can receive a longer term of VISA.');
                }
                if(Language == 2){
                    setKashi('您可以獲得長期簽證。');
                }
                if(Language == 3){
                    setKashi('लामो भिसा प्राप्त गर्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබට දිගු වීසා බලපත්රයක් ලබා ගත හැකිය.');
                }
                if(Language == 5){
                    setKashi('ဗီဇာ အရှည်ကြီး ရနိုင်ပါတယ်။');
                }
                if(Language == 6){
                    setKashi('দীর্ঘ ভিসা পাবেন');
                }
            }
            if(counter >= 34.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 35.8){
                if(Language == 0){
                    setKashi('出席と成績が悪い人、');
                }
                if(Language == 1){
                    setKashi('Students with lack of attendance and poor grades');
                }
                if(Language == 2){
                    setKashi('出席率和成績較差的人');
                }
                if(Language == 3){
                    setKashi('नराम्रो　हाजिरी र ग्रेड भएका मानिसहरू');
                }
                if(Language == 4){
                    setKashi('අඩු පැමිණීම් සහ  අඩු ලකුණු ඇති පුද්ගලයින්');
                }
                if(Language == 5){
                    setKashi('တက်ရောက်သူနှင့် အတန်းညံ့သူများ');
                }
                if(Language == 6){
                    setKashi('দুর্বল উপস্থিতি এবং গ্রেড সহ মানুষ');
                }
            }
            if(counter >= 40){
                if(Language == 0){
                    setKashi('アルバイトの時間が長すぎる人は');
                }
                if(Language == 1){
                    setKashi('Students who work part-time jobs too much');
                }
                if(Language == 2){
                    setKashi('兼職工作時間超過規定的人');
                }
                if(Language == 3){
                    setKashi('धेरै लामो समय पार्टटाइम काम गर्ने मानिसहरू');
                }
                if(Language == 4){
                    setKashi('වැඩි කාලයක් අර්ධකාලීනව වැඩ කරන අය');
                }
                if(Language == 5){
                    setKashi('အချိန်ပိုင်း အကြာကြီး အလုပ်လုပ်နေသူများ');
                }
                if(Language == 6){
                    setKashi('যারা খুব বেশি সময় ধরে পার্টটাইম কাজ করে');
                }
            }
            if(counter >= 44){
                if(Language == 0){
                    setKashi('ビザがもらえないかもしれません。');
                }
                if(Language == 1){
                    setKashi('Might not receive a visa');
                }
                if(Language == 2){
                    setKashi('您可能無法獲得簽證');
                }
                if(Language == 3){
                    setKashi('तपाईंले भिसा नपाउन सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබට වීසා බලපත්‍රයක් නොලැබෙනු ඇත');
                }
                if(Language == 5){
                    setKashi('ဗီဇာမရပါ။');
                }
                if(Language == 6){
                    setKashi('আপনি ভিসা নাও পেতে পারেন');
                }
            }
            if(counter >= 48){
                if(Language == 0){
                    setKashi('ルールをまもってはたらいてください。');
                }
                if(Language == 1){
                    setKashi('Please follow the rules and work.');
                }
                if(Language == 2){
                    setKashi('請遵守規則並工作。');
                }
                if(Language == 3){
                    setKashi('कृपया नियमहरू पालना गर्नुहोस् र काम गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර නීති රීති අනුගමනය කර වැඩ කරන්න.');
                }
                if(Language == 5){
                    setKashi('စည်းကမ်းချက်တွေကို လိုက်နာပြီး အလုပ်လုပ်ပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে নিয়ম মেনে কাজ করুন।');
                }
            }
            if(counter >= 51.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 国民健康保険
            if(counter >= 54.3){
                if(Language == 0){
                    setKashi('国民健康保険');
                }
                if(Language == 1){
                    setKashi('National Health Insurance');
                }
                if(Language == 2){
                    setKashi('國民健康保險');
                }
                if(Language == 3){
                    setKashi('राष्ट्रिय स्वास्थ्य बीमा');
                }
                if(Language == 4){
                    setKashi('ජාතික සෞඛ්ය රක්ෂණය');
                }
                if(Language == 5){
                    setKashi('အမျိုးသားကျန်းမာရေးအာမခံ');
                }
                if(Language == 6){
                    setKashi('জাতীয় স্বাস্থ্য বীমা');
                }
            }
            if(counter >= 57.4){
                if(Language == 0){
                    setKashi('日本に来たら、みなさん国民健康保険にもうしこまなければなりません。');
                }
                if(Language == 1){
                    setKashi('When you come to Japan, you have to apply for National Health Insurance.');
                }
                if(Language == 2){
                    setKashi('來日本時也必須加入國民健康保險。');
                }
                if(Language == 3){
                    setKashi('तपाईं जापान आउँदा, तपाईंले राष्ट्रिय स्वास्थ्य बीमाको लागि पनि आवेदन गर्नुपर्नेछ।');
                }
                if(Language == 4){
                    setKashi('ඔබ ජපානයට පැමිණෙන විට, ඔබට ජාතික සෞඛ්‍ය රක්‍ෂණය සඳහාද අයදුම් කිරීමට සිදුවේ.');
                }
                if(Language == 5){
                    setKashi('ဂျပန်ကို ရောက်တဲ့အခါ အမျိုးသားကျန်းမာရေး အာမခံကို လျှောက်ထားရမှာ ဖြစ်ပါတယ်။');
                }
                if(Language == 6){
                    setKashi('আপনি যখন জাপানে আসবেন, আপনাকে জাতীয় স্বাস্থ্য বীমার জন্য আবেদন করতে হবে।');
                }
            }
            if(counter >= 66){
                if(Language == 0){
                    setKashi('国民健康保険証があると、病院のお金が70％オフになります。');
                }
                if(Language == 1){
                    setKashi('If you have a National Health Insurance card, you can get 70% off on hospital fees.');
                }
                if(Language == 2){
                    setKashi('如果持有國民健康保險證，醫療費可享70％的折扣。');
                }
                if(Language == 3){
                    setKashi('यदि तपाईंसँग राष्ट्रिय स्वास्थ्य बीमा कार्ड छ भने, तपाईंले अस्पताल शुल्कमा 70% छुट पाउन सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබට ජාතික සෞඛ්‍ය රක්‍ෂණ කාඩ්පතක් තිබේ නම්, ඔබට රෝහල් ගාස්තු මත 70% ක වට්ටමක් ලබා ගත හැකිය.');
                }
                if(Language == 5){
                    setKashi('သင့်တွင် အမျိုးသားကျန်းမာရေးအာမခံကတ်တစ်ခုရှိလျှင် ဆေးရုံစရိတ် 70% လျှော့စျေးကို ရရှိနိုင်ပါသည်။');
                }
                if(Language == 6){
                    setKashi('আপনার যদি ন্যাশনাল হেলথ ইন্স্যুরেন্স কার্ড থাকে, তাহলে আপনি হাসপাতালের ফিতে 70% ছাড় পেতে পারেন।');
                }
            }
            if(counter >= 73.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 年金
            if(counter >= 75.7){
                if(Language == 0){
                    setKashi('年金');
                }
                if(Language == 1){
                    setKashi('Pension');
                }
                if(Language == 2){
                    setKashi('退休金');
                }
                if(Language == 3){
                    setKashi('पेन्सन（वृदा भत्ता）');
                }
                if(Language == 4){
                    setKashi('විශ්රාම වැටුප්');
                }
                if(Language == 5){
                    setKashi('ပင်စင်');
                }
                if(Language == 6){
                    setKashi('পেনশন');
                }
            }
            if(counter >= 79){
                if(Language == 0){
                    setKashi('日本では、２０歳になったら年金をはらわなければなりません。');
                }
                if(Language == 1){
                    setKashi('In Japan, you have to pay your pension when you turn 20 years old.');
                }
                if(Language == 2){
                    setKashi('在日本，年滿20歲就必須繳交退休金。');
                }
                if(Language == 3){
                    setKashi('जापानमा २० वर्ष पुगेपछि पेन्सन तिर्नुपर्छ।');
                }
                if(Language == 4){
                    setKashi('ජපානයේ, ඔබට වයස අවුරුදු 20 සම්පූර්ණ වන විට විශ්රාම වැටුපක් ගෙවිය යුතුය.');
                }
                if(Language == 5){
                    setKashi('ဂျပန်မှာ အသက် 20 ပြည့်ရင် ပင်စင်ပေးရမယ်။');
                }
                if(Language == 6){
                    setKashi('জাপানে, আপনার বয়স 20 বছর হলে আপনাকে পেনশন দিতে হবে।');
                }
            }
            if(counter >= 86){
                if(Language == 0){
                    setKashi('でも、学生のときは年金をはらわなくてもいいです。');
                }
                if(Language == 1){
                    setKashi("But when you're a student, you don't have to pay for your pension.");
                }
                if(Language == 2){
                    setKashi('但是，當您是學生時，您無需支付退休金。');
                }
                if(Language == 3){
                    setKashi('यद्यपि, जब तपाईं विद्यार्थी हुनुहुन्छ, तपाईंले आफ्नो पेन्सनको लागि तिर्नुपर्ने छैन।');
                }
                if(Language == 4){
                    setKashi('කෙසේ වෙතත්, ඔබ ශිෂ්‍යයෙකු වූ විට, ඔබේ විශ්‍රාම වැටුප සඳහා ගෙවිය යුතු නැත.');
                }
                if(Language == 5){
                    setKashi('သို့သော် သင်သည် ကျောင်းသားဖြစ်သောအခါတွင် သင်၏ပင်စင်အတွက် ပေးဆောင်ရန် မလိုအပ်ပါ။');
                }
                if(Language == 6){
                    setKashi('কিন্তু আপনি যখন একজন ছাত্র, আপনাকে আপনার পেনশনের জন্য অর্থ প্রদান করতে হবে না।');
                }
            }
            if(counter >= 90.5){
                if(Language == 0){
                    setKashi('区役所で年金免除の申し込みをしてください。');
                }
                if(Language == 1){
                    setKashi('You need to apply for pension exemption at the ward office.');
                }
                if(Language == 2){
                    setKashi('請向居住地的區役所申請年金（退休金）減免。');
                }
                if(Language == 3){
                    setKashi('कृपया आफ्नो वार्ड कार्यालयमा पेन्सन छुटको लागि आवेदन दिनुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබේ  ප්‍රාදේශීය සභාවේ දීවිශ්‍රාම වැටුප් නිදහස් කිරීම සඳහා අයදුම් කරන්න.');
                }
                if(Language == 5){
                    setKashi('သင့်ရပ်ကွက်ရုံးတွင် ပင်စင်ကင်းလွတ်ခွင့်အတွက် လျှောက်ထားပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে ওয়ার্ড অফিসে পেনশন  জন্য আবেদন করুন।');
                }
            }
            if(counter >= 95.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // ビザと給与明細
            if(counter >= 97.5){
                if(Language == 0){
                    setKashi('ビザと給与明細');
                }
                if(Language == 1){
                    setKashi('Visa and pay slip');
                }
                if(Language == 2){
                    setKashi('簽證和薪資單');
                }
                if(Language == 3){
                    setKashi('भिसा र पे स्लिप');
                }
                if(Language == 4){
                    setKashi('වීසා සහ ගෙවීම් පත්‍රිකාව');
                }
                if(Language == 5){
                    setKashi('ဗီဇာနှင့် လစာစလစ်');
                }
                if(Language == 6){
                    setKashi('ভিসা এবং পে স্লিপ');
                }
            }
            if(counter >= 100.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 102.2){
                if(Language == 0){
                    setKashi('毎月、アルバイトの給与明細を持って来てください。');
                }
                if(Language == 1){
                    setKashi('Please submit your part-time job pay slip every month at school office.');
                }
                if(Language == 2){
                    setKashi('請攜帶每月的兼職薪資單。');
                }
                if(Language == 3){
                    setKashi('कृपया हरेक महिना आफ्नो पार्ट टाइम कामको पे स्लिप ल्याउनुहोस्।');
                }
                if(Language == 4){
                    setKashi('සෑම මසකම ඔබගේ අර්ධකාලීන රැකියා වැටුප් පත්‍රිකාව රැගෙන එන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးပြု၍ လစဉ် အချိန်ပိုင်းအလုပ် လစာစာရွက်ကို ယူဆောင်လာပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে প্রতি মাসে আপনার খণ্ডকালীন চাকরির বেতন স্লিপ আনুন।');
                }
            }
            if(counter >= 108.3){
                if(Language == 0){
                    setKashi('給与明細がないとき、つぎのビザを作ることができません。');
                }
                if(Language == 1){
                    setKashi('If you do not submit a pay slip, you cannot apply for the next visa.');
                }
                if(Language == 2){
                    setKashi('如果您沒有薪資單，您將無法續簽簽證。');
                }
                if(Language == 3){
                    setKashi('यदि तपाईंसँग पे स्लिप छैन भने, तपाईंले अर्को भिसाको लागि आवेदन दिन सक्नुहुने छैन।');
                }
                if(Language == 4){
                    setKashi('ඔබට වැටුප් පත්‍රිකාවක් නොමැති නම්, ඔබට වෙනත් වීසා බලපත්‍රයක් සඳහා අයදුම් කිරීමට නොහැකි වනු ඇත.');
                }
                if(Language == 5){
                    setKashi('အကယ်၍ သင့်တွင် လစာစလစ်မရှိပါက အခြားဗီဇာကို လျှောက်ထားနိုင်မည်မဟုတ်ပေ။');
                }
                if(Language == 6){
                    setKashi('যদি আপনার পে স্লিপ না থাকে, তাহলে আপনি পরবর্তী ভিসার জন্য আবেদন করতে পারবেন না।');
                }
            }
            if(counter >= 113.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 出席とビザ
            if(counter >= 116){
                if(Language == 0){
                    setKashi('出席とビザ');
                }
                if(Language == 1){
                    setKashi('Attendance and visa');
                }
                if(Language == 2){
                    setKashi('出席和簽證');
                }
                if(Language == 3){
                    setKashi('उपस्थिति र भिसा');
                }
                if(Language == 4){
                    setKashi('පැමිණීම සහ වීසා');
                }
                if(Language == 5){
                    setKashi('တက်ရောက်ခွင့်နှင့် ဗီဇာ');
                }
                if(Language == 6){
                    setKashi('উপস্থিতি এবং ভিসা');
                }
            }
            if(counter >= 119){
                if(Language == 0){
                    setKashi('出席が悪い人は、つぎのビザができません。');
                }
                if(Language == 1){
                    setKashi('Students who have lack of attendance will not be able to get the next visa.');
                }
                if(Language == 2){
                    setKashi('出席率低的人將無法續簽簽證。');
                }
                if(Language == 3){
                    setKashi('कम उपस्थिति भएकाहरूले अर्को भिसा पाउन सक्ने छैनन्।');
                }
                if(Language == 4){
                    setKashi('අඩු පැමිණීමක් ඇති අයට වෙනත් වීසා බලපත්‍රයක් ලබා ගැනීමට නොහැකි වනු ඇත.');
                }
                if(Language == 5){
                    setKashi('တက်ရောက်သူ ချို့တဲ့သူများသည် အခြားဗီဇာကို ရရှိနိုင်မည်မဟုတ်ပေ။');
                }
                if(Language == 6){
                    setKashi('যাদের উপস্থিতি কম তারা পরবর্তী ভিসা পেতে সক্ষম হবে না।');
                }
            }
            if(counter >= 123.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 125.3){
                if(Language == 0){
                    setKashi('就職や進学もできません。');
                }
                if(Language == 1){
                    setKashi("you can't work or move to higher education.");
                }
                if(Language == 2){
                    setKashi('無法找到工作或接受高等教育');
                }
                if(Language == 3){
                    setKashi('वर्किङ गर्न र　उच्च शिक्षामा　जान सक्नुहुने छैन।');
                }
                if(Language == 4){
                    setKashi('රැකියාවක් කිරීමට හෝ උසස් අධ්‍යාපනයට යාමට නොහැකි වීම');
                }
                if(Language == 5){
                    setKashi('အလုပ်ရနိုင်သလို အထက်တန်းပညာကိုလည်း မတက်နိုင်ပါ။');
                }
                if(Language == 6){
                    setKashi('সেই ব্যক্তি চাকরি পাবে না বা উচ্চ শিক্ষায় যেতে পারবে না।');
                }
            }
            if(counter >= 128.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 130.2){
                if(Language == 0){
                    setKashi('出席が80%より悪くなると、つぎのビザがみじかくなるかもしれません。');
                }
                if(Language == 1){
                    setKashi('If your attendance is less than 80%, your next visa may be for a short period of time.');
                }
                if(Language == 2){
                    setKashi('如果您的出席率低於 80%，下次續簽簽證時，在留期限可能會縮短。');
                }
                if(Language == 3){
                    setKashi('यदि तपाईंको उपस्थिति 80% भन्दा खराब छ भने, तपाईंको अर्को भिसा खतरामा हुन सक्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබගේ පැමිණීම 80% ට වඩා නරක නම්, ඔබගේ මීළඟ වීසා බලපත්‍රය අනතුරේ විය හැක.');
                }
                if(Language == 5){
                    setKashi('သင်တက်ရောက်သူ 80% ထက် ပိုဆိုးပါက သင်၏နောက်ထပ်ဗီဇာသည် အန္တရာယ်ရှိနိုင်ပါသည်။');
                }
                if(Language == 6){
                    setKashi('যদি আপনার উপস্থিতি 80% এর চেয়ে খারাপ হয়, তাহলে আপনার পরবর্তী ভিসা বিপদে পড়তে পারে।');
                }
            }
            if(counter >= 138.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 140.3){
                if(Language == 0){
                    setKashi('ビザのルールはどんどんきびしくなっています。');
                }
                if(Language == 1){
                    setKashi('Visa rules are becoming rigid.');
                }
                if(Language == 2){
                    setKashi('簽證規則變得越來越嚴格。');
                }
                if(Language == 3){
                    setKashi('भिसा नियम झन कडा हुँदै गएको छ ।');
                }
                if(Language == 4){
                    setKashi('වීසා නීති එන්න එන්නම දැඩි වෙමින් පවතී.');
                }
                if(Language == 5){
                    setKashi('ဗီဇာစည်းကမ်းတွေက ပိုတင်းကျပ်လာတယ်။ အချိန်ပိုင်းအလုပ်');
                }
                if(Language == 6){
                    setKashi('ভিসার নিয়ম আরও কঠোর থেকে কঠোর হচ্ছে।');
                }
            }
            if(counter >= 144.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // アルバイト
            if(counter >= 146.7){
                if(Language == 0){
                    setKashi('アルバイト');
                }
                if(Language == 1){
                    setKashi('Part-time job');
                }
                if(Language == 2){
                    setKashi('兼職工作');
                }
                if(Language == 3){
                    setKashi('पार्ट टाइम काम');
                }
                if(Language == 4){
                    setKashi('අර්ධ කාලීන රැකියාව');
                }
                if(Language == 5){
                    setKashi('အချိန်ပိုင်းအလုပ်');
                }
                if(Language == 6){
                    setKashi('খন্ডকালীন চাকরী');
                }
            }
            if(counter >= 150){
                if(Language == 0){
                    setKashi('日本でアルバイトをするとき、');
                }
                if(Language == 1){
                    setKashi('When working part-time job in Japan,');
                }
                if(Language == 2){
                    setKashi('在日本打工時，');
                }
                if(Language == 3){
                    setKashi('जापानमा पार्टटाइम काम गर्दा,');
                }
                if(Language == 4){
                    setKashi('ජපානයේ අර්ධකාලීන වැඩ කරන විට,');
                }
                if(Language == 5){
                    setKashi('ဂျပန်မှာ အချိန်ပိုင်းအလုပ်လုပ်တဲ့အခါ၊');
                }
                if(Language == 6){
                    setKashi('জাপানে খণ্ডকালীন কাজ করার সময়,');
                }
            }
            if(counter >= 153.2){
                if(Language == 0){
                    setKashi('在留カードとマイナンバーカードを見せます。');
                }
                if(Language == 1){
                    setKashi('Present your residence card and My Number card.');
                }
                if(Language == 2){
                    setKashi('請出示在留卡和個人編號卡。');
                }
                if(Language == 3){
                    setKashi('आफ्नो निवास कार्ड र MY NUMBER कार्ड देखाउनुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබේ පදිංචි කාඩ්පත සහ මගේ අංක කාඩ්පත පෙන්වන්න.');
                }
                if(Language == 5){
                    setKashi('သင်၏နေထိုင်ခွင့်ကတ်နှင့် ကျွန်ုပ်၏နံပါတ်ကတ်ကို ပြပါ။');
                }
                if(Language == 6){
                    setKashi('আপনার আবাসিক কার্ড এবং আমার নম্বর কার্ড দেখান।');
                }
            }
            if(counter >= 157){
                if(Language == 0){
                    setKashi('入管はマイナンバーを調べて、その人のアルバイトの会社や給料を調べることができます。');
                }
                if(Language == 1){
                    setKashi("The Immigration Bureau can check a person's My Number and find out the company and salary of that person's part-time job.");
                }
                if(Language == 2){
                    setKashi('入國管理局可以檢查一個人的編號，並找出該人打工的公司和工資。');
                }
                if(Language == 3){
                    setKashi('अध्यागमन अधिकारीहरूले एक व्यक्तिको MY NUMBER जाँच गरी त्यो व्यक्तिको पार्ट टाइम काम， कम्पनी र तलब पत्ता लगाउन सक्छन्।');
                }
                if(Language == 4){
                    setKashi('ආගමන බලධාරීන්ට පුද්ගලයෙකුගේ මගේ අංකය පරීක්ෂා කර එම පුද්ගලයාගේ අර්ධකාලීන රැකියාවේ සමාගම සහ වැටුප සොයා ගත හැකිය.');
                }
                if(Language == 5){
                    setKashi('လူဝင်မှုကြီးကြပ်ရေး အာဏာပိုင်များသည် လူတစ်ဦး၏ My Number ကို စစ်ဆေးနိုင်ပြီး ထိုလူ၏ အချိန်ပိုင်းအလုပ်၏ ကုမ္ပဏီနှင့် လစာကို ရှာဖွေနိုင်သည်။');
                }
                if(Language == 6){
                    setKashi('অভিবাসন কর্তৃপক্ষ তাদের খণ্ডকালীন চাকরির কোম্পানি এবং বেতন জানতে ছাত্রের মাই নম্বর ব্যবহার করতে পারে।');
                }
            }
            if(counter >= 165.5){
                if(Language == 0){
                    setKashi('学校にひみつでアルバイトをしたとき、つぎのビザがもらえないかもしれません。');
                }
                if(Language == 1){
                    setKashi('If you work part-time without informing the school, you may not be able to get another visa.');
                }
                if(Language == 2){
                    setKashi('如果您在學校秘密兼職，您可能無法續簽簽證。');
                }
                if(Language == 3){
                    setKashi('यदि तपाईंले　विद्यालयमा जानकारी नदिइ गोप्य रूपमा  काम गर्नुहुन्छ भने, तपाईंले अर्को भिसा प्राप्त गर्न सक्नुहुन्न।');
                }
                if(Language == 4){
                    setKashi('ඔබ රහසේ පාසැලේ අර්ධකාලීන රැකියාවක් කරන්නේ නම්, ඔබට වෙනත් වීසා බලපත්‍රයක් ලබා ගැනීමට නොහැකි වනු ඇත.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းတွင် အချိန်ပိုင်း တိတ်တဆိတ် အလုပ်လုပ်ပါက အခြားဗီဇာ မရနိုင်ပါ။');
                }
                if(Language == 6){
                    setKashi('আপনি যদি স্কুলে একটি গোপন খণ্ডকালীন চাকরি করেন, তাহলে আপনি অন্য ভিসা পেতে সক্ষম হবেন না।');
                }
            }
            if(counter >= 173.5){
                if(Language == 0){
                    setKashi('アルバイトをはじめるとき、必ずじむしょの先生に話してください。');
                }
                if(Language == 1){
                    setKashi('When you start a part-time job, make sure to talk to your teacher at the office.');
                }
                if(Language == 2){
                    setKashi('當您開始兼職工作時，請務必告訴辦公室的老師。');
                }
                if(Language == 3){
                    setKashi('पार्ट टाइम काम सुरु गर्दा　तपाइँकोअफिसको शिक्षकलाई बताउनुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබ අර්ධකාලීන රැකියාවක් ආරම්භ කරන විට, කාර්යාලයේදී ඔබේ ගුරුවරයාට පැවසීමට වග බලා ගන්න.');
                }
                if(Language == 5){
                    setKashi('အချိန်ပိုင်းအလုပ်စလုပ်တဲ့အခါ ရုံးမှာ သင့်ဆရာကို သေချာပြောပြပါ။');
                }
                if(Language == 6){
                    setKashi('আপনি যখন একটি খণ্ডকালীন চাকরি শুরু করেন, অফিসে আপনার শিক্ষকের সাথে কথা বলতে ভুলবেন না।');
                }
            }
            if(counter >= 182.5){
                if(Language == 0){
                    setKashi('問題があるときは、すぐにそうだんしてください。');
                }
                if(Language == 1){
                    setKashi('If you have a problem, please consult your teacher immediately.');
                }
                if(Language == 2){
                    setKashi('如果您有任何問題，請立即與我們聯繫。');
                }
                if(Language == 3){
                    setKashi('यदि तपाईलाई समस्या छ भने, कृपया  तुरुन्तै  शिक्षकलाई बताउनुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබට ගැටලුවක් ඇත්නම් වහාම එය නිවැරදි කරන්න.');
                }
                if(Language == 5){
                    setKashi('ပြဿနာရှိပါက ဆရာများနှင့်ချက်ချင်းတိုင်ပင်ပါ');
                }
                if(Language == 6){
                    setKashi('যদি আপনার কোন সমস্যা থাকে, অনুগ্রহ করে তা অবিলম্বে সমাধান করুন।');
                }
            }
            if(counter >= 188){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            // アルバイト

            if(counter >= 191){
                if(Language == 0){
                    setKashi('アルバイト');
                }
                if(Language == 1){
                    setKashi('part-time job');
                }
                if(Language == 2){
                    setKashi('兼職工作');
                }
                if(Language == 3){
                    setKashi('आंसिक काम');
                }
                if(Language == 4){
                    setKashi('අර්ධ කාලීන රැකියාව');
                }
                if(Language == 5){
                    setKashi('အချိန်ပိုင်းအလုပ်');
                }
                if(Language == 6){
                    setKashi('খন্ডকালীন চাকরী');
                }
            }
            if(counter >= 194.2){
                if(Language == 0){
                    setKashi('これからアルバイトの話をします。');
                }
                if(Language == 1){
                    setKashi("I'm going to explain about part-time jobs.");
                }
                if(Language == 2){
                    setKashi('現在我將解釋一下兼職工作。');
                }
                if(Language == 3){
                    setKashi('अब म  पार्ट टाइम कामबारेमा कुरा गर्नेछु।');
                }
                if(Language == 4){
                    setKashi('මම දැන් අර්ධකාලීන රැකියා ගැන කතා කරන්නම්.');
                }
                if(Language == 5){
                    setKashi('အခုပြောပြမယ့် အချိန်ပိုင်းအလုပ်တွေအကြောင်း။');
                }
                if(Language == 6){
                    setKashi('আমি খণ্ডকালীন চাকরি সম্পর্কে কথা বলতে যাচ্ছি।');
                }
            }
            if(counter >= 197.5){
                if(Language == 0){
                    setKashi('アルバイトを学校にないしょですることは、絶対にだめです。');
                }
                if(Language == 1){
                    setKashi('It is absolutely not allowed to work part-time without informing the school.');
                }
                if(Language == 2){
                    setKashi('絕對禁止不報到學校打工。');
                }
                if(Language == 3){
                    setKashi('विद्यालयमा जानकारी नदिइ पार्ट टाइम काम गर्न पूर्ण रूपमा निषेध गरिएको छ।');
                }
                if(Language == 4){
                    setKashi('පාසැලෙන් පිටත අර්ධකාලීනව වැඩ කිරීම සපුරා තහනම්ය.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းအပြင်မှာ အချိန်ပိုင်းအလုပ်လုပ်ဖို့ လုံးဝတားမြစ်ထားပါတယ်။');
                }
                if(Language == 6){
                    setKashi('স্কুলের বাইরে খণ্ডকালীন কাজ করা একেবারেই নিষিদ্ধ।');
                }
            }
            if(counter >= 203.5){
                if(Language == 0){
                    setKashi('始めるときとやめるときは、じむしょの先生にかならずそうだんしてください。');
                }
                if(Language == 1){
                    setKashi('Make sure to inform your teacher at the office when starting or quitting your part-time job.');
                }
                if(Language == 2){
                    setKashi('開始或停止時請務必諮詢辦公室的老師。');
                }
                if(Language == 3){
                    setKashi('पार्ट टाइम काम सुरुगर्दा　  अथवा  छोद्दा कार्यालयमा आफ्नो शिक्षकसँग परामर्श गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ආරම්භ කරන විට හෝ නතර කරන විට කාර්යාලයේදී ඔබේ ගුරුවරයා සමඟ සාකච්ඡා කිරීමට වග බලා ගන්න.');
                }
                if(Language == 5){
                    setKashi('စတင်ချိန် သို့မဟုတ် ရပ်သည့်အခါ ရုံးတွင် သင့်ဆရာနှင့် တိုင်ပင်ရန် သေချာပါစေ။');
                }
                if(Language == 6){
                    setKashi('শুরু বা বন্ধ করার সময় অফিসে শিক্ষকের সাথে পরামর্শ করতে ভুলবেন না।');
                }
            }
            if(counter >= 210.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 213.5){
                if(Language == 0){
                    setKashi('学校があるときは、1週間28時間以内、');
                }
                if(Language == 1){
                    setKashi('When there is school, students can work for only 28 hours per week,');
                }
                if(Language == 2){
                    setKashi('學校上課時，每週 28 小時內、');
                }
                if(Language == 3){
                    setKashi('जब विद्यालय सत्रमा छ, प्रति हप्ता 28 घण्टा सम्म।');
                }
                if(Language == 4){
                    setKashi('පාසැල පැවැත්වෙන විට, සතියකට පැය 28ක් ඇතුළත.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းတက်ချိန်၊ တစ်ပတ်လျှင် ၂၈ နာရီအတွင်း။');
                }
                if(Language == 6){
                    setKashi('যখন স্কুল সেশনে থাকে, প্রতি সপ্তাহে 28 ঘন্টার মধ্যে,');
                }
            }
            if(counter >= 220){
                if(Language == 0){
                    setKashi('長い休みのときは、1週間40時間以内です。');
                }
                if(Language == 1){
                    setKashi('During long holidays, students can work less than 40 hours in a week.');
                }
                if(Language == 2){
                    setKashi('長假期間，您每週最多可工作 40 小時。');
                }
                if(Language == 3){
                    setKashi('लामो बिदाहरूमा, तपाइँ हप्तामा 40 घण्टा सम्म काम गर्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('දිගු නිවාඩු කාලය තුළ, ඔබට සතියකට පැය 40 ක් දක්වා වැඩ කළ හැකිය.');
                }
                if(Language == 5){
                    setKashi('အားလပ်ရက်ရှည်များအတွင်း တစ်ပတ်လျှင် နာရီ ၄၀ အထိ အလုပ်လုပ်နိုင်သည်။');
                }
                if(Language == 6){
                    setKashi('দীর্ঘ ছুটির সময়, এটি সপ্তাহে 40 ঘন্টারও কম।');
                }
            }
            if(counter >= 225.7){
                if(Language == 0){
                    setKashi('かならずまもってください。');
                }
                if(Language == 1){
                    setKashi('Make sure to follow the rules!');
                }
                if(Language == 2){
                    setKashi('請務必遵守此規則！');
                }
                if(Language == 3){
                    setKashi('पार्ट टाइमको नियमपालना गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('එම නීතීය අනුගමනය කීරීමට වග බලා ගන්න!');
                }
                if(Language == 5){
                    setKashi('သေချာကာကွယ်ပါ။');
                }
                if(Language == 6){
                    setKashi('নিয়ম অনুসরণ করুন.');
                }
            }
            if(counter >= 228.2){
                if(Language == 0){
                    setKashi('きけんなアルバイト、');
                }
                if(Language == 1){
                    setKashi('These are the part-time jobs not allowed for students,');
                }
                if(Language == 2){
                    setKashi('不從事危險兼職、');
                }
                if(Language == 3){
                    setKashi('जापानमा बिर्द्याथीले गर्न नमिल्ने पार्ट टाइम कामहरूः-');
                }
                if(Language == 4){
                    setKashi('භයානක අර්ධකාලීන රැකියාවක්');
                }
                if(Language == 5){
                    setKashi('အန္တရာယ်ရှိသော အချိန်ပိုင်းအလုပ်');
                }
                if(Language == 6){
                    setKashi('বিপজ্জনক খণ্ডকালীন চাকরি,');
                }
            }
            if(counter >= 230.5){
                if(Language == 0){
                    setKashi('健康に悪いアルバイト、');
                }
                if(Language == 1){
                    setKashi('Part-time job that is bad for your health.');
                }
                if(Language == 2){
                    setKashi('不健康兼職、');
                }
                if(Language == 3){
                    setKashi('पार्ट टाइम कामहरू जुन तपाईंको स्वास्थ्यको लागि हानिकारक छ।');
                }
                if(Language == 4){
                    setKashi('ඔබේ සෞඛ්‍යයට අහිතකර අර්ධකාලීන රැකියා');
                }
                if(Language == 5){
                    setKashi('ကျန်းမာရေးအတွက် မကောင်းသော အချိန်ပိုင်းအလုပ်များ');
                }
                if(Language == 6){
                    setKashi('খণ্ডকালীন চাকরি যা আপনার স্বাস্থ্যের জন্য খারাপ।');
                }
            }
            if(counter >= 233.5){
                if(Language == 0){
                    setKashi('スナック、ギャンブルなどのアルバイトをしてはいけません。');
                }
                if(Language == 1){
                    setKashi('Part-time job at hostess bars, gambling, etc.');
                }
                if(Language == 2){
                    setKashi('酒吧工作、賭博工作等。');
                }
                if(Language == 3){
                    setKashi('बार, क्लब, पब वा जुवा खेल्ने जस्ता  ठाउँहरूमा काम　नगर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ආහාර බෙදාහැරීම හෝ සූදුව වැනි අර්ධකාලීන රැකියාවල නිරත නොවන්න.');
                }
                if(Language == 5){
                    setKashi('အဆာပြေစားခြင်း သို့မဟုတ် လောင်းကစားခြင်းကဲ့သို့သော အချိန်ပိုင်းအလုပ်များတွင် မပါဝင်ပါနှင့်။');
                }
                if(Language == 6){
                    setKashi('খন্ডকালীন কাজ করবেন না যেমন স্ন্যাকিং বা জুয়া খেলা।');
                }
            }
            if(counter >= 238.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 239.8){
                if(Language == 0){
                    setKashi('Uber Eatsなどはアルバイトの時間がわかりにくいのでだめです。');
                }
                if(Language == 1){
                    setKashi('Do not work part-time jobs such as Uber Eats, as the working hours are difficult to count.');
                }
                if(Language == 2){
                    setKashi('由於難以了解工作時間，因此禁止在 Uber Eats 等公司打工。');
                }
                if(Language == 3){
                    setKashi('Uber Eats आदिमा　काम गरेको समय र घण्टा निश्चित नहुने भएकोले काम　नगर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('Uber Eats යනාදිය භාවිතා නොකරන්නේ මගේ අර්ධකාලීන රැකියාවේ නිරත  නියමිත වේලාවක් නියමිත දවසක් තීරණය කීරීමට  අපහසු නිසාය.');
                }
                if(Language == 5){
                    setKashi('ကျွန်ုပ်၏အချိန်ပိုင်းအလုပ်သည် မည်သည့်အချိန်ဖြစ်သည်ကို ပြောပြရန်ခက်ခဲသောကြောင့် Uber Eats စသည်တို့ကို ကျွန်ုပ်အသုံးမပြုပါ။');
                }
                if(Language == 6){
                    setKashi('আপনার UberEats ব্যবহার করা উচিত নয় কারণ আপনার খণ্ডকালীন চাকরি কখন হবে তা বলা কঠিন।');
                }
            }
            if(counter >= 245.5){
                if(Language == 0){
                    setKashi('アルバイトのルールをまもらないとき、ビザをもらうことができません。');
                }
                if(Language == 1){
                    setKashi('When students work for the prohibited jobs, they cannot renew their visa.');
                }
                if(Language == 2){
                    setKashi('如果學生從事禁止的兼職工作，他們將不會獲得簽證。');
                }
                if(Language == 3){
                    setKashi('पार्ट टाइमको नियमपालना　नगर्ने विद्यार्थीहरूले भिसा पाउन सक्ने छैनन्।');
                }
                if(Language == 4){
                    setKashi('ශිෂ්‍යයෙකු නරක අර්ධ-කාලීන රැකියාවක් කරන්නේ නම්, ඔවුන්ට වීසා බලපත්‍රයක් ලබා ගැනීමට නොහැකි වනු ඇත.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းသားသည် အချိန်ပိုင်းအလုပ်မကောင်းပါက ဗီဇာရနိုင်မည်မဟုတ်ပေ။');
                }
                if(Language == 6){
                    setKashi('যদি একজন ছাত্র নিষিদ্ধ একটি খণ্ডকালীন চাকরি নেয়, তাহলে তাদের ভিসা নবায়ন করা যাবে না।');
                }
            }
            if(counter >= 252.7){
                if(Language == 0){
                    setKashi('新しいアルバイトをするときは、このアルバイトは');
                }
                if(Language == 1){
                    setKashi('When starting a new part-time job, this part-time job should be');
                }
                if(Language == 2){
                    setKashi('當開始新的兼職工作時，這份兼職工作應該是、');
                }
                if(Language == 3){
                    setKashi('नयाँ पार्ट टाइम काम सुरु गर्दा, पार्ट टाइम कामहरूः ');
                }
                if(Language == 4){
                    setKashi('නව අර්ධකාලීන රැකියාවක් ආරම්භ කරන විට, මෙම අර්ධකාලීන රැකියාව විය යුතුය');
                }
                if(Language == 5){
                    setKashi('အချိန်ပိုင်းအလုပ်အသစ်တစ်ခုစလုပ်သောအခါ ဤအချိန်ပိုင်းအလုပ်ဖြစ်သင့်သည်။');
                }
                if(Language == 6){
                    setKashi('একটি নতুন খণ্ডকালীন চাকরি শুরু করার সময়, এই খণ্ডকালীন চাকরি হওয়া উচিত');
                }
            }
            if(counter >= 257.5){
                if(Language == 0){
                    setKashi('いいですか？だめですか？');
                }
                if(Language == 1){
                    setKashi('okay? or not?');
                }
                if(Language == 2){
                    setKashi('我可以？ 是不是不好啊？');
                }
                if(Language == 3){
                    setKashi('गर्न मिल्छ या मिल्दैन');
                }
                if(Language == 4){
                    setKashi('මම කරන්නද? ඒක හොඳ නැද්ද?');
                }
                if(Language == 5){
                    setKashi('ရမလား? မကောင်းဘူးလား?');
                }
                if(Language == 6){
                    setKashi('ঠিক আছে? এটা কি ভাল না?');
                }
            }
            if(counter >= 261){
                if(Language == 0){
                    setKashi('じむしょの先生にそうだんしてください。');
                }
                if(Language == 1){
                    setKashi('Please consult the teachers at the office.');
                }
                if(Language == 2){
                    setKashi('請諮詢辦公室老師。');
                }
                if(Language == 3){
                    setKashi('कृपया कार्यालयमा शिक्षकसँग परामर्श गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර කාර්යාලයේ ගුරුවරයා සමඟ සාකච්ඡා කරන්න.');
                }
                if(Language == 5){
                    setKashi('ရုံးမှာ ဆရာနဲ့ တိုင်ပင်ပါ။');
                }
                if(Language == 6){
                    setKashi('অফিসে শিক্ষকের সাথে পরামর্শ করুন।');
                }
            }
            if(counter >= 264.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            if(counter >= 266){
                setMoveVideoTime(3);
                document.getElementById('video').currentTime = 0;
            }
        }

        //　4本目　学生証
        if(MoveVideoTime == 3){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn MChBChange');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
            setChapterColor14('MoveChapBtn');

            if(counter >= 0){
                setMoveVideo(WSDB);
            }

            if(counter >= 0.5){
                if(Language == 0){
                    setKashi('学生証');
                }
                if(Language == 1){
                    setKashi('Student ID card');
                }
                if(Language == 2){
                    setKashi('學生卡');
                }
                if(Language == 3){
                    setKashi('विद्यार्थी कार्ड');
                }
                if(Language == 4){
                    setKashi('ශිෂ්ය කාඩ්පත');
                }
                if(Language == 5){
                    setKashi('ကျောင်းသားကတ်');
                }
                if(Language == 6){
                    setKashi('ছাত্র পত্র');
                }
            }
            if(counter >= 3.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 4.3){
                if(Language == 0){
                    setKashi('学生証はカードとWSDBアプリにあります。');
                }
                if(Language == 1){
                    setKashi('There are two types of student ID card: an ID card and WSDB app.');
                }
                if(Language == 2){
                    setKashi('您可以使用學生卡和 WSDB App。');
                }
                if(Language == 3){
                    setKashi('तपाईँहरूको विद्यार्थी कार्ड एउटा कार्डमा छ अर्को WSDB एप्पमा छ ।');
                }
                if(Language == 4){
                    setKashi('ශිෂ්‍ය හැඳුනුම්පත් සහ WSDB යෙදුම් තිබේ.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းသား ID ကတ်များနှင့် WSDB အက်ပ်များ ရှိပါသည်။');
                }
                if(Language == 6){
                    setKashi('স্টুডেন্ট আইডি কার্ড এবং WSDB অ্যাপস আছে।');
                }
            }
            if(counter >= 9.3){
                if(Language == 0){
                    setKashi('日本でじゅんびしたSIMがある人は、');
                }
                if(Language == 1){
                    setKashi('If you have a valid SIM in Japan,');
                }
                if(Language == 2){
                    setKashi('若您有在日本準備的 SIM 卡，');
                }
                if(Language == 3){
                    setKashi('जापानको  सिम लिनु भए');
                }
                if(Language == 4){
                    setKashi('ඔබට ජපානයේ භාවිතා කරන  සිම්පතක් තිබේ නම්,');
                }
                if(Language == 5){
                    setKashi('ဂျပန်မှာ ဆင်းမ်ကတ် ရှိပီးလျှင်၊');
                }
                if(Language == 6){
                    setKashi('আপনার যদি জাপানে একটি বৈধ সিম থাকে,');
                }
            }
            if(counter >= 12.3){
                if(Language == 0){
                    setKashi('あとからアプリを入れましょう。');
                }
                if(Language == 1){
                    setKashi("please install the WSDB app.");
                }
                if(Language == 2){
                    setKashi('請稍後安裝該App。');
                }
                if(Language == 3){
                    setKashi('पछि एप डाउनलोड गर्नुहोला।');
                }
                if(Language == 4){
                    setKashi('අපි පසුව යෙදුම ස්ථාපනය කරමු.');
                }
                if(Language == 5){
                    setKashi('အက်ပ်ကို downloadထည့်ထားရန်။');
                }
                if(Language == 6){
                    setKashi('পরে অ্যাপটি ইনস্টল করা যাক।');
                }
            }
            if(counter >= 14.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            //　WSDBアプリ
            if(counter >= 17.2){
                if(Language == 0){
                    setKashi('WSDBアプリ');
                }
                if(Language == 1){
                    setKashi('WSDB app');
                }
                if(Language == 2){
                    setKashi('WSDB App');
                }
                if(Language == 3){
                    setKashi('WSDB एप');
                }
                if(Language == 4){
                    setKashi('WSDB යෙදුම');
                }
                if(Language == 5){
                    setKashi('WSDB အက်ပ်');
                }
                if(Language == 6){
                    setKashi('WSDB অ্যাপ');
                }
            }
            if(counter >= 20.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 21){
                if(Language == 0){
                    setKashi('NSAでは、WSDBというアプリで学生証や出席を見ることができます。');
                }
                if(Language == 1){
                    setKashi('At NSA, you can check your student ID and attendance using an app called WSDB.');
                }
                if(Language == 2){
                    setKashi('在 NSA，您可以使用名為 WSDB 的App檢查您的學生證和出勤情況。');
                }
                if(Language == 3){
                    setKashi('NSA मा तपाईंले WSDB नामक एप प्रयोग गरेर आफ्नो विद्यार्थी परिचयपत्र र उपस्थिति जाँच गर्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('NSA හි, ඔබට WSDB නම් යෙදුමක් භාවිතයෙන් ඔබේ ශිෂ්‍ය හැඳුනුම්පත සහ පැමිණීම පරීක්ෂා කළ හැක.');
                }
                if(Language == 5){
                    setKashi('NSA တွင်၊ သင်သည် WSDB ဟုခေါ်သော အက်ပ်ကို အသုံးပြု၍ သင်၏ကျောင်းသား ID ကဒ်နှင့်အတန်းတက်ရောက်မှုကို စစ်ဆေးနိုင်သည်။');
                }
                if(Language == 6){
                    setKashi('NSA-তে, আপনি WSDB নামক একটি অ্যাপ ব্যবহার করে আপনার স্টুডেন্ট আইডি এবং উপস্থিতি পরীক্ষা করতে পারেন।');
                }
            }
            if(counter >= 27.6){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // アプリのダウンロード
            if(counter >= 29.5){
                if(Language == 0){
                    setKashi('アプリのダウンロード');
                }
                if(Language == 1){
                    setKashi('Download the app');
                }
                if(Language == 2){
                    setKashi('下載應用程式');
                }
                if(Language == 3){
                    setKashi('एप डाउनलोड गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('යෙදුම බාගන්න');
                }
                if(Language == 5){
                    setKashi('အက်ပ်ကို ဒေါင်းလုဒ်လုပ်ပါ။');
                }
                if(Language == 6){
                    setKashi('অ্যাপটি ডাউনলোড করুন');
                }
            }
            if(counter >= 32.6){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 33.7){
                if(Language == 0){
                    setKashi('日本でSIMカードを買ったら、WSDBアプリをダウンロードしてください。');
                }
                if(Language == 1){
                    setKashi('After purchasing a SIM card in Japan, download the WSDB app.');
                }
                if(Language == 2){
                    setKashi('在日本購買 SIM 卡後，請下載 WSDB App。');
                }
                if(Language == 3){
                    setKashi('एकपटक तपाईंले जापानमा सिम कार्ड किन्नुभएपछि, WSDB एप डाउनलोड गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබ ජපානයේ SIM කාඩ්පතක් මිලදී ගත් පසු, WSDB යෙදුම download කරගන්න');
                }
                if(Language == 5){
                    setKashi('ဂျပန်တွင် SIM ကတ်ဝယ်ပြီးသည်နှင့် WSDB အက်ပ်ကို ဒေါင်းလုဒ်လုပ်ပါ။');
                }
                if(Language == 6){
                    setKashi('আপনি যদি জাপানে একটি সিম কার্ড কিনে থাকেন, তাহলে WSDB অ্যাপ ডাউনলোড করুন।');
                }
            }
            if(counter >= 39.5){
                if(Language == 0){
                    setKashi('まず、IPhoneの人はApp Store、Androidの人はGoogle Playを開いてください。');
                }
                if(Language == 1){
                    setKashi('First, for iPhone users open the App Store, for Android users open Google Play.');
                }
                if(Language == 2){
                    setKashi('首先，iPhone用戶開啟App Store，Android用戶開啟Google Play。');
                }
                if(Language == 3){
                    setKashi('पहिले, आईफोन प्रयोगकर्ताहरूको लागि एप स्टोर खोल्नुहोस् र एन्ड्रोइड प्रयोगकर्ताहरूको लागि गुगल प्ले।');
                }
                if(Language == 4){
                    setKashi('පළමුව, iPhone භාවිතා කරන්නන් සඳහා App Store සහ Android භාවිතා කරන්නන් සඳහා Google Play විවෘත කරන්න.');
                }
                if(Language == 5){
                    setKashi('ပထမဦးစွာ iPhone အသုံးပြုသူများအတွက် App Store နှင့် Android အသုံးပြုသူများအတွက် Google Play ကိုဖွင့်ပါ။');
                }
                if(Language == 6){
                    setKashi('প্রথমে, আইফোন ব্যবহারকারীদের অ্যাপ স্টোর খুলতে হবে, অ্যান্ড্রয়েড ব্যবহারকারীদের গুগল প্লে খুলতে হবে।');
                }
            }
            if(counter >= 46.5){
                if(Language == 0){
                    setKashi('「WSDB」と検索してください。');
                }
                if(Language == 1){
                    setKashi('Search for "WSDB".');
                }
                if(Language == 2){
                    setKashi('請搜尋“WSDB”。');
                }
                if(Language == 3){
                    setKashi('कृपया "WSDB" खोज्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර "WSDB" සඳහා සොයන්න.');
                }
                if(Language == 5){
                    setKashi('"WSDB" ကိုရှာပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে "WSDB" অনুসন্ধান করুন।');
                }
            }
            if(counter >= 52){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 54.3){
                if(Language == 0){
                    setKashi('「WSDB学生管理システム」というアプリをダウンロードしてください。');
                }
                if(Language == 1){
                    setKashi('Download the app "WSDB Student Management System".');
                }
                if(Language == 2){
                    setKashi('請下載名為「WSDB學生管理系統」的App。');
                }
                if(Language == 3){
                    setKashi('कृपया "WSDB विद्यार्थी व्यवस्थापन प्रणाली" नामक एप डाउनलोड गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර "WSDB ශිෂ්‍ය කළමනාකරණ පද්ධතිය" නම් යෙදුම  download කරගන්න.');
                }
                if(Language == 5){
                    setKashi('"WSDB ကျောင်းသားစီမံခန့်ခွဲမှုစနစ်" ဟုခေါ်သောအက်ပ်ကိုဒေါင်းလုဒ်လုပ်ပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে "WSDB Student Management System" অ্যাপটি ডাউনলোড করুন।');
                }
            }
            if(counter >= 61){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 67){
                if(Language == 0){
                    setKashi('アプリをクリックすると、ログイン画面が表示されますので、');
                }
                if(Language == 1){
                    setKashi('Click on WSDB app and the login screen will appear.');
                }
                if(Language == 2){
                    setKashi('當您點擊該App時，將顯示登入畫面。');
                }
                if(Language == 3){
                    setKashi('जब तपाइँ एपमा क्लिक गर्नुहुन्छ, लगइन स्क्रिन देखाउनेछ।');
                }
                if(Language == 4){
                    setKashi('ඔබ යෙදුම මත ක්ලික් කළ විට, පිවිසුම් තිරය දර්ශනය වේ.');
                }
                if(Language == 5){
                    setKashi('အက်ပ်ကို နှိပ်လိုက်သောအခါ၊ အကောင့်ဝင်သည့် မျက်နှာပြင်ကို ပြသမည်ဖြစ်သည်။');
                }
                if(Language == 6){
                    setKashi('অ্যাপটিতে ক্লিক করুন এবং লগইন স্ক্রীন প্রদর্শিত হবে।');
                }
            }
            if(counter >= 73){
                if(Language == 0){
                    setKashi('IDとパスワードをにゅうりょくしてログインしましょう。');
                }
                if(Language == 1){
                    setKashi('Enter your username and password to log in.');
                }
                if(Language == 2){
                    setKashi('輸入您的使用者名稱和密碼登入。');
                }
                if(Language == 3){
                    setKashi('आफ्नो प्रयोगकर्ता नाम र पासवर्ड राखेर लग इन गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබගේ පරිශීලක නාමය සහ මුරපදය ඇතුළත් කිරීමෙන් ලොග් වන්න.');
                }
                if(Language == 5){
                    setKashi('သင်၏အသုံးပြုသူအမည်နှင့် စကားဝှက်ကို ထည့်သွင်းခြင်းဖြင့် ဝင်ရောက်ပါ။');
                }
                if(Language == 6){
                    setKashi('লগ ইন করতে আপনার ব্যবহারকারীর নাম এবং পাসওয়ার্ড লিখুন।');
                }
            }
            if(counter >= 77.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // アプリの使い方
            if(counter >= 79){
                if(Language == 0){
                    setKashi('アプリの使い方');
                }
                if(Language == 1){
                    setKashi('How to use the app');
                }
                if(Language == 2){
                    setKashi('如何使用該App');
                }
                if(Language == 3){
                    setKashi('एप कसरी प्रयोग गर्ने');
                }
                if(Language == 4){
                    setKashi('යෙදුම භාවිතා කරන්නේ කෙසේද');
                }
                if(Language == 5){
                    setKashi('အက်ပ်ကိုအသုံးပြုနည်း');
                }
                if(Language == 6){
                    setKashi('অ্যাপটি কিভাবে ব্যবহার করবেন');
                }
            }
            if(counter >= 83){
                if(Language == 0){
                    setKashi('アプリでできることをしょうかいします。');
                }
                if(Language == 1){
                    setKashi('Here are some things you can do with the app.');
                }
                if(Language == 2){
                    setKashi('我們將向您展示該App可以做什麼。');
                }
                if(Language == 3){
                    setKashi('हामी तपाईंलाई एपबाट के गर्न सक्नुहुन्छ भनेर देखाउने छौँ।');
                }
                if(Language == 4){
                    setKashi('ඔබට යෙදුම සමඟ කළ හැකි දේ අපි ඔබට පෙන්වන්නෙමු.');
                }
                if(Language == 5){
                    setKashi('အက်ပ်ဖြင့် သင်ဘာလုပ်နိုင်သည်ကို ကျွန်ုပ်တို့ပြသပါမည်။');
                }
                if(Language == 6){
                    setKashi('আপনি অ্যাপ দিয়ে কি করতে পারেন তা দেখান।');
                }
            }
            if(counter >= 87.4){
                if(Language == 0){
                    setKashi('まずは学生証の確認です。');
                }
                if(Language == 1){
                    setKashi('First, check your student ID card.');
                }
                if(Language == 2){
                    setKashi('首先，檢查您的學生證。');
                }
                if(Language == 3){
                    setKashi('पहिले, आफ्नो विद्यार्थी आईडी जाँच गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('පළමුව, ඔබේ ශිෂ්‍ය හැඳුනුම්පත පරීක්ෂා කරන්න.');
                }
                if(Language == 5){
                    setKashi('ပထမဦးစွာ သင်၏ကျောင်းသား ID ကိုစစ်ဆေးပါ။');
                }
                if(Language == 6){
                    setKashi('প্রথমে আপনার স্টুডেন্ট আইডি চেক করুন।');
                }
            }
            if(counter >= 90){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 93.3){
                if(Language == 0){
                    setKashi('ボタンを押すと自分の学生証が見られます。');
                }
                if(Language == 1){
                    setKashi('Click the button to see your student ID card.');
                }
                if(Language == 2){
                    setKashi('按按鈕查看您的學生證。');
                }
                if(Language == 3){
                    setKashi('आफ्नो विद्यार्थी आईडी हेर्न बटन थिच्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබේ ශිෂ්‍ය හැඳුනුම්පත බැලීමට බොත්තම ඔබන්න.');
                }
                if(Language == 5){
                    setKashi('သင်၏ကျောင်းသား ID ကိုကြည့်ရန် ခလုတ်ကိုနှိပ်ပါ။');
                }
                if(Language == 6){
                    setKashi('আপনার ছাত্র আইডি দেখতে বোতাম টিপুন।');
                }
            }
            if(counter >= 97){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 98.6){
                if(Language == 0){
                    setKashi('じぶんの出席を見ます。');
                }
                if(Language == 1){
                    setKashi("Let's look at your own attendance.");
                }
                if(Language == 2){
                    setKashi('檢查您的出席情況。');
                }
                if(Language == 3){
                    setKashi('आफ्नो उपस्थिति जाँच गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබේ පැමීණිම පරීක්ෂා කරන්න.');
                }
                if(Language == 5){
                    setKashi('ကျွန်ုပ်၏တက်ရောက်မှုကိုစစ်ဆေးပါ။');
                }
                if(Language == 6){
                    setKashi('আমার উপস্থিতি পরীক্ষা করুন।');
                }
            }
            if(counter >= 100.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 105.3){
                if(Language == 0){
                    setKashi('今までの出席をチェックできます。');
                }
                if(Language == 1){
                    setKashi('You can check your whole attendance.');
                }
                if(Language == 2){
                    setKashi('您可以查看到目前為止您的出席情況。');
                }
                if(Language == 3){
                    setKashi('तपाईं अहिले सम्मकोआफ्नो उपस्थिति जाँच गर्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබට මෙතෙක් ඔබගේ පැමිණීම පරීක්ෂා කළ හැකිය.');
                }
                if(Language == 5){
                    setKashi('သင့်တက်ရောက်မှုကို ယခုအချိန်အထိ စစ်ဆေးနိုင်ပါသည်။');
                }
                if(Language == 6){
                    setKashi('আপনি এখন পর্যন্ত আপনার উপস্থিতি পরীক্ষা করতে পারেন।');
                }
            }
            if(counter >= 108.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 111.2){
                if(Language == 0){
                    setKashi('じぶんのテストの点数を見ます。');
                }
                if(Language == 1){
                    setKashi("Let's look at your own examination score.");
                }
                if(Language == 2){
                    setKashi('看看你的考試成績。');
                }
                if(Language == 3){
                    setKashi('आफ्नो परीक्षाको स्कोर जाँच गर्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබේ පරීක්ෂණ ලකුණු බලන්න.');
                }
                if(Language == 5){
                    setKashi('သင်၏စာမေးပွဲရမှတ်များကိုကြည့်ပါ။ ');
                }
                if(Language == 6){
                    setKashi('আমার পরীক্ষার স্কোর দেখুন।');
                }
            }
            if(counter >= 113.1){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 117){
                if(Language == 0){
                    setKashi('今までのテストの点数をチェックできます。');
                }
                if(Language == 1){
                    setKashi('You can check all your examination scores.');
                }
                if(Language == 2){
                    setKashi('您可以查看到目前為止的考試成績。');
                }
                if(Language == 3){
                    setKashi('तपाईं अहिलेसम्मको आफ्नो परीक्षाको स्कोर जाँच गर्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබට මෙතෙක් ඔබගේ පරීක්ෂණ ලකුණු පරීක්ෂා කළ හැකිය.');
                }
                if(Language == 5){
                    setKashi('သင်၏ စာမေးပွဲရမှတ်များကို ယခုအချိန်အထိ စစ်ဆေးနိုင်ပါသည်။');
                }
                if(Language == 6){
                    setKashi('আপনি এখন পর্যন্ত আপনার পরীক্ষার স্কোর পরীক্ষা করতে পারেন।');
                }
            }
            if(counter >= 121.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 128.8){
                if(Language == 0){
                    setKashi('じぶんの成績を見ます。');
                }
                if(Language == 1){
                    setKashi("Let's look at your own examination result.");
                }
                if(Language == 2){
                    setKashi('看看您的成績。');
                }
                if(Language == 3){
                    setKashi('आफ्नो ग्रेड हेर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබේ ශේණිය බලා ගැනීමට හැකිය.');
                }
                if(Language == 5){
                    setKashi('သင်၏အဆင့်များကိုကြည့်ပါ။');
                }
                if(Language == 6){
                    setKashi('আমার গ্রেড দেখুন।');
                }
            }
            if(counter >= 131.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('按按鈕查看您的成績。');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 135){
                if(Language == 0){
                    setKashi('今までの成績をチェックできます。');
                }
                if(Language == 1){
                    setKashi('You can check all your grades.');
                }
                if(Language == 2){
                    setKashi('您可以檢查到目前為止的結果。');
                }
                if(Language == 3){
                    setKashi('तपाईं आफ्नो  अहिलेसम्मको ग्रेड　जाँच गर्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබට මෙතෙක් ඔබේ ප්‍රතිඵල පරීක්ෂා කළ හැක.');
                }
                if(Language == 5){
                    setKashi('သင့်ရလဒ်များကို ယခုအချိန်အထိ စစ်ဆေးနိုင်ပါသည်။');
                }
                if(Language == 6){
                    setKashi('আপনি এখন পর্যন্ত আপনার ফলাফল পরীক্ষা করতে পারেন।');
                }
            }
            if(counter >= 139.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 147){
                if(Language == 0){
                    setKashi('学校の情報を見ます。');
                }
                if(Language == 1){
                    setKashi("Let's look at the school information.");
                }
                if(Language == 2){
                    setKashi('查看學校資訊。');
                }
                if(Language == 3){
                    setKashi('स्कूल जानकारी हेर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('පාසල් තොරතුරු බලන්න.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းအချက်အလက်ကိုကြည့်ပါ။');
                }
                if(Language == 6){
                    setKashi('স্কুলের তথ্য দেখুন।');
                }
            }
            if(counter >= 149.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('按按鈕查看學校資訊。');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 153.3){
                if(Language == 0){
                    setKashi('NSAの住所、電話番号などをチェックできます。');
                }
                if(Language == 1){
                    setKashi("You can check NSA's address, phone number, etc.");
                }
                if(Language == 2){
                    setKashi('您可以查看 NSA 的地址、電話號碼等。');
                }
                if(Language == 3){
                    setKashi('तपाईं NSA को ठेगाना, फोन नम्बर, आदि जाँच गर्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබට NSA හි ලිපිනය, දුරකථන අංකය, ආදිය පරීක්ෂා කළ හැක.');
                }
                if(Language == 5){
                    setKashi('NSA ၏လိပ်စာ၊ ဖုန်းနံပါတ်စသည်တို့ကို သင်စစ်ဆေးနိုင်သည်။');
                }
                if(Language == 6){
                    setKashi('আপনি NSA এর ঠিকানা, ফোন নম্বর ইত্যাদি পরীক্ষা করতে পারেন।');
                }
            }
            if(counter >= 157.6){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            if(counter >= 162){
                setMoveVideoTime(4);
                document.getElementById('video').currentTime = 0;
            }
        }
        
        //　5本目　住所などの変更
        if(MoveVideoTime == 4){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn MChBChange');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
            setChapterColor14('MoveChapBtn');

            if(counter >= 0){
                setMoveVideo(SchoolLife);
            }

            //住所などの変更
            if(counter >= 0.5){
                if(Language == 0){
                    setKashi('住所などの変更');
                }
                if(Language == 1){
                    setKashi('Change of address etc.');
                }
                if(Language == 2){
                    setKashi('地址變更等');
                }
                if(Language == 3){
                    setKashi('ठेगाना परिवर्तन आदि।');
                }
                if(Language == 4){
                    setKashi('ලිපිනය වෙනස් කිරීම ආදිය.');
                }
                if(Language == 5){
                    setKashi('လိပ်စာပြောင်းခြင်း စသည်တို့');
                }
                if(Language == 6){
                    setKashi('ঠিকানা পরিবর্তন ইত্যাদি');
                }
            }
            if(counter >= 3.7){
                if(Language == 0){
                    setKashi('学生は、電話番号やメールアドレス');
                }
                if(Language == 1){
                    setKashi('When your phone number, e-mail address');
                }
                if(Language == 2){
                    setKashi('學生必須有電話號碼和電子郵件地址。');
                }
                if(Language == 3){
                    setKashi('विद्यार्थीहरूले फोन नंबर ईमेल  ठेगाना, ');
                }
                if(Language == 4){
                    setKashi('සිසුන්ට දුරකථන අංකයක් සහ ඊමේල් ලිපිනයක් තිබිය යුතුය.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းသားများသည် ဖုန်းနံပါတ်နှင့် အီးမေးလ်လိပ်စာရှိရမည်။');
                }
                if(Language == 6){
                    setKashi('শিক্ষার্থীদের অবশ্যই তাদের ফোন নম্বর এবং ইমেল ঠিকানা প্রদান করতে হবে।');
                }
            }
            if(counter >= 8.4){
                if(Language == 0){
                    setKashi('住所が変わるとき');
                }
                if(Language == 1){
                    setKashi('and residency are changed,');
                }
                if(Language == 2){
                    setKashi('地址變更時');
                }
                if(Language == 3){
                    setKashi('ठेगाना  परिवर्तन गर्दा,');
                }
                if(Language == 4){
                    setKashi('දුරකථන අංකය සහා ලිපිනය වෙනස් වූ විට');
                }
                if(Language == 5){
                    setKashi('လိပ်စာပြောင်းတဲ့အခါ');
                }
                if(Language == 6){
                    setKashi('যখন ঠিকানা পরিবর্তন হয়');
                }
            }
            if(counter >= 12.1){
                if(Language == 0){
                    setKashi('すぐに学校のじむしょの先生に教えてください。');
                }
                if(Language == 1){
                    setKashi('Please inform the school office immediately');
                }
                if(Language == 2){
                    setKashi('請立即通知學校辦公室。');
                }
                if(Language == 3){
                    setKashi('तुरुन्त विद्यालयमा कार्यालयको शिक्षकलाई खबर  गर्नुस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර වහාම පාසල් කාර්යාලයට දැනුම් දෙන්න.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းရုံးကို ချက်ချင်းအကြောင်းကြားပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে অবিলম্বে স্কুলকে বলুন');
                }
            }
            if(counter >= 18.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            // 暴力
            if(counter >= 20.3){
                if(Language == 0){
                    setKashi('暴力');
                }
                if(Language == 1){
                    setKashi('Violence');
                }
                if(Language == 2){
                    setKashi('暴力');
                }
                if(Language == 3){
                    setKashi('हिंसात्मक क्रियाकलाप');
                }
                if(Language == 4){
                    setKashi('ප්රචණ්ඩත්වය');
                }
                if(Language == 5){
                    setKashi('အကြမ်းဖက်မှု');
                }
                if(Language == 6){
                    setKashi('সহিংসতা');
                }
            }
            if(counter >= 23.4){
                if(Language == 0){
                    setKashi('学校の中でも外でも');
                }
                if(Language == 1){
                    setKashi('Inside and outside of school,');
                }
                if(Language == 2){
                    setKashi('校內校外');
                }
                if(Language == 3){
                    setKashi('विद्यालय भित्र र बाहिर दुवै');
                }
                if(Language == 4){
                    setKashi('පාසැලේ ඇතුළත සහ පිටත යන දෙකම');
                }
                if(Language == 5){
                    setKashi('ကျောင်းတွင်းရော အပြင်ရော');
                }
                if(Language == 6){
                    setKashi('স্কুলের ভিতরে এবং বাইরে');
                }
            }
            if(counter >= 27.1){
                if(Language == 0){
                    setKashi('暴力はダメです。');
                }
                if(Language == 1){
                    setKashi('Violence is not allowed.');
                }
                if(Language == 2){
                    setKashi('禁止暴力。');
                }
                if(Language == 3){
                    setKashi('हिंसात्मक क्रियाकलाप गर्न पाइने छैन।');
                }
                if(Language == 4){
                    setKashi('ප්‍රචණ්ඩත්වය තහනම්.');
                }
                if(Language == 5){
                    setKashi('အကြမ်းဖက်မှုက မကောင်းဘူး။');
                }
                if(Language == 6){
                    setKashi('সহিংসতা অনুমোদিত নয়।');
                }
            }
            if(counter >= 29.7){
                if(Language == 0){
                    setKashi('学校で暴力を使った場合');
                }
                if(Language == 1){
                    setKashi('In case of violence at school,');
                }
                if(Language == 2){
                    setKashi('如果在學校使用暴力');
                }
                if(Language == 3){
                    setKashi('यदि स्कूलमा हिंसात्मक क्रियाकलाप गर्यो भने,');
                }
                if(Language == 4){
                    setKashi('පාසැලේදී ප්‍රචණ්ඩත්වය භාවිතා කරන්නේ නම්');
                }
                if(Language == 5){
                    setKashi('ကျောင်းမှာ အကြမ်းဖက်လျှင်');
                }
                if(Language == 6){
                    setKashi('যদি স্কুলে সহিংসতা ব্যবহার করা হয়');
                }
            }
            if(counter >= 33){
                if(Language == 0){
                    setKashi('その学生は１週間停学になります。');
                }
                if(Language == 1){
                    setKashi('The student will be suspended from school for one week.');
                }
                if(Language == 2){
                    setKashi('該學生將被停學一周。');
                }
                if(Language == 3){
                    setKashi('त्यस विद्यार्थीलाई एक साताको लागि विद्यालयबाट निलम्बन गरिनेछ।');
                }
                if(Language == 4){
                    setKashi('ශිෂ්‍යයාට සතියක පාසල් තහනමක් පැනවේ.');
                }
                if(Language == 5){
                    setKashi('အဆိုပါ ကျောင်းသားအား ကျောင်းမှ တစ်ပတ်တာ ရပ်နားမည်ဖြစ်သည်။');
                }
                if(Language == 6){
                    setKashi('শিক্ষার্থীকে এক সপ্তাহের জন্য স্কুল থেকে সাসপেন্ড করা হবে।');
                }
            }
            if(counter >= 38.3){
                if(Language == 0){
                    setKashi('暴力がひどい場合は');
                }
                if(Language == 1){
                    setKashi('If the violence is severe,');
                }
                if(Language == 2){
                    setKashi('如果暴力行為嚴重');
                }
                if(Language == 3){
                    setKashi('यदि हिंसा गम्भीर किसिमको छ भने,');
                }
                if(Language == 4){
                    setKashi('ප්‍රචණ්ඩත්වය දරුණු නම්');
                }
                if(Language == 5){
                    setKashi('အကြမ်းဖက်မှုတွေ ပြင်းထန်လာရင်');
                }
                if(Language == 6){
                    setKashi('সহিংসতা গুরুতর হলে');
                }
            }
            if(counter >= 41.2){
                if(Language == 0){
                    setKashi('退学になることもあります。');
                }
                if(Language == 1){
                    setKashi('The student might be expelled from school.');
                }
                if(Language == 2){
                    setKashi('你甚至可能被學校開除。');
                }
                if(Language == 3){
                    setKashi('तपाईलाई विद्यालयबाट निष्कासन पनि गर्न सकिन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබ පාසලෙන් පවා නෙරපා හැරිය හැක.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းထုတ်ခံရတာတောင် ဖြစ်နိုင်တယ်။');
                }
                if(Language == 6){
                    setKashi('আপনাকে স্কুল থেকে বহিষ্কার করা হতে পারে।');
                }
            }
            if(counter >= 44.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            // 反省文
            if(counter >= 46.5){
                if(Language == 0){
                    setKashi('反省文');
                }
                if(Language == 1){
                    setKashi('Letter of apology');
                }
                if(Language == 2){
                    setKashi('反思陳述');
                }
                if(Language == 3){
                    setKashi('प्रतिबिम्ब बयान');
                }
                if(Language == 4){
                    setKashi('පරාවර්තන ප්රකාශය');
                }
                if(Language == 5){
                    setKashi('တောင်းပန်စာ');
                }
                if(Language == 6){
                    setKashi('প্রতিফলন বিবৃতি');
                }
            }
            if(counter >= 49.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 50.9){
                if(Language == 0){
                    setKashi('暴力を使った人は');
                }
                if(Language == 1){
                    setKashi('And those who committed the violence,');
                }
                if(Language == 2){
                    setKashi('還有那些使用暴力的人');
                }
                if(Language == 3){
                    setKashi('हिंसात्मक क्रियाकलाप गर्ने व्यक्तिलाई');
                }
                if(Language == 4){
                    setKashi('සහ ප්‍රචණ්ඩත්වය භාවිතා කළ අය');
                }
                if(Language == 5){
                    setKashi('အကြမ်း ဖက်သုံးတဲ့သူတွေ၊');
                }
                if(Language == 6){
                    setKashi('এবং যারা সহিংসতা ব্যবহার করেছিল');
                }
            }
            if(counter >= 55.5){
                if(Language == 0){
                    setKashi('反省文と課題をしなければなりません。');
                }
                if(Language == 1){
                    setKashi('They have to do a reflection statement and an assignment.');
                }
                if(Language == 2){
                    setKashi('必須做一篇反思論文和作業。');
                }
                if(Language == 3){
                    setKashi('तपाईंले प्रतिबिम्ब बयान र असाइनमेन्ट गर्नु पर्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබ සමාව ඉල්ලන ලිපිය සහ පැවරැම් කල යුතුය.');
                }
                if(Language == 5){
                    setKashi('တောင်းပန်စာနှင့် တာဝန်တစ်ခု ကိုလုပ်ဆောင်ရမည်။');
                }
                if(Language == 6){
                    setKashi('আমাকে একটি প্রতিফলন রচনা এবং একটি অ্যাসাইনমেন্ট করতে হবে।');
                }
            }
            if(counter >= 62.5){
                if(Language == 0){
                    setKashi('学校は暴力を使ったことを');
                }
                if(Language == 1){
                    setKashi('The school will notify');
                }
                if(Language == 2){
                    setKashi('學校將通知家人');
                }
                if(Language == 3){
                    setKashi('हिंसात्मक क्रियाकलाप गरेको कुरा');
                }
                if(Language == 4){
                    setKashi('පාසලේ ප්‍රචණ්ඩත්වය පාවිච්චි කළා කියලා');
                }
                if(Language == 5){
                    setKashi('ကျောင်းက အကြမ်းဖက်မှုကို သုံးသည့်အခါ');
                }
                if(Language == 6){
                    setKashi('স্কুল স্বীকার করেছে যে এটি সহিংসতা ব্যবহার করেছে।');
                }
            }
            if(counter >= 67.3){
                if(Language == 0){
                    setKashi('家族に連絡します。');
                }
                if(Language == 1){
                    setKashi("the student's family about the violence.");
                }
                if(Language == 2){
                    setKashi('該名學生使用了暴力。');
                }
                if(Language == 3){
                    setKashi('तपाईँहरूको परिवारमा जानकारी गराउनेछौं।');
                }
                if(Language == 4){
                    setKashi('මම එය  ඔබේ පවුලේ අයට දැනුම් දෙමි.');
                }
                if(Language == 5){
                    setKashi('ငါ့မိသားစုကို ငါဆက်သွယ်မယ်။');
                }
                if(Language == 6){
                    setKashi('আমি আমার পরিবারের সাথে যোগাযোগ করব।');
                }
            }
            if(counter >= 72.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            // けいさつ
            if(counter >= 77.2){
                if(Language == 0){
                    setKashi('けいさつ');
                }
                if(Language == 1){
                    setKashi('Police');
                }
                if(Language == 2){
                    setKashi('警察');
                }
                if(Language == 3){
                    setKashi('प्रहरी');
                }
                if(Language == 4){
                    setKashi('පොලිසිය');
                }
                if(Language == 5){
                    setKashi('ရဲ');
                }
                if(Language == 6){
                    setKashi('পুলিশ');
                }
            }
            if(counter >= 80.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 82){
                if(Language == 0){
                    setKashi('けいさつにつかまったときは、');
                }
                if(Language == 1){
                    setKashi('When You were arrested by the police outside the school,');
                }
                if(Language == 2){
                    setKashi('當您在學校外面被警察逮捕時，');
                }
                if(Language == 3){
                    setKashi('यदि तपाईँहरूलाई प्रहरीले हिरासतमा लिएको खण्डमा,');
                }
                if(Language == 4){
                    setKashi('පාසලෙන් පිටතදී මාව පොලිසිය විසින් අත්අඩංගුවට ගන්නා විට,');
                }
                if(Language == 5){
                    setKashi('ကျောင်းအပြင်မှာ ရဲတွေ ဖမ်းတုန်းက');
                }
                if(Language == 6){
                    setKashi('যখন আমাকে স্কুলের বাইরে পুলিশ গ্রেপ্তার করেছিল,');
                }
            }
            if(counter >= 85.6){
                if(Language == 0){
                    setKashi('かならず学校に教えてください。');
                }
                if(Language == 1){
                    setKashi('please make sure to inform the school.');
                }
                if(Language == 2){
                    setKashi('請務必告知學校。');
                }
                if(Language == 3){
                    setKashi('कृपया विद्यालयलाई जानकारी गराउनु होला।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර පාසල දැනුවත් කිරීමට වග බලා ගන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးပြု၍ ကျောင်းကို အသိပေးပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে স্কুলকে জানাতে ভুলবেন না।');
                }
            }
            if(counter >= 90){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 90.5){
                if(Language == 0){
                    setKashi('ビザを更新するときに');
                }
                if(Language == 1){
                    setKashi('At the time of visa renewal');
                }
                if(Language == 2){
                    setKashi('更新簽證時');
                }
                if(Language == 3){
                    setKashi('तपाईंको भिसा नवीकरण गर्दा');
                }
                if(Language == 4){
                    setKashi('ඔබගේ වීසා බලපත්‍රය අලුත් කිරීමේදී එම සීදුවීම ගැන වාර්තා කරනු ලැබේ');
                }
                if(Language == 5){
                    setKashi('ဗီဇာသက်တမ်းတိုးတဲ့အခါ');
                }
                if(Language == 6){
                    setKashi('ভিসা নবায়নের সময়');
                }
            }
            if(counter >= 93.5){
                if(Language == 0){
                    setKashi('レポートを書きます。先生が手伝います。');
                }
                if(Language == 1){
                    setKashi('the student writes a report. School will help.');
                }
                if(Language == 2){
                    setKashi('寫報告。學校會幫忙的。');
                }
                if(Language == 3){
                    setKashi('प्रहरीले समातेको कुराको रिपोर्ट लेख्नुपर्ने हुन्छ। हामी मद्दत गर्छौं।');
                }
                if(Language == 4){
                    setKashi('වාර්තාවක් ලියන්න.  පාසලෙන් උදව්  කරයි.');
                }
                if(Language == 5){
                    setKashi('အစီရင်ခံစာရေးပါ။ ကျောင်းကကူညီပါမည်။');
                }
                if(Language == 6){
                    setKashi('একটি প্রতিবেদন লিখুন। সাহায্য করবে.');
                }
            }
            if(counter >= 96.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 97.4){
                if(Language == 0){
                    setKashi('でも、問題が大きいときは');
                }
                if(Language == 1){
                    setKashi('But when the problem is terrible,');
                }
                if(Language == 2){
                    setKashi('但當問題很大的時候');
                }
                if(Language == 3){
                    setKashi('यदि हिंसात्मक क्रियाकलाप र समस्या ठूलो छ भने,');
                }
                if(Language == 4){
                    setKashi('නමුත් ගැටලුව විශාල වූ විට');
                }
                if(Language == 5){
                    setKashi('ဒါပေမယ့် ပြဿနာက ကြီးလာတဲ့အခါ');
                }
                if(Language == 6){
                    setKashi('কিন্তু যখন সমস্যা বড় হয়');
                }
            }
            if(counter >= 100.6){
                if(Language == 0){
                    setKashi('退学になります。');
                }
                if(Language == 1){
                    setKashi('You will be expelled from school.');
                }
                if(Language == 2){
                    setKashi('您將被學校開除。');
                }
                if(Language == 3){
                    setKashi('तपाईँलाई विद्यालयबाट निष्कासन गरिनेछ।');
                }
                if(Language == 4){
                    setKashi('ඔබව ඉස්කෝලෙන් අයින් කරනවා.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းထုတ်ခံရတော့မယ်။');
                }
                if(Language == 6){
                    setKashi('আপনাকে স্কুল থেকে বহিষ্কার করা হবে।');
                }
            }
            if(counter >= 103.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            //　退学（たいがく）
            if(counter >= 107.2){
                if(Language == 0){
                    setKashi('退学（たいがく）');
                }
                if(Language == 1){
                    setKashi('Withdrawal from school');
                }
                if(Language == 2){
                    setKashi('被學校開除');
                }
                if(Language == 3){
                    setKashi('विद्यालयबाट निष्कासन');
                }
                if(Language == 4){
                    setKashi('පාසලෙන් ඉවත් කිරීම');
                }
                if(Language == 5){
                    setKashi('ကျောင်းထွက်ခြင်း');
                }
                if(Language == 6){
                    setKashi('স্কুল ত্যাগ');
                }
            }
            if(counter >= 110.4){
                if(Language == 0){
                    setKashi('病気など問題があって学校をやめるときは、');
                }
                if(Language == 1){
                    setKashi('If you have to quit school due to illness or other problems,');
                }
                if(Language == 2){
                    setKashi('如果您因生病或其他問題必須退學，');
                }
                if(Language == 3){
                    setKashi('यदि तपाईले बिरामी वा अन्य समस्याहरूको कारण विद्यालय छोड्नु पर्यो भने,');
                }
                if(Language == 4){
                    setKashi('අසනීප හෝ වෙනත් ගැටළු හේතුවෙන් ඔබට පාසලෙන් ඉවත් වීමට සිදුවුවහොත්,');
                }
                if(Language == 5){
                    setKashi('ဖျားနာခြင်း သို့မဟုတ် အခြားပြဿနာများကြောင့် ကျောင်းထွက်ရခြင်း၊');
                }
                if(Language == 6){
                    setKashi('যদি আপনাকে অসুস্থতা বা অন্যান্য সমস্যার কারণে স্কুল ছাড়তে হয়,');
                }
            }
            if(counter >= 113.5){
                if(Language == 0){
                    setKashi('退学届(たいがくとどけ)を書きます。');
                }
                if(Language == 1){
                    setKashi('You will write a notice of withdrawal.');
                }
                if(Language == 2){
                    setKashi('寫一份撤回通知。');
                }
                if(Language == 3){
                    setKashi('फिर्ता हुनको लागि पत्र लेख्नुपर्ने हुन्छ।');
                }
                if(Language == 4){
                    setKashi('මම ඉල්ලා අස්කර ගැනීමේ *ලිපියක් ලිවීම.');
                }
                if(Language == 5){
                    setKashi('နုတ်ထွက်ကြောင်း အကြောင်းကြားစာရေးပါမည်။');
                }
                if(Language == 6){
                    setKashi('আমি প্রত্যাহারের নোটিশ লিখব।');
                }
            }
            if(counter >= 116.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 117.2){
                if(Language == 0){
                    setKashi('退学(たいがく)しても、');
                }
                if(Language == 1){
                    setKashi('Even if you drop out of school,');
                }
                if(Language == 2){
                    setKashi('就算你輟學了，');
                }
                if(Language == 3){
                    setKashi('विद्यालय छाडे पनि,');
                }
                if(Language == 4){
                    setKashi('ඔබ පාසල් හැර ගියත්,');
                }
                if(Language == 5){
                    setKashi('ကျောင်းထွက်ရင်တောင်');
                }
                if(Language == 6){
                    setKashi('আপনি স্কুল ছেড়ে দিলেও,');
                }
            }
            if(counter >= 119.4){
                if(Language == 0){
                    setKashi('学校のお金や寮のお金は返しません。');
                }
                if(Language == 1){
                    setKashi('School money and dormitory money will not be returned.');
                }
                if(Language == 2){
                    setKashi('學費和宿舍費將不予退還。');
                }
                if(Language == 3){
                    setKashi('विद्यालयको पैसा र छात्रावासको पैसा फिर्ता गरिने छैन।');
                }
                if(Language == 4){
                    setKashi('පාසල් මුදල් සහ නේවාසිකාගාර මුදල් ආපසු දෙන්නේ නැහැ.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းသုံးငွေနှင့် အိပ်ဆောင်ခကို ပြန်ပေးမည်မဟုတ်ပါ။');
                }
                if(Language == 6){
                    setKashi('স্কুলের টাকা এবং ছাত্রাবাসের টাকা ফেরত দেওয়া হবে না।');
                }
            }
            if(counter >= 123.6){
                if(Language == 0){
                    setKashi('学校からかりているものがあったら、返さなければなりません。');
                }
                if(Language == 1){
                    setKashi('If you borrowed anything from school, you must return it.');
                }
                if(Language == 2){
                    setKashi('如果你從學校借了任何東西，你必須歸還。');
                }
                if(Language == 3){
                    setKashi('यदि तपाईँले विद्यालयबाट केही सामान उधारो लिनुभएको छ भने त्यसलाई फिर्ता गर्न जरुरी छ।');
                }
                if(Language == 4){
                    setKashi('ඔබ පාසලෙන් යමක් ණයට ගන්නේ නම්, ඔබ එය ආපසු ලබා දිය යුතුය.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းက တစ်ခုခုချေးရင် ပြန်ပေးရမယ်။');
                }
                if(Language == 6){
                    setKashi('আপনি স্কুল থেকে ধার করা জিনিস ফেরত দিন.');
                }
            }
            if(counter >= 129.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            // はんこ
            if(counter >= 132){
                if(Language == 0){
                    setKashi('はんこ');
                }
                if(Language == 1){
                    setKashi('Stamp');
                }
                if(Language == 2){
                    setKashi('印章');
                }
                if(Language == 3){
                    setKashi('हस्ताक्षर छाप');
                }
                if(Language == 4){
                    setKashi('මුද්දර');
                }
                if(Language == 5){
                    setKashi('တံဆိပ်တုံး');
                }
                if(Language == 6){
                    setKashi('ছাপ');
                }
            }
            if(counter >= 136){
                if(Language == 0){
                    setKashi('はんこと保険は、');
                }
                if(Language == 1){
                    setKashi('Stamps and insurance will be');
                }
                if(Language == 2){
                    setKashi('印章和保險是');
                }
                if(Language == 3){
                    setKashi('टिकट र बीमा छन्');
                }
                if(Language == 4){
                    setKashi('මුද්දර සහ රක්ෂණ වේ');
                }
                if(Language == 5){
                    setKashi('တံဆိပ်ခေါင်းနဲ့ အာမခံတွေဖြစ်ပါတယ်။');
                }
                if(Language == 6){
                    setKashi('স্ট্যাম্প এবং বীমা হয়');
                }
            }
            if(counter >= 138.3){
                if(Language == 0){
                    setKashi('お金がかかります。');
                }
                if(Language == 1){
                    setKashi('charged at cost.');
                }
                if(Language == 2){
                    setKashi('需要單獨的錢。');
                }
                if(Language == 3){
                    setKashi('पैसा लाग्छ।');
                }
                if(Language == 4){
                    setKashi('ඒකට සල්ලි ඕන.');
                }
                if(Language == 5){
                    setKashi('ပိုက်ဆံယူတယ်။');
                }
                if(Language == 6){
                    setKashi('টাকা লাগে।');
                }
            }
            if(counter >= 141.4){
                if(Language == 0){
                    setKashi('ひつようなお金は');
                }
                if(Language == 1){
                    setKashi('Regarding the amount ');
                }
                if(Language == 2){
                    setKashi('關於所需金額');
                }
                if(Language == 3){
                    setKashi('आवश्यक रकमको सम्बन्धमा');
                }
                if(Language == 4){
                    setKashi('එයට වැය වෙන මුදල');
                }
                if(Language == 5){
                    setKashi('လိုအပ်သောပမာဏနှင့်ပတ်သက်၍');
                }
                if(Language == 6){
                    setKashi('সাধারণ পরিমাণ সম্পর্কে');
                }
            }
            if(counter >= 144){
                if(Language == 0){
                    setKashi('じむしょの先生に聞いてください。');
                }
                if(Language == 1){
                    setKashi('please ask the teachers in the office.');
                }
                if(Language == 2){
                    setKashi('請詢問辦公室或老師。');
                }
                if(Language == 3){
                    setKashi('कृपया आफ्नो कार्यालयको  शिक्षकलाई सोध्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබේ කාර්යාලයෙන් හෝ ගුරුවරයාගෙන් විමසන්න.');
                }
                if(Language == 5){
                    setKashi('သင့်ရုံး သို့မဟုတ် ဆရာကို မေးပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে জিমুশোয়াকে জিজ্ঞাসা করুন।');
                }
            }
            if(counter >= 146.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            // 学校のお金①
            if(counter >= 148){
                if(Language == 0){
                    setKashi('学校のお金①');
                }
                if(Language == 1){
                    setKashi('Tuition fee①');
                }
                if(Language == 2){
                    setKashi('學費①');
                }
                if(Language == 3){
                    setKashi('विद्यालयको पैसा①');
                }
                if(Language == 4){
                    setKashi('පාසල් මුදල්①');
                }
                if(Language == 5){
                    setKashi('ကျောင်းပိုက်ဆံ①');
                }
                if(Language == 6){
                    setKashi('স্কুলের টাকা①');
                }
            }
            if(counter >= 151.5){
                if(Language == 0){
                    setKashi('学校のお金は、半年に1回はらいます。');
                }
                if(Language == 1){
                    setKashi('You pay the tuition fee every six months.');
                }
                if(Language == 2){
                    setKashi('學費每六個月支付一次。');
                }
                if(Language == 3){
                    setKashi('विद्यालयको शुल्क हरेक ६ महिनामा  एकचोटी तिर्नुपर्ने हुन्छ।');
                }
                if(Language == 4){
                    setKashi('පාසල් ගාස්තු මාස ​​හයකට වරක් ගෙවනු ලැබේ.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းလခကို ခြောက်လတစ်ကြိမ် ပေးဆောင်ရသည်။');
                }
                if(Language == 6){
                    setKashi('আমি প্রতি ছয় মাসে আমার স্কুলের টাকা পরিশোধ করি।');
                }
            }
            if(counter >= 156.2){
                if(Language == 0){
                    setKashi('はらえないときは、');
                }
                if(Language == 1){
                    setKashi("When you are unable to pay,");
                }
                if(Language == 2){
                    setKashi('如果你付不起錢，');
                }
                if(Language == 3){
                    setKashi('तिर्न नसक्ने अवस्थामा,');
                }
                if(Language == 4){
                    setKashi('ඔබට ගෙවිය නොහැකි නම්,');
                }
                if(Language == 5){
                    setKashi('မဆပ်နိုင်ရင်၊');
                }
                if(Language == 6){
                    setKashi('যখন আপনি এটি পেতে পারেন না,');
                }
            }
            if(counter >= 157.7){
                if(Language == 0){
                    setKashi('早めにじむしょの先生にそうだんしてください。');
                }
                if(Language == 1){
                    setKashi('Please consult with the teacher at the office as soon as possible.');
                }
                if(Language == 2){
                    setKashi('請盡快向辦公室老師諮詢。');
                }
                if(Language == 3){
                    setKashi('कृपया सकेसम्म चाँडो आफ्नो कार्यालयको शिक्षक संग परामर्श गर्नुहोला।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර හැකි ඉක්මනින් ඔබේ කාර්යාල ගුරුවරයා හමුවන්න.');
                }
                if(Language == 5){
                    setKashi('သင်၏ရုံးဆရာနှင့် အမြန်ဆုံးတိုင်ပင်ပါ။');
                }
                if(Language == 6){
                    setKashi('যত তাড়াতাড়ি সম্ভব আপনার সন্তানের শিক্ষককে বলুন।');
                }
            }
            if(counter >= 161.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 学校のお金②
            if(counter >= 163.7){
                if(Language == 0){
                    setKashi('学校のお金②');
                }
                if(Language == 1){
                    setKashi('Tuition fee②');
                }
                if(Language == 2){
                    setKashi('學費②');
                }
                if(Language == 3){
                    setKashi('विद्यालयको पैसा②');
                }
                if(Language == 4){
                    setKashi('පාසල් මුදල්②');
                }
                if(Language == 5){
                    setKashi('ကျောင်းပိုက်ဆံ②');
                }
                if(Language == 6){
                    setKashi('স্কুলের টাকা②');
                }
            }
            if(counter >= 167.2){
                if(Language == 0){
                    setKashi('そうだんしないで２か月以上');
                }
                if(Language == 1){
                    setKashi("Students who don't pay");
                }
                if(Language == 2){
                    setKashi('超過2個月未諮詢');
                }
                if(Language == 3){
                    setKashi('शिक्षक संगको परामर्श बिना दुईमहिना भन्दा बढी ');
                }
                if(Language == 4){
                    setKashi('දැනුම් දීමක්  නොමැතිව මාස 2 කට වඩා වැඩි කාලයක්');
                }
                if(Language == 5){
                    setKashi('တိုင်ပင်ခြင်းမရှိဘဲ ၂ လကျော်');
                }
                if(Language == 6){
                    setKashi('কিছু না করে 2 মাসের বেশি হয়ে গেছে।');
                }
            }
            if(counter >= 170.6){
                if(Language == 0){
                    setKashi('お金をはらわない学生は');
                }
                if(Language == 1){
                    setKashi("without consultation more than 2months,");
                }
                if(Language == 2){
                    setKashi('不繳費的學生');
                }
                if(Language == 3){
                    setKashi('शुल्क नतिर्ने विद्यार्थीलाई');
                }
                if(Language == 4){
                    setKashi('මුදල් නොගෙවන සිසුන්');
                }
                if(Language == 5){
                    setKashi('ကျောင်းသားတွေက ကျောင်းစရိတ်မပေးဆောင်လျှင်။');
                }
                if(Language == 6){
                    setKashi('যে ছাত্ররা টাকা খরচ করে না');
                }
            }
            if(counter >= 173.4){
                if(Language == 0){
                    setKashi('学校を退学になることがあります。');
                }
                if(Language == 1){
                    setKashi('might be expelled from school.');
                }
                if(Language == 2){
                    setKashi('您可能會被學校開除。');
                }
                if(Language == 3){
                    setKashi('विद्यालयबाट निष्कासन गर्न सकिन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබව පාසලෙන් නෙරපා හැරිය හැක.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းမှ ထုတ်ပယ်ခံရနိုင်သည်။');
                }
                if(Language == 6){
                    setKashi('আপনাকে স্কুল থেকে বহিষ্কার করা হতে পারে।');
                }
            }
            if(counter >= 177.8){
                if(Language == 0){
                    setKashi('学校のお金をはらわない学生は');
                }
                if(Language == 1){
                    setKashi("Students who don't pay school fees");
                }
                if(Language == 2){
                    setKashi('不繳學費的學生');
                }
                if(Language == 3){
                    setKashi('विद्यालयको शुल्क नतिर्ने विद्यार्थीहरूको');
                }
                if(Language == 4){
                    setKashi('පාසලට මුදල් නොගෙවන සිසුන්');
                }
                if(Language == 5){
                    setKashi('ကျောင်းစရိတ်မရှိသော ကျောင်းသား၊');
                }
                if(Language == 6){
                    setKashi('যে শিক্ষার্থীরা স্কুলের জন্য অর্থ প্রদান করে না');
                }
            }
            if(counter >= 181.5){
                if(Language == 0){
                    setKashi('学校の証明書を作ることができません。');
                }
                if(Language == 1){
                    setKashi("can't receive school certificates.");
                }
                if(Language == 2){
                    setKashi('無法頒發學校證書。');
                }
                if(Language == 3){
                    setKashi('विद्यालयको प्रमाणपत्र बनाउन  असमर्थ छौं।');
                }
                if(Language == 4){
                    setKashi('ඔබට පාසල් සහතිකයක් හදන්න බැහැ.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းဆင်းလက်မှတ် မဖန်တီးနိုင်ဘူး။');
                }
                if(Language == 6){
                    setKashi('আমি স্কুল সার্টিফিকেট তৈরি করতে পারি না।');
                }
            }
            if(counter >= 185.1){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            //　校外学習
            if(counter >= 187.5){
                if(Language == 0){
                    setKashi('校外学習');
                }
                if(Language == 1){
                    setKashi('Field trip');
                }
                if(Language == 2){
                    setKashi('校園旅行');
                }
                if(Language == 3){
                    setKashi('फिल्ड भ्रमण');
                }
                if(Language == 4){
                    setKashi('අධ්‍යාපන චාරිකා');
                }
                if(Language == 5){
                    setKashi('Excursions');
                }
                if(Language == 6){
                    setKashi('স্কুল ট্রিপ');
                }
            }
            if(counter >= 191){
                if(Language == 0){
                    setKashi('学校の外で勉強します。');
                }
                if(Language == 1){
                    setKashi('We study outside of school.');
                }
                if(Language == 2){
                    setKashi('在校外學習。');
                }
                if(Language == 3){
                    setKashi('विद्यालय बाहिर अध्ययन गरिनेछ,');
                }
                if(Language == 4){
                    setKashi('පාසලෙන් පිටත ඉගෙන ගන්න.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းပြင်ပလေ့လာခြင်း');
                }
                if(Language == 6){
                    setKashi('আমি স্কুলের বাইরে পড়ি।');
                }
            }
            if(counter >= 196.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 201.5){
                if(Language == 0){
                    setKashi('必ずさんかしてください。');
                }
                if(Language == 1){
                    setKashi('Please be sure to participate.');
                }
                if(Language == 2){
                    setKashi('請務必參加。');
                }
                if(Language == 3){
                    setKashi('अवश्य सहभागी हुनुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර සහභාගී වීමට වග බලා ගන්න.');
                }
                if(Language == 5){
                    setKashi('ပါဝင်ကြပါရန်။');
                }
                if(Language == 6){
                    setKashi('শিক্ষার্থীদের অংশগ্রহণ করতে হবে।');
                }
            }
            if(counter >= 206.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            // せいせき
            if(counter >= 210.5){
                if(Language == 0){
                    setKashi('せいせき');
                }
                if(Language == 1){
                    setKashi('Grades evaluation');
                }
                if(Language == 2){
                    setKashi('成績');
                }
                if(Language == 3){
                    setKashi('ग्रेडहरू');
                }
                if(Language == 4){
                    setKashi('ශ්රේණි');
                }
                if(Language == 5){
                    setKashi('အဆင့်များ');
                }
                if(Language == 6){
                    setKashi('শ্রেণীসমূহ');
                }
            }
            if(counter >= 213.6){
                if(Language == 0){
                    setKashi('みなさんのせいせきは');
                }
                if(Language == 1){
                    setKashi('Grades are determined by');
                }
                if(Language == 2){
                    setKashi('成績是');
                }
                if(Language == 3){
                    setKashi('तपाईँको ग्रेडहरू');
                }
                if(Language == 4){
                    setKashi('ශ්රේණි වේ');
                }
                if(Language == 5){
                    setKashi('အဆင့်တွေကတော့');
                }
                if(Language == 6){
                    setKashi('সেসেকিহা');
                }
            }
            if(counter >= 215.7){
                if(Language == 0){
                    setKashi('テストの点数');
                }
                if(Language == 1){
                    setKashi('Examination results,');
                }
                if(Language == 2){
                    setKashi('檢測結果');
                }
                if(Language == 3){
                    setKashi('परीक्षाको रिजल्टबाट,');
                }
                if(Language == 4){
                    setKashi('පරීක්ෂණ ප්රතිඵල');
                }
                if(Language == 5){
                    setKashi('စမ်းသပ်မှုရလဒ်များ');
                }
                if(Language == 6){
                    setKashi('পরীক্ষার ফলাফল');
                }
            }
            if(counter >= 217.7){
                if(Language == 0){
                    setKashi('宿題をしているかどうか、');
                }
                if(Language == 1){
                    setKashi('Submitting homework,');
                }
                if(Language == 2){
                    setKashi('提交作業');
                }
                if(Language == 3){
                    setKashi('कक्षाको गृहकार्यबाट,');
                }
                if(Language == 4){
                    setKashi('ගෙදර වැඩ ඉදිරිපත් කිරීම');
                }
                if(Language == 5){
                    setKashi('အိမ်စာတွေတင်တယ်။');
                }
                if(Language == 6){
                    setKashi('হোমওয়ার্ক জমা দিন');
                }
            }
            if(counter >= 219.6){
                if(Language == 0){
                    setKashi('授業態度などで決まります。');
                }
                if(Language == 1){
                    setKashi('Attitude in class, and ect.');
                }
                if(Language == 2){
                    setKashi('這將取決於你在課堂上的態度。');
                }
                if(Language == 3){
                    setKashi('कक्षाकोठामा तपाईँहरूले गर्ने क्रियाकलापहरूबाट  निर्धारण गरिनेछ।');
                }
                if(Language == 4){
                    setKashi('මෙය පන්තියේ ඔබේ ආකල්පය අනුව තීරණය වේ.');
                }
                if(Language == 5){
                    setKashi('ဒါကို အတန်းထဲက မင်းရဲ့သဘောထားက ဆုံးဖြတ်လိမ့်မယ်။');
                }
                if(Language == 6){
                    setKashi('এটা ক্লাসে আপনার মনোভাবের উপর নির্ভর করে।');
                }
            }
            if(counter >= 224){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            // 卒業するために必要なこと
            if(counter >= 225.7){
                if(Language == 0){
                    setKashi('卒業するために必要なこと');
                }
                if(Language == 1){
                    setKashi('Requirements for graduation');
                }
                if(Language == 2){
                    setKashi('畢業條件');
                }
                if(Language == 3){
                    setKashi('स्नातक हुनको लागि पूरा गर्नुपर्ने शर्तहरू');
                }
                if(Language == 4){
                    setKashi('උපාධි කොන්දේසි');
                }
                if(Language == 5){
                    setKashi('ဘွဲ့အခြေအနေများ');
                }
                if(Language == 6){
                    setKashi('স্নাতক শর্তাবলী');
                }
            }
            if(counter >= 229.3){
                if(Language == 0){
                    setKashi('卒業するために必要なことは4つあります。');
                }
                if(Language == 1){
                    setKashi('There are four qualification for graduation.');
                }
                if(Language == 2){
                    setKashi('畢業有四個條件。');
                }
                if(Language == 3){
                    setKashi('स्नातकको लागि चार शर्तहरू छन्।');
                }
                if(Language == 4){
                    setKashi('උපාධිය සඳහා කොන්දේසි හතරක් ඇත.');
                }
                if(Language == 5){
                    setKashi('ဘွဲ့ရဖို့ အခြေအနေလေးမျိုးရှိတယ်။');
                }
                if(Language == 6){
                    setKashi('স্নাতকের জন্য চারটি শর্ত আছে।');
                }
            }
            if(counter >= 234){
                if(Language == 0){
                    setKashi('出席率が70%以上あること');
                }
                if(Language == 1){
                    setKashi('Attendance rate is 70% or higher');
                }
                if(Language == 2){
                    setKashi('①出席率70%以上');
                }
                if(Language == 3){
                    setKashi('उपस्थिति दर 70% वा बढी छ');
                }
                if(Language == 4){
                    setKashi('පැමිණීමේ අනුපාතය 70% හෝ ඊට වැඩි ය');
                }
                if(Language == 5){
                    setKashi('တက်ရောက်မှုနှုန်း 70% သို့မဟုတ် ထို့ထက်ပိုပါသည်။');
                }
                if(Language == 6){
                    setKashi('উপস্থিতির হার 70% বা তার বেশি');
                }
            }
            if(counter >= 242.8){
                if(Language == 0){
                    setKashi('学校のテストをぜんぶ受けること');
                }
                if(Language == 1){
                    setKashi('Taking all school examinations');
                }
                if(Language == 2){
                    setKashi('②參加所有學校考試');
                }
                if(Language == 3){
                    setKashi('स्कुलको सबै परीक्षाहरू दिएको हुनुपर्छ।');
                }
                if(Language == 4){
                    setKashi('සියලුම පාසල් විභාග කරන්න');
                }
                if(Language == 5){
                    setKashi('ကျောင်းစာမေးပွဲအားလုံးဖြေပါ။');
                }
                if(Language == 6){
                    setKashi('সমস্ত স্কুল পরীক্ষা দিন');
                }
            }
            if(counter >= 248.7){
                if(Language == 0){
                    setKashi('毎年JLPTを受けること');
                }
                if(Language == 1){
                    setKashi('Taking the JLPT every year');
                }
                if(Language == 2){
                    setKashi('③每年參加日本語能力考試');
                }
                if(Language == 3){
                    setKashi('हरेक वर्ष JLPTजापानीज भाषा परीक्षामा सहभागी लिएको  हुनुपर्छ।');
                }
                if(Language == 4){
                    setKashi('සෑම වසරකම JLPT ගැනීම');
                }
                if(Language == 5){
                    setKashi('နှစ်စဉ် JLPT ဖြေဆိုခြင်း။');
                }
                if(Language == 6){
                    setKashi('প্রতি বছর JLPT নেওয়া');
                }
            }
            if(counter >= 255.1){
                if(Language == 0){
                    setKashi('学校のお金をぜんぶはらうことです。');
                }
                if(Language == 1){
                    setKashi('Paying all school fees');
                }
                if(Language == 2){
                    setKashi('④支付所有的學費');
                }
                if(Language == 3){
                    setKashi('विद्यालय शुल्क सबै तिरेको हुनुपर्छ।');
                }
                if(Language == 4){
                    setKashi('පාසල සඳහා සියලු මුදල් ගෙවන්න');
                }
                if(Language == 5){
                    setKashi('ကျောင်းစရိတ်ကိုအားလုံးပေးဆောင်ခြင်း။');
                }
                if(Language == 6){
                    setKashi('স্কুলের জন্য সমস্ত টাকা পরিশোধ করা');
                }
            }
            if(counter >= 261.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            // たばこ
            if(counter >= 263.8){
                if(Language == 0){
                    setKashi('たばこ');
                }
                if(Language == 1){
                    setKashi('Tobacco');
                }
                if(Language == 2){
                    setKashi('菸草');
                }
                if(Language == 3){
                    setKashi('धुम्रपान');
                }
                if(Language == 4){
                    setKashi('දුම්වැටි');
                }
                if(Language == 5){
                    setKashi('ဆေးလိပ်');
                }
                if(Language == 6){
                    setKashi('তামাক');
                }
            }
            if(counter >= 267.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 270.6){
                if(Language == 0){
                    setKashi('日本でお酒やたばこは何歳からいいか、知っていますか。');
                }
                if(Language == 1){
                    setKashi('Do you know from what age is okay to drink alcohol or smoke in Japan?');
                }
                if(Language == 2){
                    setKashi('在日本幾歲可以喝酒和抽煙？');
                }
                if(Language == 3){
                    setKashi('तिमीहरूलाई जापानमा धुम्रपान र रक्सी सेवन गर्नको लागि कति उमेर पुगेको हुनुपर्छ थाहा छ?');
                }
                if(Language == 4){
                    setKashi('ජපානයේ මත්පැන් පානය කිරීම සහ දුම් පානය කිරීම සුදුසු වන්නේ කුමන වයසේදීද?');
                }
                if(Language == 5){
                    setKashi('ဂျပန်မှာ အရက်နဲ့ ဆေးလိပ်သောက်ရင် ဘယ်အရွယ်မှာ အဆင်ပြေလဲ။');
                }
                if(Language == 6){
                    setKashi('কোন বয়সে জাপানে অ্যালকোহল পান করা বা ধূমপান করা ঠিক?');
                }
            }
            if(counter >= 274.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 275.8){
                if(Language == 0){
                    setKashi('20歳からです。');
                }
                if(Language == 1){
                    setKashi('It is from 20 years old.');
                }
                if(Language == 2){
                    setKashi('從20歲開始。');
                }
                if(Language == 3){
                    setKashi('२० वर्ष पुगेको हुनुपर्छ।');
                }
                if(Language == 4){
                    setKashi('මම වයස අවුරුදු 20 සිට.');
                }
                if(Language == 5){
                    setKashi('ငါအသက် 20 မှ။');
                }
                if(Language == 6){
                    setKashi('আমার বয়স 20 বছর।');
                }
            }
            if(counter >= 280){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 281.4){
                if(Language == 0){
                    setKashi('たばこをすいたい人は、きつえんじょですってください。');
                }
                if(Language == 1){
                    setKashi("If you want to smoke, please do in school's smoking area.");
                }
                if(Language == 2){
                    setKashi('如果您想吸煙，請在吸煙區吸煙。');
                }
                if(Language == 3){
                    setKashi('यदि तपाईं धुम्रपान गर्न चाहनुहुन्छ भने, कृपया धुम्रपान गर्ने ठाउँमा गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබට දුම් පානය කිරීමට අවශ්‍ය නම්, කරුණාකර දුම්පානය කරන කාමරයට යන්න.');
                }
                if(Language == 5){
                    setKashi('ဆေးလိပ်သောက်ချင်ရင်ဆေးလိပ်သောက်လို့ရမည့်နေရာတွင်သောက်ရန်။');
                }
                if(Language == 6){
                    setKashi('আপনি যদি ধূমপান করতে চান তবে দয়া করে একজন ধূমপায়ীর সাথে তা করুন।');
                }
            }
            if(counter >= 289.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 290.9){
                if(Language == 0){
                    setKashi('きつえんじょは１Fにあります。');
                }
                if(Language == 1){
                    setKashi('Smoking area is on the ground floor.');
                }
                if(Language == 2){
                    setKashi('吸煙區位於一樓。');
                }
                if(Language == 3){
                    setKashi('धुम्रपान क्षेत्र पहिलो तलामा छ।');
                }
                if(Language == 4){
                    setKashi('දුම් පානය කරන ස්ථානය 1 වන මහලේ ඇත.');
                }
                if(Language == 5){
                    setKashi('ဆေးလိပ်သောက်သည့်နေရာသည် ပထမထပ်တွင်ရှိသည်။');
                }
                if(Language == 6){
                    setKashi('Kitsuenjo 1ম তলায় আছে।');
                }
            }
            if(counter >= 296.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 322.8){
                if(Language == 0){
                    setKashi('きつえんじょを使ったあとは、ドアのかぎをかけてください。');
                }
                if(Language == 1){
                    setKashi('After you smoke, please lock the door.');
                }
                if(Language == 2){
                    setKashi('離開時請鎖好門。');
                }
                if(Language == 3){
                    setKashi('कृपया धुम्रपान गर्ने ठाउँ प्रयोग गर्दा प्रयोग गरेर फर्किँदा ढोका बन्द गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබ යන විට දොර අගුළු දමන්න.');
                }
                if(Language == 5){
                    setKashi('ပြန်လာသည့်အခါ တံခါးကို ပြန်ပိတ်ပေးပါ။');
                }
                if(Language == 6){
                    setKashi('আপনি চলে গেলে দরজা লক করুন.');
                }
            }
            if(counter >= 330.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 346.2){
                if(Language == 0){
                    setKashi('日本では、道にたばこをすててはいけません。');
                }
                if(Language == 1){
                    setKashi("In Japan you are not allowed to throw Tobacco on the street.");
                }
                if(Language == 2){
                    setKashi('在日本不要把香菸丟在街上。');
                }
                if(Language == 3){
                    setKashi('जापानमा सडकमा चुरोटको ठुटा फाल्न पाइँदैन।');
                }
                if(Language == 4){
                    setKashi('ජපානයේ සිගරට් පාරේ විසි නොකරන්න.');
                }
                if(Language == 5){
                    setKashi('ဂျပန်မှာ စီးကရက်တွေကို လမ်းပေါ်မှာ မစွန့်ပစ်ပါနဲ့။');
                }
                if(Language == 6){
                    setKashi('আপনি জাপানে রাস্তায় সিগারেট ফেলতে পারবেন না।');
                }
            }
            if(counter >= 350.8){
                if(Language == 0){
                    setKashi('けいさつが見たら、2000円はらわなければなりません。');
                }
                if(Language == 1){
                    setKashi('If the police see it, you must have to pay 2000 yen.');
                }
                if(Language == 2){
                    setKashi('如果警察看到的話，就要繳2000日圓。');
                }
                if(Language == 3){
                    setKashi('प्रहरीले देखेमा २००० येन  तिर्नुपर्ने हुन्छ।');
                }
                if(Language == 4){
                    setKashi('පොලිසිය දැක්කොත් යෙන් 2000ක් ගෙවන්න වෙයි.');
                }
                if(Language == 5){
                    setKashi('ရဲတွေတွေ့ရင် ယန်း ၂၀၀၀ ပေးရမယ်။');
                }
                if(Language == 6){
                    setKashi('যদি Keisatsu এটা দেখে, আপনাকে 2000 ইয়েন দিতে হবে।');
                }
            }
            if(counter >= 355.2){
                if(Language == 0){
                    setKashi('道でたばこをすうとき、けいたいはいざらを使ってください。');
                }
                if(Language == 1){
                    setKashi('Please use a portable ashtray when smoking on the street.');
                }
                if(Language == 2){
                    setKashi('在街上吸煙時請使用便攜式煙灰缸。');
                }
                if(Language == 3){
                    setKashi('सडकमा धुम्रपान गर्दा कृपया पोर्टेबल एशट्रे प्रयोग गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('වීථියේ දුම් පානය කරන විට කරුණාකර අතේ ගෙන යා හැකි අළු බඳුනක් භාවිතා කරන්න.');
                }
                if(Language == 5){
                    setKashi('လမ်းပေါ်မှာ ဆေးလိပ်သောက်တဲ့အခါ အိတ်ဆောင်ပြာခွက်ကို သုံးပါ။');
                }
                if(Language == 6){
                    setKashi('রাস্তায় ধূমপান করার সময়, অনুগ্রহ করে একটি সিগারেট ব্যবহার করুন।');
                }
            }
            if(counter >= 359.6){
                if(Language == 0){
                    setKashi('100円ショップで買うことができます。');
                }
                if(Language == 1){
                    setKashi('You can buy it at the 100 yen shop.');
                }
                if(Language == 2){
                    setKashi('可以在百元商店購買。');
                }
                if(Language == 3){
                    setKashi('तपाईं यसलाई 100 येन पसलहरूमा किन्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබට එය යෙන් සාප්පු 100 කින් මිලදී ගත හැකිය.');
                }
                if(Language == 5){
                    setKashi('ယန်း 100 ဆိုင်တွေမှာ ဝယ်လို့ရပါတယ်။');
                }
                if(Language == 6){
                    setKashi('আপনি এটি 100 ইয়েনের দোকানে কিনতে পারেন।');
                }
            }
            if(counter >= 363.1){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            // 自転車
            if(counter >= 365.3){
                if(Language == 0){
                    setKashi('自転車');
                }
                if(Language == 1){
                    setKashi('Bicycle');
                }
                if(Language == 2){
                    setKashi('自行車');
                }
                if(Language == 3){
                    setKashi('साइकल');
                }
                if(Language == 4){
                    setKashi('බයිසිකලය');
                }
                if(Language == 5){
                    setKashi('စက်ဘီး');
                }
                if(Language == 6){
                    setKashi('সাইকেল');
                }
            }
            if(counter >= 366.2){
                if(Language == 0){
                    setKashi('日本はみなさんの国と自転車のルールがちがいます。');
                }
                if(Language == 1){
                    setKashi('Japan has different rules for cycling than your country.');
                }
                if(Language == 2){
                    setKashi('日本的自行車規則與您的國家不同。');
                }
                if(Language == 3){
                    setKashi('जापानमा साइकल चलाउनको लागि आफ्नो देश भन्दा फरक नियमहरू छन्।');
                }
                if(Language == 4){
                    setKashi('ජපානයේ බයිසිකල් පැදීම සඳහා ඔබේ රටට වඩා වෙනස් නීති තිබේ.');
                }
                if(Language == 5){
                    setKashi('ဂျပန်နိုင်ငံသည် စက်ဘီးစီးရန် မတူညီသော စည်းမျဉ်းများရှိသည်။');
                }
                if(Language == 6){
                    setKashi('জাপানে সাইকেল চালানোর জন্য আপনার দেশের চেয়ে আলাদা নিয়ম রয়েছে৷');
                }
            }
            if(counter >= 370.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 372.9){
                if(Language == 0){
                    setKashi('学校の前で自転車をおりてください。');
                }
                if(Language == 1){
                    setKashi('Please get off your bicycle in front of the school.');
                }
                if(Language == 2){
                    setKashi('請在學校前面下車。');
                }
                if(Language == 3){
                    setKashi('कृपया स्कूलको अगाडि आफ्नो साइकलबाट ओर्लनुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබේ බයිසිකලයෙන් පාසල ඉදිරිපිටින් බැස යන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးပြုပြီး ကျောင်းရှေ့မှာ စက်ဘီးပေါ်ကဆင်းပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে স্কুলের সামনে আপনার সাইকেল থেকে নামুন।');
                }
            }
            if(counter >= 376.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 377.7){
                if(Language == 0){
                    setKashi('自転車をおりてから、ちゅうりんじょうに入ってください。');
                }
                if(Language == 1){
                    setKashi('Please get off your bicycle and enter the parking lot.');
                }
                if(Language == 2){
                    setKashi('請下車並進入停車場。');
                }
                if(Language == 3){
                    setKashi('कृपया आफ्नो साइकलबाट ओर्लनुहोस् र पार्किङ स्थलमा प्रवेश गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබේ බයිසිකලයෙන් බැස බයිසිකල්  නැවැත්වීමේ ස්ථානයට ඇතුළු වන්න.');
                }
                if(Language == 5){
                    setKashi('စက်ဘီးပေါ်ကဆင်းပြီး ကားပါကင်ထဲကို ဝင်ပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে আপনার সাইকেল থেকে নামুন এবং পার্কিং লটে প্রবেশ করুন।');
                }
            }
            if(counter >= 381.6){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 389){
                if(Language == 0){
                    setKashi('バイクや車で学校に来てはいけません。');
                }
                if(Language == 1){
                    setKashi('Do not come to school by motorcycle or car.');
                }
                if(Language == 2){
                    setKashi('不要騎自行車或汽車來學校。');
                }
                if(Language == 3){
                    setKashi('मोटरसाइकल वा कारबाट विद्यालय नआउनुहोस्।');
                }
                if(Language == 4){
                    setKashi('මෝටර් බයික්  හෝ කාර් එකෙන් පාසලට එන්න එපා.');
                }
                if(Language == 5){
                    setKashi('মোটর সাইকেল বা গাড়িতে স্কুলে আসবেন না।');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 394.1){
                if(Language == 0){
                    setKashi('自転車、電車、バスで来るのはいいです。');
                }
                if(Language == 1){
                    setKashi('You can come to school by bicycle, train, or bus.');
                }
                if(Language == 2){
                    setKashi('騎自行車、火車或公車前來都很方便。');
                }
                if(Language == 3){
                    setKashi('साइकल, रेल वा बसबाट आउनुहोस्।');
                }
                if(Language == 4){
                    setKashi('බයිසිකලයෙන්, දුම්රියෙන්, බස් එකෙන් එන එක හොඳයි.');
                }
                if(Language == 5){
                    setKashi('က်ဘီး၊ ရထား သို့မဟုတ် ဘတ်စ်ကားဖြင့် လာရန် ကောင်းပါတယ်။');
                }
                if(Language == 6){
                    setKashi('আপনি সাইকেল, ট্রেন বা বাসে আসতে পারেন।');
                }
            }
            if(counter >= 398.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 399){
                if(Language == 0){
                    setKashi('自転車はちゅうりんじょうに置いてください。');
                }
                if(Language == 1){
                    setKashi('Please leave your bicycle at the parking lot.');
                }
                if(Language == 2){
                    setKashi('請將自行車停放於自行車停車處。');
                }
                if(Language == 3){
                    setKashi('कृपया आफ्नो साइकल साइकल पार्किङ स्थलमा राख्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබේ බයිසිකලය බයිසිකල් ගාල් කරන ස්ථානයේ තබන්න.');
                }
                if(Language == 5){
                    setKashi('သင့်စက်ဘီးကို စက်ဘီးရပ်နားရာနေရာတွင် ထားခဲ့ပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে আপনার সাইকেল পার্কিং লটে রেখে দিন।');
                }
            }
            if(counter >= 403.1){
                if(Language == 0){
                    setKashi('自転車がなくなったせんぱいもたくさんいます。');
                }
                if(Language == 1){
                    setKashi("Don't spread out on the street when you are riding your bike with your friends");
                }
                if(Language == 2){
                    setKashi('有很多老年人丟了自行車。');
                }
                if(Language == 3){
                    setKashi('साइकल गुमाएका धेरै सिनियर विद्यार्थीहरू छन् ।');
                }
                if(Language == 4){
                    setKashi('බයිසිකල් නැති වෙච්ච ජ්‍යෙෂ්ඨයෝ ඕන තරම් ඉන්නවා.');
                }
                if(Language == 5){
                    setKashi('စက်ဘီးဆုံးရှုံးသွားတဲ့ သက်ကြီးရွယ်အိုတွေ အများကြီးရှိတယ်။');
                }
                if(Language == 6){
                    setKashi('অনেক ছাত্র আছে যারা তাদের সাইকেল হারিয়েছে।');
                }
            }
            if(counter >= 406.9){
                if(Language == 0){
                    setKashi('日本にもどろぼうがいますから、駅でもスーパーでもいつもかぎをかけてください。');
                }
                if(Language == 1){
                    setKashi('There are thieves in Japan, so please always lock your bicycle at the station and supermarket.');
                }
                if(Language == 2){
                    setKashi('日本有小偷，所以請在車站和超市鎖好門。');
                }
                if(Language == 3){
                    setKashi('जापानमा पनि चोरी हुने भएको हुँदा, कृपया स्टेशनहरू र सुपरमार्केटहरूमा सधैँ आफ्नो साइकलको चाबी लगाउने गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ජපානයේ සොරුන් සිටින බැවින් කරුණාකර සෑම විටම දුම්රිය ස්ථානවල සහ සුපිරි වෙළඳසැල්වලට යන විට * ඔබේ බයිසිකලය අගුලු  දමන්න.');
                }
                if(Language == 5){
                    setKashi('ဂျပန်မှာ သူခိုးတွေရှိတာကြောင့် ဘူတာရုံနဲ့ စူပါမားကတ်တွေမှာ သင့်တံခါးတွေကို အမြဲသော့ခတ်ထားပါ။');
                }
                if(Language == 6){
                    setKashi('জাপানে চোর আছে, তাই অনুগ্রহ করে সবসময় স্টেশন এবং সুপারমার্কেটে আপনার দরজা বন্ধ করে রাখুন।');
                }
            }
            if(counter >= 414){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 415.1){
                if(Language == 0){
                    setKashi('2人で乗ってはいけません。');
                }
                if(Language == 1){
                    setKashi('Do not ride with two people.');
                }
                if(Language == 2){
                    setKashi('請勿與兩人一起騎乘。');
                }
                if(Language == 3){
                    setKashi('साइकलमा दुईजना  चलाउन　पाइँदैन।');
                }
                if(Language == 4){
                    setKashi('දෙදෙනෙකු සමඟ පදින්න එපා.');
                }
                if(Language == 5){
                    setKashi('လူနှစ်ယောက်နဲ့ မစီးပါနဲ့။');
                }
                if(Language == 6){
                    setKashi("দু'জনের সাথে চড়বেন না।");
                }
            }
            if(counter >= 422.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi("");
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 423.2){
                if(Language == 0){
                    setKashi('道にひろがって自転車に乗ってはいけません。');
                }
                if(Language == 1){
                    setKashi("Don't ride your bike on the road.");
                }
                if(Language == 2){
                    setKashi('不要在路上分散騎自行車。');
                }
                if(Language == 3){
                    setKashi('सडकमा छरिएर साइकल चलाउन　पाइँदैन।　');
                }
                if(Language == 4){
                    setKashi('ඔබේ බයිසිකලය පාර අවහිර වන සේ පදින්න එපා.');
                }
                if(Language == 5){
                    setKashi('စက်ဘီးကို လမ်းမှာဖြန့်မစီးပါနဲ့။');
                }
                if(Language == 6){
                    setKashi('রাস্তায় আপনার বাইক চালাবেন না।');
                }
            }
            if(counter >= 430.6){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi("");
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 431.2){
                if(Language == 0){
                    setKashi('道じゃないところをとおってはいけません。');
                }
                if(Language == 1){
                    setKashi("");
                }
                if(Language == 2){
                    setKashi('請勿在非道路的道路上通行。');
                }
                if(Language == 3){
                    setKashi('सडक नभएको ठाउँमा साइकल चलाउन　पाइँदैन।');
                }
                if(Language == 4){
                    setKashi('මාර්ග නොවන මාර්ගවල ගමන් නොකරන්න.');
                }
                if(Language == 5){
                    setKashi('လမ်းမဟုတ်သောလမ်းများကို မဖြတ်သန်းရ။');
                }
                if(Language == 6){
                    setKashi('রাস্তা নয় এমন কিছু দিয়ে যাবেন না।');
                }
            }
            if(counter >= 438.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 439.4){
                if(Language == 0){
                    setKashi('かさをさして乗ってもいけません。');
                }
                if(Language == 1){
                    setKashi('Do not ride with your umbrella on.');
                }
                if(Language == 2){
                    setKashi('請勿帶傘騎行。');
                }
                if(Language == 3){
                    setKashi('छाता लिएर  साइकल चलाउन　पाइँदैन।');
                }
                if(Language == 4){
                    setKashi('කුඩයක් අතැතිව ගමන් නොකරන්න.');
                }
                if(Language == 5){
                    setKashi('ထီးဆောင်း၍ မစီးပါနှင့်။');
                }
                if(Language == 6){
                    setKashi('আপনার ছাতা নিয়ে চড়বেন না।');
                }
            }
            if(counter >= 452.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 453.3){
                if(Language == 0){
                    setKashi('イヤホンで音楽をききながら自転車に乗ってはいけません。');
                }
                if(Language == 1){
                    setKashi('Do not ride a bicycle while listening to music with earphones.');
                }
                if(Language == 2){
                    setKashi('不要邊騎腳踏車邊用耳機聽音樂。');
                }
                if(Language == 3){
                    setKashi('इयरफोन लगाएर संगीत सुन्दै साइकल चलाउन पाइँदैन।');
                }
                if(Language == 4){
                    setKashi('ඉයර්ෆෝන් දමා සංගීතයට සවන් දෙමින් බයිසිකලයක් පදින්න එපා.');
                }
                if(Language == 5){
                    setKashi('နားကြပ်တပ်ပြီး သီချင်းနားထောင်ရင်း စက်ဘီးမစီးပါနဲ့။');
                }
                if(Language == 6){
                    setKashi('ইয়ারফোন দিয়ে গান শোনার সময় সাইকেল চালাবেন না।');
                }
            }
            if(counter >= 466.6){
                if(Language == 0){
                    setKashi('電話をしながら乗ってもいけません。');
                }
                if(Language == 1){
                    setKashi('Do not ride while talking on the phone.');
                }
                if(Language == 2){
                    setKashi('請勿邊騎車邊打電話。');
                }
                if(Language == 3){
                    setKashi(' फोनमा कुरा गर्दै साइकल चलाउन पाइँदैन।');
                }
                if(Language == 4){
                    setKashi('දුරකථනයෙන් කතා කරමින් පදින්න එපා.');
                }
                if(Language == 5){
                    setKashi('ဖုန်းပြောနေရင်း မစီးပါနဲ့။');
                }
                if(Language == 6){
                    setKashi('ফোনে কথা বলার সময় আপনি বাইক চালাতে পারবেন না।');
                }
            }
            if(counter >= 472.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 476.6){
                if(Language == 0){
                    setKashi('自転車に乗りながら、たばこをすってはいけません。');
                }
                if(Language == 1){
                    setKashi('Do not smoke while riding a bicycle.');
                }
                if(Language == 2){
                    setKashi('騎自行車時請勿吸煙。');
                }
                if(Language == 3){
                    setKashi('धुम्रपान गर्दै　साइकल चलाउन पाइँदैन।');
                }
                if(Language == 4){
                    setKashi('බයිසිකල් පැදීමේදී දුම් පානය නොකරන්න.');
                }
                if(Language == 5){
                    setKashi('စက်ဘီးစီးရင်း ဆေးလိပ်မသောက်ပါနဲ့။');
                }
                if(Language == 6){
                    setKashi('সাইকেল চালানোর সময় ধূমপান করবেন না।');
                }
            }
            if(counter >= 481.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 484.8){
                if(Language == 0){
                    setKashi('学校の前で長い時間立って話さないでください。');
                }
                if(Language == 1){
                    setKashi('Please do not stand and talk for a long time in front of the school.');
                }
                if(Language == 2){
                    setKashi('請不要在學校前長時間站立說話。');
                }
                if(Language == 3){
                    setKashi('कृपया विद्यालयको अगाडि अथवा वरिपरि लामो समयसम्म उभिएर कुरा नगर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර පාසල ඉදිරිපිට දිගු වේලාවක් සිටගෙන කතා නොකරන්න.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းရှေ့မှာ အကြာကြီးရပ်ပြီး စကားမပြောပါနဲ့။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে স্কুলের সামনে দীর্ঘক্ষণ দাঁড়িয়ে কথা বলবেন না।');
                }
            }
            if(counter >= 489.6){
                if(Language == 0){
                    setKashi('車がきますからあぶないです。');
                }
                if(Language == 1){
                    setKashi("There are cars passing by so it's dangerous.");
                }
                if(Language == 2){
                    setKashi('很危險，因為有車來了。');
                }
                if(Language == 3){
                    setKashi('सडकमा गाडीहरू आउने　भएकोले खतरनाक छ।');
                }
                if(Language == 4){
                    setKashi('වාහනයක් එන නිසා භයානකයි.');
                }
                if(Language == 5){
                    setKashi('ကားတစ်စီးလာနေတာကြောင့် အန္တရာယ်ရှိတယ်။');
                }
                if(Language == 6){
                    setKashi('একটি গাড়ি আসছে, তাই এটি বিপজ্জনক।');
                }
            }
            if(counter >= 493.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 498.2){
                if(Language == 0){
                    setKashi('バイクや車に乗るときは、かならず保険に入ってください。');
                }
                if(Language == 1){
                    setKashi('When riding a motorcycle or car, be sure to get insurance.');
                }
                if(Language == 2){
                    setKashi('騎摩托車或汽車時，一定要買保險。');
                }
                if(Language == 3){
                    setKashi('मोटरसाइकल वा कार चलाउँदा, बीमा गर्न जरुरी छ।');
                }
                if(Language == 4){
                    setKashi('මෝටර් බයිසිකලයක් හෝ මෝටර් රථයක් ධාවනය කරන විට, රක්ෂණයක් ඇති බවට වග බලා ගන්න.');
                }
                if(Language == 5){
                    setKashi('မော်တော်ဆိုင်ကယ် သို့မဟုတ် ကားစီးသည့်အခါ အာမခံရှိထားရမည်။');
                }
                if(Language == 6){
                    setKashi('একটি মোটরসাইকেল বা গাড়ী চালানোর সময়, বীমা পেতে ভুলবেন না.');
                }
            }
            if(counter >= 506){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            if(counter >= 509){
                setMoveVideoTime(5);
                document.getElementById('video').currentTime = 0;
            }
        }

        //　6本目　証明書
        if(MoveVideoTime == 5){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn MChBChange');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
            setChapterColor14('MoveChapBtn');

            if(counter >= 0){
                setMoveVideo(Proof);
            }

            //　証明書（しょうめいしょ）
            if(counter >= 0.5){
                if(Language == 0){
                    setKashi('証明書（しょうめいしょ）');
                }
                if(Language == 1){
                    setKashi('certificate');
                }
                if(Language == 2){
                    setKashi('證書');
                }
                if(Language == 3){
                    setKashi('प्रमाणपत्र');
                }
                if(Language == 4){
                    setKashi('සහතිකය');
                }
                if(Language == 5){
                    setKashi('လက်မှတ်');
                }
                if(Language == 6){
                    setKashi('সনদপত্র');
                }
            }
            if(counter >= 3.5){
                if(Language == 0){
                    setKashi('NSA日本語学校が作る証明書は、');
                }
                if(Language == 1){
                    setKashi('The certificate prepared by NSA Japanese School');
                }
                if(Language == 2){
                    setKashi('NSA日本語學校製作的證書是');
                }
                if(Language == 3){
                    setKashi('NSA जापानी स्कूल द्वारा बनाईएको प्रमाणपत्रहरू निम्न प्रकारका छन्:-');
                }
                if(Language == 4){
                    setKashi('NSA ජපන් පාසල විසින් නිර්මාණය කරන ලද සහතිකය වේ');
                }
                if(Language == 5){
                    setKashi('NSA Japanese School မှ ဖန်တီးထားသော လက်မှတ်ဖြစ်ပါသည်။');
                }
                if(Language == 6){
                    setKashi('NSA জাপানিজ স্কুল দ্বারা তৈরি সার্টিফিকেট হল');
                }
            }
            if(counter >= 7.7){
                if(Language == 0){
                    setKashi('1まい300円です。');
                }
                if(Language == 1){
                    setKashi('costs 300 yen.');
                }
                if(Language == 2){
                    setKashi('一份300日圓。');
                }
                if(Language == 3){
                    setKashi('एउटा पेपरको ३००येन लाग्छ।');
                }
                if(Language == 4){
                    setKashi('එක් සහතිකයක් යෙන්300 කි.');
                }
                if(Language == 5){
                    setKashi('လက်မှတ်တစ်စောင် 300 ယန်း။');
                }
                if(Language == 6){
                    setKashi('1 মাই হল 300 ইয়েন।');
                }
            }
            if(counter >= 11){
                if(Language == 0){
                    setKashi('証明書がほしいときは');
                }
                if(Language == 1){
                    setKashi('When you need a certificate');
                }
                if(Language == 2){
                    setKashi('當您想要證書時');
                }
                if(Language == 3){
                    setKashi('जब तपाइँ प्रमाणपत्र चाहानुहुन्छ');
                }
                if(Language == 4){
                    setKashi('ඔබට සහතිකයක් අවශ්ය විට');
                }
                if(Language == 5){
                    setKashi('လက်မှတ်လိုချင်တဲ့အခါ');
                }
                if(Language == 6){
                    setKashi('যখন আপনি একটি শংসাপত্র চান');
                }
            }
            if(counter >= 13.7){
                if(Language == 0){
                    setKashi('1日前にじむしょの先生に話してください。');
                }
                if(Language == 1){
                    setKashi('Please inform a teacher at the office one day in advance.');
                }
                if(Language == 2){
                    setKashi('請提前一天向辦公室老師諮詢。');
                }
                if(Language == 3){
                    setKashi('कृपया एक दिन पहिले कार्यालयको शिक्षकसँग अनुरोध गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර දිනකට පෙර කාර්යාලයේ සිටින ගුරුවරයා සමඟ සාකච්ඡා කරන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးပြု၍ ရုံးတွင် တစ်ရက်ကြိုတင်၍ ဆရာနှင့် တိုင်ပင်ပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে একদিন আগে জিমুশোর শিক্ষকের সাথে কথা বলুন।');
                }
            }
            if(counter >= 18.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 19.4){
                if(Language == 0){
                    setKashi('学校のお金をはらっていない学生は、');
                }
                if(Language == 1){
                    setKashi('Students who do not pay the school fees are');
                }
                if(Language == 2){
                    setKashi('不繳學費的學生是');
                }
                if(Language == 3){
                    setKashi('विद्यालय शुल्क नतिर्ने विद्यार्थीहरूको');
                }
                if(Language == 4){
                    setKashi('පාසල් ගාස්තු නොගෙවන සිසුන් වේ');
                }
                if(Language == 5){
                    setKashi('ကျောင်းစရိတ်မပေးရသော ကျောင်းသား၊');
                }
                if(Language == 6){
                    setKashi('যারা স্কুলের জন্য অর্থ প্রদান করে না তারা হল');
                }
            }
            if(counter >= 23.5){
                if(Language == 0){
                    setKashi('証明書を作ることができません。');
                }
                if(Language == 1){
                    setKashi('Unable to receive certificate.');
                }
                if(Language == 2){
                    setKashi('無法建立證書。');
                }
                if(Language == 3){
                    setKashi('प्रमाणपत्र दिन असमर्थ हुनेछौं।');
                }
                if(Language == 4){
                    setKashi('සහතිකය සෑදිය නොහැක.');
                }
                if(Language == 5){
                    setKashi('လက်မှတ်ကို ဖန်တီး၍မရပါ။');
                }
                if(Language == 6){
                    setKashi('সার্টিফিকেট তৈরি করতে অক্ষম।');
                }
            }
            if(counter >= 27){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            // 卒業見込証明書（そつぎょうみこみしょうめいしょ）
            if(counter >= 29){
                if(Language == 0){
                    setKashi('卒業見込証明書（そつぎょうみこみしょうめいしょ）');
                }
                if(Language == 1){
                    setKashi('Certificate of expected graduation');
                }
                if(Language == 2){
                    setKashi('預計畢業證明');
                }
                if(Language == 3){
                    setKashi('अपेक्षित स्नातकको प्रमाणपत्र');
                }
                if(Language == 4){
                    setKashi('උපාධි පිළිබඳ සහතිකය');
                }
                if(Language == 5){
                    setKashi('မျှော်မှန်းဘွဲ့လက်မှတ်');
                }
                if(Language == 6){
                    setKashi('প্রত্যাশিত স্নাতকের শংসাপত্র');
                }
            }
            if(counter >= 32.5){
                if(Language == 0){
                    setKashi('卒業見込証明書は、');
                }
                if(Language == 1){
                    setKashi('The certificate of expected graduation');
                }
                if(Language == 2){
                    setKashi('預計畢業證書是');
                }
                if(Language == 3){
                    setKashi('अपेक्षित स्नातकको प्रमाणपत्र को लागि，');
                }
                if(Language == 4){
                    setKashi('අපේක්ෂිත උපාධිය පිළිබඳ සහතිකය වේ');
                }
                if(Language == 5){
                    setKashi('မျှော်မှန်းဘွဲ့လက်မှတ်');
                }
                if(Language == 6){
                    setKashi('প্রত্যাশিত স্নাতকের শংসাপত্র');
                }
            }
            if(counter >= 36){
                if(Language == 0){
                    setKashi('2年生の10月1日から作ることができます。');
                }
                if(Language == 1){
                    setKashi('can be made starting from October 1st of your second year.');
                }
                if(Language == 2){
                    setKashi('您可以從第二年的 10 月 1 日開始申請。');
                }
                if(Language == 3){
                    setKashi('तपाइँ यसलाई तपाइँको दोस्रो वर्षको अक्टोबर 1 देखि बनाउन सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබේ දෙවන වසරේ ඔක්තෝබර් 1 වනදා සිට ඔබට එය කළ හැකිය.');
                }
                if(Language == 5){
                    setKashi('သင်၏ဒုတိယနှစ်၏အောက်တိုဘာလ 1 ရက်မှသင်ပြုလုပ်နိုင်သည်။');
                }
                if(Language == 6){
                    setKashi('আপনি এটি আপনার দ্বিতীয় বছরের ১লা অক্টোবর থেকে করতে পারেন।');
                }
            }
            if(counter >= 41.2){
                if(Language == 0){
                    setKashi('しゅっせきが75%以上なければなりません。');
                }
                if(Language == 1){
                    setKashi('Attendance must be at least 75%.');
                }
                if(Language == 2){
                    setKashi('出勤率必須至少達到 75%。');
                }
                if(Language == 3){
                    setKashi('विद्यालयमा　उपस्थिति कम्तिमा 75% हुनुपर्छ।');
                }
                if(Language == 4){
                    setKashi('පැමිණීම අවම වශයෙන් 75% විය යුතුය.');
                }
                if(Language == 5){
                    setKashi('တက်ရောက်သူ အနည်းဆုံး 75% ရှိရပါမည်။');
                }
                if(Language == 6){
                    setKashi('Shusseki কমপক্ষে 75% হতে হবে।');
                }
            }
            if(counter >= 48.3){
                if(Language == 0){
                    setKashi('10月1日より前にほしい学生は');
                }
                if(Language == 1){
                    setKashi('Students who want it before October 1st');
                }
                if(Language == 2){
                    setKashi('10月1日前想要的同學');
                }
                if(Language == 3){
                    setKashi('अक्टोबर 1 अघि यो चाहने विद्यार्थीहरू');
                }
                if(Language == 4){
                    setKashi('ඔක්තෝබර් 1 ට පෙර එය අවශ්ය සිසුන්');
                }
                if(Language == 5){
                    setKashi('အောက်တိုဘာ ၁ ရက်နေ့ မတိုင်ခင် လိုချင်တဲ့ ကျောင်းသား၊');
                }
                if(Language == 6){
                    setKashi('যে ছাত্ররা 1লা অক্টোবরের আগে এটা চায়');
                }
            }
            if(counter >= 52.3){
                if(Language == 0){
                    setKashi('クラスの先生にそうだんしてください。');
                }
                if(Language == 1){
                    setKashi('Please consult about it with your class teacher.');
                }
                if(Language == 2){
                    setKashi('請諮詢您的班主任。');
                }
                if(Language == 3){
                    setKashi('कृपया आफ्नो कक्षा शिक्षक　सँग सल्लाह गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබේ පන්ති ගුරුවරයාගෙන් විමසන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးပြု၍ သင်၏အတန်းဆရာနှင့် တိုင်ပင်ပါ။');
                }
                if(Language == 6){
                    setKashi('আপনার ক্লাস টিচারকে বলুন।');
                }
            }
            if(counter >= 56){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 推薦書（すいせんしょ）
            if(counter >= 58.5){
                if(Language == 0){
                    setKashi('推薦書（すいせんしょ）');
                }
                if(Language == 1){
                    setKashi('letter of recommendation');
                }
                if(Language == 2){
                    setKashi('推薦信');
                }
                if(Language == 3){
                    setKashi('सिफारिस पत्र');
                }
                if(Language == 4){
                    setKashi('නිර්දේශ ලිපිය');
                }
                if(Language == 5){
                    setKashi('ထောက်ခံစာ');
                }
                if(Language == 6){
                    setKashi('সুপারিশপত্র');
                }
            }
            if(counter >= 62.5){
                if(Language == 0){
                    setKashi('専門学校や大学に出す推薦書は、');
                }
                if(Language == 1){
                    setKashi('Recommendation letters for vocational schools and universities');
                }
                if(Language == 2){
                    setKashi('職業學校和大學的推薦信是');
                }
                if(Language == 3){
                    setKashi('व्यावसायिक विद्यालय र विश्वविद्यालयहरूको लागि सिफारिस पत्रहरू छन्');
                }
                if(Language == 4){
                    setKashi('වෘත්තීය පාසල් සහ විශ්වවිද්‍යාල සඳහා නිර්දේශ ලිපි වේ');
                }
                if(Language == 5){
                    setKashi('သက်မွေးဝမ်းကျောင်းနှင့် တက္ကသိုလ်များအတွက် ထောက်ခံစာများ');
                }
                if(Language == 6){
                    setKashi('বৃত্তিমূলক স্কুল এবং বিশ্ববিদ্যালয়ের জন্য সুপারিশ চিঠি হয়');
                }
            }
            if(counter >= 66.7){
                if(Language == 0){
                    setKashi('2年生の10月1日から作ることができます。');
                }
                if(Language == 1){
                    setKashi('can be made starting from October 1st of your second year.');
                }
                if(Language == 2){
                    setKashi('您可以從第二年的 10 月 1 日開始申請。');
                }
                if(Language == 3){
                    setKashi('तपाइँ यसलाई तपाइँको दोस्रो वर्षको अक्टोबर 1 देखि बनाउन सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබේ දෙවන වසරේ ඔක්තෝබර් 1 වනදා සිට ඔබට එය කළ හැකිය.');
                }
                if(Language == 5){
                    setKashi('သင်၏ဒုတိယနှစ်၏အောက်တိုဘာလ 1 ရက်မှသင်ပြုလုပ်နိုင်သည်။');
                }
                if(Language == 6){
                    setKashi('আপনি এটি আপনার দ্বিতীয় বছরের 1লা অক্টোবর থেকে করতে পারেন।');
                }
            }
            if(counter >= 72){
                if(Language == 0){
                    setKashi('出席率が90%以上で、');
                }
                if(Language == 1){
                    setKashi('Attendance rate must be 90% or higher.');
                }
                if(Language == 2){
                    setKashi('出席率達90%以上，');
                }
                if(Language == 3){
                    setKashi('उपस्थिति दर 90% भन्दा बढी छ,');
                }
                if(Language == 4){
                    setKashi('පැමිණීමේ අනුපාතය 90% ඉක්මවයි,');
                }
                if(Language == 5){
                    setKashi('တက်ရောက်နှုန်း 90% ကျော်၊');
                }
                if(Language == 6){
                    setKashi('উপস্থিতির হার 90% বা তার বেশি।');
                }
            }
            if(counter >= 75.3){
                if(Language == 0){
                    setKashi('せいせきがB以上なければなりません。');
                }
                if(Language == 1){
                    setKashi('Grade must be B or higher.');
                }
                if(Language == 2){
                    setKashi('成績必須為 B 或更高。');
                }
                if(Language == 3){
                    setKashi('ग्रेड B वा उच्च हुनुपर्छ।');
                }
                if(Language == 4){
                    setKashi('ශ්‍රේණි B හෝ ඊට වැඩි විය යුතුය.');
                }
                if(Language == 5){
                    setKashi('အဆင့် B နှင့်အထက်ဖြစ်ရမည်။');
                }
                if(Language == 6){
                    setKashi('গ্রেড B বা উচ্চতর হতে হবে।');
                }
            }
            if(counter >= 81){
                if(Language == 0){
                    setKashi('推薦書が欲しい学生は');
                }
                if(Language == 1){
                    setKashi('Students who want a letter of recommendation');
                }
                if(Language == 2){
                    setKashi('想要推薦信的學生');
                }
                if(Language == 3){
                    setKashi('विद्यार्थीहरू जो सिफारिसको पत्र चाहन्छन्');
                }
                if(Language == 4){
                    setKashi('නිර්දේශ ලිපියක් අවශ්‍ය සිසුන්');
                }
                if(Language == 5){
                    setKashi('ထောက်ခံစာလိုချင်တဲ့ ကျောင်းသား၊');
                }
                if(Language == 6){
                    setKashi('ছাত্র যারা সুপারিশ একটি চিঠি চান');
                }
            }
            if(counter >= 83.3){
                if(Language == 0){
                    setKashi('クラスの先生にそうだんしてください。');
                }
                if(Language == 1){
                    setKashi('Please consult with your class teacher.');
                }
                if(Language == 2){
                    setKashi('請諮詢您的班主任老師。');
                }
                if(Language == 3){
                    setKashi('कृपया आफ्नो कक्षा शिक्षक　सँग सल्लाह गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබේ පන්ති ගුරුවරයාගෙන් විමසන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးပြု၍ သင်၏အတန်းဆရာနှင့် တိုင်ပင်ပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে আপনার ক্লাস শিক্ষকের সাথে পরামর্শ করুন।');
                }
            }
            if(counter >= 88.5){
                if(Language == 0){
                    setKashi('卒業した後は、');
                }
                if(Language == 1){
                    setKashi('After graduating,');
                }
                if(Language == 2){
                    setKashi('畢業以後，');
                }
                if(Language == 3){
                    setKashi('स्नातक गरेपछि,');
                }
                if(Language == 4){
                    setKashi('උපාධිය ලැබීමෙන් පසු,');
                }
                if(Language == 5){
                    setKashi('ဘွဲ့ရပြီး၊');
                }
                if(Language == 6){
                    setKashi('স্নাতক হওয়ার পর,');
                }
            }
            if(counter >= 90.3){
                if(Language == 0){
                    setKashi('推薦書を作ることができません。');
                }
                if(Language == 1){
                    setKashi('You wont be able to get a recommendation letter.');
                }
                if(Language == 2){
                    setKashi('您無法建立推薦信。');
                }
                if(Language == 3){
                    setKashi('प्रमाणपत्र दिन असमर्थ हुनेछौं।');
                }
                if(Language == 4){
                    setKashi('මට නිර්දේශ ලිපියක් සෑදිය නොහැක.');
                }
                if(Language == 5){
                    setKashi('အကြံပြုစာတစ်စောင် ဖန်တီး၍မရပါ။');
                }
                if(Language == 6){
                    setKashi('আমি একটি সুপারিশ চিঠি তৈরি করতে অক্ষম।');
                }
            }
            if(counter >= 94.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            if(counter >= 96){
                setMoveVideoTime(6);
                document.getElementById('video').currentTime = 0;
            }
        }
        
        //　7本目　授業時間
        if(MoveVideoTime == 6){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn MChBChange');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
            setChapterColor14('MoveChapBtn');

            if(counter >= 0){
                setMoveVideo(Lesson);
            }

            //　授業時間と受付
            if(counter >= 10){
                if(Language == 0){
                    setKashi('授業時間と受付');
                }
                if(Language == 1){
                    setKashi('attendance');
                }
                if(Language == 2){
                    setKashi('上課時間及出席率');
                }
                if(Language == 3){
                    setKashi('कक्षा सञ्चालन हुने समय र हाजिर गर्ने ठाउँ');
                }
                if(Language == 4){
                    setKashi('පැමිණීම');
                }
                if(Language == 5){
                    setKashi('တက်ရောက်ခြင်း။');
                }
                if(Language == 6){
                    setKashi('উপস্থিতি');
                }
            }
            if(counter >= 13){
                if(Language == 0){
                    setKashi('午前クラスの学生は、8:50までに教室に来てください。');
                }
                if(Language == 1){
                    setKashi('For students in morning classes, please come to the classroom by 8:50.');
                }
                if(Language == 2){
                    setKashi('上午班的學生應在8點50分前到達教室。');
                }
                if(Language == 3){
                    setKashi('बिहानको कक्षामा विद्यार्थीहरू 8:50 सम्म कक्षाकोठामा आइपुग्नु पर्छ।');
                }
                if(Language == 4){
                    setKashi('උදෑසන පන්තිවල සිසුන් 8:50 වන විට පන්ති කාමරයට පැමිණිය යුතුය.');
                }
                if(Language == 5){
                    setKashi('မနက်ပိုင်း အတန်းတက်တဲ့ ကျောင်းသားတွေအနေနဲ့ 8:50 မှာ စာသင်ခန်းကို လာသင့်ပါတယ်။');
                }
                if(Language == 6){
                    setKashi('সকালের ক্লাসে শিক্ষার্থীদের জন্য, অনুগ্রহ করে 8:50 এর মধ্যে শ্রেণীকক্ষে আসুন।');
                }
            }
            if(counter >= 19.5){
                if(Language == 0){
                    setKashi('9:00から12:20まで勉強します。');
                }
                if(Language == 1){
                    setKashi('You will study from 9:00 to 12:20.');
                }
                if(Language == 2){
                    setKashi('上午班的學生會從9:00到12:20學習。');
                }
                if(Language == 3){
                    setKashi('9:00 देखि 12:20 सम्म अध्ययन हुन्छ।');
                }
                if(Language == 4){
                    setKashi('මම 9:00 සිට 12:20 දක්වා පාඩම් කරන්නෙමි.');
                }
                if(Language == 5){
                    setKashi('9:00 မှ 12:20 ထိ စာသင်ပါမည်။');
                }
                if(Language == 6){
                    setKashi('আমরা9:00 থেকে 12:20 পর্যন্ত পড়াশোনা করব।');
                }
            }
            if(counter >= 24.5){
                if(Language == 0){
                    setKashi('午後クラスの学生は、13:20までに教室に来てください。');
                }
                if(Language == 1){
                    setKashi('Students in afternoon classes, please come to the classroom by 1:20 p.m.');
                }
                if(Language == 2){
                    setKashi('下午課程的學生應在下午 1 點 20 分之前到達教室。');
                }
                if(Language == 3){
                    setKashi('दिउँसो कक्षाका विद्यार्थीहरू दिउँसो 13:20 बजेभित्र कक्षाकोठामा आइपुग्नु पर्नेछ।');
                }
                if(Language == 4){
                    setKashi('දහවල් පන්තිවල සිසුන් දහවල් 1:20 වන විට පන්ති කාමරයට පැමිණිය යුතුය.');
                }
                if(Language == 5){
                    setKashi('နေ့လယ်စာ သင်ကြားနေသော ကျောင်းသားများသည် နေ့လည် 1:20 တွင် စာသင်ခန်းသို့ လာရောက်သင့်ပါသည်။');
                }
                if(Language == 6){
                    setKashi('বিকালের ক্লাসে শিক্ষার্থীরা, অনুগ্রহ করে 1:20 টার মধ্যে ক্লাসরুমে আসুন।');
                }
            }
            if(counter >= 30.2){
                if(Language == 0){
                    setKashi('13:30から16:50まで勉強します。');
                }
                if(Language == 1){
                    setKashi('You will study from 13:30 to 16:50.');
                }
                if(Language == 2){
                    setKashi('下午課程的學生會從13:30到16:50學習。');
                }
                if(Language == 3){
                    setKashi('13:30 देखि 16:50 सम्म अध्ययन हुन्छ।');
                }
                if(Language == 4){
                    setKashi('මම 13:30 සිට 16:50 දක්වා පාඩම් කරන්නෙමි.');
                }
                if(Language == 5){
                    setKashi('13:30 မှ 16:50 ထိ စာသင်ပါမည်။');
                }
                if(Language == 6){
                    setKashi('আমরা 13:30 থেকে 16:50 পর্যন্ত পড়াশোনা করব।');
                }
            }
            if(counter >= 35){
                if(Language == 0){
                    setKashi('受付であいさつをしてから入らないとけっせきになりますから気をつけてください。');
                }
                if(Language == 1){
                    setKashi('Please be careful as you will be marked absent if you go to the classroom without greeting at the reception desk.');
                }
                if(Language == 2){
                    setKashi('請注意，如果在接待處問候後不進入，您將缺席。');
                }
                if(Language == 3){
                    setKashi('रिसेप्शन डेस्क（हाजिर गर्ने ठाउँ）मा　तपाईँ हाजिर गरेर जानुभएन भने तपाईंको हाजिर मानिने छैन सावधान रहनुहोस्।');
                }
                if(Language == 4){
                    setKashi('පිළිගැනීමේ මේසයට ආචාර කිරීමට නොපැමිනේනම් ,පාසලට නොපැමිණිලෙස සටහන් වන  බැවින් ප්‍රවේශම් වන්න.');
                }
                if(Language == 5){
                    setKashi('ဧည့်ခံကောင်တာတွင် နှုတ်ဆက်ပြီးနောက် မဝင်ပါက ပျက်ကွက်သွားမည်ကို သတိပြုပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে মনে রাখবেন যে আপনি যদি প্রবেশের আগে অভ্যর্থনাকারীকে অভিবাদন না করেন তবে আপনি ক্লাসে অনুপস্থিত থাকবেন।');
                }
            }
            if(counter >= 41){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 42){
                if(Language == 0){
                    setKashi('ぼうし・イヤホン・サングラスをつけてあいさつをしてはいけません。');
                }
                if(Language == 1){
                    setKashi('Do not do the greeting while wearing hats, earphones, or sunglasses.');
                }
                if(Language == 2){
                    setKashi('請勿戴著帽子、耳機或墨鏡與他人打招呼。');
                }
                if(Language == 3){
                    setKashi('टोपी, इयरफोन वा सनग्लास लगाएर  अभिवादन नगर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('තොප්පියක්, ඉයර්ෆෝන් හෝ අව් කණ්ණාඩි පැළඳ සිටින විට අන් අයට ආචාර නොකරන්න.');
                }
                if(Language == 5){
                    setKashi('ဦးထုပ်၊ နားကြပ် သို့မဟုတ် နေကာမျက်မှန် တပ်ထားစဉ် အခြားသူများကို မနှုတ်ဆက်ပါနှင့်။');
                }
                if(Language == 6){
                    setKashi('টুপি, ইয়ারফোন বা সানগ্লাস পরে লোকেদের শুভেচ্ছা জানাবেন না।');
                }
            }
            if(counter >= 53.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 57.2){
                if(Language == 0){
                    setKashi('名前をはっきり言ってください。');
                }
                if(Language == 1){
                    setKashi('Please say your name clearly.');
                }
                if(Language == 2){
                    setKashi('請清楚說出你的名字。');
                }
                if(Language == 3){
                    setKashi('कृपया आफ्नो नाम स्पष्ट रूपमा भन्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබේ නම පැහැදිලිව කියන්න.');
                }
                if(Language == 5){
                    setKashi('မင်းနာမည်ကို ရှင်းရှင်းလင်းလင်းပြောပါ။');
                }
                if(Language == 6){
                    setKashi('আপনার নাম স্পষ্টভাবে বলুন।');
                }
            }
            if(counter >= 65.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // テスト
            if(counter >= 68.5){
                if(Language == 0){
                    setKashi('テスト');
                }
                if(Language == 1){
                    setKashi('test');
                }
                if(Language == 2){
                    setKashi('測試');
                }
                if(Language == 3){
                    setKashi('परीक्षा');
                }
                if(Language == 4){
                    setKashi('පරීක්ෂණය');
                }
                if(Language == 5){
                    setKashi('စမ်းသပ်');
                }
                if(Language == 6){
                    setKashi('পরীক্ষা');
                }
            }
            if(counter >= 72.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 101.8){
                if(Language == 0){
                    setKashi('半年に2回大きいテストがあります。');
                }
                if(Language == 1){
                    setKashi('There are two big tests every six months.');
                }
                if(Language == 2){
                    setKashi('六個月內有兩次測試。');
                }
                if(Language == 3){
                    setKashi('छ महिनामा दुईवटा　ठूला परीक्षा हुन्छन् ।');
                }
                if(Language == 4){
                    setKashi('මාස හයකට පරීක්ෂණ දෙකක් තියෙනවා.');
                }
                if(Language == 5){
                    setKashi('ခြောက်လအတွင်း စမ်းသပ်မှု နှစ်ခုရှိသည်။');
                }
                if(Language == 6){
                    setKashi('প্রতি ছয় মাসে দুটি বড় পরীক্ষা আছে।');
                }
            }
            if(counter >= 106.5){
                if(Language == 0){
                    setKashi('半年に1回クラスが変わります。');
                }
                if(Language == 1){
                    setKashi('Classes change once every six months.');
                }
                if(Language == 2){
                    setKashi('課程每六個月更換一次。');
                }
                if(Language == 3){
                    setKashi('कक्षाहरू प्रत्येक छ महिनामा एक पटक परिवर्तन हुन्छ।');
                }
                if(Language == 4){
                    setKashi('සෑම මාස හයකට වරක් පන්ති වෙනස් වේ.');
                }
                if(Language == 5){
                    setKashi('ခြောက်လတစ်ကြိမ် အတန်းပြောင်းသည်။');
                }
                if(Language == 6){
                    setKashi('প্রতি ছয় মাসে একবার ক্লাস পরিবর্তন হয়।');
                }
            }
            if(counter >= 111.6){
                if(Language == 0){
                    setKashi('せいせきが悪い学生は、らくだいします。');
                }
                if(Language == 1){
                    setKashi('Students with poor grades will be failed.');
                }
                if(Language == 2){
                    setKashi('成績不好的學生會失敗。');
                }
                if(Language == 3){
                    setKashi('कमजोर ग्रेड भएका विद्यार्थीहरू　तल्लो कक्षामा झर्नेछन्।');
                }
                if(Language == 4){
                    setKashi('දුර්වල ලකුණු ඇති සිසුන් අසමත් වනු ඇත.');
                }
                if(Language == 5){
                    setKashi('အမှတ်မကောင်းတဲ့ ကျောင်းသားတွေ ကျရှုံးမယ်။');
                }
                if(Language == 6){
                    setKashi('খারাপ গ্রেডের ছাত্ররা ফেল করবে।');
                }
            }
            if(counter >= 117.3){
                if(Language == 0){
                    setKashi('テストをうけない学生は、そつぎょうできません。');
                }
                if(Language == 1){
                    setKashi('Students who do not take the test cannot graduate.');
                }
                if(Language == 2){
                    setKashi('不參加考試的學生將無法畢業。');
                }
                if(Language == 3){
                    setKashi('परीक्षा नदिने विद्यार्थीले स्नातक गर्न पाउने छैनन् ।');
                }
                if(Language == 4){
                    setKashi('විභාගයට පෙනී නොසිටින සිසුන්ට උපාධිය ලබා ගැනීමට නොහැකි වනු ඇත.');
                }
                if(Language == 5){
                    setKashi('စာမေးပွဲမဖြေသော ကျောင်းသားများသည် ဘွဲ့ရရှိမည်မဟုတ်ပါ။');
                }
                if(Language == 6){
                    setKashi('পরীক্ষা না দিয়ে আপনি স্নাতক হতে পারবেন না।');
                }
            }
            if(counter >= 127){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 教科書
            if(counter >= 130.5){
                if(Language == 0){
                    setKashi('教科書');
                }
                if(Language == 1){
                    setKashi('textbook');
                }
                if(Language == 2){
                    setKashi('教科書');
                }
                if(Language == 3){
                    setKashi('पाठ्यपुस्तक');
                }
                if(Language == 4){
                    setKashi('පෙළ පොත');
                }
                if(Language == 5){
                    setKashi('ကျောင်းစာအုပ်');
                }
                if(Language == 6){
                    setKashi('পাঠ্যপুস্তক');
                }
            }
            if(counter >= 134){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 143.3){
                if(Language == 0){
                    setKashi('教科書です。');
                }
                if(Language == 1){
                    setKashi('There are Textbook.');
                }
                if(Language == 2){
                    setKashi('這是一本教科書。');
                }
                if(Language == 3){
                    setKashi('यो पाठ्यपुस्तक हो।');
                }
                if(Language == 4){
                    setKashi('ඒක පාඩම් පොතක්.');
                }
                if(Language == 5){
                    setKashi('အဲဒါက ကျောင်းသုံးစာအုပ်ပါ။');
                }
                if(Language == 6){
                    setKashi('পাঠ্যপুস্তক।');
                }
            }
            if(counter >= 147){
                if(Language == 0){
                    setKashi('文法の本');
                }
                if(Language == 1){
                    setKashi('Grammar book');
                }
                if(Language == 2){
                    setKashi('文法書');
                }
                if(Language == 3){
                    setKashi('व्याकरण पुस्तक');
                }
                if(Language == 4){
                    setKashi('ව්යාකරණ පොත');
                }
                if(Language == 5){
                    setKashi('သဒ္ဒါစာအုပ်');
                }
                if(Language == 6){
                    setKashi('ব্যাকরণ বই');
                }
            }
            if(counter >= 149){
                if(Language == 0){
                    setKashi('文法ノート');
                }
                if(Language == 1){
                    setKashi('Grammar Notes');
                }
                if(Language == 2){
                    setKashi('文法筆記');
                }
                if(Language == 3){
                    setKashi('व्याकरण कपी');
                }
                if(Language == 4){
                    setKashi('ව්යාකරණ සටහන්');
                }
                if(Language == 5){
                    setKashi('သဒ္ဒါမှတ်စုများ');
                }
                if(Language == 6){
                    setKashi('ব্যাকরণ নোট');
                }
            }
            if(counter >= 151){
                if(Language == 0){
                    setKashi('漢字の本');
                }
                if(Language == 1){
                    setKashi('Kanji book');
                }
                if(Language == 2){
                    setKashi('漢字書');
                }
                if(Language == 3){
                    setKashi('खान्जी किताब');
                }
                if(Language == 4){
                    setKashi('කන්ජි පොත');
                }
                if(Language == 5){
                    setKashi('ကန်ဂျီစာအုပ်');
                }
                if(Language == 6){
                    setKashi('কাঞ্জি বই');
                }
            }
            if(counter >= 152.5){
                if(Language == 0){
                    setKashi('漢字ノート');
                }
                if(Language == 1){
                    setKashi('Kanji notes');
                }
                if(Language == 2){
                    setKashi('漢字筆記');
                }
                if(Language == 3){
                    setKashi('खान्जी नोटहरू');
                }
                if(Language == 4){
                    setKashi('කන්ජි සටහන්');
                }
                if(Language == 5){
                    setKashi('ကန်ဂျီမှတ်စုများ');
                }
                if(Language == 6){
                    setKashi('কাঞ্জি নোট');
                }
            }
            if(counter >= 154){
                if(Language == 0){
                    setKashi('作文ファイルがあります。');
                }
                if(Language == 1){
                    setKashi('and an essay file.');
                }
                if(Language == 2){
                    setKashi('論文文件。');
                }
                if(Language == 3){
                    setKashi('यहाँ निबन्ध लेख्ने फाइल छ।');
                }
                if(Language == 4){
                    setKashi('මා සතුව රචනා ගොනුවක් තිබේ.');
                }
                if(Language == 5){
                    setKashi('ငါ့မှာ စာစီစာကုံးဖိုင်ရှိတယ်။');
                }
                if(Language == 6){
                    setKashi('একটি রচনা ফাইল আছে।');
                }
            }
            if(counter >= 157){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // なふだ
            if(counter >= 159.5){
                if(Language == 0){
                    setKashi('なふだ');
                }
                if(Language == 1){
                    setKashi('name tag');
                }
                if(Language == 2){
                    setKashi('名稱標籤');
                }
                if(Language == 3){
                    setKashi('नाम लेखिएको कार्ड');
                }
                if(Language == 4){
                    setKashi('Name Tag');
                }
                if(Language == 5){
                    setKashi('အမည်တံဆိပ်');
                }
                if(Language == 6){
                    setKashi('নাম নির্দেশক');
                }
            }
            if(counter >= 162.5){
                if(Language == 0){
                    setKashi('受付であいさつをしてから教室に入ってください。');
                }
                if(Language == 1){
                    setKashi('Please greet at the reception and then enter the classroom.');
                }
                if(Language == 2){
                    setKashi('進入教室前請先在接待處打招呼。');
                }
                if(Language == 3){
                    setKashi('कृपया कक्षाकोठामा प्रवेश गर्नु अघि रिसेप्शन डेस्क（हाजिर गर्ने ठाउँ）मा अभिवादन गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර පන්ති කාමරයට ඇතුළු වීමට පෙර පිළිගැනීමේ මේසයට ආචාර කරන්න.');
                }
                if(Language == 5){
                    setKashi('စာသင်ခန်းထဲမ၀င်ခင် ဧည့်ခံကောင်တာမှာ နှုတ်ဆက်ပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে অভ্যর্থনায় অভ্যর্থনা জানান এবং তারপর শ্রেণীকক্ষে প্রবেশ করুন।');
                }
            }
            if(counter >= 168){
                if(Language == 0){
                    setKashi('教室に入るとき、あいさつをしてください。');
                }
                if(Language == 1){
                    setKashi('Please greet when you enter the classroom.');
                }
                if(Language == 2){
                    setKashi('進入教室時請打招呼。');
                }
                if(Language == 3){
                    setKashi('कृपया कक्षाकोठामा प्रवेश गर्दा　पनि सबैलाई अभिवादन गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබ පන්ති කාමරයට ඇතුළු වන විට කරුණාකර ආයුබෝවන් කියන්න.');
                }
                if(Language == 5){
                    setKashi('စာသင်ခန်းထဲဝင်တဲ့အခါ နှုတ်ဆက်ပါ။');
                }
                if(Language == 6){
                    setKashi('আপনি ক্লাসরুমে প্রবেশ করার সময় অনুগ্রহ করে হ্যালো বলুন।');
                }
            }
            if(counter >= 174.3){
                if(Language == 0){
                    setKashi('教室に入ったら、なふだをとってすわってください。');
                }
                if(Language == 1){
                    setKashi('When you enter the classroom, please take your name tag and sit down.');
                }
                if(Language == 2){
                    setKashi('進入教室後，請攜帶姓名牌入座。');
                }
                if(Language == 3){
                    setKashi('कक्षामा प्रवेश गर्दा, कृपया आफ्नो नाम लेखिएको कार्ड लिनुहोस् र सिटमा बस्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබ පන්ති කාමරයට ඇතුළු වූ විට, කරුණාකර ඔබේ Name tag එක රැගෙන අසුන් ගන්න.');
                }
                if(Language == 5){
                    setKashi('စာသင်ခန်းထဲသို့ဝင်သောအခါ ကျေးဇူးပြု၍ သင်၏အမည်ကဒ်ပြားကိုယူ၍ ထိုင်ပါ။');
                }
                if(Language == 6){
                    setKashi('আপনি যখন শ্রেণীকক্ষে প্রবেশ করবেন, অনুগ্রহ করে আপনার নাম ট্যাগ নিন এবং একটি আসনে বসুন');
                }
            }
            if(counter >= 184.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 授業のあいさつ
            if(counter >= 188.7){
                if(Language == 0){
                    setKashi('授業のあいさつ');
                }
                if(Language == 1){
                    setKashi('Greetings at the start and end of class');
                }
                if(Language == 2){
                    setKashi('上課開始和結束時的問候');
                }
                if(Language == 3){
                    setKashi('कक्षाको सुरु र अन्त्यमा अभिवादन');
                }
                if(Language == 4){
                    setKashi('පන්තියේ ආරම්භයේ සහ අවසානයේ අචාර කිරීම්');
                }
                if(Language == 5){
                    setKashi('အတန်းအစနဲ့ အဆုံးမှာ နှုတ်ဆက်ပါတယ်။');
                }
                if(Language == 6){
                    setKashi('ক্লাসের শুরুতে এবং শেষে শুভেচ্ছা');
                }
            }
            if(counter >= 191.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 192.5){
                if(Language == 0){
                    setKashi('授業が始まるときと終わるとき、あいさつをします。');
                }
                if(Language == 1){
                    setKashi('We greet each other at the beginning and end of class.');
                }
                if(Language == 2){
                    setKashi('上課開始和結束時的問候。');
                }
                if(Language == 3){
                    setKashi('कक्षा सञ्चालन हुनुअघि र कक्षा सञ्चालन सकिएपछि अभिवादन गरिनेछ।');
                }
                if(Language == 4){
                    setKashi('පන්තියේ ආරම්භයේ සහ අවසානයේ ආචාර කිරීම.');
                }
                if(Language == 5){
                    setKashi('အတန်းအစနဲ့ အဆုံး နှုတ်ဆက်ပါတယ်။');
                }
                if(Language == 6){
                    setKashi('আমরা ক্লাসের শুরুতে এবং শেষে একে অপরকে অভিবাদন জানাই।');
                }
            }
            if(counter >= 198){
                if(Language == 0){
                    setKashi('みなさん、始めましょう。');
                }
                if(Language == 1){
                    setKashi("Everyone, let's get started.");
                }
                if(Language == 2){
                    setKashi('大家，讓我們開始吧。');
                }
                if(Language == 3){
                    setKashi('सबैजना, सुरु गरौं।');
                }
                if(Language == 4){
                    setKashi('හැමෝම, අපි පටන් ගනිමු.');
                }
                if(Language == 5){
                    setKashi('အားလုံးပဲ စလိုက်ကြရအောင်။');
                }
                if(Language == 6){
                    setKashi('সবাই, শুরু করা যাক।');
                }
            }
            if(counter >= 199.7){
                if(Language == 0){
                    setKashi('きりつ。');
                }
                if(Language == 1){
                    setKashi('Stand up.');
                }
                if(Language == 2){
                    setKashi('起來。');
                }
                if(Language == 3){
                    setKashi('कृपया　खडा हुनुहोस्।');
                }
                if(Language == 4){
                    setKashi('නැගිටින්න.');
                }
                if(Language == 5){
                    setKashi('မတ်တတ်ထပါ။');
                }
                if(Language == 6){
                    setKashi('কিরিটসু।');
                }
            }
            if(counter >= 203.3){
                if(Language == 0){
                    setKashi('れい。');
                }
                if(Language == 1){
                    setKashi('Attention!');
                }
                if(Language == 2){
                    setKashi('謝謝。');
                }
                if(Language == 3){
                    setKashi('धन्यवाद।');
                }
                if(Language == 4){
                    setKashi('ලැස්ති වෙන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးတင်ပါသည်။');
                }
                if(Language == 6){
                    setKashi('রেই');
                }
            }
            if(counter >= 204.2){
                if(Language == 0){
                    setKashi('おはようございます。');
                }
                if(Language == 1){
                    setKashi('Good morning');
                }
                if(Language == 2){
                    setKashi('早安.');
                }
                if(Language == 3){
                    setKashi('शुभ - प्रभात।');
                }
                if(Language == 4){
                    setKashi('සුභ උදෑසනක්.');
                }
                if(Language == 5){
                    setKashi('မင်္ဂလာနံနက်ခင်းပါ');
                }
                if(Language == 6){
                    setKashi('শুভ সকাল');
                }
            }
            if(counter >= 205.5){
                if(Language == 0){
                    setKashi('よろしくお願いします。');
                }
                if(Language == 1){
                    setKashi('Thank you.');
                }
                if(Language == 2){
                    setKashi('謝謝。');
                }
                if(Language == 3){
                    setKashi('धन्यवाद।');
                }
                if(Language == 4){
                    setKashi('ඔයාට ස්තූතියි.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးတင်ပါသည်။');
                }
                if(Language == 6){
                    setKashi('ধন্যবাদ।');
                }
            }
            if(counter >= 208.5){
                if(Language == 0){
                    setKashi('ちゃくせき。');
                }
                if(Language == 1){
                    setKashi('Seated');
                }
                if(Language == 2){
                    setKashi('坐下。');
                }
                if(Language == 3){
                    setKashi('कृपया　बस्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඉඳ ගන්න.');
                }
                if(Language == 5){
                    setKashi('ထိုင်ပါ။');
                }
                if(Language == 6){
                    setKashi('চাকুসেকি');
                }
            }
            if(counter >= 209.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 215.7){
                if(Language == 0){
                    setKashi('おわりましょう。');
                }
                if(Language == 1){
                    setKashi("Let's finish.");
                }
                if(Language == 2){
                    setKashi('讓我們結束吧。');
                }
                if(Language == 3){
                    setKashi('अन्त्य गरौं।');
                }
                if(Language == 4){
                    setKashi('අපි අවසන් කරමු.');
                }
                if(Language == 5){
                    setKashi('အဆုံးသတ်ကြပါစို့။');
                }
                if(Language == 6){
                    setKashi('শেষ করা যাক।');
                }
            }
            if(counter >= 218){
                if(Language == 0){
                    setKashi('きりつ。');
                }
                if(Language == 1){
                    setKashi('Stand up.');
                }
                if(Language == 2){
                    setKashi('起來。');
                }
                if(Language == 3){
                    setKashi('कृपया　खडा हुनुहोस्। अभिवादन।');
                }
                if(Language == 4){
                    setKashi('කෙළින් සිටින.');
                }
                if(Language == 5){
                    setKashi('မတ်တတ်ထပါ။');
                }
                if(Language == 6){
                    setKashi('কিরিটসু।');
                }
            }
            if(counter >= 220.5){
                if(Language == 0){
                    setKashi('れい。');
                }
                if(Language == 1){
                    setKashi('Thanks');
                }
                if(Language == 2){
                    setKashi('謝謝。');
                }
                if(Language == 3){
                    setKashi('धन्यवाद।');
                }
                if(Language == 4){
                    setKashi('ඔයාට ස්තූතියි.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးတင်ပါသည်။');
                }
                if(Language == 6){
                    setKashi('রেই');
                }
            }
            if(counter >= 221.5){
                if(Language == 0){
                    setKashi('ありがとうございました。');
                }
                if(Language == 1){
                    setKashi('Thank you very much');
                }
                if(Language == 2){
                    setKashi('非常感謝。');
                }
                if(Language == 3){
                    setKashi('मुरी मुरी धन्यवाद।');
                }
                if(Language == 4){
                    setKashi('ඔයාට බොහෝම ස්තූතියි.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးအများကြီးတင်ပါတယ်။');
                }
                if(Language == 6){
                    setKashi('আপনাকে অনেক ধন্যবাদ');
                }
            }
            if(counter >= 223.2){
                if(Language == 0){
                    setKashi('さようなら。');
                }
                if(Language == 1){
                    setKashi('Goodbye');
                }
                if(Language == 2){
                    setKashi('再見。');
                }
                if(Language == 3){
                    setKashi('अलविदा।');
                }
                if(Language == 4){
                    setKashi('ආයුබෝවන්.');
                }
                if(Language == 5){
                    setKashi('သွားတော့မယ်။');
                }
                if(Language == 6){
                    setKashi('বিদায়');
                }
            }
            if(counter >= 226){
                if(Language == 0){
                    setKashi('ちゃくせき。');
                }
                if(Language == 1){
                    setKashi('Dismissed!');
                }
                if(Language == 2){
                    setKashi('坐下。');
                }
                if(Language == 3){
                    setKashi('कृपया　बस्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඉඳ ගන්න.');
                }
                if(Language == 5){
                    setKashi('ထိုင်ပါ။');
                }
                if(Language == 6){
                    setKashi('চাকুসেকি');
                }
            }
            if(counter >= 227.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // ミニテスト
            if(counter >= 233.3){
                if(Language == 0){
                    setKashi('ミニテスト');
                }
                if(Language == 1){
                    setKashi('mini test');
                }
                if(Language == 2){
                    setKashi('迷你測試');
                }
                if(Language == 3){
                    setKashi('मिनी परीक्षण');
                }
                if(Language == 4){
                    setKashi('කුඩා පරීක්ෂණය');
                }
                if(Language == 5){
                    setKashi('အသေးစားစမ်းသပ်မှု');
                }
                if(Language == 6){
                    setKashi('মিনি পরীক্ষা');
                }
            }
            if(counter >= 237){
                if(Language == 0){
                    setKashi('毎日、授業のはじめにミニテストをします。');
                }
                if(Language == 1){
                    setKashi('We will take a mini-test at the beginning of each class.');
                }
                if(Language == 2){
                    setKashi('每節課開始時都會有一個小測驗。');
                }
                if(Language == 3){
                    setKashi('प्रत्येक दिन　कक्षाको सुरुमा एउटा सानो-परीक्षा हुनेछ।');
                }
                if(Language == 4){
                    setKashi('සෑම පන්තියකම ආරම්භයේදීම කුඩා පරීක්ෂණයක් ඇත.');
                }
                if(Language == 5){
                    setKashi('အတန်းတစ်ခုစီ၏အစတွင် mini-test တစ်ခုရှိလိမ့်မည်။');
                }
                if(Language == 6){
                    setKashi('আমরা প্রতিটি ক্লাসের শুরুতে একটি ছোট-টেস্ট নেব।');
                }
            }
            if(counter >= 244){
                if(Language == 0){
                    setKashi('ことばと漢字のテストです。');
                }
                if(Language == 1){
                    setKashi("It's Vocabulary and Kanji test.");
                }
                if(Language == 2){
                    setKashi('這是單字和漢字的測驗。');
                }
                if(Language == 3){
                    setKashi('यो शब्द र कांजी को परीक्षण हो।');
                }
                if(Language == 4){
                    setKashi('මෙය වචන සහ කන්ජි පරීක්ෂණයකි.');
                }
                if(Language == 5){
                    setKashi('ဒါက kotobaနဲ့ ခန်ဂျီ စမ်းသပ်မှုပါ။');
                }
                if(Language == 6){
                    setKashi('এটি একটি শব্দভান্ডার এবং কাঞ্জি পরীক্ষা।');
                }
            }
            if(counter >= 249){
                if(Language == 0){
                    setKashi('前の日に勉強したことば、漢字をしっかりふくしゅうしてください。');
                }
                if(Language == 1){
                    setKashi('Make sure to review the vocabularies  and kanji you studied the previous day.');
                }
                if(Language == 2){
                    setKashi('請務必複習前一天學習的單字和漢字。');
                }
                if(Language == 3){
                    setKashi('तपाईंले अघिल्लो दिन अध्ययन गर्नुभएको शब्द र खान्जी समीक्षा गर्न निश्चित गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබ පෙර දින ඉගෙන ගත් වචන සහ කන්ජි පාඩම්කි කිරීමට වග බලා ගන්න.');
                }
                if(Language == 5){
                    setKashi('ယခင်နေ့က သင်လေ့လာခဲ့သော စကားလုံးများနှင့် ကန်ဂျီများကို သေချာသုံးသပ်ပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে আপনি আগের দিন অধ্যয়ন করা শব্দ এবং কাঞ্জি মনে রাখবেন।');
                }
            }
            if(counter >= 258){
                if(Language == 0){
                    setKashi('名前はカタカナのフルネームで書いてください。');
                }
                if(Language == 1){
                    setKashi('Please write your full name in katakana.');
                }
                if(Language == 2){
                    setKashi('請用片假名寫下您的全名。');
                }
                if(Language == 3){
                    setKashi('कृपया आफ्नो पूरा नाम काताकानामा लेख्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබේ සම්පූර්ණ නම katakana හි ලියන්න.');
                }
                if(Language == 5){
                    setKashi('မင်းနာမည်အပြည့်အစုံကို katakana မှာရေးပေးပါ။');
                }
                if(Language == 6){
                    setKashi('কাতাকানায় আপনার পুরো নাম লিখুন।');
                }
            }
            if(counter >= 263){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 265){
                setMoveVideoTime(7);
                document.getElementById('video').currentTime = 0;
            }
        }

        //　8本目　日本語で話そう
        if(MoveVideoTime == 7){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn MChBChange');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
            setChapterColor14('MoveChapBtn');

            if(counter >= 0){
                setMoveVideo(Ruhr);
            }

            //  日本語で話そう
            if(counter >= 1){
                if(Language == 0){
                    setKashi('日本語で話そう');
                }
                if(Language == 1){
                    setKashi("Let's speak in Japanese");
                }
                if(Language == 2){
                    setKashi('我們來說說日語吧');
                }
                if(Language == 3){
                    setKashi('जापानी भाषामा बोल्ने बानी बसालौँ।');
                }
                if(Language == 4){
                    setKashi('අපි ජපන් භාෂාව කතා කරමු');
                }
                if(Language == 5){
                    setKashi('ဂျပန်စကားပြောရအောင်');
                }
                if(Language == 6){
                    setKashi('আসুন জাপানি কথা বলি');
                }
            }
            if(counter >= 3.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 13.7){
                if(Language == 0){
                    setKashi('授業のときは、日本語だけで話してください。');
                }
                if(Language == 1){
                    setKashi('Please speak only in Japanese during class.');
                }
                if(Language == 2){
                    setKashi('上課時請只說日語。');
                }
                if(Language == 3){
                    setKashi('कक्षाको समयमा, कृपया जापानी मात्र बोल्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('පන්තිය අතරතුර, කරුණාකර ජපන් භාෂාව පමණක් කතා කරන්න.');
                }
                if(Language == 5){
                    setKashi('စာသင်ချိန်အတွင်း ဂျပန်စကားကိုသာ ပြောပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে ক্লাস চলাকালীন শুধুমাত্র জাপানি ভাষায় কথা বলুন।');
                }
            }
            if(counter >= 18.8){
                if(Language == 0){
                    setKashi('国のことばで友達と話さないでください。');
                }
                if(Language == 1){
                    setKashi("Don't talk to your friends in your country's language.");
                }
                if(Language == 2){
                    setKashi('不要用您所在國家的語言與您的朋友交談。');
                }
                if(Language == 3){
                    setKashi('आफ्ना साथीहरूसँग आफ्नो देशको भाषामा कुरा नगर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබේ රටේ භාෂාවෙන් ඔබේ මිතුරන් සමඟ කතා නොකරන්න.');
                }
                if(Language == 5){
                    setKashi('သင့်တိုင်းပြည်၏ဘာသာစကားဖြင့် သင့်သူငယ်ချင်းများနှင့် စကားမပြောပါနှင့်။');
                }
                if(Language == 6){
                    setKashi('আপনার বন্ধুদের সাথে আপনার দেশের ভাষায় কথা বলবেন না।');
                }
            }
            if(counter >= 25){
                if(Language == 0){
                    setKashi('みなさん勉強していますから、うるさくしないでください。');
                }
                if(Language == 1){
                    setKashi("Everyone is studying, so please don't make noise.");
                }
                if(Language == 2){
                    setKashi('大家都在學習，請不要大驚小怪。');
                }
                if(Language == 3){
                    setKashi('सबैजना पढिरहेका छन्, त्यसैले कृपया हल्ला नगर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('හැමෝම පාඩම් කරන නිසා කරුණාකරලා කලබල වෙන්න එපා.');
                }
                if(Language == 5){
                    setKashi('အားလုံးလေ့လာနေတာမို့ စိတ်ရှုပ်မခံပါနဲ့။');
                }
                if(Language == 6){
                    setKashi('সবাই পড়াশোনা করছে, তাই আওয়াজ করবেন না।');
                }
            }
            if(counter >= 31.6){
                if(Language == 0){
                    setKashi('しつもんがあるときは、先生にしつもんしてください。');
                }
                if(Language == 1){
                    setKashi('If you have any questions, please ask your teacher.');
                }
                if(Language == 2){
                    setKashi('如果您有任何疑問，請詢問您的老師。');
                }
                if(Language == 3){
                    setKashi('यदि तपाइँसँग कुनै प्रश्नहरू छन् भने, कृपया तपाइँको शिक्षकलाई सोध्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබට කිසියම් ප්‍රශ්නයක් ඇත්නම්, කරුණාකර ඔබේ ගුරුවරයාගෙන් විමසන්න.');
                }
                if(Language == 5){
                    setKashi('မေးစရာများရှိရင် ဆရာ့ကို မေးပါ။');
                }
                if(Language == 6){
                    setKashi('যদি আপনার কোন প্রশ্ন থাকে, অনুগ্রহ করে আপনার শিক্ষককে জিজ্ঞাসা করুন।');
                }
            }
            if(counter >= 37){
                if(Language == 0){
                    setKashi('きゅうけいのときも日本語で話して、');
                }
                if(Language == 1){
                    setKashi('Even during breaks, speak in Japanese,');
                }
                if(Language == 2){
                    setKashi('即使休息的時候請日語交談，');
                }
                if(Language == 3){
                    setKashi('विश्रामको समयमा पनि जापानी　भाषामा बोल्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('විවේක කාලයේදී පවා කතා කරන්න භාෂාවෙන්,');
                }
                if(Language == 5){
                    setKashi('အားလပ်ချိန်မှာတောင် ဂျပန်လိုပြော၊');
                }
                if(Language == 6){
                    setKashi('বিরতির সময়ও, আমরা জাপানি ভাষায় কথা বলে');
                }
            }
            if(counter >= 40.2){
                if(Language == 0){
                    setKashi('ほかの国の友達をたくさん作ってください。');
                }
                if(Language == 1){
                    setKashi('Make a lot of friends from other countries.');
                }
                if(Language == 2){
                    setKashi('請結交很多來自其他國家的朋友。');
                }
                if(Language == 3){
                    setKashi('कृपया अन्य  देशका साथीहरू धेरैभन्दा धेरै बनाउनुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර වෙනත් රටවලින් බොහෝ මිතුරන් ඇති කර ගන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးပြု၍ အခြားနိုင်ငံမှ သူငယ်ချင်းများ များများလုပ်ပါ။');
                }
                if(Language == 6){
                    setKashi('অন্যান্য দেশ থেকে প্রচুর বন্ধু তৈরি করুন।');
                }
            }
            if(counter >= 44.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // けいたい電話
            if(counter >= 48){
                if(Language == 0){
                    setKashi('けいたい電話');
                }
                if(Language == 1){
                    setKashi('mobile phone');
                }
                if(Language == 2){
                    setKashi('智慧型手機');
                }
                if(Language == 3){
                    setKashi('मोबाइल फोन');
                }
                if(Language == 4){
                    setKashi('ජංගම දුරකථන');
                }
                if(Language == 5){
                    setKashi('မိုဘိုင်းဖုန်း');
                }
                if(Language == 6){
                    setKashi('মোবাইল ফোন');
                }
            }
            if(counter >= 50.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 62.5){
                if(Language == 0){
                    setKashi('授業のとき、けいたい電話は');
                }
                if(Language == 1){
                    setKashi('Do not use your mobile phone during class.');
                }
                if(Language == 2){
                    setKashi('上課時請勿使用手機');
                }
                if(Language == 3){
                    setKashi('कक्षाको समयमा आफ्नो सेल फोन प्रयोग नगर्नुहोस्');
                }
                if(Language == 4){
                    setKashi('පන්ති අතරතුර ඔබගේ ජංගම දුරකථනය භාවිතා නොකරන්න');
                }
                if(Language == 5){
                    setKashi('စာသင်ချိန်အတွင်း လက်ကိုင်ဖုန်းကို မသုံးပါနှင့်');
                }
                if(Language == 6){
                    setKashi('ক্লাস চলাকালীন, ফোন চালু থাকে');
                }
            }
            if(counter >= 65){
                if(Language == 0){
                    setKashi('マナーモードにして、');
                }
                if(Language == 1){
                    setKashi('Set to silent mode,');
                }
                if(Language == 2){
                    setKashi('設定為靜音模式，');
                }
                if(Language == 3){
                    setKashi('मोबाइल साइलेन्ट मोडमा सेट गर्नुहोस्,');
                }
                if(Language == 4){
                    setKashi('නිහඬ ප්‍රකාරයට සකසන්න,');
                }
                if(Language == 5){
                    setKashi('အသံတိတ်မုဒ်သို့ သတ်မှတ်ရန်၊');
                }
                if(Language == 6){
                    setKashi('নীরব মোডে সেট করুন,');
                }
            }
            if(counter >= 67){
                if(Language == 0){
                    setKashi('かばんに入れて');
                }
                if(Language == 1){
                    setKashi('Put it in your bag');
                }
                if(Language == 2){
                    setKashi('把它放進您的包裡');
                }
                if(Language == 3){
                    setKashi('आफ्नो झोला मा राख्नुहोस्');
                }
                if(Language == 4){
                    setKashi('එය ඔබේ බෑගයට දමන්න');
                }
                if(Language == 5){
                    setKashi('သင့်အိတ်ထဲတွင်ထည့်ပါ။');
                }
                if(Language == 6){
                    setKashi('এটি আপনার ব্যাগে রাখুন');
                }
            }
            if(counter >= 69.3){
                if(Language == 0){
                    setKashi('かばんをしめてください。');
                }
                if(Language == 1){
                    setKashi('Please close your bag.');
                }
                if(Language == 2){
                    setKashi('請關上您的包。');
                }
                if(Language == 3){
                    setKashi('कृपया आफ्नो झोला बन्द गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබේ බෑගය වසා දමන්න.');
                }
                if(Language == 5){
                    setKashi('သင့်အိတ်ကိုပိတ်ပါ။');
                }
                if(Language == 6){
                    setKashi('আপনার ব্যাগ বন্ধ করুন.');
                }
            }
            if(counter >= 74){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 76.5){
                if(Language == 0){
                    setKashi('けいたい電話はポケットに入れてはいけません。');
                }
                if(Language == 1){
                    setKashi("Don't put your mobile phone in your pocket.");
                }
                if(Language == 2){
                    setKashi('不要將手機放在口袋裡。');
                }
                if(Language == 3){
                    setKashi('आफ्नो सेल फोन आफ्नो खल्ती मा नराख्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබගේ ජංගම දුරකථනය ඔබගේ සාක්කුවේ තබා නොගන්න.');
                }
                if(Language == 5){
                    setKashi('လက်ကိုင်ဖုန်းကို အိတ်ကပ်ထဲ ထည့်မထားပါနဲ့။');
                }
                if(Language == 6){
                    setKashi('আপনার পকেটে সেল ফোন বহন করবেন না।');
                }
            }
            if(counter >= 82.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 84.7){
                if(Language == 0){
                    setKashi('授業中にけいたい電話を見ないでください。');
                }
                if(Language == 1){
                    setKashi("Please don't look at your mobile phone during class.");
                }
                if(Language == 2){
                    setKashi('上課時請不要看手機。');
                }
                if(Language == 3){
                    setKashi('कृपया कक्षाको समयमा आफ्नो सेल फोन नहेर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර පන්ති අතරතුර ඔබගේ ජංගම දුරකථනය දෙස නොබලන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးပြုပြီး အတန်းချိန်အတွင်း သင့်လက်ကိုင်ဖုန်းကို မကြည့်ပါနဲ့။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে ক্লাস চলাকালীন টেলিফোনের দিকে তাকাবেন না।');
                }
            }
            if(counter >= 89){
                if(Language == 0){
                    setKashi('先生がけいたい電話を使ってもいいと言ったときだけ、使ってもいいです。');
                }
                if(Language == 1){
                    setKashi("You can only use your mobile phone when your teacher allows it.");
                }
                if(Language == 2){
                    setKashi('只有在老師允許的情況下，您才可以使用手機。');
                }
                if(Language == 3){
                    setKashi('तपाईले आफ्नो सेल फोन  प्रयोग गर्न सक्नुहुन्छ यदि तपाईको शिक्षकले तपाईलाई त्यसो गर्न अनुमति दिनुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබට ඔබේ ජංගම දුරකථනය භාවිත කළ හැක්කේ ඔබේ ගුරුවරයා ඔබට එසේ කිරීමට ඉඩ දෙන්නේ නම් පමණි.');
                }
                if(Language == 5){
                    setKashi('မင်းရဲ့ဆရာက မင်းကိုခွင့်ပြုမှသာလျှင် မင်းရဲ့ဆဲလ်ဖုန်းကို သုံးနိုင်တယ်။');
                }
                if(Language == 6){
                    setKashi('আপনি সেল ফোন ব্যবহার করতে পারেন শুধুমাত্র যদি আপনার শিক্ষক বলেন এটা ঠিক আছে।');
                }
            }
            if(counter >= 95.5){
                if(Language == 0){
                    setKashi('もし、けいたい電話を見たら');
                }
                if(Language == 1){
                    setKashi('If you are seen using mobile phone,');
                }
                if(Language == 2){
                    setKashi('如果您看看您的手機，');
                }
                if(Language == 3){
                    setKashi('यदि सेलफोन हेर्नुभयो भने,');
                }
                if(Language == 4){
                    setKashi('ඔබ ඔබේ ජංගම දුරකථනය දෙස බැලුවහොත්,');
                }
                if(Language == 5){
                    setKashi('စာသင်မချိန်တွင်း လက်ကိုင်ဖုန်းကို ကြည့်လိုက်ရင်');
                }
                if(Language == 6){
                    setKashi('আপনি যদি একটি সেল ফোন দেখতে পান,');
                }
            }
            if(counter >= 98.5){
                if(Language == 0){
                    setKashi('授業がおわるまで');
                }
                if(Language == 1){
                    setKashi('Until class ends');
                }
                if(Language == 2){
                    setKashi('直到下課');
                }
                if(Language == 3){
                    setKashi('कक्षा समाप्त नभएसम्म');
                }
                if(Language == 4){
                    setKashi('පන්තිය අවසන් වන තුරු');
                }
                if(Language == 5){
                    setKashi('အတန်းပြီးသည်အထိ');
                }
                if(Language == 6){
                    setKashi('ক্লাস শেষ না হওয়া পর্যন্ত');
                }
            }
            if(counter >= 100){
                if(Language == 0){
                    setKashi('先生がけいたい電話をあずかります。');
                }
                if(Language == 1){
                    setKashi('The teacher will take away the mobile phone.');
                }
                if(Language == 2){
                    setKashi('老師會保留你的手機。');
                }
                if(Language == 3){
                    setKashi('शिक्षकले तपाईको सेल फोन राख्नेछन्।');
                }
                if(Language == 4){
                    setKashi('ගුරුවරයා ඔබේ ජංගම දුරකථනය තබා ගනීවි.');
                }
                if(Language == 5){
                    setKashi('ဆရာက မင်းရဲ့လက်ကိုင်ဖုန်းကို သိမ်းထားလိမ့်မယ်။');
                }
                if(Language == 6){
                    setKashi('আপনার শিক্ষক আপনার সেল ফোন অফিসে নিয়ে যাবেন।');
                }
            }
            if(counter >= 104.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 飲食
            if(counter >= 108.7){
                if(Language == 0){
                    setKashi('飲食');
                }
                if(Language == 1){
                    setKashi('Eating and drinking');
                }
                if(Language == 2){
                    setKashi('飲食');
                }
                if(Language == 3){
                    setKashi('खाने र पिउने');
                }
                if(Language == 4){
                    setKashi('කනවා බොනවා');
                }
                if(Language == 5){
                    setKashi('စားသောက်ခြင်း။');
                }
                if(Language == 6){
                    setKashi('খাওয়া-দাওয়া');
                }
            }
            if(counter >= 112){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 125){
                if(Language == 0){
                    setKashi('教室で飲み物は飲んでもいいです。');
                }
                if(Language == 1){
                    setKashi('You may drink in the classroom.');
                }
                if(Language == 2){
                    setKashi('你可以在教室裡喝飲料。');
                }
                if(Language == 3){
                    setKashi('तपाईंले कक्षाकोठामा पेय पदार्थ पिउन सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබට පන්ති කාමරයේ බීම පානය කළ හැකිය.');
                }
                if(Language == 5){
                    setKashi('စာသင်ခန်းထဲမှာ အချိုရည်တွေ သောက်နိုင်တယ်။');
                }
                if(Language == 6){
                    setKashi('আপনি ক্লাসরুমে পানীয় পান করতে পারেন।');
                }
            }
            if(counter >= 130){
                if(Language == 0){
                    setKashi('でも、食べ物はだめです。');
                }
                if(Language == 1){
                    setKashi('But no food.');
                }
                if(Language == 2){
                    setKashi('但食物不好。');
                }
                if(Language == 3){
                    setKashi('तर खान पाइँदैन।');
                }
                if(Language == 4){
                    setKashi('නමුත් කෑම ඡාති කන්න බැහැ.');
                }
                if(Language == 5){
                    setKashi('ဒါပေမယ့် အစားအသောက်က မကောင်းဘူး။');
                }
                if(Language == 6){
                    setKashi('তবে খাবার নিষিদ্ধ।');
                }
            }
            if(counter >= 135){
                if(Language == 0){
                    setKashi('あめ、ガム、フリスクはだめです。');
                }
                if(Language == 1){
                    setKashi('No candy, gum, or frisk.');
                }
                if(Language == 2){
                    setKashi('不要吃口香糖或糖果。');
                }
                if(Language == 3){
                    setKashi('चकलेट，चुइगम र फ्रिस्क पनि खान पाइँदैन।');
                }
                if(Language == 4){
                    setKashi('කැන්ඩි, චුයින්ගම් සහ ෆ්‍රිස්ක් වලට අවසර නැත.');
                }
                if(Language == 5){
                    setKashi('သကြားလုံး၊ ပီကေနှင့် frisk တို့ကို ခွင့်မပြုပါ။');
                }
                if(Language == 6){
                    setKashi('ক্যান্ডি, গাম এবং ফ্রিস্কিও নিষিদ্ধ।');
                }
            }
            if(counter >= 140.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 143.7){
                if(Language == 0){
                    setKashi('3Fロビーがあります。');
                }
                if(Language == 1){
                    setKashi('There is a lobby on the 3rd floor.');
                }
                if(Language == 2){
                    setKashi('3樓設有大堂。');
                }
                if(Language == 3){
                    setKashi('तेस्रो तल्लामा लबी छ।');
                }
                if(Language == 4){
                    setKashi('3 වන මහලේ විවේක කාමරය ඇත.');
                }
                if(Language == 5){
                    setKashi('တတိယထပ်တွင် ဧည့်ခန်းတစ်ခုရှိသည်။');
                }
                if(Language == 6){
                    setKashi('3য় তলায় একটি লবি আছে।');
                }
            }
            if(counter >= 147.5){
                if(Language == 0){
                    setKashi('3Fロビーで食べてもいいです。');
                }
                if(Language == 1){
                    setKashi('You can eat in the 3rd floor lobby.');
                }
                if(Language == 2){
                    setKashi('您也可以在三樓大廳用餐。');
                }
                if(Language == 3){
                    setKashi('तपाईं तेस्रो तल्लाको लबीमा  खान सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi(' ඔබට 3 වන මහලේ කාමරයේ  ආහාර ගත හැකිය.');
                }
                if(Language == 5){
                    setKashi('3rd floor lobby မှာလည်း စားလို့ရပါတယ်။');
                }
                if(Language == 6){
                    setKashi('আপনি 3য় তলার লবিতে খেতে পারেন।');
                }
            }
            if(counter >= 152){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // ぼうし・うわぎ
            if(counter >= 154){
                if(Language == 0){
                    setKashi('ぼうし・うわぎ');
                }
                if(Language == 1){
                    setKashi('hat/coat');
                }
                if(Language == 2){
                    setKashi('帽子/外套');
                }
                if(Language == 3){
                    setKashi('टोपी/कोट/ज्याकेट');
                }
                if(Language == 4){
                    setKashi('තොප්පිය / කබාය');
                }
                if(Language == 5){
                    setKashi('ဦးထုပ်/အင်္ကျီ');
                }
                if(Language == 6){
                    setKashi('টুপি/কোট');
                }
            }
            if(counter >= 157){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 165){
                if(Language == 0){
                    setKashi('教室で、ぼうしをかぶらないでください。');
                }
                if(Language == 1){
                    setKashi('Please do not wear a hat in the classroom.');
                }
                if(Language == 2){
                    setKashi('請不要在教室裡戴帽子。');
                }
                if(Language == 3){
                    setKashi('कृपया कक्षाकोठामा टोपी नलगाउनुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර පන්ති කාමරය තුළ හිස් වැසුම් පැළඳීමෙන් වළකින්න.');
                }
                if(Language == 5){
                    setKashi('စာသင်ခန်းထဲမှာ ဦးထုပ်မဆောင်းပါနဲ့။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে শ্রেণীকক্ষে টুপি পরবেন না।');
                }
            }
            if(counter >= 171.5){
                if(Language == 0){
                    setKashi('マフラーもとってください。');
                }
                if(Language == 1){
                    setKashi('Please take off your scarf as well.');
                }
                if(Language == 2){
                    setKashi('請同時帶上消音器。');
                }
                if(Language == 3){
                    setKashi('कृपया मफलर पनि नलगाउनुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර මෆුරා පැලදීමෙන් වලකින්න.');
                }
                if(Language == 5){
                    setKashi('မာဖလာကိုလည်း ချွတ်ထားပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে মাফলারটিও নিন।');
                }
            }
            if(counter >= 178.5){
                if(Language == 0){
                    setKashi('コートもぬいでください。');
                }
                if(Language == 1){
                    setKashi('Please take off your coat too.');
                }
                if(Language == 2){
                    setKashi('還請脫掉外套。');
                }
                if(Language == 3){
                    setKashi('कृपया आफ्नो कोट पनि फुकाल्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබේ කබායත් ගලවන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးပြုပြီး အနွေးထည်ကိုချွတ်ထားပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে আপনার কোটও খুলে ফেলুন।');
                }
            }
            if(counter >= 188.3){
                if(Language == 0){
                    setKashi('日本では、学校や家の中で');
                }
                if(Language == 1){
                    setKashi('In Japan, in school or at home');
                }
                if(Language == 2){
                    setKashi('在日本，在學校或家裡');
                }
                if(Language == 3){
                    setKashi('जापानमा स्कूलमा वा घरमा');
                }
                if(Language == 4){
                    setKashi('ජපානයේ, පාසැලේදී හෝ නිවසේදී ඇතුලදී');
                }
                if(Language == 5){
                    setKashi('ဂျပန်မှာ၊ ကျောင်းမှာပဲဖြစ်ဖြစ်၊ အိမ်မှာ');
                }
                if(Language == 6){
                    setKashi('জাপানে, স্কুলে বা বাড়িতে');
                }
            }
            if(counter >= 192){
                if(Language == 0){
                    setKashi('ぼうしをかぶったり、コートを着たりするのはしつれいです。');
                }
                if(Language == 1){
                    setKashi('It is rude to wear a hat or a coat.');
                }
                if(Language == 2){
                    setKashi('戴帽子或穿外套是不禮貌的。');
                }
                if(Language == 3){
                    setKashi('टोपी वा कोट लगाउनु अशिष्ट हो।');
                }
                if(Language == 4){
                    setKashi('තොප්පියක් හෝ කබායක් පැළඳීම අශිෂ්ටයි.');
                }
                if(Language == 5){
                    setKashi('ဦးထုပ် သို့မဟုတ် ကုတ်အင်္ကျီကို ဝတ်ဆင်ခြင်းသည် ယဉ်ကျေးမှုနှင့်မကိုက်ညီပါ။');
                }
                if(Language == 6){
                    setKashi('টুপি বা কোট পরা কঠিন।');
                }
            }
            if(counter >= 197){
                if(Language == 0){
                    setKashi('寒いときは、中にたくさん服を着てください。');
                }
                if(Language == 1){
                    setKashi("When it's cold, wear lots of clothes inside.");
                }
                if(Language == 2){
                    setKashi('天冷的時候，在室內多穿點衣服。');
                }
                if(Language == 3){
                    setKashi('चिसो मौसममा भित्र धेरै लुगाहरू लगाउनुहोस्।');
                }
                if(Language == 4){
                    setKashi('එය සීතල වන විට, ඇතුළත ඇඳුම් ගොඩක් අඳින්න.');
                }
                if(Language == 5){
                    setKashi('အအေးမိတဲ့အခါ အထဲမှာ အဝတ်များများဝတ်ပါ။');
                }
                if(Language == 6){
                    setKashi('যখন ঠাণ্ডা হয়, তখন ভিতরে প্রচুর কাপড় পরুন।');
                }
            }
            if(counter >= 201.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // しせい
            if(counter >= 205){
                if(Language == 0){
                    setKashi('しせい');
                }
                if(Language == 1){
                    setKashi('posture');
                }
                if(Language == 2){
                    setKashi('姿勢');
                }
                if(Language == 3){
                    setKashi('आसन');
                }
                if(Language == 4){
                    setKashi('ඉරියව්ව');
                }
                if(Language == 5){
                    setKashi('ကိုယ်ဟန်အနေအထား');
                }
                if(Language == 6){
                    setKashi('ভঙ্গি');
                }
            }
            if(counter >= 208){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 215){
                if(Language == 0){
                    setKashi('うでをくんではいけません。');
                }
                if(Language == 1){
                    setKashi("Don't cross your arms.");
                }
                if(Language == 2){
                    setKashi('不要交叉雙臂。');
                }
                if(Language == 3){
                    setKashi('आफ्नो हात हात बाँधेर नबसौँ।');
                }
                if(Language == 4){
                    setKashi('ඔබේ දෑත් හරස් නොකරන්න.');
                }
                if(Language == 5){
                    setKashi('လက်ပိုက်ထားခြင်းမပြုလုပ်ရန်။');
                }
                if(Language == 6){
                    setKashi('আপনার বাহু ভাঁজ করবেন না');
                }
            }
            if(counter >= 220){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 221){
                if(Language == 0){
                    setKashi('足をくんではいけません。');
                }
                if(Language == 1){
                    setKashi('Do not cross your legs.');
                }
                if(Language == 2){
                    setKashi('不要交叉雙腿。');
                }
                if(Language == 3){
                    setKashi('आफ्नो  खुट्टा उपरखुट्टी लाएर　नबसौँ।');
                }
                if(Language == 4){
                    setKashi('කකුලක් උඩින් කකුලක් තබා වාඩි නොවන්න.');
                }
                if(Language == 5){
                    setKashi('သင်၏ခြေထောက်များကို တစ်ဖက်နှင့်တစ်ဖက်တင်ထားခြင်းမပြုလုပ်ရန်။');
                }
                if(Language == 6){
                    setKashi('পায়ের উপর পা তুলে বসা যাবে না');
                }
            }
            if(counter >= 224.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 225.3){
                if(Language == 0){
                    setKashi('かべによりかかってはいけません。');
                }
                if(Language == 1){
                    setKashi('Do not lean on walls.');
                }
                if(Language == 2){
                    setKashi('不要靠在牆上。');
                }
                if(Language == 3){
                    setKashi('भित्तामा अडेस लाएर　नबसौँ।');
                }
                if(Language == 4){
                    setKashi('බිත්තියට හේත්තු නොවන්න.');
                }
                if(Language == 5){
                    setKashi('နံရံကို မမှီပါနှင့်။');
                }
                if(Language == 6){
                    setKashi('দেয়ালে হেলান দেবেন না।');
                }
            }
            if(counter >= 231){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 232.4){
                if(Language == 0){
                    setKashi('きれいにすわってください。');
                }
                if(Language == 1){
                    setKashi('Please sit properly.');
                }
                if(Language == 2){
                    setKashi('請坐好。');
                }
                if(Language == 3){
                    setKashi('कृपया सही तरिकाले बस्ने बानी बसालौँ।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර හොඳින් වාඩි වන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးပြုပြီး တည့်တည့်မတ်မတ်ထိုင်ရန်။');
                }
                if(Language == 6){
                    setKashi('দয়া করে সুন্দরভাবে বসুন।');
                }
            }
            if(counter >= 237.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 239.5){
                if(Language == 0){
                    setKashi('ほおづえをついてはいけません。');
                }
                if(Language == 1){
                    setKashi("Don't rest your chin.");
                }
                if(Language == 2){
                    setKashi('不要靠在下巴上。');
                }
                if(Language == 3){
                    setKashi('कुइना टेकेर चिउडोमा हात राखेर नबसौँ।');
                }
                if(Language == 4){
                    setKashi('ඔබේ නිකට මතට  අත තබා නොසිටින්න.');
                }
                if(Language == 5){
                    setKashi('မေးစေ့ထောက်၍မနေရန်။');
                }
                if(Language == 6){
                    setKashi('গালে হাত রেখে বসা যাবে না');
                }
            }
            if(counter >= 244.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 246.3){
                if(Language == 0){
                    setKashi('寝てはいけません。');
                }
                if(Language == 1){
                    setKashi("Don't sleep.");
                }
                if(Language == 2){
                    setKashi('別睡覺。');
                }
                if(Language == 3){
                    setKashi('कक्षा कोठामा सुत्न पाइँदैन।');
                }
                if(Language == 4){
                    setKashi('නිදාගන්න එපා.');
                }
                if(Language == 5){
                    setKashi('မအိပ်ပါနဲ့။');
                }
                if(Language == 6){
                    setKashi('ঘুমাবেন না।');
                }
            }
            if(counter >= 250.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 253){
                if(Language == 0){
                    setKashi('ポケットに手を入れてはいけません。');
                }
                if(Language == 1){
                    setKashi("Don't put your hands in your pockets.");
                }
                if(Language == 2){
                    setKashi('不要將手放入口袋中。');
                }
                if(Language == 3){
                    setKashi('आफ्नो खल्ती मा  हात नराख्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබේ අත් සාක්කුවලට දමන්න එපා.');
                }
                if(Language == 5){
                    setKashi('လက်ကို အိတ်ကပ်ထဲ မထည့်ပါနဲ့။');
                }
                if(Language == 6){
                    setKashi('আপনার পকেটে হাত রাখবেন না।');
                }
            }
            if(counter >= 256.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 258.5){
                setMoveVideoTime(8);
                document.getElementById('video').currentTime = 0;
            }
        }

        //　9本目　教科書を忘れた時
        if(MoveVideoTime == 8){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn MChBChange');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
            setChapterColor14('MoveChapBtn');

            if(counter >= 0){
                setMoveVideo(Regulations);
            }

            // 教科書をわすれたとき
            if(counter >= 0){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 1){
                if(Language == 0){
                    setKashi('教科書をわすれたとき');
                }
                if(Language == 1){
                    setKashi('When you forget your textbook');
                }
                if(Language == 2){
                    setKashi('當你忘記帶課本時');
                }
                if(Language == 3){
                    setKashi('जब तपाईं आफ्नो पाठ्यपुस्तक बिर्सनुहुन्छ');
                }
                if(Language == 4){
                    setKashi('ඔබට ඔබේ පෙළපොත අමතක වූ විට');
                }
                if(Language == 5){
                    setKashi('သင်ဖတ်စာအုပ်မေ့သွားသောအခါ');
                }
                if(Language == 6){
                    setKashi('আপনি যখন আপনার পাঠ্যবই ভুলে যান');
                }
            }
            if(counter >= 3.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 6.2){
                if(Language == 0){
                    setKashi('教科書をわすれないでください。');
                }
                if(Language == 1){
                    setKashi("Don't forget your textbook.");
                }
                if(Language == 2){
                    setKashi('別忘带您的課本。');
                }
                if(Language == 3){
                    setKashi('आफ्नो पाठ्यपुस्तक नबिर्सनुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබේ පෙළපොත අමතක කරන්න එපා.');
                }
                if(Language == 5){
                    setKashi('သင်ဖတ်စာအုပ်ကိုမမေ့ပါနှင့်။');
                }
                if(Language == 6){
                    setKashi('আপনার পাঠ্যপুস্তক ভুলবেন না.');
                }
            }
            if(counter >= 11.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 12.7){
                if(Language == 0){
                    setKashi('わすれたときは、1まい10円でコピーをします。');
                }
                if(Language == 1){
                    setKashi('If you forget it, we will make a copy for 10 yen per piece.');
                }
                if(Language == 2){
                    setKashi('如果您忘記了，我們將影印一份，每份 10 日圓。');
                }
                if(Language == 3){
                    setKashi('यदि तपाइँ यसलाई बिर्सनुभयो भने, हामी प्रत्येक प्रतिलिपिको  10 येन  लिनेछौं।');
                }
                if(Language == 4){
                    setKashi('ඔබට එය අමතක වුවහොත්, අපි *එක පිටුවකට යෙන් 10 බැගින් පිටපත් සාදන්නෙමු.');
                }
                if(Language == 5){
                    setKashi('မေ့သွားရင်လျှင်စာရွက်၁ရွက်ကို ၁၀ ယန်းနဲ့ ကူးယူရမည်။');
                }
                if(Language == 6){
                    setKashi('যদি আপনি এটি ভুলে যান, আমরা প্রতিদিন 10 ইয়েনের জন্য একটি অনুলিপি তৈরি করব।');
                }
            }
            if(counter >= 18.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            //遅刻（ちこく）
            if(counter >= 32){
                if(Language == 0){
                    setKashi('遅刻（ちこく）');
                }
                if(Language == 1){
                    setKashi('Being late');
                }
                if(Language == 2){
                    setKashi('落後於時間');
                }
                if(Language == 3){
                    setKashi('ढिलो उपस्थित');
                }
                if(Language == 4){
                    setKashi('ප්‍රමාදවී පැමීණීම ');
                }
                if(Language == 5){
                    setKashi('အချိန်နောက်ကျ');
                }
                if(Language == 6){
                    setKashi('সময়ের পিছনে');
                }
            }
            if(counter >= 35.1){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 36.9){
                if(Language == 0){
                    setKashi('１分でもちこくすると、');
                }
                if(Language == 1){
                    setKashi('Even if you are late for 1 minute,');
                }
                if(Language == 2){
                    setKashi('如果您忘遲到哪怕一分鐘');
                }
                if(Language == 3){
                    setKashi('यदि तपाईं एक मिनेट ढिलो हुनुहुन्छ भने,');
                }
                if(Language == 4){
                    setKashi('ඔබ විනාඩියක්වත් ප්‍රමාද නම්,');
                }
                if(Language == 5){
                    setKashi('တစ်မိနစ်တောင်နောက်ကျရင်');
                }
                if(Language == 6){
                    setKashi('আপনি যদি এটি 1 মিনিট ধরে রাখেন,');
                }
            }
            if(counter >= 40.9){
                if(Language == 0){
                    setKashi('その授業はけっせきになります。');
                }
                if(Language == 1){
                    setKashi('you will be considered as absent for that subject.');
                }
                if(Language == 2){
                    setKashi('您將缺席那堂課。');
                }
                if(Language == 3){
                    setKashi('तपाईं  त्यो विषयको कक्षामा अनुपस्थित हुनुहुनेछ।');
                }
                if(Language == 4){
                    setKashi('ඔබ එම පන්තියට නොපැමිණෙනු ඇත.');
                }
                if(Language == 5){
                    setKashi('အဲဒီအတန်းကနေ ပျက်ကွက်စာရင်းဖြစ်ပါမည်။');
                }
                if(Language == 6){
                    setKashi('সেই ক্লাস কঠিন হবে।');
                }
            }
            if(counter >= 45.2){
                if(Language == 0){
                    setKashi('ちこくした時、');
                }
                if(Language == 1){
                    setKashi('When you are late,');
                }
                if(Language == 2){
                    setKashi('當我遲到的時候，');
                }
                if(Language == 3){
                    setKashi('जब म ढिलो भएँ,');
                }
                if(Language == 4){
                    setKashi('මම පරක්කු වූ විට,');
                }
                if(Language == 5){
                    setKashi('နောက်ကျတဲ့အခါ၊');
                }
                if(Language == 6){
                    setKashi('যখন আমি বিরক্ত হয়ে যাই,');
                }
            }
            if(counter >= 47.8){
                if(Language == 0){
                    setKashi('じむしょに行ってから教室に行かないと');
                }
                if(Language == 1){
                    setKashi('you have to go to the office before going to the classroom');
                }
                if(Language == 2){
                    setKashi('您必須去辦公室，然後去教室。');
                }
                if(Language == 3){
                    setKashi('2Fअफिस जानु पर्छ र त्यसपछि कक्षा कोठामा जानु पर्छ।');
                }
                if(Language == 4){
                    setKashi('ඔෆිස් එකට ගිහින් පන්ති කාමරයට යන්න ඕන.*නැතිනම්,');
                }
                if(Language == 5){
                    setKashi('ရုံးခန်းသွားပြီးမှ စာသင်ခန်းကို သွားရမယ်။ ');
                }
                if(Language == 6){
                    setKashi('আমাকে জিমে যেতে হবে এবং তারপর ক্লাসরুমে যেতে হবে।');
                }
            }
            if(counter >= 52){
                if(Language == 0){
                    setKashi('しゅっせきにならないので気をつけてください。');
                }
                if(Language == 1){
                    setKashi('so that you will not be considered as absent.');
                }
                if(Language == 2){
                    setKashi('請注意，因為您將不算出席。');
                }
                if(Language == 3){
                    setKashi('कृपया सावधान रहनुहोस् किनकि तपाई अफिस  जानु भएन भने उपस्थित भएनि हजिरी हुदैन ।');
                }
                if(Language == 4){
                    setKashi('ඔබට පැමිණීමට ඉඩ නොලැබෙන බැවින් ප්‍රවේශම් වන්න.');
                }
                if(Language == 5){
                    setKashi('တက်ရောက်ခွင့်မပြုတဲ့အတွက် သတိထားပါ။');
                }
                if(Language == 6){
                    setKashi('রাগ না করার জন্য সতর্ক থাকুন।');
                }
            }
            if(counter >= 56.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            //休むときの連絡
            if(counter >= 59){
                if(Language == 0){
                    setKashi('休むときの連絡');
                }
                if(Language == 1){
                    setKashi('Informing of absences ');
                }
                if(Language == 2){
                    setKashi('休息時聯繫');
                }
                if(Language == 3){
                    setKashi('बिदा लिँदा सम्पर्क गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('විවේකයක් ගන්නා විට  දැනුම් දීම');
                }
                if(Language == 5){
                    setKashi('အနားယူသည့်အခါ ဆက်သွယ်ပါ။');
                }
                if(Language == 6){
                    setKashi('বিরতি নেওয়ার সময় যোগাযোগ করুন');
                }
            }
            if(counter >= 61.9){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 62.5){
                if(Language == 0){
                    setKashi('学校を休むときや、ちこくするときは、');
                }
                if(Language == 1){
                    setKashi('When you are absent or being late');
                }
                if(Language == 2){
                    setKashi('當你缺課或遲到時、當出現問題時');
                }
                if(Language == 3){
                    setKashi('स्कुल बिदा लिँदा र ढिलो आउँदा，');
                }
                if(Language == 4){
                    setKashi('ඔබ පාසලෙන් විවේකයක් ගන්නා විට හෝ පාසලට පැමීණිම ප්‍රමාද වන විට,');
                }
                if(Language == 5){
                    setKashi('ကျောင်းနားချိန် ဒါမှမဟုတ် အိပ်ယာဝင်တဲ့အခါ၊');
                }
                if(Language == 6){
                    setKashi('আপনি যখন স্কুলে অনুপস্থিত থাকেন বা ছুটি পান,');
                }
            }
            if(counter >= 66){
                if(Language == 0){
                    setKashi('かならずじゅぎょうがはじまる前に学校にれんらくしてください。');
                }
                if(Language == 1){
                    setKashi('Please be sure to contact the shcool before classes start.');
                }
                if(Language == 2){
                    setKashi('請務必在開課前聯絡學校。');
                }
                if(Language == 3){
                    setKashi('कृपया कक्षा सुरु हुनु अघि जसरी नि  स्कुलमा जानकारी गराउनुहोला।');
                }
                if(Language == 4){
                    setKashi('පන්ති ආරම්භ කිරීමට පෙර කරුණාකර පාසල සම්බන්ධ කර ගැනීමට වග බලා ගන්න.');
                }
                if(Language == 5){
                    setKashi('အတန်းမစခင် ကျောင်းကို သေချာဆက်သွယ်ပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে স্কুল দিন শুরু হওয়ার আগে স্কুলে পৌঁছাতে ভুলবেন না।');
                }
            }
            if(counter >= 71.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 75){
                if(Language == 0){
                    setKashi('休むとき、ちこくするとき、なにかもんだいがあるときは、');
                }
                if(Language == 1){
                    setKashi('If you are absent or will be late, or have any problems,');
                }
                if(Language == 2){
                    setKashi('當您忘休息、放鬆或有事情要做時，');
                }
                if(Language == 3){
                    setKashi('स्कुल बिदा लिँदा， ढिलो उपस्थिति भएको बेला र समस्या परेको बेला，');
                }
                if(Language == 4){
                    setKashi('ඔබ විවේකයක් ගන්නා විට, විවේකීව සිටින විට හෝ ගැටලුවක් ඇති විට,');
                }
                if(Language == 5){
                    setKashi('အနားယူတဲ့အခါ၊ အပန်းဖြေဖို့ ဒါမှမဟုတ် လုပ်စရာတစ်ခုခုရှိတဲ့အခါ၊');
                }
                if(Language == 6){
                    setKashi('যখন আপনার বিশ্রাম, বিশ্রাম বা কিছু করার প্রয়োজন হয়,');
                }
            }
            if(counter >= 79.4){
                if(Language == 0){
                    setKashi('でんわかLINEでれんらくしてください。');
                }
                if(Language == 1){
                    setKashi('please contact the office by telephone number or LINE.');
                }
                if(Language == 2){
                    setKashi('請透過電話或LINE與我們聯繫。');
                }
                if(Language == 3){
                    setKashi('कृपया हामीलाई फोन वा लाइन मार्फत सम्पर्क गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර දුරකථනයෙන් හෝ LINE මගින් අප හා සම්බන්ධ වන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးပြု၍ ဖုန်း သို့မဟုတ် LINE ဖြင့် ဆက်သွယ်ပါ။');
                }
                if(Language == 6){
                    setKashi('দয়া করে নির্দ্বিধায় Denwaka LINE ব্যবহার করুন।');
                }
            }
            if(counter >= 82.9){
                if(Language == 0){
                    setKashi('クラスのLINEでいろいろなれんらくをしますから');
                }
                if(Language == 1){
                    setKashi('We will communicate various things on class LINE.');
                }
                if(Language == 2){
                    setKashi('學校將透過 LINE 向您傳達各種訊息。');
                }
                if(Language == 3){
                    setKashi('कक्षाको लाइनमा  हामी धेरै कुराहरूको जानकारी आदानप्रदान गर्छौं।');
                }
                if(Language == 4){
                    setKashi('LINE පන්තියේ අපි ගොඩක් විනෝද වෙනවා.');
                }
                if(Language == 5){
                    setKashi('အတန်း LINE မှာ ပုံစံအမျိူးမျိူးဖြင့်ဆက်သွယ်သည့်အတွက်ကြောင့်');
                }
                if(Language == 6){
                    setKashi('আমরা ক্লাসে লাইনে অনেক মজা করব।');
                }
            }
            if(counter >= 86.4){
                if(Language == 0){
                    setKashi('SIMをかった人はLINEを入れてください。');
                }
                if(Language == 1){
                    setKashi('Once you bought a SIM, please download LINE and add NSA account.');
                }
                if(Language == 2){
                    setKashi('如果您購買了 SIM 卡，請立即下載 LINE。');
                }
                if(Language == 3){
                    setKashi('यदि तपाईंले सिम कार्ड किन्नु भएको छ भने, कृपया LINE APPडाउनलोड गरेर चलाउनुहोला। ');
                }
                if(Language == 4){
                    setKashi('ඔබ SIM එකක් මිල දී ගත්තේ නම්, කරුණාකර LINE ඇතුලත් කරන්න.');
                }
                if(Language == 5){
                    setKashi('သင့်တွင် SIM ရှိလျှင် LINE ကိုdownload လုပ်ပြီးထည့်ထားရန်။');
                }
                if(Language == 6){
                    setKashi('আপনার যদি একটি সিম থাকে, অনুগ্রহ করে লাইন যোগ করুন।');
                }
            }
            if(counter >= 89.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            if(counter >= 92){
                setMoveVideoTime(9);
                document.getElementById('video').currentTime = 0;
            }
        }

        //　10本目　寮の説明
        if(MoveVideoTime == 9){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn MChBChange');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
            setChapterColor14('MoveChapBtn');

            if(counter >= 0){
                setMoveVideo(DorRuhr);
            }

            //　ベランダでの禁止事項
            if(counter >= 0.5){
                if(Language == 0){
                    setKashi('今から、寮の説明をします。');
                }
                if(Language == 1){
                    setKashi('I will now explain the rules of dormitory.');
                }
                if(Language == 2){
                    setKashi('現在我就來介紹一下宿舍吧！');
                }
                if(Language == 3){
                    setKashi('म अब छात्रावासको व्याख्या गर्नेछु!');
                }
                if(Language == 4){
                    setKashi('මම දැන් නේවාසිකාගාරය පැහැදිලි කරන්නම්!');
                }
                if(Language == 5){
                    setKashi('အခု အိပ်ဆောင်ကို ရှင်းပြမယ်။');
                }
                if(Language == 6){
                    setKashi('আমি এখন ডরমিটরি ব্যাখ্যা করব।');
                }
            }
            if(counter >= 3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 4.2){
                if(Language == 0){
                    setKashi('ベランダでの禁止事項');
                }
                if(Language == 1){
                    setKashi('Prohibited things on the balcony');
                }
                if(Language == 2){
                    setKashi('陽台上禁止攜帶的物品');
                }
                if(Language == 3){
                    setKashi('बालकनीमा गर्न नहुने कामहरू');
                }
                if(Language == 4){
                    setKashi('බැල්කනියේ තහනම් භාණ්ඩ');
                }
                if(Language == 5){
                    setKashi('လသာဆောင်တွင် တားမြစ်ထားသောပစ္စည်းများ');
                }
                if(Language == 6){
                    setKashi('ব্যালকনিতে নিষিদ্ধ জিনিসপত্র');
                }
            }
            if(counter >= 7.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 9.6){
                if(Language == 0){
                    setKashi('ベランダで電話してはいけません。');
                }
                if(Language == 1){
                    setKashi("Don't make phone calls on the balcony.");
                }
                if(Language == 2){
                    setKashi('不要在陽台上打電話。');
                }
                if(Language == 3){
                    setKashi('बालकनीमा बसेर　फोन कल नगर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('බැල්කනියේ දුරකථන ඇමතුම් ගන්න එපා.');
                }
                if(Language == 5){
                    setKashi('လသာဆောင်တွင် ဖုန်းမခေါ်ဆိုပါနှင့်။');
                }
                if(Language == 6){
                    setKashi('ব্যালকনিতে ফোন কল করবেন না।');
                }
            }
            if(counter >= 12){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 15){
                if(Language == 0){
                    setKashi('ベランダで話してはいけません。');
                }
                if(Language == 1){
                    setKashi("Don't talk on the balcony.");
                }
                if(Language == 2){
                    setKashi('不要在陽台上說話。');
                }
                if(Language == 3){
                    setKashi('बालकनीमा　बसेर कुरा नगर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('බැල්කනියේ කතා කරන්න එපා.');
                }
                if(Language == 5){
                    setKashi('လသာဆောင်မှာ စကားမပြောပါနဲ့။');
                }
                if(Language == 6){
                    setKashi('ব্যালকনিতে কথা বলবেন না।');
                }
            }
            if(counter >= 17.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 19.6){
                if(Language == 0){
                    setKashi('ベランダでたばこをすってはいけません。');
                }
                if(Language == 1){
                    setKashi('Do not smoke on the balcony.');
                }
                if(Language == 2){
                    setKashi('請勿在陽台上吸煙。');
                }
                if(Language == 3){
                    setKashi('बालकनीमा धुम्रपान नगर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('බැල්කනියේ දුම් පානය නොකරන්න.');
                }
                if(Language == 5){
                    setKashi('လသာဆောင်တွင် ဆေးလိပ်မသောက်ပါနှင့်။');
                }
                if(Language == 6){
                    setKashi('ব্যালকনিতে ধূমপান করবেন না।');
                }
            }
            if(counter >= 21.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 寮に入ってもいい人
            if(counter >= 23){
                if(Language == 0){
                    setKashi('寮に入ってもいい人');
                }
                if(Language == 1){
                    setKashi('People who can stay in the dormitory');
                }
                if(Language == 2){
                    setKashi('可以進入宿舍的人');
                }
                if(Language == 3){
                    setKashi('छात्रावासमा बस्न सक्ने मानिसहरू');
                }
                if(Language == 4){
                    setKashi('නේවාසිකාගාරයේ ඉන්න පුළුවන් අය');
                }
                if(Language == 5){
                    setKashi('အဆောင်မှာနေနိုင်သူတွေ');
                }
                if(Language == 6){
                    setKashi('যারা ডরমেটরিতে থাকতে পারে');
                }
            }
            if(counter >= 26.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 27.2){
                if(Language == 0){
                    setKashi('寮にはルームメイトだけ入ることができます');
                }
                if(Language == 1){
                    setKashi('Only roommates can enter the dormitory.');
                }
                if(Language == 2){
                    setKashi('宿舍內只允許室友入住。');
                }
                if(Language == 3){
                    setKashi('छात्रावासमा रुममेटहरूलाई मात्र अनुमति छ।');
                }
                if(Language == 4){
                    setKashi('නේවාසිකාගාරයේ නේවාසික මිතුරන්ට පමණක් අවසර ඇත.');
                }
                if(Language == 5){
                    setKashi('အခန်းဖော်များကိုသာ အိပ်ဆောင်တွင် ခွင့်ပြုထားသည်။');
                }
                if(Language == 6){
                    setKashi('যারা ডরমেটরিতে থাকতে পারে');
                }
            }
            if(counter >= 32){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 40){
                if(Language == 0){
                    setKashi('友達は寮に入ってはいけません。');
                }
                if(Language == 1){
                    setKashi('Friends are not allowed in the dormitory.');
                }
                if(Language == 2){
                    setKashi('朋友不得進入宿舍。');
                }
                if(Language == 3){
                    setKashi('छात्रावासमा साथीहरूलाई अनुमति छैन।');
                }
                if(Language == 4){
                    setKashi('මිතුරන්ට නේවාසිකාගාරයට ඇතුළු වීමට අවසර නැත.');
                }
                if(Language == 5){
                    setKashi('သူငယ်ချင်းများကို အိပ်ဆောင်တွင် ခွင့်မပြုပါ။');
                }
                if(Language == 6){
                    setKashi('বন্ধুদের ডরমেটরিতে অনুমতি দেওয়া হয় না।');
                }
            }
            if(counter >= 42.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 45.5){
                if(Language == 0){
                    setKashi('寮でパーティーをしてはいけません。');
                }
                if(Language == 1){
                    setKashi("Parties are not allowed in your dorm.");
                }
                if(Language == 2){
                    setKashi('不要在宿舍裡舉辦聚會。');
                }
                if(Language == 3){
                    setKashi('तपाईंको छात्रावासमा पार्टीहरू नगर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබේ නේවාසිකාගාරයේ සාද පවත්වන්න එපා.');
                }
                if(Language == 5){
                    setKashi('သင့်အိပ်ဆောင်တွင် ပါတီများ ကျင်းပခွင့်မပြု  အိပ်ဆောင်စာချုပ်');
                }
                if(Language == 6){
                    setKashi('আপনার ডর্মে পার্টি করবেন না।');
                }
            }
            if(counter >= 48.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 寮の契約
            if(counter >= 50.5){
                if(Language == 0){
                    setKashi('寮の契約');
                }
                if(Language == 1){
                    setKashi('dormitory contract');
                }
                if(Language == 2){
                    setKashi('宿舍合約');
                }
                if(Language == 3){
                    setKashi('छात्रावास सम्झौता');
                }
                if(Language == 4){
                    setKashi('නේවාසිකාගාර කොන්ත්රාත්තුව');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင်စာချုပ်');
                }
                if(Language == 6){
                    setKashi('ছাত্রাবাস চুক্তি');
                }
            }
            if(counter >= 53.8){
                if(Language == 0){
                    setKashi('日本に来る前に寮を予約した人は、学校の寮に住んでください。');
                }
                if(Language == 1){
                    setKashi('If you reserved a dormitory before coming to Japan, please live in the school dormitory');
                }
                if(Language == 2){
                    setKashi('如果您在來日本之前預訂了宿舍，請住在學校宿舍。');
                }
                if(Language == 3){
                    setKashi('यदि तपाईंले जापान आउनु अघि छात्रावास आरक्षित गर्नुभयो भने, कृपया विद्यालयको छात्रावासमा　बस्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබ ජපානයට පැමිණීමට පෙර නේවාසිකාගාරයක් වෙන් කරවා ගත්තේ නම් කරුණාකර පාසල් නේවාසිකාගාරයේ ජීවත් වන්න.');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင်ထဲမှာ တစ်ခုခု ပျက်ဆီးသွားသည့်အခါ၊');
                }
                if(Language == 6){
                    setKashi('আপনি যদি জাপানে আসার আগে একটি ছাত্রাবাস সংরক্ষণ করেন, অনুগ্রহ করে স্কুলের ছাত্রাবাসে থাকুন।');
                }
            }
            if(counter >= 59.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 60.6){
                if(Language == 0){
                    setKashi('6か月か1年です。');
                }
                if(Language == 1){
                    setKashi('for 6 months or 1 year.');
                }
                if(Language == 2){
                    setKashi('6個月或1年。');
                }
                if(Language == 3){
                    setKashi('६ महिना वा १ वर्ष ।');
                }
                if(Language == 4){
                    setKashi('මාස 6ක් හෝ අවුරුද්දක්.');
                }
                if(Language == 5){
                    setKashi('၆ လ သို့မဟုတ် ၁ နှစ်။');
                }
                if(Language == 6){
                    setKashi('6 মাস বা 1 বছর।');
                }
            }
            if(counter >= 64.3){
                if(Language == 0){
                    setKashi('とちゅうでキャンセルできません。');
                }
                if(Language == 1){
                    setKashi('You cannot cancel midway.');
                }
                if(Language == 2){
                    setKashi('中途不能取消。');
                }
                if(Language == 3){
                    setKashi('तपाईँले बीचमा रद्द गर्न सक्नुहुन्न।');
                }
                if(Language == 4){
                    setKashi('ඔබට අතරමග අවලංගු කළ නොහැක.');
                }
                if(Language == 5){
                    setKashi('လမ်းခုလတ်မှာ ပယ်ဖျက်လို့မရပါဘူး။');
                }
                if(Language == 6){
                    setKashi('আপনি মাঝপথে বাতিল করতে পারবেন না।');
                }
            }
            if(counter >= 67.5){
                if(Language == 0){
                    setKashi('ルールをまもって、住んでください！');
                }
                if(Language == 1){
                    setKashi('Please follow the rules and live here!');
                }
                if(Language == 2){
                    setKashi('請遵守這裡的規則！');
                }
                if(Language == 3){
                    setKashi('कृपया नियमहरू पालना पालना गरेर बस्नुहोस्!');
                }
                if(Language == 4){
                    setKashi('කරුණාකර නීති අනුගමනය කර මෙහි ජීවත් වන්න!');
                }
                if(Language == 5){
                    setKashi('စည်းကမ်းများကို လိုက်နာပြီး ဤနေရာတွင် နေထိုင်ပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে নিয়ম অনুসরণ করুন এবং এখানে বাস করুন!');
                }
            }
            if(counter >= 70.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 寮のお金
            if(counter >= 71.5){
                if(Language == 0){
                    setKashi('寮のお金');
                }
                if(Language == 1){
                    setKashi('dormitory money');
                }
                if(Language == 2){
                    setKashi('宿舍錢');
                }
                if(Language == 3){
                    setKashi('छात्रावास पैसा');
                }
                if(Language == 4){
                    setKashi('නේවාසිකාගාර මුදල්');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင်ပိုက်ဆံ');
                }
                if(Language == 6){
                    setKashi('ছাত্রাবাস টাকা');
                }
            }
            if(counter >= 75){
                if(Language == 0){
                    setKashi('電気、ガス、水のお金は1人1人が、毎月かならずはらってください。');
                }
                if(Language == 1){
                    setKashi('Each person must pay for electricity, gas, and water every month.');
                }
                if(Language == 2){
                    setKashi('每個人每月必須支付電費、瓦斯費和水費。');
                }
                if(Language == 3){
                    setKashi('प्रत्येक व्यक्तिले प्रत्येक महिना बिजुली, ग्यास र पानीको शुल्क बुझाउनुपर्छ।');
                }
                if(Language == 4){
                    setKashi('සෑම පුද්ගලයෙකුම සෑම මසකම විදුලිය, ගෑස් සහ ජලය සඳහා ගෙවිය යුතුය.');
                }
                if(Language == 5){
                    setKashi('လူတစ်ဦးစီသည် လစဉ် လျှပ်စစ်မီး၊ သဘာဝဓာတ်ငွေ့နှင့် ရေအတွက် ပေးဆောင်ရမည်ဖြစ်သည်။');
                }
                if(Language == 6){
                    setKashi('প্রত্যেক ব্যক্তিকে প্রতি মাসে বিদ্যুৎ, গ্যাস এবং জলের জন্য অর্থ প্রদান করতে হবে৷');
                }
            }
            if(counter >= 85.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // エネルギーの節約
            if(counter >= 87){
                if(Language == 0){
                    setKashi('エネルギーの節約');
                }
                if(Language == 1){
                    setKashi('energy savings');
                }
                if(Language == 2){
                    setKashi('節能');
                }
                if(Language == 3){
                    setKashi('जब तपाईं छात्रावासमा केहि तोड्नुहुन्छ');
                }
                if(Language == 4){
                    setKashi('බලශක්ති ඉතිරිකිරීම්');
                }
                if(Language == 5){
                    setKashi('စွမ်းအင်ချွေတာခြင်း။');
                }
                if(Language == 6){
                    setKashi('শক্তি সঞ্চয়');
                }
            }
            if(counter >= 90.2){
                if(Language == 0){
                    setKashi('日本の電気は高いです。');
                }
                if(Language == 1){
                    setKashi('Electricity in Japan is expensive.');
                }
                if(Language == 2){
                    setKashi('日本的電很貴。');
                }
                if(Language == 3){
                    setKashi('जापानमा बिजुली महँगो छ।');
                }
                if(Language == 4){
                    setKashi('ජපානයේ විදුලිය මිල අධිකයි.');
                }
                if(Language == 5){
                    setKashi('ဂျပန်မှာ လျှပ်စစ်က ဈေးကြီးတယ်။');
                }
                if(Language == 6){
                    setKashi('জাপানে বিদ্যুৎ ব্যয়বহুল।');
                }
            }
            if(counter >= 93.3){
                if(Language == 0){
                    setKashi('エアコンの温度に気を付けてください。');
                }
                if(Language == 1){
                    setKashi('Please be careful about the temperature of your air conditioner.');
                }
                if(Language == 2){
                    setKashi('請注意空調的溫度。');
                }
                if(Language == 3){
                    setKashi('कृपया आफ्नो एयर कन्डिसनरको तापक्रममा ध्यान दिनुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබේ වායු සමීකරණ යන්ත්රයේ උෂ්ණත්වය ගැන සැලකිලිමත් වන්න.');
                }
                if(Language == 5){
                    setKashi('သင့်လေအေးပေးစက်၏ အပူချိန်ကို သတိထားပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে আপনার এয়ার কন্ডিশনার তাপমাত্রা সম্পর্কে সতর্ক থাকুন।');
                }
            }
            if(counter >= 96.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 99.5){
                if(Language == 0){
                    setKashi('出かけるとき、必ず電気を消してください！');
                }
                if(Language == 1){
                    setKashi('Be sure to turn off the lights when you go out!');
                }
                if(Language == 2){
                    setKashi('出門一定要關燈！');
                }
                if(Language == 3){
                    setKashi('जब तपाईं बाहिर जानुहुन्छ बत्तीहरू बन्द गर्न निश्चित हुनुहोस्!');
                }
                if(Language == 4){
                    setKashi('ඔබ පිටතට යන විට විදුලි පහන් නිවා දැමීමට වග බලා ගන්න!');
                }
                if(Language == 5){
                    setKashi('အပြင်သွားတဲ့အခါ မီးကို သေချာပိတ်ထားပါ။');
                }
                if(Language == 6){
                    setKashi('আপনি যখন বাইরে যান লাইট বন্ধ করতে ভুলবেন না!');
                }
            }
            if(counter >= 103.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 寮のものをこわした時
            if(counter >= 109){
                if(Language == 0){
                    setKashi('寮のものをこわした時');
                }
                if(Language == 1){
                    setKashi('When you broke something in the dormitory');
                }
                if(Language == 2){
                    setKashi('當我在宿舍打破東西時');
                }
                if(Language == 3){
                    setKashi('जब मैले छात्रावासमा केहि तोडें');
                }
                if(Language == 4){
                    setKashi('මම නේවාසිකාගාරයේ යමක් කැඩු විට');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင်ထဲမှာ တစ်ခုခု ကွဲသွားတဲ့အခါ');
                }
                if(Language == 6){
                    setKashi('যখন আমি ডরমেটরিতে কিছু ভাঙলাম');
                }
            }
            if(counter >= 112.3){
                if(Language == 0){
                    setKashi('寮のものをこわしたときは、');
                }
                if(Language == 1){
                    setKashi('When you break something in the dormitory,');
                }
                if(Language == 2){
                    setKashi('當你在宿舍打破東西時');
                }
                if(Language == 3){
                    setKashi('जब तपाइँ छात्रावासमा केहि भाँच्नुहुन्छ,');
                }
                if(Language == 4){
                    setKashi('මම නේවාසිකාගාරයේ යමක් කැඩු විට');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင်ထဲမှာ တစ်ခုခု ဖောက်ဖျက်တဲ့အခါ၊');
                }
                if(Language == 6){
                    setKashi('যখন আপনি ছাত্রাবাসে কিছু ভাঙেন,');
                }
            }
            if(counter >= 117.7){
                if(Language == 0){
                    setKashi('こわした人が、しゅうりのお金をはらわなければなりません。');
                }
                if(Language == 1){
                    setKashi('The person who broke it must pay for the repair cost.');
                }
                if(Language == 2){
                    setKashi('造成損壞的人必須支付修理費。');
                }
                if(Language == 3){
                    setKashi('क्षति पुर्याएको व्यक्तिले मर्मतको लागि तिर्नुपर्छ।');
                }
                if(Language == 4){
                    setKashi('හානියට පත් පුද්ගලයා අලුත්වැඩියා සඳහා ගෙවිය යුතුය.');
                }
                if(Language == 5){
                    setKashi('ပျက်ဆီးသွားသူသည် ပြုပြင်မှုအတွက် ပေးဆောင်ရမည်ဖြစ်ပါသည်။');
                }
                if(Language == 6){
                    setKashi('যে ব্যক্তি এটি ভেঙেছে তাকে অবশ্যই টাকা দিতে হবে।');
                }
            }
            // かぎをなくしたとき
            if(counter >= 123.3){
                if(Language == 0){
                    setKashi('かぎをなくしたとき');
                }
                if(Language == 1){
                    setKashi('When you lose your keys');
                }
                if(Language == 2){
                    setKashi('當你丟失鑰匙時');
                }
                if(Language == 3){
                    setKashi('जब तपाईं आफ्नो चाबी हराउनुहुन्छ');
                }
                if(Language == 4){
                    setKashi('ඔබේ යතුරු නැති වූ විට');
                }
                if(Language == 5){
                    setKashi('မင်းရဲ့သော့ပျောက်သွားတဲ့အခါ');
                }
                if(Language == 6){
                    setKashi('যখন আপনি আপনার চাবি হারাবেন');
                }
            }
            if(counter >= 126){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 132.5){
                if(Language == 0){
                    setKashi('かぎをなくしたときは、学校にれんらくしてください。');
                }
                if(Language == 1){
                    setKashi('If you lose your key, please inform the school.');
                }
                if(Language == 2){
                    setKashi('如果您遺失了鑰匙，請聯絡學校。');
                }
                if(Language == 3){
                    setKashi('यदि तपाईंले आफ्नो साँचो हराउनु भयो भने, कृपया विद्यालयमा सम्पर्क गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබේ යතුර නැති වුවහොත් කරුණාකර පාසල අමතන්න.');
                }
                if(Language == 5){
                    setKashi('သင့်သော့ပျောက်ဆုံးပါက ကျောင်းကို ဆက်သွယ်ပါ။');
                }
                if(Language == 6){
                    setKashi('আপনি যদি আপনার চাবি হারিয়ে ফেলেন, অনুগ্রহ করে স্কুলে আসুন।');
                }
            }
            if(counter >= 138.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 寮をきれいに使う
            if(counter >= 140){
                if(Language == 0){
                    setKashi('寮をきれいに使う');
                }
                if(Language == 1){
                    setKashi('Use the dormitory neatly');
                }
                if(Language == 2){
                    setKashi('宿舍使用整齊');
                }
                if(Language == 3){
                    setKashi('सफा सँग छात्रावास प्रयोग गर्नुहोस्');
                }
                if(Language == 4){
                    setKashi('නේවාසිකාගාරය පිළිවෙලට භාවිතා කරන්න');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင်ကို သပ်သပ်ရပ်ရပ်သုံးပါ။');
                }
                if(Language == 6){
                    setKashi('সুন্দরভাবে ডরমিটরি ব্যবহার করুন');
                }
            }
            if(counter >= 143.6){
                if(Language == 0){
                    setKashi('部屋がきたない人は、寮を出るときお金をはらわなければなりません。');
                }
                if(Language == 1){
                    setKashi('If your room is dirty, you will have to pay when you leave the dormitory.');
                }
                if(Language == 2){
                    setKashi('房間髒亂的學生離開宿舍時必須付費。');
                }
                if(Language == 3){
                    setKashi('जसका कोठाहरू फोहोर छन् उनीहरूले छात्रावासबाट बाहिर निस्कँदा पैसा तिर्नु पर्छ।');
                }
                if(Language == 4){
                    setKashi('කාමර අපිරිසිදු වී ඇති අය නේවාසිකාගාරයෙන් පිටවන විට මුදල් ගෙවිය යුතුය.');
                }
                if(Language == 5){
                    setKashi('အခန်းတွေ ညစ်ပတ်နေတဲ့သူတွေ အိပ်ဆောင်က ထွက်သွားရင် ပိုက်ဆံပေးဆောင်ရမည်။');
                }
                if(Language == 6){
                    setKashi('যদি আপনার রুম নোংরা হয়, আপনি ডরমিটরি ছেড়ে যাওয়ার সময় আপনাকে অর্থ প্রদান করতে হবে।');
                }
            }
            if(counter >= 150){
                if(Language == 0){
                    setKashi('きれいに使ってください。');
                }
                if(Language == 1){
                    setKashi('Please use it cleanly.');
                }
                if(Language == 2){
                    setKashi('請謹慎使用。');
                }
                if(Language == 3){
                    setKashi('कृपया यसलाई सफा सँग प्रयोग गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර එය ප්රවේශමෙන් භාවිතා කරන්න.');
                }
                if(Language == 5){
                    setKashi('ဂရုတစိုက်အသုံးပြုပါ။');
                }
                if(Language == 6){
                    setKashi('দয়া করে এটি পরিষ্কারভাবে ব্যবহার করুন।');
                }
            }
            if(counter >= 154){
                if(Language == 0){
                    setKashi('電気、ガス、水道のお金を払わない人、寮のルールを守らない人は、');
                }
                if(Language == 1){
                    setKashi("People who don't pay for electricity, gas, and water, or who don't follow the dormitory rules,");
                }
                if(Language == 2){
                    setKashi('不繳水電費、瓦斯費、不遵守宿舍規定的，');
                }
                if(Language == 3){
                    setKashi('बिजुली, ग्यास र पानीको भुक्तानी नगर्ने र छात्रावासको नियम पालना नगर्नेहरू,');
                }
                if(Language == 4){
                    setKashi('විදුලිය, ගෑස් සහ ජලය සඳහා මුදල් නොගෙවන අය සහ නේවාසිකාගාර නීති අනුගමනය නොකරන අය,');
                }
                if(Language == 5){
                    setKashi('လျှပ်စစ်မီး၊ ဂက်စ်၊ ရေ ပေးဆောင်ခြင်း မပြုသူများနှင့် အိပ်ဆောင်စည်းကမ်း မလိုက်နာသူများ၊');
                }
                if(Language == 6){
                    setKashi('যারা বিদ্যুৎ, গ্যাস এবং পানির জন্য অর্থ প্রদান করেন না বা যারা ডরমেটরি নিয়ম অনুসরণ করেন না,');
                }
            }
            if(counter >= 161){
                if(Language == 0){
                    setKashi('他の部屋にひっこしすることがあります。');
                }
                if(Language == 1){
                    setKashi('might be moved to another room.');
                }
                if(Language == 2){
                    setKashi('您可能會被轉移到另一個房間。');
                }
                if(Language == 3){
                    setKashi('तपाईंलाई अर्को कोठामा सार्न सकिन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබව වෙනත් කාමරයකට ගෙන යා හැකිය.');
                }
                if(Language == 5){
                    setKashi('သင်သည် အခြားနေရာသို့ ပြောင်းရွှေ့နိုင်သည်။');
                }
                if(Language == 6){
                    setKashi('আমি অন্য ঘরে ফিরে যেতে পারি।');
                }
            }
            if(counter >= 165){
                if(Language == 0){
                    setKashi('学生は自分で部屋をきめることができません。');
                }
                if(Language == 1){
                    setKashi('Students cannot choose your own room.');
                }
                if(Language == 2){
                    setKashi('學生不能選擇自己的房間。');
                }
                if(Language == 3){
                    setKashi('विद्यार्थीले आफ्नो कोठा छनोट गर्न पाउँदैनन् ।');
                }
                if(Language == 4){
                    setKashi('සිසුන්ට තමන්ගේම කාමරයක් තෝරා ගත නොහැක.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းသားများသည် မိမိအခန်းကို ရွေးချယ်၍မရပါ။');
                }
                if(Language == 6){
                    setKashi('শিক্ষার্থীরা তাদের নিজস্ব রুম বেছে নিতে পারে না।');
                }
            }
            if(counter >= 169.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 郵便受けの確認
            if(counter >= 171){
                if(Language == 0){
                    setKashi('郵便受けの確認');
                }
                if(Language == 1){
                    setKashi('Check your mailbox');
                }
                if(Language == 2){
                    setKashi('檢查您的信箱');
                }
                if(Language == 3){
                    setKashi('आफ्नो मेलबक्सको जाँच गर्नुहोस्');
                }
                if(Language == 4){
                    setKashi('ඔබගේ තැපැල් පෙට්ටිය පරීක්ෂා කරන්න');
                }
                if(Language == 5){
                    setKashi('သင့်စာတိုက်ပုံးကို စစ်ဆေးပါ။');
                }
                if(Language == 6){
                    setKashi('আপনার মেইলবক্স দেখুন');
                }
            }
            if(counter >= 174){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 178){
                if(Language == 0){
                    setKashi('毎日、ゆうびん受けをチェックしてください。');
                }
                if(Language == 1){
                    setKashi('Check your mailbox every day.');
                }
                if(Language == 2){
                    setKashi('每天檢查您的郵箱。');
                }
                if(Language == 3){
                    setKashi('आफ्नो मेलबक्स दैनिक जाँच गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබේ තැපැල් පෙට්ටිය දිනපතා පරීක්ෂා කරන්න.');
                }
                if(Language == 5){
                    setKashi('သင့်စာတိုက်ပုံးကို နေ့စဉ်စစ်ဆေးပါ။');
                }
                if(Language == 6){
                    setKashi('প্রতিদিন আপনার মেইলবক্স চেক করুন।');
                }
            }
            if(counter >= 184.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // ポストの確認
            if(counter >= 190.5){
                if(Language == 0){
                    setKashi('ポストの確認');
                }
                if(Language == 1){
                    setKashi('Confirmation of post');
                }
                if(Language == 2){
                    setKashi('確認發帖');
                }
                if(Language == 3){
                    setKashi('पोस्टको जाँच गर्नुहोस्');
                }
                if(Language == 4){
                    setKashi('තනතුර තහවුරු කිරීම');
                }
                if(Language == 5){
                    setKashi('ပို့စ်အတည်ပြုချက်');
                }
                if(Language == 6){
                    setKashi('পোস্টের নিশ্চিতকরণ');
                }
            }
            if(counter >= 193.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 195.3){
                if(Language == 0){
                    setKashi('寮にかえってきたら、ポストをチェックしてください。');
                }
                if(Language == 1){
                    setKashi('Please check the mailbox when you return to the dormitory.');
                }
                if(Language == 2){
                    setKashi('返回宿舍後請查收信箱。');
                }
                if(Language == 3){
                    setKashi('जब तपाईं छात्रावासमा फर्कनुहुन्छ कृपया आफ्नो मेलबक्स जाँच गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබ නැවත නේවාසිකාගාරයට පැමිණෙන විට කරුණාකර ඔබගේ තැපැල් පෙට්ටිය පරීක්ෂා කරන්න.');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင်သို့ ပြန်ရောက်သောအခါ သင့်စာတိုက်ပုံးကို စစ်ဆေးပါ။');
                }
                if(Language == 6){
                    setKashi('আপনি ডরমিটরিতে ফিরে আসার সময় দয়া করে মেইলবক্স চেক করুন।');
                }
            }
            if(counter >= 206.9){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 211){
                if(Language == 0){
                    setKashi('出かけるときもチェックしてください。');
                }
                if(Language == 1){
                    setKashi('Please check it when you go out.');
                }
                if(Language == 2){
                    setKashi('外出時請檢查一下。');
                }
                if(Language == 3){
                    setKashi('तपाईं बाहिर जाँदा पनि कृपया यसलाई जाँच गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබ පිටතට යන විට කරුණාකර එය පරීක්ෂා කරන්න.');
                }
                if(Language == 5){
                    setKashi('အပြင်သွားတဲ့အခါ ကျေးဇူးပြု၍ စစ်ဆေးပါ။');
                }
                if(Language == 6){
                    setKashi('আপনি যখন বাইরে যান অনুগ্রহ করে এটি পরীক্ষা করুন।');
                }
            }
            if(counter >= 221.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 寮の注意事項
            if(counter >= 224){
                if(Language == 0){
                    setKashi('寮の注意事項');
                }
                if(Language == 1){
                    setKashi('Dormitory notes');
                }
                if(Language == 2){
                    setKashi('宿舍筆記');
                }
                if(Language == 3){
                    setKashi('छात्रवासमा सावधानी अपनाउनुपर्ने कुराहरू:-');
                }
                if(Language == 4){
                    setKashi('නේවාසිකාගාර ගැටලු');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင်မှတ်စုများ');
                }
                if(Language == 6){
                    setKashi('ছাত্রাবাস নোট');
                }
            }
            if(counter >= 227){
                if(Language == 0){
                    setKashi('寮の大きい問題は２つです。');
                }
                if(Language == 1){
                    setKashi('There are two major problems with dormitories.');
                }
                if(Language == 2){
                    setKashi('宿舍有兩個主要問題。');
                }
                if(Language == 3){
                    setKashi('छात्रावासमा दुईवटा प्रमुख समस्या छन् ।');
                }
                if(Language == 4){
                    setKashi('නේවාසිකාගාරවල ප්‍රධාන ගැටලු දෙකක් තිබේ.');
                }
                if(Language == 5){
                    setKashi('အဆောင်များတွင် ပြဿနာကြီးနှစ်ခုရှိသည်။');
                }
                if(Language == 6){
                    setKashi('ডরমিটরিতে দুটি প্রধান সমস্যা রয়েছে।');
                }
            }
            if(counter >= 230.5){
                if(Language == 0){
                    setKashi('１つは、ゴミの捨て方が悪いことです。');
                }
                if(Language == 1){
                    setKashi('One, is the way of garbage disposal is wrong.');
                }
                if(Language == 2){
                    setKashi('第一件是垃圾沒有妥善處理。');
                }
                if(Language == 3){
                    setKashi('एउटा फोहोरको उचित व्यवस्थापन नहुनु ।');
                }
                if(Language == 4){
                    setKashi('එකක් තමයි කසළ නිසි ලෙස බැහැර නොකිරීම.');
                }
                if(Language == 5){
                    setKashi('တစ်ခုမှာ အမှိုက်ကို စနစ်တကျ မစွန့်ပစ်ခြင်းပင်ဖြစ်သည်။');
                }
                if(Language == 6){
                    setKashi('একটি হল আমরা যেভাবে আবর্জনা ফেলি তা খারাপ।');
                }
            }
            if(counter >= 235){
                if(Language == 0){
                    setKashi('ごみの捨て方のルールが寮にはってあります。ごみのスケジュールの通りに捨ててください。');
                }
                if(Language == 1){
                    setKashi('Rules for how to dispose of trash are posted in the dormitory. Please dispose off garbage according to the garbage schedule.');
                }
                if(Language == 2){
                    setKashi('宿舍內垃圾的處理有規定。 請依照垃圾分類表處理垃圾。');
                }
                if(Language == 3){
                    setKashi('छात्रावासमा फोहोर फाल्ने तरिकाका लागि नियमहरू छन्। कृपया फोहोर फाल्ने तालिका अनुसार फोहोर फाल्ने गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('නේවාසිකාගාරයේ කසළ බැහැර කරන්නේ කෙසේද යන්න පිළිබඳ නීති තිබේ. කරුණාකර කසළ කාලසටහනට අනුව කසළ බැහැර කරන්න.');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင်မှာ အမှိုက်ဘယ်လိုစွန့်ပစ်ရမယ်ဆိုတဲ့ စည်းကမ်းတွေရှိတယ်။ အမှိုက်ကို အချိန်ဇယားအတိုင်း စွန့်ပစ်ပါ။');
                }
                if(Language == 6){
                    setKashi('ডরমিটরিতে ময়লা ফেলার নিয়ম আছে। আবর্জনা সময়সূচী অনুযায়ী আবর্জনা নিষ্পত্তি করুন.');
                }
            }
            if(counter >= 242){
                if(Language == 0){
                    setKashi('夜に捨てては、いけません。');
                }
                if(Language == 1){
                    setKashi("Don't throw it away at night.");
                }
                if(Language == 2){
                    setKashi('晚上不要把它丟掉。');
                }
                if(Language == 3){
                    setKashi('यसलाई राती नफाल्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('රාත්රියේදී එය විසි නොකරන්න.');
                }
                if(Language == 5){
                    setKashi('ညဘက် မလွှင့်ပစ်ပါနဲ့။');
                }
                if(Language == 6){
                    setKashi('রাতে এটি ফেলে দেবেন না।');
                }
            }
            if(counter >= 246){
                if(Language == 0){
                    setKashi('２つ目は、うるさいことです。');
                }
                if(Language == 1){
                    setKashi("The second thing is that it's noisy.");
                }
                if(Language == 2){
                    setKashi('第二件事是噪音。');
                }
                if(Language == 3){
                    setKashi('दोस्रो कुरा यो हो कि यो कोलाहल हो।');
                }
                if(Language == 4){
                    setKashi('දෙවන කරුණ නම් එය ඝෝෂාකාරී වීමයි.');
                }
                if(Language == 5){
                    setKashi('ဒုတိယအချက်ကတော့ ဆူညံနေတာပါပဲ။');
                }
                if(Language == 6){
                    setKashi('দ্বিতীয় জিনিসটি হল এটি কোলাহলপূর্ণ।');
                }
            }
            if(counter >= 249.8){
                if(Language == 0){
                    setKashi('夜9時から、朝6時まで、まどをあけて大きいこえで話さないでください。');
                }
                if(Language == 1){
                    setKashi('Please do not open the windows and speak loudly from 9pm to 6am.');
                }
                if(Language == 2){
                    setKashi('请不要开着窗户大声说话。晚上 9 點至凌晨 6 點期間請勿大聲喧嘩。');
                }
                if(Language == 3){
                    setKashi('कृपया आफ्ना झ्यालहरू खुल्ला राखेर  राति ९ बजेदेखि बिहान ६ बजेसम्म चर्को स्वरमा नबोल्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර ඔබේ ජනේල විවෘතව තබා ගන්න, රාත්‍රී 9 සිට උදේ 6 දක්වා හයියෙන් කතා නොකරන්න.');
                }
                if(Language == 5){
                    setKashi('သင့်ပြတင်းပေါက်များကို ဖွင့်ထားပြီး ည ၉ နာရီမှ နံနက် ၆ နာရီအထိ ကျယ်လောင်စွာ မပြောပါနှင့်။');
                }
                if(Language == 6){
                    setKashi('দয়া করে জানালা খুলবেন না এবং রাত 9 টা থেকে সকাল 6 টা পর্যন্ত জোরে কথা বলবেন না।');
                }
            }
            if(counter >= 258.5){
                if(Language == 0){
                    setKashi('ギターや音楽もだめです。');
                }
                if(Language == 1){
                    setKashi('No guitar or music allowed.');
                }
                if(Language == 2){
                    setKashi('吉他和音樂也不好。');
                }
                if(Language == 3){
                    setKashi('गितार र संगीत पनि बजाउन पाइँदैन।');
                }
                if(Language == 4){
                    setKashi('ගිටාර් සහ සංගීතය ද හොඳ නැත.');
                }
                if(Language == 5){
                    setKashi('ဂစ်တာ နဲ့ သီချင်းလည်း တီးဆိုခြင်းမပြုရန်။');
                }
                if(Language == 6){
                    setKashi('কোন গিটার বা সঙ্গীত অনুমোদিত.');
                }
            }
            if(counter >= 263.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            if(counter >= 265){
                setMoveVideoTime(10);
                document.getElementById('video').currentTime = 0;
            }
        }

        //　11本目　寮までの道のりA
        if(MoveVideoTime == 10){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn MChBChange');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
            setChapterColor14('MoveChapBtn');

            if(counter >= 0){
                setMoveVideo(JourneyA);
            }

            //　寮(尾上団地)スタート！
            if(counter >= 0.5){
                if(Language == 0){
                    setKashi('寮(尾上団地)スタート！');
                }
                if(Language == 1){
                    setKashi('Start of the road to the dormitory (Onoe Danchi)!');
                }
                if(Language == 2){
                    setKashi('宿舍（尾上團地）開始了！');
                }
                if(Language == 3){
                    setKashi('छात्रावास (ओनोउए दान्ची) सुरु भएको छ!');
                }
                if(Language == 4){
                    setKashi('නේවාසිකාගාරය (Onoe Danchi) ආරම්භ වී ඇත!');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင် (Onoe Danchi) စတင်ပါပြီ။');
                }
                if(Language == 6){
                    setKashi('ডরমিটরি (Onoe Danchi) শুরু!');
                }
            }
            if(counter >= 2.9){
                if(Language == 0){
                    setKashi('学校を出て、ひだりにまがってください。');
                }
                if(Language == 1){
                    setKashi('Exit the school and turn to the left.');
                }
                if(Language == 2){
                    setKashi('退出學校並左轉。');
                }
                if(Language == 3){
                    setKashi('स्कूलबाट बाहिर निस्कनुहोस् र बायाँ घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('පාසලෙන් පිටවී වමට හැරෙන්න.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းမှထွက်ပြီး ဘယ်ဘက်သို့ လှည့်ပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে স্কুল ত্যাগ করুন এবং আশ্রয়ে যান।');
                }
            }
            if(counter >= 7.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 10.6){
                if(Language == 0){
                    setKashi('すぐつぎのかどをみぎにまがります。');
                }
                if(Language == 1){
                    setKashi('Turn right at the next corner.');
                }
                if(Language == 2){
                    setKashi('在下一個轉角處右轉。');
                }
                if(Language == 3){
                    setKashi('अलि अगाडि नजिकैबाट दाँया घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඊළඟ කෙළවරේ දකුණට හැරෙන්න.');
                }
                if(Language == 5){
                    setKashi('နောက်ထောင့်မှာ ညာဘက်ကို လှည့်ပါ။');
                }
                if(Language == 6){
                    setKashi('পরবর্তী কোণে ঘুরুন।');
                }
            }
            if(counter >= 14.9){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 18){
                if(Language == 0){
                    setKashi('「止まれ」はみぎ、ひだりをチェックしてからとおってください。');
                }
                if(Language == 1){
                    setKashi('Please check to the right and left before passing.');
                }
                if(Language == 2){
                    setKashi('當出現“停止”標誌時，請在通過前檢查左右。');
                }
                if(Language == 3){
                    setKashi("''रोक्नुहोस्।'' कृपया पास गर्नु अघि दायाँ र बायाँ जाँच गर्नुहोस्।");
                }
                if(Language == 4){
                    setKashi(`"නවත්වන්න.''"කරුණාකර පසුකර යාමට පෙර වම සහ දකුණ පරීක්ෂා කරන්න.`);
                }
                if(Language == 5){
                    setKashi('"ရပ်ပါ" ဟုပြောသောအခါ မဖြတ်သန်းမီ ညာဘက်နှင့် ဘယ်ဘက်တို့ကို စစ်ဆေးပါ။');
                }
                if(Language == 6){
                    setKashi("''স্টপ।'' পাস করার আগে মিগি এবং হিদারি চেক করুন।");
                }
            }
            if(counter >= 22.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 30.9){
                if(Language == 0){
                    setKashi('ひだりにまがります。');
                }
                if(Language == 1){
                    setKashi('Turn left.');
                }
                if(Language == 2){
                    setKashi('左轉。');
                }
                if(Language == 3){
                    setKashi('बायाँ घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('වමට හරවන්න.');
                }
                if(Language == 5){
                    setKashi('လက်ဝဲဘက်ကွေ့ပါ။');
                }
                if(Language == 6){
                    setKashi('আমি হ্যাং আউট করতে যাচ্ছি।');
                }
            }
            if(counter >= 35.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 41.7){
                if(Language == 0){
                    setKashi('大きいみちに来たら、みぎにまがってください。');
                }
                if(Language == 1){
                    setKashi('When you reach the large street, turn to the right.');
                }
                if(Language == 2){
                    setKashi('當你來到一條大路時，向右轉。');
                }
                if(Language == 3){
                    setKashi('जब तपाईं ठूलो सडकमा आउनुहुन्छ, कृपया दायाँ घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබ විශාල මාර්ගයට පැමිණෙන විට කරුණාකර දකුණටහැරෙන්න.');
                }
                if(Language == 5){
                    setKashi('လမ်းမကြီးကိုရောက်ရင် လှည့်ကြည့်လိုက်ပါ။');
                }
                if(Language == 6){
                    setKashi('আপনি যখন একটি বড় রাস্তায় আসেন, অনুগ্রহ করে ঘুরে আসুন।');
                }
            }
            if(counter >= 46.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 54.4){
                if(Language == 0){
                    setKashi('しんごうをわたったら、ひだりにまがります。');
                }
                if(Language == 1){
                    setKashi('After you cross the traffic light, turn left.');
                }
                if(Language == 2){
                    setKashi('穿過紅綠燈後，左轉。');
                }
                if(Language == 3){
                    setKashi('ट्राफिक लाइट पार गरेपछि, बायाँ घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබ රථවාහන ආලෝකය තරණය කළ පසු, වමට හැරෙන්න.');
                }
                if(Language == 5){
                    setKashi('မီးပွိုင့်ကိုဖြတ်ပြီးရင် ဘယ်ဘက်ကွေ့ပါ။');
                }
                if(Language == 6){
                    setKashi('শিঙ্গো পার হওয়ার পর, রিজের নিচে যান।');
                }
            }
            if(counter >= 58.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 60){
                if(Language == 0){
                    setKashi('しんごうがあおになったら、みちをわたってください。');
                }
                if(Language == 1){
                    setKashi('When the traffic light turns green, cross the street.');
                }
                if(Language == 2){
                    setKashi('當燈變綠時，過馬路。');
                }
                if(Language == 3){
                    setKashi('जब बत्ती हरियो हुन्छ, सडक पार गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ආලෝකය කොළ පැහැයට හැරෙන විට, පාර මාරැ වෙන්න.');
                }
                if(Language == 5){
                    setKashi('မီးစိမ်းသွားလျှင် လမ်းဖြတ်ကူးပါ။');
                }
                if(Language == 6){
                    setKashi('ট্রাফিক লাইট সবুজ হয়ে গেলে রাস্তা পার হন।');
                }
            }
            if(counter >= 64){
                if(Language == 0){
                    setKashi('ここに、自転車屋「JOY」があります。');
                }
                if(Language == 1){
                    setKashi('There is a bicycle shop called "JOY" here.');
                }
                if(Language == 2){
                    setKashi('這裡有一家名為「JOY」的自行車店。');
                }
                if(Language == 3){
                    setKashi("यहाँ ``JOY'' नामक साइकल पसल छ।");
                }
                if(Language == 4){
                    setKashi('මෙතන තියෙනවා "JOY" කියලා බයිසිකල් කඩයක්.');
                }
                if(Language == 5){
                    setKashi('ဒီမှာ ``JOY´ လို့ ခေါ်တဲ့ စက်ဘီးဆိုင်ရှိတယ်။');
                }
                if(Language == 6){
                    setKashi('এখানে "JOY" নামে একটি সাইকেলের দোকান আছে।');
                }
            }
            if(counter >= 66.5){
                if(Language == 0){
                    setKashi('自転車屋「JOY」のまえを、みぎにまがってください。');
                }
                if(Language == 1){
                    setKashi('Please turn right in front of the bicycle shop "JOY".');
                }
                if(Language == 2){
                    setKashi('在自行車店“JOY”前右轉。');
                }
                if(Language == 3){
                    setKashi('कृपया साइकल पसल "JOY" को अगाडिबाट दायाँ घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර "JOY" බයිසිකල් සාප්පුව දකුණට හැරෙන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးပြုပြီး "JOY" စက်ဘီးဆိုင်ရဲ့ညာဘက်ကိုကွေ့ပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে সাইকেলের দোকান "JOY" এর সামনে ঘুরে আসুন।');
                }
            }
            if(counter >= 70.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 78.3){
                if(Language == 0){
                    setKashi('しばらくまっすぐすすんでください。');
                }
                if(Language == 1){
                    setKashi('Please go straight for a bit.');
                }
                if(Language == 2){
                    setKashi('請繼續向前行駛片刻。');
                }
                if(Language == 3){
                    setKashi('केही समय सिधा अगाडि बढि रहनुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර  කෙළින්ම යන්න.');
                }
                if(Language == 5){
                    setKashi('ခဏလောက် ရှေ့တည့်တည့်ကို ဆက်သွားပါ။');
                }
                if(Language == 6){
                    setKashi('দয়া করে এক মুহুর্তের জন্য সোজা যান।');
                }
            }
            if(counter >= 82.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 87.7){
                if(Language == 0){
                    setKashi('あるいている人に気をつけてください。');
                }
                if(Language == 1){
                    setKashi('Be careful of people who are out there.');
                }
                if(Language == 2){
                    setKashi('請注意行人。');
                }
                if(Language == 3){
                    setKashi('हिँड्ने मान्छे भएको हुनाले ख्याल गरेर चलाउनुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඇවිදන් යන අය සිටින බැහින්  පරිස්සම් යන්න.');
                }
                if(Language == 5){
                    setKashi('လမ်းလျှောက်နေသောသူများရှိတဲ့အတွက် ဂရုစိုက်ပါ။');
                }
                if(Language == 6){
                    setKashi('দয়া করে পথচারীদের জন্য সতর্ক থাকুন।');
                }
            }
            if(counter >= 92){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 106.5){
                if(Language == 0){
                    setKashi('ひだりにまがってください。');
                }
                if(Language == 1){
                    setKashi('Please turn to the left.');
                }
                if(Language == 2){
                    setKashi('請左轉。');
                }
                if(Language == 3){
                    setKashi('बायाँ घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර වමට හැරෙන්න.');
                }
                if(Language == 5){
                    setKashi('ဘယ်ဘက်ကိုကွေ့ပါ။');
                }
                if(Language == 6){
                    setKashi('দয়া করে বাম দিকে ঘুরুন।');
                }
            }
            if(counter >= 110.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 120.9){
                if(Language == 0){
                    setKashi('まっすぐすすんでください。');
                }
                if(Language == 1){
                    setKashi('Please go straight ahead.');
                }
                if(Language == 2){
                    setKashi('請一直往前走。');
                }
                if(Language == 3){
                    setKashi('केही समय सिधा अगाडि बढि रहनुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර කෙළින්ම ඉදිරියට යන්න.');
                }
                if(Language == 5){
                    setKashi('ရှေ့တည့်တည့်ကို သွားပါ။');
                }
                if(Language == 6){
                    setKashi('দয়া করে সরাসরি এগিয়ে যান।');
                }
            }
            if(counter >= 125.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 144.2){
                if(Language == 0){
                    setKashi('おおぞね駅のこうさてんで、みぎにまがります。');
                }
                if(Language == 1){
                    setKashi('At the intersection of Ozone Station, turn right.');
                }
                if(Language == 2){
                    setKashi('在大曾根站交叉路口右轉。');
                }
                if(Language == 3){
                    setKashi('ओजोन स्टेशन चोक (intersection)बाट  दाँया घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඕසෝන් දුම්රිය ස්ථානයේ   ඉදිරියෙන් දකුණට හැරෙන්න.');
                }
                if(Language == 5){
                    setKashi('အိုဇုန်းဘူတာရုံလမ်းဆုံတွင် ညာဘက်သို့ကွေ့ပါ။');
                }
                if(Language == 6){
                    setKashi('ওজোন স্টেশনের কোসেটেনে, মিগিতে পরিণত করুন।');
                }
            }
            if(counter >= 148.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 149.5){
                if(Language == 0){
                    setKashi('まえに、BMWの店があります。');
                }
                if(Language == 1){
                    setKashi('There is a BMW store in front of you.');
                }
                if(Language == 2){
                    setKashi('前面有一家BMW專賣店。');
                }
                if(Language == 3){
                    setKashi('अगाडि एउटा BMW कार स्टोर छ।');
                }
                if(Language == 4){
                    setKashi('ඉදිරිපස BMW වෙළඳසැලක් ඇත.');
                }
                if(Language == 5){
                    setKashi('ရှေ့တွင် BMW စတိုးတစ်ခုရှိသည်။');
                }
                if(Language == 6){
                    setKashi('আপনার সামনে একটি BMW স্টোর আছে।');
                }
            }
            if(counter >= 154){
                if(Language == 0){
                    setKashi('BMWの店を、ひだりにまがります。');
                }
                if(Language == 1){
                    setKashi('Turn left at the BMW store.');
                }
                if(Language == 2){
                    setKashi('在BMW專賣店左轉。');
                }
                if(Language == 3){
                    setKashi('BMW स्टोरमा, बाँया घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('BMW වෙළඳසැලේදී, වමට හැරෙන්න.');
                }
                if(Language == 5){
                    setKashi('BMW စတိုးတွင် ဘယ်ဘက်သို့ လှည့်ပါ။');
                }
                if(Language == 6){
                    setKashi('BMW স্টোরের কোণে বাম দিকে ঘুরুন।');
                }
            }
            if(counter >= 158.1){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 162.6){
                if(Language == 0){
                    setKashi('ひだりのしんごうをわたってください。まえに「mini mini」があります。');
                }
                if(Language == 1){
                    setKashi('Please cross the traffic light on the left. There is a "mini mini" in front.');
                }
                if(Language == 2){
                    setKashi('穿過左側的紅綠燈。  面前有一個「迷你迷你」。');
                }
                if(Language == 3){
                    setKashi('कृपया बायाँ तर्फबाट हुँदै सडक पार गर्नुहोस्। तपाईको अगाडि एउटा "मिनी मिनी" छ।');
                }
                if(Language == 4){
                    setKashi('වම් පසින් පාර  මාර්ග සාංඥාව මාරැ වෙන්න ඔබ ඉදිරියෙහි " mini mini" ඇත.');
                }
                if(Language == 5){
                    setKashi('ဘယ်ဘက်မီးပွိုင့်ကိုဖြတ်ပါ။ သင့်ရှေ့မှာ "မီနီမီနီ" ရှိတယ်။');
                }
                if(Language == 6){
                    setKashi('ট্রাফিক লাইট ক্রস করুন. আপনার সামনে একটি "minimini"আছে।');
                }
            }
            if(counter >= 167){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 174.8){
                if(Language == 0){
                    setKashi('まっすぐすすみます。');
                }
                if(Language == 1){
                    setKashi('Go straight ahead.');
                }
                if(Language == 2){
                    setKashi('直行。');
                }
                if(Language == 3){
                    setKashi('सिधा अगाडि बढि रहनुहोस्।');
                }
                if(Language == 4){
                    setKashi('කෙලින්ම යන්න.');
                }
                if(Language == 5){
                    setKashi('တည့်တည့်သွားပါ။');
                }
                if(Language == 6){
                    setKashi('সোজা এগিয়ে যান।');
                }
            }
            if(counter >= 179){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 207.7){
                if(Language == 0){
                    setKashi('へいあんどおり駅');
                }
                if(Language == 1){
                    setKashi('Heian Dori Station');
                }
                if(Language == 2){
                    setKashi('平安通站');
                }
                if(Language == 3){
                    setKashi('हे एन दोओरी(Heiandori) स्टेशन');
                }
                if(Language == 4){
                    setKashi('EIANDORI දුම්රිය  ස්ථානය');
                }
                if(Language == 5){
                    setKashi('Heiandori ဘူတာရုံ');
                }
                if(Language == 6){
                    setKashi('হেইয়ান ডোরি স্টেশন');
                }
            }
            if(counter >= 210){
                if(Language == 0){
                    setKashi('へいあんどおり駅のしんごうをわたって、みぎにまがります。');
                }
                if(Language == 1){
                    setKashi('Cross the signal at Heiandori Station and turn right.');
                }
                if(Language == 2){
                    setKashi('在平通站穿過信號燈並右轉。');
                }
                if(Language == 3){
                    setKashi('हे एन दोओरी(Heiandori) स्टेशनमा सिग्नल पार गर्नुहोस् र दायाँ घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('HEIANDORI ස්ථානයේ ඇති මාර්ග සංඥාව හරස් කර දකුණට හැරෙන්න.');
                }
                if(Language == 5){
                    setKashi('Heiandori ဘူတာရှိ မီးပွိုင့်ကို ဖြတ်ပြီး ညာဘက်သို့ ကွေ့ပါ။ ');
                }
                if(Language == 6){
                    setKashi('Heiandori স্টেশনের signal পেরিয়ে মিগিতে পরিণত করুন।');
                }
            }
            if(counter >= 215.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 242.5){
                if(Language == 0){
                    setKashi('ひだりに、スーパー「AEON そよら」があります。');
                }
                if(Language == 1){
                    setKashi('There is a supermarket "AEON Soyora" on the left.');
                }
                if(Language == 2){
                    setKashi('左邊有一家超市「AEON Soyora」。');
                }
                if(Language == 3){
                    setKashi('त्यहाँ बायाँ मा एक सुपरमार्केट "AEON Soyora" छ।');
                }
                if(Language == 4){
                    setKashi('වම් පසින් "AEON Soyora" සුපිරි වෙළඳසැලක් ඇත.');
                }
                if(Language == 5){
                    setKashi('ဘယ်ဘက်တွင် "AEON Soyora" စူပါမားကတ်တစ်ခုရှိသည်။');
                }
                if(Language == 6){
                    setKashi("হিদারিতে ``AEON Soyora'' নামে একটি সুপার মার্কেট আছে।");
                }
            }
            if(counter >= 245){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 252.7){
                if(Language == 0){
                    setKashi('交番をひだりにまがります。');
                }
                if(Language == 1){
                    setKashi('Turn left at the police box.');
                }
                if(Language == 2){
                    setKashi('在警察崗亭左轉。');
                }
                if(Language == 3){
                    setKashi('पुलिस स्टेशनबाट बाँया घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('පොලිස්  මුරපොලෙන් වමට හැරෙන්න.');
                }
                if(Language == 5){
                    setKashi('ရဲဘောက်စ်မှာ ဘယ်ဘက်ကွေ့။');
                }
                if(Language == 6){
                    setKashi('পুলিশ বক্সের কোণে বাম দিকে ঘুরুন।');
                }
            }
            if(counter >= 257){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 263.1){
                if(Language == 0){
                    setKashi('ここから「AEON そよら」に入ることができます。');
                }
                if(Language == 1){
                    setKashi('You can enter "AEON Soyora" from here.');
                }
                if(Language == 2){
                    setKashi('從這裡輸入“AEON Soyora”。');
                }
                if(Language == 3){
                    setKashi('यहाँबाट "AEON Soyora" प्रवेश गर्न मिल्छ।');
                }
                if(Language == 4){
                    setKashi('මෙතනින් "AEON Soyora" ඇතුලත් කරන්න පුළුවන්');
                }
                if(Language == 5){
                    setKashi('ဤနေရာမှ "AEON Soyora" သို့ဝင်နိုင်ပါတယ်။');
                }
                if(Language == 6){
                    setKashi('আপনি এখান থেকে "AEON SOYORA" যেতে পারেন।');
                }
            }
            if(counter >= 266.3){
                if(Language == 0){
                    setKashi('寮は、ここです。');
                }
                if(Language == 1){
                    setKashi('The dormitory is here.');
                }
                if(Language == 2){
                    setKashi('宿舍就在這裡。');
                }
                if(Language == 3){
                    setKashi('छात्रावास यहाँ छ।');
                }
                if(Language == 4){
                    setKashi('නේවාසිකාගාරය මෙහි ඇත.');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင်က ဒီမှာပါ။');
                }
                if(Language == 6){
                    setKashi('ডরমিটরি এখানে।');
                }
            }
            if(counter >= 270){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 275.6){
                if(Language == 0){
                    setKashi('ひだりにまがります。');
                }
                if(Language == 1){
                    setKashi('Turn left.');
                }
                if(Language == 2){
                    setKashi('左轉。');
                }
                if(Language == 3){
                    setKashi('बायाँ घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('වමට හරවන්න.');
                }
                if(Language == 5){
                    setKashi('ဘယ်ဘက်ကွေ့ပါ။');
                }
                if(Language == 6){
                    setKashi('বাম দিকে ঘুরুন।');
                }
            }
            if(counter >= 280){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 285){
                if(Language == 0){
                    setKashi('みぎにまがります。');
                }
                if(Language == 1){
                    setKashi('Turn right.');
                }
                if(Language == 2){
                    setKashi('右轉。');
                }
                if(Language == 3){
                    setKashi('दाँया मोड।');
                }
                if(Language == 4){
                    setKashi('දකුණට හරවන්න.');
                }
                if(Language == 5){
                    setKashi('ညာဘက်ကွေ့ပါ။');
                }
                if(Language == 6){
                    setKashi('আমি মিগিতে যাচ্ছি।');
                }
            }
            if(counter >= 289.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 297.5){
                if(Language == 0){
                    setKashi('寮(尾上団地)ゴール！');
                }
                if(Language == 1){
                    setKashi('The road to the dormitory (Onoe Danchi) goal!');
                }
                if(Language == 2){
                    setKashi('宿舍（尾上團地）到達！');
                }
                if(Language == 3){
                    setKashi('छात्रावास (Onoe Danchi) लक्ष्य!');
                }
                if(Language == 4){
                    setKashi('නේවාසිකාගාරය (Onoe Danchi) ඉලක්කය!');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင် (Onoe Danchi) ပန်းတိုင်။');
                }
                if(Language == 6){
                    setKashi('ডরমিটরি (Onoe Danchi) গোল!');
                }
            }
            if(counter >= 300.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            if(counter >= 305){
                setMoveVideoTime(11);
                document.getElementById('video').currentTime = 0;
            }
        }

        //　12本目　寮までの道のりB
        if(MoveVideoTime == 11){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn MChBChange');
            setChapterColor13('MoveChapBtn');
            setChapterColor14('MoveChapBtn');

            if(counter >= 0){
                setMoveVideo(JourneyB);
            }

            //　寮(大幸東団地)スタート！
            if(counter >= 1.5){
                if(Language == 0){
                    setKashi('寮(大幸東団地)スタート！');
                }
                if(Language == 1){
                    setKashi('Start of the road to the dormitory (Taiko Higashi Danchi)!');
                }
                if(Language == 2){
                    setKashi('宿舍（大幸東團地）開始了！');
                }
                if(Language == 3){
                    setKashi('छात्रावास (दाइको हिगाशि दान्ची)सुरु भएको छ।');
                }
                if(Language == 4){
                    setKashi('නේවාසිකාගාරය (Daiko Higashi Danchi) ආරම්භ කර ඇත!');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင် (Daiko Higashi Danchi) စတင်ပါပြီ။');
                }
                if(Language == 6){
                    setKashi('ডরমিটরি (DAIKO HIGASHI) শুরু হয়!');
                }
            }
            if(counter >= 4.2){
                if(Language == 0){
                    setKashi('学校を出て、みぎにまがってください。');
                }
                if(Language == 1){
                    setKashi('Exit the school and turn right.');
                }
                if(Language == 2){
                    setKashi('退出學校並向右轉。');
                }
                if(Language == 3){
                    setKashi('स्कूलबाट निस्कनुहोस् र दायाँ घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('පාසලෙන් පිටවී දකුණට හැරෙන්න.');
                }
                if(Language == 5){
                    setKashi('ကျောင်းမှထွက်ပြီး ညာဘက်သို့ လှည့်ပါ။');
                }
                if(Language == 6){
                    setKashi('স্কুল থেকে প্রস্থান করুন এবং ডানদিকে ঘুরুন।');
                }
            }
            if(counter >= 8.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 20.8){
                if(Language == 0){
                    setKashi('大きいみちに来たら、ひだりにまがってください。');
                }
                if(Language == 1){
                    setKashi('When you reach the large street, please turn to the left.');
                }
                if(Language == 2){
                    setKashi('當您來到一條大路時，請左轉。');
                }
                if(Language == 3){
                    setKashi('जब तपाईं ठूलो सडकमा आउनुहुन्छ, कृपया बायाँ घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ඔබ විශාල මාර්ගයකට පැමිණෙන විට, කරුණාකර වමට හැරෙන්න.');
                }
                if(Language == 5){
                    setKashi('လမ်းကျယ်ကြီးရောက်ရင် ဘယ်ဘက်ကို ကွေ့ပါ။');
                }
                if(Language == 6){
                    setKashi('বড় রাস্তায় এলে বাম দিকে ঘুরুন।');
                }
            }
            if(counter >= 25){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 57){
                if(Language == 0){
                    setKashi('ひだりにスーパー「AEON」があります。');
                }
                if(Language == 1){
                    setKashi('There is a supermarket "AEON" on the left.');
                }
                if(Language == 2){
                    setKashi('左邊有一家超市“AEON”。');
                }
                if(Language == 3){
                    setKashi('बायाँ मा एक सुपरमार्केट "AEON" छ।');
                }
                if(Language == 4){
                    setKashi('වම් පසින් "AEON" සුපිරි වෙළඳසැලක් ඇත.');
                }
                if(Language == 5){
                    setKashi('ဘယ်ဘက်တွင် "AEON" စူပါမားကတ်တစ်ခုရှိသည်။');
                }
                if(Language == 6){
                    setKashi('হিদারিতে একটি সুপারমার্কেট "AEON" আছে।');
                }
            }
            if(counter >= 61){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 79){
                if(Language == 0){
                    setKashi('みちをわたって、みぎにまがってください。');
                }
                if(Language == 1){
                    setKashi('Cross the street and turn to the right.');
                }
                if(Language == 2){
                    setKashi('過馬路並右轉。');
                }
                if(Language == 3){
                    setKashi('सडक पार गर्नुहोस् र दायाँ घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('පාර හරහා ගොස් දකුණට හැරෙන්න.');
                }
                if(Language == 5){
                    setKashi('လမ်းကိုဖြတ်ပြီး ညာဘက်ကွေ့လိုက်ပါ။');
                }
                if(Language == 6){
                    setKashi('রাস্তা পেরিয়ে ঘুরুন।');
                }
            }
            if(counter >= 83.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 124.6){
                if(Language == 0){
                    setKashi('大きいこうさてんをわたります。');
                }
                if(Language == 1){
                    setKashi('Cross the big intersection.');
                }
                if(Language == 2){
                    setKashi('穿過大十字路口。');
                }
                if(Language == 3){
                    setKashi('ठूलो चोक (intersection) पार गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('විශාල මංසන්ධිය තරණය කරන්න.');
                }
                if(Language == 5){
                    setKashi('လမ်းဆုံကြီးကို ဖြတ်ကျော်ပါ။');
                }
                if(Language == 6){
                    setKashi('বড় রাস্তা পার হও।');
                }
            }
            if(counter >= 128.6){
                if(Language == 0){
                    setKashi('すなだばし駅があります。寮からいちばん近い駅です。');
                }
                if(Language == 1){
                    setKashi('There is Sunadabashi station. This is the station closest to the dormitory.');
                }
                if(Language == 2){
                    setKashi('有砂田橋站。　這是離宿舍最近的車站。');
                }
                if(Language == 3){
                    setKashi('सुनदाबासी स्टेशन छ।　यो छात्रावासको सबैभन्दा नजिकको स्टेशन हो।');
                }
                if(Language == 4){
                    setKashi('සුනදබාෂි දුම්රිය ස්ථානය ඇත.　මෙය නේවාසිකාගාරයට ආසන්නම ස්ථානයයි.');
                }
                if(Language == 5){
                    setKashi('Sunadabashi ဘူတာရှိတယ်။　　ဤသည်မှာ အိပ်ဆောင်နှင့် အနီးဆုံးဘူတာဖြစ်သည်။');
                }
                if(Language == 6){
                    setKashi('এটি "SUNADABASHI" স্টেশন। এটি ছাত্রাবাসের সবচেয়ে কাছের স্টেশন।');
                }
            }
            if(counter >= 133.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 135){
                if(Language == 0){
                    setKashi('スーパー「Max Valu」があります。');
                }
                if(Language == 1){
                    setKashi('There is a supermarket "Max Valu".');
                }
                if(Language == 2){
                    setKashi('有一個超市場“Max Valu”。');
                }
                if(Language == 3){
                    setKashi('त्यहाँ एक सुपरमार्केट　"MAX Valu" छ।');
                }
                if(Language == 4){
                    setKashi('සුපිරි "Max Valu" එකක් තියෙනවා.');
                }
                if(Language == 5){
                    setKashi('Super "Max Valu" ရှိတယ်။');
                }
                if(Language == 6){
                    setKashi('একটি সুপার "MAX VALU" আছে।');
                }
            }
            if(counter >= 140){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 165.5){
                if(Language == 0){
                    setKashi('ひだりにまがってください。');
                }
                if(Language == 1){
                    setKashi('Please turn to the left.');
                }
                if(Language == 2){
                    setKashi('請左轉。');
                }
                if(Language == 3){
                    setKashi('कृपया बायाँ घुम्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර වමට හැරෙන්න.');
                }
                if(Language == 5){
                    setKashi('ဘယ်ဘက်လှည့်ပါ။');
                }
                if(Language == 6){
                    setKashi('দয়া করে বাম দিকে ঘুরুন।');
                }
            }
            if(counter >= 169.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 172.5){
                if(Language == 0){
                    setKashi('ここが寮です。');
                }
                if(Language == 1){
                    setKashi('This is the dormitory.');
                }
                if(Language == 2){
                    setKashi('這是宿舍。');
                }
                if(Language == 3){
                    setKashi('यो छात्रावास हो।');
                }
                if(Language == 4){
                    setKashi('මේ නේවාසිකාගාරයයි.');
                }
                if(Language == 5){
                    setKashi('ဒါက အိပ်ဆောင်ပါ။');
                }
                if(Language == 6){
                    setKashi('এটি হল ছাত্রাবাস।');
                }
            }
            if(counter >= 177){
                if(Language == 0){
                    setKashi('スーパー「ヤマト」があります。');
                }
                if(Language == 1){
                    setKashi('There is a supermarket "Yamato".');
                }
                if(Language == 2){
                    setKashi('有一家超市「YAMATO」。');
                }
                if(Language == 3){
                    setKashi('त्यहाँ एक सुपरमार्केट "Yamato" छ।');
                }
                if(Language == 4){
                    setKashi('"යමටෝ" සුපිරි වෙළඳසැලක් ඇත.');
                }
                if(Language == 5){
                    setKashi('စူပါမားကတ် "Yamato" ရှိတယ်။');
                }
                if(Language == 6){
                    setKashi('একটি সুপারমার্কেট "Yamato" আছে।');
                }
            }
            if(counter >= 182){
                if(Language == 0){
                    setKashi('寮(大幸東団地)ゴール！');
                }
                if(Language == 1){
                    setKashi('Road to the dormitory (Taiko Higashi Danchi) Goal!');
                }
                if(Language == 2){
                    setKashi('宿舍（大幸東團地）到達！');
                }
                if(Language == 3){
                    setKashi('छात्रावास (दाइको हिगाशि दान्ची) गोल.....।');
                }
                if(Language == 4){
                    setKashi('නේවාසිකාගාරය (Daiko Higashi Danchi) ඉලක්කය!');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင် (Daiko Higashi Danchi) ပန်းတိုင်။');
                }
                if(Language == 6){
                    setKashi('ডরমিটরি (তাইকো হিগাশি দাঞ্চি) গোল!');
                }
            }
            if(counter >= 185.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            if(counter >= 187.5){
                setMoveVideoTime(12);
                document.getElementById('video').currentTime = 0;
            }
        }

        //　13本目　寮ツアー
        if(MoveVideoTime == 12){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn MChBChange');
            setChapterColor14('MoveChapBtn');

            if(counter >= 0){
                setMoveVideo(Dormitory);
            }

            //　校外学習
            if(counter >= 0.8){
                if(Language == 0){
                    setKashi('NSA日本語学校寮ツアー動画');
                }
                if(Language == 1){
                    setKashi('NSA Japanese School Dormitory Tour Video');
                }
                if(Language == 2){
                    setKashi('NSA日本學校宿舍參觀視頻');
                }
                if(Language == 3){
                    setKashi('NSA जापानी स्कूल छात्रावास भ्रमण भिडियो');
                }
                if(Language == 4){
                    setKashi('NSA ජපන් පාසල් නේවාසිකාගාර චාරිකා වීඩියෝව');
                }
                if(Language == 5){
                    setKashi('NSA Japanese School Dormitory Tour Video');
                }
                if(Language == 6){
                    setKashi('NSA জাপানি স্কুল ডরমিটরি ট্যুর ভিডিও');
                }
            }
            if(counter >= 4.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 18){
                if(Language == 0){
                    setKashi('ゴミ箱 ごみはすぐにすてて、部屋をきれいに使ってください。');
                }
                if(Language == 1){
                    setKashi('Trash can Please throw away trash immediately and keep your room clean.');
                }
                if(Language == 2){
                    setKashi('垃圾桶 請立即扔掉垃圾並保持房間清潔。');
                }
                if(Language == 3){
                    setKashi('फोहोर बक्स  कृपया तुरुन्तै फोहोर फाल्नुहोस् र आफ्नो कोठा सफा राख्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කුණු කූඩය    කරුණාකර වහාම කුණු ඉවත දමා ඔබේ කාමරය පිරිසිදුව තබා ගන්න.');
                }
                if(Language == 5){
                    setKashi('အမှိုက်ပုံး  အမှိုက်ကို ချက်ချင်းစွန့်ပစ်ပြီး အခန်းကို သန့်ရှင်းအောင်ထားပါ။');
                }
                if(Language == 6){
                    setKashi('আবর্জনা অনুগ্রহ করে অবিলম্বে আবর্জনা ফেলে দিন এবং আপনার ঘর পরিষ্কার রাখুন।');
                }
            }
            if(counter >= 20.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 34.5){
                if(Language == 0){
                    setKashi('部屋①');
                }
                if(Language == 1){
                    setKashi('Room ①');
                }
                if(Language == 2){
                    setKashi('房間①');
                }
                if(Language == 3){
                    setKashi('कोठा ①');
                }
                if(Language == 4){
                    setKashi('කාමරය ①');
                }
                if(Language == 5){
                    setKashi('အခန်း ①');
                }
                if(Language == 6){
                    setKashi('রুম ①');
                }
            }
            if(counter >= 36.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 43.6){
                if(Language == 0){
                    setKashi('洗濯機（せんたくき）  じゅんばんに使いましょう。');
                }
                if(Language == 1){
                    setKashi('Washing machine Use them in order.');
                }
                if(Language == 2){
                    setKashi('洗衣機  請與您的室友輪流使用它。');
                }
                if(Language == 3){
                    setKashi('कपडा धुनी मिसिन    मिलेर पालैपालो प्रयोग गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('රෙදි සෝදන යන්ත්රය    ඒවා පිළිවෙලට භාවිතා කරන්න.');
                }
                if(Language == 5){
                    setKashi('အဝတ်လျှော်စက်　  အဲဒါတွေကို စနစ်တကျသုံးပါ။');
                }
                if(Language == 6){
                    setKashi('ওয়াশিং মেশিন এর পুঙ্খানুপুঙ্খভাবে ব্যবহার করা যাক।');
                }
            }
            if(counter >= 46.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 49.7){
                if(Language == 0){
                    setKashi('洗面台（せんめんだい）  ルームメイトといっしょに使うので、きれいにしてください。');
                }
                if(Language == 1){
                    setKashi('Wash basin You will be using it with your roommate, so please keep it clean.');
                }
                if(Language == 2){
                    setKashi('洗手盆  您將與室友一起使用它，因此請保持清潔。');
                }
                if(Language == 3){
                    setKashi('मुख धुने बेसिन तपाइँ यसलाई तपाइँको रूममेट संग प्रयोग गर्नुहुनेछ, त्यसैले कृपया यसलाई सफा राख्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('නාන බේසම ඔබ එය ඔබේ නේවාසික මිතුරා සමඟ භාවිතා කරනු ඇත, එබැවින් කරුණාකර එය පිරිසිදුව තබා ගන්න.');
                }
                if(Language == 5){
                    setKashi('မျက်နှာသစ်စင် သင့်အခန်းဖော်နှင့်အတူ အသုံးပြုမည်ဖြစ်သောကြောင့် သန့်ရှင်းအောင်ထားပါ။');
                }
                if(Language == 6){
                    setKashi('ওয়াশবাসিন আপনি এটি আপনার রুমমেটের সাথে ব্যবহার করবেন, তাই দয়া করে এটি পরিষ্কার রাখুন।');
                }
            }
            if(counter >= 52.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 56.7){
                if(Language == 0){
                    setKashi('おふろ  使うとき、かんきせんをまわしてください。');
                }
                if(Language == 1){
                    setKashi('Bath Please turn on the ventilation fan when using it.');
                }
                if(Language == 2){
                    setKashi('浴室  使用時請打開換氣扇。');
                }
                if(Language == 3){
                    setKashi('स्नान कक्ष，कृपया यसलाई प्रयोग गर्दा भेन्टिलेसन फ्यान खोल्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('නාන    කරුණාකර එය භාවිතා කරන විට වාතාශ්රය විදුලි පංකාව සක්රිය කරන්න.');
                }
                if(Language == 5){
                    setKashi('ရေချိုး  အသုံးပြုနေစဉ် လေဝင်လေထွက်ပန်ကာကို ဖွင့်ပါ။');
                }
                if(Language == 6){
                    setKashi('স্নান ব্যবহার করার সময় অনুগ্রহ করে ফ্যান চালু করুন।');
                }
            }
            if(counter >= 59.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 75.5){
                if(Language == 0){
                    setKashi('トイレ  こまめにそうじしてください。');
                }
                if(Language == 1){
                    setKashi('Toilet Please clean it frequently.');
                }
                if(Language == 2){
                    setKashi('廁所  請經常清潔。');
                }
                if(Language == 3){
                    setKashi('शौचालय कृपया बारम्बार सफा गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('වැසිකිළිය    කරුණාකර නිතර පිරිසිදු කරන්න.');
                }
                if(Language == 5){
                    setKashi('အိမ်သာ  မကြာခဏ သန့်ရှင်းပေးပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে ঘন ঘন টয়লেট পরিষ্কার করুন।');
                }
            }
            if(counter >= 78.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 85.5){
                if(Language == 0){
                    setKashi('部屋② ');
                }
                if(Language == 1){
                    setKashi('Room ②');
                }
                if(Language == 2){
                    setKashi('房間② ');
                }
                if(Language == 3){
                    setKashi('कोठा ② ');
                }
                if(Language == 4){
                    setKashi('කාමරය ②  ');
                }
                if(Language == 5){
                    setKashi('အခန်း ②  ');
                }
                if(Language == 6){
                    setKashi('রুম ②');
                }
            }
            if(counter >= 88.5){
                if(Language == 0){
                    setKashi(' ');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 92){
                if(Language == 0){
                    setKashi('部屋③ ');
                }
                if(Language == 1){
                    setKashi('Room ③');
                }
                if(Language == 2){
                    setKashi('房間③');
                }
                if(Language == 3){
                    setKashi('कोठा ③');
                }
                if(Language == 4){
                    setKashi('කාමරය ③');
                }
                if(Language == 5){
                    setKashi('အခန်း ③');
                }
                if(Language == 6){
                    setKashi('রুম ③');
                }
            }
            if(counter >= 95.2){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 105.4){
                if(Language == 0){
                    setKashi('キッチン  きれいにそうじして、使ってください。');
                }
                if(Language == 1){
                    setKashi('Kitchen Please clean it thoroughly and use it.');
                }
                if(Language == 2){
                    setKashi('廚房  請徹底清潔並使用。');
                }
                if(Language == 3){
                    setKashi('भान्सा कृपया यसलाई राम्ररी सफा गर्नुहोस् र प्रयोग गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කුස්සිය    කරුණාකර එය හොඳින් පිරිසිදු කර භාවිතා කරන්න.');
                }
                if(Language == 5){
                    setKashi('မီးဖိုချောင်  ကျေးဇူးပြု၍ သေချာဆေးကြောပြီး အသုံးပြုပါ။');
                }
                if(Language == 6){
                    setKashi('রান্নাঘর পরিষ্কার করুন এবং ব্যবহার করুন।');
                }
            }
            if(counter >= 108){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 111.8){
                if(Language == 0){
                    setKashi('炊飯器(すいはんき)  きれいに使ってください。');
                }
                if(Language == 1){
                    setKashi('Rice cooker Please use it carefully.');
                }
                if(Language == 2){
                    setKashi('電鍋  請乾淨地使用。');
                }
                if(Language == 3){
                    setKashi('राइस कुकर कृपया यसलाई सावधानीपूर्वक प्रयोग गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('රයිස් කුකර්    කරුණාකර එය ප්රවේශමෙන් භාවිතා කරන්න.');
                }
                if(Language == 5){
                    setKashi('ထမင်းပေါင်းအိုး  ဂရုတစိုက်အသုံးပြုပါ။');
                }
                if(Language == 6){
                    setKashi('দয়া করে রাইস কুকার পরিষ্কারভাবে ব্যবহার করুন।');
                }
            }
            if(counter >= 114.4){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 119){
                if(Language == 0){
                    setKashi('コンロ  使ったあとは、きれいにふいてください。');
                }
                if(Language == 1){
                    setKashi('Stove Wipe it clean after using it.');
                }
                if(Language == 2){
                    setKashi('厨房炉灶  使用後擦拭乾淨。');
                }
                if(Language == 3){
                    setKashi('चुलो यसलाई प्रयोग गरेपछि सफा गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('උදුන    එය භාවිතා කිරීමෙන් පසු එය පිරිසිදු කරන්න.');
                }
                if(Language == 5){
                    setKashi('မီးဖို  အသုံးပြုပြီးပါက သန့်ရှင်းပေးပါ။');
                }
                if(Language == 6){
                    setKashi('চুলা ব্যবহার করার পরে এটি পরিষ্কার করুন।');
                }
            }
            if(counter >= 121.6){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 124){
                if(Language == 0){
                    setKashi('冷蔵庫(れいぞうこ)  ときどき中をふいて、きれいに使ってください。');
                }
                if(Language == 1){
                    setKashi('Refrigerator Wipe the inside from time to time to keep it clean.');
                }
                if(Language == 2){
                    setKashi('冰箱  時常擦拭內部，保持清潔。');
                }
                if(Language == 3){
                    setKashi('फ्रिज  यसलाई सफा राख्न समय समयमा भित्री भाग पुछ्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ශීතකරණය    ඇතුළත පිරිසිදුව තබා ගැනීමට වරින් වර පිස දමන්න.');
                }
                if(Language == 5){
                    setKashi('ရေခဲသေတ္တာ  အတွင်းပိုင်းကို အခါအားလျော်စွာ သန့်ရှင်းအောင် သုတ်ပေးပါ။');
                }
                if(Language == 6){
                    setKashi('রেফ্রিজারেটর পরিষ্কার রাখতে অনুগ্রহ করে সময় সময় ভিতরে মুছুন।');
                }
            }
            if(counter >= 126.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 135.4){
                if(Language == 0){
                    setKashi('禁煙＆ごみ分別表  部屋とベランダでたばこをすってはいけません。');
                }
                if(Language == 1){
                    setKashi('No smoking & garbage sorting table Do not smoke in your room or on the balcony.');
                }
                if(Language == 2){
                    setKashi('禁止吸煙及垃圾分類台  請勿在房間內或陽台上吸煙。');
                }
                if(Language == 3){
                    setKashi('धुम्रपान सम्बन्धी र फोहोर फाल्ने सम्बन्धी  तालिका ,आफ्नो कोठामा वा बालकनीमा धुम्रपान नगर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('දුම්පානය සහ කසළ වර්ග කිරීමේ වගුවක් ඇත. ඔබේ කාමරයේ හෝ බැල්කනියේ දුම් පානය නොකරන්න.');
                }
                if(Language == 5){
                    setKashi('ဆေးလိပ်မသောက်ရနှင့် အမှိုက်ခွဲခြင်း ဇယား  သင့်အခန်း သို့မဟုတ် လသာဆောင်တွင် ဆေးလိပ်မသောက်ပါနှင့်။');
                }
                if(Language == 6){
                    setKashi('নো ধূমপান এবং আবর্জনা পৃথকীকরণ চার্ট রুম এবং বারান্দায় ধূমপান নিষিদ্ধ।');
                }
            }
            if(counter >= 138.5){
                if(Language == 0){
                    setKashi('禁煙＆ごみ分別表  ごみは分別表を見て、きちんとわけてください。');
                }
                if(Language == 1){
                    setKashi('No smoking & garbage sorting table Please refer to the garbage separation table and separate your garbage properly.');
                }
                if(Language == 2){
                    setKashi('止吸煙及垃圾分類台  請參閱垃圾分類表，正確分類垃圾。');
                }
                if(Language == 3){
                    setKashi('धुम्रपान सम्बन्धी र फोहोर फाल्ने सम्बन्धी  तालिका, कृपया फोहोर अलग गर्ने तालिकालाई सन्दर्भ गर्नुहोस् र आफ्नो फोहोरलाई राम्ररी अलग गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('දුම්පානය සහ කසළ වර්ග කිරීමේ වගුවක් නොමැත    කරුණාකර කසළ වෙන් කිරීමේ වගුව වෙත ගොස් ඔබේ කසළ නිසි ලෙස වෙන් කරන්න.');
                }
                if(Language == 5){
                    setKashi('ဆေးလိပ်မသောက်ရနှင့် အမှိုက်ခွဲခြင်း ဇယား ကျေးဇူးပြု၍ အမှိုက်ခွဲခြားခြင်းဇယားကို ကြည့်ပြီး သင့်အမှိုက်များကို စနစ်တကျ ခွဲခြားပါ။');
                }
                if(Language == 6){
                    setKashi('নো ধূমপান এবং আবর্জনা আলাদা করার টেবিল অনুগ্রহ করে আবর্জনা আলাদা করার টেবিল দেখুন এবং আপনার আবর্জনা সঠিকভাবে আলাদা করুন।');
                }
            }
            if(counter >= 141.5){
                if(Language == 0){
                    setKashi('ごみすて場  わけたごみは、決められた曜日にすててください。');
                }
                if(Language == 1){
                    setKashi('Garbage disposal site Please throw away the separated garbage on the designated day of the week.');
                }
                if(Language == 2){
                    setKashi('垃圾場  請在一週中的指定日期丟掉分類垃圾。');
                }
                if(Language == 3){
                    setKashi('फोहोर फाल्ने ठाउँ ,  कृपया छुट्याइएको फोहोर हप्ताको तोकिएको दिनमा फाल्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කුණු ගොඩ    කරුණාකර වෙන් කරන ලද කසළ සතියේ නියමිත දිනට ඉවත දමන්න.');
                }
                if(Language == 5){
                    setKashi('အမှိုက်သရိုက်အမှိုက်ပုံ  ကျေးဇူးပြု၍ ရက်သတ္တပတ်၏ သတ်မှတ်ရက်တွင် ခွဲထားသော အမှိုက်များကို စွန့်ပစ်ပါ။');
                }
                if(Language == 6){
                    setKashi('আবর্জনা নিষ্পত্তি এলাকা অনুগ্রহ করে সপ্তাহের নির্ধারিত দিনে পৃথক আবর্জনা নিষ্পত্তি করুন।');
                }
            }
            if(counter >= 147){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 150.3){
                if(Language == 0){
                    setKashi('寮ツアー動画END...');
                }
                if(Language == 1){
                    setKashi('Dormitory tour video END...');
                }
                if(Language == 2){
                    setKashi('宿舍參觀影片完...');
                }
                if(Language == 3){
                    setKashi('छात्रावास भ्रमण भिडियो END...');
                }
                if(Language == 4){
                    setKashi('නේවාසිකාගාර චාරිකාවේ වීඩියෝව අවසන්...');
                }
                if(Language == 5){
                    setKashi('အိပ်ဆောင် ခရီးစဉ် ဗီဒီယို အဆုံး...');
                }
                if(Language == 6){
                    setKashi('ডরমেটরি ট্যুর ভিডিও শেষ...');
                }
            }

            if(counter >= 154){
                setMoveVideoTime(13);
                document.getElementById('video').currentTime = 0;
            }
        }

        //　14本目　家電の使い方
        if(MoveVideoTime == 13){
            setChapterColor1('MoveChapBtn');
            setChapterColor2('MoveChapBtn');
            setChapterColor3('MoveChapBtn');
            setChapterColor4('MoveChapBtn');
            setChapterColor5('MoveChapBtn');
            setChapterColor6('MoveChapBtn');
            setChapterColor7('MoveChapBtn');
            setChapterColor8('MoveChapBtn');
            setChapterColor9('MoveChapBtn');
            setChapterColor10('MoveChapBtn');
            setChapterColor11('MoveChapBtn');
            setChapterColor12('MoveChapBtn');
            setChapterColor13('MoveChapBtn');
            setChapterColor14('MoveChapBtn MChBChange');

            if(counter >= 0){
                setMoveVideo(Electronics);
            }

            //　コンロの使い方
            if(counter >= 0.5){
                if(Language == 0){
                    setKashi('コンロの使い方');
                }
                if(Language == 1){
                    setKashi('How to use the stove');
                }
                if(Language == 2){
                    setKashi('瓦斯爐的使用方法');
                }
                if(Language == 3){
                    setKashi('ग्याँस स्टोभ प्रयोग गर्ने तरीका');
                }
                if(Language == 4){
                    setKashi('උදුන භාවිතා කරන්නේ කෙසේද');
                }
                if(Language == 5){
                    setKashi('မီးဖိုအသုံးပြုနည်း');
                }
                if(Language == 6){
                    setKashi('চুলা কিভাবে ব্যবহার করবেন');
                }
            }
            if(counter >= 4.3){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 4.8){
                if(Language == 0){
                    setKashi('コンロで火をつけるときは、');
                }
                if(Language == 1){
                    setKashi('When starting a fire on the stove,');
                }
                if(Language == 2){
                    setKashi('當在瓦斯爐上生火時，');
                }
                if(Language == 3){
                    setKashi('चुलोमा आगो बाल्दा,');
                }
                if(Language == 4){
                    setKashi('ලිප මත ගින්දර දල්වන විට,');
                }
                if(Language == 5){
                    setKashi('မီးဖိုပေါ်မီးထွန်းသောအခါ၊');
                }
                if(Language == 6){
                    setKashi('চুলায় আগুন জ্বালানোর সময়,');
                }
            }
            if(counter >= 6.8){
                if(Language == 0){
                    setKashi('火がつくまでボタンを長く押してください。');
                }
                if(Language == 1){
                    setKashi('Press and hold the button until it lights up.');
                }
                if(Language == 2){
                    setKashi('按住按鈕直至其亮起。');
                }
                if(Language == 3){
                    setKashi('आगो नबल्दासम्म बटन थिच्नुहोस् र होल्ड गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('එය දැල්වෙන තුරු බොත්තම ඔබාගෙන සිටින්න.');
                }
                if(Language == 5){
                    setKashi('မီးလင်းသည်အထိ ခလုတ်ကို နှိပ်ပြီး ဖိထားပါ။');
                }
                if(Language == 6){
                    setKashi('আলো না হওয়া পর্যন্ত বোতাম টিপুন এবং ধরে রাখুন।');
                }
            }
            if(counter >= 10.5){
                if(Language == 0){
                    setKashi('レバーを左右に動かして火の大きさをちょうせつします。');
                }
                if(Language == 1){
                    setKashi('Move the lever to the left or right to adjust the level of the fire.');
                }
                if(Language == 2){
                    setKashi('左右移動控制桿可調整火力大小。');
                }
                if(Language == 3){
                    setKashi('आगोको आकार समायोजन गर्न लीभर बायाँ र दायाँ सार्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ගින්නෙහි ප්රමාණය සකස් කිරීම සඳහා ලීවරය වමට සහ දකුණට ගෙන යන්න.');
                }
                if(Language == 5){
                    setKashi('မီးအရွယ်အစားကိုချိန်ညှိရန် လီဗာကို ဘယ်ညာရွှေ့ပါ။');
                }
                if(Language == 6){
                    setKashi('আগুনের আকার সামঞ্জস্য করতে লিভারটি বাম এবং ডানদিকে সরান।');
                }
            }
            if(counter >= 15.5){
                if(Language == 0){
                    setKashi('ボタンをおすと、火がきえます。');
                }
                if(Language == 1){
                    setKashi('Press the button to extinguish the fire.');
                }
                if(Language == 2){
                    setKashi('按下按鈕即可滅火。');
                }
                if(Language == 3){
                    setKashi('आगो निभाउन बटन थिच्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('ගින්න නිවා දැමීමට බොත්තම ඔබන්න.');
                }
                if(Language == 5){
                    setKashi('မီးငြှိမ်းသတ်ရန် ခလုတ်ကို နှိပ်ပါ။');
                }
                if(Language == 6){
                    setKashi('আগুন নিভানোর জন্য বোতাম টিপুন।');
                }
            }
            if(counter >= 19.6){
                if(Language == 0){
                    setKashi('魚などを焼きたいときは、グリルを使って焼くこともできます。');
                }
                if(Language == 1){
                    setKashi('If you want to grill fish, etc., you can also use the grill.');
                }
                if(Language == 2){
                    setKashi('如果你想烤魚等，也可以使用烤架。');
                }
                if(Language == 3){
                    setKashi('यदि तपाइँ माछा आदि ग्रिल गर्न चाहनुहुन्छ भने, तपाइँ ग्रिल पनि प्रयोग गर्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබට මාළු ආදිය ග්‍රිල් කිරීමට අවශ්‍ය නම්, ඔබට ග්‍රිල් ද භාවිතා කළ හැකිය.');
                }
                if(Language == 5){
                    setKashi('ငါးကင် စတာတွေကို ကင်ချင်ရင်လည်း အကင်ကို သုံးနိုင်ပါတယ်။');
                }
                if(Language == 6){
                    setKashi('আপনি যদি মাছ, ইত্যাদি গ্রিল করতে চান, আপনি গ্রিলও ব্যবহার করতে পারেন।');
                }
            }
            if(counter >= 27){
                if(Language == 0){
                    setKashi('グリルをつかうときは、かならず水を入れてつかいましょう。');
                }
                if(Language == 1){
                    setKashi('When using the grill, be sure to fill it with water.');
                }
                if(Language == 2){
                    setKashi('使用烤架時，請務必加滿水。');
                }
                if(Language == 3){
                    setKashi('ग्रिल प्रयोग गर्दा, ग्रिलमा　पानी भर्न निश्चित हुनुहोस्।');
                }
                if(Language == 4){
                    setKashi('ග්රිල් භාවිතා කරන විට, එය ජලය සමග පිරවීමට වග බලා ගන්න.');
                }
                if(Language == 5){
                    setKashi('အကင်ကိုအသုံးပြုတဲ့အခါ၊ ရေနဲ့သေချာဖြည့်ပါ။');
                }
                if(Language == 6){
                    setKashi('গ্রিল ব্যবহার করার সময়, এটি জল দিয়ে পূরণ করতে ভুলবেন না।');
                }
            }
            if(counter >= 33.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 38){
                if(Language == 0){
                    setKashi('火がつかないとき、赤いランプがつきます。');
                }
                if(Language == 1){
                    setKashi('When the fire stops, the red lamp will turn on.');
                }
                if(Language == 2){
                    setKashi('當火停止時，紅燈會亮起。');
                }
                if(Language == 3){
                    setKashi('जब आगो बल्दैन, रातो बत्ती बल्छ।');
                }
                if(Language == 4){
                    setKashi('ගින්න නැවැත්වූ විට රතු එළිය දැල්වෙයි.');
                }
                if(Language == 5){
                    setKashi('မီးရပ်သွားတဲ့အခါ မီးနီပွင့်လာမယ်။');
                }
                if(Language == 6){
                    setKashi('আগুন বন্ধ হয়ে গেলে, লাল বাতি জ্বলবে।');
                }
            }
            if(counter >= 42.7){
                if(Language == 0){
                    setKashi('電池をかえてください。');
                }
                if(Language == 1){
                    setKashi('Please change the battery.');
                }
                if(Language == 2){
                    setKashi('請更換電池。');
                }
                if(Language == 3){
                    setKashi('कृपया ब्याट्री परिवर्तन गर्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර බැටරිය වෙනස් කරන්න.');
                }
                if(Language == 5){
                    setKashi('ကျေးဇူးပြု၍ ဘက်ထရီကိုပြောင်းပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে ব্যাটারি পরিবর্তন করুন।');
                }
            }
            if(counter >= 46.6){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // エアコンの使い方
            if(counter >= 54){
                if(Language == 0){
                    setKashi('エアコンの使い方');
                }
                if(Language == 1){
                    setKashi('How to use the air conditioner');
                }
                if(Language == 2){
                    setKashi('空調的使用方法');
                }
                if(Language == 3){
                    setKashi('एयर कंडीशनर प्रयोग गर्ने तरीका');
                }
                if(Language == 4){
                    setKashi('වායු සමීකරණ යන්ත්රය භාවිතා කරන්නේ කෙසේද');
                }
                if(Language == 5){
                    setKashi('လေအေးပေးစက် အသုံးပြုနည်း');
                }
                if(Language == 6){
                    setKashi('এয়ার কন্ডিশনার কিভাবে ব্যবহার করবেন');
                }
            }
            if(counter >= 57){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 58){
                if(Language == 0){
                    setKashi('部屋では暖房や冷房をつかうことができます。');
                }
                if(Language == 1){
                    setKashi('You can use cooling and heating  in your room.');
                }
                if(Language == 2){
                    setKashi('您可以在房間內使用暖氣或冷氣。');
                }
                if(Language == 3){
                    setKashi('तपाईं आफ्नो कोठा तातो  वा चिसो बनाउन सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබේ කාමරයේ උණුසුම හෝ සිසිලනය භාවිතා කළ හැකිය.');
                }
                if(Language == 5){
                    setKashi('သင့်အခန်းတွင် အပူပေးခြင်း သို့မဟုတ် အအေးပေးခြင်းတို့ကို အသုံးပြုနိုင်သည်။');
                }
                if(Language == 6){
                    setKashi('আপনি আপনার ঘরে গরম এবং ঠান্ডা ব্যবহার করতে পারেন।');
                }
            }
            if(counter >= 62.8){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 66.2){
                if(Language == 0){
                    setKashi('冷房の温度はだいたい23℃～26℃、');
                }
                if(Language == 1){
                    setKashi('The cooling temperature is approximately 23℃~26℃,');
                }
                if(Language == 2){
                    setKashi('冷卻溫度設定約23℃~28℃，');
                }
                if(Language == 3){
                    setKashi('चिसो बनाउनकोलागी तापमान लगभग 23 ℃ देखि 26 ℃ हुनुपर्छ। ');
                }
                if(Language == 4){
                    setKashi('වායුසමීකරණ යන්ත්රයේ  සිසිලඋෂ්ණත්වය ආසන්න වශයෙන් 23℃~26℃,');
                }
                if(Language == 5){
                    setKashi('လေအေးပေးစက်၏ အပူချိန်မှာ ခန့်မှန်းခြေအားဖြင့် 23 ℃ ~ 26 ℃ ၊');
                }
                if(Language == 6){
                    setKashi('এয়ার কন্ডিশনার তাপমাত্রা প্রায় 23℃~26℃,');
                }
            }
            if(counter >= 71.1){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 75.1){
                if(Language == 0){
                    setKashi('暖房の温度はだいたい25℃～28℃にしましょう。');
                }
                if(Language == 1){
                    setKashi('The heating temperature should be approximately 25℃ to 28℃.');
                }
                if(Language == 2){
                    setKashi('加熱溫度應約25℃至28℃。');
                }
                if(Language == 3){
                    setKashi('तातो बनाउनको लागी तापमान लगभग 25 ℃ देखि 28 ℃ हुनुपर्छ।');
                }
                if(Language == 4){
                    setKashi('උනුසුම් උෂ්ණත්වය ආසන්න වශයෙන් 25 ° සිට 28 ° දක්වා විය යුතුය.');
                }
                if(Language == 5){
                    setKashi('အပူအပူချိန်သည် ခန့်မှန်းခြေအားဖြင့် 25°C မှ 28°C ရှိသင့်သည်။');
                }
                if(Language == 6){
                    setKashi('গরম করার তাপমাত্রা প্রায় 25 ℃ থেকে 28 ℃ হওয়া উচিত।');
                }
            }
            if(counter >= 80.6){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // 洗濯機の使い方
            if(counter >= 86){
                if(Language == 0){
                    setKashi('洗濯機の使い方');
                }
                if(Language == 1){
                    setKashi('How to use a washing machine');
                }
                if(Language == 2){
                    setKashi('洗衣機的使用方法');
                }
                if(Language == 3){
                    setKashi('लुगा धुने मेसिन प्रयोग गर्ने तरीका');
                }
                if(Language == 4){
                    setKashi('රෙදි සෝදන යන්ත්රයක් භාවිතා කරන්නේ කෙසේද');
                }
                if(Language == 5){
                    setKashi('အဝတ်လျှော်စက်အသုံးပြုနည်း');
                }
                if(Language == 6){
                    setKashi('কিভাবে একটি ওয়াশিং মেশিন ব্যবহার করতে হয়');
                }
            }
            if(counter >= 89.5){
                if(Language == 0){
                    setKashi('洗濯機（せんたくき）をつかうときは、でんげんをつけてスタートをおしてください。');
                }
                if(Language == 1){
                    setKashi('When using the washing machine, please turn on the laundry and press start.');
                }
                if(Language == 2){
                    setKashi('使用洗衣機時，請開啟並按啟動鍵。');
                }
                if(Language == 3){
                    setKashi('धुने मेसिन प्रयोग गर्दा,पावर अन बटन थिच्नुहोस् र सुरु गर्ने बटन थिच्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('රෙදි සෝදන යන්ත්රය භාවිතා කරන විට, එය සක්රිය කර ආරම්භය ඔබන්න.');
                }
                if(Language == 5){
                    setKashi('အဝတ်လျှော်စက်ကို အသုံးပြုသောအခါတွင် ၎င်းကိုဖွင့်ပြီး စတင်မည်ကို နှိပ်ပါ။');
                }
                if(Language == 6){
                    setKashi('ওয়াশিং মেশিন ব্যবহার করার সময়, অনুগ্রহ করে লন্ড্রি চালু করুন এবং স্টার্ট টিপুন৷');
                }
            }
            if(counter >= 96){
                if(Language == 0){
                    setKashi('たくさん服を入れると、洗濯（せんたく）できません。');
                }
                if(Language == 1){
                    setKashi("If you put too many clothes in it, you won't be able to wash it.");
                }
                if(Language == 2){
                    setKashi('衣服放太多的話就洗不了了。');
                }
                if(Language == 3){
                    setKashi('यदि तपाईंले यसमा धेरै लुगाहरू राख्नुभयो भने, तपाईंले यसलाई धुन सक्नुहुन्न।');
                }
                if(Language == 4){
                    setKashi('රෙදි ගොඩක් දැම්මොත් හෝදන්න බැරි වෙනවා.');
                }
                if(Language == 5){
                    setKashi('အဝတ်တွေ အများကြီးထည့်ထားရင် လျှော်လို့ မရတော့ဘူး။');
                }
                if(Language == 6){
                    setKashi('আপনি যদি এটিতে অনেকগুলি কাপড় রাখেন তবে আপনি এটি ধুতে পারবেন না।');
                }
            }
            if(counter >= 100){
                if(Language == 0){
                    setKashi('気をつけてください。');
                }
                if(Language == 1){
                    setKashi('Please be careful.');
                }
                if(Language == 2){
                    setKashi('偶爾洗一下衣服。');
                }
                if(Language == 3){
                    setKashi('कृपया सावधान रहनुहोस्।');
                }
                if(Language == 4){
                    setKashi('කරුණාකර පරිස්සම් වන්න.');
                }
                if(Language == 5){
                    setKashi('သတိထားပါ။');
                }
                if(Language == 6){
                    setKashi('দয়া করে সতর্ক থাকুন।');
                }
            }
            if(counter >= 103.5){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            // お風呂の使い方
            if(counter >= 105.7){
                if(Language == 0){
                    setKashi('お風呂の使い方');
                }
                if(Language == 1){
                    setKashi('How to use the bath');
                }
                if(Language == 2){
                    setKashi('浴缸的使用方法');
                }
                if(Language == 3){
                    setKashi('नुहाउने ठाउँ प्रयोग गर्ने तरिका');
                }
                if(Language == 4){
                    setKashi('ස්නානය භාවිතා කරන්නේ කෙසේද');
                }
                if(Language == 5){
                    setKashi('ရေချိုးခန်းအသုံးပြုနည်း');
                }
                if(Language == 6){
                    setKashi('স্নান কিভাবে ব্যবহার করবেন');
                }
            }
            if(counter >= 109){
                if(Language == 0){
                    setKashi('お湯がでないときは、ガスがオフになっているかもしれません。');
                }
                if(Language == 1){
                    setKashi('If there is no hot water, the gas may be turned off.');
                }
                if(Language == 2){
                    setKashi('如果沒有熱水，瓦斯可能會關閉。');
                }
                if(Language == 3){
                    setKashi('यदि नुहाउने ठाउँमा तातोपानी आउँदैन भने ग्याँस बन्द भएको हुनसक्छ।');
                }
                if(Language == 4){
                    setKashi('ඔබට උණු වතුර අවශ්‍ය නොමැති නම්, ගෑස් නිවා දැමිය හැකිය.');
                }
                if(Language == 5){
                    setKashi('သင့်တွင် ရေနွေးမရှိပါက ဓာတ်ငွေ့ပိတ်သွားနိုင်သည်။');
                }
                if(Language == 6){
                    setKashi('গরম পানি না থাকলে গ্যাস বন্ধ করা হতে পারে।');
                }
            }
            if(counter >= 113.3){
                if(Language == 0){
                    setKashi('オフになっていたら、レバーを下におしながら回すとオンになります。');
                }
                if(Language == 1){
                    setKashi("If it's off, hold the lever down and turn it to turn it on.");
                }
                if(Language == 2){
                    setKashi('如果它處於關閉狀態，請向下按操縱桿並轉動它以將其打開。');
                }
                if(Language == 3){
                    setKashi('यदि यो बन्द छ भने, लीभर तल थिच्नुहोस् र यसलाई खोल्न यसलाई घुमाउनुहोस्।');
                }
                if(Language == 4){
                    setKashi('එය ක්‍රියා විරහිත නම්, ලීවරය පහළට ඔබා එය ක්‍රියාත්මක කිරීමට එය හරවන්න.');
                }
                if(Language == 5){
                    setKashi('ပိတ်ထားပါက လီဗာကို အောက်သို့ နှိပ်ပြီး ၎င်းကိုဖွင့်ရန် ခလုတ်ကိုနှိပ်ပါ။');
                }
                if(Language == 6){
                    setKashi('যদি এটি বন্ধ থাকে, লিভারটি ধরে রাখুন এবং এটি চালু করতে এটি চালু করুন।');
                }
            }
            if(counter >= 118.1){
                if(Language == 0){
                    setKashi('ハンドルを赤いほうにまわすとお湯が出ます。');
                }
                if(Language == 1){
                    setKashi('Turn the handle to the red side to get hot water.');
                }
                if(Language == 2){
                    setKashi('將手把轉向紅色一側即可獲得熱水。');
                }
                if(Language == 3){
                    setKashi('ह्यान्डललाई रातोतर्फ घुमाउनुभयो भने तातोपानी आउनेछ।');
                }
                if(Language == 4){
                    setKashi('උණු වතුර ලබා ගැනීම සඳහා හසුරුව රතු පැත්තට හරවන්න.');
                }
                if(Language == 5){
                    setKashi('ရေနွေးရဖို့ လက်ကိုင်ကို အနီရောင်ဘက်ကို လှည့်ပါ။');
                }
                if(Language == 6){
                    setKashi('গরম জল পেতে হাতলটিকে লাল দিকে ঘুরিয়ে দিন।');
                }
            }
            if(counter >= 121.7){
                if(Language == 0){
                    setKashi('青いほうにまわすと水が出ます。');
                }
                if(Language == 1){
                    setKashi('Turn it to the blue side for cold water.');
                }
                if(Language == 2){
                    setKashi('將其轉向藍色一側，水就會流出來。');
                }
                if(Language == 3){
                    setKashi('यसलाई नीलोतर्फ घुमाउनुभयो भने  चिसोपानी आउनेछ।');
                }
                if(Language == 4){
                    setKashi('එය නිල් පැත්තට හරවන්න, එවිට  සිසිල් ඡලය පිටතට පැමිණේ.');
                }
                if(Language == 5){
                    setKashi('အပြာဘက်ကို လှည့်လျှင်ရေအေးထွက်လာမည်။');
                }
                if(Language == 6){
                    setKashi('এটিকে নীল দিকে ঘুরিয়ে দিন এবং জল বেরিয়ে আসবে।');
                }
            }
            if(counter >= 124.7){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }
            if(counter >= 126.3){
                if(Language == 0){
                    setKashi('ハンドルを上げても水が出ないときは、');
                }
                if(Language == 1){
                    setKashi('If water does not come out even after raising the handle,');
                }
                if(Language == 2){
                    setKashi('如果即使抬起手柄後也沒有出水，');
                }
                if(Language == 3){
                    setKashi('ह्यान्डल उठाउँदा पनि पानी आएन भने');
                }
                if(Language == 4){
                    setKashi('හසුරුව එසවූ පසුවත් ජලය පිට නොවන්නේ නම්,');
                }
                if(Language == 5){
                    setKashi('လက်ကိုင်ကို မြှင့်ပြီးတောင် ရေမထွက်ရင်၊');
                }
                if(Language == 6){
                    setKashi('হাতল তোলার পরও যদি পানি বের না হয়,');
                }
            }
            if(counter >= 130){
                if(Language == 0){
                    setKashi('シャワーについているボタンをおしてください。');
                }
                if(Language == 1){
                    setKashi('Please press the button on the shower.');
                }
                if(Language == 2){
                    setKashi('按下淋浴上的按鈕。');
                }
                if(Language == 3){
                    setKashi('पानी आउने ठाउँ नजिकै एउटा बटम छ त्यसलाई थिच्नुहोस्।');
                }
                if(Language == 4){
                    setKashi('නානය මත බොත්තම ඔබන්න.');
                }
                if(Language == 5){
                    setKashi('ရေချိုးခန်းထဲက ခလုတ်ကို နှိပ်ပါ။');
                }
                if(Language == 6){
                    setKashi('অনুগ্রহ করে ঝরনার বোতাম টিপুন।');
                }
            }
            if(counter >= 135){
                if(Language == 0){
                    setKashi('ボタンで水を出したり止めたりできます。');
                }
                if(Language == 1){
                    setKashi('You can turn the water on and off with the button.');
                }
                if(Language == 2){
                    setKashi('您只需按一下按鈕即可打開和關閉水。');
                }
                if(Language == 3){
                    setKashi('यो बटमलाई थिचेर पानी खोल्न र बन्द गर्न सक्नुहुन्छ।');
                }
                if(Language == 4){
                    setKashi('බොත්තමක් එබීමෙන් ඔබට ජලය සක්රිය සහ අක්රිය කළ හැකිය.');
                }
                if(Language == 5){
                    setKashi('ခလုတ်တစ်ချက်နှိပ်ရုံဖြင့် ရေကို အဖွင့်အပိတ်လုပ်နိုင်ပါသည်။');
                }
                if(Language == 6){
                    setKashi('আপনি বোতাম দিয়ে জল চালু এবং বন্ধ করতে পারেন।');
                }
            }
            if(counter >= 139){
                if(Language == 0){
                    setKashi('');
                }
                if(Language == 1){
                    setKashi('');
                }
                if(Language == 2){
                    setKashi('');
                }
                if(Language == 3){
                    setKashi('');
                }
                if(Language == 4){
                    setKashi('');
                }
                if(Language == 5){
                    setKashi('');
                }
                if(Language == 6){
                    setKashi('');
                }
            }

            if(counter >= 142){
                setMoveVideoTime(14);
                document.getElementById('video').currentTime = 0;
            }
        }
    }

    const timeSetMsg1 = () => {
        // document.getElementById('video').currentTime = 0;
        setMoveVideoTime(0);
    }
    const timeSetMsg2 = () => {
        setMoveVideoTime(1);
    }
    const timeSetMsg3 = () => {
        setMoveVideoTime(2);
    }
    const timeSetMsg4 = () => {
        setMoveVideoTime(3);
    }
    const timeSetMsg5 = () => {
        setMoveVideoTime(4);
    }
    const timeSetMsg6 = () => {
        setMoveVideoTime(5);
    }
    const timeSetMsg7 = () => {
        setMoveVideoTime(6);
    }
    const timeSetMsg8 = () => {
        setMoveVideoTime(7);
    }
    const timeSetMsg9 = () => {
        setMoveVideoTime(8);
    }
    const timeSetMsg10 = () => {
        setMoveVideoTime(9);
    }
    const timeSetMsg11 = () => {
        setMoveVideoTime(10);
    }
    const timeSetMsg12 = () => {
        setMoveVideoTime(11);
    }
    const timeSetMsg13 = () => {
        setMoveVideoTime(12);
    }
    const timeSetMsg14 = () => {
        setMoveVideoTime(13);
    }
    // const timeSetMsg15 = () => {
    //     setMoveVideoTime(14);
    // }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            navigate('/');
        }
    })

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <Link to='/'>
                    <img src={Titleimg} className='MenuLogo'/>
                </Link>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <img src={BackImg} className='BackImg'/>
            <p className='MenuReLoad'>ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>01</div>
                        <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>02</div>
                        <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>03</div>
                        <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>04</div>
                        <div className={ChapterColor5} onClick={() => {timeSetMsg5()}}>05</div>
                    </div>
                    <div className='MoveChapArea'>
                        <div className={ChapterColor6} onClick={() => {timeSetMsg6()}}>06</div>
                        <div className={ChapterColor7} onClick={() => {timeSetMsg7()}}>07</div>
                        <div className={ChapterColor8} onClick={() => {timeSetMsg8()}}>08</div>
                        <div className={ChapterColor9} onClick={() => {timeSetMsg9()}}>09</div>
                        <div className={ChapterColor10} onClick={() => {timeSetMsg10()}}>10</div>
                    </div>
                    <div className='MoveChapArea'>
                        <div className={ChapterColor11} onClick={() => {timeSetMsg11()}}>11</div>
                        <div className={ChapterColor12} onClick={() => {timeSetMsg12()}}>12</div>
                        <div className={ChapterColor13} onClick={() => {timeSetMsg13()}}>13</div>
                        <div className={ChapterColor14} onClick={() => {timeSetMsg14()}}>14</div>
                        {/* <div className='MoveChapBtn' onClick={() => {timeSetMsg15()}}>15</div> */}
                    </div>
                    <video src={MoveVideo} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className={ChapterColor1} onClick={() => {timeSetMsg1()}}>01</div>
                    <div className={ChapterColor2} onClick={() => {timeSetMsg2()}}>02</div>
                    <div className={ChapterColor3} onClick={() => {timeSetMsg3()}}>03</div>
                    <div className={ChapterColor4} onClick={() => {timeSetMsg4()}}>04</div>
                    <div className={ChapterColor5} onClick={() => {timeSetMsg5()}}>05</div>
                </div>
                <div className='MoveChapArea3'>
                    <div className={ChapterColor6} onClick={() => {timeSetMsg6()}}>06</div>
                    <div className={ChapterColor7} onClick={() => {timeSetMsg7()}}>07</div>
                    <div className={ChapterColor8} onClick={() => {timeSetMsg8()}}>08</div>
                    <div className={ChapterColor9} onClick={() => {timeSetMsg9()}}>09</div>
                    <div className={ChapterColor10} onClick={() => {timeSetMsg10()}}>10</div>
                </div>
                <div className='MoveChapArea3'>
                    <div className={ChapterColor11} onClick={() => {timeSetMsg11()}}>11</div>
                    <div className={ChapterColor12} onClick={() => {timeSetMsg12()}}>12</div>
                    <div className={ChapterColor13} onClick={() => {timeSetMsg13()}}>13</div>
                    <div className={ChapterColor14} onClick={() => {timeSetMsg14()}}>14</div>
                    {/* <div className='MoveChapBtn' onClick={() => {timeSetMsg15()}}>15</div> */}
                </div>
            </div>
        </div>
    )
}