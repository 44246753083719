import '../CSS/Style.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import Titleimg from '../Images/title.png';
import BackImg from '../Images/background.svg';

import JourneyB from '../Images/JourneyB.mp4';

export default function JourneyBMove ({ setLanguage, Language, isAuth, setIsAuth, LogInNo, setLogInNo }) {

    // メニュー言語選択時枠を二重にする
    const [JapanLanguage, setJapanLanguage] = useState('MenuLanguage Choice');
    const [EnglishLanguage, setEnglishLanguage] = useState('MenuLanguage');
    const [ChinaLanguage, setChinaLanguage] = useState('MenuLanguage');
    const [NepalLanguage, setNepalLanguage] = useState('MenuLanguage SmallMenu');
    const [SiṁhalaLanguage, setSiṁhalaLanguage] = useState('MenuLanguage SmallMenu');
    const [BurmaLanguage, setBurmaLanguage] = useState('MenuLanguage');
    const [BengalLanguage, setBengalLanguage] = useState('MenuLanguage SmallMenu');

    const [MoveText, setMoveText] = useState('MoveText');

    // 歌詞
    const [kashi, setKashi] = useState('');

    //日本語時
    const selectJapan = () => {
        setLanguage(0); // 他ページに選択中の言語を指定する処理
    }

    //英語時
    const selectEnglish = () => {
        
        setLanguage(1); // 他ページに選択中の言語を指定する処理
    }

    //繁体中国語時
    const selectChina = () => {
        setLanguage(2); // 他ページに選択中の言語を指定する処理
    }

    //ネパール語時
    const selectNepal = () => {
        setLanguage(3); // 他ページに選択中の言語を指定する処理
    }

    //シンハラ語時
    const selectSiṁhala = () => {
        setLanguage(4); // 他ページに選択中の言語を指定する処理
    }

    //ビルマ語時
    const selectBurma = () => {
        setLanguage(5); // 他ページに選択中の言語を指定する処理
    }

    //ベンガル語時
    const selectBengal = () => {
        setLanguage(6); // 他ページに選択中の言語を指定する処理
    }

    // 遷移時に表示させる言語
    useEffect(() => {
        if(Language == 0){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage Choice');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 1){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage Choice');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 2){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage Choice');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }
        
        if(Language == 3){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu Choice');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 4){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu Choice');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText');
        }

        if(Language == 5){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage Choice');
            setBengalLanguage('MenuLanguage SmallMenu');

            setMoveText('MoveText BurmaMoveText');
        }

        if(Language == 6){
            // メニュー言語選択時枠を二重にする
            setJapanLanguage('MenuLanguage');
            setEnglishLanguage('MenuLanguage');
            setChinaLanguage('MenuLanguage');
            setNepalLanguage('MenuLanguage SmallMenu');
            setSiṁhalaLanguage('MenuLanguage SmallMenu');
            setBurmaLanguage('MenuLanguage');
            setBengalLanguage('MenuLanguage SmallMenu Choice');

            setMoveText('MoveText');
        }
    })

    // スマホ画面時メニューバーの開閉処理
    const [MenuBase, setMenuBase] = useState('MenuBase MenuClose');
    const [MenuBaseCount, setMenuBaseCount] = useState(1);
    const selectClose = () => {
        if ( MenuBaseCount == 0){
            setMenuBase('MenuBase MenuClose');
            setMenuBaseCount(1);
        }
        if ( MenuBaseCount == 1){
            setMenuBase('MenuBase');
            setMenuBaseCount(0);
        }
    }

    // 動画再生位置取得
    const callbackRef = useRef()
    let counter = 0;

    // ここに動画の停止・再生の処理を加える
    const [AnswerOnOff, setAnswerOnOff] = useState(1);
    const [player, setPlayer] = useState(null);

    const answerOpen = () => {
        if(AnswerOnOff == 0){
            setAnswerOnOff(1);
            player.play();
        }
        if(AnswerOnOff == 1){
            setAnswerOnOff(0);
            player.pause();
        }
    }

    const updateMsg = () => {
        counter = document.getElementById('video').currentTime
        console.log(Math.floor(counter))

        //　寮(大幸東団地)スタート！
        if(counter >= 0){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
        }
        if(counter >= 1.5){
            if(Language == 0){
                setKashi('寮(大幸東団地)スタート！');
            }
            if(Language == 1){
                setKashi('Start of the road to the dormitory (Taiko Higashi Danchi)!');
            }
            if(Language == 2){
                setKashi('宿舍（大幸東團地）開始了！');
            }
            if(Language == 3){
                setKashi('छात्रावास (दाइको हिगाशि दान्ची)सुरु भएको छ।');
            }
            if(Language == 4){
                setKashi('නේවාසිකාගාරය (Daiko Higashi Danchi) ආරම්භ කර ඇත!');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင် (Daiko Higashi Danchi) စတင်ပါပြီ။');
            }
            if(Language == 6){
                setKashi('ডরমিটরি (DAIKO HIGASHI) শুরু হয়!');
            }
        }
        if(counter >= 4.2){
            if(Language == 0){
                setKashi('学校を出て、みぎにまがってください。');
            }
            if(Language == 1){
                setKashi('Exit the school and turn right.');
            }
            if(Language == 2){
                setKashi('退出學校並向右轉。');
            }
            if(Language == 3){
                setKashi('स्कूलबाट निस्कनुहोस् र दायाँ घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('පාසලෙන් පිටවී දකුණට හැරෙන්න.');
            }
            if(Language == 5){
                setKashi('ကျောင်းမှထွက်ပြီး ညာဘက်သို့ လှည့်ပါ။');
            }
            if(Language == 6){
                setKashi('স্কুল থেকে প্রস্থান করুন এবং ডানদিকে ঘুরুন।');
            }
        }
        if(counter >= 8.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 20.8){
            if(Language == 0){
                setKashi('大きいみちに来たら、ひだりにまがってください。');
            }
            if(Language == 1){
                setKashi('When you reach the large street, please turn to the left.');
            }
            if(Language == 2){
                setKashi('當您來到一條大路時，請左轉。');
            }
            if(Language == 3){
                setKashi('जब तपाईं ठूलो सडकमा आउनुहुन्छ, कृपया बायाँ घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('ඔබ විශාල මාර්ගයකට පැමිණෙන විට, කරුණාකර වමට හැරෙන්න.');
            }
            if(Language == 5){
                setKashi('လမ်းကျယ်ကြီးရောက်ရင် ဘယ်ဘက်ကို ကွေ့ပါ။');
            }
            if(Language == 6){
                setKashi('বড় রাস্তায় এলে বাম দিকে ঘুরুন।');
            }
        }
        if(counter >= 25){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 57){
            if(Language == 0){
                setKashi('ひだりにスーパー「AEON」があります。');
            }
            if(Language == 1){
                setKashi('There is a supermarket "AEON" on the left.');
            }
            if(Language == 2){
                setKashi('左邊有一家超市“AEON”。');
            }
            if(Language == 3){
                setKashi('बायाँ मा एक सुपरमार्केट "AEON" छ।');
            }
            if(Language == 4){
                setKashi('වම් පසින් "AEON" සුපිරි වෙළඳසැලක් ඇත.');
            }
            if(Language == 5){
                setKashi('ဘယ်ဘက်တွင် "AEON" စူပါမားကတ်တစ်ခုရှိသည်။');
            }
            if(Language == 6){
                setKashi('হিদারিতে একটি সুপারমার্কেট "AEON" আছে।');
            }
        }
        if(counter >= 61){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 79){
            if(Language == 0){
                setKashi('みちをわたって、みぎにまがってください。');
            }
            if(Language == 1){
                setKashi('Cross the street and turn to the right.');
            }
            if(Language == 2){
                setKashi('過馬路並右轉。');
            }
            if(Language == 3){
                setKashi('सडक पार गर्नुहोस् र दायाँ घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('පාර හරහා ගොස් දකුණට හැරෙන්න.');
            }
            if(Language == 5){
                setKashi('လမ်းကိုဖြတ်ပြီး ညာဘက်ကွေ့လိုက်ပါ။');
            }
            if(Language == 6){
                setKashi('রাস্তা পেরিয়ে ঘুরুন।');
            }
        }
        if(counter >= 83.3){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 124.6){
            if(Language == 0){
                setKashi('大きいこうさてんをわたります。');
            }
            if(Language == 1){
                setKashi('Cross the big intersection.');
            }
            if(Language == 2){
                setKashi('穿過大十字路口。');
            }
            if(Language == 3){
                setKashi('ठूलो चोक (intersection) पार गर्नुहोस्।');
            }
            if(Language == 4){
                setKashi('විශාල මංසන්ධිය තරණය කරන්න.');
            }
            if(Language == 5){
                setKashi('လမ်းဆုံကြီးကို ဖြတ်ကျော်ပါ။');
            }
            if(Language == 6){
                setKashi('বড় রাস্তা পার হও।');
            }
        }
        if(counter >= 128.6){
            if(Language == 0){
                setKashi('すなだばし駅があります。寮からいちばん近い駅です。');
            }
            if(Language == 1){
                setKashi('There is Sunadabashi station. This is the station closest to the dormitory.');
            }
            if(Language == 2){
                setKashi('有砂田橋站。　這是離宿舍最近的車站。');
            }
            if(Language == 3){
                setKashi('सुनदाबासी स्टेशन छ।　यो छात्रावासको सबैभन्दा नजिकको स्टेशन हो।');
            }
            if(Language == 4){
                setKashi('සුනදබාෂි දුම්රිය ස්ථානය ඇත.　මෙය නේවාසිකාගාරයට ආසන්නම ස්ථානයයි.');
            }
            if(Language == 5){
                setKashi('Sunadabashi ဘူတာရှိတယ်။　　ဤသည်မှာ အိပ်ဆောင်နှင့် အနီးဆုံးဘူတာဖြစ်သည်။');
            }
            if(Language == 6){
                setKashi('এটি "SUNADABASHI" স্টেশন। এটি ছাত্রাবাসের সবচেয়ে কাছের স্টেশন।');
            }
        }
        if(counter >= 133.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 135){
            if(Language == 0){
                setKashi('スーパー「Max Valu」があります。');
            }
            if(Language == 1){
                setKashi('There is a supermarket "Max Valu".');
            }
            if(Language == 2){
                setKashi('有一個超市場“Max Valu”。');
            }
            if(Language == 3){
                setKashi('त्यहाँ एक सुपरमार्केट　"MAX Valu" छ।');
            }
            if(Language == 4){
                setKashi('සුපිරි "Max Valu" එකක් තියෙනවා.');
            }
            if(Language == 5){
                setKashi('Super "Max Valu" ရှိတယ်။');
            }
            if(Language == 6){
                setKashi('একটি সুপার "MAX VALU" আছে।');
            }
        }
        if(counter >= 140){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 165.5){
            if(Language == 0){
                setKashi('ひだりにまがってください。');
            }
            if(Language == 1){
                setKashi('Please turn to the left.');
            }
            if(Language == 2){
                setKashi('請左轉。');
            }
            if(Language == 3){
                setKashi('कृपया बायाँ घुम्नुहोस्।');
            }
            if(Language == 4){
                setKashi('කරුණාකර වමට හැරෙන්න.');
            }
            if(Language == 5){
                setKashi('ဘယ်ဘက်လှည့်ပါ။');
            }
            if(Language == 6){
                setKashi('দয়া করে বাম দিকে ঘুরুন।');
            }
        }
        if(counter >= 169.7){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
        if(counter >= 172.5){
            if(Language == 0){
                setKashi('ここが寮です。');
            }
            if(Language == 1){
                setKashi('This is the dormitory.');
            }
            if(Language == 2){
                setKashi('這是宿舍。');
            }
            if(Language == 3){
                setKashi('यो छात्रावास हो।');
            }
            if(Language == 4){
                setKashi('මේ නේවාසිකාගාරයයි.');
            }
            if(Language == 5){
                setKashi('ဒါက အိပ်ဆောင်ပါ။');
            }
            if(Language == 6){
                setKashi('এটি হল ছাত্রাবাস।');
            }
        }
        if(counter >= 177){
            if(Language == 0){
                setKashi('スーパー「ヤマト」があります。');
            }
            if(Language == 1){
                setKashi('There is a supermarket "Yamato".');
            }
            if(Language == 2){
                setKashi('有一家超市「YAMATO」。');
            }
            if(Language == 3){
                setKashi('त्यहाँ एक सुपरमार्केट "Yamato" छ।');
            }
            if(Language == 4){
                setKashi('"යමටෝ" සුපිරි වෙළඳසැලක් ඇත.');
            }
            if(Language == 5){
                setKashi('စူပါမားကတ် "Yamato" ရှိတယ်။');
            }
            if(Language == 6){
                setKashi('একটি সুপারমার্কেট "Yamato" আছে।');
            }
        }
        if(counter >= 182){
            if(Language == 0){
                setKashi('寮(大幸東団地)ゴール！');
            }
            if(Language == 1){
                setKashi('Road to the dormitory (Taiko Higashi Danchi) Goal!');
            }
            if(Language == 2){
                setKashi('宿舍（大幸東團地）到達！');
            }
            if(Language == 3){
                setKashi('छात्रावास (दाइको हिगाशि दान्ची) गोल.....।');
            }
            if(Language == 4){
                setKashi('නේවාසිකාගාරය (Daiko Higashi Danchi) ඉලක්කය!');
            }
            if(Language == 5){
                setKashi('အိပ်ဆောင် (Daiko Higashi Danchi) ပန်းတိုင်။');
            }
            if(Language == 6){
                setKashi('ডরমিটরি (তাইকো হিগাশি দাঞ্চি) গোল!');
            }
        }
        if(counter >= 185.5){
            if(Language == 0){
                setKashi('');
            }
            if(Language == 1){
                setKashi('');
            }
            if(Language == 2){
                setKashi('');
            }
            if(Language == 3){
                setKashi('');
            }
            if(Language == 4){
                setKashi('');
            }
            if(Language == 5){
                setKashi('');
            }
            if(Language == 6){
                setKashi('');
            }
        }
    }

    const timeSetMsg1 = () => {
        document.getElementById('video').currentTime = 0;
    }

    useEffect(() => {
        callbackRef.current = updateMsg
    }, [updateMsg])

    useEffect(() => {
        const tick = () => { callbackRef.current() }
        const intervalId = setInterval(tick, AnswerOnOff);
        return () => {
            clearInterval(intervalId);
        };
    },[])

    const navigate = useNavigate();
    useEffect(() => {
        if(!isAuth){
            setLogInNo(LogInNo);
            navigate('/');
        }
    })

    const HomeReset = () => {
        setLogInNo(LogInNo);
        navigate('/');
    }

    return(
        <div className='PageBase'>
            <div className={MenuBase}>
                <img src={Titleimg} className='MenuLogo' onClick={() => {HomeReset()}}/>
                <div className='space' onClick={() => {selectClose()}}>Language</div>
                <div className={JapanLanguage} onClick={() => {selectJapan()}}>日本語</div>
                <div className={EnglishLanguage} onClick={() => {selectEnglish()}}>英語</div>
                <div className={ChinaLanguage} onClick={() => {selectChina()}}>繁体<br/>中国語</div>
                <div className={NepalLanguage} onClick={() => {selectNepal()}}>ネパール語</div>
                <div className={SiṁhalaLanguage} onClick={() => {selectSiṁhala()}}>シンハラ語</div>
                <div className={BurmaLanguage} onClick={() => {selectBurma()}}>ビルマ語</div>
                <div className={BengalLanguage} onClick={() => {selectBengal()}}>ベンガル語</div>
            </div>

            <div className='BackImgArea'>
                <img src={BackImg} className='BackImg'/>
            </div>

            <p className='MenuReLoad'><FontAwesomeIcon icon={faCircleExclamation} style={{color: "#ff2600"}} /> ページをリロードしないでください</p>

            <div className='SelectArea'>
                <div className='MoveArea'>
                    <div className='MoveChapArea1'>
                        <div className='MoveChapBtn MChBChange' onClick={() => {timeSetMsg1()}}>Chp1</div>
                    </div>
                    <video src={JourneyB} className='MoveSet' id='video' autoPlay playsInline controls />
                </div>
                <div className='TextArea'>
                    <div className={MoveText}>{kashi}</div>
                </div>
                <div className='MoveChapArea2'>
                    <div className='MoveChapBtn MChBChange' onClick={() => {timeSetMsg1()}}>Chp1</div>
                </div>
            </div>
        </div>
    )
}